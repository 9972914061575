import React from "react";

import UserReferral from "./UserReferral";
import UserAnalytics from "./UserAnalytics";
import UserEarnings from "./UserEarnings";
import SuccessKit from "./SuccessKit";
import SubscriptionInfo from "./SubscriptionInfo";
import CashoutOption from "./CashoutOption";
import FlutterwaveTransfer from "./FlutterwaveTransfer";
import PaypalTransfer from "./PaypalTransfer";
import FreeTrial from "./FreeTrial";
import BankData from "../../../../secrets/NigerianBank.json";
import getUserLocation from "../../../helpers/getUserLocation";


import Loading from "../../../common/Loading";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import BottomNav from "../../../common/BottomNav";

import getBasicInfo from "../../../helpers/getBasicInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getShopperInfo from "../../../helpers/getShopperInfo";
import getReferrals from "../../../helpers/getReferrals";
import SuccessSubscription from "./SuccessSubscription";
import NProgress from "nprogress";
import Metatags from "react-meta-tags";

import firebase from "../../../../firebaseConfig";
import {
  
  PAYPAL_TRANSFER, 
  FLUTTERWAVE_TRANSFER,
  PAYSTACK_SUBACCOUNT,
  PAYSTACK_KEY,
  FREE_TRIAL,
  paystackMonthly,
  free_planId,
  NAIRA_EXCHANGE
  } from "../../../../secrets";


const FREE_TRIAL_PRICE=100;

class Reward extends React.Component {
  state = {
    firstName: "",
    cartCount: 0,
    shoppingpoints: 10,
    phoneNumber: "",
    userInfo: {},
    isLoggedIn: false,

    accountNumber:"",
    bankName:"",
    paypalEmail:"",
    paypalPhone:"",
    displayPaypal: false,
    displayFlutterwave: false,
  };

  handlePhoneInput = (phoneNumber) => {
    this.setState({ phoneNumber });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  paypalPhoneChange =(phone)=>{
    this.setState({ paypalPhone:phone });
  }

  toggleDisplay = () => {
    if (this.state.displayFooter === "none") {
      this.setState({
        displayFooter: "flex",
      });
    } else {
      this.setState({
        displayFooter: "none",
      });
    }
  };

  toggleShare = (e) => {
    e.stopPropagation();
    if (this.state.displayShareLinks === "none") {
      this.setState({
        displayShareLinks: "flex",
        displayFooter: "flex",
      });
    } else {
      this.setState({
        displayShareLinks: "none",
        displayFooter: "flex",
      });
    }
  };

  copyReferral = (e) => {
    e.stopPropagation();

    let shortReferral = this.state.sellerId?.slice(0, 6);
    shortReferral = shortReferral?.toUpperCase();
    let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

    console.log("copy", referralLink);
    navigator.clipboard.writeText(referralLink);
    this.setState({
      copied: "Copied Referral Link",
    });
  };

  activateSubscription =(period,successType)=>{
    let{storeInfo}=this.state;

    let subscriptionType=period?.toLowerCase();

    if(subscriptionType=="yearly"){
      subscriptionType="annually"
    }
    if(!subscriptionType){
      subscriptionType="";
    }
    
    storeInfo["subscriptionActive"]=true;
    storeInfo["subscriptionType"]=subscriptionType;
    this.setState({
      storeInfo,
      subscriptionType,
      successPopup: true,
      freeTrial: false,
      successType: successType?successType:"subscription"
    })
  }

  hideSuccesMsg=()=>{
    this.setState({
      successPopup: false
    })
  }
  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  fetchSellerInfo = async (id) => {
    try {
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let { firstName, lastName, slug, activated, storeName, phoneNumber,referralPayment,freeTrialUsed, subscriptionActive,subscriptionHistory, country } =
      basicInfo;

      let shortReferral = id?.slice(0, 6);
      shortReferral = shortReferral?.toUpperCase();

      let totalCashedOut=0;
      let cashedoutReferrals=0;
      if(referralPayment){
        referralPayment.forEach((payment)=>{

          console.log("Payment",payment);
          totalCashedOut+=payment.cashedOutAmount;
          cashedoutReferrals+=payment?.completedReferral;
    
        })
      }

      let {referralCount,successfullReferral, referralList, potentialReward}= await getReferrals(shortReferral, cashedoutReferrals);
      console.log("referralList",referralList)

      let sortedReferrals= referralList.sort(function(x, y){
        let yTimeStamp=y.timeStamp;
        let xTimeStamp=x.timeStamp;
        return yTimeStamp - xTimeStamp;
      })


      let totalReferrals=referralCount;
      console.log("totalReferrals",totalReferrals);
      if(!totalReferrals){
        totalReferrals=0;
      }


      let freeTrial=false;
      
      console.log("freeTrialUsed",freeTrialUsed)

      if((!freeTrialUsed || freeTrialUsed=== undefined) &&(!subscriptionActive) && !subscriptionHistory
        && country?.toLowerCase()=="nigeria"){
        freeTrial=true
      }

      let successRate=0;
      if(totalReferrals && successfullReferral &&(cashedoutReferrals<=totalReferrals)){
        successRate=(successfullReferral/totalReferrals)*100;
        successRate=Math.round(successRate);
      }

      let storeURL = `http://www.cashaam.com/${slug}`;
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        activated: typeof activated !== "undefined" ? activated : true,
        storeInfo: basicInfo,
        totalReferrals,
        totalCashedOut,
        cashedoutReferrals,
        successRate,
        successfullReferral,
        country: basicInfo?.country,
        freeTrial,
        referralList: sortedReferrals,
        potentialReward: parseFloat(potentialReward)
      });
      

      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  hideFreeTrial=()=>{
    this.setState({
      freeTrial: false
    })
  }

  startFreeTrial =()=>{

    this.setState({
      loadingSubscription: true
    })

    let {
      firstName, 
      lastName, 
      email,
      phoneNumber,
      id,
      slug}=this.state.storeInfo;

    let price=FREE_TRIAL_PRICE;
    //Convert to cents
    price=price*100;

    var handler = PaystackPop.setup({

      // plan: free_planId,
      subaccount: PAYSTACK_SUBACCOUNT,
      key: PAYSTACK_KEY,
      email,
      amount: price,
      currency: "NGN",
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: phoneNumber,
          },
        ],
        
        sellerId: id,
        slug,
        paymentMethod: "paystack",
        amount: price/100,
        currency: "NGN",
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
      },
      callback: (response) => {
        console.log("paystack Responce: ", response);
        console.log("success. transaction ref is " + response.reference);
        console.log("success. transaction ref is " + response.transaction);
        
        this.callFreeTrialAPI(email, id);
        // window.location.reload(false);

      },
      onClose: () => {
        this.setState({
          loadingSubscription: false
        })
        console.log("window closed");
      },
    });
    handler.openIframe();

  }

  disableFree= async(sellerId)=>{

    const db = firebase.firestore();
    const sellerRef = await db
      .collection("sellersAccounts")
      .doc(sellerId)
      .get();

    let sellerData = sellerRef.data();
    console.log("sellerData=>", sellerData);

    await db.collection("sellersAccounts").doc(sellerId).set(
      {
        freeTrialUsed: true,
        // subscriptionActive: true
      },
      { merge: true }
    );

  }

  callFreeTrialAPI= async (email, id)=>{

    try{
    this.setState({
      subscriptionSuccess: false
    })

    const queryData = {
      "email":email,
      "plan":  paystackMonthly,// Standard Monthly Plan - NOT FREE TRIAL
      "code":"CASHAAM30"

    };
    console.log("callFreeTrialAPI",queryData);
    const res = await fetch(  FREE_TRIAL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryData),
    });
    let response = await res?.json();
    console.log("response",response);

    // window.location.reload(false);

    if (res.status === 200) {

      this.setState({
        subscribed: true,
        subscriptionSuccess: true,
        loadingSubscription: false
      })

      this.disableFree(id);

    } else {
      console.log(" failed to create subscription");
      this.setState({
        subscribed: true,
        subscriptionSuccess: false,
        loadingSubscription: false
      })
    }

  }catch (error) {

    console.log(" failed to create subscription=>",error);
      this.setState({
        subscribed: true,
        subscriptionSuccess: false,
        loadingSubscription: false
      })
  }

  }

  loadFlutterwave = () => {
    let script = document.createElement("script");
    script.src = `https://checkout.flutterwave.com/v3.js`;
    script.addEventListener("load", () => {
        console.log("flutterwave loaded");
        this.setState({ flutterwaveLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPaystack =()=>{
    let script = document.createElement("script");
    script.src = "https://js.paystack.co/v1/inline.js";
    script.addEventListener("load", () => {
        console.log("paystack loaded");
        this.setState({ paystackLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPendo =() =>{

    let script = document.createElement("script");

    let pendoKey="6ff8873a-3edf-4dc1-6d19-5a3490191d2a"

    script.src='https://cdn.pendo.io/agent/static/'+pendoKey+'/pendo.js';
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);

  }

  onHide = () => {
    this.setState({
      displayFlutterwave: false,
      displayPaypal: false
    });
  };

  showFlutterwave=()=>{
    this.setState({
      displayFlutterwave: true
    })
  }

  showPaypal =()=>{
    this.setState({
      displayPaypal: true
    })
  }
  selectBank = (value) => {
    this.setState({ bankName: value });
  };

  transferWithPaypal=async ()=>{

    try{
      let {paypalPhone, 
        paypalEmail, 
        sellerId,
        successfullReferral,
        cashedoutReferrals,
      }=this.state;

      this.setState({
        errorPaypalEmail: "",
        errorPaypalPhone:"",
        loading: true
      })

      if(!paypalEmail){
        return this.setState({errorPaypalEmail:"Please enter your paypal email", loading: false});
      }

      if(!paypalPhone || paypalPhone?.length < 5){
        return this.setState({errorPaypalPhone:"Please enter your paypal phone number" ,loading: false});
      }

      let countrycode=paypalPhone.charAt(0);
      let phone=paypalPhone.substring(1);

      if(!successfullReferral){
        return this.setState({
          loading:false, 
          transferSuccess: false,
          userMessage:"You don't have any rewards to transfer. Make sure the friends that you have reffered are subscribed to get rewards.",
          successfullReferral:0
          })
      } 
      if(!cashedoutReferrals){
        cashedoutReferrals= 0;
      }
      let amount=(successfullReferral-cashedoutReferrals)*5;
      const query={
        customerId:sellerId,
        email:paypalEmail,
        countrycode,
        phone,
        amount,
        currency: "USD"
      }

      console.log("query",query);
      let res = await fetch(PAYPAL_TRANSFER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query),
      });

      if (res.status === 200) {
        console.log("status",200);
        const responce = await res.json();
        if(responce.error) {

          this.setState({
            loading:false, 
            transferSuccess: false,
            userMessage:responce.message,
            successfullReferral:0
          })
          console.log("Error with the api", responce.error);

          return;
        }

        let successMsg=`We have requested $${amount} to be sent to your account. Please expect it within 24 hours.`;
        this.setState({
          loading: false, 
          userMessage:responce.message,
          transferSuccess: true,
          successfullReferral:0
        })
        
      } else {
        this.setState({
          loading:false, 
          transferSuccess: false,
          userMessage:"There has been an error tranfering your rewards. Please refresh the page and try again.",
          successfullReferral:0
          })
        console.log("Error with the api", res);
      }
    }catch (error) {

      this.setState({
        loading:false,
        transferSuccess: false,
        userMessage:"There has been an error transfering your rewards. Please refresh the page and try again.",
        successfullReferral:0 
      })
      console.log("Error transfering money", error);

    }

  }

  transferWithFlutterwave =async ()=>{

    try{
      this.setState({
        errorAccount: "",
        errorBank:"",
        loading: true
      })
      let{bankNames,banks, accountNumber, bankName,sellerId,successfullReferral,cashedoutReferrals }=this.state;

      if(!accountNumber){
        return this.setState({errorAccount:"Please enter your bank account number" ,loading: false});
      }
      if(!bankName){
        return this.setState({errorBank:"Please select your bank from the dropdown menu", loading: false});
      }

      let bankCode=banks[bankName];
      console.log("bankCode",bankCode);

      if(!successfullReferral){
        return this.setState({
          loading:false, 
          transferSuccess: false,
          userMessage:"You don't have any rewards to transfer. Make sure the friends that you have reffered are subscribed to get rewards.",
          successfullReferral:0
          })
      } 
      if(!cashedoutReferrals){
        cashedoutReferrals= 0;
      }

      // let amount=((successfullReferral-cashedoutReferrals) * 5)*NAIRA_EXCHANGE;
      let amount=this.state.potentialReward-this.state.totalCashedOut;
      const query={ 
        customerId:sellerId,
        bankaccount:accountNumber,
        bankcode: bankCode,
        amount,
        completedreferral: successfullReferral,
        currency: "NGN"
      }
      console.log("query",query)

      const res = await fetch(FLUTTERWAVE_TRANSFER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query),
      });

      if (res.status === 200) {
        console.log("status",200);
        const responce = await res.json();
        if(responce.error) {

          this.setState({
            loading:false, 
            transferSuccess: false,
            userMessage:responce.message,
            successfullReferral:0
          })
          console.log("Error with the api", responce.error);

          return;
        }

        let successMsg=`We have requested ₦${amount} to be sent to your account. Please expect it within 24 hours.`;
        this.setState({
          loading: false, 
          userMessage:responce.message,
          transferSuccess: true,
          successfullReferral:0
        })
        
      } else {
        this.setState({loading:false, 
          transferSuccess: false,
          userMessage:"There has been an error tranfering your rewards. Please refresh the page and try again.",
          successfullReferral:0
          })
        console.log("Error with the api", res);
      }

    }catch(error){
      this.setState({loading:false,
        transferSuccess: false,
        userMessage:"There has been an error transfering your rewards. Please refresh the page and try again.",
        successfullReferral:0 
      })
      console.log("Error transfering money", error);
    }

  }

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData",{ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  async componentDidMount() {
    try{

      this.loadHubspot();
      let bankNames = Object.keys(BankData);
      let banks = {};
      bankNames.forEach((bankName) => {
        banks[bankName] = BankData[bankName].code;
      });
      this.setState({
        bankNames: bankNames,
        banks: banks
      });

      NProgress.start()
      
      // this.loadFlutterwave();
      this.loadPaystack();

      await this.loadPendo();


      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {

          if(this.state.pendoLoaded){
            console.log("initialize pendo")
            pendo.initialize({
              visitor: {
                  id: user.uid,   // Required if user is logged in
                  email: user.email,       // Recommended if using Pendo Feedback, or NPS Email
                  full_name: user.displayName,   // Recommended if using Pendo Feedback
                  role:  "seller" 
              },
      
              account: {
                  id: user.uid, 
                  planLevel:  "seller",
                  userType: "seller"
              }
            });

          }
          


          let shortReferral = user.uid?.slice(0, 6);
          shortReferral = shortReferral.toUpperCase();

          let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

          this.setState({
            shortReferral,
            referralLink,
            sellerId: user.uid,
          });
          localStorage.setItem("cashaam_uid", user.uid);
          this.getLocation();

          //check the user registration progress
          let step = await getRegistrationProgress(user.uid);

          step = parseFloat(step);
          if (step === 100) {
            // this.props.history.push("/seller/dashboard");
            console.log("completed registration step:", step);
          } else {
            console.log("MY STEP", step);
            if (step < 4) {
              this.props.history.push("/seller/dashboard/setup/onboarding");
            }
          }

          NProgress.set(0.5);
          const sellerUid = user.uid;
          let {creationTime, lastSignInTime}=user?.metadata;
        
          console.log("creationTime, lastSignInTime",{creationTime, lastSignInTime})
          let creationDate=new Date(creationTime);
          let lastSignInDate= new Date(lastSignInTime);

          console.log("creationDate",creationDate);
          console.log("lastSignInDate",lastSignInDate);

          let creationMonth = creationDate.getUTCMonth() + 1; //months from 1-12
          let creationDay = creationDate.getUTCDate();
          let creationYear = creationDate.getUTCFullYear();
          let creation=`${creationMonth}/${creationDay}/${creationYear}`;

          let lastSignedMonth = lastSignInDate.getUTCMonth() + 1; //months from 1-12
          let lastSignedDay = lastSignInDate.getUTCDate();
          let lastSignedYear = lastSignInDate.getUTCFullYear();

          let lastSigned=`${lastSignedMonth}/${lastSignedDay}/${lastSignedYear}`;

          console.log("lastSigned",lastSigned);
          console.log("creation",creation);


          // let eligibleFreeTrial = false;
          // if(creationTime&& lastSignInTime && lastSigned!==creation){


          //   eligibleFreeTrial = true; 
          // }
          await this.fetchSellerInfo(sellerUid);

          let shopperSideInfo = await getShopperInfo(user.uid);

          let rewards=shopperSideInfo?.rewards;

          this.setState({
            shopperSideInfo,
            shoppingPoints: rewards?.shoppingpoints? rewards?.shoppingpoints: 10,
            wallet: shopperSideInfo?.wallet,
            user
          })

          NProgress.done();
        } else {
          this.props.history.push("/");
        }
      });
    }catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Billing & Referral</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Chat" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>

        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />

        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            <div className="flexwrapper rewards-wrapper">
              <div className="rewards-page">
                <div className="account-container is-large">
                  <div className="section__title padded-title mb-48 mt-18 mt-md-48">
                    Billing & Referral
                  </div>

                  <SubscriptionInfo 
                   storeInfo={this.state.storeInfo}
                   activateSubscription={this.activateSubscription}
                   freeTrial={this.state.freeTrial}
                  />

                  {this.state.successPopup?
                    <SuccessSubscription 
                      hideSuccesMsg={this.hideSuccesMsg} 
                      subscriptionType={this.state.subscriptionType}
                      slug={this.state.storeInfo?.slug}
                      accountType={this.state.storeInfo?.accountType}
                      successType={this.state.successType}
                    />
                  :null}

                  <div className="section">
                    <div className="section__header" />
                    <div className="section__header mb-30 earnings-ch">
                      <div className="section__title padded-title">Referral System
                      </div>
                    </div>
                    
                    <UserReferral
                      shortReferral={this.state.shortReferral}
                      referralLink={this.state.referralLink}
                      copied={this.state.copied}
                      earnGold={this.earnGold}
                      copyReferral={this.copyReferral}
                    />
                    <SuccessKit />

                    <UserEarnings
                      successfullReferral={((this.state.successfullReferral-this.state.cashedoutReferrals)>0)?
                        this.state.successfullReferral-this.state.cashedoutReferrals:0}
                      rewardAmount={((this.state.successfullReferral-this.state.cashedoutReferrals)>0)?
                        (this.state.successfullReferral-this.state.cashedoutReferrals)*5:0}
                      nigeriaReward={this.state.potentialReward-this.state.totalCashedOut}
                      country={this.state.country}
                    />

                  {(this.state.country?.toLowerCase()!="nigeria" && (this.state.successfullReferral-this.state.cashedoutReferrals)>0)||
                  (this.state.country?.toLowerCase()=="nigeria" && (this.state.potentialReward-this.state.totalCashedOut)>0)?
                    <div 
                      className="card affiliate-code"
                      style={{marginTop: "60px"}}
                    >
                      <div className="card__body affiliate-code__body">
                        <div style={{marginBottom:"15px"}} className="section__title">Cashout your reward</div>

                        <div className="current-reward">
                        {this.state.country?.toLowerCase()!="nigeria"?
                          <div className="current-reward">
                          Cashout Amount: {`$${(this.state.successfullReferral-this.state.cashedoutReferrals)*5}`}
                          </div>
                          :
                          <div className="current-reward">
                          Cashout Amount: ₦{((this.state.potentialReward-this.state.totalCashedOut))?.toLocaleString("en-US")}
                          </div>
                          }
                        </div>
                        {this.state.country?.toLowerCase()=="nigeria"?
                          <CashoutOption
                            name={"Flutterwave"}
                            image="https://res.cloudinary.com/cashaam/image/upload/v1655832443/logos/flutterwave-removebg_tajv2j.webp"
                            showTransfer={this.showFlutterwave}
                          />
                        :
                        <CashoutOption
                        name={"Paypal"}
                        image="https://res.cloudinary.com/cashaam/image/upload/v1655834298/logos/paypal-logo-bg_nv0vaz.webp" 
                        showTransfer={this.showPaypal}
                        />
                      }



                      </div>
                    </div>
                  :null}

                    {/* {this.state.freeTrial?
                      <FreeTrial 
                      startFreeTrial={this.startFreeTrial}
                      hideFreeTrial={this.hideFreeTrial}
                      loadingSubscription={this.state.loadingSubscription}
                      subscribed={this.state.subscribed}
                      subscriptionSuccess={this.state.subscriptionSuccess}
                      />
                    :null} */}
                    {this.state.displayFlutterwave?
                      <FlutterwaveTransfer 

                        onHide={this.onHide}
                        handleChange={this.handleChange}
                        selectBank ={this.selectBank}

                        accountNumber={this.state.accountNumber}
                        bankName={this.state.bankName}
                        bankNames={this.state.bankNames}
                        banks={this.state.banks}
                        errorAccount={this.state.errorAccount}
                        errorBank={this.state.errorBank}
                        transferSuccess={this.state.transferSuccess}

                        onSubmit={this.transferWithFlutterwave}
                        
                        
                        errorPhone={this.state.errorPhone}
                        errorAmount={this.state.errorAmount}
                        userMessage={this.state.userMessage}
                        loadingContent={this.state.loading}
                        paymentSuccess={this.state.paymentSuccess}


                        
                      />
                    :null}

                    {this.state.displayPaypal?
                      <PaypalTransfer 

                        onHide={this.onHide}
                        handleChange={this.handleChange}
                        onSubmit={this.transferWithPaypal}
                        onPhoneChange={this.paypalPhoneChange}

                        paypalEmail={this.state.paypalEmail}
                        paypalPhone={this.state.paypalPhone}
                        

                        errorPhone={this.state.errorPaypalPhone}
                        errorEmail={this.state.errorPaypalEmail}
                        userMessage={this.state.userMessage}
                        loadingContent={this.state.loading}
                        paymentSuccess={this.state.paymentSuccess}
                      />
                    :null}

                    <UserAnalytics 
                    totalReferrals={this.state.totalReferrals?this.state.totalReferrals:0}
                    cashedoutReferrals={this.state.cashedoutReferrals}
                    totalCashedOut={this.state.totalCashedOut}
                    successRate={this.state.successRate}
                    successfullReferral={this.state.successfullReferral}
                    referralList={this.state.referralList}
                    />
                    <div id="recaptcha-container" />
                  </div>
                </div>
              </div>
            </div>

            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Reward;
