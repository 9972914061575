import React from 'react';

function MessageBox(props) {
    return (
        <div className="orderdetailsbox">
                  <div className="orderdetailstop">
                    <div>
                      You have 1 Message Credits - You can send a message to
                      your customer
                    </div>
                  </div>
                  <div className="orderdetailscontent">
                    <div className="w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        className="form-4"
                      >
                        <div className="formlabel">
                          Choose your order message
                        </div>
                        <input
                          type="text"
                          className="odtextfield w-input"
                          maxLength={256}
                          name="field-2"
                          data-name="Field 2"
                          placeholder="Example Text"
                          id="field-2"
                          required
                        />
                        <a
                          data-w-id="90c71399-d4d4-3298-5ab2-a47c155ecbda"
                          href="#"
                          className="messagecreditinfo w-inline-block"
                        >
                          <div className="text-block">
                            Configure predefined messages
                          </div>
                          <div className="rightarrow">➜</div>
                        </a>
                        <div className="formlabel">
                          <span className="text-span">*</span>Message
                        </div>
                        <textarea
                          placeholder="Example Text"
                          maxLength={5000}
                          id="field"
                          name="field"
                          data-name="field"
                          className="odtextarea w-input"
                          defaultValue={""}
                        />
                        <input
                          type="submit"
                          defaultValue="Send Message"
                          data-wait="Please wait..."
                          className="messagecreditsubmit w-button"
                        />
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    );
}

export default MessageBox;