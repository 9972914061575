import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../../../../firebaseConfig";
// import "../css/main.css";
// import "../css/cashaam-seller.css";
// import "../css/normalize.css";
import Lottie from "react-lottie";
import * as animation from "../../../../documents/cashaam-lottie.json";
import Metatags from "react-meta-tags";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import isSeller from "../../../helpers/isSeller";
import logoutHandler from "../../../helpers/logout";
import Loading from "../../../common/Loading";
import phone from "phone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./LoginPage";
export default class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      passwordResetSent: false,
      displayEmail: false,
      displayLoginWithPhone: true,
      showConfirmationCodeInput: false,
      phoneNumber: "",
      confirmationCode: "",
      loading: false,
    };
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePhoneInput = (phoneNumber) => {
    console.log(this.state.phoneNumber);
    this.setState({ phoneNumber });
  };

  displayEmailOnly = (value) => {
    this.setState({
      displayEmail: value,
    });
  };

  onDisplayLoginWithPhone = (value) => {
    this.setState({
      displayLoginWithPhone: value,
    });
  };

  displayConfirmationCodeInput = (value) => {
    this.setState({
      showConfirmationCodeInput: value,
    });
  };

  goBackToNormalLogin = () => {
    this.displayEmailOnly(true);
    this.onDisplayLoginWithPhone(false);
    this.displayConfirmationCodeInput(false);
  };

  confResult = null;

  submitConfResult = () => {
    this.displayLoading();
    // Loading(true, "Confirming code. Please wait...");
    const { phoneNumber } = this.state;
    const db = firebase.firestore();

    var code = this.state.confirmationCode;
    this.confResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        // var user = result.user;
        // console.log("user", user);
        var user = firebase.auth().currentUser;

        if (user.email === null) {
          this.hideLoading();
          // Loading(false);
          //   toast.error(
          //     "Invalid login credentials, Please sign up before loging in"
          //   );
          return user
            .delete()
            .then(() => {
              //   this.props.history.push("/seller/signup");
              toast.error(
                "Invalid login credentials, Please sign up before loging in"
              );
            })
            .catch((error) => {
              this.hideLoading();
              // Loading(false);
              console.log("error deleting user", error);
            });
        }

        const currUserIsSeller = await isSeller(user.uid);
        if (currUserIsSeller === true) {
          this.hideLoading();
          // Loading(false);
          this.props.history.push("/seller/dashboard");
        } else {
          // toast.error("Please create a seperate account to sell on Cashaam");
          // await logoutHandler();

          const userInfo = currUserIsSeller.userInfo;
          const db = firebase.firestore();
          await db.collection("sellersAccounts").doc(user.uid).set({
            id: userInfo.data().customerId,
            firstName: userInfo.data().firstName,
            lastName: userInfo.data().lastName,
            email: userInfo.data().email,
            phoneNumber: userInfo.data().phoneNumber,
          }, { merge: true });

          this.hideLoading();
          // Loading(false);
          this.props.history.push("/seller/dashboard");
          return;
        }
        if (user.emailVerified === false) {
          this.hideLoading();
          // Loading(false);
          toast.error("Please don't forget to verify your email");
        }
      })
      .catch((error) => {
        this.hideLoading();
        // Loading(false);
        // User couldn't sign in (bad verification code?)
        // ...
        if (error.code === "auth/invalid-verification-code") {
          toast.error("Wrong code, please check your phone and try again");
        }
        console.log("error confirming code", error);
      });
  };

  loginWithPhone = (e) => {
    this.displayLoading();
    // Loading(true, "Please wait...");
    e.preventDefault();
    const { phoneNumber } = this.state;
    var appVerifier = window.recaptchaVerifier;
    console.log("Number", `+${phoneNumber}`);
    // let eSixFourFormatNumberArray = phone(`+${phoneNumber}`);
    // console.log("e64 array", eSixFourFormatNumberArray);
    // let eSixFourFormatNumber = eSixFourFormatNumberArray[0];
    // console.log("e64", eSixFourFormatNumber);

    //special cases for development purposes
    // if(phoneNumber==="11000000000" || phoneNumber===""){

    // }

    firebase
      .auth()
      .signInWithPhoneNumber(`+${phoneNumber}`, appVerifier)
      .then(async (confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        // window.confirmationResult = confirmationResult;
        this.hideLoading();
        // Loading(false);
        this.setState({
          showConfirmationCodeInput: true,
        });
        this.confResult = confirmationResult;
      })
      .catch((error) => {
        this.hideLoading();
        // Loading(false);
        if (error.code === "auth/invalid-phone-number") {
          toast.error("invalid phone number format");
        }
        console.log(error);
        // Error; SMS not sent
        // ...
        // Or, if you haven't stored the widget ID:
        // window.recaptchaVerifier.render().then(function(widgetId) {
        //   grecaptcha.reset(widgetId);
        // });
      });
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  resetPassword = (e) => {
    e.preventDefault();
    this.displayLoading();
    this.setState({
      passResetError: ""
    })
    var auth = firebase.auth();
    var { email } = this.state;

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.hideLoading();
        console.log("reset email sent to:", email);
        // Email sent.
        this.setState({
          passwordResetSent: true,
        });
      })
      .catch((error) => {
        this.hideLoading();
        // An error happened.
        this.setState({
          passResetError: "There is no profile linked to this email. Please try again with another email."
        })
        console.log("error sending password reset email", error);
      });
  };

  onSubmitHandler = async (e) => {
    this.displayLoading();
    // Loading(true, "Please wait...");
    e.preventDefault();
    const { email, password } = this.state;
    // sign the user in with email and password
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      // once the user signs in direct them to seller dashboard
      .then(async () => {
        //check if user verified their email yet
        var user = firebase.auth().currentUser;

        const currUserIsSeller = await isSeller(user.uid);
        if (currUserIsSeller === true) {
          this.hideLoading();
          // Loading(false);
          this.props.history.push("/seller/dashboard");
        } else {
          // toast.error("Please create a seperate account to sell on Cashaam");
          // await logoutHandler();
          const userInfo = currUserIsSeller.userInfo;
          const db = firebase.firestore();
          await db.collection("sellersAccounts").doc(user.uid).set({
            id: userInfo.data().customerId,
            firstName: userInfo.data().firstName,
            lastName: userInfo.data().lastName,
            email: userInfo.data().email,
            phoneNumber: userInfo.data().phoneNumber,
          }, { merge: true });
          this.hideLoading();
          // Loading(false);
          this.props.history.push("/seller/dashboard");
          return;
        }
        if (user.emailVerified === false) {
          this.hideLoading();
          // Loading(false);
          toast.error("Please don't forget to verify your email");
        }
      })
      // else display error
      .catch((error) => {
        this.hideLoading();
        // Loading(false);
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        toast.error("Incorrect email or password");
        // Make it personalized
        // ...
      });
  };

  onResendVerificationCode = async () => {
    try {
      this.displayLoading();
      var appVerifier = window.recaptchaVerifier;
      const { phoneNumber } = this.state;

      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, appVerifier);

      this.hideLoading();
    } catch (error) {
      console.log("error resending verification code", error);
      this.hideLoading();

      if (error.code === "auth/too-many-requests") {
        return toast.error("Too many attempts. Please try again later");
      }

      return toast.error("error resending code");
    }
  };

  componentDidMount() {


    if(this.props.location.state){

      const {phoneLogin}=this.props.location.state
      console.log("State: ",phoneLogin)
      if(phoneLogin){
        this.onDisplayLoginWithPhone(true)
      }
    }

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
      }
    );
  }

  render() {
    return (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Sellers Login - Cashaam</title>
          <meta
            content="Cashaam Seller&#x27;s Login to manage the products and customers."
            name="description"
          />
          <meta content="Sellers Login -  Cashaam" property="og:title" />
          <meta
            content="Cashaam Seller&#x27;s Login to manage the products and customers."
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta
            name="keywords"
            content="Free Website,Free online,store, Lagos, Nigeria, Cashaam, Ship products, online stores, stores, custom links,instagram, facebook, sell online,online store, free online store"
          />
          <meta name="msapplication-TileColor" content="#dd4200" />
          <meta name="theme-color" content="#dd4200" />
        </Metatags>
        <ToastContainer />
        <LoginPage
          passResetError={this.state.passResetError}
          displayEmail={this.state.displayEmail}
          displayLoginWithPhone={this.state.displayLoginWithPhone}
          passwordResetSent={this.state.passwordResetSent}
          onSubmitHandler={this.onSubmitHandler}
          onChangeHandler={this.onChangeHandler}
          onDisplayLoginWithPhone={this.onDisplayLoginWithPhone}
          displayEmailOnly={this.displayEmailOnly}
          showConfirmationCodeInput={this.state.showConfirmationCodeInput}
          loginWithPhone={this.loginWithPhone}
          handlePhoneInput={this.handlePhoneInput}
          submitConfResult={this.submitConfResult}
          onResendVerificationCode={this.onResendVerificationCode}
          goBackToNormalLogin={this.goBackToNormalLogin}
          resetPassword={this.resetPassword}
          loading={this.state.loading}
        />
        <div id="recaptcha-container"></div>
      </>
    );
  }
}
