import React from "react";

const PickupPop = (props) => {
  return (
    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container">
        <div style={{ display: "block" }} className="pop-ng-pickup mc-pop-ng-pickup">
          <div className="pop-header">
            <div className="text-main">Order Processing</div>
            <a href="#" className="box-btn-primary w-inline-block">
              <div className="btn-text-primary-outline processed">
                Unprocessed
              </div>
            </a>
          </div>
          <div className="pop-content">
            <div className="sub-title">Scheduling your Pickup</div>
            <div className="pop-text bold">
              Before you schedule your pickup, here’s a few things to note.
            </div>
            <div className="pop-text">
              1. Ensure your Product is ready to be shipped before you schedule.
              <br />
              2. Your shipping Tracking number is activated once you click on
              schedule pickup and the pickup will be processed.
              <br />
              3. This process cannot be reversed and you will be charged.
              <br />
              4. Once your pickup is scheduled a tracking number will be
              displayed in the box below.{" "}
            </div>
          </div>
          <div className="pop-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="sub-title">Shipping Information</div>
              {!props.tracking_number ? <div onClick={() => props.showManualScheduleShip(true)} style={{ cursor: "pointer", width: "120px", padding: "2px 2px", }} className="btn-secondary">  <div style={{ fontSize: "14px" }} className="text-btn-tertiary">Edit Shipping Info</div></div> : null}
            </div>
            <div className="pop-text bold">
            </div>

            <div className="pop-text">
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}> Transaction Id:</span> <span style={{ paddingLeft: "5px" }}>{props.order.transactionId}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}> Departure:</span> <span style={{ paddingLeft: "5px" }}>{props.order.location}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}> Destination: </span><span style={{ paddingLeft: "5px" }}>{` ${props.order.street}, ${props.order.apt && props.order.apt !== "N/A" ? props.order.apt : ""} ${props.order.city}, ${props.order.state}, ${props.order.country}`}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}>  Recipient:</span> <span style={{ paddingLeft: "5px" }}>{props.order.customerName}</span>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}>  Customer Phone:</span> <a href={`tel:+${props.order.deliveryPhoneNumber}`} style={{ paddingLeft: "5px" }}>{props.order.deliveryPhoneNumber}</a>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ fontWeight: "bold", minWidth: "80px" }}>  Customer Email:</span> <a href={`mailto:${props.order.email}`} style={{ paddingLeft: "5px" }}>{props.order.email}</a>
              </div>
            </div>
          </div>
          {props.loading || props.tracking_number || props.errorMessage ?
            <div style={{ borderColor: props.errorMessage ? "#ff8c00" : "#9a8def", borderWidth: props.errorMessage ? "2px" : "1px", fontSize: "14px" }} className="tracking-box">
              {props.errorMessage ? <div>{props.errorMessage} or <a target="_blank" href="https://support.cashaam.com">contact us</a></div> : null}

              {props.loading === true ? (
                <>Please wait...</>
              ) : props.tracking_number ? (
                <>
                  <div className="tracking-title">
                    Your Schedule Pickup request has been received. Below is your
                    Waybill Number for your tracking.
                </div>
                  <div style={{ minHeight: "30px" }} className="tracking-number">
                    {props.tracking_number}
                  </div>
                  {props.sellerPickup === false ? (
                    <div
                      style={{ fontWeight: "bold", paddingBottom: "10px" }}
                      className="tracking-info"
                    >
                      {" "}
                      <span style={{ color: "red", marginRight: "5px" }}>
                        Note:
                    </span>
                    Pleae drop off the item at the following location:{" "}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://maps.google.com/?q=${props.sellerPickupAddress}`}
                      >
                        {" "}
                        {props.sellerPickupAddress}{" "}
                      </a>
                    </div>
                  ) : null}
                  {props.buyerPickup === false ? (
                    <div
                      style={{ fontWeight: "bold", paddingBottom: "10px" }}
                      className="tracking-info"
                    >
                      {" "}
                      <span style={{ color: "red", marginRight: "5px" }}>
                        Note:
                    </span>
                    Your customer will pick up the item at the following
                    location:{" "}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`https://maps.google.com/?q=${props.sellerPickupAddress}`}
                      >
                        {" "}
                        {props.buyerPickupAddress}{" "}
                      </a>
                    </div>
                  ) : null}
                  <div className="tracking-info">
                    {props.shippingProvider && props.shippingProvider.includes("GOKADA") ?
                      `If you are not contacted by GoKada within 24 hours, please call -
(+234) 802 726 1647 or email - support@gokada.ng with your
waybill number.`
                      :
                      `If you are not contacted by GIG within 24 hours, please call -
                    (+234)813 985 1120 or email - info@giglogistics.com with your
                    waybill number.`}
                  </div>{" "}
                </>
              ) : null}
            </div> : null}
          <div className="pop-footer">
            {props.tracking_number ? (
              <div
                style={{ backgroundColor: "green", backgroundImage: "none" }}
                className="btn-primary"
              >
                <div className="text-btn-primary">Pickup Scheduled</div>
              </div>
            ) : props.loading === false ? (
              <div
                onClick={() => props.completeSchedulePickup()}
                style={{ cursor: "pointer" }}
                className="btn-primary"
              >
                <div className="text-btn-primary">Schedule Pickup</div>
              </div>
            ) : (
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundImage: "none",
                      backgroundColor: "grey",
                    }}
                    className="btn-primary"
                  >
                    <div className="text-btn-primary">Scheduling...</div>
                  </div>
                )}
            <div
              onClick={() => props.markAsFullFilled(props.order.orderId, props.order.customerId)}
              style={{ cursor: "pointer" }}
              className="btn-primary"
            >
              <div className="text-btn-primary">{`Mark as Delivered (Self Delivery)`}</div>
            </div>
            {props.loading === false ? (
              <div
                onClick={() => props.cancelSchedulePickup()}
                style={{ cursor: "pointer" }}
                className="btn-secondary"
              >
                {props.tracking_number ? (
                  <div className="text-btn-tertiary">Done</div>
                ) : (
                    <div className="text-btn-tertiary">Cancel</div>
                  )}
              </div>
            ) : null}


          </div>
        </div>
      </div>
    </div>
  );
};
export default PickupPop;
