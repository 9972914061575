import React, {useState } from "react";
import ReferralTable from "./ReferralTable";

function UserAnalytics(props) {

  const [breakDown, setBreakDown] = useState(false);
  const showBreakDown=()=>{

    setBreakDown(!breakDown);
  }

  return (
    <div className="section mt-60">
      <div style={{marginBottom:"0px"}} className="section__header mb-30 analytics-ch">
        <div className="section__title padded-title">Referral History</div>
      </div>

      <div style={{cursor: "pointer"}} onClick={showBreakDown} className="payment-info">
                What does the referral status mean
                <i className="fd-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                        <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                        <circle cx="7.9" cy="11.4" r="0.7" />
                      </svg>
                    </i>
                    
              </div>


            {breakDown?
              <hgroup className="speech-bubble">
                <div className="breakdown-info"><b>Inactive Status:</b> The user you referred is yet to subscribe.
                <br />
                <br /><b>Active Status: </b> The user you referred subscribed.
                <br/>
                </div>
              </hgroup>
              :null
            }

      <div className="affiliate-stats">
        <div className="affiliate-stats__wrapper">
          {/* <div className="affiliate-stats__left">
            <div className="affiliate-card">
              <div className="affiliate-card__body">
                <div className="affiliate-card__title is-large">
                  Conversions
                </div>
                <div className="affiliate-card__chart">
                  <div className="percentage-circle">
                    <svg
                      viewBox="0 0 100 100"
                      className="percentage-circle__svg"
                    >
                      <path
                        className="percentage-circle__rail"
                        d="M 50,50 m 0,-42.5
     a 42.5,42.5 0 1 1 0,85
     a 42.5,42.5 0 1 1 0,-85"
                        strokeWidth={5}
                        style={{
                          fill: "none",
                          strokeWidth: 5,
                          strokeDasharray: "267.035px, 267.035px",
                          stroke: "rgb(235, 235, 235)",
                        }}
                      />
                      <path
                        className="percentage-circle__track"
                        d="M 50,50 m 0,-42.5
     a 42.5,42.5 0 1 1 0,85
     a 42.5,42.5 0 1 1 0,-85"
                        style={{
                          transformOrigin: "center center",
                          fill: "none",
                          strokeWidth: 5,
                          strokeLinecap: "butt",
                          transform: "rotate(0deg)",
                          strokeDasharray: "0px, 267.035px",
                        }}
                      />
                    </svg>
                    <div className="percentage-circle__children">
                      <div className="affiliate-card__chart__text">{props.successRate}%</div>
                    </div>
                  </div>
                </div>
                <div className="affiliate-card__description mt-72">
                  <span>
                    Share your affiliate link <br /> above to start making{" "}
                    <br /> passive income!
                  </span>
                </div>
              </div>
            </div>
          </div> */}


          {props.referralList && props.referralList?.length>0?
          <div className="affiliate-stats__left">
            <div style={{paddingBottom:"40px"}} className="affiliate-card">
                    {/* <div className="affiliate-card_title">Referrals & Rewards</div> */}
              <div style={{height: "500px", overflow:"scroll",marginTop: "40px", paddingTop: "0px"}} className="affiliate-card__body">

                <ReferralTable 
                  referralList={props.referralList}
                  cashedoutReferrals={props.cashedoutReferrals}
                />
              </div>
              <div className="reward_info">Your referred user must be subscribed for you to be paid</div>
            </div>
          </div>
          :null}
          <div className="affiliate-stats__right">
            <div className="affiliate-stats__right__item">
              <div className="affiliate-card is-compact">
                <div className="affiliate-card__body">
                  <div className="affiliate-card__title has-tooltip">
                    Total Referrals
                    {/* <i className="fd-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                        <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                        <circle cx="7.9" cy="11.4" r="0.7" />
                      </svg>
                    </i> */}
                  </div>
                  <div className="affiliate-card__value">{props.totalReferrals}</div>
                </div>
              </div>
            </div>
            <div className="affiliate-stats__right__item">
              <div className="affiliate-card is-compact">
                <div className="affiliate-card__body">
                  <div className="affiliate-card__title has-tooltip">
                    Unsubscribed Referrals
                    {/* <i className="fd-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                        <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                        <circle cx="7.9" cy="11.4" r="0.7" />
                      </svg>
                    </i> */}
                  </div>
                  {props.successfullReferral>props.totalReferrals?
                   <div className="affiliate-card__value">0</div>
                   :
                    <div className="affiliate-card__value">{props.totalReferrals-props.successfullReferral}</div>
                  }
                 
                </div>
              </div>
            </div>
            <div className="affiliate-stats__right__item">
              <div className="affiliate-card is-compact">
                <div className="affiliate-card__body">
                  <div className="affiliate-card__title has-tooltip">
                    Paid Out
                    {/* <i className="fd-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                      >
                        <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                        <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                        <circle cx="7.9" cy="11.4" r="0.7" />
                      </svg>
                    </i> */}
                  </div>
                  <div className="affiliate-card__value">{props.cashedoutReferrals}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAnalytics;
