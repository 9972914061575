import firebase from "../../firebaseConfig";

const saveAboutStore = (id, storeName, description,slug) => {
  return new Promise((resolve, reject) => {
    try {
      const db = firebase.firestore();
      db.collection("sellersAccounts").doc(id).set(
        {
          storeName,
          description,
          slug
        },
        { merge: true }
      );

      resolve("success");
    } catch (error) {
      console.log("error saving about store info", error);
      reject(error);
    }
  });
};
export default saveAboutStore;
