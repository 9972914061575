const formatDate = (date) => {
    //Ex: 2020-9-28 23:42:3 ==> 2020-09-28T23:42:03 *This is necessary because of Safari*
    let dateArray = date.split(" ");
    let dateComponents = dateArray[0];
    let timeComponents = dateArray[1];

    dateComponents = dateComponents.split("-");
    timeComponents = timeComponents.split(":");

    let year = dateComponents[0];
    let month = dateComponents[1]
    let day = dateComponents[2];
    let hour = timeComponents[0];
    let min = timeComponents[1];
    let sec = timeComponents[2];

    if (year.length < 4) {
        year = "20" + year;
    }

    if (month.length < 2) {
        month = "0" + month;
    }

    if (day.length < 2) {
        day = "0" + day;
    }

    if (hour.length < 2) {
        hour = "0" + hour;
    }

    if (min.length < 2) {
        min = "0" + min;
    }

    if (sec.length < 2) {
        sec = "0" + sec;
    }

    let formatedDate = `${year}-${month}-${day}T${hour}:${min}:${sec}`;
    return formatedDate;
}
export default formatDate;