import React, { Component } from 'react'

export default class HowItWorks extends Component {
    render() {
        return (
            <div>
                <h1>How It Works?</h1>
            </div>
        )
    }
}
