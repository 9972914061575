import React from "react";
import { Link } from "react-router-dom";
import paymentCashaam from "../../images/payment_cashaam.svg";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";

const BvnInfo = (props) => {
  return (
    <>
      <div className="section-onboarding">
        <div className="onboarding-steps">
          <div className="block-title-1">
            <h4 className="h4">Validate Your Account</h4>
          </div>
          <div className="microcopy-signup">
            We want to ensure that your account information is correct and
            confirm it belongs to you.
            {/*. Central Bank regulations require all bank
            account numbers to be validated with Bank Verification Numbers (BVN)
            before funds can be transferred to your account. */}
          </div>
          <div className="holder-guide bvn">
            <div className="microcopy-signup bvn">
              How do we use your BVN for validation?
              <br />‍<br />
              1. To verify your name.
              <br />
              2. To verify your phone number.
              <br />
              3. To verify you own the bank account.
            </div>
            <div className="info-box">
              <div className="bvn-info">
                Your BVN does not provide access to your Bank Account or
                transaction history and is{" "}
                <span className="highlight">
                  only used to verify your identity.
                </span>
              </div>
            </div>
          </div>
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                onSubmit={props.handleSubmit}
                id="email-form"
                name="email-form"
                data-name="Email Form"
              >
                
                {props.bvnError? <div class= "error-message">{props.bvnError}</div>: null}
                <input
                  onChange={props.handleChange}
                  type="text"
                  className={props.bvnError? "input-text w-input input-glow input-error" : "input-text input-glow w-input"}
                  maxLength={256}
                  name="bvn"
                  data-name="bvn"
                  placeholder="Enter your 11 digits BVN"
                  id="bvn"
                  required
                />
                {props.loading === false ? (
                  <input
                    type="submit"
                    defaultValue="Validate my Account"
                    data-wait="Please wait..."
                    className="submit-primary w-button"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="submit-button w-button"
                  >
                    <div
                      style={{ borderTopColor: "#6740aa" }}
                      className="loader"
                    ></div>
                  </div>
                )}
              </form>



              {/* <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div> */}
              {/* {props.submitFailed === true ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>
                    The information your provided could not be verified. Please,
                    check and try again.
                  </div>
                </div>
              ) : null} */}
            </div>
            <div
              onClick={props.goBack}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Go Back
            </div>
          </div>
        </div>
      </div>
      <Link to="/" className="logo-black w-inline-block">
        <img
          src={logo}
          // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
          sizes="(max-width: 767px) 80px, 100px"
          alt
          className="cashaam-logo-dark"
        />
        <img
          src={logoWhite}
          // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
          sizes="100vw"
          alt
          className="cashaam-logo-white"
        />
      </Link>
    </>
  );
};
export default BvnInfo;
