import firebase from "../../firebaseConfig";
import shortid from "shortid";

const saveProductToDatabase = (productInfo) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;
      // timeStamp of the time the post was added
      const lastUpdate = new Date().toLocaleString();
      // check if the collection document's id we are searching for is the same as the currentUser id
      // generate 8 digit uniq sku
      var sku = shortid.generate();
      var docRef = "";

      console.log("PROD ID", productInfo.productId);

      if (productInfo.productId) {
        docRef = db
          .collection("sellersAccounts")
          .doc(sellerUid)
          .collection("stores")
          .doc(sellerUid)
          .collection("products")
          .doc(productInfo.productId);
      } else {
        docRef = db
          .collection("sellersAccounts")
          .doc(sellerUid)
          .collection("stores")
          .doc(sellerUid)
          .collection("products")
          .doc();
      }

      const {
        productName,
        productDescription,
        productPrice,
        productStock,
        productVariant,
        productWeight,
        productHeight,
        productWidth,
        productLength,
        productCategory,
        sellerFullName,
        currency,
        storeName,
        discount,
        discountedPrice,
        location,
        likes,
        slug,
        featuredImageURL,
        productImagesURL,
        catalogs,
      } = productInfo;
      console.log("PROD INFO", productInfo);
      const currentUserUid = firebase.auth().currentUser.uid;

      await docRef.set({
        sellerId: currentUserUid,
        sku: sku,
        sellerFullName: sellerFullName,
        featuredImage: featuredImageURL,
        productImages: productImagesURL,
        productName: productName,
        productDescription: productDescription,
        productPrice: productPrice,
        productStock: productStock,
        productVariant: productVariant,
        productWeight: productWeight,
        productCategory: productCategory,
        id: docRef.id,
        currency: currency,
        storeName: storeName,
        discount: discount,
        discountedPrice: discountedPrice,
        location: location,
        lastUpdate: lastUpdate,
        likes: likes,
        slug,
        productHeight,
        productWidth,
        productLength,
        catalogs,
      });
      // .then(async () => {
      // save the product data in collection 'allProducts'
      var docRefAllProducts = db.collection("allProducts").doc(docRef.id);

      // const currentUserUid = firebase.auth().currentUser.uid;
      await docRefAllProducts.set({
        sellerId: currentUserUid,
        sku: sku,
        sellerFullName: sellerFullName,
        featuredImage: featuredImageURL,
        productImages: productImagesURL,
        productName: productName,
        productDescription: productDescription,
        productPrice: productPrice,
        productStock: productStock,
        productVariant: productVariant,
        productWeight: productWeight,
        productCategory: productCategory,
        id: docRef.id,
        currency: currency,
        storeName: storeName,
        discount: discount,
        discountedPrice: discountedPrice,
        location: location,
        lastUpdate: lastUpdate,
        likes: likes,
        slug,
        productHeight,
        productWidth,
        productLength,
        catalogs,
      });
      // })
      // .then(() => {
      resolve("success");
      // });
    } catch (error) {
      console.log("error saving product", error);
      reject(error);
    }
  });
};

export default saveProductToDatabase;
