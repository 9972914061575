import React from "react";

const ConfirmCode = (props) => {
  return (
    <form
      id="email-form"
      name="email-form"
      data-name="Email Form"
      className="form"
      // onSubmit={props.handleSubmit}
      onSubmit={e => e.preventDefault()}
    >
      <input
        onChange={props.onChangeHandler}
        type="text"
        className="input-text w-input"
        id="single-factor-code-text-field" 
        autocomplete="one-time-code"
        maxLength={256}
        name="confirmationCode"
        data-name="confirmationCode"
        placeholder="e.g: 123456 - Confirmation Code"
        // id="confirmationCode"
        required
      />
      {props.loading === false ? (
        <input
          onClick={() => props.submitConfResult()}
          type="button"
          value="Confirm"
          defaultValue="Login"
          data-wait="Please wait..."
          className="submit-button w-button"
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="submit-button w-button"
        >
          <div style={{ borderTopColor: "#6740aa" }} className="loader"></div>
        </div>
      )}
      {props.loading === false ? (
        <input
          onClick={() => props.goBackToNormalLogin()}
          style={{ marginTop: "8px", backgroundColor: "grey" }}
          type="button"
          defaultValue="Try another way"
          data-wait="Please wait..."
          className="submit-button w-button"
        />
      ) : null}
    </form>
  );
};
export default ConfirmCode;
