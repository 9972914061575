import React, { Component } from "react";
import Avatar from "../chatList/Avatar";

export default class ChatItem extends Component {
  state = {

    showTimeStamp:false
  }

  toggleTime =()=>{

    console.log('toggleTime')
    
    this.setState({
      showTimeStamp: !this.state.showTimeStamp
    });
  }

  render() {
    // console.log("creastedAt",this.props.createdAt.toDate())
    return (
      
      <div
        style={{ animationDelay: `0.8s` }}
        className={`chat__item ${this.props.user ? this.props.user : ""}`}
        onClick={this.toggleTime}
      >
        <div className="chat__item__content">
          <div className="chat__msg">{this.props.msg}</div>
          <div className={`chat__meta ${this.state.showTimeStamp===true? "show-time":""}`}>
            <span>{this.props.createdAt?.toDate().toDateString()}, &nbsp;</span>
            <span>{this.props.createdAt?.toDate().toLocaleTimeString('en-US')}</span>
          </div>
        </div>
        {/* <Avatar isOnline="active" image={this.props.image} /> */}
      </div>
    );
  }
}
