import firebase from "../../firebaseConfig";

const getParcelFromTransaction = (transactionId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      // const result = await db
      //   .collection("shipping")
      //   .doc(shippingObjectId)
      //   .get();
      const result = await db
      .collection("shipping")
      .where("transactionId", "==", transactionId)
      .get();

      if (result.empty) {
        console.log("could not find shipping doc for transaction")

        resolve(null);
      }else{
        console.log("doc found");
        const { shipment } = result.docs[0].data();
        console.log("shipment", shipment);

        let parcel = {};

        //for some reason, shipment object changed after cashaam 2.0 revamp
        //temporary solution until we figure out where the cause of the change
        if (shipment.shippingObject.parcels) {
          parcel = shipment.shippingObject.parcels[0];
        }else if(shipment.nigeriaShippingInfo){
          parcel = shipment.nigeriaShippingInfo.parcels[0];

        }else {
          parcel = shipment.shippingObject[0].shippingObject.parcels[0];

        }
        resolve(parcel);

      }
    } catch (error) {
      console.log("error getting parcel from transaction", error);
      reject(error);
    }
  });
};
export default getParcelFromTransaction;
