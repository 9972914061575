import React from "react";
import MessageHolder from "./MessageHolder";
import ChatWindow from "./ChatWindow";

const BotChat = (props) => (
  <div
    style={{ display: "block" }}
    data-w-tab="Bot Chats"
    className="w-tab-pane"
  >
    {props.messages && props.messages.length > 0 ? (
      <div className="chat-customer">
        <div className="page-title">
          <h3 className="title-chats">Chats - Bots</h3>
          <div className="page-description">
            Your chat conversations with Cashaam Bot
          </div>
        </div>
        <div className="chat-holder">
          <div className="chat-list">
            <MessageHolder
              name="Cashaam Bot"
              date={
                props.messages[0]
                  ? props.messages[0].timestamp.toDate().toString().slice(0, 16)
                  : ""
              }
              active={true}
              lastMessage={props.messages[0] ? props.messages[0].content : ""}
            />
          </div>
          <ChatWindow
            userName="Cashaam - Bot"
            storeName={props.storeName}
            phoneNumber={props.phoneNumber}
            messages={props.messages}
          />
        </div>
      </div>
    ) : (
      <div className="chat-customer">
        <div className="page-title">
          <h3 className="title-chats">Chats - Bots</h3>
          <div className="page-description">
            You haven't started any chats with the Cashaam Chat Bot yet.
          </div>
        </div>
      </div>
    )}
  </div>
);
export default BotChat;
