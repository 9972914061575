import React, { Component } from "react";
import CustomerDetails from "../../../common/CustomerDetails";
import MessageBox from "../../../common/MessageBox";
import ProductDetails from "../../../common/ProductDetails";
import TransactionControls from "../../../common/TransactionControls";
import TransactionTopBar from "../../../common/TransactionTopBar";

import getParcelFromTransaction from "../../../helpers/getParcelFromTransaction";

import printerLogo from "../../../../images/352513_local_print_shop_icon.svg";

class TransactionDetails extends Component {
  state = {
    orderList: [],
  };

  getParcel = async(transactionId)=>{

    try{

      
      const parcel = await getParcelFromTransaction(transactionId);

      console.log("transaction parcel",parcel)
      let productWeight;

      if (!parcel || typeof parcel === "undefined") {

        productWeight=2.00;
      }else{
        productWeight=parcel.weight?parcel.weight:2.00;
      }

      return productWeight;
  }catch (error) {
    console.log("error getting parcel",error);
    return 2.00;
  }

  }



  async componentDidMount() {

    try{
    let currentTransaction = this.props.currentTransaction;

    let orderList = [];
    orderList = currentTransaction?.orderList;
    let paymentMethod = currentTransaction?.paymentMethod;
    let purchasedDate = currentTransaction?.date;

    let shippingCarrierAccount = currentTransaction?.shippingCarrierAccount;

    let transactionId=currentTransaction?.transactionId;
    let transactionStatus=currentTransaction?.orderList[0]?.orderStatus;

    let discountUsed=null;
    if(currentTransaction?.discountUsed){
      discountUsed=currentTransaction?.discountUsed;
    }else{
      discountUsed={
        discountValue:"N/A",
        discountType: "N/A",
        discountCode:"N/A",
      };
    }

    if(transactionStatus?.toLowerCase()==="fulfilled"){
      transactionStatus="Delivered";
    }else if(transactionStatus?.toLowerCase()==="completed"){
      transactionStatus="New";
    }
    transactionStatus=transactionStatus.charAt(0).toUpperCase() + transactionStatus.slice(1);


    //this.calculatePayment(currentTransaction);
    let transactionObject=currentTransaction?.transactionObject;
    let {
      transactionAmount,
      applicationFeeAmount,
      taxes,
      shippingPrice,
      transactionCurrency,
      paymentStatus,
      taxAndFee,
      sellerPayout,
      payoutCurrency
    }=transactionObject;
    transactionAmount=parseFloat(transactionAmount);
    applicationFeeAmount=parseFloat(applicationFeeAmount);
    taxes=parseFloat(taxes);
    shippingPrice=parseFloat(shippingPrice);
    taxAndFee=parseFloat(taxAndFee);
    sellerPayout=parseFloat(sellerPayout);

    let shippingObjectId=currentTransaction?.shippingObjectId;

    let productWeight = await this.getParcel(transactionId);



    let customerName,
      customerEmail,
      shippingStreet,
      shippingState,
      shippingCity,
      shippingCountry,
      shippingZip,
      shippingApt,
      customerPhone;
    if (orderList.length > 0) {
      let order = orderList[0];

      shippingStreet = order?.street;

      if(shippingStreet){
        shippingStreet=shippingStreet.replace('N/A', '');
      }
      shippingCity = order?.city;
      shippingState = order?.state;
      shippingCountry = order?.country;
      shippingZip = order?.zip;
      shippingApt = order?.apt;
      customerName=order.customerName;
      customerEmail=order.email;
      customerPhone=order.deliveryPhoneNumber
    }
    paymentMethod=paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1);

    this.setState({
      orderList,
      transactionId,
      paymentMethod,
      shippingStreet,
      shippingState,
      shippingCity,
      shippingCountry,
      shippingZip,
      shippingApt,
      customerName,
      customerEmail,
      purchasedDate,
      customerPhone,
      shippingCarrierAccount,
      productWeight,
      transactionStatus,
      transactionAmount,
      applicationFeeAmount,
      taxes,
      shippingPrice,
      transactionCurrency,
      paymentStatus,
      taxAndFee,
      sellerPayout,
      payoutCurrency,
      discountUsed
    });

  }catch(err) {
    console.error("error loading transaction info",err);
  }
  }

  render() {
    return (
      <div className="flexwrapper">
        <div
          data-w-id="bfad64a3-0238-4117-d841-c2ef24e65944"
          style={{ display: "none" }}
          className="menucover"
        />
        <div className="orderdetailswrapper">
          <TransactionTopBar 
            backToTransactions={this.props.backToTransactions}
            transactionAmount={this.state.transactionAmount}
            transactionCurrency={this.state.transactionCurrency}
            transactionStatus={this.state.transactionStatus}
            customerName={this.state.customerName}
            purchasedDate={this.state.purchasedDate}
            transactionId={this.state.transactionId}
            sellerCountry={this.props.sellerCountry}
            slug={this.props.slug}
            notification={this.props.notification}
          />
          <div className="odsection">
            <div className="odcontainer transparent nopadding">
              <div className="odrow justifybetween">
                <div className="row mobileverticalcenter">
                  <div className="paymentbutton">
                    <div>Payment via {this.state.paymentMethod}</div>
                  </div>
                  <div
                    data-hover="false"
                    data-delay={0}
                    className="updatestatusbutton lightseagreen withmargin w-dropdown"
                    
                  >
                    <a className="dropdown-toggle w-dropdown-toggle" href="#trans-control">
                      <div className="w-icon-dropdown-toggle" />
                      <div>{this.state.transactionStatus}</div>
                    </a>
                    <nav className="dropdown-list-2 w-dropdown-list">
                      <a href="#trans-control" className="updatestatusitem w-dropdown-link">
                        Link 1
                      </a>
                      <a href="#" className="updatestatusitem w-dropdown-link">
                        Link 2
                      </a>
                      <a href="#" className="updatestatusitem w-dropdown-link">
                        Link 3
                      </a>
                    </nav>
                  </div>
                  {/* <a href="#" className="printorderbutton w-inline-block gray-out">
                    <img
                      src={printerLogo}
                      loading="lazy"
                      width={24}
                      alt
                      className="image-2"
                    />
                    <div>Print Order</div>
                  </a>
                  <a href="#" className="scheduledeliverybutton w-inline-block gray-out">
                    <div>Schedule Delivery</div>
                  </a> */}
                </div>
                <div className="row lr-arrow">
                  <a href="#" className="odpag leftodpag w-inline-block" />
                  <a href="#" className="odpag rightodpag w-inline-block" />
                </div>
              </div>
            </div>
          </div>
          <div className="odsection">
            <div className="odgrid">
              <div>
                <CustomerDetails 
                shippingStreet={this.state.shippingStreet}
                shippingState={this.state.shippingState}
                shippingCity={this.state.shippingCity}
                shippingCountry={this.state.shippingCountry}
                shippingZip={this.state.shippingZip}
                shippingApt={this.state.shippingApt}
                customerName={this.state.customerName}
                customerEmail={this.state.customerEmail}
                purchasedDate={this.state.purchasedDate}
                customerPhone={this.state.customerPhone}
                />
                {/* <MessageBox /> */}
              </div>
              <div>
                <div>
                  <ProductDetails 
                    getCorrectShippingType={this.props.getCorrectShippingType}
                    shippingCarrierAccount={this.state.shippingCarrierAccount}
                    orderList={this.state.orderList}
                    transactionAmount={this.state.transactionAmount}
                    transactionCurrency={this.state.transactionCurrency}
                    transactionStatus={this.state.transactionStatus}
                    taxAndFee={this.state.taxAndFee}
                    shippingPrice={this.state.shippingPrice}
                    discountUsed={this.state.discountUsed}
                  />
                  <TransactionControls 
                  markAsShipped={this.props.markAsShipped}
                  markAsDelivered={this.props.markAsDelivered}
                  getCarrierName={this.props.getCarrierName}
                  displayShippingLabel={this.props.displayShippingLabel}
                  transactionId={this.state.transactionId}
                  sellerId={this.props.currentTransaction?.sellerId}
                  orderList={this.props.currentTransaction?.orderList}
                  orderIds={this.props.currentTransaction?.orderIds}
                  currentTransaction={this.props.currentTransaction}
                  productWeight={this.state.productWeight}
                  transactionCurrency={this.state.transactionCurrency}
                  sellerPayout={this.state.sellerPayout}
                  shippingPrice={this.state.shippingPrice}
                  customerEmail={this.state.customerEmail}
                  showScheduleDelivery={this.props.showScheduleDelivery}
                  schedulingError={this.props.schedulingError}
                  scheduleLoading={this.props.scheduleLoading}
                  sellerCountry={this.props.sellerCountry}
                  showShippingInstructions={this.props.showShippingInstructions}
                  showDeliveryInfo={this.props.showDeliveryInfo}
                  payoutCurrency={this.state.payoutCurrency}
                  storeName={this.props.sellerInfo?.storeName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransactionDetails;
