import React from 'react';
import priceFormater from '../../../helpers/priceFormater';
const Customer = (props) => {
    let date = "date"
    console.log("customer prop", props);
    return (
        <div className="order-block">
            <div className="mc-box-date-big box-date half-box">
                <div className="box-value">{!props.firstName?.includes("N/A") ? props.firstName : null} {!props.lastName?.includes("N/A") ? props.lastName : null}</div>

                <div style={{ paddingTop: "10px" }} className="box-title">{props.city},{" "}{props.state}</div>
            </div>
            <div className="mc-box-date-big box-date half-box">
                <div className="box-value">
                    {props.email}
                </div>
                {props.phoneNumber ? <div style={{ paddingTop: "10px" }} className="box-title">{props.customer.phoneNumber}</div> : <div style={{ paddingTop: "10px", color: "transparent" }} className="box-title">filler</div>}

            </div>
            <div className="mc-box-date box-date">
                <div style={{ paddingLeft: "10px" }} className="box-value">
                    {parseInt(props.orderCount) > 1 ? `${props.orderCount} orders` : `${props.orderCount} order`}
                </div>
                {props.totalAmountSpent ? <div style={{ paddingTop: "10px", paddingLeft: "10px" }} className="box-title">{priceFormater(props.totalAmountSpent, props.currency)}</div> : <div style={{ paddingTop: "10px", paddingLeft: "10px", color: "transparent" }} className="box-title">filler</div>}

            </div>
            <div className="mc-box-date box-date half-box">
                {props.subscribed === true ?
                    <a
                        className="box-btn-primary w-inline-block"
                    >
                        <div style={{ borderRadius: "50px", backgroundColor: "#dad8ff", color: "black" }} className="box-btn-text-primary">Subscribed</div>
                    </a>
                    : null}
            </div>
            <div style={{ display: "flex", flexDirection: "column" }} className="mc-box-date box-date half-box">
                <a
                    href={`mailto:${props.email}`}
                    style={{ cursor: "pointer" }}
                    className="box-btn-primary w-inline-block"
                >
                    <div className="box-btn-text-tertiary">Email Customer </div>
                </a>
                {/* {props.phoneNumber ? <a
                    href={`tel:+${props.phoneNumber}`}
                    style={{ cursor: "pointer" }}
                    className="box-btn-primary w-inline-block"
                >
                    <div className="box-btn-text-tertiary">Call Customer </div>
                </a> : null} */}
            </div>
        </div>
    )
}
export default Customer;