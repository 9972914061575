import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";
import eye from "../../../../images/eye.svg";
import eyeOff from "../../../../images/eye-off.svg";
import PhoneInput from "react-phone-input-2";
const RegisterPage = (props) => {




  return (
    <div className="section-full">
      <Link to="/" className="logo-bg-dark w-inline-block">
        {/* <img
          src={logo}
          //   srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
          sizes="100vw"
          alt
          className="cashaam-logo-dark"
        /> */}
        <img
          src={logoWhite}
          //   srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
          sizes="(max-width: 479px) 60px, (max-width: 767px) 90px, 100px"
          alt
          className="cashaam-logo-white"
        />
      </Link>
      <div className="container-left">
        <div className="bg-img-login">
          <div className="holder-copy">
            <h1 className="h1">A better way to sell online.</h1>
            <h1 className="h1 hidden">Create Your Free Account</h1>
            <h3 className="h3 hidden">Sell on your Website, Amazon and more.</h3>
            <h3 className="h3">Sell on your Website, Amazon and more.</h3>
            <div className="copy-18">
              You can have your store up and ready to launch in 15 minutes or
              less.
            </div>
            <div className="copy-18 hidden">
              Sell more, grow your business online. Millions of customers are
              waiting! We can help you reach more people and expose your
              business for it for free.
            </div>
          </div>
        </div>
      </div>
      <div className="container-right">
        <div className="holder-signup-otp">
          <div style={{ display: "flex" }} className="signup mobile-signing">
            <h1 className="h2">Create Your Free Account.</h1>

            <div className="form-signup w-form">
              {/* {props.firstNameError ? (
                <div className="error-message">{props.firstNameError}</div>
              ) : null} */}
              
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form"
                onSubmit={props.handleSubmit}
              >

            <div className="half-width register-name" style={{width: "100%"}}>
              <div className="name-elements">
                {props.firstNameError ? (
                    <div className="error-message" style={{marginBottom:"5px"}}>{props.firstNameError}</div>
                  ) : <label for="step1">First Name</label> }
                <input
                  onChange={props.handleChange}
                  type="text"
                  className={
                    props.firstNameError
                      ? "input-text w-input input-glow input-error"
                      : "input-text input-glow w-input"
                  }
                  maxLength={256}
                  name="firstName"
                  data-name="firstName"
                  placeholder="First Name"
                  id="firstName"
                />
                </div>

                <div className="name-elements">
                {props.lastNameError ? (
                    <div className="error-message" style={{marginBottom:"5px"}}>{props.lastNameError}</div>
                  ) : <label for="step1">Last Name</label>}
                <input
                  onChange={props.handleChange}
                  type="text"
                  className={
                    props.lastNameError
                      ? "input-text w-input input-glow input-error"
                      : "input-text input-glow w-input"
                  }
                  maxLength={256}
                  name="lastName"
                  data-name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                />
                </div>
                </div>

                {(props.mobile.substring(0, 1) == 1 &&
                  props.mobile.length > 11) ||
                (props.mobile.substring(0, 3) == "234" &&
                  props.mobile.length > 13) ? (
                  <div className="error-message">
                    Please enter a valid phone number
                  </div>
                ) : props.phoneError ? (
                  <div className="error-message">{props.phoneError}</div>
                ) : null}

                <PhoneInput
                  onlyCountries={["us", "ng"]}
                  inputClass={
                    props.phoneError
                      ? "input-text w-input input-glow input-error"
                      : "input-text input-glow w-input"
                  }
                  inputStyle={{
                    minWidth: "100%",
                  }}
                  containerStyle={{
                    marginBottom: "8px",
                  }}
                  country={props.location !== "nigeria" ? "us" : "ng"}
                  value={props.mobile}
                  onChange={(mobile) => props.handlePhoneInput(mobile)}
                />
                {props.emailError ? (
                  <div className="error-message">{props.emailError}</div>
                ) : null}
                <input
                  onChange={props.handleChange}
                  type="email"
                  className={
                    props.emailError
                      ? "input-text w-input input-glow input-error"
                      : "input-text input-glow w-input"
                  }
                  maxLength={256}
                  name="email"
                  data-name="Email Address 2"
                  placeholder="Email Address"
                  id="email"
                />
                {props.passwordError ? (
                  <div className="error-message">{props.passwordError}</div>
                ) : null}
                <input
                  onChange={props.handleChange}
                  type={props.showPassword === false ? "password" : "text"}
                  className={
                    props.passwordError
                      ? "input-text w-input input-glow input-error"
                      : "input-text w-input input-glow"
                  }
                  maxLength={256}
                  name="password"
                  data-name="Password"
                  placeholder="Password"
                  id="password"
                />

                <div className="referral_btn">
                  <button
                    className={!props.isToggleOn ? "referral_hide" : ""}
                    onClick={props.toggleReferral}
                  >
                    + ADD REFERRAL CODE
                  </button>
                </div>

                <input
                  onChange={props.handleChange}
                  value={props.referralCode}
                  type="text"
                  className={
                    props.isToggleOn
                      ? "referral_hide"
                      : "input-text w-input input-glow"
                  }
                  maxLength={6}
                  name="referralCode"
                  data-name="referral code"
                  placeholder="Referral Code (OPTIONAL)"
                  id="referral_code"
                />
                <div className="visible">
                  {props.showPassword === false ? (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => props.onShowPassword(true)}
                      src={eye}
                      alt
                      className="eye-open"
                    />
                  ) : (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => props.onShowPassword(false)}
                      src={eyeOff}
                      alt
                      className="eye-closed"
                    />
                  )}
                </div>
                {props.loading === false ? (
                  <input
                    style={{ fontSize: "16px" }}
                    type="submit"
                    value="Sign Up"
                    defaultValue="Sign Up"
                    data-wait="Please wait..."
                    className="submit-button w-button"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="submit-button w-button"
                  >
                    <div
                      style={{ borderTopColor: "#6740aa" }}
                      className="loader"
                    ></div>
                  </div>
                )}
              </form>
              {/* <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div> */}
              {props.errorMessage !== null ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>{props.errorMessage}</div>
                </div>
              ) : null}
            </div>

            {props.loginSuggestion ? (
              <div style={{ width: "100%" }}>
                <p className="microcopy-signup">
                  It looks like you are an existing user. <br />
                  You can login with your {props.loginSuggestion}
                </p>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => props.loginWithPhone(props.loginSuggestion)}
                  className="btn-secondary"
                >
                  <div className="text-btn-secondary">
                    Login with {props.loginSuggestion}
                  </div>{" "}
                </a>
              </div>
            ) : null}

            {!props.loginSuggestion ? (
              <Link style={{ textDecoration: "none"}} to="/seller/signin">
                <div style={{ cursor: "pointer"}} className="microcopy-signup">
                  Already have an account? <span style={{ textDecoration: "underline", color: "#261ab1"}}>Log In</span>
                </div>{" "}
              </Link>
            ) : null}

            <div className="terms-conditions">
               By signing up, you agree to the <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Terms of Service</a>, <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Privacy Policy</a> and <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Cookie Use</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;