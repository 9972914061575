import React,{useState} from "react";
import ReactMarkdown from 'react-markdown';

function AIPost(props) {


  const [copied, setCopy] = useState(false);

  const copyReferral =(text)=>{
    console.log("copy",text);
    navigator.clipboard.writeText(text)
    setCopy(true)
  }

  const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

  return (
    <div style={{ position: "relative" }} className={`jss224 ${props.type!="ai"?"user-prompt":""}`}>
      <div className={`jss225 ${props.type!="ai"?"user-borders":""}`} />
      <div className="jss233">
        <div
          role="textbox"
          aria-multiline="true"
          className="jss236 jss226 jss238"
          data-testid="MarkedEditable"
          data-slate-editor="true"
          data-slate-node="value"
          zindex={-1}
          style={{
            position: "relative",
            outline: "none",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        >

          <div data-slate-node="element" style={{ position: "relative", display:"flex", flex: 1, flexDirection: "row"}}>
            {props.type=="ai"?
                <div style={{ alignItems: "flex-start"}} className="holder-avatar ai-avatars">
                    <div style={{width:"40px", height:"40px",backgroundColor:"white",marginTop:"15px"}} className="user-avatar">
                    <img src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif" />
                    </div>
                </div>
                        :
                <div style={{ alignItems: "flex-start"}} className="holder-avatar ai-avatars">
                    <div style={{width:"40px", height:"40px", marginTop:"15px"}} className="user-avatar">
                    <div className="initials">{props.initials}</div>
                    </div>
                </div>
            }

            <div style={{ display:"flex", flexDirection:"column"}}>
              <span style={{marginTop:"18px"}} className="ai-reply" data-slate-node="text">
                <span data-slate-leaf="true">
                  <span data-slate-string="true">
                  {isHTML(props.generatedContent)?
                  <span dangerouslySetInnerHTML={{ __html: props.generatedContent }} />
                  :
                  <ReactMarkdown >{props.generatedContent}</ReactMarkdown>
                  }
                  </span>
                </span>
              </span>


            </div>
          </div>


          <div className="ai-how-to pop-footer">
              {props.type=="ai"? (
                <>
                  <div style={{color: "#6640aa"}} className="customer-support">Watch this video to use the response to create your Ad</div>

                  <div
                    onClick={() => props.showVideo()}
                    style={{ cursor: "pointer" }}
                    className="ship-order"
                  >
                    Watch Video
                  </div>
                </>
              ) : null}
            </div>

        </div>
      </div>

      {props.type=="ai"?
        <button 
            onClick={() => props.showVideo()}
            className="jss198 jss240 jss228 video-tooltip" tabIndex={0} type="button">
            <span className="jss248">
              <img style={{width:"33px"}} src="https://res.cloudinary.com/cashaam/image/upload/v1688430433/icons/blue-tooltip_efpqlf.webp" />
            </span>
        </button>
      :null}

      {props.type=="ai"?

        (copied?
          <div className="copied jss198 jss240 jss228">Copied Post!</div>
        :
          <button 
          onClick={()=>copyReferral(props.generatedContent)}
          className="jss198 jss240 jss228" tabIndex={0} type="button">
            <span className="jss248">
              <svg
                className="jss33 jss229"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  d="M20.56 6.17427H9.67394C8.32869 6.17427 7.2346 7.24552 7.2346 8.56242V20.6116C7.2346 21.9285 8.32869 23 9.67394 23H20.5602C21.9055 23 23 21.9287 23 20.6116V8.56242C22.9998 7.24552 21.9055 6.17427 20.56 6.17427ZM21.9733 20.6114C21.9733 21.3739 21.3391 21.9945 20.5602 21.9945H9.67394C8.895 21.9945 8.26126 21.3739 8.26126 20.6114V8.56242C8.26126 7.7999 8.895 7.17928 9.67394 7.17928H20.5602C21.3391 7.17928 21.9733 7.79967 21.9733 8.56242V20.6114Z"
                  fill="currentColor"
                />
                <path
                  d="M4.44048 16.8212C3.66108 16.8212 3.02688 16.2005 3.02688 15.438V3.38882C3.02688 2.62586 3.66108 2.00502 4.44048 2.00502H15.3263C16.1052 2.00502 16.7392 2.62586 16.7392 3.38882C16.7392 3.66635 16.9693 3.89133 17.2525 3.89133C17.536 3.89133 17.7659 3.66635 17.7659 3.38882C17.7659 2.0717 16.6715 1 15.3261 1H4.44025C3.09455 1 2 2.0717 2 3.38882V15.438C2 16.7549 3.09432 17.8264 4.44025 17.8264C4.72375 17.8264 4.95357 17.6012 4.95357 17.3239C4.95357 17.0466 4.72398 16.8212 4.44048 16.8212Z"
                  fill="currentColor"
                />
                <path
                  d="M20.56 6.17427H9.67394C8.32869 6.17427 7.2346 7.24552 7.2346 8.56242V20.6116C7.2346 21.9285 8.32869 23 9.67394 23H20.5602C21.9055 23 23 21.9287 23 20.6116V8.56242C22.9998 7.24552 21.9055 6.17427 20.56 6.17427ZM21.9733 20.6114C21.9733 21.3739 21.3391 21.9945 20.5602 21.9945H9.67394C8.895 21.9945 8.26126 21.3739 8.26126 20.6114V8.56242C8.26126 7.7999 8.895 7.17928 9.67394 7.17928H20.5602C21.3391 7.17928 21.9733 7.79967 21.9733 8.56242V20.6114Z"
                  stroke="currentColor"
                  strokeWidth="0.6"
                />
                <path
                  d="M4.44048 16.8212C3.66108 16.8212 3.02688 16.2005 3.02688 15.438V3.38882C3.02688 2.62586 3.66108 2.00502 4.44048 2.00502H15.3263C16.1052 2.00502 16.7392 2.62586 16.7392 3.38882C16.7392 3.66635 16.9693 3.89133 17.2525 3.89133C17.536 3.89133 17.7659 3.66635 17.7659 3.38882C17.7659 2.0717 16.6715 1 15.3261 1H4.44025C3.09455 1 2 2.0717 2 3.38882V15.438C2 16.7549 3.09432 17.8264 4.44025 17.8264C4.72375 17.8264 4.95357 17.6012 4.95357 17.3239C4.95357 17.0466 4.72398 16.8212 4.44048 16.8212Z"
                  stroke="currentColor"
                  strokeWidth="0.6"
                />
              </svg>
            </span>
          </button>
      )
      :null}

        {props.type=="ai" && props?.dateCreated?.toDate?
          <div 
          className={`chat__meta show-time`}
          style={{
            position: "absolute",
            right: "10px",
            bottom: "5px",
            fontSize: "11px",
            color: "black"
          }}
          >
            <span>{props.dateCreated?.toDate().toDateString()}, &nbsp;</span>
            <span>{props.dateCreated?.toDate().toLocaleTimeString('en-US')}</span>
          </div>
        :null}

    </div>
  );
}

export default AIPost;
