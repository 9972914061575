import firebase from "../../firebaseConfig";

const getChatList = async (userId) => {

    try {

        const db = firebase.firestore();

        var chatList=[];
        

        var query = await db.collection("messages")
        .where("sellerId", "==", userId);
                
        await query.get().then((querySnapshot) => {
            
            var messages={};
            querySnapshot.forEach((document) => {
                console.log("messages doc",document.data())
                
                messages=document.data();
                messages["messagesId"]=document.id;
                chatList.push(messages);
                

            });


            
            console.log("chatList",chatList);
        });


        return chatList;
    } catch (error) {
        console.log("error getting chat list", error);
        return [];
    }

}
export default getChatList;