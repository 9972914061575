import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";
import clap from "../../../../images/clap-new.jpeg";
import star from "../../../../css/images/Star.svg";
import firebase from "../../../../firebaseConfig/index";
import getUserLocation from "../../../helpers/getUserLocation";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import saveAccountType from "../../../helpers/saveAccountType";

class PickAccountType extends React.Component {
  state = {
    currentStep: null,
    userCurrentCountry: "",
    firstName: null,
    watchedIntro: false,
  };

  selectAccount = async (account) => {
    console.log("selected account:", account);
    let { sellerId } = this.state;

    await saveAccountType(sellerId, account);
    await saveRegisterProgress(sellerId, 1);

    // this.props.history.push("/seller/dashboard/setup",);
    this.props.history.push({
        pathname: "/seller/dashboard/setup",
        state: { goToStoreSetup: false }
      });

  };
  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      shopCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  setTimer = () => {
    setTimeout(() => {
      this.componentDidMount();
    }, 10000);
  };

  nextStep=()=>{
    this.setState({
        watchedIntro: true
    })
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log("user", user);
      if (user) {
        let step = await getRegistrationProgress(user.uid);
        step = parseFloat(step);

        await this.getLocation();
        console.log("STEP", step);
        if (step > 3) {
          this.props.history.push("/seller/dashboard");
        }
        this.setState({
          currentStep: step,
          firstName: user.displayName,
          sellerId: user.uid,
        });
      } else {
        this.props.history.push("/seller/signup");
      }
    });
  }

  render() {
    console.log("test", this.state.currentStep > 4);
    if (!this.state.firstName) {
      this.setTimer();
    }
    return (
      <>
        {this.state.firstName ? (
          <>
            {!this.state.watchedIntro ? (
              <div className="section-onboarding">
                <div className="onboarding">
                    <div className="intro-container wrapper">

                        <div className="intro-title">Watch to learn more about Cashaam services</div>
                    <iframe
                        className="shipping-tutorial"
                        src="https://www.youtube.com/embed/TiDWpnCwHy0"
                        title="How Cashaam Works"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowfullscreen
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>

                        <div 
                        className="microcopy-signup intro-next" 
                        style={{cursor: "pointer"}}
                        onClick={()=>this.nextStep()}>Next</div>

                    </div>
                </div>
              </div>
            ) : (
              <div className="section-onboarding">
                <div className="onboarding">
                  <div className="block-title-1 center-title">
                    <h4 className="h4">Account Setup</h4>
                  </div>
                  <div
                    style={{
                      color: "#12121299",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  >
                    How do you want to get started?
                  </div>

                  <div style={{marginBottom: "10px"}} className="adshelpgridwrap">
                    <div className="adshelpgrid account-choice">
                      <div
                        id="w-node-_8013c410-f860-e5bb-ea42-2303070c4ce0-4bc00d62"
                        className="adshelpgriditem green"
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => this.selectAccount("Business")}
                      >
                        <div className="account-type">Business</div>
                        <div className="adshelpgriditemtext">
                          <span className="adshelpgriditemtextspan">
                            Get your Unlimited Virtual Card, Ad, Amazon and more
                          </span>
                          <br />
                          {this.state.userCurrentCountry?.toLowerCase() ==
                          "nigeria" ? (
                            <div className="small-txt">
                              ₦6,000 per month. Start with a free trial.
                            </div>
                          ) : (
                            <div className="small-txt">
                              $10 per month. Start with a free trial.
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        id="w-node-_2bdf2431-550f-26af-7d9c-68d43327f68c-4bc00d62"
                        className="adshelpgriditem purple"
                        style={{ position: "relative", cursor: "pointer" }}
                        onClick={() => this.selectAccount("Personal")}
                      >
                        <div>
                          <div
                            style={{ position: "absolute", top: "19px" }}
                            className="adshelpgriditemlabel"
                          >
                            <img src={star} loading="lazy" alt />
                            <div>New</div>
                          </div>
                          <div className="account-type">Personal</div>
                        </div>
                        <div className="adshelpgriditemtext">
                          <span className="adshelpgriditemtextspan">
                            Get your Unlimited Virtual Card
                          </span>
                          <br />
                          {this.state.userCurrentCountry?.toLowerCase() ==
                          "nigeria" ? (
                            <div className="small-txt">
                              Card Creation fee: ₦1,000
                            </div>
                          ) : (
                            <div className="small-txt">
                              Create your virtual card for FREE
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#12121299",
                      fontSize: "18px",
                      margin: "0 auto",
                    }}
                  >
                    Not Sure? Just pick one and switch later.
                  </div>
                </div>
              </div>
            )}
            <Link to="/" className="logo-black w-inline-block">
              <img
                src={logo}
                // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
                sizes="(max-width: 767px) 80px, 100px"
                alt
                className="cashaam-logo-dark"
              />
              <img
                src={logoWhite}
                // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
                sizes="100vw"
                alt
                className="cashaam-logo-white"
              />
            </Link>
          </>
        ) : (
          <>
            {setTimeout(() => {
              console.log("refreshing page data");
              this.componentDidMount();
            }, 10000)}
            <div className="section-onboarding">
              <div
                style={{ position: "absolute", bottom: "50%", left: "40%" }}
                className="onboarding"
              >
                <div className="block-title-1">
                  <div className="holder-svg">
                    {/* <img src={clap} alt className="clap" /> */}
                  </div>
                  <h4 className="h4">Please wait...</h4>
                </div>

                <div className="microcopy-signup">
                  Gathering your information...
                </div>
              </div>
            </div>
            <Link to="/" className="logo-black w-inline-block">
              <img
                src={logo}
                // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
                sizes="(max-width: 767px) 80px, 100px"
                alt
                className="cashaam-logo-dark"
              />
              <img
                src={logoWhite}
                // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
                sizes="100vw"
                alt
                className="cashaam-logo-white"
              />
            </Link>
          </>
        )}
      </>
    );
  }
}
export default PickAccountType;
