import firebase from "../../firebaseConfig";

const getDocRef = (userId) => {
    const db = firebase.firestore();
    // get current user logged in uid
    // console.log(firebase.auth().currentUser)
    var docRef = db
        .collection("sellersAccounts")
        .doc(userId)
        .collection("stores")
        .doc(userId)
    return docRef
}


export const saveAvailability = (userId, availability) => {
  return new Promise(async (resolve, reject) => {
    try {
      // get current user logged in uid
      var docRef = getDocRef(userId)

      const store = await docRef.get();
      
      await docRef.update({
        availability,
      });
      resolve("Sucess");
    } catch (error) {
      console.log("error saving catalog", error);
      reject(error);
    }
  });
};

export const getAvailability = (userId) => {
    return new Promise(async (resolve, reject) => {
      const db = firebase.firestore();
      try {
  
        var docRef = getDocRef(userId)
        const store = await docRef.get();
        const availability = store.data().availability;
  
        resolve(availability);
      } catch (error) {
        console.log("error retreiving availability", error);
        reject(error);
      }
    });
  };