import React from "react";
import Loader from "react-loader-spinner";
import Autocomplete from "react-autocomplete";

function FlutterwaveTransfer(props) {
  return (
    <div className="fixedmodalwrapper gray-background">
      <div className="fixedmodalcontainer fixed-modal">
        <div className="fixedmodalhead itemaddedmodalhead">
          {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
          <div className="text-block-4" style={{ margin: "auto", fontWeight: "bold" }}>
            Transfer Money
          </div>
        </div>

        {props.loadingContent === true ? (
          <div className="loading-content" style={{ width: "300px" }}>
            <Loader type="TailSpin" color="#7455c1" height={100} width={100} />
            <div>Loading...</div>
          </div>
        ) : (
            props.userMessage? 
            
            (<div className="airtime-resp-container">
              {props.transferSuccess===true? 
             <div className="success-payment"></div>
             :
             <div className="error-payment"></div>
              }
              <div className="airtime-resp">{props.userMessage}</div>
              
                
            </div>):


          <div className="paymentmodalbuttonwrap">
            <div className="column transfer-container">

              {props.errorAccount ? (
                <div className="error-message">{props.errorAccount}</div>
              ) : (
                <label htmlFor="updateProfileFirstName" className="formlabel">
                 Account Number
                </label>
              )}
              <input
                value={props.accountNumber}
                onChange={props.handleChange}
                type="text"
                className={`formtextfield tabsprofiletextfield ${
                  props.errorAccount ? "input-error" : ""
                }`}
                maxLength={256}
                name="accountNumber"
                data-name="Update Profile First Name"
                placeholder="Enter Account Number"
                id="updateProfileFirstName"
              />

              {props.errorBank ? (
                <div className="error-message">{props.errorBank}</div>
              ) : (
                <label htmlFor="updateProfileFirstName" className="formlabel">
                  Bank
                </label>
              )}
              
                <Autocomplete
                    value={props.bankName}
                    inputProps={{
                    type: "text",
                    className: `formtextfield tabsprofiletextfield ${props.errorBank ? "input-error" : ""}`,
                    maxLength: 256,
                    name: "bankName",
                    placeholder: "Enter your bank (example: Sterling Bank)",
                    id: "bankName"
                    }}
                    wrapperStyle={{}} //keep this empty in order to avoid default styling from messing with the input's style inherited from css
                    items={props.bankNames}
                    getItemValue={(item) => item}
                    shouldItemRender={(item, value) =>
                    item.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    onChange={props.handleChange}
                    onSelect={(value) => props.selectBank(value)}
                    menuStyle={{
                        borderRadius: "3px",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "2px 0",
                        fontSize: "90%",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "10%",
                        zIndex: 1,
                    }}
                    renderItem={(item, isHighlighted, i) => (
                    <div
                        style={{
                            background: isHighlighted ? "lightgray" : "white",
                            height: "25px",
                            lineHeight: "25px",
                            fontSize: "14px",
                            fontFamily: "Sf ui display, sans-serif",
                            paddingLeft: "10px"
                        }}
                        key={item}
                    >
                        {item}
                    </div>
                    )}
                />

              <input
                type="button"
                onClick={props.onSubmit}
                defaultValue="Transfer"
                data-wait="Please wait..."
                id="updateProfileSubmit"
                className="formselectbutton tabupdatebutton"
              />
            </div>
          </div>
        )}
        <a
          onClick={props.onHide}
          className="fixedmodalexitimg w-inline-block"
        />
      </div>
    </div>
  );
}

export default FlutterwaveTransfer;
