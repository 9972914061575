import firebase from "../../firebaseConfig";

const storeProductImagesToStorage = async (
  image,
  productName,
  productCategory
) => {
  return new Promise((resolve, reject) => {
    try {
      const storage = firebase.storage();

      //enfore image type to JPEG, JPG, and PNG only
      console.log("feat image", image.type);
      if (
        image.type !== "image/png" &&
        image.type !== "image/jpg" &&
        image.type !== "image/jpeg"
      ) {
        const message = `Image of type ${image.type} are not supported. Please upload a JPEG, JPG or PNG`;
        console.log("File type is not supported. Uploaded type:", image.type);
        // alert(message);
        reject("Wrong Image Type");
      }

      //Generate random IDs or References to push the pictures using date timestamp and picture name Storage does not have such method
      // const randomId = Date.now().toString() + "-" + image.name;
      const date = Date.now().toString();
      const type = image.type.substring(6);
      let name = productName ? productName.replace(/[^a-zA-Z ]/g, "") : "";
      name = name.replace(/ /g, "_");
      let category = productCategory
        ? typeof productCategory === "string"
          ? productCategory.replace(/[^a-zA-Z ]/g, "")
          : productCategory.toString().replace(/[^a-zA-Z ]/g, "")
        : "";
      category = category.replace(/ /g, "_");
      const randomId = `${name}_${category}_${date}.${type}`;

      // Create file metadata including the content type
      var metadata = {
        // contentType: 'image/jpeg',
        // To enable Client-side caching you can set the Cache-Control headers here. Uncomment below.
        cacheControl: "public,max-age=300"
      };

      let uploadImgsToStorage = storage
        .ref(`products/images/${randomId}`)
        .put(image, metadata);

      uploadImgsToStorage.on(
        "state_changed",
        snapshot => {
          // var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        // checks if anything went wrong when uploading images to storage
        error => {
          alert("error with uploading images");
        },
        () => {
          // once the images uploaded to storage successfully then get the images urls
          storage
            .ref("products/images/")
            .child(`${randomId}`)
            .getDownloadURL()
            .then(imgsUrl => {
              resolve(imgsUrl);
            });
        }
      );
    } catch (error) {
      console.log("error savign images", error);
      reject(error);
    }
  });
};

export default storeProductImagesToStorage;
