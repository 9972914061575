import React from 'react';

function GrowthInfo(props) {
    return (
        <div className="growthresources">
        <div className="growthresourcestop">
          <div>
            Growth Resources
            <br />
          </div>
        </div>
        <div className="growthresourcescontent">
          <div className="grwrap">
            <div className="grothresourcestitle">
              <strong>Key Growth Resources</strong>
            </div>
            <a href="#" className="growthresourcestext w-inline-block">
              <div>How to do Delivery or Shipping</div>
            </a>
            <a href="#" className="growthresourcestext w-inline-block">
              <div>Updating your Bank Information</div>
            </a>
            <a href="#" className="growthresourcestext w-inline-block">
              <div>How to Add New Products</div>
            </a>
          </div>
          <div className="grwrap">
            <div className="grothresourcestitle">
              <strong>Key Video Resources</strong>
            </div>
            <div className="grtextwrap">
              <a href="#" className="grtext">
                How to do Delivery or Shipping
              </a>
              <a href="#" className="grtext">
                Updating your Bank Information
              </a>
              <a href="#" className="grtext">
                How to Add New Products
              </a>
              <a href="#" className="grtext lastitem">
                How to Share and Earn on Cashaam
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default GrowthInfo;