import firebase from "../../firebaseConfig";

const isSeller = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const document = await db.collection("sellersAccounts").doc(userId).get();
      console.log("DOC", document.esists);
      if (document.exists) {
        resolve(true);
      } else {
        const userInfo = await db
          .collection("buyersAccounts")
          .doc(userId)
          .get();
        console.log("buyers info", userInfo.data());
        resolve({ userInfo });
      }
    } catch (error) {
      console.log("error checking user type", error);
      reject(error);
    }
  });
};

export default isSeller;
