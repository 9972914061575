
import firebase from "../../firebaseConfig";

const getReferrals = async (referralCode,cashedoutReferrals) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();

      console.log("referralCode",referralCode)


      const referrals = await db.collection("sellersAccounts")
      .where("referralCode", "==", referralCode).get();

      let referralDocs=referrals.docs;
      console.log("referrals",referralDocs);
      let successfullReferral = 0;
      let referralList=[];
      let potentialReward=0;
      let paidReferral = 0;
      referralDocs.forEach((doc)=>{
        
        let paid=false;

        let referDoc=doc.data();
        console.log("referralDoc",referDoc);
        
        //Reward a referral if the new account has subscription active for atleast 2 month, or an active lite account
        if(referDoc?.subscriptionActive &&
          (referDoc?.subscriptionType=="monthly" ||
          referDoc?.subscriptionType=="quarterly" ||
          referDoc?.subscriptionType=="annually" ||
          referDoc?.subscriptionType=="lite"
          && 
          (referDoc?.subscriptionHistory && 
            referDoc?.subscriptionHistory.length>1
            ||
            referDoc?.subscriptionType=="lite"
            )
          )){

            if(cashedoutReferrals && paidReferral<cashedoutReferrals){
              paidReferral++
              paid=true
            }
            
            if(referDoc?.subscriptionType=="lite"){
              potentialReward+=100;
            }else{
              potentialReward+=3000;
            }

          successfullReferral++;
        }


        let date= (new Date(referDoc?.registrationDate))
        let localeDate=date.toLocaleDateString('en-US',{
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        let timeStamp= date.getTime();
        let referralItem={
          subscriptionType: referDoc.subscriptionType,
          subscriptionActive: referDoc?.subscriptionActive,
          registrationDate: referDoc?.registrationDate,
          date:localeDate,
          timeStamp,
          paid,
          subscriptionHistory: referDoc?.subscriptionHistory
        };
        referralList.push(referralItem);

        
  
      })

      console.log("successfullReferral",successfullReferral)

      let referralCount=referralDocs?.length;
      console.log("referrals count",referralCount)
      resolve({referralCount,successfullReferral, referralList, potentialReward});
    } catch (error) {
      console.log("error getting referrals", error);
      reject(error);
    }
  });
};

export default getReferrals;