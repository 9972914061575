import Swal from "sweetalert2";

const Loading = (value, message, time) => {
  if (value !== false) {
    let timerInterval;
    Swal.fire({
      title: message ? message : "Your product is being saved...",
      timer: time ? time : 8000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {}, 100);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
      allowOutsideClick: false,
      background: "none",
      backdrop: `rgba(255, 255, 255,0.65)`,
    });
  } else if (value === false) {
    Swal.close();
  }
};
export default Loading;
