import React from "react";
const ShippingLabelPop = (props) => {
  return (
    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container">
        <div style={{ display: "flex", overflow: "scroll", maxHeight: "90vh" }} className="pop-us-ship-label">
          <div className="pop-header">
            <div className="text-main">Order Processing</div>
            {props.errorPrintingShippingLabel === true ? <a
              className="box-btn-primary w-inline-block"
            >
              <div style={{ borderColor: "red" }} className="btn-text-primary-outline processed">
                Error Printing Label
              </div>
            </a> : null}
          </div>
          <div className="pop-content">
            <div className="sub-title">
              Download &amp; Print Shipping Labels
            </div>
            <div className="pop-text bold">
              Before you print your shipping labels here’s a few things to note.
            </div>
            <div className="pop-text">
              1. Ensure your Product is ready to be shipped before you download.
              <br />
              2. Your shipping Tracking number is activated once you download
              the shipping label.
              <br />
              3. Confirm shipping label printed matches to the item purchased.
              <br />
              4. Find the nearest USPS Office and submit your item along with
              the shipping label.
              <br />
              {/* 5. To track your product delivery visit -{" "}
              <a href="http://www.usps.com/" target="_blank" className="link">
                <span>
                  <strong className="bold-text">www.usps.com</strong>
                </span>
              </a>
              <span>,</span> enter your tracking number found on the shipping
              label to know the status of the product. */}
            </div>
          </div>
          {props.errorPrintingShippingLabel === true && props.errorDisplayManualShipping===false?
            <div style={{ borderColor: "#ff8c00", borderWidth: "2px", fontSize: "14px" }} className="tracking-box">
              <div>
                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>Error Printing Shipping Label. Please click on "Edit Shipping Info" to fix the following problems: </div>
                {props.printShippingLabelErrors.map((message, i) => (
                  <div key={i}>{`${i + 1}. ${message.text}`}</div>
                ))}
                <br />
                Please <a target="_blank" href="https://support.cashaam.com">Contact Us</a> if this problem persists.</div>
            </div> 
              : (props.errorDisplayManualShipping===true?
                <div style={{ borderColor: "#ff8c00", borderWidth: "2px", fontSize: "14px" }} className="tracking-box">
                  <div>
                    <div style={{ fontWeight: "bold" }}>Error Printing Shipping Label. Please <a target="_blank" href="https://support.cashaam.com">Contact Us</a>, and our team will help you resolve this problem.</div>
                    
                    </div>
                </div>
                : null)}
                
                

          <div className="pop-footer">


            {props.errorPrintingShippingLabel === false ? props.loading === true ? (
              <div
                style={{
                  cursor: "pointer",
                  background: "none",
                  backgroundColor: "grey",
                }}
                className="btn-primary"
              >
                <div className="text-btn-primary">
                  Creating Shipping Label...
                </div>
              </div>
            ) : (props.shippingLink ? <a
              href={props.shippingLink}
              target="_blank"
              style={{ cursor: "pointer" }}
              className="btn-primary"
            >
              <div className="text-btn-primary">View Label</div>
            </a> :
              <div
                onClick={() => props.printLabel()}
                style={{ cursor: "pointer" }}
                className="btn-primary"
              >
                <div className="text-btn-primary">Download Shipping Label</div>
              </div>
              ) :
              <div
                onClick={() => props.onDisplayManualShipping(true, props.order)}
                style={{ cursor: "pointer" }}
                className="btn-primary"
              >
                <div className="text-btn-primary">Edit Shipping Info</div>
              </div>}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.hideShippingLabel()}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Done</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShippingLabelPop;
