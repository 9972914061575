import React from "react";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import { Link } from "react-router-dom";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";

const AboutStoreForm = (props) => (
  <>
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1">
          <h4 className="h4">Tell Us About Your Store</h4>
        </div>
        <div className="microcopy-signup">
          {/* Your store name will appear next to products and items. It should be
          catchy and easy to remember. You can also change this later. */}
          <br />
          <br />
          Make your description relevant and unique to help attract customers.
        </div>
        <div className="holding-onboarding">
          <div className="forms w-form">
            <form
              onSubmit={props.handleSubmit}
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >
              {props.storeNameError? (<div className= "error-message">{props.storeNameError}</div> ): null}
              <input
                onChange={props.handleChange}
                value={props.storeName}
                type="text"
                className={props.storeNameError? "input-text w-input input-glow input-error" : "input-text w-input input-glow"}
                maxLength={20}
                minLength={2}
                name="storeName"
                data-name="storeName"
                placeholder="Enter your Store Name"
                id="storeName"
                //required
              />
              {props.descriptionError? <div className= "error-message">{props.descriptionError}</div> : null}
              <textarea
                onChange={props.handleChange}
                value={props.description}
                placeholder="Enter your Store Description"
                maxLength={5000}
                //minLength={25}
                id="description"
                name="description"
                data-name="description"
                //required
                className={props.descriptionError? "input-text w-input input-glow input-error" : "input-text w-input input-glow"}
                defaultValue={""}
                
              />
              <div className="small-text">
                <div className="text-info">
                  {160 - props.description?.length}
                </div>
              </div>
              <div className="holder-url">
                <div className="input-label">Customize your Store URL</div>
                <div className="live-url">
                   

                   {props.slugError?  (<div className= "error-message">{props.slugError}</div>
                    ) : props.invalidSlug === true ? (
                    props.slug?.length==0 ? (
                      null
                    ) :
                      props.upperCaseDetectedInSlug ? (
                      <div className= "error-message">No Upper Case letters allowed</div>
                    ) : props.spaceDetectetInSlug ? (
                      <div className= "error-message">No Space allowed</div>
                    ) : props.specialCharDetectedInSlug ? (
                      <div className= "error-message">No Special Characters or Numbers allowed</div>
                    ) : (
                      <div className= "error-message">Unavailable</div>
                    )
                  ) : null}
                </div>
                <div className="store-url">
                  <div className="input-side">
                    <div>www.cashaam.com/</div>
                  </div>
                  <input
                    value={props.slug}
                    onChange={props.handleSlugChange}
                    onFocus={() => props.getSlugSuggestion()}
                    type="text"
                    className="input-text url w-input input-glow"
                    maxLength={256}
                    name="slug"
                    data-name="slug"
                    placeholder="mystorename"
                    id="url"
                    //required
                  />
                </div>
              </div>

              {props.loading === false ? (
                <input
                  type="submit"
                  defaultValue="Next"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="submit-button w-button"
                >
                  <div
                    style={{ borderTopColor: "#6740aa" }}
                    className="loader"
                  ></div>
                </div>
              )}
            </form>
            <div
              onClick={() => props.goBackToPickAccount()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Go Back
            </div>
            {/* <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <Link to="/" className="logo-black w-inline-block">
      <img
        src={logo}
        // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
        sizes="(max-width: 767px) 80px, 100px"
        alt
        className="cashaam-logo-dark"
      />
      <img
        src={logoWhite}
        // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
        sizes="100vw"
        alt
        className="cashaam-logo-white"
      />
    </Link>
  </>
);
export default AboutStoreForm;