import React from "react";

function ThemeActivation(props) {
  return (
    <div className="paymentmodalbuttonwrap">
      <h2>Activate Moda</h2>
      <div className="column shippinginfoleft">
        <iframe
          className="embeded-vid"
          allowFullScreen="allowFullScreen"
          frameBorder="0"
          src="https://www.youtube.com/embed/tkb-BUY3bSo"
        >
          {" "}
        </iframe>


        {props.showGoToThemeEditor ? (
          <div className="activation-btns">
            <a
              onClick={() => props.onShowThemePop(false)}
              target="_blank"
              href={props.url}
              style={{
                textDecoration: "none",
                maxWidth: "40%",
                cursor: "pointer",
              }}
              className="btn-primary"
            >
              <div className="text-btn-primary">Go to Theme</div>
            </a>
          </div>
        ) : (
          <div className="activation-btns">
            

            <input
              type="button"
              onClick={() =>
                props.confirmThemeChange(
                  props.sellerId,
                  props.onShowThemePop,
                  props.onShowGoToThemeEditor,
                  props.discountCode
                )
              }
              defaultValue="Activate"
              data-wait="Please wait..."
              id="updateProfileSubmit"
              className="formselectbutton activate-theme"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ThemeActivation;
