import React from "react";
import cashaamLogo from "../../images/brand_0.svg";
import storeFrontLogo from "../../images/brand.svg";
import { Link } from "react-router-dom";

const TopHeader = (props) => {
  return (
    <>
      <div
        data-w-id="98fd8586-2469-1b98-60df-c1637690dca3"
        className="tint-nav"
      />
      <div className="top-bar">
        <div className="box-user">
          <div className="holder-avatar">
            <div className="user-avatar">
              <div className="initials">
                {props.firstName && props.lastName
                  ? `${props.firstName[0]}${props.lastName[0]}`
                  : props.firstName
                  ? props.firstName[0]
                  : props.sellerName
                  ? props.sellerName[0]
                  : "C"}
              </div>
            </div>
          </div>
          <div className="user-info">
            <div className="user-name">
              {props.firstName}
              <br />
              {props.lastName}
            </div>
            <div className="storeid">StoreID: 21212121212</div>
          </div>
          {/* <div className="icon-floats">
            <Link to="/seller/dashboard/settings">
              <div className="link-icon">
                <div className="icon-dash w-embed">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-settings"
                    width={22}
                    height={22}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentcolor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <circle cx={12} cy={12} r={3} />
                  </svg>
                </div>
              </div>
            </Link>
            <div className="link-icon">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => props.logoutHandler()}
                className="icon-dash tab w-embed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-logout"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentcolor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>
              </div>
            </div>
          </div> */}
        </div>
        <div className="box-link">
          <div className="holder-right-link">
            <a
              target="_blank"
              href="https://support.cashaam.com"
              className="link-dash"
            >
              Help
            </a>
            <a
              target="_blank"
              href="https://support.cashaam.com"
              className="link-dash"
            >
              FAQ
            </a>
            <a href={props.notification && props.notification>0? "/seller/dashboard/chat": ""} className="link-icon notification-parent">
              {props.notification && props.notification>0?
              <div className="notification-number">{props.notification}</div>: 
              <div style={{ backgroundColor: "red"}} className="notification-number"></div>
              }
              
              <div className="icon-dash w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-bell"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentcolor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" />
                  <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                </svg>
              </div>
            </a>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => props.logoutHandler()}
              className="link-icon w-inline-block"
            >
              <div className="icon-dash w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-logout"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentcolor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                  <path d="M7 12h14l-3 -3m0 6l3 -3" />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
