import React, { useState } from "react";
import priceFormater from "../../../../helpers/priceFormater";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";

import styles from './UpcomingBookings.module.css'

import { days, months, reverseTimeMap } from "../../../../../constants";
import Modal from "../../../../UI/Modal";
import BookingDetails from "./BookingDetails";


/**
 * This component displays past bookings in a table
 * @param {Object} props 
 */
const PastBookingsDisplay = (props) => {
    const [bookingDetails, setBookingDetails] = useState({})
    const [showBookingDetails, setShowBookingDetails] = useState(false)
    const [bookingDate, setBookingDate] = useState(new Date())

    const openModal = (booking) => {
        setBookingDetails(booking)
        setBookingDate(new Date(booking.bookingTimestamp))
        setShowBookingDetails(true)
    }

    const onCancelBooking = () => {
        props.cancelBooking(bookingDetails, "displayPastBookings")
        setShowBookingDetails(false)
    }

    const onMarkAsComplete = () => {
        props.markBookingAsComplete(bookingDetails)
        setShowBookingDetails(false)
    }

    return (
        <div style={{ minWidth: "100%" }} className="main">
            <Modal show={showBookingDetails} closeModal={() => setShowBookingDetails(false)}>
                <BookingDetails
                    bookingDetails={bookingDetails}
                    // event={event}
                    bookingDate={bookingDate}
                    onCancelBooking={onCancelBooking}
                    isPastBooking={true}
                    onMarkAsComplete={onMarkAsComplete}
                />
                {/* <div className={styles.bookingDetailsContainer}>
                    <div className={styles.detailHeading}>
                        <div>
                            <div><strong>Booking ID:</strong> {bookingDetails.id}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div className={`${styles.bookingDetailsBtn} ${styles.addToCalendar}`}>Add to Calendar</div>
                            <div className={`${styles.bookingDetailsBtn} ${styles.cancelBooking}`}>Cancel Booking</div>
                        </div>
                    </div>
                    <div className={styles.bookingDetails}>
                        <div style={{ width: "25%" }}>
                            <h6>Date</h6>
                            <p>{`${days[bookingDate.getDay()]} ${months[bookingDate.getMonth()]} ${bookingDate.getDate()}, ${bookingDate.getFullYear()}`}</p>
                            <h6>Time</h6>
                            <p>{reverseTimeMap[bookingDetails.time]}</p>
                        </div>
                        <div style={{ width: "45%" }}>
                            <h5>{bookingDetails.serviceName}</h5>
                            {
                                bookingDetails.addOns &&
                                bookingDetails.addOns.map((addOn, i) => {
                                    return (
                                        <>
                                            <p>{addOn.addOnName}</p>
                                        </>
                                    )
                                })
                            }
                            <h6>{priceFormater(bookingDetails.amount, 'USD')}</h6>
                        </div>
                        <div style={{ width: "30%" }}>
                            <div>
                                <p>CUSTOMER NAME</p>
                                <h6>{bookingDetails.customerName}</h6>
                            </div>
                            <div>
                                <p>CUSTOMER EMAIL</p>
                                <h6>{bookingDetails.customerEmail}</h6>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Modal>
            <div className="container-orders">
                <div className="order-holder">
                    <div className="order-day">
                        <h5 className="day-title">Past Bookings</h5>
                        {props.pastBookings.length > 0 &&
                            <table className={styles.table}>
                                <col style={{ width: "22%" }} />
                                <col style={{ width: "14%" }} />
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "14%" }} />
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Amount</th>
                                        <th>Service</th>
                                        {/* <th>Status</th> */}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.pastBookings.map((booking, i) => {
                                            let date = new Date(booking.bookingTimestamp)
                                            return (
                                                <tr>
                                                    <td>{`${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${reverseTimeMap[booking.time]}`}</td>
                                                    <td>{priceFormater(
                                                        booking.amount,
                                                        'USD'
                                                    )}</td>
                                                    <td>{booking.serviceName}</td>
                                                    {/* <td>{booking.status}</td> */}
                                                    <td onClick={() => openModal(booking)} style={{ color: "#6741AA", cursor: "pointer" }}>Details &gt;</td>
                                                </tr>
                                            )

                                        })
                                    }
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PastBookingsDisplay;