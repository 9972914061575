import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "../UI/Modal";
import setUserTheme from "../helpers/theme/setUserTheme";
import Loading from "../common/Loading";
import getUserTheme from "../helpers/theme/getUserTheme";
import ThemeActivation from "./ThemeActivation";
import UpgradePopup from "./UpgradePopup";
// import brandLogoOne from "../../images/brand_0.svg";
// import brandLogoTwo from "../../images/stortfront_dark.png";
const brandLogoOne = null;
const brandLogoTwo = null;

const DISCOUNT_CODE = "TAKETHREE";
// let url = null;

const goToCashaamTheme = (sellerId, onShowThemePop, setEditorUrl) => {
  onShowThemePop(true);
  let url = `https://theme.cashaam.com/theme?store=${sellerId}`;
  setEditorUrl(url);
};

// const ThemeMessage = (props) => {
//   useEffect(() => {
//     console.log("HERE");
//     window.cloudinary
//       .galleryWidget({
//         container: "#wrap",
//         cloudName: "cashaam",
//         mediaAssets: [
//           {
//             tag: "moda_preview",
//             transformation: { crop: "pad" },
//           },
//           {
//             tag: "moda_preview",
//             mediaType: "video",
//             transformation: { crop: "pad" },
//           },
//         ],
//         carouselLocation: "top",
//         imageBreakpoint: 200,
//       })
//       .render();
//   }, []);
//   return (
//     <div style={{ minWidth: "100%" }} className="message-container">
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//         }}
//       >
//         <h1 className="theme-message-title">Moda Theme powered by Cashaam</h1>
//       </div>
//       <div className="wrap" id="wrap"></div>

//       {/* <img
//         style={{ maxHeight: "50%" }}
//         src="https://res.cloudinary.com/cashaam/image/upload/c_pad,g_center,w_1500/v1600019352/Screen_Shot_2020-09-13_at_1.47.28_PM_s3n11m.png"
//       /> */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           justifyContent: "center",
//         }}
//       >
//         {props.showGoToThemeEditor === true ? (
//           <h3 className="theme-message-question">
//             Congratulations! You're now using our Moda theme.
//           </h3>
//         ) : (
//           <h3 className="theme-message-question">
//             Are you sure you want to signup for this theme?
//           </h3>
//         )}
//       </div>
//       {props.showGoToThemeEditor === true ? (
//         <div
//           style={{ display: "flex", justifyContent: "space-around" }}
//           className="button-holder"
//         >
//           <a
//             onClick={() => props.onShowThemePop(false)}
//             target="_blank"
//             href={props.url}
//             style={{
//               textDecoration: "none",
//               maxWidth: "40%",
//               cursor: "pointer",
//             }}
//             className="btn-primary"
//           >
//             <div className="text-btn-primary">Go to Theme</div>
//           </a>
//         </div>
//       ) : (
//         <div
//           style={{ display: "flex", justifyContent: "space-around" }}
//           className="button-holder"
//         >
//           <a
//             onClick={() =>
//               confirmThemeChange(
//                 props.sellerId,
//                 props.onShowThemePop,
//                 props.onShowGoToThemeEditor
//               )
//             }
//             async
//             style={{
//               textDecoration: "none",
//               maxWidth: "40%",
//               cursor: "pointer",
//             }}
//             className="btn-primary"
//           >
//             <div className="text-btn-primary">Use Moda</div>
//           </a>

//           <a
//             onClick={() => props.onShowThemePop(false)}
//             style={{
//               textDecoration: "none",
//               maxWidth: "40%",
//               cursor: "pointer",
//             }}
//             to="/seller/signup"
//             className="btn-primary"
//           >
//             <div className="text-btn-primary">Cancel</div>
//           </a>
//         </div>
//       )}
//     </div>
//   );
// };

const LeftNav = (props) => {
  const [showThemePop, onShowThemePop] = useState(false);
  const [showLoading, onShowLoading] = useState(true);
  const [showGoToThemeEditor, onShowGoToThemeEditor] = useState(false);
  const [editorUrl, setEditorUrl] = useState("");
  const [currentTheme, setCurrentTheme] = useState(null);
  const [discountCode, setDiscountCode] = useState("TAKETHREE");
  const [errorMsg, setErrorMsg] = useState("");
  const [upgradePromo, setUpgradePromo] = useState({
    show: false,
    title: "",
    description: "",
  });
  const [navbarPromo,setNavbarPromo ] = useState(true);

  const togglePromo=()=>{
    console.log("togglePromo")
    setNavbarPromo(false)
  }

  const fetchTheme = async () => {
    try {
      let themeName = await getUserTheme(props.sellerId);
      let url = `https://theme.cashaam.com/theme?store=${props.sellerId}`;
      if (themeName) {
        setCurrentTheme(themeName);
        setEditorUrl(url);
      }
    } catch (error) {
      console.log("error fetching theme", error);
    }
  };

  const confirmThemeChange = async (
    sellerId,
    onShowThemePop,
    onShowGoToThemeEditor,
    discountCode
  ) => {
    try {
      Loading(true, "Getting Theme... Please wait.");
      await setUserTheme(sellerId, "moda");
      Loading(false);
      onShowGoToThemeEditor(true);
    } catch (error) {
      console.log("error confirming theme", error);
      onShowThemePop(false);
      setErrorMsg("");
    }
  };

  const showUpgrade = (title, description) => {
    setUpgradePromo({
      show: true,
      title,
      description,
    });
  };

  const hideUpgrade = () => {
    setUpgradePromo({
      show: false,
      title: "",
      description: "",
    });
  };

  const handleChange = (e) => {
    let code = e.target.value;
    setDiscountCode(code);
  };

  useEffect(() => {
    fetchTheme();
  }, [props.sellerId, props.userCurrentCountry]);

  return (
    <>
      <div
        style={{ display: props.showLeftNav === true ? "flex" : null }}
        className="panel-left"
      >
        {upgradePromo?.show ? (
          <UpgradePopup
            showUpgrade={showUpgrade}
            hideUpgrade={hideUpgrade}
            title={upgradePromo.title}
            description={upgradePromo.description}
          />
        ) : null}
        <div className="holder-navigation">
          <div className="nav-overview">

            
            <div className="holder-link">
              {props.accountType=="Personal" || props.subscriptionType=="lite"?
              null
              :
              <Link
                to="/seller/dashboard"
                aria-current="page"
                className={`nav-link w--current ${
                  props.subscriptionActive &&
                  (!props.subscriptionType ||
                    props.subscriptionType?.toLowerCase() != "lite")
                    ? ""
                    : "not-subscribed"
                }`}
              >
                Overview
              </Link>
              } 
            </div>
            
            {/* <div className="holder-link">
                <Link 
                  to="/seller/dashboard/marketing" 
                  aria-current="page"
                  className="nav-link w--current"
                >
                  My Card
                </Link>
            </div> */}

            <div className="holder-link">
              <Link
                to="/seller/dashboard/ads"
                aria-current="page"
                className={`nav-link w--current`}
              >
                Marketing
              </Link>
              <img 
                  style={{height: "27px"}}
                  src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif"
                />
            </div>
          </div>








          {props.accountType=="Personal" || props.subscriptionType=="lite"?

            (navbarPromo?
              <div className="navbar-promo business-features">
                <div 
                  onClick={() => togglePromo()}
                    data-w-id="26bc9849-f5e3-a88b-8cb5-308cad688054" className="closepopup navbar-close"
                     />
                
                <Link style={{textDecoration: "none"}} to="/seller/dashboard/rewards">
                  <img src="https://res.cloudinary.com/cashaam/image/upload/v1677640473/Website%20Content/Screen_Shot_2023-02-28_at_10.12.01_PM_vzpt8b.webp" />
                </Link>
              </div>
            :
            <div className="nav-store">
              <div className="holder-link">
                <div className="nav-page-title">My Store</div>
              </div>

              <div className="business-features">
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/products">
                    All Products
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/addProduct">
                    Add Product
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/orders">
                    Orders
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/customers">
                    Customers
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/discount">
                    Add Discounts
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/chat">
                    Chat
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/domain">
                    Domains
                  </a>
                </div>
                <div className="holder-link">
                  <a className="nav-link" href="/seller/dashboard/rewards">
                    Billing &amp; Referral
                  </a>
                </div>
                <Link style={{textDecoration: "none"}} to="/seller/dashboard/rewards">
                  <div className="upgrade-business">
                  Upgrade to Business
                  </div>
                </Link>

              </div>
            </div>
          )

          :
          <div className="nav-store">
            <div className="holder-link">
              <div className="nav-page-title">My Store</div>
            </div>
            <div className="holder-link">
              <Link to="/seller/dashboard/products" className="nav-link">
                All Products
              </Link>
            </div>
            {props.subscriptionActive &&
            (!props.subscriptionType ||
              props.subscriptionType?.toLowerCase() != "lite") ? (
              <div className="holder-link">
                <Link to="/seller/dashboard/addProduct" className="nav-link">
                  Add Product
                </Link>
              </div>
            ) : (
              <div
                className="holder-link"
                onClick={() =>
                  showUpgrade(
                    "Upgrade to Add Product",
                    "Your current plan does not allow you to sell products. Upgrade to sell locally and abroad."
                  )
                }
              >
                <div className={`nav-link not-subscribed`}>Add Product</div>
                <div className="freebadge upgrade-badge">UPGRADE</div>
              </div>
            )}

            <div className="holder-link">
              <Link to="/seller/dashboard/orders" className="nav-link">
                Orders
              </Link>
            </div>
            <div className="holder-link">
              <Link to="/seller/dashboard/customers" className="nav-link">
                Customers
              </Link>
            </div>
            {props.userCurrentCountry &&
            props.userCurrentCountry.toLowerCase() !== "nigeria" ? (
              <>
                <div className="holder-link">
                  <Link to="/seller/dashboard/addCatalog" className="nav-link">
                    Add Collections
                  </Link>
                </div>
              </>
            ) : null}
            <div className="holder-link">
              <Link to="/seller/dashboard/discount" className="nav-link">
                Add Discounts
              </Link>
            </div>

            {/* <div className="holder-link">
              <Link to="/seller/dashboard/chat" className="nav-link">
                Chat
              </Link>
            </div> */}

            {/* <div className="holder-link">
              <Link to="/seller/dashboard/content" className="nav-link">
                My AI
              </Link>
              <img 
                style={{height: "27px"}}
                src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif"
               />
                    
            </div> */}

            {props.subscriptionActive &&
            (!props.subscriptionType ||
              props.subscriptionType?.toLowerCase() != "lite") ? (
              <div className="holder-link">
                <Link to="/seller/dashboard/domain" className="nav-link">
                  Domains
                </Link>
              </div>
            ) : (
              <div
                className="holder-link"
                onClick={() =>
                  showUpgrade(
                    "Upgrade to use Domains",
                    "Your current plan does not allow you to connect your domain name. Upgrade your plan."
                  )
                }
              >
                <div className={`nav-link not-subscribed`}>Domains</div>
                <div className="freebadge upgrade-badge">UPGRADE</div>
              </div>
            )}

            <div className="holder-link">
              <Link to="/seller/dashboard/rewards" className="nav-link">
                Billing & Referral
              </Link>
            </div>

            {/* <div className="holder-link">
              <Link to="/seller/dashboard/ads" className="nav-link">
                Ads Help
              </Link>
            </div> */}

          </div>
          }

          

          <div className="nav-others">
          {props.accountType!="Personal" && props.subscriptionType!="lite"?
            (props.subscriptionActive &&
            (!props.subscriptionType ||
              props.subscriptionType?.toLowerCase() != "lite") ? (
              <div className="holder-link">
                {currentTheme ? (
                  <a
                    target="_blank"
                    href={editorUrl}
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                    className="nav-link"
                  >
                    Edit Theme
                  </a>
                ) : (
                  <a
                    onClick={() =>
                      goToCashaamTheme(
                        props.sellerId,
                        onShowThemePop,
                        setEditorUrl
                      )
                    }
                    style={{ cursor: "pointer" }}
                    className="nav-link"
                  >
                    Cashaam Plus+
                  </a>
                )}
              </div>
            ) : (
              <div
                className="holder-link"
                onClick={() =>
                  showUpgrade(
                    "Upgrade to use Cashaam Plus+",
                    "Your current plan does not allow you to create beautiful websites to sell. Upgrade your plan."
                  )
                }
              >
                <div className={`nav-link not-subscribed`}>Cashaam Plus+</div>
                {/* <div className="freebadge upgrade-badge">UPGRADE</div> */}
              </div>
            ))
          :null}

            <div className="holder-link">
              <Link to="/seller/dashboard/settings" className="nav-link">
                Settings
              </Link>
            </div>
            <div className="holder-link">
              <a
                target="_blank"
                href="https://grow.cashaam.com"
                className="nav-link"
              >
                FAQ
              </a>
            </div>
            <div className="holder-link">
              <a
                onClick={() => props.logoutHandler()}
                style={{ cursor: "pointer" }}
                className="nav-link"
              >
                Log Out
              </a>
            </div>
          </div>
        </div>
        <div className="holder-brand" />
      </div>
      {showThemePop === true ? (
        <Modal show={showThemePop}>
          {/* <ThemeMessage
            sellerId={props.sellerId}
            onShowThemePop={onShowThemePop}
            showGoToThemeEditor={showGoToThemeEditor}
            url={editorUrl}
            onShowGoToThemeEditor={onShowGoToThemeEditor}
          /> */}
          <ThemeActivation
            discountCode={discountCode}
            handleChange={handleChange}
            sellerId={props.sellerId}
            onShowThemePop={onShowThemePop}
            showGoToThemeEditor={showGoToThemeEditor}
            url={editorUrl}
            onShowGoToThemeEditor={onShowGoToThemeEditor}
            confirmThemeChange={confirmThemeChange}
            errorMsg={errorMsg}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default LeftNav;
