import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";
import clap from "../../../../images/clap-new.jpeg";
import firebase from "../../../../firebaseConfig/index";
import getUserLocation from "../../../helpers/getUserLocation";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";

class OnboardingPage extends React.Component {
  state = {
    currentStep: null,
    userCurrentCountry: "",
    firstName: null,
  };

  getAccountInfo= async(id)=>{
    const db = firebase.firestore();
    let result = await db.collection("sellersAccounts").doc(id).get();
    let storeInfo = result.data();
    let { accountType, slug, country } = storeInfo;


    this.setState({
      accountType, 
      slug,
      country
    });
  }


  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      shopCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  setTimer = () => {
    setTimeout(() => {
      this.componentDidMount();
    }, 10000)
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      console.log("user", user);
      if (user) {
        let step = await getRegistrationProgress(user.uid);
        step = parseFloat(step);

        await this.getAccountInfo(user.uid);
        await this.getLocation()
        console.log("STEP", step);
        if (step > 3) {
          this.props.history.push("/seller/dashboard");
        }
        this.setState({ currentStep: step, firstName: user.displayName });
      } else {
        this.props.history.push("/seller/signup");

      }
    });
  }

  render() {
    console.log("test", this.state.currentStep > 4);
    if (!this.state.firstName) {
      this.setTimer()
    }
    return (
      <>
        {this.state.firstName ? (
          <>
            {" "}
            <div className="section-onboarding">
              <div className="onboarding">
                <div className="block-title-1">
                  <div className="holder-svg">
                    <img
                      style={{ height: "70px", width: "70px" }}
                      src={clap}
                      alt
                      className="clap"
                    />
                  </div>
                  <h4 className="h4">
                    Thank you for signing up, {this.state.firstName}!
                  </h4>
                </div>

                <div className="microcopy-signup">
                  Your Cashaam Business Account creation was successful. In a
                  few minutes, your store will be ready to receive payment. We
                  will walk you through the setup process now.
                </div>
                <div className="block-steps">
                  <div className="steps">
                    {this.state.accountType?.toLowerCase()=="personal"?
                      <div className="guide-text">
                        <h5 className="h5">Personal Information</h5> 
                        <div className="guide-copy">
                          Setup your cashaam tag and address - 2 mins
                        </div>
                      </div>
                      :
                      <div className="guide-text">
                        <h5 className="h5">Store Information</h5> 
                        <div className="guide-copy">
                          Setup your store name and description - 2 mins
                        </div>
                      </div>
                    }
                    <div className="bullet-number">
                      <div className="text-block-2">1</div>
                    </div>{" "}
                    {this.state.currentStep > 1 ? (
                      <div className="btn-steps-done">
                        <div className="text-active"> Completed</div>
                      </div>
                    ) : (
                        <Link to="/seller/dashboard/setup">
                          <div className="btn-steps-active">
                            <div className="text-active">Continue</div>
                          </div>
                        </Link>
                      )}
                  </div>
                  <div className="steps">
                    <div className="guide-text">
                      <h5 className="h5">Payment Information</h5>
                      <div className="guide-copy">
                        Setup and connect your bank account via Stripe - 5 mins
                      </div>
                    </div>
                    <div className="bullet-number">
                      <div className="text-block-2">2</div>
                    </div>
                    {this.state.currentStep < 2 ? (
                      <div className="btn-steps-disabled">
                        <div className="text-disabled">Continue</div>
                      </div>
                    ) : this.state.currentStep > 3 ? (
                      <div className="btn-steps-done">
                        <div className="text-active"> Completed</div>
                      </div>
                    ) : (
                          <Link
                            to={
                              this.state.country?.toLowerCase() !==
                                "nigeria"
                                ? "/seller/dashboard/setup/USbank"
                                : "/seller/dashboard/setup/bank"
                            }
                          >
                            <div className="btn-steps-active">
                              <div className="text-active">Continue</div>
                            </div>
                          </Link>
                        )}
                  </div>
                  {/* <div className="steps">
                <div className="guide-text">
                  <h5 className="h5">Product Upload</h5>
                  <div className="guide-copy">
                    Upload your first 3 Products - 4 mins
                  </div>
                </div>
                <div className="bullet-number">
                  <div className="text-block-2">3</div>
                </div>
                {this.state.currentStep < 4 ? (
                  <div className="btn-steps-disabled">
                    <div className="text-disabled">Continue</div>
                  </div>
                ) : this.state.currentStep > 5 ? (
                  <div className="btn-steps-done">
                    <div className="text-active"> Completed</div>
                  </div>
                ) : (
                  <Link to="/seller/dashboard/addProduct/">
                    <div className="btn-steps-active">
                      <div className="text-active">Continue</div>
                    </div>
                  </Link>
                )}
              </div> */}
                  <div className="steps">
                    {this.state.accountType?.toLowerCase()=="personal"?
                      <div className="guide-text">
                        <h5 className="h5">Create Virtual Card</h5>
                        <div className="guide-copy">
                          Create your cashaam virtual card - 2
                          mins
                        </div>
                      </div>
                      :
                      <div className="guide-text">
                        <h5 className="h5">Add Products</h5>
                        <div className="guide-copy">
                          You must add at least 3 products to start selling - 5
                          mins
                        </div>
                      </div>
                    }
                    <div className="bullet-number">
                      <div className="text-block-2">3</div>
                    </div>
                    {this.state.currentStep < 4 ? (
                      <div className="btn-steps-disabled">
                        <div className="text-disabled">Continue</div>
                      </div>
                    ) : this.state.currentStep < 5 ? (
                      <Link to="/seller/dashboard/addProduct">
                        <div className="btn-steps-active">
                          <div className="text-active">Continue</div>
                        </div>
                      </Link>

                    ) : (
                          <div className="btn-steps-done">
                            <div className="text-active"> Completed</div>
                          </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
            <Link to="/" className="logo-black w-inline-block">
              <img
                src={logo}
                // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
                sizes="(max-width: 767px) 80px, 100px"
                alt
                className="cashaam-logo-dark"
              />
              <img
                src={logoWhite}
                // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
                sizes="100vw"
                alt
                className="cashaam-logo-white"
              />
            </Link>
            {/* <style jsx>{`
          .steps {
            min-width: 500px;
          }
        `}</style> */}
          </>
        ) : (
            <>
              {setTimeout(() => {
                console.log("refreshing page data");
                this.componentDidMount();
              }, 10000)}
              <div className="section-onboarding">
                <div
                  style={{ position: "absolute", bottom: "50%", left: "40%" }}
                  className="onboarding"
                >
                  <div className="block-title-1">
                    <div className="holder-svg">
                      {/* <img src={clap} alt className="clap" /> */}
                    </div>
                    <h4 className="h4">Please wait...</h4>
                  </div>

                  <div className="microcopy-signup">
                    Gathering your information...
                </div>
                </div>
              </div>
              <Link to="/" className="logo-black w-inline-block">
                <img
                  src={logo}
                  // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
                  sizes="(max-width: 767px) 80px, 100px"
                  alt
                  className="cashaam-logo-dark"
                />
                <img
                  src={logoWhite}
                  // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
                  sizes="100vw"
                  alt
                  className="cashaam-logo-white"
                />
              </Link>
            </>
          )}
      </>
    );
  }
}
export default OnboardingPage;
