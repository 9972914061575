
const priceFormaterIntl = (number, currency) => {
  try {
    // console.log("commonCurrencies JSON", commonCurrencies);

    let supportedCurrencies = {
      NGN: {
        symbol: "₦",
        name: "Nigerian Naira",
        symbol_native: "₦",
        decimal_digits: 2,
        rounding: 0,
        code: "NGN",
        name_plural: "Nigerian nairas",
      },
      GBP: {
        symbol: "£",
        name: "British Pound Sterling",
        symbol_native: "£",
        decimal_digits: 2,
        rounding: 0,
        code: "GBP",
        name_plural: "British pounds sterling",
      },
      CAD: {
        symbol: "CA$",
        name: "Canadian Dollar",
        symbol_native: "$",
        decimal_digits: 2,
        rounding: 0,
        code: "CAD",
        name_plural: "Canadian dollars",
      },
      EUR: {
        symbol: "€",
        name: "Euro",
        symbol_native: "€",
        decimal_digits: 2,
        rounding: 0,
        code: "EUR",
        name_plural: "euros",
      },
    };

    let options = {};
    let numberFormat;
    let price;
    if (currency === "N" || currency === "NGN" || currency==="GBP" || currency==="EUR" || currency==="CAD") {
      options = { style: "currency", currency: "USD" };
      numberFormat = new Intl.NumberFormat("en-US", options);
      price = numberFormat.format(number);
      price = price.substr(1);
      let symbol = supportedCurrencies[currency].symbol;
      return `${symbol}${price}`;
    } else {
      options = { style: "currency", currency: "USD" };
      numberFormat = new Intl.NumberFormat("en-US", options);
      price = numberFormat.format(number);
      return price;
    }
  } catch (error) {
    console.log("error formating price", error);
    return error;
  }
};

export default priceFormaterIntl;
