import React from "react";

const SelfShippingPop = (props) => {
    return (
        <div style={{ display: "flex" }} className="tint-background">
            <div className="pop-container">
                <div style={{ display: "block" }} className="pop-ng-pickup mc-pop-ng-pickup">
                    <div className="pop-header">
                        <div className="text-main">Self Shipping</div>
                        <a onClick={() => props.onHideSelfShippingPop()} className="box-btn-primary w-inline-block">
                            
                             <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-x-circle"
                                >
                                <circle cx={12} cy={12} r={10} />
                                <line x1={15} y1={9} x2={9} y2={15} />
                                <line x1={9} y1={9} x2={15} y2={15} />
                             </svg>
                        </a>
                    </div>
                    {/* <div className="pop-content">
            <div className="sub-title">Scheduling your Pickup</div>
            <div className="pop-text bold">
              Before you schedule your pickup, here’s a few things to note.
            </div>
            <div className="pop-text">
              1. Ensure your Product is ready to be shipped before you schedule.
              <br />
              2. Your shipping Tracking number is activated once you click on
              schedule pickup and the pickup will be processed.
              <br />
              3. This process cannot be reversed and you will be charged.
              <br />
              4. Once your pickup is scheduled a tracking number will be
              displayed in the box below.{" "}
            </div>
          </div> */}


            {props.successSubmission?
                         <div className="airtime-resp-container">
                    
                            <div className="success-payment"></div>
                            <div className="airtime-resp">The order's tracking information has be successfully updated. Your payment for the self shipping will be transfered to you in the next 24hrs.</div>
                        </div>
                    :

                    <div className="pop-content">
                        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                            <div className="sub-title">Shipping Information</div>
                            <div className="tracking-information">
                            Enter the shipping carrier, the tracking number, and estimated delivery date for this delivery. Your customer's order details will be updated and they will get a notification to track their delivery.
                            </div>
                            {/* {!props.tracking_number ? <div onClick={() => props.showManualScheduleShip(true)} style={{ cursor: "pointer", width: "120px", padding: "2px 2px", }} className="btn-secondary">  <div style={{ fontSize: "14px" }} className="text-btn-tertiary">Edit Shipping Info</div></div> : null} */}
                        </div>
                        <div className="pop-text bold">
                        </div>

                        {props.errorMessage ? <div style={{ borderColor: "#ff8c00", borderWidth: "2px", fontSize: "14px" }} className="tracking-box">
                        <div>{props.errorMessage}</div> </div> : null}

                        <div className="pop-text">
                            <div style={{ display: "flex",flexDirection: "column" }}>
                            <label className="dim-label">Shipping Provider Name</label>

                            <select
                                onChange={(e)=>props.selectShippingProvider(e.target.value)}
                                className={`time-input ${
                                    props.errorType && props.errorType=="selfShipProviderName" ? "input-error input-text w-input" : "input-text w-input"
                                }`}
                                name="selfShipProviderName" 
                                style={{ paddingLeft: "5px" }}
                            >
                                <option disabled selected value>Select a Shipping Provider Name</option>
                                <option value="ARAMEX">ARAMEX</option>
                                <option value="DHL">DHL</option>
                                <option value="EMS Speed Post">EMS Speed Post</option>
                                <option value="FedEx">FedEx</option>
                                <option value="GIG">GIG</option>
                                <option value="GOKADA">GOKADA</option>
                                
                                <option value="Kwik">Kwik</option>
                                <option value="Sendbox">Sendbox</option>
                                <option value="UPS">UPS</option>
                                <option value="Dispatch Rider">Dispatch Rider</option>
                                <option value="Other">Other</option>
                            </select>

                            {props.selfShipProviderName=="Other"?
                                <input placeholder='Enter Shipping Provider Name - Type "N/A" if not available' onChange={props.handleChange} name="otherShipping" 
                                className={`time-input ${
                                    props.errorType && props.errorType=="selfShipProviderName" ? "input-error input-text w-input" : "input-text w-input"
                                }`}
                                style={{ paddingLeft: "5px" }} />
                            :null}

                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label className="dim-label">Tracking Number</label>
                                <input placeholder='Enter the Tracking Number - Type "N/A" if not available' onChange={props.handleChange} name="selfShipTrackNumber" 
                                    className={`time-input ${
                                        props.errorType && props.errorType=="selfShipTrackNumber" ? "input-error input-text w-input" : "input-text w-input"
                                    }`}
                                    style={{ paddingLeft: "5px" }} 
                                />
                            </div>
                            <div style={{ display: "flex",flexDirection: "column" }}>
                                <label className="dim-label">Estimated Delivery Date</label>

                                <input 
                                    type="date" id="start" name="trip-start"
                                    onChange={props.changeDate}
                                    className={`time-input ${
                                        props.errorType && props.errorType=="estimatedDeliveryDate" ? "input-error input-text w-input" : "input-text w-input"
                                    }`}
                                    style={{ paddingLeft: "5px" }}
                                />
                            </div>
                        </div>
                    </div>
                }

                {!props.successSubmission?
                    <div className="pop-footer tracking-btn-container">

                        {props.loading === false ? (<>
                           
                            <div onClick={() => props.onCompleteSelfShipping()}
                                 style={{ cursor: "pointer" }}
                                className="self-shipping-btn">
                                Done
                            </div>
                            
                            <div>
                            {props.sellerCountry?.toLowerCase()=="nigeria"?
                                <a  href="https://api.whatsapp.com/message/KCMW4LVXEVS6B1" 
                                    target="_blank"
                                    className="customer-support">Contact Support
                                </a>
                              : 
                              <a href="https://api.whatsapp.com/message/WAIDJC3FXFPEN1" 
                                    target="_blank"
                                    className="customer-support">Contact Support
                                </a>
                            }
                            
                                
                            </div>
                            {/* <div onClick={() => props.onHideSelfShippingPop()}
                                style={{ cursor: "pointer", marginTop: "10px" }}
                                className="btn-secondary"
                            >

                                <div
                                    className="text-btn-tertiary">Cancel</div>
                            </div> */}
                        </>) : null}
                    </div>

                :null}
                </div>
            </div>
        </div>
    );
};
export default SelfShippingPop;
