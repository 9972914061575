import firebase from "../../../firebaseConfig";

const getUserTheme = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let snap = await db.collection("sellersAccounts").doc(id).get();
      let sellerInfo = snap.data();
      let { themeName } = sellerInfo;
      if (themeName) {
        resolve(themeName);
      } else {
        resolve(null);
      }
    } catch (error) {
      console.log("error getting user theme", error);
      reject(error);
    }
  });
};
export default getUserTheme;
