import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";

import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";

import {
  saveCatalog,
  getCatalogs,
  deleteCatalog,
} from "../../../helpers/catalogs";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import AddCatalogForm from "./AddCatalogForm";
import getUserLocation from "../../../helpers/getUserLocation";
import getUnreadChat from "../../../helpers/getUnreadChat";
import getBasicInfo from "../../../helpers/getBasicInfo";
import NProgress from 'nprogress';
const db = firebase.firestore();

export default class AddCatalog extends Component {
  state = {
    sellerId: "",
    catalog: "",
    catalogs: [],
    errorMessage: "",
    userCurrentCountry: null
  };

  showErrorMessage = (message) => {
    this.setState({ errorMessage: message });

    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, 10000);
  };

  handleDelete = async (catalog) => {
    try {
      const catalogs = await deleteCatalog(catalog);
      this.setState({
        catalogs,
      });
    } catch (err) {
      console.error("An error occured when deleting catalogs");
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const catalogs = await saveCatalog(this.state.catalog);
      this.setState({ catalogs, catalog: "" });
    } catch (error) {
      console.error("Error occurred when saving catalog", error);
      this.setState({ catalogs: [] });
    }
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  componentDidMount() {
    NProgress.start();
    // check if seller is logged in
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.getLocation();
        const storeInfo = await getBasicInfo(user.uid);
        this.setState({ storeInfo})

        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );
        const catalogs = await getCatalogs();
        this.setState({ catalogs: catalogs ? catalogs : [], sellerId: user.uid });
        NProgress.done();
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <ToastContainer />
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            <AddCatalogForm
              handleChange={this.handleChange}
              catalog={this.state.catalog}
              catalogs={this.state.catalogs}
              handleSubmit={this.handleSubmit}
              handleDelete={this.handleDelete}
            />
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="addProduct"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
