import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";

import Loader from "react-loader-spinner";
import VerifyButton from "../stripe/VerifyButton";
import { loadStripe } from "@stripe/stripe-js";
import {STRIPE_KEY} from "../../../../secrets"
const stripePromise = loadStripe(STRIPE_KEY);

import getBasicInfo from "../../../helpers/getBasicInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import firebase from "../../../../firebaseConfig";

class VerificationPage extends React.Component {
  state = {
    displayVerification: false,
    step1: false,
    step2: false,
    step3: false,
  };

  handleSteps = (e) => {
    let value = e.target.checked;
    console.log("step", e.target.name, value);
    this.setState({
      [e.target.name]: value,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  verificationFeedback=(feedback, stripeMsg)=>{

    this.setState({
      verificationSuccess: false,
      verificationError:false,
      errorMsg:""
    })
    if(feedback=="success"){
      this.setState({verificationSuccess: true});

    }else if(feedback=="error"){
      this.setState({
        verificationError: true, 
        errorMsg: "There has been an error with your verificaction. Please try again."
      });
    }

  }

  async componentDidMount() {
    try {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          let shortReferral = user.uid?.slice(0, 6);
          shortReferral = shortReferral.toUpperCase();

          console.log("shortReferral", shortReferral);
          let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

          this.setState({
            shortReferral,
            referralLink,
            sellerId: user.uid,
          });
          localStorage.setItem("cashaam_uid", user.uid);

          //check the user registration progress
          let step = await getRegistrationProgress(user.uid);

          step = parseFloat(step);
          // if (step === 100) {
          //   console.log("completed registration step:", step);
          // } else {
          //   console.log("MY STEP", step);
          //   if (step < 4) {
          //     this.props.history.push("/seller/dashboard/setup/onboarding");
          //   }
          // }

          const sellerUid = user.uid;

          const basicInfo = await getBasicInfo(sellerUid);

          this.setState({
            storeInfo: basicInfo,
          });
        } else {
          this.props.history.push("/");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="section-onboarding">
          <div className="onboarding-steps">
            <div className="fixedmodalhead itemaddedmodalhead">
              <div
                className="text-block-4"
                style={{ margin: "auto", fontWeight: "bold", fontSize: "18px" }}
              >
                Verification
              </div>
            </div>

            {this.state.verificationLoading ? (
              <div className="loading-content" style={{ width: "300px" }}>
                <Loader
                  type="TailSpin"
                  color="#7455c1"
                  height={100}
                  width={100}
                />
                <div>Loading...</div>
              </div>
            ) : this.state.verificationSuccess ? (
              <div className="airtime-resp-container">
                <div className="success-payment"></div>
                <div className="airtime-resp">
                  Your verification has been submitted.
                </div>
                <div className="holder-btn">
                
                    <a className="popupbutton w-inline-block" 
                        role="link" 
                        style={{margin:"0 auto", width:"90%",marginTop: "10px", padding: "5px 23px"}}  href="/seller/dashboard"
                    >
                    Done</a>
                
                </div>

              </div>
            ) : this.state.verificationError ? (
              <div className="airtime-resp-container">
                <div className="error-payment"></div>
                <div className="airtime-resp">There has been error. Continue and try again later.</div>
                <div className="holder-btn">
                
                <a className="popupbutton w-inline-block" 
                    role="link" 
                    style={{margin:"0 auto", width:"90%",marginTop: "10px", padding: "5px 23px"}}  href="/seller/dashboard"
                >
                Continue</a>
            
                </div>
              </div>
            ) : (
              <div
                style={{ marginTop: "15px", fontSize: "15px" }}
                className="paymentmodalbuttonwrap"
              >
                <div className="column transfer-container">
                  <div className="steps-title">
                    <input
                      onClick={this.handleSteps}
                      type="checkbox"
                      name="step1"
                      className="steps-check"
                    ></input>
                    <label for="step1">
                      Learn about the verification process
                    </label>
                  </div>
                  {!this.state.step1 ? (
                    <div className="verification-steps">
                      <iframe
                        className="shipping-tutorial"
                        src="https://www.youtube.com/embed/np-w_y4spQI"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowfullscreen
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen"
                        msallowfullscreen="msallowfullscreen"
                        oallowfullscreen="oallowfullscreen"
                        webkitallowfullscreen="webkitallowfullscreen"
                      ></iframe>

                      <div className="virtualcardworkpopuptext">
                        Watch this video about identity verification
                      </div>
                    </div>
                  ) : null}

                  <div className="steps-title">
                    <input
                      onClick={this.handleSteps}
                      type="checkbox"
                      name="step2"
                      className="steps-check"
                    ></input>
                    <label for="step1">Requirements</label>
                  </div>

                  {this.state.step1 && !this.state.step2 ? (
                    <div className="verification-steps">
                      <div>
                        Have one of these identification documents ready
                      </div>
                      <ol type="i">
                        <li>Driving License</li>
                        <li>Government ID</li>
                        <li>Passport</li>
                      </ol>
                    </div>
                  ) : null}

                  <div className="steps-title">
                    <input
                      onClick={this.handleSteps}
                      type="checkbox"
                      name="step3"
                      className="steps-check"
                    ></input>
                    <label for="step1">
                      <em>Optional</em>: Update your profile info
                    </label>
                  </div>
                  {this.state.step1 && this.state.step2 && !this.state.step3 ? (
                    <div className="verification-steps">
                      <div>
                        Update your profile info to match your documents.
                      </div>
                      <div>
                        To Update your profile info go to the{" "}
                        <a href="/seller/dashboard/settings"> settings page</a>
                      </div>
                    </div>
                  ) : null}

                  <div className="steps-title">
                    <input
                      onClick={this.handleSteps}
                      type="checkbox"
                      name="step4"
                      checked={this.state.birthday}
                      className="steps-check"
                    ></input>
                    <label for="step1">Enter your date of birth</label>
                  </div>
                  {this.state.step1 &&
                  this.state.step2 &&
                  this.state.step3 &&
                  !this.state.birthday ? (
                    <div className="verification-steps">
                      <input
                        value={this.state.birthday}
                        onChange={this.handleChange}
                        type="date"
                        className={`formtextfield tabsprofiletextfield dob-input${
                          this.state.dobError ? "input-error no-margin" : ""
                        }`}
                        maxLength={256}
                        name="birthday"
                        placeholder="Enter Your Date of Birth"
                        id="updateProfileFirstName"
                      />
                    </div>
                  ) : null}

                  {this.state.step1 &&
                  this.state.step2 &&
                  this.state.step3 &&
                  this.state.birthday ? (
                    <VerifyButton
                      stripePromise={stripePromise}
                      birthday={this.state.birthday}
                      sellerId={this.state.storeInfo?.id}
                      storeInfo={this.state.storeInfo}
                      verificationFeedback={this.verificationFeedback}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>

        <Link to="/" className="logo-black w-inline-block">
          <img
            src={logo}
            // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
            sizes="(max-width: 767px) 80px, 100px"
            alt
            className="cashaam-logo-dark"
          />
          <img
            src={logoWhite}
            // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
            sizes="100vw"
            alt
            className="cashaam-logo-white"
          />
        </Link>
      </>
    );
  }
}

export default VerificationPage;
