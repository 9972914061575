import firebase from "../../firebaseConfig";

const updateOrderAddress = (
  orderIds,
  sellerId,
  customerId,
  street,
  city,
  state,
  country,
  zipcode,
  apt
) => {
  return new Promise(async (resolve, reject) => {
    try {
        console.log("update=>",{
            orderIds,
            sellerId,
            customerId,})
      const db = firebase.firestore();
      let batch = db.batch();

      await Promise.all(
        orderIds.map((orderId) => {
          let sellerRef = db
            .collection("sellersAccounts")
            .doc(sellerId)
            .collection("stores")
            .doc(sellerId)
            .collection("orders")
            .doc(orderId);

          let buyerRef = db
            .collection("buyersAccounts")
            .doc(customerId)
            .collection("orders")
            .doc(orderId);


          batch.set(
            sellerRef,
            {
                street,
                city,
                state,
                country,
                zipcode,
                apt
            },
            { merge: true }
          );

          batch.set(
            buyerRef,
            {
                street,
                city,
                state,
                country,
                zipcode,
                apt
            },
            { merge: true }
          );

        })
      );

      await batch.commit();

      resolve("success");
    } catch (error) {
      console.log("error changing order address", error);
      reject(error);
    }
  });
};
export default updateOrderAddress;
