import React from "react";

function SuccessKit(props) {
  return (
    <div className="card affiliate-kit">
      <div className="card__body affiliate-kit__body">
        {/* <div className="affiliate-kit__left" >
              
              </div> */}

        <iframe
          className="affiliate-kit__left"
          src="https://www.youtube.com/embed/deySj4YVlL0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          // allowfullscreen
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen" 
          msallowfullscreen="msallowfullscreen" 
          oallowfullscreen="oallowfullscreen" 
          webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>

        <div className="affiliate-kit__right">
          <div className="section affiliate-kit__box">
            <div className="section__header">
              <div className="section__title">Success kit</div>
              <div className="section__subtitle">
                <span>
                  We wanted to make it as easy as possible to generate passive
                  revenue with your Cashaam referrals. <br /> <br /> Watch this
                  video to learn more about Cashaam referral and rewards system.
                </span>
              </div>
            </div>
            <a
              href="https://drive.google.com/drive/folders/1ZcRTRhjMZT8rsQWhJcNlJhSj65lOB-QN?usp=sharing"
              target="_blank"
              download
              className="btn is-size-md is-theme-primary kit-btn"
              id="affiliate-kit-download"
              data-fde-name="view:account-share-flodesk:click:get-success-kit"
            >
              Download Success Kit
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessKit;
