import React, { Component } from "react";
import PropTypes from "prop-types";

export default class OrderOptions extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    displayDropdownOptions: false
  };

  displayOptions = () => {
    const dropdown = this.state.displayDropdownOptions;
    console.log(this.props.order.email);
    this.setState({
      displayDropdownOptions: !dropdown
    });
  };

  hideDisplayOptions = () => {
    this.setState({
      displayDropdownOptions: false
    });
  };

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideDisplayOptions();
    }
  }

  render() {
    const dropdownStyle = {
      position: "relative",
      display: "inline-block"
    };

    const dropdownContentStyle = {
      position: "absolute",
      backgroundColor: "#dfdfdf",
      minWidth: "160px",
      boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
      zIndex: 1
    };

    const dropdownLinkStyle = {
      color: "black",
      textDecoration: "none",
      display: "block",
      cursor: "pointer",
      padding: "10px"
    };

    return (
      <div>
        <div style={dropdownStyle} ref={this.setWrapperRef}>
          <button
            onClick={() => this.displayOptions()}
            className="box-btn-text-primary"
          >
            Delivery Options
          </button>
          {this.state.displayDropdownOptions === true ? (
            <div id="myDropdown" style={dropdownContentStyle}>
              <a
                onClick={(e) =>
                  this.props.schedulePickup(
                    this.props.order.sellerId,
                    this.props.order.transactionId,
                    this.props.order
                  )
                }
                style={dropdownLinkStyle}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-primary">Schedule Pickup</div>
              </a>
              <a
                onClick={() =>
                  this.props.markAsFullFilled(
                    this.props.order.orderId,
                    this.props.order.customerId
                  )
                }
                style={dropdownLinkStyle}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-primary">Mark as Delivered</div>
              </a>
              <a
                onClick={() =>
                  this.props.onShowSelfShippingPop(this.props.order)
                } style={dropdownLinkStyle}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-primary">Mark as Shipped</div>
              </a>
              <a
                href={`mailto:${this.props.order.email}`}
                style={dropdownLinkStyle}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-primary">Email Customer</div>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
