import React, { Component } from "react";
import Avatar from "./Avatar";
import profilePic from "../../../../../images/prof-pic.jpg"

export default class ChatListItems extends Component {
  constructor(props) {
    super(props);
  }
  selectChat = (e) => {
    for (
      let index = 0;
      index < e.currentTarget.parentNode.children.length;
      index++
    ) {
      e.currentTarget.parentNode.children[index].classList.remove("active");
    }
    e.currentTarget.classList.add("active");


    this.props.goToChat(
      this.props.messagesId, 
      this.props.sellerName, 
      this.props.sellerId, 
      this.props.sellerAvatar, 
      this.props.buyerName, 
      this.props.buyerId, 
      this.props.buyerAvatar
      );

  };

  render() {
    return (
      <div
        style={{ animationDelay: `0.${this.props.animationDelay}s` }}
        onClick={this.selectChat}
        className={`chatlist__item ${
          this.props.active ? this.props.active : ""
        } `}
      >
        <Avatar
          image={
            this.props.buyerAvatar?this.props.buyerAvatar:"https://cdn-icons-png.flaticon.com/512/1160/1160040.png?w=1060&t=st=1670776973~exp=1670777573~hmac=903d7108f6e30eedac5296f2e9f7d3bddaaf71d51b1c9f287683b465ba75b3b0"
          }
          buyerName={this.props.buyerName?this.props.buyerName:"Anonymous"}
          isOnline={this.props.isOnline}
        />

        <div className="userMeta">
          <p>{this.props.buyerName?this.props.buyerName:"Anonymous"}</p>
          <span className="activeTime">{this.props.updatedAt?.toDate().toDateString()}</span>
        </div>
        {this.props.sellerUnread>0?
          <div className="unread">
            <p>{this.props.sellerUnread}</p>
          </div>: null
        }
      </div>
    );
  }
}
