import React from "react";

function AdsHelp(props) {
  return (
    <>
      <div className="helppopupmodal">
        <div className="helppopup">
          <img
            src="https://res.cloudinary.com/cashaam/image/upload/v1664206266/logos/ads_kazguc.webp"
            loading="lazy"
            sizes="(max-width: 479px) 93vw, 384px"
            alt
            className="helppopupimage"
          />
          <div
            data-w-id="cfcff604-8c3d-ecff-4319-d7a66e0db1b3"
            className="closepopup"
            onClick={()=>props.hideAdsHelp()}
          />
          <div className="helppopupcontent">
            <div className="helppopuptitle">
              Speak with a Cashaam Marketing Pro to improve ad performance
            </div>
            <div className="helppopuptext">
              Recent privacy updates may impact your ad performance and
              measurement. Cashaam Marketing Pros are equipped with technical
              solutions and best practices to .mprove your ad results.
            </div>
            <div className="helppopuptext">
              Request a call to get customized recommendations that can help
              improve your campaigns.
            </div>
            <a href="https://calendly.com/cashaam-support" target="_blank" className="helppopupbutton w-inline-block">
              <img src="https://res.cloudinary.com/cashaam/image/upload/v1664206323/logos/Phone_gjtytu.svg" loading="lazy" alt />
              <div>Schedule Call</div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdsHelp;
