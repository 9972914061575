import React from 'react'

import styles from './AddOn.module.css'

const AddOn = (props) => {
    return (
        <div className={styles.addon}>
            <div>
                <div className="box-item">
                    <div className="box-value">
                        {props.addOn.addOnName.length > 30
                            ? `${props.addOn.addOnName.slice(0, 27)}...`
                            : props.addOn.addOnName}
                    </div>
                    <div className="box-value stock-value">
                        {props.addOn.addOnDuration} minutes
                        </div>
                </div>
            </div>
            <div>
                <a
                    onClick={props.editAddOn}
                    style={{ cursor: "pointer" }}
                    className="btn-edit w-inline-block"
                >
                    <div>Edit</div>
                </a>

                <a
                    onClick={props.deleteAddOn}
                    style={{ cursor: "pointer" }}
                    href="#"
                    className="btn-delete w-inline-block"
                >
                    <div>Delete</div>
                </a>
            </div>
        </div>
    )
}

export default AddOn
