import React from 'react'

import priceFormater from '../../../helpers/priceFormater';


const Service = (props) => {
    const editService = () => {
        props.editService(props.service)
    }

    const deleteService = () => {
        props.deleteService(props.service)
    }
    return (
        <div className="item-product">
            <div className="product-block">
                <div
                    style={{ maxWidth: "fit-content", width: "50%", minWidth: "50%" }}
                    className="product-meta"
                >
                    {
                        props.service.images.length > 0 &&
                        <div style={{ marginLeft: "unset" }} className="item-img">
                            <img src={props.service.images[0]} alt className="stock-img" />
                        </div>
                    }
                    <div className="box-item">
                        <div className="box-value">
                            {props.service.name.length > 30
                                ? `${props.service.name.slice(0, 27)}...`
                                : props.service.name}
                        </div>
                        <div className="box-value stock-value">
                            {props.service.duration} minutes
                        </div>
                    </div>
                </div>
                <div className="meta-right">
                    <div className="box-item quater-box">
                        <div className="box-value price">
                            {priceFormater(
                                props.service.price,
                                'USD'
                            )}
                        </div>
                    </div>

                    <div className="box-item quater-box">
                        <div className="box-title hide">Details</div>
                        <a
                            onClick={editService}
                            style={{ cursor: "pointer" }}
                            className="btn-edit w-inline-block"
                        >
                            <div>Edit</div>
                        </a>

                        <a
                            onClick={deleteService}
                            style={{ cursor: "pointer" }}
                            href="#"
                            class="btn-delete w-inline-block"
                        >
                            <div>Delete</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service
