import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class LandingPage extends Component {
  render() {
    return (
      <div>
        <Link to="/seller/signup">Sign Up for Free</Link>
        <br />
        <Link to="/seller/signin">Sign In to Sell</Link>
        <br />
        <Link to="/seller_faq">FAQ</Link>
        <br />
        <Link to="/seller_how_it_works">How it works?</Link>
        <br />
      </div>
    );
  }
}
