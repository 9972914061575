import firebase from "../../firebaseConfig";

const getShippingLabel = (sellerId, orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const orderInfo = await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("orders")
        .doc(orderId)
        .get();

      let { shippingLabelLink } = orderInfo.data();

      if (shippingLabelLink) {
        console.log("found shipping label", shippingLabelLink);
        resolve({ shippingLabelLink });
      } else {
        console.log("no existing shipping label found", shippingLabelLink);
        resolve(false);
      }
    } catch (error) {
      console.log("error getting shipping label", error);
      reject(error);
    }
  });
};
export default getShippingLabel;
