import React from "react";
import { Link } from "react-router-dom";

const PasswordResetForm = (props) => {
  return (
    <div className="container-right">
      <div className="holder-option">
        <div style={{ display: "block" }} className="block-reset-password">
          <div className="block-title">
            <h1 className="h6">Reset Password</h1>
          </div>
          <div className="microcopy-signup reset">
            Enter the Email Address linked to your profile, we will send reset
            instructions.
          </div>
          
          {props.passResetError? 
          <div className="holder-error">
              <div className="txt-error">
              {props.passResetError}
              </div>
          </div>
          :null}

          <div className="w-form">
            <form
              onSubmit={props.resetPassword}
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >
              <input
                onChange={props.onChangeHandler}
                type="text"
                className={props.passResetError? "input-text w-input input-error" : "input-text w-input"}
                maxLength={256}
                name="email"
                data-name="email"
                placeholder="Email Address"
                id="email"
                required
              />
              <input
                type="submit"
                defaultValue="Request a Reset"
                data-wait="Please wait..."
                className="button-primary w-button"
              />
            </form>
          </div>
          <div
            onClick={() => props.displayEmailOnly(true)}
            style={{ cursor: "pointer" }}
            className="microcopy-signup"
          >
            I remember my Login Details
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordResetForm;
