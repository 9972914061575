import React from "react";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";
import {NAIRA_EXCHANGE} from "../../../../secrets";
import Loader from "react-loader-spinner";

class RechargeUS extends React.Component {
  render() {
    return (
      <div className="fixedmodalwrapper gray-background">
        <div style={{overflow:"visible"}} className="fixedmodalcontainer fixed-modal">
          <div className="fixedmodalhead itemaddedmodalhead">
            {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
            <div
              className="text-block-4"
              style={{ margin: "auto", fontWeight: "bold" }}
            >
              {this.props.roundOffAmount?
              "Whole dollar amounts only"
              :this.props.topUpSuccess?
              "Top-Up Success":
              "Add Dollar to Your Card"}
            </div>
          </div>

          {this.props.topUpLoading === true ? (
            <div className="loading-content" style={{ width: "300px" }}>
              <Loader
                type="TailSpin"
                color="#7455c1"
                height={100}
                width={100}
              />
              <div>Loading...</div>
            </div>
          ) : this.props.topUpSuccess ? (
            <div className="airtime-resp-container">
              
              <div className="success-payment"></div>
              <div className="airtime-resp">{priceFormaterIntl(
                      parseFloat(this.props.topupAmount) / NAIRA_EXCHANGE,
                      "USD"
                    )} will be recharged to your card within 1hr.</div>
            </div>
          ) : this.props.roundOffAmount?(
            <div className="airtime-resp-container">
              
              <div style={{backgroundSize:"40px", height:"60px",}} 
              className="error-payment round-error"></div>
              <div style={{textAlign:"center", width:"340px", color:"black", fontSize:"15px"}} className="airtime-resp-container">Your top-up can only be in whole dollars ({priceFormaterIntl(Math.ceil(parseFloat(this.props.topupAmount) / NAIRA_EXCHANGE),"USD")} not {priceFormaterIntl(parseFloat(this.props.topupAmount) / NAIRA_EXCHANGE,"USD")}). Please update your amount so that you can get the whole dollar amount to your card.</div>
            
              <div
                    onClick={() => this.props.goBack()}
                    className="formselectbutton tabupdatebutton"
                    style={{cursor:"pointer", marginTop:"10px",backgroundColor: "#800080"}}
                  >BACK</div>
            </div>

          ) :(
            <div className="paymentmodalbuttonwrap">
              <div className="column transfer-container">


                <div style={{    display: "flex", flexDirection: "row"}}>
                
                  <div style={{position: "relative", width:"100%"}}>
                    <label htmlFor="updateProfileFirstName" className="formlabel">
                      Enter Amount to Top Up
                    </label>
                    <div 
                    className={`topup-us-currency ${
                      this.props.topUpError ? "error" : ""
                    }`}
                    style={{left:"15px"}}
                    >
                      $
                    </div>
                    <input
                      value={this.props.topupAmount}
                      onChange={this.props.changeAmount}
                      type="number"
                      className={`formtextfield tabsprofiletextfield top-up-field topup-us ${
                        this.props.topUpError ? "input-error no-margin" : ""
                      }`}
                      style={{ border: "2px solid #7455c1", fontSize:"18px"}}
                      maxLength={256}
                      name="accountNumber"
                      data-name="Update Profile First Name"
                      placeholder="Enter Amount"
                      id="updateProfileFirstName"
                    />
                  </div>

                </div>
                {this.props.topUpError ? (
                    <div style={{textAlign:"center", }} className="error-message">{this.props.topUpError}</div>
                ) : (
                    null
                )}

                {this.props.dailyLimitReached?
                <div style={{textAlign:"center", color: "black"}} className="error-message">
                  Please {" "}
                  <a href="https://bit.ly/cashaam-ticket" target="_blank" rel="noreferrer noopener">contact us</a>
                   {" "}
                   {this.props.storeInfo?.cardApproval && this.props.storeInfo?.stripeVerification?.status=="verified"?
                   "to top up more.":"to complete your verification."
                   }
                  
                </div>
                  :null
                }

                <div style={{display: "flex", flexDirection:"row", justifyContent: "space-between", marginTop: "10px"}}>
                  <div className="dollar-conv">
                    Total Amount:{" "}
                    {priceFormaterIntl(
                      parseFloat(this.props.totalAmount),
                      "USD"
                    )}
                  </div>

                <div className="topup-fee">Fee: {priceFormaterIntl(
                      parseFloat(this.props.cashaamFee),
                      "USD"
                    )}</div>

                </div>

                {this.props.topupAmount && !this.props.topUpError? (
                  <input
                    type="button"
                    onClick={() => this.props.stripePay()}
                    defaultValue="Top Up"
                    data-wait="Please wait..."
                    id="updateProfileSubmit"
                    className="formselectbutton tabupdatebutton"
                  />

                ) : (
                  <input
                    type="button"
                    style={{ backgroundColor: "gray" }}
                    defaultValue="Top Up"
                    data-wait="Please wait..."
                    id="updateProfileSubmit"
                    className="formselectbutton tabupdatebutton"
                  />
                )}

                {this.props.upgradeSuggester?
                <>
                 <div className="upgrade-info">Upgrade to a different plan to Top Up more.</div>
                  <a 
                    href="/seller/dashboard/rewards" 
                    className="formselectbutton tabupdatebutton upgrage-suggest"
                    >
                      UPGRADE
                    </a>

                  </>

                :null
                }

                {this.props.verificationSuggester && !this.props.storeInfo?.stripeVerification?
                  <>
                  <div className="upgrade-info">Verify your identity to Top Up.</div>
                    <a 
                      onClick={() => this.props.showVerification()}
                      className="formselectbutton tabupdatebutton upgrage-suggest"
                      >
                        Verify
                      </a>

                    </>

                  :null
                }

                <a href="https://blog.cashaam.com/facebook-ads-why-nigerians-should-pay-in-dollars-and-not-naira/" 
                    target="_blank" rel="noopener noreferrer"
                    className="upgrade-info"
                    style={{fontSize:"12px"}}>
                  Save more on Virtual Card with Facebook Dollar Ad Account.
                </a>
                  
                {/* {this.props.virtualCards?
                null:
                <div style={{marginTop: "10px"}} className="freebadge">Get an extra $5 for creating your card today!</div>
                
              } */}
              </div>
            </div>
          )}
          <a
            onClick={() => this.props.hideTopup()}
            className="fixedmodalexitimg w-inline-block"
          />
        </div>
      </div>
    );
  }
}

export default RechargeUS;
