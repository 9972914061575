import firebase from "../../firebaseConfig";

const storeProductFeaturedImage = (imageFile, productName, productCategory) => {
  return new Promise(async (resolve, reject) => {
    try {
      const storage = firebase.storage();
      // const imageFile = this.state.featuredImage;
      // const randomId = Date.now().toString() + "-" + imageFile.name;
      console.log("feat image", imageFile.type);
      if (
        imageFile.type !== "image/png" &&
        imageFile.type !== "image/jpg" &&
        imageFile.type !== "image/jpeg"
      ) {
        const message = `Image of type ${imageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
        console.log(
          "File type is not supported. Uploaded type:",
          imageFile.type
        );
        // alert(message);
        reject("Wrong Image Type");
      }
      const date = Date.now().toString();
      const type = imageFile.type.substring(6);
      let name = productName ? productName.replace(/[^a-zA-Z ]/g, "") : "image";
      name = name.replace(/ /g, "_");
      let category = productCategory
        ? typeof productCategory === "string"
          ? productCategory.replace(/[^a-zA-Z ]/g, "")
          : productCategory.toString().replace(/[^a-zA-Z ]/g, "")
        : "";
      category = category.replace(/ /g, "_");
      const randomId = `${name}_${category}_${date}.${type}`;

      // Create file metadata including the content type
      var metadata = {
        // contentType: 'image/jpeg',
        // To enable Client-side caching you can set the Cache-Control headers here. Uncomment below.
        cacheControl: "public,max-age=300"
      };

      let uploadLogoImageToStorage = storage
        .ref(`products/images/${randomId}`)
        .put(imageFile, metadata);

      uploadLogoImageToStorage.on(
        "state_changed",
        snapshot => {
          // var percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        // checks if anything went wrong when uploading image to storage
        error => {
          alert("error with uploading image");
        },
        () => {
          // once the image uploaded to storage successfully then get the image urls
          storage
            .ref("products/images/")
            .child(`${randomId}`)
            .getDownloadURL()
            .then(imgUrl => {
              resolve(imgUrl);
            });
        }
      );
    } catch (error) {
      console.log("error storing featured Image to firebase storage", error);
      reject(error);
    }
  });
};

export default storeProductFeaturedImage;
