import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";
import eye from "../../../../images/eye.svg";
import eyeOff from "../../../../images/eye-off.svg";
import PhoneInput from "react-phone-input-2";
import EmailLogin from "./EmailLogin";
import PhoneLogin from "./PhoneLogin";
import PasswordResetForm from "./PasswordResetForm";
import ResetEmailSent from "./ResetEmailSent";

const LoginPage = (props) => {
  console.log("props", props);
  return (
    <div className="section-full">
      <Link to="/" className="logo-bg-dark w-inline-block">
        {/* <img
          src={logo}
          //   srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
          sizes="100vw"
          alt
          className="cashaam-logo-dark"
        /> */}
        <img
          src={logoWhite}
          //   srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
          sizes="(max-width: 479px) 60px, (max-width: 767px) 90px, 100px"
          alt
          className="cashaam-logo-white"
        />
      </Link>
      <div className="container-left">
        <div className="bg-img-login">
          <div className="holder-copy">
            <h1 className="h1">A better way to sell online.</h1>
            <h1 className="h1 hidden">Own a Free Store and Sell</h1>
            <h3 className="h3 hidden">Sell on your Website, Amazon and more.</h3>
            <h3 className="h3">Sell on your Website, Amazon and more.</h3>
            <div className="copy-18">
              You can have your store up and ready to launch in 15 minutes or
              less.
            </div>
            <div className="copy-18 hidden">
              Sell more, grow your business online. Millions of customers are
              waiting! We can help you reach more people and expose your
              business for it for free.
            </div>
          </div>
        </div>
      </div>
      {props.displayLoginWithPhone === true ? (
        <PhoneLogin
        onChangeHandler={props.onChangeHandler}
        handlePhoneInput={props.handlePhoneInput}
        submitConfResult={props.submitConfResult}
        onResendVerificationCode={props.onResendVerificationCode}
        goBackToNormalLogin={props.goBackToNormalLogin}
        loading={props.loading}
        showConfirmationCodeInput={props.showConfirmationCodeInput}
        loginWithPhone={props.loginWithPhone}
      />
      ) : props.displayEmail === true ? (
        <EmailLogin
        loading={props.loading}
        onSubmitHandler={props.onSubmitHandler}
        onChangeHandler={props.onChangeHandler}
        onDisplayLoginWithPhone={props.onDisplayLoginWithPhone}
        displayEmailOnly={props.displayEmailOnly} 
      />
      ) : props.passwordResetSent === false ? (
        <PasswordResetForm
          onChangeHandler={props.onChangeHandler}
          resetPassword={props.resetPassword}
          loading={props.loading}
          displayEmailOnly={props.displayEmailOnly}
          passResetError={props.passResetError}
        />
      ) : (
        <ResetEmailSent displayEmailOnly={props.displayEmailOnly} />
      )}
    </div>
  );
};
export default LoginPage;
