export const Pagination = (props) => {
    
    let numPage = props.numPages ? props.numPages : 0;
    let myArr = new Array(numPage).fill("0");
    return (
      <div className="paginationwrapper" style={{display: "flex"}}>
        <a
          onClick={props.goToPreviousPage}
          className={`pagbutton prev-button w-inline-block ${
            props.currentPage === 1 ? "invisible" : ""
          }`}
        >
          <img 
          src="https://res.cloudinary.com/cashaam/image/upload/v1672541088/icons/Arrow-narrow-left_jsozvj.svg" 
          loading="lazy" alt="prev" />
          <div className="pagtext">Previous</div>
        </a>
        <div className="pagbuttons">

          {props.getPaginationGroup().map((val, index) => (
            props.pages>=val?
            (<div
              className={`pgnumbers ${props.currentPage === val ? "active" : null}`}
              onClick={props.changePage}
            >
              <div>{val}</div>
            </div>)
            :null
          ))}
  

        </div>
        <a
          onClick={props.goToNextPage}
          className={`pagbutton nextbutton w-inline-block ${
            props.currentPage === props.pages ? "invisible" : ""
          }`}
        >
          <img 
          src="https://res.cloudinary.com/cashaam/image/upload/v1672541071/icons/Arrow-narrow-Right_de1zb5.svg" 
          loading="lazy" alt="next" />
          <div className="pagtextnext">Next</div>
        </a>
      </div>
    );
  };
  