import firebase from "../../firebaseConfig";

const logoutHandler = async () => {
  return firebase
    .auth()
    .signOut()
    .then(function() {
      // Sign-out successful.
    })
    .catch(function(error) {
      // An error happened.
      console.log(error, "error with signout");
    });
};

export default logoutHandler;
