import firebase from "../../firebaseConfig";
import { NG_REQ_PICKUP_DELIVERY_URL } from "../../secrets";

const scheduleNigeriaPickup = (shippingObjectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("shippingObjectId", shippingObjectId);
      const db = firebase.firestore();
      //get shipping object from shipment collection
      const shipmentSnap = await db
        .collection("shipping")
        .doc(shippingObjectId)
        .get();
      const shipmentInfo = shipmentSnap.data();
      console.log("shipmentInfo", shipmentInfo);
      let { carrier_account, servicelevel_token } = shipmentInfo;
      let { nigeriaShippingInfo } = shipmentInfo.shipment;

      const queryData = {
        carrier_account,
        servicelevel_token,
        shipment: nigeriaShippingInfo,
      };
      const res = await fetch(NG_REQ_PICKUP_DELIVERY_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          countrycode: "ng",
        },
        body: JSON.stringify(queryData),
      });

      if (res.status === 200) {
        const result = await res.json();

        let scheduleResult = result.status ? result : result[0]; //handle result being returned as an array

        resolve({ scheduleResult });
      } else {
        console.log("error schedule shipping pick up");
        reject("error schedule shipping pick up");
      }
    } catch (error) {
      console.log("error schedule delivery pick up", error);
      reject(error);
    }
  });
};
export default scheduleNigeriaPickup;
