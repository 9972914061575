import React from "react";

const ResetEmailSent = (props) => {
  return (
    <div className="container-right">
      <div className="holder-option">
        <div style={{ display: "block" }} className="block-reset-email">
          <div className="block-title">
            <h1 className="h6">We sent at Email</h1>
          </div>
          <div className="microcopy-signup reset">
            We have sent instructions to your email on how to reset your
            password.{" "}
          </div>
          <div
            onClick={() => props.displayEmailOnly(true)}
            style={{ cursor: "pointer" }}
            className="btn-secondary"
          >
            <div className="text-btn-secondary">Back to Login</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetEmailSent;
