const getDateFromTimestamp = (timestamp) => {
  try {
    let date = timestamp.split(" ");
    date = date[0];
    date = date.split("-");
    date[0] = parseInt(date[0]);
    date[1] = parseInt(date[1]);
    date[2] = parseInt(date[2]);

    date = new Date(date[0], date[1] - 1, date[2]).toLocaleDateString();
    return date;
  } catch (error) {
    console.log("error converting timestamp to date", error);
    return timestamp;
  }
};
export default getDateFromTimestamp;
