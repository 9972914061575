import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../../../../firebaseConfig";
import UpdateStoreSuccess from "../../../common/UpdateStoreSuccess";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import phone from "phone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {VirtualCardAPI} from "../../../../secrets"

const UpdateProfile = (props) => {
  return (
    <div className="pop-store-update">
      <div className="profile-update">
        <div className="profile-block">
          <div className="block-title-2">
            <h4 className="page-title">Update your Profile</h4>
          </div>
          <div className="microcopy-signup">
            You can update or change your Name or Mobile Number. You cannot
            change your email, because your email is linked to your account.
          </div>
          <div className="product-builder" />
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                onSubmit={props.handleSubmit}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                {props.firstNameError ? (
                    <div className="error-message">{props.firstNameError}</div>
                  ) : <label style={{marginBottom:"0px"}} for="step1">First Name</label> }
                <input
                  value={props.firstName}
                  onChange={props.handleChange}
                  type="text"
                  className={
                    props.firstNameError
                      ? "input-text w-input input-glow input-error"
                      :"input-text w-input"
                  }
                  maxLength={256}
                  name="firstName"
                  data-name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  required
                />

                  {props.lastNameError ? (
                    <div className="error-message">{props.lastNameError}</div>
                  ) : <label style={{marginBottom:"0px"}} for="step1">Last Name</label>}
                <input
                  value={props.lastName}
                  onChange={props.handleChange}
                  type="text"
                  className={
                    props.lastNameError
                      ? "input-text w-input input-glow input-error"
                      : "input-text w-input"
                  }
                  maxLength={256}
                  name="lastName"
                  data-name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  required
                />
                <PhoneInput
                                onlyCountries={["us", "ng"]}
                  inputClass="input-text w-input"
                  inputStyle={{
                    minWidth: "100%",
                  }}
                  containerStyle={{
                    marginBottom: "8px",
                  }}
                  country={props.location !== "nigeria" ? "us" : "ng"}
                  value={props.phoneNumber}
                  onChange={(phoneNumber) =>
                    props.handlePhoneInput(phoneNumber)
                  }
                />

                  {props.dobError?
                  <div style={{ color: "red",marginBottom: "5px"}}>{props.dobError}</div>
                  :
                  <label for="step1">Date of Birth<em>(13yrs or older)</em></label> 
                  }
                    <input
                    value={props.birthday}
                    onChange={props.handleChange}
                    type="date"
                    className={`input-text w-input half-width-inputs`}
                    maxLength={256}
                    name="birthday"
                    placeholder="Enter Your Date of Birth"
                    id="updateProfileFirstName"
                    max="2010-04-30"
                    />

                <div className="meta-additions" />
                <input
                  type="submit"
                  value="Update Profile"
                  defaultValue="Update Profile"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <input
                  style={{
                    backgroundImage: "none",
                    backgroundColor: "grey",
                    marginTop: "5px",
                  }}
                  onClick={() => {
                    props.displayPasswordChangeForm();
                  }}
                  type="button"
                  value="Change Password"
                  defaultValue="Change Password"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
                  className="microcopy-signup go-back"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.cancel()}
                >
                  Cancel
                </div>
              </form>
              {/* <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div> */}
              {props.updateError ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>
                  {props.errorMessage}{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConfirmChanges = (props) => {
  return (
    <div className="pop-store-update">
      <div className="profile-update">
        <div className="profile-block">
          <div className="block-title-2">
            <h4 className="page-title">Confirm Changes</h4>
          </div>
          <div className="microcopy-signup">
            Enter your password to confirm change
          </div>
          <div className="product-builder" />
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                <input
                  onChange={props.handleChange}
                  type="password"
                  className="input-text w-input"
                  maxLength={256}
                  name="password"
                  data-name="password"
                  id="password"
                  required
                />

                <div className="meta-additions" />
                <input
                  onClick={() => {
                    props.submitChanges();
                  }}
                  type="button"
                  value="Confirm"
                  defaultValue="Confirm"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
              onClick={() => props.backToSettings()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >Cancel</div>
              </form>
              {props.updateError ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>
                  {props.errorMessage}{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const VerifyNewNumber = (props) => {
  return (
    <div className="pop-store-update">
      <div className="profile-update">
        <div className="profile-block">
          <div className="block-title-2">
            <h4 className="page-title">Verify Phone Number</h4>
          </div>
          <div className="microcopy-signup">
            Enter the OTP code sent to your phone to verify this Phone Number
          </div>
          <div className="product-builder" />
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                <input
                  onChange={props.handleChange}
                  type="text"
                  className="input-text w-input"
                  maxLength={256}
                  name="confirmationCode"
                  data-name="confirmationCode"
                  id="confirmationCode"
                  placeholder="Confirmation Code"
                  required
                />

                <div className="meta-additions" />
                <input
                  type="button"
                  onClick={() => {
                    props.submitChanges();
                  }}
                  value="Verify"
                  defaultValue="Verify"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
              onClick={() => props.backToSettings()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Cancel
            </div>
              </form>
              {props.updateError ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>
                    {props.errorMessage}{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChangePassword = (props) => {
  return (
    <div className="pop-store-update">
      <div className="profile-update">
        <div className="profile-block">
          <div className="block-title-2">
            <h4 className="page-title">Update your Password</h4>
          </div>
          <div className="microcopy-signup">
            Enter your old password and your new password, to make a change
            request.
          </div>
          <div className="product-builder" />
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                <input
                  placeholder="Current Password"
                  onChange={props.handleChange}
                  type="password"
                  className="input-text w-input"
                  maxLength={256}
                  name="resetPasswordZero"
                  data-name="resetPasswordZero"
                  id="resetPasswordZero"
                  required
                />
                <input
                  placeholder="New Password"
                  onChange={props.handleChange}
                  type="password"
                  className="input-text w-input"
                  maxLength={256}
                  name="resetPasswordOne"
                  data-name="resetPasswordOne"
                  id="resetPasswordOne"
                  required
                />
                <input
                  placeholder="Confirm Password"
                  onChange={props.handleChange}
                  type="password"
                  className="input-text w-input"
                  maxLength={256}
                  name="resetPasswordTwo"
                  data-name="resetPasswordTwo"
                  id="resetPasswordTwo"
                  required
                />
                <div className="meta-additions" />
                <input
                  onClick={() => {
                    props.resetPassword();
                  }}
                  type="button"
                  value="Update Password"
                  defaultValue="Update Password"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.cancel()}
                >
                  Cancel
                </div>
              </form>
              {props.updateError ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>
                  {props.errorMessage}{" "}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class UpdateLogin extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmationCode: "",
    updateSuccess: false,
    updateError: false,
    showPasswordField: false,
    showUpdatePasswordForm: false,
    showPhoneConfirmation: false,
    phoneNumberUpdated: false,
    invalidPhoneVerificationCode: true,
    resetPasswordZero: "",
    resetPasswordOne: "",
    resetPasswordTwo: "",
    errorMessage: ""
  };

  handleChange = (e) => {

    if(e.target.name=="birthday"){
      let dod=e.target.value;
      console.log("dob",dod)
      if(new Date(dod) > new Date('2010-04-30')){
        console.log("dob is before 2010")
        let dobError="You must be older than 13yrs old."
        this.setState({
          dobError,
          [e.target.name]: e.target.value,
          dobChange: true
        });

      }else if(new Date(dod) < new Date('1900-01-30')){
        let dobError="Please enter your birthday"
        this.setState({
          dobError,
          [e.target.name]: e.target.value,
          dobChange: true
        });
      }else{
        this.setState({
          [e.target.name]: e.target.value,
          dobError:"",
          dobChange: true
        });
      }

    }else{
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    
  };

  handlePhoneInput = (pNumber) => {
    let number = "+" + pNumber;
    console.log(number);
    this.setState({ phoneNumber: number });
  };

  confResult = null;
  handleSubmit = async (e) => {

  try{
    e.preventDefault();
    var user = firebase.auth().currentUser;

    this.setState({ 
      firstNameError:"",
      lastNameError:"",
      dobError:""
    })
    let{
      firstName,
      lastName,
      birthday,
      dobError
    }=this.state;

    if (!firstName || firstName.length < 2) {
      
      this.setState({
        firstNameError: "Please enter your first name",
      });
      return;
    }

    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(format.test(firstName)){
      
      this.setState({
        firstNameError: "Remove space or special character",
      });
      return;
    }

    if(/\d/.test(firstName)){
      
      this.setState({
        firstNameError: "Remove numbers from your name",
      });
      return;
    }

    if(firstName && firstName.length >12){
      
      this.setState({
        firstNameError: "Name can't be more than 12 letters",
      });
      return;
    }

    if (!lastName || lastName.length < 2) {
      
      this.setState({
        lastNameError: "Please enter your last name",
      });
      return;
    }

    if(format.test(lastName)){
      
      this.setState({
        lastNameError: "Remove space or special character",
      });
      return;
    }

    if(/\d/.test(lastName)){
      
      this.setState({
        lastNameError: "Remove numbers from your name",
      });
      return;
    }

    if(lastName && lastName.length >12){
      
      this.setState({
        lastNameError: "Name can't be more than 12 letters",
      });
      return;
    }

    if(!birthday && !dobError){
      this.hideLoading();
      this.setState({
        dobError: "Please enter your birthday",
      });
      return;
    }

    //match phone number format from state to the format on firebase auth
    let number = this.state.phoneNumber.replace(/\s/g, ""); //remove whitespace
    number = number.replace(/[()]/g, ""); //remove parentheses
    number = number.replace(/-/g, ""); //remove dashes
    if (!number.includes("+")) { //ensure add + if one doesn't exist
      number = "+" + number;
    }
    //check if phoneNumber was changed and update the phone Auth
    if (number !== user.phoneNumber) {
      var phoneNumber = number.replace(/\+/g,"");
      var appVerifier = window.recaptchaVerifier;
      console.log("Number", `+${phoneNumber}`);
      // let eSixFourFormatNumberArray = phone(`+${phoneNumber}`);
      // console.log("e64 array", eSixFourFormatNumberArray);
      // let eSixFourFormatNumber = eSixFourFormatNumberArray[0];
      // console.log("e64", eSixFourFormatNumber);

      firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          this.confResult = confirmationResult;
          console.log(this.confResult);
          console.log(confirmationResult);
          this.setState({
            showPhoneConfirmation: true,
            phoneNumberUpdated: true,
          });
        })
        .catch(function (error) {
          // Error; SMS not sent
          // ...
          console.log(error);
        });
    } else {
      this.setState({ showPasswordField: true, phoneNumberUpdated: false });
    }

  }catch(error) {
      console.log(error);
  }
  };

  submitChanges = async (value) => {
    // capture the current user uid
    var user = firebase.auth().currentUser;
    const sellerUid = user.uid;
    const db = firebase.firestore();
    let { firstName, lastName, email, phoneNumber, password,birthday,dobChange, cardHolderId } = this.state;
    let batch = db.batch();
    const code = this.state.confirmationCode;
    //enforce phone Number format before saving to firebase
    let formatedPhoneNumber = phoneNumber.replace(/[- )(\+]/g, "");
    if (!phoneNumber.includes("+")) {
      phoneNumber = "+" + phoneNumber;
    }
    const storeDataToDatabase = async () => {
      var userRef = db.collection("sellersAccounts").doc(sellerUid);

      var storeRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      batch.set(
        userRef,
        {
          firstName,
          lastName,
          phoneNumber,
          formatedPhoneNumber,
          dob:birthday
        },
        { merge: true }
      );

      batch.set(
        storeRef,
        {
          firstName,
          lastName,
          phoneNumber,
          // email,
          formatedPhoneNumber,
        },
        { merge: true }
      );

      batch
        .commit()
        .then(async () => {
          try {
            //update display name
            await user.updateProfile({
              displayName: firstName,
            });

            // //udpate email
            // await user.updateEmail(email);

            // //send email verification
            // user.sendEmailVerification();

            // this.setState({
            //   updateSuccess: true,
            //   updateError: false,
            // });
            this.backToSettings();
          } catch (error) {
            this.setState({
              updateError: true,
            });
            console.log("error updating profile", error);
          }
        })
        .catch((err) => {
          this.setState({
            updateError: true,
          });
          console.log(err, "<--- error with saving profile Info");
        });
    };

    if (this.state.phoneNumberUpdated === true) {
      var credential = await firebase.auth.PhoneAuthProvider.credential(
        this.confResult.verificationId,
        code
      );

      user
        .updatePhoneNumber(credential)
        .then((res) => {
          console.log("test", res);
          if (firstName && lastName && phoneNumber) {
            storeDataToDatabase();

            if(dobChange && cardHolderId){
              this.updateCardHolder();

            }
          } else {
            toast.error("Please enter all necessary information");
            return;
          }
        })
        .catch((error) => {
          console.log("error updating phone number", error);
          this.setState({
            updateError: true,
            invalidPhoneVerificationCode: true,
            errorMessage: error.message
          });
          if (error.code === "auth/provider-already-linked") {
            return toast.error(
              "This Phone number is already linked to an existing account."
            );
          } else if (error.code === "auth/invalid-verification-code") {
            return toast.error("Invalid code. Please try again.");
          } else if (error.code === "auth/missing-verification-code") {
            return toast.error(
              "Please enter the verification code sent to your phone"
            );
          }
        });
    } else {
      if (firstName && lastName && phoneNumber && password) {
        //make sure the password entered by the user to confirm changes is valid before pushing to DB
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then((user) => {
            if (user) {
              storeDataToDatabase();

              if(dobChange && cardHolderId){
                this.updateCardHolder();
              }

            }
          })
          .catch((error) => {
            console.log("error authenticating", error);
            this.setState({ updateError: true });
          });
      } else {
        toast.error("Please enter all necessary information");
        this.setState({ updateError: true });
        return;
      }
    }

    // if (firstName && lastName && phoneNumber && password) {
    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(email, password)
    //     .then(user => {
    //       if (user) {
    //         storeDataToDatabase();
    //       }
    //     })
    //     .catch(error => {
    //       console.log("error authenticating",error);
    //       this.setState({ updateError: true });
    //     });
    // } else {
    //   toast.error("Please enter all necessary information");
    //   return;
    // }
  };

  updateCardHolder= async()=>{
    try{

      let{
        id,
        phoneNumber,
        cardHolderId,
        birthday,
        email,
        stripeAcceptanceTimestamp
      }=this.state;

      let ipAddr="199.36.158.100";

      let queryData={
        sellerId: id,
        cardHolderId,
        phoneNumber,
        dob: birthday,
        ipAddr,
        stripeAcceptanceTimestamp,
        email
      }
    
    const response = await fetch( `${VirtualCardAPI}/update-cardholder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryData),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log("result:",result)
    } else {
      console.log("Update cardholder failed",response.status);
    }

    }catch(error){
      console.log("failed updating cardholder",error);
    }
  }

  cancel = () => {
    this.props.history.push("/seller/dashboard");
  };

  getUserInfo = (id) => {
    const db = firebase.firestore();

    db.collection("sellersAccounts")
      .doc(id)
      .get()
      .then((doc) => {
        const { 
          firstName,
          lastName,
          email, 
          phoneNumber, 
          dob, 
          id,
          virtualCards,

        } = doc.data();

        let cardHolderId=null;
        if(virtualCards && virtualCards?.cardHolderId){
          cardHolderId=virtualCards?.cardHolderId;
        }
        let stripeAcceptanceTimestamp=Date.now();
        console.log("stripeAcceptance",stripeAcceptanceTimestamp)

        this.setState({
          firstName,
          lastName,
          email,
          phoneNumber,
          birthday: dob,
          id,
          cardHolderId,
          stripeAcceptanceTimestamp
        });
      });
  };

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
      }
    );

    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log("userInfo", user.phoneNumber);
        this.setState({
          sellerName: user.displayName,
          phoneNumber: user.phoneNumber
        });
        console.log(user.uid);
        this.getUserInfo(user.uid);
      } else {
        // this.props.history.push("/");
      }
    });
  }

  backToSettings = () => {
    //re-display the settings form to do more updates
    this.setState({
      updateSuccess: false,
      showPasswordField: false,
      showUpdatePasswordForm: false,
      showPhoneConfirmation: false,
      phoneNumberUpdated: false,
    });

    //hide this window
    this.props.cancel();
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  displayPasswordChangeForm = () => {
    this.setState({
      showUpdatePasswordForm: true,
      updateSuccess: false,
      showPasswordField: false,
    });
  };

  reAuthenticate = (currentPassword) => {
    //returns a promise
    var user = firebase.auth().currentUser;
    var credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(credentials);
  };

  resetPassword = () => {
    try {
      const {
        resetPasswordOne,
        resetPasswordTwo,
        resetPasswordZero,
      } = this.state;
      this.reAuthenticate(resetPasswordZero)
        .then(() => {
          if (resetPasswordOne === resetPasswordTwo) {
            var user = firebase.auth().currentUser;
            var newPassword = resetPasswordOne;

            user
              .updatePassword(newPassword)
              .then(() => {
                // Update successful.
                // this.setState({
                //   updateSuccess: true,
                //   updateError: false,
                // });
                this.backToSettings();
              })
              .catch((error) => {
                // An error happened.
                console.log("error updating password", error);
                this.setState({
                  updateError: true,
                });
              });
          } else {
            this.setState({
              updateError: true,
            });
          }
        })
        .catch((error) => {
          this.setState({
            updateError: true,
          });
          console.log("error re-authenticating", error);
        });
    } catch (error) {
      console.log("error resetting password", error);
    }
  };
  render() {
    return (
      <>
        <ToastContainer />

        <div style={{ display: "flex" }} className="pop-settings">
          <div className="pop-container">
            {this.state.updateSuccess === false &&
            this.state.showPasswordField === false &&
            this.state.showUpdatePasswordForm === false &&
            this.state.showPhoneConfirmation === false ? (
              <UpdateProfile
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                firstNameError={this.state.firstNameError}
                lastNameError={this.state.lastNameError}
                phoneNumber={this.state.phoneNumber}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handlePhoneInput={this.handlePhoneInput}
                displayPasswordChangeForm={this.displayPasswordChangeForm}
                cancel={this.props.cancel}
                  updateError={this.state.updateError}
                  errorMessage={this.state.errorMessage}
                  dobError={this.state.dobError}
                  birthday={this.state.birthday}
              />
            ) : null}
            {this.state.showPasswordField === true &&
            this.state.showPhoneConfirmation === false &&
            this.state.updateSuccess === false ? (
              <ConfirmChanges
                handleChange={this.handleChange}
                submitChanges={this.submitChanges}
                  updateError={this.state.updateError}
                  backToSettings={this.backToSettings}
                  errorMessage={this.state.errorMessage}
              />
            ) : null}
            {this.state.showPhoneConfirmation === true &&
            this.state.updateSuccess === false ? (
              <VerifyNewNumber
                handleChange={this.handleChange}
                submitChanges={this.submitChanges}
                  updateError={this.state.updateError}
                  backToSettings={this.backToSettings}
                  errorMessage={this.state.errorMessage}
              />
            ) : null}
            {this.state.showUpdatePasswordForm === true &&
            this.state.updateSuccess === false ? (
              <ChangePassword
                handleChange={this.handleChange}
                resetPassword={this.resetPassword}
                cancel={this.props.cancel}
                  updateError={this.state.updateError}
                  errorMessage={this.state.errorMessage}
              />
            ) : null}
          </div>
        </div>

        {/* {this.state.updateSuccess ? (
          <UpdateStoreSuccess backToSettings={this.backToSettings} />
        ) : null} */}
        <div id="recaptcha-container"></div>
      </>
    );
  }
}

export default UpdateLogin;
