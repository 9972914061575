import firebase from "../../firebaseConfig";

export const saveCatalog = (catalog) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;
      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const catalogs = store.data() ? store.data().catalogs ? store.data().catalogs : [] : [];
      catalog = catalog.trim();
      catalog =
        catalog.charAt(0).toUpperCase() + catalog.slice(1).toLowerCase();

      catalogs.push(catalog);
      catalogs.sort();
      await docRef.update({
        catalogs,
      });
      resolve(catalogs);
    } catch (error) {
      console.log("error saving catalog", error);
      reject(error);
    }
  });
};

export const getCatalogs = () => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;

      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const catalogs = store.data().catalogs;

      resolve(catalogs);
    } catch (error) {
      console.log("error getting catalog", error);
      reject(error);
    }
  });
};

export const deleteCatalog = (deletedCatalog) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;

      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const catalogs = store.data().catalogs;
      const updatedCatalogs = catalogs.filter(
        (catalog) => catalog !== deletedCatalog
      );
      await docRef.update({
        catalogs: updatedCatalogs,
      });
      resolve(updatedCatalogs);
    } catch (error) {
      console.log("error saving catalog", error);
      reject(error);
    }
  });
};
