import firebase from "../../firebaseConfig";

const getTransaction = (sellerId, tId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const transactionInfo = await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("transactions")
        .doc(tId)
        .get();
      const transData = transactionInfo.data();

      resolve(transData);
    } catch (error) {
      console.log("error getting transaction", error);
      reject(error);
    }
  });
};
export default getTransaction;
