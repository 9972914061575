import React, { Component } from "react";
import firebase from "../../../firebaseConfig";
import { Link } from "react-router-dom";
import Metatags from "react-meta-tags";
import TopHeader from "../../common/TopHeader";
import LeftNav from "../../common/LeftNav";
import getUserLocation from "../../helpers/getUserLocation";
import getRegistrationProgress from "../../helpers/getRegistrationProgress";
import Dashboard from "./Dashboard";
import FeaturePromo from "./FeaturePromo";
import RightNav from "../../common/RightNav";
import BottomNav from "../../common/BottomNav";
import getBasicInfo from "../../helpers/getBasicInfo";
import getProductCount from "../../helpers/getProductCount";
import getOrderCount from "../../helpers/getOrderCount";
import getSoldItemsCount from "../../helpers/getSoldItemsCount";
import getDispatchedOrders from "../../helpers/getDispatchedOrder";
import getPeriodicOrderInfo from "../../helpers/getPeriodicOrderInfo";
import getUnreadChat from "../../helpers/getUnreadChat";
import GoLive from "../../common/GoLive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import MessengerCustomerChat from "react-messenger-customer-chat";
import NProgress from 'nprogress';

const FEATURES_COUNTDOWN=7;

export default class ManageStore extends Component {
  state = {
    storeName: "",
    sellerId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    slug: "",
    storeURL: "",
    productCount: -1,
    orderCount: -1,
    customerCount: -1,
    chatCount: -1,
    storeAlreadySetup: true,
    bankAlreadySetup: true,
    emailVerified: true,
    userCurrentCountry: "",
    localCurrency: "",
    soldItemsCount: -1,
    dispatchedOrdersCount: -1,
    oneDayRevenue: -1,
    sevenDaysRevenue: -1,
    thirtyDaysRevenue: -1,
    oneDayOrderCount: -1,
    sevenDayOrderCount: -1,
    thirtyDayOrderCount: -1,
    notification: -1,
    activated: null,
    showLeftNav: false,
    showGoLive: false,
    resources: [],
    newFeature: false
  };

  fetchSellerInfo = async (id) => {
    try {
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let {
        firstName,
        lastName,
        slug,
        activated,
        storeName,
        phoneNumber,
        subscriptionActive
      } = basicInfo;


      // if(!subscriptionActive && activated){
      //   return this.props.history.push("/seller/dashboard/rewards");

      // }

      console.log("basicInfo",basicInfo)
      let virtualCards = basicInfo?.virtualCards;

      let card = null;
      let lastFourDigits="";
      let spendingLimit=0;
      let currency="$";

      if(virtualCards && virtualCards?.cards && virtualCards?.cards?.length>0){
        card = virtualCards?.cards[0];
        lastFourDigits = card?.lastFourDigits;
        spendingLimit=card?.spendingLimit;
        currency=card?.currency;
      }
      


      let storeURL = `http://www.cashaam.com/${slug}`;
      let country = this.state.userCurrentCountry.toLocaleLowerCase();
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        storeInfo: basicInfo,
        activated: typeof activated !== "undefined" ? activated : true,
        lastFourDigits,
        spendingLimit
      });


      let productCount = 0;
      let orderCount = 0;
      let soldItemsCount = 0;
      let dispatchedOrdersCount = 0;
      let oneDayOrderInfo = 0;
      let sevenDayOrderInfo = 0;
      let thirtyDayOrderInfo = 0;
      getProductCount(id).then(result => {
        productCount = result;
        this.setState({
          productCount
        })
      }
      );
      getOrderCount(id).then(result => {
        orderCount = result;
        this.setState({
          orderCount
        })
      });
      getSoldItemsCount(id).then(result => {
        soldItemsCount = result;
        this.setState({
          soldItemsCount
        })
      })


      getDispatchedOrders(id).then(result => {
        dispatchedOrdersCount = result;
        this.setState({
          dispatchedOrdersCount
        })
      }
      );
      getPeriodicOrderInfo(id, 1, country).then(result => {
        oneDayOrderInfo = result;
        let oneDayRevenue = oneDayOrderInfo.totalAmount;
        let oneDayOrderCount = oneDayOrderInfo.count;
        let oneDayOrderCurrency=oneDayOrderInfo?.orderCurrency;
        this.setState({
          oneDayRevenue,
          oneDayOrderCount,
          oneDayOrderCurrency
        })

      });
      getPeriodicOrderInfo(id, 7, country).then(result => {
        sevenDayOrderInfo = result;
        let sevenDaysRevenue = sevenDayOrderInfo.totalAmount;
        let sevenDayOrderCount = sevenDayOrderInfo.count;
        let sevenDayOrderCurrency= sevenDayOrderInfo?.orderCurrency;
        this.setState({
          sevenDaysRevenue,
          sevenDayOrderCount,
          sevenDayOrderCurrency
        })

      });
      getPeriodicOrderInfo(id, 30, country).then(
        result => {
          thirtyDayOrderInfo = result;
          let thirtyDaysRevenue = thirtyDayOrderInfo.totalAmount;
          let thirtyDayOrderCount = thirtyDayOrderInfo.count;
          let thirtyDayOrderCurrency=thirtyDayOrderInfo?.orderCurrency;
          this.setState({
            thirtyDaysRevenue,
            thirtyDayOrderCount,
            thirtyDayOrderCurrency
          })

        }
      );

      getUnreadChat(id).then(
        result => {
          this.setState({
            notification: result
          })
        }
      );


      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  getCustomers = async (slug) => {
    const body = { query_field: "slug", query_value: slug };

    const response = await fetch(
      "https://us-central1-cashaam-dev.cloudfunctions.net/sellerquery-querydb",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    // console.log('resp--',await response.json());
    try {
      const resp = await response.json();
      const orders = resp.orders;
      let customersIds = [];

      // const loadCustomerIds = () =>
      //   new Promise((resolve, reject) => {
      //     resolve(
      orders.forEach((order) => {
        if (!customersIds.includes(order.customerId)) {
          customersIds.push(order.customerId);
        }
      })
      //   );
      // });

      // await loadCustomerIds();
      console.log("customerids", customersIds.length)
      this.setState({
        customerCount: customersIds.length,
      });
    } catch (error) {
      console.log("error getting customers", error);
    }
  };

  checkForIncompleteSetup = (sellerId) => {
    const db = firebase.firestore();

    db.collection("sellersAccounts")
      .doc(sellerId)
      .collection("stores")
      .get()
      .then((snap) => {
        console.log("CHECK", snap.docs.length);
        if (snap.docs.length < 1) {
          this.setState({
            storeAlreadySetup: false,
          });
        }
      });

    if (this.state.userCurrentCountry === "Nigeria") {
      db.collection("sellersAccounts")
        .doc(sellerId)
        .collection("bank")
        .get()
        .then((snap) => {
          console.log("CHECK", snap.docs.length);
          if (snap.docs.length < 1) {
            this.setState({
              bankAlreadySetup: false,
            });
          }
        });
    } else {
      db.collection("sellersAccounts")
        .doc(sellerId)
        .collection("stripe")
        .get()
        .then((snap) => {
          console.log("CHECK", snap.docs.length);
          if (snap.docs.length < 1) {
            this.setState({
              bankAlreadySetup: false,
            });
          }
        });
    }
  };

  goToSetupStore = () => {
    this.props.history.push("/seller/dashboard/setup");
  };

  goToSetupBank = () => {
    this.props.history.push("/seller/dashboard/setup/bank");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };
  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData",{ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  goLive = async () => {
    try {
      const { sellerId } = this.state;

      const db = firebase.firestore();
      await db.collection("sellersAccounts").doc(sellerId).set(
        {
          activated: true,
        },
        { merge: true }
      );

      this.setState({
        showGoLive: true,
      });
    } catch (error) {
      console.log("error going live", error);
      toast.error("Error going live!");
    }
  };

  onCloseGoLive = () => {
    this.setState({
      showGoLive: false,
    });
    this.componentDidMount();
  };

  copyReferral =()=>{
    let referralLink=this.state.referralLink;
    console.log("copy",referralLink);
    navigator.clipboard.writeText(referralLink)
    this.setState({
      copiedReferral: "Copied Referral Link"
    })
  }

  hideNewFeature=()=>{
    // if(this.state.activated){
      this.setState({
        newFeature: false
      })
    // }else{
    //   this.props.history.push("/seller/dashboard/rewards");
    // }
    
  }

  countDaysPassed=(lastSignedIn)=>{

    let calendar = lastSignedIn.split(" ");

    var date = new Date(lastSignedIn);
    var startDate= (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
    console.log("date newdate",startDate);

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = mm + '/'+ dd + '/' + yyyy;
    console.log("date formattedToday",formattedToday)

    const diffInMs   = new Date(formattedToday) - new Date(startDate)
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    // console.log("date diffInDays",diffInDays);
    // if(diffInDays>FEATURES_COUNTDOWN){
      this.getNewFeature()
    // }
    
  }

  getNewFeature= async()=>{
    try{
      let {activated, storeInfo}=this.state;
    console.log("getNewFeature")
    const db = firebase.firestore();

    let featurePromo=null;
    
    let{subscriptionActive, subscriptionType}=storeInfo;
    console.log("subscriptionActive =>",storeInfo );
    if(subscriptionActive && subscriptionType=="lite"){

      console.log("free $5")
      featurePromo = await db.collection("newFeature")
      .where("use", "==", "offers")
      .limit(1)
      .get();
    }else{

      featurePromo = await db.collection("newFeature")
      .where("use", "!=", "Welcoming")
      .limit(1)
      .get();
    }    

    console.log("featurePromo",featurePromo)
    let featureInfo = null;
    if (featurePromo.empty) {
      console.log("No promo found");
      return;
    } else {
      featureInfo = featurePromo.docs[0].data();
    }
    console.log("featureInfo =>", featureInfo);

    this.setState({
      newFeature: true,
      featureTitle: featureInfo?.title,
      featureDescription: featureInfo?.description,
      featureVideo: featureInfo?.embedVideo,
      featureHeading: featureInfo?.heading?featureInfo?.heading:"New Feature",
      featureLink: featureInfo?.link,

    })
  }catch (error) {
    console.log("error getNewFeature: ",error)
  }
  }

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPendo =() =>{

    let script = document.createElement("script");

    let pendoKey="6ff8873a-3edf-4dc1-6d19-5a3490191d2a";

    script.src='https://cdn.pendo.io/agent/static/'+pendoKey+'/pendo.js';
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);

  }

  componentDidMount() {
    NProgress.start();
    this.loadHubspot();
    this.loadPendo();
    console.log("mc-date: 10/03");
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {

        if(this.state.pendoLoaded){
          console.log("initialize pendo")
          pendo.initialize({
            visitor: {
                id: user.uid,   // Required if user is logged in
                email: user?.email,       // Recommended if using Pendo Feedback, or NPS Email
                full_name: user?.displayName,   // Recommended if using Pendo Feedback
                role:  "seller" 
            },
    
            account: {
                id: user.uid, 
                planLevel:  "seller",
                userType: "seller"
            }
          });

        }
        
        console.log("user",user);
        let lastSignedIn=user?.metadata?.lastSignInTime;
        console.log("lastSignedIn",lastSignedIn);
        
        let shortReferral= user.uid?.slice(0,6);
        shortReferral=shortReferral.toUpperCase();
        
        let referralLink=`https://cashaam.com/launch?referral_code=${shortReferral}`

        this.setState({
          shortReferral,
          referralLink,
          sellerId: user.uid
        });
        localStorage.setItem("cashaam_uid", user.uid);
        this.getLocation();

        //check the user registration progress
        let step = await getRegistrationProgress(user.uid);

        step = parseFloat(step);
        if (step === 100) {
          // this.props.history.push("/seller/dashboard");
          console.log("completed registration step:", step);

        } else {
          console.log("MY STEP", step)
          if (step < 4) {
            this.props.history.push("/seller/dashboard/setup/onboarding");
          }
        }

        //check if user verified email
        if (user.emailVerified === false) {
          this.setState({
            emailVerified: false,
          });
        }
        // this.setState({
        //   firstName: user.displayName,
        // });
        NProgress.set(0.5);
        const sellerUid = user.uid;
        this.checkForIncompleteSetup(sellerUid);
        const slug = await this.fetchSellerInfo(sellerUid);
        this.countDaysPassed(lastSignedIn);
        
        this.getCustomers(slug);
        NProgress.done();
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Dashboard</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Dashboard" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>
        <ToastContainer />

        {this.state.showGoLive === true ? (
          <GoLive
            storeName={this.state.storeName}
            onCloseGoLive={this.onCloseGoLive}
            slug={this.state.slug}
            phoneNumber={this.state.phoneNumber}
          />
        ) : null}
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />

            <Dashboard
              shortReferral={this.state.shortReferral}
              referralLink={this.state.referralLink}
              copiedReferral={this.state.copiedReferral}
              copyReferral={this.copyReferral}
              userCurrentCountry={this.state.userCurrentCountry}
              activated={this.state.activated}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              customerCount={this.state.customerCount}
              orderCount={this.state.orderCount}
              productCount={this.state.productCount}
              currency={this.state.localCurrency}
              soldItemsCount={this.state.soldItemsCount}
              dispatchedOrdersCount={this.state.dispatchedOrdersCount}
              oneDayRevenue={this.state.oneDayRevenue}
              sevenDaysRevenue={this.state.sevenDaysRevenue}
              thirtyDaysRevenue={this.state.thirtyDaysRevenue}
              oneDayOrderCount={this.state.oneDayOrderCount}
              sevenDayOrderCount={this.state.sevenDayOrderCount}
              thirtyDayOrderCount={this.state.thirtyDayOrderCount}

              oneDayOrderCurrency={this.state.oneDayOrderCurrency? this.state.oneDayOrderCurrency : this.state.localCurrency}
              sevenDayOrderCurrency={this.state.sevenDayOrderCurrency? this.state.sevenDayOrderCurrency: this.state.localCurrency}
              thirtyDayOrderCurrency={this.state.thirtyDayOrderCurrency? this.state.thirtyDayOrderCurrency: this.state.localCurrency}
              notification={this.state.notification}
              goLive={this.goLive}
              slug={this.state.slug}
              storeName={this.state.storeName}
              phoneNumber={this.state.phoneNumber}

              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}

              lastFourDigits={this.state.lastFourDigits}
              spendingLimit={this.state.spendingLimit}
            />


            {this.state.newFeature?
                <FeaturePromo 
                hideNewFeature={this.hideNewFeature}
                featureTitle={this.state.featureTitle}
                featureDescription={this.state.featureDescription}
                featureVideo={this.state.featureVideo}
                featureHeading={this.state.featureHeading}
                featureLink={this.state.featureLink}
                activated={true}
                />
                :null
            }

            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
            notification={this.state.notification}
            logoutHandler={this.logoutHandler} 
          />
        </div>
      </>
    );
  }
}
