import firebase from "../../firebaseConfig";
import { BUYER_DELIVERY_TYPE_URL } from "../../secrets";

const getBuyerDeliveryType = (shippingObjectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      //get shipping object from shipment collection
      const shipmentSnap = await db
        .collection("shipping")
        .doc(shippingObjectId)
        .get();
      const shipmentInfo = shipmentSnap.data();
      let { carrier_account, servicelevel_token } = shipmentInfo;
      let { nigeriaShippingInfo } = shipmentInfo.shipment;

      const queryData = {
        carrier_account,
        servicelevel_token,
        shipment: nigeriaShippingInfo,
      };
      const res = await fetch(BUYER_DELIVERY_TYPE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          countrycode: "ng",
        },
        body: JSON.stringify(queryData),
      });

      if (res.status === 200) {
        const result = await res.json();
        resolve({ scheduleResult: result });
      } else {
        console.log("error checking buyer delivery type");
        reject("error checking buyer delivery type");
      }
    } catch (error) {
      console.log("error checking buyer delivery type", error);
      reject(error);
    }
  });
};
export default getBuyerDeliveryType;
