import React from "react";

function CardAgreement(props) {
  return (
    <div>
      <div>
        <p>WHEREAS:</p>
        <ol>
          <li>
            <p>
              CASHAAM&nbsp;is an&nbsp;e-commerce platform powered by individuals
              from across the globe that provides social media marketing and
              e-commerce service.
            </p>
          </li>
        </ol>
        <ol start={2}>
          <li>
            <p>
              The parties desire to enter into this agreement
              wherein&nbsp;CASHAAM&nbsp;will provide virtual cards
              to&nbsp;PARTY&nbsp;to make online payments.
            </p>
          </li>
        </ol>
        <ol start={3}>
          <li>
            <p>
              This agreement details CASHAAM’s obligations to PARTY. It also
              highlights certain risks of using CASHAAM’s services and platform.
            </p>
          </li>
        </ol>
        <p>
          NOW, THEREFORE, in consideration of the mutual promises and covenants
          contained herein, the Parties agree as follows:
        </p>
        <ol>
          <li>
            <p>Term of Agreement and Renewal</p>
          </li>
          <p>
            This Agreement shall come into force after accepting this agreement
          </p>
        </ol>

        <ol start={2}>
          <li>
            <p>Obligations of CASHAAM to PARTY&nbsp;</p>
          </li>
        </ol>
        <ol type="I">
          <li>
            <p>
              CASHAAM&nbsp;shall provide PARTY with virtual cards to make online payments.
            </p>
          </li>
          <li>
            <p>
              CASHAAM&nbsp;shall provide paid expert advice to help PARTY run ads
              that convert across various social media platforms if requested.
            </p>
          </li>
          <li>
            <p>
              CASHAAM is responsible for the security and protection of Card
              Holder Data (CHD) it collects and stores. Accordingly, CASHAAM
              undertakes to implement access control measures, security
              protocols, and standards including the use of encryption and
              firewall technologies to ensure that CHD is kept safe and secure
              on our servers, in compliance with the PCI DSS Requirement.&nbsp;
            </p>
          </li>
          <li>
            <p>
              CASHAAM undertakes to implement periodical security updates to
              ensure that its security infrastructures are in compliance with
              reasonable industry standards.
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li>
            <p>Obligations of PARTY to CASHAAM</p>
          </li>
        </ol>
        <ol type="I">
          <li>
            <p>
              PARTY agrees not to allow anyone else to have or use your password
              details.&nbsp;
            </p>
          </li>
          <li>
            <p>
              PARTY undertakes to comply with all reasonable instructions
              CASHAAM may issue regarding account access and security. In the
              event PARTY shares his/her/its password details, CASHAAM will not
              be liable to PARTY for losses or damages.&nbsp;
            </p>
          </li>
          <li>
            <p>
              PARTY will also take all reasonable steps to protect the security
              of the personal electronic device through which PARTY accesses
              CASHAAM’s services (including, without limitation, using PIN
              and/or password-protected personally configured device
              functionality to access CASHAAM’s services and not sharing of a
              device with other people).
            </p>
          </li>
          <li>
            <p>
              PARTY agrees to comply with all data privacy and security
              requirements of the Payment Card Industry Data Security Standard
              (PCI DSS Requirements”) and under any applicable law or regulation
              that may be in force, enacted, or adopted regarding
              confidentiality, PARTY’s access, use, storage and disclosure of
              user information. Information on the PCI DSS can be found on the
              PCI Council’s website. It is PARTY’s responsibility to comply with
              these standards.
            </p>
          </li>
          <li>
            <p>
              PARTY grants CASHAAM a perpetual, irrevocable, sub-licensable,
              assignable, worldwide, royalty-free license to use, reproduce,
              electronically distribute, and display PARTY’s data for the
              following purposes:
            </p>
          </li>

          <ol type="a">
            <li>
              <p>providing and improving CASHAAM’s services;</p>
            </li>
            <li>
              <p>
                internal usage, including but not limited to, data analytics and
                metrics so long as individual customer data has been anonymized
                and aggregated with other customer data;
              </p>
            </li>
            <li>
              <p>
                complying with applicable legal requirements and assisting law
                enforcement agencies by responding to requests for the
                disclosure of information in accordance with local laws; and
              </p>
            </li>
            <li>
              <p>
                any other purpose for which consent has been provided by PARTY.
              </p>
            </li>
          </ol>
        </ol>
        <p>
          <br />
        </p>
        <ol start={4}>
          <li>
            <p>Representations and Warranties&nbsp;</p>
          </li>
          <p>PARTY represents and warrants to CASHAAM that:</p>
        </ol>

        <ol type="I">
          <li>
            <p>
              PARTY has full power and authority to enter into, execute, deliver
              and perform this Agreement;
            </p>
          </li>
          <li>
            <p>
              PARTY is duly organized, authorized, and in good standing under
              the laws of the Federal Republic of Nigeria or any state, region,
              or country of your organization and is duly authorized to do
              business in all other states, regions, or countries in which
              PARTY’s business operates.
            </p>
          </li>
          <li>
            <p>PARTY is above the age of 18.</p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={5}>
          <li>
            <p>Force Majeure</p>
            <ol>
              <li>
                <p>
                  Neither Party shall be responsible for any failure to fulfill
                  any term or condition of the Agreement if and to the extent
                  that fulfillment has been delayed or temporarily prevented by
                  a force majeure occurrence, as hereunder defined, and which is
                  unforeseeable, beyond the control, without the fault or
                  negligence of the Party affected (Affected Party) and prevents
                  the Affected Party from fulfilling its obligations under this
                  Agreement.
                </p>
              </li>

              <li>
                <p>
                  For the purposes of this Agreement, force majeure relates to
                  the occurrence of events such as:
                </p>
              </li>

              <ol type="I">
                <li>
                  <p>
                    Riot, war, acts of terrorism, civil war, rebellion,
                    revolution, insurrection of military or usurped power;
                  </p>
                </li>
                <li>
                  <p>
                    Earthquake, flood, and/or other natural physical disaster,
                    but excluding weather conditions regardless of severity;
                  </p>
                </li>
                <li>
                  <p>
                    Strikes at a national level or industrial disputes at a
                    national level, or strikes or industrial disputes by labor
                    not employed by the Affected Party and which affect a
                    substantial portion of the provision of the services under
                    this Agreement;
                  </p>
                </li>
                <li>
                  <p>
                    Acts or orders of governments or governmental bodies
                    (including legislative bodies, local or port authorities),
                    changes to any general or local Statute, Ordinance, Decree,
                    or other Law or any regulation or bye-law of any local or
                    other duly constituted authority or the introduction of any
                    such Statute, Ordinance, Decree, Law, regulation or bye-law;
                  </p>
                </li>
                <li>
                  <p>
                    Regulatory authority’s intervention negates the intention
                    and terms of this Agreement.
                  </p>
                </li>
              </ol>
            </ol>

            <ol start={3}>
                <li>
                    <p>
                    In the event of a force majeure occurrence, the Affected Party
                    shall promptly notify the other Parties in writing, giving the
                    full particulars thereof, and shall use all reasonable endeavors
                    to remedy the situation immediately.
                    </p>
                </li>
            </ol>

            <ol start={4}>
                <li>
                    <p>
                    Following notification of a force majeure occurrence, the Parties
                    shall meet without delay with a view to agreeing on a mutually
                    acceptable course of action to minimize any effects of such
                    occurrence.
                    </p>
                </li>
            </ol>

          </li>
        </ol>


        <ol start={6}>
          <li>
            <p>Confidentiality</p>
            <ol>
              <li>
                <p>
                  The parties acknowledge that in the performance of their
                  duties under this Agreement, either party may communicate to
                  the other (or its designees) certain confidential and
                  proprietary information, including without limitation
                  information concerning each party’s services, know-how,
                  technology, techniques, or business or marketing plans
                  (collectively, the “Confidential Information”) all of which
                  are confidential and proprietary to, and trade secrets of, the
                  disclosing party.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Confidential Information does not include information
                  that:&nbsp;
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <ol>
          <li>
            <p>
              &nbsp;is public knowledge at the time of disclosure by the
              disclosing party;&nbsp;
            </p>
          </li>
          <li>
            <p>
              becomes public knowledge or known to the receiving party after
              disclosure by the disclosing party other than by breach of the
              receiving party’s obligations under this section or by breach of a
              third party’s confidentiality obligations;&nbsp;
            </p>
          </li>
          <li>
            <p>
              was known by the receiving party prior to disclosure by the
              disclosing party other than by breach of a third party’s
              confidentiality obligations; or&nbsp;
            </p>
          </li>
          <li>
            <p>is independently developed by the receiving party.</p>
          </li>
        </ol>
        <ol start={3}>
          <li>
            <p>
              As a condition to the receipt of the Confidential Information from
              the disclosing party, the receiving party shall:&nbsp;
            </p>
          </li>
        </ol>
        <ol>
          <li>
            <p>
              &nbsp;not disclose in any manner, directly or indirectly, to any
              third party any portion of the disclosing party’s Confidential
              Information;&nbsp;
            </p>
          </li>
          <li>
            <p>
              not use the disclosing party’s Confidential Information in any
              fashion except to perform its duties under this Agreement or with
              the disclosing party’s express prior written consent;&nbsp;
            </p>
          </li>
          <li>
            <p>
              disclose the disclosing party’s Confidential Information, in whole
              or in part, only to employees and agents who need to have access
              thereto for the receiving party’s internal business
              purposes;&nbsp;
            </p>
          </li>
          <li>
            <p>
              take all necessary steps to ensure that its employees and agents
              are informed of and comply with the confidentiality restrictions
              contained in this Agreement; and&nbsp;
            </p>
          </li>
          <li>
            <p>
              take all necessary precautions to protect the confidentiality of
              the Confidential Information received hereunder and exercise at
              least the same degree of care in safeguarding the Confidential
              Information as it would with its own confidential information, and
              in no event shall apply less than a reasonable standard of care to
              prevent disclosure.
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={7}>
          <li>
            <p>INTELLECTUAL PROPERTY</p>
            <ol>
              <li>
                <p>
                  CASHAAM does not grant any right or license to any CASHAAM’s
                  intellectual property rights by implication, estoppel, or
                  otherwise other than those expressly mentioned in this
                  Agreement.
                </p>
              </li>
              <li>
                <p>
                  Each party shall retain all intellectual property rights
                  including all ownership rights, title, and interest in and to
                  its own products and services, subject only to the rights and
                  licenses specifically granted herein.
                </p>
              </li>
              <li>
                <p>
                  CASHAAM hereby grants PARTY a revocable, non-exclusive,
                  non-transferable license to use CASHAAM’s APIs, developer’s
                  toolkit, and other software applications (the “Software”) in
                  accordance with the documentation accompanying the Software.
                  This license grant includes all updates, upgrades, new
                  versions, and replacement software for PARTY’s use in
                  connection with the CASHAAM’s services. If PARTY does not
                  comply with the documentation and any other requirements
                  provided by CASHAAM, then PARTY will be liable for all
                  resulting damages suffered by PARTY, Paystack, and third
                  parties. Unless otherwise provided by applicable law, PARTY
                  agrees not to alter, reproduce, adapt, distribute, display,
                  publish, reverse engineer, translate, disassemble, decompile
                  or otherwise attempt to create any source code that is derived
                  from the Software. Upon expiration or termination of this
                  Agreement, PARTY will immediately cease all use of any
                  Software.
                </p>
              </li>
              <li>
                <p>
                  CASHAAM hereby grants PARTY a revocable, non-exclusive,
                  non-transferable license to use CASHAAM’s trademarks used to
                  identify CASHAAM’s services (the “Trademarks”) solely in
                  conjunction with the use of CASHAAM’s services. PARTY agrees
                  that it will not at any time during or after this Agreement
                  assert or claim any interest in or do anything that may
                  adversely affect the validity of any Trademark or any other
                  trademark, trade name, or product designation belonging to or
                  licensed to CASHAAM (including, without limitation registering
                  or attempting to register any Trademark or any such other
                  trademark, trade name or product designation). Upon expiration
                  or termination of this Agreement, PARTY will immediately cease
                  all display, advertising, and use of all of the Trademarks.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={8}>
          <li>
            <p>Publicity</p>
          </li>
        </ol>
        <p>
          PARTY hereby grants CASHAAM permission to use PARTY’s name and logo in
          CASHAAM’s marketing materials including, but not limited to use on the
          CASHAAM website, in customer listings, in interviews, and in press
          releases. Such Publicity does not imply an endorsement of PARTY’s
          products and services.
        </p>
        <p>
          <br />
        </p>
        <ol start={9}>
          <li>
            <p>Card Network Rules</p>
            <ol>
              <li>
                <p>
                  Each card network has its own rules, regulations, and
                  guidelines. PARTY is required to comply with all applicable
                  Network Rules that are applicable to merchants. PARTY can
                  review portions of the Network Rules at Mastercard, Visa,
                  Verve, and other payment cards. Card Networks reserve the
                  right to amend the Network Rules.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={10}>
          <li>
            <p>Customer Payments</p>
            <ol>
              <li>
                <p>
                  CASHAAM will only process transactions that have been
                  authorized by the applicable Card Network or card issuer.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li>
            <p>
              CASHAAM does not guarantee or assume any liability for
              transactions authorized and completed that are later reversed or
              charged back (see Chargebacks below). PARTY is solely responsible
              for all reversed or charged back transactions, regardless of the
              reason for, or timing of, the reversal or chargeback. CASHAAM may
              add or remove one or more payment types or networks at any time.
              If CASHAAM does so it will use reasonable efforts to give PARTY
              prior notice of the removal.
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={11}>
          <li>
            <p>Fees &amp; Pricing Schedule</p>
          </li>
        </ol>
        <p>
          PARTY agrees to pay CASHAAM for the services it renders. CASHAAM
          reserves the right to revise its Fees. In the event that CASHAAM
          revises its fees, it will notify PARTY within 5 days of such change.
        </p>
        <p>
          <br />
        </p>
        <ol start={12}>
          <li>
            <p>Termination</p>
          </li>
        </ol>
        <p>
          This Agreement may be terminated in the circumstances and manner
          provided hereunder:
        </p>
        <ol>
          <li>
            <p>By PARTY closing its CASHAAM Account.</p>
          </li>
          <li>
            <p>
              Suspension of PARTY’s CASHAAM Account and access to CASHAAM
              services and any funds, or terminate this Agreement, if;
            </p>
          </li>
        </ol>
        <ul>
          <li>
            <p>
              PARTY does not comply with any of the provisions of this
              Agreement;
            </p>
          </li>
          <li>
            <p>CASHAAM is required to do so by Law;</p>
          </li>
          <li>
            <p>where a suspicious or fraudulent transaction occurs</p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ol start={13}>
          <li>
            <p>Indemnity</p>
            <ol>
              <li>
                <p>
                  PARTY&nbsp;undertakes to indemnify, defend and hold
                  harmless&nbsp;CASHAAM, its subsidiaries, affiliates, officers,
                  directors, employees, agents, and successors from and against
                  all losses due to data security breaches or the unauthorized
                  use of shared personal information, any loss of funds, fraud,
                  damages, actions, interest, awards, demands, liabilities,
                  actions, judgment, penalties, fines, costs or expenses of
                  whatever kind, including reasonable attorneys’ fees, damages
                  and proceedings that may be made by any third party arising as
                  a consequence of negligence and unlawful acts or omissions on
                  the part of&nbsp;PARTY&nbsp;or its agents or subcontractors to
                  comply with any of its obligations in this Agreement.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li>
            <p>
              PARTY&nbsp;agrees to defend the&nbsp;CASHAAM&nbsp;against any such
              liability, claim or demand.&nbsp;
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={14}>
          <li>
            <p>Governing Law and&nbsp;Dispute Resolution</p>
            <ol>
              <li>
                <p>
                  The Parties shall do all within their power to settle amicably
                  any difference of disputes concerning the interpretation or
                  performance of this Agreement or, failing such amicable
                  settlement; same shall be settled by an arbitrator.&nbsp;
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li>
            <p>
              Without prejudice to the right of a Party to seek interim
              preservative orders from a court of competent jurisdiction, the
              decision of the arbitrators shall be final and binding on the
              Parties.
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={15}>
          <li>
            <p>Miscellaneous</p>
            <ol>
              <li>
                <p>
                  All amendments to this Agreement shall be an integral part
                  thereof.
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={2}>
          <li>
            <p>
              In the event of any one or more of the provisions of this
              Agreement being held for any reason to be invalid, illegal or
              unenforceable in any respect, by a court of competent jurisdiction
              or by operation of law, such invalidity, illegality or
              unenforceability shall be omitted and shall not affect any other
              provision of this Agreement, and this Agreement shall be construed
              as if the such invalid, illegal or unenforceable provision was not
              a part of this Agreement. If the severed provision is critical to
              the performance of this Agreement, then the Parties shall
              negotiate together with a view to agreeing upon a valid
              replacement provision of equivalent economic effect, which shall
              replace the severed provision and be inserted in this Agreement.
            </p>
          </li>
        </ol>
        <p>
          <br />
        </p>
        <ol start={3}>
          <li>
            <p>
              The relationship between the Parties is one of an independent
              contractors and nothing in this Agreement shall be construed as
              creating any relationship of partnership, employment, joint
              venture, or agency between the Parties.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default CardAgreement;
