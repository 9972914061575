// import React from "react";
import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NProgress from 'nprogress';

import "./css/normalize.css";
import "./css/webflow.css";
import "./css/cashaam-storefront.webflow.css";
import "./css/chat.css";
import "./css/rewards.css"
import "./css/custom.css";
import './css/nprogress.css';
import "./css/normalize-order.css";
import "./css/components-order.css";
import "./css/cashaam-panel-order.css";

import "./css/css/normalize.css";
import "./css/css/webflow.css";
import "./css/css/cashaam-dashboard.webflow.css";
import "./css/billing.css";

// Seller's routes
import SellerLandingPage from "./components/seller/landing-page/index";
import SellerSignUp from "./components/seller/authentication/signUp/index";
import SellerSignIn from "./components/seller/authentication/signIn/index";
import SellerDashboard from "./components/seller/dashboard/index";
import StoreSetup from "./components/seller/dashboard/storeSetup/index";
import OnboardingPage from "./components/seller/dashboard/storeSetup/OnboardingPage";
import AddBankInfo from "./components/seller/dashboard/storeSetup/AddBankInfo";
import AddUSBankInfo from "./components/seller/dashboard/storeSetup/AddUSBankInfo";
import Settings from "./components/seller/dashboard/Settings/index";
import AddProduct from "./components/seller/dashboard/addProduct/index";
import AddCatalog from './components/seller/dashboard/addCatalog'
import Help from "./components/seller/Help";
import HowItWorks from "./components/seller/how-it-works-page/index";
import Landingpage from "./components/main-landing-page/index";
import NotFound from "./components/seller/NotFound";
import Products from "./components/seller/dashboard/products";
import Details from "./components/seller/dashboard/products/Details";
import Orders from "./components/seller/dashboard/orders";
import Customers from "./components/seller/dashboard/customers";
import Chat from "./components/seller/dashboard/chat/Chat";
import Discount from "./components/seller/dashboard/discount";
import Services from './components/seller/dashboard/services/Services'
import AddServices from './components/seller/dashboard/services/AddServices'
import AddOns from "./components/seller/dashboard/services/addons/AddOns";
import Availability from "./components/seller/dashboard/services/availability/Availability";
import AllServices from "./components/seller/dashboard/services/AllServices";
import Domain from "./components/seller/dashboard/domain";
import Bookings from "./components/seller/dashboard/services/bookings/Bookings";
import Messaging from "./components/seller/Messaging/index";
import Rewards from "./components/seller/dashboard/rewards/index";
import Marketing from "./components/seller/dashboard/marketing/index";
import PickAccountType from "./components/seller/dashboard/storeSetup/PickAccountType";
import VerifyButton from "./components/seller/dashboard/stripe/VerifyButton";
import VerificationPage from "./components/seller/dashboard/stripe/VerificationPage";
import ContentGenerator from "./components/seller/dashboard/SocialAi/ContentGenerator";
import AdsHelp from "./components/seller/dashboard/adsHelp/AdsHelp";
import AdsDashboard from "./components/seller/dashboard/adsHelp/AdsDashboard";
import TestPage from "./components/seller/dashboard/redirects/instagramRedirect";

// import "./css/addToCalendar.scss"
let READY = false;

function App(props) {
  const { Component, pageProps } = props;
  // let [ready, setReady] = useState(false);
  //add Facebook Attribution
  // useEffect(() => {
  //   // READY === true ? setReady(true) : null;
  //   window.fbAsyncInit = function () {
  //     FB.init({
  //       appId: "553728658555390",
  //       cookie: true,
  //       xfbml: true,
  //       version: "v6.0",
  //     });

  //     FB.AppEvents.logPageView();
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // });

  return (
    <Router>
      <div className="body App">
        <Switch>
          <Route exact path="/" component={Landingpage} />
          <Route exact path="/seller" component={SellerLandingPage} />
          <Route exact path="/seller/signup" component={SellerSignUp} />
          <Route exact path="/seller/signin" component={SellerSignIn} />
          <Route exact path="/seller/dashboard" component={SellerDashboard} />
          <Route exact path="/seller/dashboard/products" component={Products} />
          <Route
            exact
            path="/seller/dashboard/products/:productId"
            component={Details}
          />
          <Route exact path="/seller/dashboard/orders" component={Orders} />
          <Route
            exact
            path="/seller/dashboard/customers"
            component={Customers}
          />
          {/* <Route exact path="/seller/dashboard/chat" component={Chat} /> */}
          <Route exact path="/seller/dashboard/service/allServices" component={AllServices} />
          <Route exact path="/seller/dashboard/service/addService" component={AddServices} />
          <Route exact path="/seller/dashboard/service/addOns" component={AddOns} />
          <Route exact path="/seller/dashboard/service/availability" component={Availability} />
          <Route exact path="/seller/dashboard/service/bookings" component={Bookings} />

          <Route exact path="/seller/dashboard/setup" component={StoreSetup} />
          <Route
            exact
            path="/seller/dashboard/setup/onboarding"
            component={OnboardingPage}
          />
          <Route
            exact
            path="/seller/dashboard/setup/pickaccount"
            component={PickAccountType}
          />

          <Route
            exact
            path="/seller/dashboard/setup/bank"
            component={AddBankInfo}
          />
          <Route
            exact
            path="/seller/dashboard/setup/USbank"
            component={AddUSBankInfo}
          />
          <Route exact path="/seller/dashboard/settings" component={Settings} />
          {/* <Route exact path="/seller/manage" component={ManageStore} /> */}
          <Route
            exact
            path="/seller/dashboard/addProduct"
            component={AddProduct}
          />
          <Route
            exact
            path="/seller/dashboard/addCatalog"
            component={AddCatalog}
          />
          <Route
            exact
            path="/seller/dashboard/discount"
            component={Discount}
          />
          <Route
            exact
            path="/seller/dashboard/domain"
            component={Domain}
          />

          <Route exact path="/seller/help" component={Help} />
          <Route exact path="/seller/dashboard/chat" component={Messaging} />

          <Route exact path="/seller/dashboard/rewards" component={Rewards} />
          {/* <Route exact path="/seller/dashboard/marketing" component={Marketing} /> */}
          
          <Route exact path="/seller/howItWorks" component={HowItWorks} />
          <Route exact path="/seller/dashboard/stripe" component={VerificationPage} />
          <Route exact path="/seller/dashboard/content" component={ContentGenerator} />
          <Route exact path="/seller/dashboard/ads" component={AdsDashboard} />

          <Route exact path="/insta-response" component={TestPage} />

          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;