import React, { useState } from 'react'

import { timesArray } from '../../../../../constants'

import styles from './AvailabilityInput.module.css'

const TimeInput = (props) => {
    const [cursor, setCursor] = useState(-1)
    const [timeOptions, setTimeOptions] = useState(timesArray)
    const [showTimes, setShowTimes] = useState(false)

    const changeCursor = (e) => {
        if (e.key === "ArrowUp") {
            e.preventDefault()
            if (cursor > 0) {
                const active = document.activeElement; // store the input element that is in focus
                setCursor(cursor - 1)
                document.getElementById("option-" + (cursor - 1)).focus(); // set focus to the time option 
                active.focus() // set focus back to the input element
                setShowTimes(true) // show the time options
            }
        }
        else if (e.key === "ArrowDown") {
            e.preventDefault()
            if (cursor < timeOptions.length) {
                const active = document.activeElement;
                document.getElementById("option-" + (cursor + 1)).focus();
                setCursor(cursor + 1)
                active.focus()
                setShowTimes(true)
            }
        }
        else if (e.key === "Enter") {
            e.preventDefault()
            if (cursor === -1) return
            if (cursor === 0) props.setTimeValueOption("24 hours")
            else props.setTimeValueOption(timeOptions[cursor - 1])
            setShowTimes(false)
        }
    }

    const handleChange = (e) => {
        setCursor(-1)
        let filteredTimes = timesArray.filter(time => {
            const regex = new RegExp(`^${e.target.value}`, 'gi')
            return time.match(regex)
        })
        setTimeOptions(filteredTimes)
        setShowTimes(true)
        props.handleChange(e)
    }

    const handleInputClicked = () => {
        setCursor(-1)
        let filteredTimes = timesArray.filter(time => {
            const regex = new RegExp(`^${props.inputValue}`, 'gi')
            return time.match(regex)
        })
        if (filteredTimes.length > 0) setTimeOptions(filteredTimes)
        // props.showTimesOptions()
        setShowTimes(true)
    }

    const onHover = (index) => {
        setCursor(index)
    }

    const hideTimesOptions = () => {
        setShowTimes(false)
    }

    const onTimeOptionClicked = (index) => {
        if (index === 0) props.setTimeValueOption("24 hours")
        else props.setTimeValueOption(timeOptions[index - 1])
        setShowTimes(false)
    }

    return (
        <div style={{ position: "relative" }} className={styles.centerContent}>
            { (props.isOpen && props.show) &&
                <input
                    style={{ margin: 0 }}
                    type="text"
                    value={props.inputValue}
                    name="timeInput"
                    onChange={handleChange}
                    className="input-text w-input"
                    minLength={1}
                    maxLength={256}
                    placeholder={props.placeholder}
                    autoComplete="off"
                    required
                    onKeyDown={changeCursor}
                    onClick={handleInputClicked}
                    onBlur={hideTimesOptions}
                />
            }
            {
                showTimes &&
                <div role="listbox" className={styles.timeOptionsContainer}>
                    <div onMouseEnter={() => onHover(0)} onMouseDown={() => onTimeOptionClicked(0)} tabIndex="1" id={"option-0"} className={styles.timeOption} style={{ backgroundColor: cursor === 0 ? "#eee" : "inherit" }} role="option" aria-selected="true" >24 hours</div>
                    {
                        timeOptions.map((time, i) => {
                            return (
                                <div
                                    key={i}
                                    id={`option-${i + 1}`}
                                    className={styles.timeOption}
                                    onMouseEnter={() => onHover(i + 1)}
                                    onMouseDown={(e) => onTimeOptionClicked(i + 1)}
                                    style={{ backgroundColor: cursor === i + 1 ? "#eee" : "inherit" }}
                                    role="option"
                                    aria-selected={cursor === i + 1 ? "true" : "false"}
                                    tabIndex="1"
                                >
                                    {time}
                                </div>
                            )
                        })
                    }

                </div>
            }
        </div>
    )
}


const AvailabilityInput = (props) => {

    const toggleOpen = () => {
        props.toggleIsOpen(props.index)
    }

    return (
        <>
            <div className={styles.inputContainer}>
                <div className={styles.availabilityInfo}>
                    <div className={styles.dayContainer}>
                        <p style={{ marginTop: 10 }}>{props.day}</p>
                    </div>
                    <div className={styles.switchContainer}>
                        <label style={{ marginRight: 10, marginTop: 10 }} className={styles.switch}>
                            <input type="checkbox" checked={props.isOpen} onChange={toggleOpen} />
                            <span className={styles.slider + " " + styles.round}></span>
                        </label>
                    </div>
                    <div style={{ marginTop: 10 }} className={styles.openOrCloseContainer}>
                        <p style={{ margin: 0 }}>{props.isOpen ? "Open" : "Closed"}</p>
                    </div>
                </div>
                <div className={styles.hoursInputContainer}>
                    {
                        props.isOpen &&
                        props.hours.map((hour, i) => {
                            return (
                                <>
                                    <div className={styles.hoursInput}>
                                        <TimeInput
                                            placeholder="Opens at"
                                            show={true}
                                            isOpen={props.isOpen}
                                            inputValue={hour.openTime}
                                            handleChange={(e) => props.handleChange(e, props.index, i, "openTime")}
                                            setTimeValueOption={(value) => props.setTimeValueOption(props.index, i, "openTime", value)}
                                        />
                                        {
                                            (hour.openTime !== "24 hours") &&
                                            <h5>
                                                –
                                        </h5>
                                        }
                                        <TimeInput
                                            isOpen={props.isOpen}
                                            show={hour.openTime !== "24 hours"}
                                            placeholder="Closes at"
                                            inputValue={hour.closeTime}
                                            handleChange={(e) => props.handleChange(e, props.index, i, "closeTime")}
                                            setTimeValueOption={(value, allDay = false) => props.setTimeValueOption(props.index, i, "closeTime", value, allDay)}
                                        />
                                        <div className={styles.centerContent}>
                                            <span style={{ display: props.isOpen && i !== 0 ? "inherit" : "none", cursor: "pointer" }} onClick={() => props.deleteHour(props.index, i)}>
                                                <i class="far fa-trash-alt"></i>
                                            </span>
                                        </div>
                                        {
                                            (hour.openTime && hour.closeTime && i === props.hours.length - 1) &&
                                            <div className={styles.addHoursDesktop}>
                                                <p style={{ margin: 0, display: props.isOpen ? "inherit" : "none", color: "#7E55BC", cursor: "pointer" }} onClick={() => props.addHours(props.index)} >
                                                    Add hours
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    {
                                        (hour.openTime && hour.closeTime && i === props.hours.length - 1) &&
                                        <div className={`${styles.addHoursMobile}`}>
                                            <p style={{ margin: 0, display: props.isOpen ? "inherit" : "none", color: "#7E55BC", cursor: "pointer" }} onClick={() => props.addHours(props.index)} >
                                                Add hours
                                            </p>
                                        </div>
                                    }

                                </>
                            )
                        })
                    }
                </div>

            </div>
            {/* {
                props.hours.map((hour, i) => {
                    return (
                        <div className={styles.inputContainer} style={{ display: !props.isOpen && i !== 0 ? "none" : "grid" }}>
                            <div className={styles.centerContent}>
                                {i === 0 && <p style={{ margin: 0 }}>{props.day}</p>}
                            </div>
                            <div className={styles.centerContent}>
                                {i == 0 && <label style={{ margin: 0 }} className={styles.switch}>
                                    <input type="checkbox" onChange={toggleOpen} />
                                    <span className={styles.slider + " " + styles.round}></span>
                                </label>}
                            </div>
                            <div className={styles.centerContent}>
                                {i == 0 && (<p style={{ margin: 0 }}>{props.isOpen ? "Open" : "Closed"}</p>)}
                            </div>
                            <TimeInput
                                placeholder="Opens at"
                                show={true}
                                isOpen={props.isOpen}
                                inputValue={hour.openTime}
                                handleChange={(e) => props.handleChange(e, props.index, i, "openTime")}
                                setTimeValueOption={(value) => props.setTimeValueOption(props.index, i, "openTime", value)}
                            />
                            <div>
                                {
                                    (hour.openInputValue !== "24 hours") &&
                                    <h5 style={{ display: props.isOpen ? "inherit" : "none" }}>
                                        –
                                    </h5>
                                }
                            </div>
                            <TimeInput
                                isOpen={props.isOpen}
                                show={hour.openInputValue !== "24 hours"}
                                placeholder="Closes at"
                                inputValue={hour.closeTime}
                                handleChange={(e) => props.handleChange(e, props.index, i, "closeTime")}
                                setTimeValueOption={(value, allDay = false) => props.setTimeValueOption(props.index, i, "closeTime", value, allDay)}
                            />
                            <div className={styles.centerContent}>
                                <span style={{ display: props.isOpen && i !== 0 ? "inherit" : "none", cursor: "pointer" }} onClick={() => props.deleteHour(props.index, i)}>
                                    <i class="far fa-trash-alt"></i>
                                </span>
                            </div>
                            <div className={styles.centerContent}>
                                {
                                    (hour.openTime && hour.closeTime && i === props.hours.length - 1) &&
                                    <p style={{ margin: 0, display: props.isOpen ? "inherit" : "none", color: "#7E55BC", cursor: "pointer" }} onClick={() => props.addHours(props.index)} >
                                        Add hours
                                    </p>
                                }
                            </div>
                        </div>
                    )
                })
            } */}
        </>
    )
}

export default AvailabilityInput
