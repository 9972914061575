import React from "react";
import { Link } from "react-router-dom";

const AddBankSuccess = (props) => {
  return (
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1">
          <div className="icon-success w-embed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-circle-check"
              width={84}
              height={84}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <circle cx={12} cy={12} r={9} />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <h4 className="h4">Your BVN Validation Was Successful</h4>
        </div>
        <div className="microcopy-signup">
          We've verified you own the account and it has been configured to
          receive payment.
        </div>
        <div className="holder-btn">
          <div className="btn-primary">
            <div style={{ cursor: "pointer" }} onClick={() => props.goToAddProduct()} className="text-btn-primary">Continue</div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default AddBankSuccess;
