import firebase from "../../firebaseConfig";

const reAuthenticate = (email, password) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => resolve(true))
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("error reauthenticating", error);
        alert("Invalid credentials. Please try again");
        reject(error);
        // ...
      });
  });
};
export default reAuthenticate;
