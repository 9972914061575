import React, { Component } from "react";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import InputTags from "../../../common/InputTags";
import styles from "./AddDiscount.module.css";
import ReactTooltip from "react-tooltip";

export class AddDiscountForm extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <ReactTooltip />
        {/* 
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Add New Discount</h4>

                </div> */}

        <div className="main">
          <div className="form-holder">
            <div style={{ minWidth: "60%" }} className="product-steps">
              <div className="block-title-2">
                <h4 className="page-title">Discounts Codes</h4>
              </div>
              {this.props.errorMessage ? (
                <p style={{ color: "red" }}>{this.props.errorMessage}</p>
              ) : null}
              <div className="product-builder" />
              <div className="holding-onboarding">
                <div className="forms w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="update-form"
                    onSubmit={this.props.handleSubmit}
                  >
                    <input
                      style={{ maxWidth: "35%" }}
                      data-tip="Discount Code (eg: BLACKFRIDAY)"
                      value={this.props.discount.discountCode}
                      onChange={this.props.handleChange}
                      type="text"
                      className="input-text w-input"
                      maxLength={256}
                      name="discountCode"
                      placeholder="Code"
                      required
                    />

                    <input
                      style={{ maxWidth: "35%" }}
                      data-tip="Discount Amount(eg: 10)"
                      value={this.props.discount.discountValue}
                      onChange={this.props.handleChange}
                      type="number"
                      className="input-text w-input"
                      name="discountValue"
                      placeholder="Value"
                      min="1"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      required
                    />

                    <select
                      name="discountType"
                      value={this.props.discount.discountType}
                      onChange={this.props.handleChange}
                      data-tip="Precentage or Dollar Amount"
                      className="input-text w-input"
                      style={{ maxWidth: "20%", minWidth: "20%" }}
                    >
                      <option value="percent">Percent %</option>
                      {this.props.userCountry &&
                      this.props.userCountry.toLowerCase() === "nigeria" ? (
                        <option value="naira">Naira ₦</option>
                      ) : (
                        <option value="dollars">Dollars $</option>
                      )}
                    </select>

                    <button
                      // onClick={this.props.handleSubmit}
                      type="submit"
                      value="Next"
                      defaultValue="AD"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    >
                      Add
                    </button>
                  </form>

                  <div>
                    {this.props.discounts.map((discount, index) => {
                      return index === 0 ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: "10px",
                            }}
                            className={styles.discounts}
                            key={index}
                          >
                            <h3>Active Discounts</h3>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: "10px",
                            }}
                            className={styles.discounts}
                            key={index}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontSize: 14,
                                minWidth: "30%",
                              }}
                            >
                              {discount.discountCode}
                            </p>
                            {discount.discountType === "percent" ? (
                              <p
                                style={{
                                  margin: 0,
                                  fontSize: 14,
                                  minWidth: "30%",
                                }}
                              >
                                {discount.discountValue}%{" "}
                              </p>
                            ) : (

                            discount.discountType === "naira"? 
                                (
                                    <p
                                    style={{
                                        margin: 0,
                                        fontSize: 14,
                                        minWidth: "30%",
                                    }}
                                    >
                                    {" "}
                                    ₦{discount.discountValue}{" "}
                                    </p>
                                ):(
                                    <p
                                    style={{
                                        margin: 0,
                                        fontSize: 14,
                                        minWidth: "30%",
                                    }}
                                    >
                                    {" "}
                                    ${discount.discountValue}{" "}
                                    </p>
                                )
                            )}
                            <button
                              className={styles.deleteBtn}
                              onClick={() => this.props.handleDelete(discount)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: "10px",
                          }}
                          className={styles.discounts}
                          key={index}
                        >
                          <p
                            style={{ margin: 0, fontSize: 14, minWidth: "30%" }}
                          >
                            {discount.discountCode}
                          </p>
                          {discount.discountType === "percent" ? (
                            <p
                              style={{
                                margin: 0,
                                fontSize: 14,
                                minWidth: "30%",
                              }}
                            >
                              {discount.discountValue}%{" "}
                            </p>
                          ) : (

                            discount.discountType === "naira"? 
                                (
                                    <p
                                    style={{
                                        margin: 0,
                                        fontSize: 14,
                                        minWidth: "30%",
                                    }}
                                    >
                                    {" "}
                                    ₦{discount.discountValue}{" "}
                                    </p>
                                ):(
                                    <p
                                    style={{
                                        margin: 0,
                                        fontSize: 14,
                                        minWidth: "30%",
                                    }}
                                    >
                                    {" "}
                                    ${discount.discountValue}{" "}
                                    </p>
                                )
                          )}
                          <button
                            className={styles.deleteBtn}
                            onClick={() => this.props.handleDelete(discount)}
                          >
                            Delete
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}

export default AddDiscountForm;
