import React from "react";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
// import checkInfo from "../../images/check-info.svg";
import paystackLogo from "../../images/paystack.svg";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";

const AddBankForm = (props) => (
  <>
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1">
          {props.accountType?.toLowerCase()=="personal"?
            <h4 className="h4">Financial Information</h4>
            :
            <h4 className="h4">Payment Information</h4>
          }
          
        </div>
        <div className="microcopy-signup">
          {props.accountType?.toLowerCase()=="personal"?
            "In case of a dispute, this is where your refund will be deposited."
            :
            "When a customer buys your product, where do you want your money to be deposited?"
          }
          <br />
          <br />
        </div>
        <div className="holder-guide">
          <div className="microcopy-signup">
            Please provide the following:
            <br />‍<br />
            1. Bank Name
            <br />
            2. Bank Account Number
          </div>
          <div className="btn-sm-holder">
            {/* <div className="btn-tertiary forms">
              <div className="text-block-4">I don't have an account</div>
            </div>
            <div className="btn-tertiary forms">
              <div className="text-block-4">I don't have a BVN</div>
            </div> */}
          </div>
        </div>
        <div className="holding-onboarding">
        {props.verificationError?
            <div
              style={{
                borderColor: "#ff8c00",
                borderWidth: "2px",
                fontSize: "14px",
              }}
              className="tracking-box"
            >
              <div>
                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  Error verifying your bank account
                  <br />
                </div>
                <div style={{textAlign: "center"}}>
                  Please use different account number or {" "}
                  <a target="_blank" href="https://support.cashaam.com">
                          contact us
                        </a>{" "}
                        if this persist
                </div>
              </div>
            </div>
          : null}
            

          <div className="forms w-form">
            <form
              onSubmit={props.submitBankInfo}
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >
              {/* <select
                id="banks-2"
                name="banks"
                data-name="banks"
                required
                className="input-text w-select"
              > */}
              {/* <option value>Select your preferred Bank</option> */}
              {props.bankNameError? <div className= "error-message">{props.bankNameError}</div>: null}
              <Autocomplete
                value={props.bankName}
                inputProps={{
                  type: "text",
                  className: "input-text w-select",
                  maxLength: 256,
                  name: "bankName",
                  placeholder: "Enter your bank (example: Sterling Bank)",
                  id: "bankName"
                }}
                wrapperStyle={{}} //keep this empty in order to avoid default styling from messing with the input's style inherited from css
                items={props.bankNames}
                getItemValue={(item) => item}
                shouldItemRender={(item, value) =>
                  item.toLowerCase().indexOf(value.toLowerCase()) > -1
                }
                onChange={props.handleChange}
                onSelect={(value) => props.selectBank(value)}
                menuStyle={{
                  borderRadius: "3px",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                  background: "rgba(255, 255, 255, 0.9)",
                  padding: "2px 0",
                  fontSize: "90%",
                  position: "fixed",
                  overflow: "auto",
                  maxHeight: "10%",
                  zIndex: 1,
                }}
                renderItem={(item, isHighlighted, i) => (
                  <div
                    style={{
                      background: isHighlighted ? "lightgray" : "white",
                    }}
                    key={item}
                  >
                    {item}
                  </div>
                )}
              />
              {/* </select> */}
              {/* {(props.accountNameError)? <div className= "error-message">{props.accountNameError}</div>: null} */}
              {/*
                <input
                onChange={props.handleChange}
                value={props.accountHolderName}
                type="text"
                className={props.accountNameError? "input-text w-input input-glow input-error" : "input-text input-glow w-input"}
                maxLength={256}
                name="accountHolderName"
                data-name="accountHolderName"
                placeholder="Enter the first and last name on the account"
                id="accountHolderName"
                //required
              />
                  */}

              
              {props.accountNumberError? <div className= "error-message">{props.accountNumberError}</div>: null}
              <input
                onChange={props.handleChange}
                value={props.accountNumber}
                type="text"
                className={props.accountNumberError? "input-text w-input input-glow input-error" : "input-text input-glow w-input"}
                maxLength={256}
                name="accountNumber"
                data-name="accountNumber"
                placeholder="Enter your 10-digit account number"
                id="accountNumber"
                //required
              />

              {!props.validateAccount?
                (!props.loadingBankUpload?
                  <input
                    type="submit"
                    defaultValue="Next"
                    value="Next"
                    data-wait="Please wait..."
                    className="submit-primary w-button"
                  />
                  :
                  <div 
                  style={{textAlign: "center"}}
                  className="submit-primary w-button">
                    Loading ...
                  </div>)
                  :null
              }
              {/* {props.action === "update" ?
                <div
                  className="microcopy-signup"
                  style={{ marginTop: "10px", cursor: "pointer", maxWidth: "fit-content" }}
                  onClick={() => props.backToSettings()}

                >cancel</div> : null} */}
            </form>

            {props.validateAccount?
              <>
              <div className="account-user">
                {props.validateAccount}
              </div>
                
              <div className="validate-user">
                <div>
                Is this your account?
                </div>
                <button onClick={()=>props.completeValidation()} className="yes-btn">Yes</button>
                <button onClick={()=>props.failValidation()} className="no-btn">No</button>
              </div>
            </>
          :null}

              {props.bankFormOnly?
                null:
                <>
                  <span style={{ textAlign: "center", padding:"0 50%", fontSize: "16px"}}>OR</span>
                  <br />

                  <a
                    style={{ cursor: "pointer" }}
                  // className="skip-btn"
                  >

                    <button
                      onClick={() => props.goToBVN()}
                      className="submit-primary w-button"
                    >
                      Use BVN Instead
                      </button>
                  </a>
                </>
              }

            <div
              onClick={() => props.goBackToStoreSetup()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Go Back
            </div>

            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Link to="/" className="logo-black w-inline-block">
      <img
        src={logo}
        // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
        sizes="(max-width: 767px) 80px, 100px"
        alt
        className="cashaam-logo-dark"
      />
      <img
        src={logoWhite}
        // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
        sizes="100vw"
        alt
        className="cashaam-logo-white"
      />
    </Link>
  </>
);
export default AddBankForm;
