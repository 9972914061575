import React from "react";
import { Link } from "react-router-dom";
import eye from "../../../../images/eye.svg";
import eyeOff from "../../../../images/eye-off.svg";
const EmailLogin = (props) => {
  return (
    <div className="container-right">
      <div className="holder-signup-otp">
        <div style={{ display: "flex" }} className="signup mobile-signing">
          <h1 className="h2">Log into your Account</h1>
          <div className="microcopy-signup">
            Login with your Email and Password{" "}
          </div>
          <div className="form-signup w-form">
            <form
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form"
              onSubmit={props.onSubmitHandler}
            >
              <input
                onChange={props.onChangeHandler}
                type="email"
                className="input-text w-input"
                maxLength={256}
                name="email"
                data-name="Email Address 2"
                placeholder="Email Address"
                id="email"
                required
              />
              <input
                onChange={props.onChangeHandler}
                type={"password"}
                className="input-text w-input"
                maxLength={256}
                name="password"
                data-name="Password"
                placeholder="Password"
                id="password"
                required
              />

              {props.loading === false ? (
                <input
                  type="submit"
                  value="Login"
                  defaultValue="Login"
                  data-wait="Please wait..."
                  className="submit-button w-button"
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="submit-button w-button"
                >
                  <div
                    style={{ borderTopColor: "#6740aa" }}
                    className="loader"
                  ></div>
                </div>
              )}
              {props.loading === false ? (
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => props.onDisplayLoginWithPhone(true)}
                  className="btn-secondary"
                >
                  <div className="text-btn-secondary">Login with phone</div>{" "}
                </a>
              ) : null}
            </form>
            {/* <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div> */}
            {/* {props.errorMessage !== null ? (
              <div style={{ display: "flex" }} className="w-form-fail">
                <div>{props.errorMessage}</div>
              </div>
            ) : null} */}
          </div>
          <Link style={{ textDecoration: "none" }} to="/seller/signup">
            <div style={{ cursor: "pointer"}} className="microcopy-signup">
              Don't have an account ? <span style={{ textDecoration: "underline", color: "#261ab1"}}>Sign up</span>
            </div>{" "}
          </Link>
          <div
            onClick={() => props.displayEmailOnly(false)}
            style={{ cursor: "pointer"}}
            className="microcopy-signup"
          >
            Forgot password? <span style={{ textDecoration: "underline", color: "#261ab1"}}>Reset password</span>
          </div>

          <div className="terms-conditions">
              By continuing, you agree to the <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Terms of Service</a>, <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Privacy Policy</a> and <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Cookie Use</a>.
          </div>

        </div>
      </div>
    </div>
  );
};
export default EmailLogin;
