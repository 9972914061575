import React from 'react';
import priceFormaterIntl from "../helpers/priceFormaterIntl";
import getDateFromTimestamp from "../helpers/getDateFromTimestamp";

import notification from "../../images/4092563_bell_mobile-ui_notification_icon.svg";
import eye from "../../images/211739_eye_icon.svg";
import cupImage from "../../images/2316248_accomplishments_awards_champion_cup_resume_icon.svg";
import user from "../../images/1564535_customer_user_userphoto_account_person_icon-1.svg";

class TransactionTopBar extends React.Component {


  selectFAQ =(link)=>{

    console.log("FAQ:",link);
    window.open(link);
  }


  selectHELP=(link)=>{

    console.log("Help:",link);
    window.open(link);
  }

  render() {
    return (
        <div className="odsection">
            <div className="odcontainer">
              <div className="odhead">
              <a
                className="back-arrow odpag leftodpag w-inline-block"
                onClick={this.props.backToTransactions}
              />

                <div className="odtopleft">
                  <div className="odtext">Orders: </div>
                  {/* <div className="odtextbold">{this.props.transactionId}</div> */}
                  <div className="oduser">from {this.props.customerName}</div>
                  <div className="orderprice">
                    <div>{priceFormaterIntl(this.props.transactionAmount,this.props.transactionCurrency)}</div>
                  </div>
                  <div className="orderdate">{getDateFromTimestamp(this.props.purchasedDate)}</div>
                </div>
                <div className="odtopright">
                  <div className="notificationbar">
                    <a 
                      target="_blank"
                      href={`https://cashaam.com/${this.props.slug}`} 
                      className="notificationitem w-inline-block"
                    >
                      <img
                        src={eye}
                        loading="lazy"
                        width={25}
                        alt
                      />
                      <div className="notificationtext">View my shop</div>
                    </a>
                    <a href={this.props.notification && this.props.notification>0?
                            "/seller/dashboard/chat": ""} 
                      className="notificationitem w-inline-block notification-parent">
                        {this.props.notification && this.props.notification>0?
                        <div className="notification-number">{this.props.notification}</div>: 
                        <div style={{ backgroundColor: "red"}} className="notification-number"></div>
                        }
                      <img
                        src={notification}
                        loading="lazy"
                        width={25}
                        alt
                      />
                      {/* <div className="notficationitemcount">
                        <div>7</div>
                      </div> */}
                    </a>
                    <a 
                      target="_blank"
                      href="https://www.cashaam.com/settings?tab=earnings"
                      className="notificationitem w-inline-block">
                      <img
                        src={cupImage}
                        loading="lazy"
                        width={25}
                        alt
                      />
                      {/* <div className="notficationitemcountcup">
                        <div>14</div>
                      </div> */}
                    </a>
                    {/* <a href="#" className="notificationitem w-inline-block">
                      <img
                        src={user}
                        loading="lazy"
                        width={25}
                        alt
                      />
                    </a> */}
                  </div>
                  <div className="infoboxwrap">
                    <div className="row">
                      <div className="form-block-2 w-form">
                        <form
                          id="email-form-2"
                          name="email-form-2"
                          data-name="Email Form 2"
                          className="form-3"
                          onSubmit={e => e.preventDefault()}
                        >
                          <select
                            id="FAQ-2"
                            name="FAQ-2"
                            data-name="FAQ 2"
                            className="faqselectlist w-select"
                            onChange={(e)=>this.selectFAQ(e.target.value)}
                          >
                            <option disabled selected value> FAQ </option>
                            <option value="https://res.cloudinary.com/cashaam/image/upload/v1627597813/guides/how-to-do-shipping-delivery_sv18lp.gif">Delivery</option>
                            <option value="https://res.cloudinary.com/cashaam/image/upload/v1627597487/guides/when-you-get-an-order_tvivct.gif">Payment</option>
                            <option value="https://res.cloudinary.com/cashaam/image/upload/v1627597605/guides/how-to-add-products-2_rmphjz.gif">Add Products</option>
                          </select>
                          <select
                            id="Help-2"
                            name="Help-2"
                            data-name="Help 2"
                            className="helpselectlist w-select"
                            onChange={(e)=>this.selectHELP(e.target.value)}
                          >
                            <option disabled selected value> Help </option>
                            {this.props.sellerCountry?.toLowerCase()=="nigeria"?
                              <option value="https://api.whatsapp.com/message/KCMW4LVXEVS6B1">Contact Us</option>: 
                              <option value="https://api.whatsapp.com/message/WAIDJC3FXFPEN1">Contact Us</option>
                            }
                            
                            <option value="https://calendly.com/cashaam-setup/usa">Schedule Time</option>
                          </select>
                        </form>
                        <div className="w-form-done">
                          <div>
                            Thank you! Your submission has been received!
                          </div>
                        </div>
                        <div className="w-form-fail">
                          <div>
                            Oops! Something went wrong while submitting the
                            form.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

export default TransactionTopBar;