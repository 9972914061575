import React from "react";
import { Link } from "react-router-dom";

const ProductAdditionSuccess = (props) => {
  return (
    <div className="form-holder">
      <div style={{ minWidth: "60%" }} className="product-steps">
        <div style={{ minWidth: "100%" }} className="onboarding-steps">
          <div className="block-title-1">
            <div className="icon-success w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-circle-check"
                width={84}
                height={84}
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </div>
            <h4 className="h4">Your Product was added successfully!</h4>
          </div>
          {/* <div className="microcopy-signup">We've verified you own the account and your collections account has been configured to receive payment.</div> */}
          <div className="holder-btn">

            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.addMoreProducts()}
              className="btn-primary"
            >
              <div className="text-btn-primary">Add More Products</div>
            </div>
            <Link to="/seller/dashboard/products" className="btn-secondary">
              <div className="text-btn-secondary">Go to Product Page</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductAdditionSuccess;
