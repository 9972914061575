import React from "react";
import LeftNav from "../../../common/LeftNav";
import { Link } from "react-router-dom";
import MessageHolder from "./MessageHolder";
import ChatWindow from "./ChatWindow";
import BotChat from "./BotChat";
import CustomerChat from "./CustomerChat";
import getSellerChats from "../../../helpers/getSellerChats";
import firebase from "../../../../firebaseConfig";
import Loading from "../../../common/Loading";
import isBankSetup from "../../../helpers/isBankSetup";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getUserLocation from "../../../helpers/getUserLocation";
class Chat extends React.Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      sellerId: "",
      email: "",
      phoneNumber: "",
      storeName: "",
      messages: [],
      displayBotChat: true,
      bankSetupCompleted: false,
    };
  }

  onDisplayBotChat = () => {
    this.setState({ displayBotChat: true });
  };

  onHideBotChat = () => {
    this.setState({ displayBotChat: false });
  };

  fetchSellerInfo = (id) =>
    new Promise((resolve, reject) => {
      Loading(true, "Loading...");
      //  (id) =>{
      const db = firebase.firestore();
      db.collection("sellersAccounts")
        .doc(id)
        .get()
        .then(async (doc) => {
          Loading(false);
          if (doc.exists && doc.data().slug) {
            // this.setState({
            //   sellerId: id,
            //   email: await doc.data().email,
            //   phoneNumber: await doc.data().phoneNumber
            // });
            const email = await doc.data().email;
            const phoneNumber = await doc.data().phoneNumber;
            const storeName = await doc.data().storeName;
            const firstName = await doc.data().firstName;
            resolve({ email, phoneNumber, storeName, firstName });
          }
        })
        .catch((error) => {
          console.log("error getting seller info", error);
          Loading(false);
        });
    });

  getChat = async (phoneNumber) => {
    const messages = await getSellerChats(phoneNumber);
    this.setState({ messages });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    } else {
      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");


      }
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  async componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // const { screen } = this.props.location.state || "products";
        // this.handleRender(screen);

        let location = await this.getLocation();
        this.checkRegistrationCompletion(user.uid, location);

        this.setState({
          sellerName: user.displayName,
        });
        const sellerId = user.uid;
        const sellerInfo = await this.fetchSellerInfo(sellerId);
        const { email, phoneNumber, storeName, firstName } = sellerInfo;
        const bankSetupCompleted = await isBankSetup(sellerId);
        console.log("bank??", bankSetupCompleted);
        this.setState({
          sellerId,
          email,
          phoneNumber,
          storeName,
          firstName,
          bankSetupCompleted,
        });
        this.getChat(phoneNumber);
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    console.log("chat state", this.state);
    return (
      <div className="main">
        <div className="container">
          <LeftNav
            sellerName={this.state.firstName}
            logoutHandler={this.logoutHandler}
            sellerId={this.state.sellerId}
          />

          <div className="content">
            <h1 className="title-page">Conversations</h1>
            <div
              style={{
                display: "flex",
                position: "absolute",
                left: "auto",
                right: "1%",
                top: "8%",
                bottom: "auto",
                minWidth: "fit-content",
              }}
            >
              {this.state.bankSetupCompleted ? (
                <a
                  style={{ maxWidth: "fit-content" }}
                  href="https://api.whatsapp.com/send?phone=18037028505&text=%0A%0AHello"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="more-btn new-product w-inline-block"
                >
                  <div style={{ display: "block" }} className="link-tx">
                    Start Chat
                  </div>
                </a>
              ) : null}
            </div>
            <div
              data-duration-in={300}
              data-duration-out={100}
              className="tabs-2 w-tabs"
            >
              <div className="w-tab-menu">
                {this.state.displayBotChat === false ? (
                  <>
                    <a
                      onClick={() => this.onDisplayBotChat()}
                      data-w-tab="Bot Chats"
                      className="tab-link-bot-chats w-inline-block w-tab-link"
                    >
                      <div>Bots</div>
                    </a>
                    <a
                      onClick={() => this.onHideBotChat()}
                      data-w-tab="Customer Chat"
                      className="tab-link-customer-chat w-inline-block w-tab-link w--current"
                    >
                      <div>Customers</div>
                    </a>
                  </>
                ) : (
                    <>
                      <a
                        onClick={() => this.onDisplayBotChat()}
                        data-w-tab="Bot Chats"
                        className="tab-link-bot-chats w-inline-block w-tab-link w--current"
                      >
                        <div>Bots</div>
                      </a>
                      <a
                        onClick={() => this.onHideBotChat()}
                        data-w-tab="Customer Chat"
                        className="tab-link-customer-chat w-inline-block w-tab-link "
                      >
                        <div>Customers</div>
                      </a>
                    </>
                  )}
              </div>
              <div className="w-tab-content">
                {this.state.displayBotChat === false ? (
                  <CustomerChat />
                ) : (
                    <BotChat
                      phoneNumber={this.state.phoneNumber}
                      messages={this.state.messages}
                      storeName={this.state.storeName}
                    />
                  )}
              </div>
            </div>
            <Link
              to="/seller/dashboard/addProduct"
              className="add-product w-inline-block"
            >
              <div className="html-embed w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={3}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-plus"
                >
                  <line x1={12} y1={5} x2={12} y2={19} />
                  <line x1={5} y1={12} x2={19} y2={12} />
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
