import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import { Link } from "react-router-dom";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";
import SelectStates from "../seller/dashboard/orders/SelectStates";

const PersonalDetails = (props) => (
  <>
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1 center-title">
          <h4 className="h4">Add Your Address</h4>
        </div>

        <div style={{margin:"0 auto"}} className="microcopy-signup">
          This address will be used to create your virtual card.
        </div>

        <div className="holding-onboarding">
          <div className="forms w-form">
            <form
              onSubmit={props.handleSubmit}
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >

              <div className="address-elements">
                 {props.countryError ?
                <div style={{ color: "red" }}>{props.countryError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">
                  Country
                </label>
                
                }
                {/*
                <input
                  onChange={props.handleChange}
                  value={props.country}
                  type="text"
                  className={`input-text w-input half-width-inputs ${props.countryError?"input-error":""}`}
                  maxLength={256}
                  name="country"
                  data-name="country"
                  placeholder="Enter your country"
                  id="country"
                /> */}

                <div
                  className={`input-text w-input half-width-inputs ${props.countryError?"input-error":""}`}
                  
                >
                  {props.country}
                </div>

              </div>


              {props.country?.toLowerCase()=="nigeria"?

              <div className="address-elements">
                {props.addressError ?
                <div style={{ color: "red" }}>{props.addressError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">
                  Street
                </label>
                }
                <input
                  onChange={props.handleChange}
                  value={props.street}
                  type="text"
                  className={`input-text w-input half-width-inputs ${props.addressError?"input-error":""}`}
                  maxLength={256}
                  name="street"
                  data-name="street"
                  placeholder="Enter your street"
                  id="street"
                />
              </div>

              :
              <>
              {props.addressError ?
                <div style={{ color: "red" }}>{props.addressError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">Address</label>
              }
              
              <PlacesAutocomplete
                value={props.address || ""}
                onChange={props.setAddress}
                onSelect={props.handleSelectAddress}
                shouldFetchSuggestions={props.shouldFetchSuggestions}
                onError={props.onError}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    
                    <input
                      value={props.address}
                      {...getInputProps({
                        placeholder: "",
                      })}
                      data-name="address"
                      maxLength="5000"
                      id="address"
                      name="address"
                      placeholder="Your Business/Home Address "
                      //required="required"
                      className={
                        props.addressError
                          ? "input-text w-input input-glow input-error"
                          : "input-text w-input input-glow"
                      }
                    ></input>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        minWidth: "100%",
                        // borderLeft: "2px solid rgb(219, 219, 219)",
                        // borderRight: "2px solid rgb(219, 219, 219)",
                        boxShadow: "0px 0px 2px",
                      }}
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion, i) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#d0bdf0"
                            : "white",
                          borderBottom: "2px solid rgb(219, 219, 219)",
                          borderTop:
                            i > 0 ? "0.5px solid rgb(219, 219, 219)" : null,
                          cursor: "pointer",
                          padding: "5px",
                          // boxShadow: "0px 0px 2px",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                            key={i}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </PlacesAutocomplete>
              </>
              }

              <div className="half-width">
                  {props.country?.toLowerCase()=="nigeria"?
                     <div className="address-element">
                        {props.stateError ?
                            <div style={{ color: "red",marginBottom: "5px" }}>{props.stateError}</div>
                            : 
                            <label>State</label>
                        }
                            <SelectStates 
                              handleDeliveryChange={props.handleChange}
                              state={props.state}
                              name={"state"}
                            />
                          </div>
                        :
                      <div className="address-element">
                        {props.stateError ?
                            <div style={{ color: "red",marginBottom: "5px" }}>{props.stateError}</div>
                            : 
                        <label htmlFor="productWeight" className="label-2x">
                          State
                        </label>
                        }
                        <input
                          onChange={props.handleChange}
                          value={props.state}
                          type="text"
                          className={`input-text w-input half-width-inputs ${props.stateError?"input-error":""}`}
                          maxLength={256}
                          name="state"
                          data-name="state"
                          placeholder="Enter your state"
                          id="state"
                        />
                      </div>
                  }



                {props.country?.toLowerCase()=="nigeria" && props.stateLGA?
                  <div className="address-element">
                    {props.cityError ?
                      <div style={{ color: "red",marginBottom: "5px" }}>{props.cityError}</div>
                      : 
                      <label>City</label>
                    }
                        <select
                            value={props.city}
                            id="buyerCity"
                            className={`mc-input input-text w-input ${props.cityError?"input-error":""}`}
                            placeholder="City" name="city"
                            onChange={props.handleChange}
                            >
                                {props.stateLGA.map((lga, index) => {
                                return <option value={lga} name="state"> {lga}</option>
                                })}
                        </select>
                      </div>
                      :

                    <div className="address-element">
                      {props.cityError ?
                        <div style={{ color: "red",marginBottom: "5px" }}>{props.cityError}</div>
                        : 
                        <label htmlFor="productWeight" className="label-2x">
                          City
                        </label>
                        }
                        <input
                          onChange={props.handleChange}
                          value={props.city}
                          type="text"
                          className={`mc-input input-text w-input ${props.cityError?"input-error":""}`}
                          maxLength={256}
                          name="city"
                          data-name="city"
                          placeholder="Enter your city"
                          id="city"
                        />
                    </div>
                  }
              </div>

              <div className="half-width">
              {/* {props.country?.toLowerCase()!="nigeria"? */}
                <div className="address-element">
                  <label htmlFor="productWeight" className="label-2x">
                    Zipcode
                  </label>
                  <input
                    onChange={props.handleChange}
                    value={props.zipcode}
                    type="text"
                    className="input-text w-input half-width-inputs"
                    maxLength={256}
                    name="zipcode"
                    data-name="zipcode"
                    placeholder="Enter your zipcode (Optional)"
                    id="zipcode"
                  />
                </div>
                {/* :null} */}
                <div className="address-element">
                  <label htmlFor="productWeight" className="label-2x">
                    Apt/Floor
                  </label>
                  <input
                    onChange={props.handleChange}
                    value={props.apt}
                    type="text"
                    className="input-text w-input half-width-inputs"
                    maxLength={256}
                    name="apt"
                    data-name="apt"
                    placeholder="Enter your Apt/Floor"
                    id="apt"
                  />
                </div>
              </div>


              <div className="half-width">
                <div className="address-element">
                  {props.dobError?
                  <div style={{ color: "red",marginBottom: "5px"}}>{props.dobError}</div>
                  :
                  <label for="step1">Date of Birth<em>(13yrs or older)</em></label> 
                  }
                    <input
                    value={props.birthday}
                    onChange={props.handleChange}
                    type="date"
                    className={`input-text w-input half-width-inputs`}
                    maxLength={256}
                    name="birthday"
                    placeholder="Enter Your Date of Birth"
                    id="updateProfileFirstName"
                    max="2010-04-30"
                    />
                </div>


                <div className="address-element">
                  <label htmlFor="productWeight" className="label-2x">
                      Website (optional)
                      </label>
                      <input
                        onChange={props.handleChange}
                        value={props.business}
                        type="text"
                        className="input-text w-input half-width-inputs"
                        maxLength={256}
                        name="business"
                        data-name="business"
                        placeholder="Business or personal website"
                        id="business"
                      />
                </div>
              </div>
              <div style={{marginBottom:"15px"}} className="radio-form">
                    <label className="btn-radio w-radio">
                      <input
                        checked={props.registedBusiness}
                        onChange={props.handleChange}
                        type="radio"
                        data-name="registedBusiness"
                        id="registedBusiness"
                        name="registedBusiness"
                        defaultValue={props.registedBusiness}
                        className="w-form-formradioinput w-radio-input"                     
                        
                      />
                      <span
                        htmlFor="fullTime"
                        className="input-radio w-form-label"
                      >
                        Do you have a registered business?
                      </span>
                    </label>
                  </div>

              <input
                type="submit"
                defaultValue="Next"
                data-wait="Please wait..."
                className="submit-primary w-button"
              />
            </form>

            <div
              onClick={() => props.goBackToAboutStoreForm()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Go Back
            </div>

            {/* <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div> */}
            {/*props.invalidAddress !== false ? (
              <div style={{ display: "flex" }} className="w-form-fail">
                <div>Invalid address, Please check and try again.</div>
              </div>
            ) : null*/}
          </div>
        </div>
      </div>
    </div>
    <Link to="/" className="logo-black w-inline-block">
      <img
        src={logo}
        // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
        sizes="(max-width: 767px) 80px, 100px"
        alt
        className="cashaam-logo-dark"
      />
      <img
        src={logoWhite}
        // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
        sizes="100vw"
        alt
        className="cashaam-logo-white"
      />
    </Link>
  </>
);
export default PersonalDetails;
