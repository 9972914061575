import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import firebase from "../../../../firebaseConfig";
import getUserLocation from "../../../helpers/getUserLocation";
import Layout from './Layout'

import { getServices, deleteService } from "../../../helpers/service";
import Loading from '../../../common/Loading';
import Services from './Services';
import EditService from './EditService'
import getSellersInfo from '../../../helpers/getSellersInfo';

/**
 * This JSX function contains components to Edit a service and 
 * display all services
 */
const AllServices = () => {
    const [pageInfo, setPageInfo] = useState({
        userId: "",
        userCurrentCountry: "",
        firstName: "",
        slug: ""
    })
    const [services, setServices] = useState([])
    const [selectedService, setSelectedService] = useState({})

    const [isEditing, setIsEditing] = useState(false)

    /**
     * Function fetches user info (userId, country, name) from data base when component is mounted
     */
    const fetchData = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                Loading(true, "Loading")
                const locationData = await getUserLocation();
                const country = locationData.country_name === "India"
                    ? "Nigeria"
                    : locationData.country_name;

                // const country = await getLocation()
                const sellerInfo = await getSellersInfo(user.uid)
                const { firstName, lastName, slug } = sellerInfo;
                setPageInfo({
                    userId: user.uid,
                    firstName: firstName,
                    lastName,
                    currentCountry: country,
                    slug
                })
                const services = await getServices(user.uid)
                setServices(services)
                Loading(false)
            } else {
                this.props.history.push("/");
            }
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const onEditService = (service) => {
        setSelectedService(service)
        setIsEditing(true)
    }

    const onCancelEditing = () => {
        setIsEditing(false)
    }

    const onCloseEditing = () => {
        setIsEditing(false)
        fetchData()
    }

    const onDeleteService = async (service) => {
        try {
            await deleteService(pageInfo.userId, service.id)
            fetchData()
        } catch (err) {
            
        }
    }

    return (
        <Layout
            firstName={pageInfo.firstName}
            userCurrentCountry={pageInfo.currentCountry}
        >
            {
                isEditing ?
                    <EditService
                        service={selectedService}
                        isEditing={isEditing}
                        onCancelEditing={onCancelEditing}
                        userId={pageInfo.userId}
                        onCloseEditing={onCloseEditing}
                        slug={pageInfo.slug}
                    />
                    :
                    <Services
                        services={services}
                        onEditService={onEditService}
                        onDeleteService={onDeleteService}
                    />
            }
        </Layout>
    )
}

export default AllServices
