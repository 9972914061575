import React, { Component } from 'react'
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

import Layout from './Layout'
import firebase from "../../../../firebaseConfig";
import getUserLocation from "../../../helpers/getUserLocation";
import ServiceForm from './ServiceForm'

import { saveService } from "../../../helpers/service";
import storeProductImagesToStorage from '../../../helpers/storeProductImagesToStorage'
import Loading from '../../../common/Loading';
import Confirmation from './Confirmation';
import { getAvailability } from '../../../helpers/availability';
import getSellersInfo from '../../../helpers/getSellersInfo';

const options = [
    { label: "Clothing, Shoes, Jewelry & Watches", value: 0 },
    { label: "Movies, Music & Games", value: 1 },
    { label: "Electronics, Computers & Office", value: 2 },
    { label: "Home, Garden & Tools", value: 3 },
    { label: "Food & Grocery", value: 4 },
    { label: "Beauty & Health", value: 5 },
    { label: "Toys, Kids & Baby", value: 6 },
    { label: "Handmade", value: 7 },
    { label: "Sports & Outdoors", value: 8 },
    { label: "Automotive & Industrial", value: 9 },
];

/**
 * Contains the component that displays the form to add a service
 */
class AddServices extends Component {
    state = {
        userId: "",
        slug: "",
        serviceName: "",
        serviceDescription: "",
        servicePrice: null,
        serviceDuration: null,
        userCurrentCountry: "",
        firstName: "",
        lastName: "",
        displayAvailability: false,
        selectedCategories: [],
        previewImageUrls: [],
        imageFiles: [],
        missingValues: [],
        uploadSuccess: false,
        availability: [],
        addGuestEnabled: false,
        location: "",
        email: "",
        phoneNumber: ""
    }

    /**
     * Saves service information to firebase
     * Gets called when a user submits form
     * @param {Event} e 
     */
    onAddService = async (e) => {
        e.preventDefault()
        
        try {
            Loading(true, "Service is being added")
            const {
                serviceDescription,
                serviceName,
                servicePrice,
                serviceDuration,
                selectedCategories,
                imageFiles,
                slug,
                location,
                email,
                phoneNumber,
                addGuestEnabled
            } = this.state

            const productImagesURL = [];
            for (let i = 0; i < imageFiles.length; i++) {
                const url = await storeProductImagesToStorage(
                    imageFiles[i],
                    serviceName,
                    selectedCategories[0]
                );
                productImagesURL.push(url);
            }
            const service = {
                name: serviceName,
                description: serviceDescription,
                price: servicePrice,
                duration: serviceDuration,
                images: productImagesURL,
                location,
                email,
                phoneNumber,
                addGuestEnabled,
                categories: selectedCategories.map(
                    (value) => options[value].label
                ),
                slug
            }
            // console.log("SERVICE BEING SAVED::", service)
            await saveService(this.state.userId, service)
            // this.showWaitingPopupWhenProductIsBeingSave(false)
            Loading(false)
            this.setState({ uploadSuccess: true })
        } catch (err) {
            Loading(false)
        }

    }

    /**
     * Handle changes in text input
     * @param {Event} e 
     */
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    /**
     * Handle changes in checkbox input
     * @param {Event} e 
     */
    handleCheckboxChange = (e) => {
        this.setState({
            addGuestEnabled: e.target.checked
        })
    }
    /**
     * Called when an image file is uploaded in the form
     * @param {Event} e 
     */
    onImageUpload = (e) => {
        e.preventDefault()
        let file;
        const previewImageUrls = [...this.state.previewImageUrls]
        const imageFiles = [...this.state.imageFiles]

        for (var i = 0; i < e.target.files.length; i++) {
            file = e.target.files[i]
            if (
                file.type !== "image/png" &&
                file.type !== "image/jpg" &&
                file.type !== "image/jpeg"
            ) {
                const message = `Image of type ${file.type} are not supported. Please upload a JPEG, JPG or PNG`;

                toast.error(message);
                return;
            }
            previewImageUrls.push(URL.createObjectURL(file))
            imageFiles.push(file)
        }
        this.setState({
            previewImageUrls,
            imageFiles
        })
    }

    /**
     * Removes an image at a particlar index
     * @param {number} index 
     */
    onDeleteImage = (index) => {
        const previewImageUrls = [...this.state.previewImageUrls]
        const imageFiles = [...this.state.imageFiles]
        previewImageUrls.splice(index, 1)
        imageFiles.splice(index, 1)
        this.setState({
            previewImageUrls,
            imageFiles
        })
    }

    /**
     * 
     * @param {{}} {selected} 
     */
    selectCategory = ({ selected }) => {
        if (selected.length <= 2) {
            this.setState({ selectedCategories: selected })
        } else {
            toast.error("You can only select 2 categories");
        }
    };

    getLocation = async () => {
        const locationData = await getUserLocation();
        const userCurrentCountry = locationData.country_name === "India"
            ? "Nigeria"
            : locationData.country_name
        this.setState({
            userCurrentCountry,
            // servicePrice: parseInt(priceFormater(9, userCurrentCountry))
        });
        return locationData.country_name === "India"
            ? "Nigeria"
            : locationData.country_name;
    };

    componentDidMount = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const availability = await getAvailability(user.uid)
                const sellerInfo = await getSellersInfo(user.uid)
                const { firstName, lastName, storeName, slug, address, email } = sellerInfo;
                this.setState({ 
                    firstName, 
                    lastName, 
                    storeName, 
                    userId: user.uid, 
                    availability, slug, 
                    location: address,
                    email
                });
                this.getLocation()
            } else {
                this.props.history.push("/");
            }
        });
    }

    render() {
        return (
            <Layout
                firstName={this.state.firstName}
                userCurrentCountry={this.state.userCurrentCountry}
            >
                <div className="panel-center">
                    <div className="page-header">
                        <h4 className="header-title">Add New Service</h4>
                    </div>
                    <ToastContainer />

                    {
                        this.state.uploadSuccess ?
                            <Confirmation
                                hasAvailability={this.state.availability}
                            />
                            :
                            <ServiceForm
                                submitForm={this.onAddService}
                                onImageUpload={this.onImageUpload}
                                onDeleteImage={this.onDeleteImage}
                                previewImageUrls={this.state.previewImageUrls}
                                serviceName={this.state.serviceName}
                                serviceDescription={this.state.serviceDescription}
                                servicePrice={this.state.servicePrice}
                                serviceDuration={this.state.serviceDuration}
                                currency={this.state.userCurrentCountry}
                                options={options}
                                selected={this.state.selectedCategories}
                                selectCategory={this.selectCategory}
                                handleChange={this.handleChange}
                                location={this.state.location}
                                addGuestEnabled={this.state.addGuestEnabled}
                                email={this.state.email}
                                phoneNumber={this.state.phoneNumber}
                                handleCheckboxChange={this.handleCheckboxChange}
                            />

                    }
                </div>
            </Layout>
        )
    }
}

export default AddServices
