import firebase from "../../firebaseConfig";

const getBasicInfo = (id) => {
  return new Promise(async (resole, reject) => {
    try {
      const db = firebase.firestore();
      const doc = await db.collection("sellersAccounts").doc(id).get();

      if (doc.exists && doc.data().slug) {
        resole(doc.data());
      } else {
        console.log("seller does not exist. id:", id);
        resole(false)
      }
    } catch (error) {
      console.log("error getting basic info", error);
      reject(error);
    }
  });
};
export default getBasicInfo;
