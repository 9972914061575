import React, { Component } from "react";
import "./chatBody.css";
import ChatContent from "../chatContent/ChatContent";
import UserProfile from "../userProfile/UserProfile";

import ChatList from "../chatList/ChatList"

export default class ChatBody extends Component {


  state = {
    showMenu: "show-chat", 
    showChat: "hide-chat",
    currentChat: "undefined"
    
  }

  goToMenu =() => {

    this.setState({
      showMenu:"show-menu", 
      showChat: "hide-chat",
    })
  }

  goToChat =(messagesId, sellerName, sellerId, sellerAvatar, buyerName, buyerId, buyerAvatar)=>{

    console.log("change view")

    this.setState({
      showMenu: "hide-chat", 
      showChat: "show-chat",
      currentChat:messagesId,
      sellerName, 
      sellerId, 
      sellerAvatar,
      buyerName, 
      buyerId, 
      buyerAvatar

    })
    
  }

  render() {
    return (
      <div className="main__chatbody">

        <ChatList

          sellerId={this.props.user? this.props.user.uid: "undefined"}
          chatList={this.props.chatList}
          showMenu={this.state.showMenu}
          goToChat={this.goToChat}

        />

{this.state.currentChat!=="undefined"?
        <ChatContent 
        user={this.props.user}
        currentChat={this.state.currentChat}
        showChat={this.state.showChat}
        goToMenu={this.goToMenu}
        sellerName={this.state.sellerName}
        sellerId={this.state.sellerId}
        sellerAvatar={this.state.sellerAvatar}
        buyerName={this.state.buyerName}
        buyerId={this.state.buyerId}
        buyerAvatar={this.state.buyerAvatar}
      />
      :null}
        {/* <UserProfile /> */}
      </div>
    );
  }
}
