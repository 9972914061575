import React from "react";
import { Link } from "react-router-dom";
import priceFormater from "../../helpers/priceFormater";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import Facebook from "../../UI/buttons/Facebook";
import Email from "../../UI/buttons/Email";
import Twitter from "../../UI/buttons/Twitter";
import Pinterest from "../../UI/buttons/Pinterest";
import Messenger from "../../UI/buttons/Messenger";
import LinkedIn from "../../UI/buttons/LinkedIn";
import { FB_APP_ID } from "../../../secrets";
import Whatsapp from "../../UI/buttons/Whatsapp";
import Spinner from "../../UI/Spinner";
const Dashboard = (props) => {
  console.log("active", props.activated);
  return (
    <>
      <div style={{ zIndex: "10" }} className="panel-center">
        <div className="page-header">
          <h4 className="header-title">Overview</h4>
          {props.productCount !== -1 ? (
            props.activated && props.activated !== false ? (
              <div className="store-status">
                <a
                  target="_blank"
                  href={`https://cashaam.com/${props.slug}`}
                  style={{
                    paddingLeft: "5px",
                    color: "black",
                    textDecoration: "none",
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  className="store-state-live"
                >
                  {`Store link: cashaam.com/${props.slug}`}
                </a>
              </div>
            ) : props.productCount < 3 ? (
              <Link
                to="/seller/dashboard/addProduct"
                style={{
                  backgroundColor: "#fb24248c",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "black",
                }}
                className="store-status"
              >
                <div className="store-state-live">{`Add ${
                  3 - parseInt(props.productCount)
                } more products to go live`}</div>
              </Link>
            ) : (
              <div
                onClick={() => props.goLive()}
                style={{ backgroundColor: "green", cursor: "pointer" }}
                className="store-status"
              >
                <div style={{ color: "white" }} className="store-state-live">
                  You can now go Live!
                </div>
              </div>
            )
          ) : null}
          {/* <div className="store-status">
            Visit your store:{" "}
            <div className="store-state-live">{`cashaam.com/${props.slug}`}</div>
          </div> */}
        </div>
        {props.firstName ? (
          <div className="welcome-note">
            <div className="holder-greet">
              <div className="greetings-text">
                {props.productCount > 2 &&
                typeof props.activated !== "undefined" &&
                props.activated === false ? (
                  <>
                    {" "}
                    Hey {props.firstName}, {props.storeName} Is Now Ready to Be
                    Launched.
                  </>
                ) : (
                  <>Welcome back {props.firstName}! </>
                )}
              </div>

              <div className="greet-instruction">
                {props.activated === true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      minWidth: "max-content",
                    }}
                  >
                    {" "}
                    <span style={{ paddingRight: "6px" }}>
                      Your store looks great! Share it on social media.
                    </span>
                    <div style={{ display: "flex" }}>
                      <FacebookShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<Facebook />}
                      />
                      <Whatsapp
                        style={{ paddingRight: "10px" }}
                        phoneNumber={props.phoneNumber}
                        url={`https://cashaam.com/${props.slug}`}
                      />
                      {/* <FacebookMessengerShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<Messenger />}
                        appId={FB_APP_ID}
                      /> */}
                      {/* <PinterestShareButton style={{ paddingRight: "10px" }}
                url={`https://cashaam.com/${props.slug}`}
                children={<Pinterest />}
                media={this.state.featuredImage}
              /> */}
                      <TwitterShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<Twitter />}
                      />
                      <LinkedinShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<LinkedIn />}
                      />
                      <EmailShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<Email />}
                      />
                    </div>
                  </div>
                ) : props.productCount < 3 ? (
                  <>
                    Add Your first <strong>three products.</strong> Studies show
                    that multiple items help increase buyer conversion rates.
                    <br />
                    <br /> ‍<strong> 1. Product Photo </strong>- Be sure to use
                    natural light, a plain background and take multiple photos
                    of the product in use or by itself (photography guide).
                    <br /> <strong>2. Product Description</strong> - Craft a
                    compelling product description that tells shoppers about the
                    item and how it was created.
                  </>
                ) : null}
              </div>
            </div>
            {
              props.productCount > 2 &&
              typeof props.activated !== "undefined" &&
              props.activated === false ? (
                <div
                  onClick={() => props.goLive()}
                  style={{ cursor: "pointer" }}
                  className="btn-add-product bottom"
                >
                  <div className="text-block-6">Go Live</div>
                </div>
              ) : null
              // <Link to="/seller/dashboard/addProduct">
              //   <div className="btn-add-product">
              //     <div className="text-block-6">Add Product</div>
              //   </div>{" "}
              // </Link>
            }
          </div>
        ) : null}

        <div className="welcome-note refer-banner">
          <div className="holder-greet refer-holder">
            <div className="greetings-text refer-text">
              {props.subscriptionActive && props.subscriptionType=="lite" && (props.userCurrentCountry.toLowerCase()==="nigeria" || props.userCurrentCountry.toLowerCase()==="india")?
              "Upgrade to Business and get a $5 Card Credit":"Invite Others & Earn $5 When They Sign Up"
            }
            
              
            </div>

            {props.subscriptionActive && props.subscriptionType=="lite" && (props.userCurrentCountry.toLowerCase()==="nigeria" || props.userCurrentCountry.toLowerCase()==="india")?
            null:
            <div className="refer-container desktop-refer">
              <div className="copied">{props.copiedReferral}</div>
              <div className="user-referral">
                    <div className="referral-link">{props.shortReferral}</div>
                    <button type="button" className="copy-btn"
                    onClick={props.copyReferral}
                    >copy</button>
              </div>
              <div className="referral-info">Your Referral Link</div>
            </div>
            }
            
          </div>
        </div>

        <div className="cards">
          <div className="card-top hide">
            <div className="card-title referral-display">
              <div className="card-left">
              My Store
              </div>
             

              <div className="refer-container mobile-refer">
              <div className="copied">{props.copiedReferral}</div>
              <div className="user-referral">
                    <div className="referral-link">{props.shortReferral}</div>
                    <button type="button" className="copy-btn"
                    onClick={props.copyReferral}
                    >copy</button>
              </div>
              <div className="referral-info">Your Referral Link</div>
            </div>
            </div>
          </div>
          <div className="card-holder">
            {/* <div className="card-block w-inline-block"> */}
            <Link
              to="/seller/dashboard/ads"
              className="card-block w-inline-block"
            >
              <div className="text-box">
                <h5 className="h5 dash">Marketing</h5>
                <div className="amount">Use our tools to drive sales and reach a wider audience</div>
              </div>
              <div 
                style={{justifyContent: "flex-start"}}
                className="number-box">
              <img 
                style={{height:"30px", width:"30px", marginTop:"5px"}}
                src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif"
               />
                    
                {/* <h5 className="h5 dash">
                  {props.customerCount !== -1 ? (
                    props.customerCount
                  ) : (
                    <Spinner />
                  )}
                </h5> */}
              </div>
            </Link>
            {/* </div> */}

            <Link to="/seller/dashboard/products" className="card-block">
                  <div className="text-box">
                    <h5 className="h5 dash">View Products</h5>
                    <div className="amount"># of Available Products</div>
                  </div>
                  <div className="number-box">
                    <h5 className="h5 dash">
                      {props.productCount !== -1 ? props.productCount : <Spinner />}
                    </h5>
                    <div className="amount pd">0</div>
                  </div>
                </Link>
                

            <Link to="/seller/dashboard/orders" className="card-block">
              <div className="text-box">
                <h5 className="h5 dash">Sold</h5>
                <div className="amount"># of Sold Products</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.soldItemsCount !== -1 ? (
                    props.soldItemsCount
                  ) : (
                    <Spinner />
                  )}
                </h5>
                <div className="amount pd">0</div>
              </div>
            </Link>
          </div>
        </div>
        <div className="cards">
          <div className="card-top">
            <div className="card-title">My Orders</div>
          </div>
          <div className="card-holder">
            <Link
              to="/seller/dashboard/orders"
              className="card-block w-inline-block"
            >
              <div className="text-box">
                <h5 className="h5 dash">New Orders</h5>
                <div className="amount">Prep for Shipping</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.orderCount !== -1 ? props.orderCount : <Spinner />}
                </h5>
                <div className="amount pd">0</div>
              </div>
            </Link>

            <Link
              to="/seller/dashboard/orders"
              className="card-block w-inline-block"
            >
              <div className="text-box">
                <h5 className="h5 dash">Shipped</h5>
                <div className="amount">Track Shipped Products</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.dispatchedOrdersCount !== -1 ? (
                    props.dispatchedOrdersCount
                  ) : (
                    <Spinner />
                  )}
                </h5>
                <div className="amount pd">0</div>
              </div>
            </Link>


            <Link
              to="/seller/dashboard/chat"
              className="card-block w-inline-block"
            >
              <div className="text-box">
                <h5 className="h5 dash">Chat</h5>
                <div className="amount">Conversations with customers</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  
                </h5>
                {/* <div className="amount pd">0</div> */}
                <div className="number-box">
                <h5 className="h5 dash">
                  {props.notification !== -1 ? props.notification : <Spinner />}
                </h5>
                <div className="amount pd">0</div>
              </div>
              </div>
            </Link>

            {/* <div className="card-block">
              <div className="text-box">
                <h5 className="h5 dash">Chatbot</h5>
                <div className="amount">Conversations with Cashaam Bot</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">0</h5>
                <div className="amount pd">0</div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="cards">
          <div className="card-top">
            <div className="card-title">My Sales</div>
          </div>
          <div className="card-holder">
            <div className="card-block static">
              <div className="text-box">
                <h5 className="h5 dash">Today</h5>
                <div className="amount">Estimated Revenue</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.oneDayOrderCount !== -1 ? (
                    props.oneDayOrderCount
                  ) : (
                    <Spinner />
                  )}
                </h5>
                <div className="amount">
                  {" "}
                  {props.oneDayRevenue !== -1 ? (
                    priceFormater(props.oneDayRevenue, props.oneDayOrderCurrency)
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
            <div className="card-block static">
              <div className="text-box">
                <h5 className="h5 dash">7 Days</h5>
                <div className="amount">Estimated Revenue</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.sevenDayOrderCount !== -1 ? (
                    props.sevenDayOrderCount
                  ) : (
                    <Spinner />
                  )}
                </h5>
                <div className="amount">
                  {props.sevenDaysRevenue !== -1 ? (
                    priceFormater(props.sevenDaysRevenue, props.sevenDayOrderCurrency)
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
            <div className="card-block static">
              <div className="text-box">
                <h5 className="h5 dash">30 Days</h5>
                <div className="amount">Estimated Revenue</div>
              </div>
              <div className="number-box">
                <h5 className="h5 dash">
                  {props.thirtyDayOrderCount !== -1 ? (
                    props.thirtyDayOrderCount
                  ) : (
                    <Spinner />
                  )}
                </h5>
                <div className="amount">
                  {props.thirtyDaysRevenue !== -1 ? (
                    priceFormater(props.thirtyDaysRevenue, props.thirtyDayOrderCurrency)
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
