import React from "react";
import Snapshot from "./Snapshot";
import VirtualCard from "./VirtualCard";
import DetailedCard from "./DetailedCard";
import CardOnboarding from "./CardOnboarding";
import TopupAds from "./TopupAds";
import RechargeCard from "./RechargeCard";
import RechargeUS from "./RechargeUS";
import CardInfo from "./CardInfo";
import StoreSnapshot from "./StoreSnapshot";
import CardTransactions from "./CardTransactions";
import UserAgreement from "./UserAgreement";
import AdsHelp from "./AdsHelp";
import VerifyButton from "../stripe/VerifyButton"
import { VirtualCardAPI, 
    PAYSTACK_SUBACCOUNT,
    PAYSTACK_KEY,
    STRIPE_KEY,
    stripeTopUp,
    topUpProductId,
    NAIRA_EXCHANGE,
    TRANSFERWISE_KEY
  } from "../../../../secrets";

import firebase from "../../../../firebaseConfig";
import {loadStripe} from '@stripe/stripe-js';
import Verification from "./Verification";

const stripePromise = loadStripe(STRIPE_KEY);

const TOPUP_FEE=0.05;

const FREE_LIMIT=100;
const LITE_LIMIT=25;
const MONTHLY_LIMIT=100;
const QUARTERLY_LIMIT=150;
const ANNUAL_LIMIT=200;
const GLOBAL_LIMIT=2000;
const DAILY_LIMIT=100;
const VERIFIED_DAILY_LIMIT=201;
const VERIFIED_ACCOUNT_LIMIT=4000;
const LIMIT_PERIOD=30;
const RATE_MARKUP=1.30;

class MarketingDashboard extends React.Component {
  state = {
    storeInfo: this.props.storeInfo,
    showCardOTP: false,
    cardOtp: "",
    topupModal: false,
    topupAmount:"",
    startOnboarding: false,
    totalAmount: 0,
    cashaamFee: 0,
    virtualCards: null
  };

  handleChange = (e) => {

    let dobError="";
    if(e.target.name=="birthday"){
      let dod=e.target.value;
      console.log("dob",dod)
      if(new Date(dod) > new Date('2010-04-30')){
        console.log("dob is before 2010")
        dobError="You must be older than 13yrs old."
      }else if(new Date(dod) < new Date('1900-01-30')){
        dobError="Please enter your birthday"
      }
    }
    this.setState({
      [e.target.name]: e.target.value,
      dobError
    });
  };

  handleSteps = (e) => {
    let value=e.target.checked;
    console.log("step",e.target.name, value)
    this.setState({
      [e.target.name]: value,
    });
  };

  revealCard = async () => {
    try {

      let cardId=this.state.cardId;
      
      const queryData = {
        cardId: cardId,
      };
      console.log(queryData);
      const res = await fetch(`${VirtualCardAPI}/get-card`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });
      console.log("reveal card", res);

      if (res.status === 200) {
        let result = await res.json();
        console.log("revealed card", result);
        let card=result?.card;
        this.setState({
          detailedCard: card,
          cardRevealed: true
        })
      } else {
        console.log(" failed to reveal card");
      }
    } catch (error) {
      console.log("error revealing card", error);
    }
  };

  confResult = null;

  verifyPhone = () => {

    this.setState({ 
      errorVerificationCode: ""
    })

    var appVerifier = window.recaptchaVerifier;
    
    let phoneNumber = `${this.state.phoneNumber}`;
    if(phoneNumber.substring(0, 1)!="+"){
      phoneNumber = `+${this.state.phoneNumber}`;
    }
    console.log("phoneNumber",phoneNumber);
    firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      this.setState({
        showSMSVerification: true,
      });
      this.confResult = confirmationResult;
      console.log(this.confResult);
      console.log(confirmationResult);
    })
    .catch(function (error) {
      // Error; SMS not sent
      // ...
      console.log(error);
    });
  };
  
  submitConfResult = async () => {
    // this.displayLoading();
    this.setState({
      loadingReveal: true,
      errorVerificationCode: ""
    })
    var code = this.state.cardOtp;
    if(!code) {
      this.setState({
        errorVerificationCode: "Please enter the verification code sent to your phone.",
        loadingReveal: false
      })
      return;
    }

    this.confResult
      .confirm(code)
      .then(async (result) => {
        // this.hideLoading();
        this.revealCard();

        this.setState({
          showCardOTP: false,
          loadingReveal: false
        })

      }).catch((error) => {
        // this.hideLoading();

        if (error.code === "auth/invalid-verification-code") {
          console.log("Wrong code, please check your phone and try again");
          this.setState({ 
            errorVerificationCode: "You entered invalid code, please check your phone and try again",
            loadingReveal: false
          })
        }else{
          this.setState({ 
            errorVerificationCode: "There has been an error processing your request, please refresh the page and try again.",
            loadingReveal: false
          })
        }
        console.log("error confirming code", error);
      })
  };

  closeOnboarding=() => {
    this.setState({
      startOnboarding: false
    })
}

  displayOnboarding=()=>{
    this.setState({ 
      startOnboarding: false
    })
  }

  displayCard = (display) => {

    if(!this.props.storeInfo?.virtualCards){

      this.setState({ 
        startOnboarding: true
      })
      return;
    }
    this.setState({
      showCardOTP: display
    })

    if(display){
      this.verifyPhone();
    }
    
  }

  hideCard =()=>{
    this.setState({
      cardRevealed: false
    })
  }

  hideVerification=()=>{
    this.setState({
      displayVerification: false,
      step1: false,
      step2: false,
      step3: false
    })
  }

  showVerification=()=>{
    this.setState({
      displayVerification: true,
      step1: false,
      step2: false,
      step3: false,
      topupModal: false
    })
  }

  showTopup=()=>{
    this.setState({
      topupModal: true
    })
  }

  hideTopup=()=>{
    this.setState({
      topupModal: false
    })

    if(this.state.newCard){
      this.displayAdsHelp()
    }
  }

  createAndTopup=()=>{
    this.setState({
      topupModal: true,
      startOnboarding: false,
      showUserAgreeMent: false,
    })
  }

  displayAdsHelp=()=>{
    this.setState({
      showAdsHelp: true
    })
  }

  hideAdsHelp=()=>{
    this.setState({
      showAdsHelp: false
    });

    if(this.state.newCard){
      window.location.reload(false);
    }

  }

  hideAgreement=()=>{
    this.setState({
      topupModal: false,
      startOnboarding: false,
      showUserAgreeMent: false,
    })
  }

  displayAgreement =()=>{
    this.setState({
      showUserAgreeMent: true,
      topupModal: false,
      startOnboarding: false

    })
  }

  changeAmount=(e)=>{

    
    let amountEntered=e.target.value;
    if(amountEntered){
      amountEntered=parseFloat(amountEntered.replace(/,/g, ''));
      console.log("amountEntered",amountEntered);

      let cashaamFee=TOPUP_FEE*amountEntered;
      cashaamFee=parseFloat(cashaamFee)?.toFixed(2);
      console.log("cashaamFee",cashaamFee)

      let totalAmount=parseFloat(amountEntered)+parseFloat(cashaamFee);
      console.log("totalAmount",totalAmount);
      this.setState({
        topupAmount: e.target.value,
        totalAmount,
        cashaamFee,
        topUpError:""
      })

    }else{
      this.setState({
        topupAmount: amountEntered,
        totalAmount: 0,
        cashaamFee: 0,
        topUpError:""
      })

    }

  }

  goBack =()=>{
    
    this.setState({
      topUpLoading: false,
      topUpError:"",
      topUpSuccess: false,
      upgradeSuggester: false,
      roundOffAmount: false,
      dailyLimitReached: false
    })

  }

  stripePay=async()=>{

    try{

      //Card Top up disabled
      return;

      if(!this.props.storeInfo?.virtualCards){
        return;
      }
      
      /* ENABLED US stripe Payment*/
      this.setState({
        topUpLoading: true,
        topUpError:"",
        topUpSuccess: false,
        upgradeSuggester: false,
        roundOffAmount: false
      })
  
      let {
        firstName, 
        lastName, 
        email,
        phoneNumber,
        id,
        slug,
        storeName,
        country,
        state,
        city,
        zipcode,
        street,
        stripeVerification
      }=this.props.storeInfo;
  
      let {totalAmount, cashaamFee}=this.state;
      let verificationStatus=stripeVerification?.verificationSession?.status;

      totalAmount=parseFloat(totalAmount).toFixed(2);
  
      let monthBalance=parseFloat(this.state.monthBalance);
      let topUpLimit=parseFloat(this.state.topUpLimit);
  
  
      if(totalAmount>monthBalance){
        this.setState({
          topUpError: `Exceeded your accounts monthly limit of $${topUpLimit}`,
          topUpLoading: false,
          topUpSuccess: false, 
          upgradeSuggester: true
        })
        return;
      }
  
      if(verificationStatus && verificationStatus=="verified" && this.props.storeInfo?.cardApproval){
  
        console.log(`limit ${totalAmount}>${VERIFIED_DAILY_LIMIT}`)
        if((totalAmount-cashaamFee)>VERIFIED_DAILY_LIMIT){
          this.setState({
            topUpError: `You have exceeded your account's daily limit of $200.`,
            topUpLoading: false,
            topUpSuccess: false,
            dailyLimitReached: true
          })
          return;
        }
  
      }else {
        let verificationSuggester= false;

        if(!verificationStatus || verificationStatus!="verified"){
          verificationSuggester= true;
        }
        
        this.setState({
          verificationSuggester,
          topUpLoading: false,
          topUpSuccess: false,
          dailyLimitReached: false,
          dailyLimitReached: true
        })
        return;
      }
  
      if(totalAmount<=1){
        this.setState({
          topUpError: "Your top-up amount has to be atleast $1",
          topUpLoading: false,
          topUpSuccess: false
        })
        return;
      }


      cashaamFee=parseFloat(cashaamFee);
      let topUpAmount = parseFloat(totalAmount-cashaamFee).toFixed(1);

      let eventType="";
      if(this.props.storeInfo?.virtualCards){
        eventType="VIRTUAL_CARD_RECHARGE";
      }else{
        eventType="VIRTUAL_CARD_CREATION";
      }
  
      let {dob}=this.props.storeInfo;
      if(!dob){
        dob=null;
      }
      
      
      let stripeAcceptanceTimestamp=Date.now();
      console.log("stripeAcceptance",stripeAcceptanceTimestamp)

      let stripeAmount=Math.ceil(totalAmount*100);
      let cardId=this.state.cardId;

      
      const queryData = {
        productId: topUpProductId,
        cardId: cardId,
        sellerId: id,
        storeName,
        slug,
        amount: stripeAmount,
        transactionFee: parseFloat(cashaamFee).toFixed(2),
        email,
        metadata: {

          sellerId: id,
          slug,
          paymentMethod: "stripe",
          amount: totalAmount,
          currency: "USD",
          name: `${firstName} ${lastName}`,
          email,
          phoneNumber,
          transactionFee: parseFloat(cashaamFee),
          eventType,
          storeName,
          country,
          state,
          city,
          zipcode,
          street,
          dob,
          stripeAcceptanceTimestamp,
        }
      };
      console.log(queryData);
      const res = await fetch(`${stripeTopUp}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });


      let response = await res?.json();
      console.log("response",response)

      let sessionURL=response?.sessionURL;
      window.open(sessionURL,"_self");

    }catch(error){

      console.log("error",error)
    }
  }

  makePayment=()=>{

    //Top up Card Disabled
    return;

    this.setState({
      topUpLoading: true,
      topUpError:"",
      topUpSuccess: false,
      upgradeSuggester: false,
      roundOffAmount: false
    })

    try{

      if(!this.props.storeInfo?.virtualCards){
        return;
      }

    let {
      firstName, 
      lastName, 
      email,
      phoneNumber,
      id,
      slug,
      storeName,
      country,
      state,
      city,
      zipcode,
      street,
      stripeVerification
    }=this.props.storeInfo;

    let {totalAmount, topupAmount, nairaRate}=this.state;

    let cashaamFee=0;
    let verificationStatus=stripeVerification?.verificationSession?.status;
    // let price=parseFloat(this.state.totalAmount)*100;

    // let dollarAmount=price/(NAIRA_EXCHANGE*100);
    // dollarAmount=parseFloat(dollarAmount).toFixed(2);
    // let dollarFee=parseFloat(cashaamFee)/NAIRA_EXCHANGE;
    // dollarFee=dollarFee?.toFixed(2);

    let nairaAmount=parseFloat(topupAmount)*nairaRate;
    let price = parseFloat(nairaAmount)*100;

    let dollarAmount=parseFloat(topupAmount).toFixed(2);
    let dollarFee=parseFloat(cashaamFee)/nairaRate;
    dollarFee=dollarFee?.toFixed(2);

    console.log("dollarAmount",dollarAmount);
    console.log("dollarFee",dollarFee)

    let monthBalance=parseFloat(this.state.monthBalance);
    let topUpLimit=parseFloat(this.state.topUpLimit);


    if(dollarAmount>monthBalance){
      this.setState({
        topUpError: `Exceeded your accounts monthly limit of $${topUpLimit}`,
        topUpLoading: false,
        topUpSuccess: false, 
        upgradeSuggester: true
      })
      return;
    }

    if(verificationStatus && verificationStatus=="verified"){

      console.log(`limit ${dollarAmount}>${VERIFIED_DAILY_LIMIT}`)
      if(dollarAmount>VERIFIED_DAILY_LIMIT){
        this.setState({
          topUpError: `You have exceeded your account's daily limit of $200.`,
          topUpLoading: false,
          topUpSuccess: false,
          dailyLimitReached: true
        })
        return;
      }

    }else if(dollarAmount>DAILY_LIMIT){
      this.setState({
        topUpError: `You have exceeded your account's daily limit of $100.`,
        topUpLoading: false,
        topUpSuccess: false,
        dailyLimitReached: true
      })
      return;
    }
    

    if(dollarAmount<1){
      this.setState({
        topUpError: "Your top-up amount has to be atleast $1",
        topUpLoading: false,
        topUpSuccess: false
      })
      return;
    }


    // let roundedDown=Math.floor(parseFloat(dollarAmount-dollarFee).toFixed(2));
    // let diff=parseFloat(dollarAmount-dollarFee).toFixed(2);
    // console.log("dollarAmount,dollarFee",dollarAmount,dollarFee);
    // console.log("dollarAmount-dollarFee",diff);
    // console.log("Math.floor dollarAmount-dollarFee",roundedDown);

    // let nearestDollar = (diff!= roundedDown); 
    
    // let diffRounded=Math.round(diff);
    // console.log("diffRounded",diffRounded-diff);
    // if (nearestDollar && !((diffRounded-diff).toFixed(2)==0.01 || (diffRounded-diff).toFixed(2)==-0.01)){
    //   this.setState({
    //     roundOffAmount: true,
    //     topUpLoading: false,
    //     topUpSuccess: false
    //   })
    //   return;
    // }

    if (dollarAmount % 1 != 0){
      this.setState({
        roundOffAmount: true,
        topUpLoading: false,
        topUpSuccess: false
      })
      return;
    }


    price=Math.floor(price)
    console.log("price",price);
    console.log("cashaamFee NGN",cashaamFee);

    let eventType="";
    if(this.props.storeInfo?.virtualCards){
      eventType="VIRTUAL_CARD_RECHARGE";
    }else{
      eventType="VIRTUAL_CARD_CREATION";
    }

    let {dob}=this.props.storeInfo;
    if(!dob){
      dob=null;
    }
    
    
    let stripeAcceptanceTimestamp=Date.now();
    console.log("stripeAcceptance",stripeAcceptanceTimestamp)

    //Character limit for stripe is 24 chars
    let remainingChars=23-firstName?.length;
    console.log("remainingChars",remainingChars);
    let newLastN=lastName?.substring(0, remainingChars);
    console.log("newLastN",newLastN);
    //Shorten last name to fit the character limit for stripe
    lastName=newLastN;

    var handler = PaystackPop.setup({

      subaccount: PAYSTACK_SUBACCOUNT,
      key: PAYSTACK_KEY,
      email,
      amount: price,
      currency: "NGN",
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: phoneNumber,
          },
        ],
        
        sellerId: id,
        slug,
        paymentMethod: "paystack",
        amount: price/100,
        currency: "NGN",
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
        exchangeRate: nairaRate,
        transactionFee: parseFloat(cashaamFee),
        eventType,
        storeName,
        country,
        state,
        city,
        zipcode,
        street,
        dob,
        stripeAcceptanceTimestamp,
      },
      callback: (response) => {
        console.log("paystack Responce: ", response);
        console.log("success. transaction ref is " + response.reference);
        console.log("success. transaction ref is " + response.transaction);
        
        if(this.props.storeInfo?.virtualCards){
          console.log("call top-up api")
          // this.callTopupAPI(dollarAmount, dollarFee);
        }else{
          console.log("create and top-up");
           //this.callCreateCard(dollarAmount, dollarFee);
        }
        
        this.setState({
          topUpLoading: false,
          topUpError:"",
          topUpSuccess: true
        })

        // window.location.reload();
      },
      onClose: () => {
        this.setState({
          topUpLoading: false
        })
        console.log("window closed");
      },
    });
    handler.openIframe();

  }catch (error) {
    console.log("Error making payment with paystack", error)
    this.setState({
      topUpLoading: false,
      topUpError:"There was an error with getting your payment. Please enter only whole numbers.",
      topUpSuccess: false
    })
  }

  }

  callTopupAPI= async (amount, cashaamFee)=>{

    amount=parseFloat(amount);
    cashaamFee=parseFloat(cashaamFee);
    let topUpAmount = parseFloat(amount-cashaamFee).toFixed(1)
    let {id, slug, storeName}=this.props.storeInfo;
    let cardId=this.state.cardId;
    const queryData = {
      cardId: cardId,
      sellerId: id,
      storeName,
      slug,
      amount: topUpAmount,
      transactionFee: parseFloat(cashaamFee).toFixed(2)
    };
    console.log(queryData);
    const res = await fetch(`${VirtualCardAPI}/top-up`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryData),
    });
    let response = await res?.json();
    console.log("response",response)

    if (res.status === 200) {
      let spendingLimit=parseFloat(this.state.spendingLimit);
      spendingLimit=spendingLimit+(amount-cashaamFee);

      const start = Date.now();
      let paymentHistory=this.state.paymentHistory;
      let paymentObj={
        purchaseAmount: amount-cashaamFee,
        timeStamp: start,
        transactionFee: cashaamFee,
        type: "recharge",
        currency:"usd"
      }
      if(!paymentHistory){
        paymentHistory=[]
      }
      paymentHistory.unshift(paymentObj);

      this.setState({
        spendingLimit: parseFloat(spendingLimit),
        paymentHistory
      })

    } else {
      console.log(" failed to reveal card");
    }

  }

  callCreateCard= async (amount, cashaamFee)=>{

    amount=parseFloat(amount)
    cashaamFee=parseFloat(cashaamFee)
    let {
      id, 
      slug,
      storeName,   
      firstName, 
      lastName, 
      country,
      state,
      city,
      zipcode,
      street
    }=this.props.storeInfo;

    let topUpAmount = parseFloat(amount-cashaamFee).toFixed(1)
    let finalAmount = parseFloat(topUpAmount);

    //Character limit for stripe is 24 chars
    let remainingChars=24-firstName?.length;
    console.log("remainingChars",remainingChars);
    let newLastN=lastName?.substring(0, remainingChars);
    console.log("newLastN",newLastN);

    const queryData = {
      sellerId: id,
      storeName,
      slug,
      amount: finalAmount,
      firstName, 
      lastName:newLastN,
      country,
      state,
      city,
      zipcode,
      street,
      transactionFee: parseFloat(cashaamFee)?.toFixed(2),
      noCategoriesLimit:true
    };
    console.log(queryData);
    const res = await fetch(`${VirtualCardAPI}/create-cardholder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryData),
    });
    

    if (res.status === 200) {
      let response = await res?.json();
      console.log("top-up", response);
      let virtualCards = response.virtualCardsObj;
      let cardHolderId = virtualCards?.cardHolderId;

      let {firstName, lastName, email, phoneNumber}=this.props.storeInfo;
      let fullName =`${firstName} ${lastName}`;
      if(virtualCards?.cards && virtualCards?.cards[0]){
        let card = virtualCards?.cards[0];
        let lastFourDigits = card?.lastFourDigits;
        let spendingLimit=card?.spendingLimit;
        let currency=card?.currency;
        let cardId= card?.cardId;
        let cardHistory =card?.history;
  
        const start = Date.now();
        let paymentHistory=[];
        let paymentObj={
          purchaseAmount: amount-cashaamFee,
          timeStamp: start,
          transactionFee: cashaamFee,
          type: "create",
          currency:"usd"
        }
        paymentHistory.push(paymentObj);

        this.setState({
          virtualCards,
          cardHolderId,
          card,
          lastFourDigits,
          spendingLimit,
          currency,
          cardId,
          cardHistory,
          phoneNumber,
          firstName, lastName, email,fullName,
          startOnboarding: false,
          newCard: true,
          paymentHistory
        });

      }

    } else {
      console.log(" failed to reveal card");
    }

  }

  getMonthlyLimit =(cardHistory, subscriptionActive, subscriptionType, verificationStatus)=>{
    try{

      console.log("getMonthlyLimit for ", cardHistory);

      let length = cardHistory.length;
      

      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
  
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;
  
      const formattedToday = mm + '/'+ dd + '/' + yyyy;
      console.log("date formattedToday",formattedToday);

      let totalMonthTopUp=0;


      for (let i=0; i<length; i++){
        let historyElement = cardHistory[i];
        let transactionType = historyElement?.type;
        let purchaseAmount=historyElement?.purchaseAmount;
        let timeStamp = historyElement?.timeStamp;

        if(!timeStamp){
          timeStamp = historyElement?.timestamp;
        }

        if(timeStamp<1641078780000){
          timeStamp=timeStamp*1000;
        }

        timeStamp=parseInt(timeStamp);
        var date = new Date(timeStamp);
        var transactionDate= (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
    
        const diffInMs   = new Date(formattedToday) - new Date(transactionDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        if(diffInDays<LIMIT_PERIOD && (transactionType?.toLowerCase()=="create" || transactionType?.toLowerCase()=="recharge")){

          console.log("add to monthly topup", [historyElement, diffInDays]);
          totalMonthTopUp+=parseFloat(purchaseAmount);
          

        }

      }

      console.log("totalMonthTopUp", totalMonthTopUp);

      let topUpLimit=0;
      let monthBalance=0;
      let limitedTopup=true;

      if(subscriptionActive){

        if(subscriptionType?.toLowerCase()=="freetrial"){
          topUpLimit=FREE_LIMIT;
          monthBalance=topUpLimit-totalMonthTopUp;

        }else if(verificationStatus && verificationStatus=="verified"){
          topUpLimit=VERIFIED_ACCOUNT_LIMIT;
          monthBalance=VERIFIED_ACCOUNT_LIMIT-totalMonthTopUp;
          limitedTopup=false;
        }else{
          topUpLimit=GLOBAL_LIMIT;
          monthBalance=GLOBAL_LIMIT-totalMonthTopUp;
          limitedTopup=false;
        }


      }

      monthBalance=parseFloat(monthBalance).toFixed(2);
      if(monthBalance<0){
        monthBalance=0;
      }

      this.setState({
        totalMonthTopUp,
        topUpLimit,
        monthBalance,
        limitedTopup
      })
      
      

    }catch (error) {
      console.log("error calculating monthly limit", error)
    }

  }





  componentDidUpdate(prevProps) {
    if (
      this.state.cardHolderId !==
      prevProps.storeInfo?.virtualCards?.cardHolderId
    ) {

      let {firstName, lastName, email, phoneNumber, subscriptionActive, subscriptionType, stripeVerification}=this.props.storeInfo;

      let fullName =`${firstName} ${lastName}`;
      let virtualCards = this.props.storeInfo?.virtualCards;
      let cardHolderId = virtualCards?.cardHolderId;
      if(virtualCards?.cards && virtualCards?.cards[0]){
        let card = virtualCards?.cards[0];
        let lastFourDigits = card?.lastFourDigits;
        let spendingLimit=card?.spendingLimit;
        let currency=card?.currency;
        let cardId= card?.cardId;
        let cardHistory =card?.history;

        let paymentHistory =[];
        let transactionHistory =[];
  
        let totalHistory = cardHistory?.length;
  
        for(let i=0; i<totalHistory; i++){
  
          let transaction=cardHistory[i];
  
          let transactionType=transaction?.type;
          if(transactionType?.toLowerCase()==="spend" || transactionType?.toLowerCase()==="refund"){
            transactionHistory.push(transaction);
          }else if(transactionType?.toLowerCase()==="recharge"|| transactionType?.toLowerCase()==="create"){
            paymentHistory.push(transaction);
          }
  
        }

        let sortedTransactions = transactionHistory.sort(function(x, y){

          let yTimeStamp=y.timeStamp? y.timeStamp : y.timestamp;
          let xTimeStamp=x.timeStamp? x.timeStamp : x.timestamp;

          if(yTimeStamp<1641078780000){
            yTimeStamp=yTimeStamp*1000;
          }
  
          if(xTimeStamp<1641078780000){
            xTimeStamp=xTimeStamp*1000;
          }
          
          return yTimeStamp - xTimeStamp;
        })
  
        let sortedPayments= paymentHistory.sort(function(x, y){
          let yTimeStamp=y.timeStamp? y.timeStamp : y.timestamp;
          let xTimeStamp=x.timeStamp? x.timeStamp : x.timestamp;

          if(yTimeStamp<1641078780000){
            yTimeStamp=yTimeStamp*1000;
          }
  
          if(xTimeStamp<1641078780000){
            xTimeStamp=xTimeStamp*1000;
          }

          return yTimeStamp - xTimeStamp;
        })


        let lastRecharge=sortedPayments[0]?.purchaseAmount;

        let verificationStatus=stripeVerification?.verificationSession?.status;

        this.getMonthlyLimit(cardHistory, subscriptionActive, subscriptionType, verificationStatus);
  
        this.setState({
          virtualCards,
          cardHolderId,
          card,
          lastFourDigits,
          spendingLimit,
          currency,
          cardId,
          cardHistory,
          paymentHistory:sortedPayments,
          transactionHistory: sortedTransactions,
          lastRecharge,
          phoneNumber,
          firstName, lastName, email,fullName,
          startOnboarding: false,
          verificationStatus
        });

      }

    }
  }

  getNairaRate=async()=>{
    
    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${TRANSFERWISE_KEY}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      const res = await fetch("https://api.transferwise.com/v1/rates?source=USD&target=NGN",
        requestOptions
      );
      let result = await res.json();

      console.log("conversion api",result)

      let conversionRate=result[0];

      let nairaRate=parseFloat(conversionRate?.rate)*RATE_MARKUP;
      nairaRate=nairaRate.toFixed(2)

      nairaRate=nairaRate*

      this.setState({
        nairaRate
      })


    }catch(error){
      console.log("getNairaRate error",error)
      let nairaRate=NAIRA_EXCHANGE*RATE_MARKUP;

      nairaRate.toFixed(2)
      this.setState({
        nairaRate
      })
    }
  }


  componentDidMount() {

    this.getNairaRate();
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
      }
    );

    if(this.props.storeInfo){

      

      let {firstName, lastName, email, phoneNumber}=this.props.storeInfo;
      let fullName =`${firstName} ${lastName}`;
      let virtualCards = this.props.storeInfo?.virtualCards;
      let cardHolderId = virtualCards?.cardHolderId;
      let card = virtualCards?.cards[0];
      let lastFourDigits = card?.lastFourDigits;
      let spendingLimit=card?.spendingLimit;
      let currency=card?.currency;
      let cardId= card?.cardId;
      let cardHistory =card?.history;

      let paymentHistory =[];
      let transactionHistory =[];

      let totalHistory = cardHistory?.length;

      for(let i=0; i<totalHistory; i++){

        let transaction=cardHistory[i];

        let transactionType=transaction?.type;
        if(transactionType?.toLowerCase()==="spend" || transactionType?.toLowerCase()==="refund"){
          transactionHistory.push(transaction);
        }else if(transactionType?.toLowerCase()==="recharge"|| transactionType?.toLowerCase()==="create"){
          paymentHistory.push(transaction);
        }

      }

      let sortedTransactions = transactionHistory.sort(function(x, y){

        let yTimeStamp=y.timeStamp? y.timeStamp : y.timestamp;
        let xTimeStamp=x.timeStamp? x.timeStamp : x.timestamp;

        if(yTimeStamp<1641078780000){
          yTimeStamp=yTimeStamp*1000;
        }

        if(xTimeStamp<1641078780000){
          xTimeStamp=xTimeStamp*1000;
        }

        return yTimeStamp - xTimeStamp;
      })

      let sortedPayments= paymentHistory.sort(function(x, y){
        let yTimeStamp=y.timeStamp? y.timeStamp : y.timestamp;
        let xTimeStamp=x.timeStamp? x.timeStamp : x.timestamp;

        if(yTimeStamp<1641078780000){
          yTimeStamp=yTimeStamp*1000;
        }

        if(xTimeStamp<1641078780000){
          xTimeStamp=xTimeStamp*1000;
        }

        return yTimeStamp - xTimeStamp;
      })


      let lastRecharge=sortedPayments[0]?.purchaseAmount;

      this.setState({
        virtualCards,
        cardHolderId,
        card,
        lastFourDigits,
        spendingLimit,
        currency,
        cardId,
        cardHistory,
        paymentHistory:sortedPayments,
        transactionHistory: sortedTransactions,
        lastRecharge,
        phoneNumber,
        firstName, lastName, email, fullName
      });
    }
  }
  render() {
    return (

      <>
      <div className="flexwrapper rewards-wrapper">
        <div className="dashboardcontent">
        {/* {this.props.storeInfo?.country?.toLowerCase()=="nigeria" ||
          (this.state.virtualCards && this.state.limitedTopup)?
          <div className="top-info-banner">
            <div>Card Allowance is ${this.state.topUpLimit}. You have ${this.state.monthBalance} left!</div>
            
          </div>
        :
        <div className="top-info-banner">
            {this.props.storeInfo?.stripeVerification && this.props.storeInfo?.stripeVerification?.verificationSession?.status=="verified" && this.props.storeInfo?.cardApproval?
            
            <div>Your account is verified. Your top up amount has increased</div>
            :
              (this.props.storeInfo?.stripeVerification && this.props.storeInfo?.stripeVerification?.verificationSession?.status!="verified")
              || !this.props.storeInfo?.cardApproval?
                <div>Contact us to complete your identity verification</div>
                :
                <div>Verify to Increase Your Top Up Amount</div>
            }
            
          </div>} */}

          <div className="top-info-banner">
            <div>We are stopping all virtual cards on Fri, September 1st</div>
          </div>
          <div className="dashboardcontenttop">
            {this.props.storeInfo?.accountType=="Personal" || this.props.storeInfo?.subscriptionType=="lite"?
              <div className="dashboardcontenttitle">My Card</div>
              :
              <div className="dashboardcontenttitle">My Card</div>
            }
            <div className="row">

                {/* {this.props.storeInfo?.country?.toLowerCase()=="nigeria" ||
                  (this.props.storeInfo?.stripeVerification
                    && this.props.storeInfo?.stripeVerification?.verificationSession?.status=="verified"
                    && this.props.storeInfo?.cardApproval
                    )?
                  (
                    this.state.virtualCards?
                        (this.state.monthBalance>0?
                          (
                            <a
                              data-w-id="03ad451d-c842-0ca6-8181-f7a11bd91f96"
                              onClick={this.showTopup}
                              className="dashboardcontenttopbutton w-inline-block"
                            >
                              <div>Top-Up</div>
                            </a>
                          ): 
                          (
                            <a
                              data-w-id="03ad451d-c842-0ca6-8181-f7a11bd91f96"
                              href="/seller/dashboard/rewards"
                              className="dashboardcontenttopbutton w-inline-block"
                            >
                              <div>Upgrade</div>
                            </a>
                          )

                        )
                    :
                      // <a
                      //   data-w-id="03ad451d-c842-0ca6-8181-f7a11bd91f96"
                      //   onClick={this.displayOnboarding}
                      //   className="dashboardcontenttopbutton w-inline-block"
                      // >
                      //   <div>Create Your Card</div>
                      // </a>
                      null
                  )
                  :
                  null
                } */}

              
                {(this.props.storeInfo?.stripeVerification
                    && this.props.storeInfo?.stripeVerification?.verificationSession?.status=="verified"
                    && this.props.storeInfo?.cardApproval)
                    || this.props.storeInfo?.country?.toLowerCase()=="nigeria"?

                    null
                    :
                    (!this.props.storeInfo?.stripeVerification?
                        <a
                              data-w-id="03ad451d-c842-0ca6-8181-f7a11bd91f96"
                              onClick={this.showVerification}
                              className="dashboardcontenttopbutton w-inline-block"
                              style={{backgroundColor: "#e0f4eb",
                                borderColor: "#007748d4",
                                color: "#007748d4"}}
                            >
                                  <div>Get Verified</div>
                        </a>
                      :
                        <a
                            target="_blank" href="https://support.cashaam.com/"
                            data-w-id="03ad451d-c842-0ca6-8181-f7a11bd91f96"
                            className="dashboardcontenttopbutton w-inline-block"
                            style={{backgroundColor: "#7455c1",
                                borderColor: "#7455c1",
                                color: "white"
                              }}
                          >
                                <div>Contact Us</div>
                        </a>
                    )

                }
                

              {!this.props.storeInfo?.subscriptionActive || this.props.storeInfo?.subscriptionType=="lite"?
                <a
                  href="https://faqs.cashaam.com"
                  target="_blank"
                  className="dashboardcontenttopbutton w-inline-block"
                >
                  <div>Get Help</div>
                </a>
                :
                <a
                  onClick={()=>this.displayAdsHelp()}
                  className="dashboardcontenttopbutton w-inline-block"
                >
                  <div>Ads Help</div>
                </a>
              }
            </div>
          </div>

          <Snapshot 
            customerCount={this.props.customerCount}
            addedToCart={this.props.addedToCart}
            spendingLimit={this.state.spendingLimit? this.state.spendingLimit: 0}
          />

          <div className="dashboardgrid">

            {this.state.cardRevealed && this.state.detailedCard? 

              <DetailedCard
              firstName={this.props.storeInfo?.firstName}
              lastName={this.props.storeInfo?.lastName}
              detailedCard={this.state.detailedCard}
              cardRevealed={this.state.cardRevealed}
              hideCard={this.hideCard}
              storeInfo={this.props.storeInfo}
              />
                :
              <VirtualCard
                firstName={this.props.storeInfo?.firstName}
                lastName={this.props.storeInfo?.lastName}
                lastFourDigits={this.state.lastFourDigits}
                detailedCard={this.state.detailedCard}
                cardRevealed={this.state.cardRevealed}
                displayCard={this.displayCard}
                virtualCards={this.state.virtualCards}
                storeInfo={this.props.storeInfo}
                
              />
              
            }

            <CardInfo 
              cardHistory={this.state.cardHistory}
              spendingLimit={this.state.spendingLimit?this.state.spendingLimit:0}
              showTopup={this.showTopup}
              virtualCards={this.state.virtualCards}
              lastRecharge={this.state.lastRecharge}
              totalMonthTopUp={this.state.totalMonthTopUp}
              topUpLimit={this.state.topUpLimit}
              monthBalance={this.state.monthBalance}
              limitedTopup={this.state.limitedTopup}
              stripeVerification={this.props.storeInfo?.stripeVerification
                && this.props.storeInfo?.stripeVerification?.verificationSession?.status=="verified"}
              country={this.props.storeInfo?.country}
              cardApproval={this.props.storeInfo?.cardApproval}
            />


            


            {this.state.cardHistory && this.state.paymentHistory && this.state.paymentHistory?.length>0?
              <CardTransactions 
                cardHistory={this.state.paymentHistory}
                fullName={this.state.fullName}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                historyTpe="Payments"
              />
              :null
            }

            {this.state.cardHistory && this.state.transactionHistory && this.state.transactionHistory?.length>0?
              <CardTransactions 
                cardHistory={this.state.transactionHistory}
                fullName={this.state.fullName}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                historyTpe="Transactions"
              />
              :null
            }

            {this.props.storeStats && 
              !(this.props.storeInfo?.accountType=="Personal" || this.props.storeInfo?.subscriptionType=="lite")?
              <StoreSnapshot 
                storeStats={this.props.storeStats}
                spendingLimit={this.state.spendingLimit?this.state.spendingLimit:0}
                storeAnalytics={this.props.storeAnalytics}
                daysFilter={this.props.daysFilter}
                slug={this.props.storeInfo?.slug}
              />
              :null
            }
          </div>
        </div>
      </div>


      {this.state.showCardOTP?
      
        <div style={{display: 'flex'}} className="sendcodepopupmodal">
          <div className="sendcodepopup">
            <div onClick={() => this.displayCard(false)}
              data-w-id="26bc9849-f5e3-a88b-8cb5-308cad688054" className="closepopup" />
            <div className="popuptitle">We just sent a code to your phone number to reveal wallet details</div>
            <div className={`sendcodebox ${this.state.errorVerificationCode?"input-error":""}`}>
              <input onChange={this.handleChange} 
                  className="otp-box"
                  placeholder="Enter OTP" name="cardOtp" value={this.state.cardOtp} 
              />
            </div>

            {this.state.errorVerificationCode?
              <div className="error-message">{this.state.errorVerificationCode}</div>
              : null
            }

            {this.state.loadingReveal?
              <div style={{width: "120px"}} className="popupbutton largepopupbutton w-inline-block">
                <div
                  style={{ 
                    borderTopColor: "#6740aa", margin:"0 auto", width: "15px", height: "15px", 
                    border: "15px solid #f3f3f3", borderTop: "15px solid #6740aa"}}
                  className="loader"
                ></div>
              </div>
          :
              <a onClick={()=> this.submitConfResult()} className="popupbutton largepopupbutton w-inline-block">
                <div>Reveal Card Info</div>
              </a>
            }
            <div className="sendcodetext">Verification Code sent to {this.state.phoneNumber}</div>
            <a onClick={()=> this.verifyPhone()} className="popuplink">Did not receive? Send again</a>
          </div>
        </div>

        :null}

        {!this.state.startOnboarding || !this.props.storeInfo?.virtualCards?
        null
        :
        <CardOnboarding 
         createAndTopup={this.createAndTopup}
         displayAgreement={this.displayAgreement}
         closeOnboarding={this.closeOnboarding}
         startOnboarding={this.state.startOnboarding}
         storeInfo={this.props.storeInfo}
        />
        }

        {this.state.showUserAgreeMent?
          <UserAgreement 
            createAndTopup={this.createAndTopup}
            hideAgreement={this.hideAgreement}
          />
          :null
        }

        {this.state.showAdsHelp?
          <AdsHelp
            hideAdsHelp={this.hideAdsHelp}
          />
        :null}
        

        {/* 
            OLD
        {this.state.topupModal?
          <TopupAds 
           hideTopup={this.hideTopup}
           changeAmount={this.changeAmount}
           topupAmount={this.state.topupAmount}
           makePayment={this.makePayment}
          />
          :null
        } */}

        {/*Card Disabled*/}
        {/* {this.state.topupModal && this.props.storeInfo?.virtualCards?

          (this.props.storeInfo?.country?.toLowerCase()=="nigeria"?
            <RechargeCard 
              hideTopup={this.hideTopup}
              changeAmount={this.changeAmount}
              topupAmount={this.state.topupAmount}
              makePayment={this.makePayment}
              goBack={this.goBack}
              topUpLoading={this.state.topUpLoading}
              topUpError={this.state.topUpError}
              topUpSuccess={this.state.topUpSuccess}
              totalAmount={this.state.totalAmount}
              cashaamFee={this.state.cashaamFee}
              virtualCards={this.state.virtualCards}
              upgradeSuggester={this.state.upgradeSuggester}
              roundOffAmount={this.state.roundOffAmount}
              dailyLimitReached={this.state.dailyLimitReached}
              verificationSuggester={this.state.verificationSuggester}
              showVerification={this.showVerification}

              nairaRate={this.state.nairaRate}
              paymentFailed={this.state.paymentFailed}
              subscriptionActive={this.state.subscriptionActive} 
              subscriptionType={this.state.subscriptionType}
            />
          :
          
          <RechargeUS 
              test={"testUS"}
              hideTopup={this.hideTopup}
              changeAmount={this.changeAmount}
              topupAmount={this.state.topupAmount}
              makePayment={this.makePayment}
              goBack={this.goBack}
              topUpLoading={this.state.topUpLoading}
              topUpError={this.state.topUpError}
              topUpSuccess={this.state.topUpSuccess}
              totalAmount={this.state.totalAmount}
              cashaamFee={this.state.cashaamFee}
              virtualCards={this.state.virtualCards}
              upgradeSuggester={this.state.upgradeSuggester}
              roundOffAmount={this.state.roundOffAmount}
              dailyLimitReached={this.state.dailyLimitReached}
              email={this.state.email}
              sellerId={this.props.storeInfo?.id}
              slug={this.props.storeInfo?.slug}
              verificationSuggester={this.state.verificationSuggester}
              showVerification={this.showVerification}
              stripePay={this.stripePay}
              storeInfo={this.props.storeInfo}
            />
          )
        :null} */}


        
        {this.state.displayVerification?
          <Verification 
            hideVerification={this.hideVerification}
            step1={this.state.step1}
            step2={this.state.step2}
            step3={this.state.step3}
            step4={this.state.step4}
            verificationLoading={this.state.verificationLoading}
            verificationSuccess={this.state.verificationSuccess}
            handleSteps={this.handleSteps}
            birthday={this.state.birthday}
            handleChange={this.handleChange}
            storeInfo={this.props.storeInfo}
            dobError={this.state.dobError}
          />
        :null}

        <div id="recaptcha-container"></div>
      </>


    );
  }
}

export default MarketingDashboard;
