import React from "react";
import firebase from "../../firebaseConfig";

const getCustomerInfo = (customerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const customerRef = db.collection("buyersAccounts").doc(customerId);
      const customerInfo = await customerRef.get();

      console.log("test exists", customerInfo.exists);

      if (customerInfo.exists) {
        console.log("custData", customerInfo.data());
        resolve(customerInfo.data());
      } else {
        resolve(null);
      }
    } catch (error) {
      console.log("error getting customer info", error);
      resolve(null);
      // reject(error);
    }
  });
};
export default getCustomerInfo;
