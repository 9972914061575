import React, { Component } from "react";
import firebase from "../../../../firebaseConfig/index";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import AddBankForm from "../../../UI/AddBankForm";
import UpdateBankSuccess from "../../../common/UpdateBankSuccess";
import BvnInfo from "../../../UI/BvnInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getSellersInfo from "../../../helpers/getSellersInfo";
import Loading from "../../../common/Loading";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";
import { STRIPE_CLIENT_ID } from "../../../../secrets";
import AddUSBankForm from "../../../UI/AddUSBankForm";
import createStripeUser from "../../../helpers/createStripeUser";
const stripeClientId = STRIPE_CLIENT_ID;

import getBasicInfo from "../../../helpers/getBasicInfo";
import Loader from "react-loader-spinner";
import VerifyButton from "../stripe/VerifyButton";
import {loadStripe} from '@stripe/stripe-js';
import {STRIPE_KEY,stripeCheckout,cardApplicationFee} from "../../../../secrets"
const stripePromise = loadStripe(STRIPE_KEY);

class AddUSBankInfo extends Component {
  state = {
    accountHolderName: "",
    accountHolderType: "individual",
    routingNumber: "",
    accountNumber: "",
    sellerName: "",
    sellerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    businessName: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    street: "",
    stripeLink: "",
    submitFailed: false,
    loading: false,
    submitSuccess: false,
  };

  displayError = () => {
    this.setState({
      submitFailed: true,
    });
    setTimeout(() => this.hideError(), 5000);
  };

  hideError = () => {
    this.setState({ submitFailed: false });
  };

  checkForSellerBank = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = firebase.firestore();
        const sellerUid = firebase.auth().currentUser.uid;
        const sellerStoreData = await db
          .collection("sellersAccounts")
          .doc(sellerUid)
          .collection("stripe")
          .get();
        // if the seller already has a Bank in collection then direct them back to dashboard
        if (sellerStoreData.empty === false) {
          await saveRegisterProgress(sellerUid, 4);
          resolve(this.setState({ submitSuccess: true }));
          this.props.history.push("/seller/dashboard/addProduct");
        } else {
          resolve("no bank set up");
        }
      } catch (error) {
        console.log("error checking for seller bank", error);
      }
    });
  };

  selectBank = (value) => {
    this.setState({ bankName: value });
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  getSeller = async (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        // const sellerInfo = await getSellersInfo(id);

        const sellerInfo = await getBasicInfo(id);

        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          storeName,
          city,
          state,
          country,
          slug,
          dob
        } = sellerInfo;
        let businessURL = `https://cashaam.com/${slug}`;
        let sellerId = id;
        let stripeState = { sellerId: sellerId, origin: window.location.href };
        stripeState = JSON.stringify(stripeState);
        console.log("busi url", businessURL);
        resolve(
          this.setState({
            sellerId,
            firstName,
            lastName,
            email,
            phoneNumber,
            storeName,
            city,
            state,
            country,
            dob,
            sellerInfo,
            stripeLink: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&state=${stripeState}&stripe_user[email]=${email}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&stripe_user[phone_number]=${phoneNumber}&stripe_user[business_name]=${storeName}&stripe_user[city]=${city}&stripe_user[state]=${state}&stripe_user[country]=${country}&stripe_user[url]=${businessURL}`,
          })
        );
      } catch (error) {
        console.log("error getting seller info", error);
        reject(error);
      }
    });
  };

  setUpLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  onGoToBankForm = () => {
    this.setState({ displayBvnInfo: false });
  };

  goBackToStoreSetup = () => {
    //Go back to the Store Setup Form (Previous page)
    this.props.history.push({
      pathname: "/seller/dashboard/setup",
      state: { goToStoreSetup: true }
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  startLoading=(loading)=>{
    this.setState({
      verificationLoading:loading
    });

  }

  verificationFeedback=async(feedback, stripeMsg)=>{

    try{
    this.setState({
      verificationSuccess: false,
      verificationError:false,
      verificationLoading: false,
      errorMsg:""
    })
    if(feedback=="success"){
      this.setState({verificationSuccess: true});

    }else if(feedback=="error"){
      this.setState({
        verificationError: true, 
        errorMsg: "There has been an error with your verificaction. Please try again."
      });
    }

  }catch(error){
    console.log("verificationFeedback error",error);
  }

  }

  goToDashboard=async()=>{
    let sellerId=this.state.sellerInfo?.id;
    await saveRegisterProgress(sellerId, 4);
    this.props.history.push("/seller/dashboard/rewards")
  }

  submitBankForm = async (e) => {
    e.preventDefault();
    this.displayLoading();
    try {
      let { sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber } = this.state;

      if (isNaN(accountNumber)) {
        this.hideLoading();
        return alert("Please only enter numbers for the Account Number");
      }

      if (isNaN(routingNumber)) {
        this.hideLoading();
        return alert("Please only enter numbers for the Routing Number");
      }
      let result = await createStripeUser(sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber);
      await saveRegisterProgress(sellerId, 4);
      this.hideLoading();
      this.props.history.push("/seller/dashboard/addProduct")
    } catch (error) {
      console.log("error submiting form", error);
      // this.displayError()

      //if it fails, save the info into firebase so we can manually add it later (temporary solution so the user does not get stuck on this page)
      this.saveFailedBankAttempt();
    }
  }

  saveFailedBankAttempt = async () => {
    console.log("saveFailedBankAttempt");
    const db = firebase.firestore();
    let { sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber } = this.state;

    await db.collection("failedStripeAccounts").doc(sellerId).set({ sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber, date: Date.now() })
    await db.collection("sellersAccounts").doc(sellerId).collection("stripe").doc(sellerId).set({
      stripe_user_id: "failed"
    })
    await saveRegisterProgress(sellerId, 4);
    this.hideLoading();
    this.props.history.push("/seller/dashboard/addProduct")

  }

  componentDidMount() {
    //fetch all bank names from firebase
    Loading(true, "Please wait...");
    const db = firebase.firestore();
    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          await this.checkForSellerBank();
          await this.getSeller(user.uid);
          let step = await getRegistrationProgress(user.uid);
          step = parseFloat(step);

          if (step === 100) {
            this.props.history.push("/seller/dashboard");
          }
          if (step <= 2) {
            //user did not complete store info form
            //SEND BACK to store setup
            this.props.history.push("/seller/dashboard/setup");
          }

          if (step > 3) {
            //user already setup their bank
            //NEXT add products
            this.props.history.push("/seller/dashboard/addProduct");
          }

          this.setState({
            sellerName: user.displayName,
          });
          Loading(false);
        } catch (error) {
          Loading(false);
          console.log("error loading seller info", error);
        }
      } else {
        Loading(false);
        this.props.history.push("/");
      }
    });
  }



  render() {
    return (
      <>
        <div className="section-onboarding verification-page">
          <div className="onboarding-steps verification-container">
          
          {!this.state.sellerInfo?.subscriptionActive?
              <>
                <div className="block-title-1">
                <h4 style={{textAlign: 'center', width:"100%"}} className="h4">Card Application Fee</h4>
                </div>
                <div className="microcopy-signup" style={{ marginBottom: "10px",textAlign: 'center'}}>
                Pay $3 Card Application Fee to create Unlimited Virtual Dollar Card to Make Payments Anywhere in the World.<br />
                </div>

                <form
                  action={stripeCheckout}
                  method="POST"
                >
                  <input
                    style={{ display: "none" }}
                    type="text"
                    id="priceId"
                    name="priceId"
                    value={cardApplicationFee}
                    readOnly
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    id="email"
                    name="email"
                    value={this.state.sellerInfo?.email}
                    readOnly
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    id="slug"
                    name="slug"
                    value={this.state.sellerInfo?.slug}
                    readOnly
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    id="sellerId"
                    name="sellerId"
                    value={this.state.sellerInfo?.id}
                    readOnly
                  />
                  <input
                    style={{ display: "none" }}
                    type="text"
                    id="eventType"
                    name="eventType"
                    value={"CARD_APPLICATION_FEE"}
                    readOnly
                  />
                  <button
                    type="submit"
                    className="popupbutton w-inline-block"
                    style={{margin:"0 auto", width:"100%", padding: "9px 23px", fontSize:"16px"}} 
                    data-id="monthly"
                  >
                    Get Started
                  </button>
                </form>
              </>
            :
            <>
            <div className="block-title-1">
              <h4 style={{textAlign: 'center', width:"100%"}} className="h4">Account Verification</h4>
            </div>
            <div className="microcopy-signup" style={{ marginBottom: "10px",textAlign: 'center'}}>
            Verify your account in 2 mins to remove any restrictions. <br />
            </div>


            {this.state.sellerInfo?.stripeVerification || this.state.sellerInfo?.country?.toLowerCase()=="nigeria"?
              null
              :
              (this.state.verificationLoading? (
                  <div className="loading-content" style={{ width: "300px" }}>
                    <Loader
                      type="TailSpin"
                      color="#7455c1"
                      height={100}
                      width={100}
                    />
                    <div>Loading...</div>
                  </div>
                ) : this.state.verificationSuccess ? (
                  <div className="airtime-resp-container">
                    
                    <div className="success-payment"></div>
                    <div style={{ textAlign: 'center'}} className="airtime-resp">Your verification has been submitted.</div>
                  </div>
                ) : this.state.verificationError?
                (
                  <div className="airtime-resp-container">
                      
                      <div className="error-payment"></div>
                  <div className="airtime-resp" style={{ textAlign: 'center'}}>{this.state.errorMsg}</div>
                </div>):
                (
                  <div>
                    <VerifyButton 
                        stripePromise={stripePromise}
                        birthday={this.state.dob}
                        sellerId={this.state.sellerInfo?.id}
                        storeInfo={this.state.sellerInfo}
                        verificationFeedback={this.verificationFeedback}
                        startLoading={this.startLoading}
                    />
                  </div>
                )
              )
            }
            </>
            }

            <div style={{display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    position:"relative"
                  }}
            >
              {this.state.verificationSuccess?
                null
                :
                <div
                  onClick={() => this.goBackToStoreSetup()}
                  style={{ cursor: "pointer" }}
                  className="microcopy-signup go-back"
                >
                  Go Back
                </div>
              }

              {this.state.verificationSuccess?
                  <div
                    onClick={() => this.goToDashboard()}
                    style={{ cursor: "pointer",backgroundColor:"#42ad49", margin:"10px auto", position: "initial" }}
                    className="microcopy-signup skip-now"
                  >
                    Continue
                  </div>
                :
                  <div
                  onClick={() => this.goToDashboard()}
                    style={{ cursor: "pointer" }}
                    className="microcopy-signup skip-now"
                  >
                    Skip for Now
                  </div>
              }

            </div>


          </div>
        </div>

        <Link to="/" className="logo-black w-inline-block">
          <img
            src={logo}
            // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
            sizes="(max-width: 767px) 80px, 100px"
            alt
            className="cashaam-logo-dark"
          />
          <img
            src={logoWhite}
            // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
            sizes="100vw"
            alt
            className="cashaam-logo-white"
          />
        </Link>
        {this.state.submitSuccess === true ? (
          <UpdateBankSuccess />
        ) : null}
      </>
      
    );
  }
}

export default AddUSBankInfo;
