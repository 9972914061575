import firebase from 'firebase'; 
 var firebaseConfig = {'apiKey': 'AIzaSyDZgQN1qtFXWZCtQnJvMR0ScQpLFjxu_Ic', 
'authDomain': 'cashaam-dev.firebaseapp.com', 
'databaseURL': 'https://cashaam-dev.firebaseio.com', 
'projectId': 'cashaam-dev', 
'storageBucket': 'cashaam-dev.appspot.com', 
'messagingSenderId': '648498891464', 
'appId': '1:648498891464:web:da226fef3ff35b8d0a33fd', 
'measurementId': 'G-5Q463RDX19'} 
 firebase.initializeApp(firebaseConfig) 
 firebase.analytics() 
 firebase.performance(); 
export default firebase;
