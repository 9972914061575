import firebase from "../../firebaseConfig";

const getUnreadChat = async (sellerId) => {

    try{
        const db = firebase.firestore();
        const messages=await db.collection("messages")
        .where("sellerId", "==", sellerId)
        .get();

        const chatList = messages.docs.map((chat) => chat.data());
        console.log("chatList",chatList);

        let notification=0;
        chatList.map((chat)=>{
            if(chat?.sellerUnread){
                notification+=parseInt(chat?.sellerUnread);
            }
            

        })

        return notification;
    }catch(err){
        console.log("Error finding unread msgs",err);
        return 0;
        
    }
}

export default getUnreadChat;