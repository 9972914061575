import React from 'react';
import {stripeCheckout,NAIRA_EXCHANGE} from "../../../../secrets";

const FREE_LIMIT=100;
const LITE_LIMIT=25;
const MONTHLY_LIMIT=100;
const QUARTERLY_LIMIT=150;
const ANNUAL_LIMIT=200;

function SubPlan(props) {
    return (
        <div
        className={`pricing-redesign__pricing-card-body ${props.activeTab==props.period?"tab-active":"tab-inactive"}`}
        data-pricing-card="professional"
      >
        {props.period=="Quarterly"?
        <span className="pricing-redesign__highlight">Most Popular</span>
        :null}
        <div 
        className={`pricing-redesign__pricing-card pricing-redesign__pricing-card--narrow ${props.period!="Quarterly"?"add-margin":"primary"}`}
        style={{position:"relative"}}
        >
          <div className="pricing-redesign__content-container">
            <div className="main-content">
                {props.period=="Monthly"?
                    (props.accountType=="Personal" && props.subscriptionType!="lite"?
                      <h3 className="title">Business</h3>
                      :<h3 className="title">Basic</h3>
                    )

                    :
                    <h3 className="title">{props.period}</h3>
                }
              
              <p className="description description--narrow">
                {props.description}
              </p>
            </div>
            <div className="pricing-content pricing-content__monthly">
              <span aria-hidden="true">&nbsp;</span>
              <p
                aria-hidden="true"
                className="pricing-redesign__price-container"
              >
                <sup className="currency">
                    {props.currency=="₦"?
                    "NGN": 
                    "USD"
                    }
                </sup>
                <sup className="currency-symbol">{props.currency}</sup>
                
                {props.period=="Monthly"?
                  <div>
                    <span style={{
                      textDecoration: "line-through", 
                      fontSize:"23px !important",
                       color:"red",
                        position: "absolute",
                    top: "165px"}} 
                    className="price heading--1 discounted">₦6,078</span>
                    <span className="price heading--1">{props.price.toLocaleString('en-US')}</span>
                  </div>
                :
                <span className="price heading--1">{props.price.toLocaleString('en-US')}</span>
                }
                <sub className="per-month">/{props.shortForm}</sub>
              </p>
              <span className="visuallyhidden">$10 USD per month</span>
            </div>

          </div>
          <hr className="divider" />
          <div className="pricing-redesign__info-section pricing-redesign__info-section--narrow">
            <div className="pricing-redesign__cc-rates pricing-redesign__cc-rates--narrow">
              <div className="static-cc-rates">
                <strong>Exchange Rates and Fees</strong>
                <div className="pricing-redesign__feature-block">
                    {props.paymentMethod == "paystack"?
                  <span className="pricing-redesign__feature-item rate-fees">
                    <img 
                        className="sub-icons"
                        src="https://res.cloudinary.com/cashaam/image/upload/v1666055116/icons/conversion_dnzdl7.webp" 
                    />
                        <span className="span-text">₦{NAIRA_EXCHANGE} to $1</span>
                  </span>
                  :null}
                  {/* <span className="pricing-redesign__feature-item rate-fees">
                  <img 
                    className="sub-icons"
                    src="https://res.cloudinary.com/cashaam/image/upload/v1666055118/icons/commission_fz7qnc.webp" 
                  />
                    <span className="span-text">3.5% per card topup</span>
                  </span> */}
                  
                </div>
              </div>
              
            </div>
            <div className="pricing-redesign__included-features">
              <strong>What’s included on {props.period=="Monthly"?(props.accountType=="Personal"?
                      "Business"
                      :"Basic"
                      ):props.period}</strong>
              <div className="feature-wrap">

                {props.period=="Monthly"?
                    <ul className="static-feature-block pricing-redesign__feature-block">
                    {/* {props.paymentMethod == "paystack"?
                        <li className="pricing-redesign__feature-item">

                            <span className="span-text">Virtual Card for Payments</span>
                        </li>
                    :null} */}
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Professional Websites</span>
                    </li>
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Accept Payment Globally</span>
                    </li>

                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Sell your products intentionally</span>
                    </li>
                    {/* <li className="pricing-redesign__feature-item">
                      <span className="span-text">Unlimited Virtual Card Top UP</span>
                    </li> */}
                    
                    </ul>
                :null}

                {props.period=="Quarterly"?
                    <ul className="static-feature-block pricing-redesign__feature-block">
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Everything in Basic Plan</span>
                    </li>
                    {props.paymentMethod == "paystack"?
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Expert Support for Ads Conversion</span>
                    </li>
                    :null}
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Facebook Pixels Integration</span>
                    </li>
                    {/* <li className="pricing-redesign__feature-item">
                      <span className="span-text">Unlimited Virtual Card Top UP</span>
                    </li> */}

                    </ul>
                :null}

                {props.period=="Yearly"?
                    <ul className="static-feature-block pricing-redesign__feature-block">
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Everything in Basic Plan</span>
                    </li>
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">List your products on Amazon</span>
                    </li>
                    <li className="pricing-redesign__feature-item">

                        <span className="span-text">Facebook Pixels Integration</span>
                    </li>
                    {/* <li className="pricing-redesign__feature-item">

                        <span className="span-text">Get Two Months for Free (25% OFF)</span>
                    </li> */}
                    
                    {/* <li className="pricing-redesign__feature-item">
                      <span className="span-text">Unlimited Virtual Card Top UP</span>
                    </li> */}

                    </ul>
                :null}

                {/* {props.freeTrial?
                  <div className="freetrail_info">Our free trial has a $100 limit. Upgrade for more.</div>
                :null} */}
              </div>

              {props.period=="Quarterly"?
                <div style={{bottom:"10px"}} className="billing-break">
                    Billed every 3 months
                </div>
              :null}

              {props.period=="Yearly"?
                <div style={{bottom:"10px"}} className="billing-break">
                    Billed every 12 months

                </div>
              :null
              }

              {props.period=="Monthly"?
                <div style={{bottom:"10px"}} className="billing-break">
                    Billed every month
                </div>
                :null
              }

            </div>
            

            {props.paymentMethod == "paystack" ? (

                props.freeTrial?
                <div style={{marginTop:"10px"}}>
                  <div style={{marginBottom:"10px"}} className="freetrail_info">Get started with our free trial</div>
                  <div onClick={()=>props.startFreeTrial(props.priceId, props.period)}>
                      <button
                          type="submit"
                          className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button`}
                          data-id="monthly"
                      >
                          TRY ONE MONTH FOR FREE
                      </button>
                  </div>

                  <div style={{marginTop:"15px"}} onClick={()=>props.paystackSubscription(props.price, props.priceId, props.period)}>
                      <button
                          type="submit"
                          className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button`}
                          data-id="monthly"
                          style={{backgroundColor:"black", color:"white"}}
                      >
                          PICK THIS PLAN
                      </button>
                  </div>

                </div>

                :
                
                <div onClick={()=>props.paystackSubscription(props.price, props.priceId, props.period)}>
                    <button
                        type="submit"
                        className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button ${props.freeTrial?"":"sub-reg"}`}
                        data-id="monthly"
                    >
                        Get Started
                    </button>
                </div>

          ) : (
            <form
              action={stripeCheckout}
              method="POST"
            >
              <input
                style={{ display: "none" }}
                type="text"
                id="priceId"
                name="priceId"
                value={props.priceId}
                readOnly
              />
              <input
                style={{ display: "none" }}
                type="text"
                id="email"
                name="email"
                value={props.email}
                readOnly
              />
              <button
                type="submit"
                className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button`}
                data-id="monthly"
              >
                Get Started
              </button>
            </form>
          )}


          </div>
        </div>

      </div>
    );
}

export default SubPlan;