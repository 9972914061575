const priceFormater = (number, currency) => {
  try {
    let options = {};
    let numberFormat;
    let price;
    if (currency === "N" || currency === "NGN") {
      options = { style: "currency", currency: "USD" };
      numberFormat = new Intl.NumberFormat("en-US", options);
      price = numberFormat.format(number);
      price = price.substr(1);
      return `₦${price}`;
    } else {
      options = { style: "currency", currency: "USD" };
      numberFormat = new Intl.NumberFormat("en-US", options);
      price = numberFormat.format(number);
      return price;
    }
  } catch (error) {
    console.log("error formating price", error);
    return error;
  }
};

export default priceFormater;
