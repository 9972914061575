import React, { Component } from "react";
import Transactions from "./Transactions";
import GrowthInfo from "./GrowthInfo";
import OrderSearch from "./OrderSearch";
import OrderPagination from "./OrderPagination";
import OrderTopBar from "./OrderTopBar";
import PendingOrders from "./PendingOrders"

class OrderList extends Component {
  state = {
    showAll: true,
    showNew: false,
    showDispatched: false,
    showDelivered: false,
    showPending: false,
  };

  displayAll = ()=>{
      this.setState({
        showAll: true,
        showNew: false,
        showDispatched: false,
        showDelivered: false,
        showPending: false,
      })
  }

  displayDispatched = ()=>{
      this.setState({
        showAll: false,
        showNew: false,
        showDispatched: true,
        showDelivered: false,
        showPending: false,
      })
  }

  displayDelivered = ()=>{
      this.setState({
        showAll: false,
        showNew: false,
        showDispatched: false,
        showDelivered: true,
        showPending: false,
    })
   }

   displayPending = ()=>{
    this.setState({
      showAll: false,
      showNew: false,
      showDispatched: false,
      showDelivered: false,
      showPending: true,
  })
 }

  displayNew = ()=>{
    this.setState({
      showAll: false,
      showNew: true,
      showDispatched: false,
      showDelivered: false,
      showPending: false,
    })
  }
  render() {
    return (
      <div style={{ width: "90%", fontSize: "14px"}} className="contentwrapper">
        {/* <div className="topbar">
          <div className="topbarleft">
            <div className="topbaravatar">
              <div>MC</div>
            </div>
            <a href="#" className="topbarsettings w-inline-block" />
          </div>
          <div className="topbarright">
            <a href="#" className="topbartext w-inline-block">
              <div>Help</div>
            </a>
            <a href="#" className="topbartext w-inline-block">
              <div>FAQ</div>
            </a>
            <a href="#" className="topbarnotification w-inline-blocknotification-parent">
              {this.props.notification && this.props.notification>0?
              <div className="notification-number">{this.props.notification}</div>: null
              }

            </a>
            <a onClick={() => this.props.logoutHandler()} className="topbarexit w-inline-block" />
          </div>
        </div> */}
        <OrderTopBar 
          sellerCountry={this.props.sellerCountry}
          slug={this.props.slug}
          notification={this.props.notification}
        />
        <div
          data-current="Tab 1"
          data-easing="ease"
          data-duration-in={300}
          data-duration-out={100}
          className="w-tabs"
        >
          <div className="tabsmenu w-tab-menu">
            <a
              data-w-tab="Tab 1"
              className={`tabitem w-inline-block w-tab-link ${this.state.showAll === true ?"w--current":""}`}
              onClick={() => this.displayAll()}
            >
              <div>All</div>
            </a>
            <a data-w-tab="Tab 2"
                className={`tabitem w-inline-block w-tab-link ${this.state.showNew === true ?"w--current":""}`}
                onClick={() => this.displayNew()}
                >
              <div>New</div>
            </a>
            <a data-w-tab="Tab 3" 
                className={`tabitem w-inline-block w-tab-link ${this.state.showDispatched === true ?"w--current":""}`}
                onClick={() => this.displayDispatched()} >
              <div>Dispatched</div>
            </a>
            <a data-w-tab="Tab 4" 
                className={`tabitem w-inline-block w-tab-link ${this.state.showDelivered === true ?"w--current":""}`}
                onClick={() => this.displayDelivered()}>
              <div>Delivered</div>
            </a>
            <a data-w-tab="Tab 5" 
                className={`tabitem w-inline-block w-tab-link ${this.state.showPending === true ?"w--current":""}`}
                onClick={() => this.displayPending()}>
              <div className="long-tab">Abandoned Cart</div>
            </a>
          </div>
          <div className="w-tab-content">
            <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
              <div className="tabcontainer">
                {/* <OrderSearch /> */}

                {this.state.showAll === true ? (
                  <Transactions
                    transactionInfo={this.props.allTransactions}
                    showTransactionDetails={this.props.showTransactionDetails}
                    getCorrectShippingType={this.props.getCorrectShippingType}
                  />
                ) : null}

                {this.state.showNew === true? (
                  <Transactions
                    transactionInfo={this.props.completeTransactions}
                    showTransactionDetails={this.props.showTransactionDetails}
                    getCorrectShippingType={this.props.getCorrectShippingType}
                  />
                ) : null}
                {this.state.showDispatched === true ? (
                  <Transactions
                  transactionInfo={this.props.dispatchedTransactions}
                  showTransactionDetails={this.props.showTransactionDetails}
                  getCorrectShippingType={this.props.getCorrectShippingType}
                  />
                ) : null}

                {this.state.showDelivered === true ? (
                  <Transactions
                  transactionInfo={this.props.fulfilledTransactions}
                  showTransactionDetails={this.props.showTransactionDetails}
                  getCorrectShippingType={this.props.getCorrectShippingType}
                  />
                ) : null}

                {this.state.showPending===true ?

                <PendingOrders
                  pendingOrders={this.props.pendingOrders}
                 />: null
              }
                

                {/* <OrderPagination /> */}
              </div>
            </div>
            <div data-w-tab="Tab 2" className="w-tab-pane" />
            <div data-w-tab="Tab 3" className="w-tab-pane" />
            <div data-w-tab="Tab 4" className="w-tab-pane" />
            <div data-w-tab="Tab 5" className="w-tab-pane" />
          </div>
        </div>
        <GrowthInfo />
      </div>
    );
  }
}

export default OrderList;
