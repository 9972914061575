import React from "react";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";
import Loader from "react-loader-spinner";
import VerifyButton from "../stripe/VerifyButton";
import {loadStripe} from '@stripe/stripe-js';
import {STRIPE_KEY} from "../../../../secrets";
const stripePromise = loadStripe(STRIPE_KEY);




class Verification extends React.Component {

  state={
    verificationSuccess: false,
    verificationError:false,
    errorMsg:"",
    verificationLoading: false
  }

  startLoading=(loading)=>{
    this.setState({
      verificationLoading:loading
    });

  }

  verificationFeedback=(feedback, stripeMsg)=>{

    this.setState({
      verificationSuccess: false,
      verificationError:false,
      verificationLoading: false,
      errorMsg:""
    })
    if(feedback=="success"){
      this.setState({verificationSuccess: true});

    }else if(feedback=="error"){
      this.setState({
        verificationError: true, 
        errorMsg: "There has been an error with your verificaction. Please try again."
      });
    }

  }

  

  render() {
    return (
      <div className="fixedmodalwrapper gray-background">
        <div style={{overflow:"visible"}} className="fixedmodalcontainer fixed-modal verify-container">
          <div className="fixedmodalhead itemaddedmodalhead">
            {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
            <div
              className="text-block-4"
              style={{ margin: "auto", fontWeight: "bold" }}
            >
              Verification
            </div>
          </div>

          {this.state.verificationLoading? (
            <div className="loading-content" style={{ width: "300px" }}>
              <Loader
                type="TailSpin"
                color="#7455c1"
                height={100}
                width={100}
              />
              <div>Loading...</div>
            </div>
          ) : this.state.verificationSuccess ? (
            <div className="airtime-resp-container">
              
              <div className="success-payment"></div>
              <div className="airtime-resp">Your verification has been submitted.</div>
            </div>
          ) : this.state.verificationError?
          (
            <div className="airtime-resp-container">
                
                <div className="error-payment"></div>
            <div className="airtime-resp">{this.state.errorMsg}</div>
          </div>):
          (
            <div style={{marginTop:"15px"}} className="paymentmodalbuttonwrap">
                <div className="column transfer-container">

                    {/* <div className="steps-title">
                        <input onClick={this.props.handleSteps} type="checkbox" name="step1" 
                            className="steps-check">
                        </input>
                        <label for="step1">Learn about the verification process</label>
                    </div>
                    {!this.props.step1?
                    <div className="verification-steps">
                        
                        <iframe
                            className="shipping-tutorial"
                            src="https://www.youtube.com/embed/np-w_y4spQI"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowfullscreen
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                        ></iframe>

                        <div className="virtualcardworkpopuptext">
                            Watch this video about identity verification
                        </div>
                    </div>
                    :null}

                    <div className="steps-title">
                        <input onClick={this.props.handleSteps} type="checkbox" name="step2" 
                            className="steps-check">
                        </input>
                        <label for="step1">Requirements</label>
                    </div>

                    {(this.props.step1 && !this.props.step2)?
                    <div className="verification-steps">

                        <div>Have one of these identification documents ready</div>
                        <ol type="i">
                            <li>Driving License</li>
                            <li>Government ID</li>
                            <li>Passport</li>
                        </ol> 

                    </div>
                    :null} */}

                    <div className="steps-title">
                        {/* <input onClick={this.props.handleSteps} type="checkbox" name="step4" checked={this.props.birthday}
                            className="steps-check">
                        </input> */}
                        <label for="step1">Enter your date of birth <em>(You must be older than 13yr)</em></label>
                    </div>

                    <div className="">
                        <input
                        value={this.props.birthday}
                        onChange={this.props.handleChange}
                        type="date"
                        className={`formtextfield tabsprofiletextfield dob-input`}
                        maxLength={256}
                        name="birthday"
                        placeholder="Enter Your Date of Birth"
                        id="updateProfileFirstName"
                        max="2010-04-30"
                        />
                    </div>

                    {/* <div className="steps-title">
                        <input onClick={this.props.handleSteps} type="checkbox" name="step3" 
                            className="steps-check">
                        </input>
                        <label for="step1"><em>Optional</em>: Update your profile info</label>
                    </div>
                    {(this.props.step1 && this.props.step2 && this.props.birthday && !this.props.step3)?
                    <div className="verification-steps">
                        <div>Update your profile info to match your documents.</div>
                        <div>To Update your profile info go to the <a href="/seller/dashboard/settings" > settings page</a></div>
                    </div>
                    :null} */}

                    {this.props.birthday && !this.props.dobError?
                        <VerifyButton 
                            stripePromise={stripePromise}
                            birthday={this.props.birthday}
                            sellerId={this.props.storeInfo?.id}
                            storeInfo={this.props.storeInfo}
                            verificationFeedback={this.verificationFeedback}
                            startLoading={this.startLoading}
                        />
                    :
                      <div 
                        className="popupbutton w-inline-block" 
                        style={{backgroundColor:"gray",color:"white", borderColor:"white"}} 
                    >
                        Continue
                      </div>
                  }       

                </div>
            </div>
          )}
          <a
            onClick={() => this.props.hideVerification()}
            className="fixedmodalexitimg w-inline-block"
          />
        </div>
      </div>
    );
  }
}

export default Verification;
