import firebase from "../../firebaseConfig";

const addToCloudinaryDeleteList = (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let brokenUpURL = url.split("/");
      let name = brokenUpURL[8];
      await db.collection("cloudinary_delete").add({
        url: url,
        fileName: name,
      });

      resolve(true);
    } catch (error) {
      console.log("error adding to cloudinary delete list", error);
      reject(error);
    }
  });
};
export default addToCloudinaryDeleteList;
