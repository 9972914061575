import React, { Component } from "react";
import ReactDOM from "react-dom";
import { WithContext as ReactTags } from "react-tag-input";

const KeyCodes = {
  comma: 188,
  enter: 13,
  // SPACE: 32,
  TAB: 9,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.TAB];

const InputTags = (props) => {

  const { tags, suggestions, customClass } = props;
  return (
    <div className={customClass || "mc-input-tags"}>
      <ReactTags
        classNames={{
          tags: "tagsClass",
          tagInput: "tagInputClass",
          tagInputField: props.inputFieldClass,
          selected: "selectedClass",
          tag: "tagClass",
          remove: "removeClass",
          suggestions: "suggestionsClass",
          activeSuggestion: "activeSuggestionClass",
        }}
        tags={tags}
        suggestions={suggestions}
        handleDelete={props.handleDelete}
        handleAddition={props.handleAddition}
        handleDrag={props.handleDrag}
        delimiters={delimiters}
        allowDragDrop={false}
        placeholder={props.placeholder}
        id={props.customId? props.customId:""}
      />

      <div onClick={props.clickAdd} className="add-variant-btn" >Add</div>
    </div>
  );
};

export default InputTags;
