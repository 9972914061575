import { VARIANT_COMBO_URL } from "../../secrets";

const getVariantCombo = (queryData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await fetch(
                VARIANT_COMBO_URL,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(queryData),
                }
            );
            let result = await res.json();
            console.log("result", result);
            resolve(result)
        } catch (error) {
            console.log("error getting variant combo", error);
            reject(error);
        }
    })
}
export default getVariantCombo;