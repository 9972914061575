import React from "react";

function SubscriptionTable(props) {
  return (
    <>
      <h2 className="history-title">Subscription History</h2>
      <table>
      <thead>
        <tr>
          <th>Period</th>
          <th>Start date</th>
          <th>Expiration date</th>
          <th>Paid</th>
        
        </tr>
      </thead>
      <tbody>
        {props.subscriptionHistory.map((item, index) => {
          return (
            <>
              <tr>
                <td style={{textTransform: "uppercase"}}>{item.subscriptionPeriod}</td>
                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td>
                  {item.currency?.toLowerCase() == "ngn" ? "₦" : "$"}
                  {item.chargedAmount?.toLocaleString("en-US")}
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
      <tfoot></tfoot>
      </table>
    </>
  );
}

export default SubscriptionTable;
