import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";

function UpdateUSBankForm(props) {
  return (
    <>
      <div className="section-onboarding">
        <div className="onboarding-steps">
          <div className="block-title-1">
            <h4 className="h4">Let’s Set Up Your Payment Details.</h4>
          </div>
          <div className="microcopy-signup">
            When a customer buys your product, where do you want your money to
            be deposited?
            <br />
            <br />
            {/* To confirm your account, we will validate your Account Number and BVN
          via PayStack. */}
          </div>
          <div className="holder-guide">
            <div className="microcopy-signup">
              Please provide the following:
              <br />‍<br />
              1. Name on Account
              <br />
              2. Bank Account Number
              <br />
              3. Routing Number
            </div>
            <div className="btn-sm-holder">
              {/* <div className="btn-tertiary forms">
              <div className="text-block-4">I don't have an account</div>
            </div>
            <div className="btn-tertiary forms">
              <div className="text-block-4">I don't have a BVN</div>
            </div> */}
            </div>
          </div>

          <div className="holding-onboarding">
          <div className="forms w-form">

          <form
            onSubmit={props.onSubmit}
            id="email-form"
            name="email-form"
            data-name="Email Form"
          >
            <input
              onChange={props.handleChange}
              type="text"
              className="input-text w-input"
              maxLength={256}
              name="accountHolderName"
              data-name="accountHolderName"
              placeholder="Enter the first and last name on the account"
              id="accountHolderName"
              required
            />
            <input
              onChange={props.handleChange}
              type="text"
              className="input-text w-input"
              maxLength={256}
              name="accountNumber"
              data-name="accountNumber"
              placeholder="Enter your account number"
              id="accountNumber"
              required
            />
            <input
              onChange={props.handleChange}
              type="text"
              className="input-text w-input"
              maxLength={256}
              name="routingNumber"
              data-name="routingNumber"
              placeholder="Enter your routing number"
              id="routingNumber"
              required
            />
            {props.loading === false ? (
              <input
                type="submit"
                defaultValue="Next"
                value="Next"
                data-wait="Please wait..."
                className="submit-primary w-button"
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className="submit-primary w-button"
              >
                <div
                  style={{ borderTopColor: "#6740aa" }}
                  className="loader"
                ></div>
              </div>
            )}
          </form>
          <div
              onClick={() => props.backToSettings()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Cancel
            </div>
            <div/>
            </div>
            </div>
          {/* <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div> */}
          {props.displayError === true ? (
            <div style={{ display: "flex" }} className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          ) : null}
          {/* </div>
        </div>
      </div>
    </div> */}
        </div>
      </div>
      <Link to="/" className="logo-black w-inline-block">
        <img
          src={logo}
          // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
          sizes="(max-width: 767px) 80px, 100px"
          alt
          className="cashaam-logo-dark"
        />
        <img
          src={logoWhite}
          // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
          sizes="100vw"
          alt
          className="cashaam-logo-white"
        />
      </Link>
    </>
  );
}

export default UpdateUSBankForm;
