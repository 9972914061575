
import firebase from "../../firebaseConfig";

const getShopperInfo = async (customerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const user = await db.collection("buyersAccounts").doc(customerId).get();
      resolve(user.data());
    } catch (error) {
      console.log("error getting customer info", error);
      reject(error);
    }
  });
};

export default getShopperInfo;