import React from "react";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";

class StoreSnapshot extends React.Component {

  state ={
    highestScore: 100,
    filter: false
  }

  openFilter =()=>{

    let filter=this.state.filter;
    this.setState({
      filter: !filter
    })
  }

  pickFilter =(days)=>{
    this.setState({
      filter: false
    })

    this.props.storeAnalytics(this.props.slug, days);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props?.daysFilter !==
      prevProps?.daysFilter
    ) {
      console.log("update analytics");

      let highestScore=0;
      let storeStats = this.props.storeStats;
  
      Object.keys(storeStats).forEach(function(key) {
  
        let stats=storeStats[key];
        let statsScore=parseFloat(stats?.event_count);
        if(statsScore>highestScore){
          highestScore=statsScore;
        }
  
      });
  
  
      this.setState({
        highestScore,
      })

    }
  }

  componentDidMount() {

    const d = new Date();
    let year = d.getFullYear();

    let highestScore=0;
    let storeStats = this.props.storeStats;

    Object.keys(storeStats).forEach(function(key) {

      let stats=storeStats[key];
      let statsScore=parseFloat(stats?.event_count);
      if(statsScore>highestScore){
        highestScore=statsScore;
      }

    });


    this.setState({
      highestScore,
      year
    })

  }

  render() {
    return (
      <div
        id="w-node-e4438cdf-4af6-519c-6133-a0f0b4fa2475-b87d6bb3"
        className="dashboardbox gridbox3"
      >
        <div className="gridbox3top">
          <div className="gridboxtitle nomargin">How is my store doing?</div>
          <div
            data-hover="false"
            data-delay={0}
            className="dropdown w-dropdown"
          >
            <div className="dropdowntoggle w-dropdown-toggle" onClick={this.openFilter}>
              <div className="dropdownicon w-icon-dropdown-toggle" />
              <div>Filter</div>
            </div>
            <nav className={`w-dropdown-list ${this.state.filter?"w--open":""}`}>
              <a onClick={()=>this.pickFilter(7)} className="dropdownlink w-dropdown-link w-dropdown-link-analytics">
                Last Week
              </a>
              <a onClick={()=>this.pickFilter(30)} className="dropdownlink w-dropdown-link w-dropdown-link-analytics">
                Last Month
              </a>
              <a onClick={()=>this.pickFilter(365)} className="dropdownlink w-dropdown-link w-dropdown-link-analytics">
                2022
              </a>
            </nav>
          </div>
        </div>
        <div
          id="w-node-_155a2821-3f9f-f901-a792-2a668a7107c0-b87d6bb3"
          className="gridbox3statuscontentwrap"
        >
          <div className="gridbox3statuscontent">
            {/* {this.props.storeStats?.map((stats, index) => {
              return stats?.eventName ? (
                <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((stats?.eventCount*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    {stats?.category?.join(" ")}
                  </div>
                </div>
              ) : null;
            })} */}

              <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((this.props.storeStats?.first_visit?.event_count*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    Visits
                  </div>
              </div>

              <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((this.props.storeStats?.add_to_cart?.event_count*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    Cart
                  </div>
              </div>

              <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((this.props.storeStats?.begin_checkout?.event_count*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    Begun Checkout
                  </div>
              </div>

              <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((this.props.storeStats?.add_shipping_info?.event_count*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    Shipping Info
                  </div>
              </div>

              <div className="gridbox3statuswrap">
                  <div className="gridbox3status">
                    <div style={{height: `${((this.props.storeStats?.add_payment_info?.event_count*100)/this.state.highestScore)}%`}} className="gridbox3statusbar _2" />
                  </div>
                  <div
                    style={{ textAlign: "center" }}
                    className="gridbox3statustext"
                  >
                    Payment
                  </div>
              </div>


          </div>
          <div className="gridbox3statuscontentstats">
            <div className="row">
              <div className="gridbox3statuscontentstatstext">{this.state.highestScore}</div>
              <div className="gridbox3statuscontentstatsline" />
            </div>
            <div className="row">
              <div className="gridbox3statuscontentstatstext">{Math.ceil((this.state.highestScore/3)*2)}</div>
              <div className="gridbox3statuscontentstatsline" />
            </div>
            <div className="row">
              <div className="gridbox3statuscontentstatstext">{Math.ceil(this.state.highestScore/3)}</div>
              <div className="gridbox3statuscontentstatsline" />
            </div>
            <div className="row">
              <div className="gridbox3statuscontentstatstext">0</div>
              <div className="gridbox3statuscontentstatsline" />
            </div>
          </div>
        </div>
        <div className="gridbox3bottom">
          <div className="row">
            <div className="gridbox3bottomtext">Total Ad Spend</div>
            <div className="gridbox3bottomprice">{priceFormaterIntl(this.props.spendingLimit, "USD")}</div>
          </div>
          {/* <a href="#" className="viewall">
            View all
          </a> */}
        </div>
      </div>
    );
  }
}

export default StoreSnapshot;
