import React from 'react';


const VariantForm = (props) => {
    return (

        <div style={{ display: "block", minWidth: "fit-content", columns: props.numberOfColumns ? props.numberOfColumns + 2 : 3 }} className="mc-extra-variant-holder">

            {props.combination.map((value, i) => (
                <input style={{ minWidth: "100px" }} key={i}
                    value={value} type="text" className="input-text w-input"
                    readOnly="readonly"
                />
            ))}
            <input style={{ minWidth: "100px" }} onChange={props.handleVariantPriceChange} name={props.id} type="number" placeholder="price" defaultValue={props.productPrice} value={props.price} min={0.01}
                step={0.01} required={true} className="input-text w-input"
            />
            <input style={{ minWidth: "100px" }} onChange={props.handleVariantQuantityChange} name={props.id} type="number" placeholder="quantity" defaultValue={props.productStock} value={props.quantity}
                step={1} required={true} className="input-text w-input"
            />

        </div>
    )
};
export default VariantForm;