import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import shortid from "shortid";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import StatefulMultiSelect from "@khanacademy/react-multi-select";

import storeProductFeaturedImage from "../../../helpers/storeProductFeaturedImage";
import storeProductImagesToStorage from "../../../helpers/storeProductImagesToStorage";
import saveProductToDatabase from "../../../helpers/saveProductToDatabase";
import getSellersInfo from "../../../helpers/getSellersInfo";
import InputTags from "../../../common/InputTags";
import priceFormater from "../../../helpers/priceFormater";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCatalogs } from "../../../helpers/catalogs";
import "./EditProduct.css";
import MyQuillEditor from "../../../common/MyQuillEditor";
import { escape, unescape } from 'html-escaper';
import ExtraVariant from "../../../common/ExtraVariant";
import VariantForm from "../../../common/VariantForm";
import getVariantCombo from "../../../helpers/getVariantCombo";
import VariantExamplePop from "../../../common/VariantExamplePop";



function getCombn(arr) {
  if (arr.length == 1) {
    return arr[0];
  } else {
    var ans = [];

    // recur with the rest of the array. 
    var otherCases = getCombn(arr.slice(1));
    for (var i = 0; i < otherCases.length; i++) {
      for (var j = 0; j < arr[0].length; j++) {
        ans.push(arr[0][j] + "-&-" + otherCases[i]);
      }
    }
    return ans;
  }
}

let tempFiles = [];
const db = firebase.firestore();
const options = [
  { label: "Clothing, Shoes, Jewelry & Watches", value: 0 },
  { label: "Movies, Music & Games", value: 1 },
  { label: "Electronics, Computers & Office", value: 2 },
  { label: "Home, Garden & Tools", value: 3 },
  { label: "Food & Grocery", value: 4 },
  { label: "Beauty & Health", value: 5 },
  { label: "Toys, Kids & Baby", value: 6 },
  { label: "Handmade", value: 7 },
  { label: "Sports & Outdoors", value: 8 },
  { label: "Automotive & Industrial", value: 9 },
];

const optionsToValues = {
  "Clothing, Shoes, Jewelry & Watches": 0,
  "Movies, Music & Games": 1,
  "Electronics, Computers & Office": 2,
  "Home, Garden & Tools": 3,
  "Food & Grocery": 4,
  "Beauty & Health": 5,
  "Toys, Kids & Baby": 6,
  Handmade: 7,
  "Sports & Outdoors": 8,
  "Automotive & Industrial": 9,
};

// const colorOptions = [
//   { label: "Black", value: 0 },
//   { label: "White", value: 1 },
//   { label: "Red", value: 2 },
//   { label: "Blue", value: 3 },
//   { label: "Green", value: 4 },
//   { label: "Yellow", value: 5 },
//   { label: "Gold", value: 6 },
//   { label: "Grey", value: 7 },
//   { label: "Brown", value: 8 },
//   { label: "Pink", value: 9 },
//   { label: "Orange", value: 10 },
//   { label: "Purple", value: 11 },
//   { label: "Silver", value: 12 },
//   { label: "Other", value: 13 },
// ];

// const colorsOptionsToValues = {
//   Black: 0,
//   White: 1,
//   Red: 2,
//   Blue: 3,
//   Green: 4,
//   Yellow: 5,
//   Gold: 6,
//   Grey: 7,
//   Other: 8,
// };

export default class EditProduct extends Component {
  state = {
    productImages: this.props.productInfo.productImages
      ? this.props.productInfo.productImages
      : [],
    featuredImage: this.props.productInfo.featuredImage
      ? this.props.productInfo.featuredImage
      : "",
    productName: this.props.productInfo.productName
      ? this.props.productInfo.productName
      : "",
    productDescription: this.props.productInfo.productDescription
      ? unescape(this.props.productInfo.productDescription)
      : "",
    productPrice: this.props.productInfo.productPrice
      ? this.props.productInfo.productPrice
      : 0,
    productStock: this.props.productInfo.productStock
      ? this.props.productInfo.productStock
      : 0,
    productVariant:
      this.props.productInfo.productVariant &&
        Array.isArray(this.props.productInfo.productVariant) === false
        ? this.props.productInfo.productVariant
        : {},
    productWeight: this.props.productInfo.productWeight
      ? parseFloat(this.props.productInfo.productWeight * 16)
      : 0,
    productHeight: this.props.productInfo.productHeight
      ? this.props.productInfo.productHeight
      : 0,
    productWidth: this.props.productInfo.productWidth
      ? this.props.productInfo.productWidth
      : 0,
    productLength: this.props.productInfo.productLength
      ? this.props.productInfo.productLength
      : 0,
    // productCategory: this.props.productInfo.productCategory
    //   ? this.props.productInfo.productCategory
    //   : "",
    sellerFullName: this.props.productInfo.sellerFullName
      ? this.props.productInfo.sellerFullName
      : "",
    slug: this.props.productInfo.slug ? this.props.productInfo.slug : "",
    currency: this.props.productInfo.currency
      ? this.props.productInfo.currency
      : "N",
    storeName: this.props.productInfo.storeName
      ? this.props.productInfo.storeName
      : "",
    discount: this.props.productInfo.discount
      ? this.props.productInfo.discount
      : 0,
    discountedPrice: this.props.productInfo.discountedPrice
      ? this.props.productInfo.discountedPrice
      : 0,
    location: this.props.productInfo.location
      ? this.props.productInfo.location
      : "",
    lastUpdate: this.props.productInfo.lastUpdate
      ? this.props.productInfo.lastUpdate
      : "",
    likes: this.props.productInfo.likes ? this.props.productInfo.likes : 0,
    newFiles: [],
    newFeaturedImageFile: [],
    featuredImageURL: "",
    productCategory: this.props.productInfo.productCategory
      ? this.props.productInfo.productCategory
      : [],
    selectedCatalogs: this.props.productInfo.catalogs
      ? this.props.productInfo.catalogs
      : [],
    storeCatalogs: [],
    selected: [],
    noProducts: false,
    productUploadSuccess: false,
    productUploadError: false,
    sizeSuggestions: [
      { id: "Extra Small", text: "Extra Small" },
      { id: "Small", text: "Small" },
      { id: "Medium", text: "Medium" },
      { id: "Large", text: "Large" },
      { id: "Extra Large", text: "Extra Large" },
    ],
    tags: [], // preselected tags,
    selectedColors: [],
    viewOtherColorInput: false,
    colorTags: [],
    otherColors: [],
    coloredInputField: [],
    extraVariants: this.props.productInfo.productVariant?.extraVariants ? this.props.productInfo.productVariant.extraVariants : [],
    extraVariantsTags: [],
    invalidExtraVariants: [],
    variantObject: this.props.productInfo.productVariant?.variantObject ? this.props.productInfo.productVariant.variantObject : {},
    editCombinationPrices: true,
    startIndex: 0,
    showExample: false
  };

  setShowExample = (val) => {
    this.setState({ showExample: val })
  }

  handleEditorChange = (html) => {
    console.log("quill val", html)
    this.setState({ productDescription: html })
  }

  colorOptions = this.props.selectedProductColorOptions;
  colorsOptionsToValues = this.props.selectedProductColorOptionsToValues;
  handleDelete = (i) => {
    const { tags } = this.state;
    let newTagArray = [];
    let productVar = this.state.productVariant;

    let newTags = tags.filter((tag, index) => index !== i);
    newTags.map((tag) => {
      newTagArray.push(tag.text);
    });
    productVar.sizes = newTagArray;
    this.setState({
      tags: newTags,
      productVariant: productVar,
    });
    this.generateVariantCombinations()

  };

  handleColorDelete = async (i) => {
    let { colorTags, otherColors } = this.state;
    otherColors = otherColors.filter((color) => color !== colorTags[i].text);
    await Promise.resolve(this.setState({
      colorTags: colorTags.filter((tag, index) => index !== i),
      otherColors,
    }));
    this.generateVariantCombinations()

  };


  clickAddVariant = (id, i)=>{

    let customId=`${id}${i}`;
    console.log("customId",customId);
    let inputValue = document.getElementById(customId).value;

    if(inputValue){
      let tag={
        id: inputValue,
        text: inputValue
      }
      this.handleExtraVariantAddition(tag,i);
      document.getElementById(customId).value = ``;
    }

  }

  clickAdd = (id, handleAddition)=>{
    console.log("id",id);
    var inputValue = document.getElementById(id).value;

    if(inputValue){
      let tag={
        id: inputValue,
        text: inputValue
      }
      handleAddition(tag);
      document.getElementById(id).value = ``;
    }
  }
  handleAddition = async (tag) => {
    console.log("tag",tag)
    let tagArray = [];
    let productVar = this.state.productVariant;

    tagArray.push(tag.text);
    this.state.tags.map((tag) => {
      tagArray.push(tag.text);
    });
    productVar.sizes = tagArray;

    await Promise.resolve(this.setState((state) => ({
      tags: [...state.tags, tag],
      productVariant: productVar,
    })));
    this.generateVariantCombinations()

  };

  handleColorAddition = async (tag) => {
    let tagArray = [];
    // let productVar = this.state.productVariant;

    tagArray.push(tag.text);
    this.state.colorTags.map((tag) => {
      tagArray.push(tag.text);
    });
    // productVar.colors = tagArray;

    await Promise.resolve(this.setState((state) => ({
      colorTags: [...state.colorTags, tag],
      otherColors: tagArray,
    })));
    this.generateVariantCombinations()

  };

  handleDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };

  handleColorDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.colorTags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ colorTags: newTags });
  };

  handleChange = (e) => {
    //enforce numbers only for product dimensions
    if (
      e.target.name === "productWeight" ||
      e.target.name === "productHeight" ||
      e.target.name === "productLength" ||
      e.target.name === "productWidth"
    ) {
      this.setState({
        [e.target.name]: parseFloat(e.target.value),
      });

      return;
    }

    //enfore price format
    if (e.target.name === "productPrice") {
      // let price = e.target.value.replace(/[^0-9 ]/g, "");
      // price = price.replace(/ /g, "");
      this.setState({
        [e.target.name]: e.target.value,
      });
      return;
    }

    if (e.target.name === "productStock") {
      let stock = e.target.value.replace(/[^0-9 ]/g, "");
      stock = stock.replace(/ /g, "");
      this.setState({
        [e.target.name]: stock,
      });
      return;
    }

    if (e.target.name === "productSizes") {
      //turn input into an array
      let sizeArray = e.target.value.split(",");
      let productVariant = this.state.productVariant;
      productVariant.sizes = sizeArray;
      console.log("sizes", sizeArray);
      this.setState({
        [e.target.name]: e.target.value,
        productVariant,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumberInputChange = (e) => {
    const doubleValue = parseFloat(e.target.value);
    this.setState({
      [e.target.name]: doubleValue,
    });
  };

  handleArrayInputChange = (e) => {
    const tempHolder = e.target.value.split(",");
    this.setState({
      [e.target.name]: tempHolder,
    });
  };

  //handle featuredImage change
  handleFeaturedImageChange = async (e) => {
    e.preventDefault();
    // const {productName, productCategory}=this.state;
    const productName = this.state.productName
      ? this.state.productName
      : "image";
    const productCategory = this.state.productCategory
      ? this.state.productCategory
      : ["default"];

    const featuredImageFile = e.target.files[0];

    //enfore image type on upload
    if (
      featuredImageFile.type !== "image/png" &&
      featuredImageFile.type !== "image/jpg" &&
      featuredImageFile.type !== "image/jpeg"
    ) {
      const message = `Image of type ${featuredImageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
      console.log(
        "File type is not supported. Uploaded type:",
        featuredImageFile.type
      );
      toast.error(message);
      return;
    }

    this.showAwaitingImageUpload(true);

    const featuredImageURL = await storeProductFeaturedImage(
      featuredImageFile,
      productName,
      productCategory
    );
    this.showAwaitingImageUpload(false);

    this.setState({ featuredImage: featuredImageURL });
  };

  // handles the images change handle
  handleImageChange = async (e) => {
    e.preventDefault();
    if (this.state.productImages.length + 1 > 4) {
      toast.error("You can only upload a maximum of 4 images");
      return;
    }

    const imageFile = e.target.files[0];

    //enfore image type on upload
    if (
      imageFile.type !== "image/png" &&
      imageFile.type !== "image/jpg" &&
      imageFile.type !== "image/jpeg"
    ) {
      const message = `Image of type ${imageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
      console.log("File type is not supported. Uploaded type:", imageFile.type);
      toast.error(message);
      return;
    }

    this.showAwaitingImageUpload(true);
    const productName = this.state.productName
      ? this.state.productName
      : "image";
    const productCategory = this.state.productCategory
      ? this.state.productCategory
      : ["default"];
    const imageURL = await storeProductImagesToStorage(
      imageFile,
      productName,
      productCategory
    );
    const allImages = this.state.productImages;
    this.showAwaitingImageUpload(false);
    allImages.push(imageURL);
    this.setState({ productImages: allImages });
  };

  showAwaiting = (value, message) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: message,
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => { }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  // this function is the popup when saving the product
  showAwaitingImageUpload = (value) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: "Your Image is being uploaded...",
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => { }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  // this function is the popup when saving the product
  showWaitingPopupWhenProductIsBeingSave = (value) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: "Your product is being saved...",
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => { }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();



    //Enfore user to select atleast one category
    if (this.state.productCategory.length === 0) {
      toast.error("Please select atleast one category");
      return;
    }

    //enforce minimum price of N100 for nigeria sellers
    if (
      this.state.productPrice < 100 &&
      (this.state.currency === "N" || this.state.currency === "NGN")
    ) {
      return toast.error("Please enter a minimum price of N100");
    }

    const featuredImageURL = this.state.featuredImage;

    let productImagesURL = this.state.productImages;

    //check if featured Image exists
    if (!featuredImageURL) {
      toast.error("Please upload main Image for your product");
      return;
    }

    this.showWaitingPopupWhenProductIsBeingSave();

    console.log("featImg", featuredImageURL);
    console.log("images", productImagesURL);

    let {
      productName,
      productDescription,
      productPrice,
      productStock,
      productVariant,
      productWeight,
      productHeight,
      productWidth,
      productLength,
      productCategory,
      sellerFullName,
      currency,
      storeName,
      discount,
      discountedPrice,
      location,
      likes,
      slug,
      otherColors,
      selectedCatalogs,
      extraVariants,
      variantObject
    } = this.state;

    //convert to metric for Nigeria
    if(this.props.userCurrentCountry?.toLocaleLowerCase()=="nigeria"){
      productLength=Math.ceil(productLength/2.54);
      productWidth=Math.ceil(productWidth/2.54);
      productHeight=Math.ceil(productHeight/2.54);
      //roundup 3 decimal places
      productWeight=Math.ceil(productWeight*0.0022* 1000) / 1000;
    }else{
      //convert product weight from oz to lbs
      productWeight = parseFloat(productWeight / 16);
    }

    console.log("metric dimensions:",{productWeight,
      productHeight,
      productWidth,
      productLength,})

    let isExtraVariantValid = await this.validateExtraVariants(extraVariants);
    if (!isExtraVariantValid.valid) {
      let index = isExtraVariantValid.index;
      let invalidExtraVariants = this.state.invalidExtraVariants;
      invalidExtraVariants.push(index);
      this.showWaitingPopupWhenProductIsBeingSave(false);

      return this.setState({
        invalidExtraVariants
      })
    }

    productDescription = escape(productDescription); //ESCAPE HTML - this will be decoded when rendering on the front end
    //add other colors to product info if available
    if (otherColors.length > 0) {
      let allColors = productVariant.colors.concat(otherColors);
      productVariant.colors = allColors;
    }
    console.log("Before PROD INF0", productVariant);
    //Add extra variants
    productVariant.extraVariants = extraVariants;

    //Variant object containing every variant and their corresponding price and quantity
    productVariant.variantObject = variantObject;

    const productInfo = {
      productName,
      productDescription,
      productPrice,
      productStock,
      productVariant,
      productWeight,
      productHeight,
      productWidth,
      productLength,
      productCategory,
      sellerFullName,
      currency,
      storeName,
      discount,
      discountedPrice,
      location,
      likes,
      slug,
      featuredImageURL,
      productImagesURL,
      productId: this.props.productId,
      catalogs: selectedCatalogs.map(
        (value) => this.state.storeCatalogs[value].label
      ),
    };

    console.log("first PROD INFO", productInfo);

    const result = await saveProductToDatabase(productInfo);

    console.log("product upload result 10/3", result);
    if (result === "success") {
      this.showWaitingPopupWhenProductIsBeingSave(false);
      this.props.closeModal();
      this.setState({
        productUploadSuccess: true,
      });
    } else {
      console.log("error saving product", result);
      this.showWaitingPopupWhenProductIsBeingSave(false);
      this.setState({
        productUploadError: true,
      });
    }
  };

  checkSellerInfo = async (sellerUid) => {
    try {
      const sellerInfo = await getSellersInfo(sellerUid);
      if (sellerInfo !== "No store") {
        const { firstName, lastName, storeName, slug, address } = sellerInfo;
        this.setState({
          sellerFullName: firstName + " " + lastName,
          storeName: storeName,
          slug: slug,
          location: address,
        });
      } else {
        toast.error("Please setup a store before adding products");
        this.props.history.push("/seller/dashboard/setup");
      }
    } catch (error) {
      console.log("error checking seller info", error);
    }
  };



  cancel = async () => {
    //empty tempFiles with the user cancels Add Product action
    tempFiles = [];

    this.props.history.push("/seller/dashboard");
  };

  addProductsLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };
  selectCategory = ({ selected }) => {
    //limit the number of category selected to 2 MAX
    const numberOfItems = selected.length;
    const categoryNames = [];
    if (numberOfItems === 0) {
      this.setState({ selected });
      this.setState({ productCategory: [] });
    } else if (numberOfItems <= 2) {
      this.setState({ selected });
      selected.forEach((categoryValue) => {
        categoryNames.push(options[categoryValue].label);
        this.setState({ productCategory: categoryNames });
      });
    } else {
      toast.error("You can only select 2 categories");
      return;
    }
  };

  selectCatalog = ({ selected }) => {
    // console.log(selected)
    this.setState({
      selectedCatalogs: selected,
    });
  };

  removeFeaturedImage = async (imageURL) => {
    if (!imageURL) {
      return toast.error("No image to be deleted");
    }

    // const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerUid = this.props.sellerUid;
    let batch = db.batch();
    const productId = this.props.productId;

    // setFeaturedImageUploaded(false);
    this.setState({ featuredImageUploaded: false });

    if (imageURL.includes("blob:http")) {
      //delete a picture after selecting and BEFORE SAVING CHANGES to database
      return this.setState({
        featuredImage: "",
        newFeaturedImageFile: "",
        featuredImageURL: "",
        previewFeaturedImage: "",
      });
    }

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = await db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("products")
      .doc(productId);
    batch.update(sellersAccountsRef, { featuredImage: "" });

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.update(allProductsRef, { featuredImage: "" });

    await batch
      .commit()
      .then(() => {
        var imageRef = storage.refFromURL(imageURL);

        imageRef
          .delete()
          .then(() => {
            this.setState({
              featuredImage: "",
              newFeaturedImageFile: "",
              featuredImageURL: "",
            });
            console.log("image removed");
          })
          .catch((error) => {
            console.log("error deleting image--", error);
          });
      })
      .catch((error) => {
        console.log("error deleting image reference");
      });
  };

  selectColor = async ({ selectedColors }) => {
    let productColors = [];
    selectedColors.map((colorsCodes) => {
      const colorName = this.colorOptions[colorsCodes].label;
      //exclude "Other"
      if (colorName !== "Other") {
        productColors.push(colorName);
        this.displayOtherColorInput(false);
      } else {
        this.displayOtherColorInput(true);
      }
    });
    console.log("prodColor", productColors);
    let productVar = this.state.productVariant;
    productVar.colors = productColors;

    await Promise.resolve(this.setState({ selectedColors, productVariant: productVar }));
    this.generateVariantCombinations()

  };

  displayOtherColorInput = (val) => {
    if (val === false) {
      //clear the inputed colors from "other" input field
      return this.setState({
        otherColors: [],
        colorTags: [],
        viewOtherColorInput: val
      });
    }

    this.setState({ viewOtherColorInput: val });
    //highlight the "other color" input field
    this.highLightField("other-colors");
  };

  highLightField = (name) => {
    //highlight input field
    let coloredInputField = this.state.coloredInputField;
    coloredInputField.push(name);
    this.setState({ coloredInputField });

    setTimeout(() => {
      let targetIndex = coloredInputField.indexOf(name);
      coloredInputField.splice(targetIndex, 1);
      this.setState({
        coloredInputField
      })
    }, 5000)
  }

  populateSelectedCategories = () => {
    const selected = [];
    //avoid errors with productCategory not being an array sometimes. (don't know why this happens right now. shouldn't happen but just to be safe)
    if (Array.isArray(this.state.productCategory) === true) {
      this.state.productCategory.forEach((category) => {
        selected.push(optionsToValues[category]);
      });
    }
    this.setState({ selected });
  };

  populateSelectedColors = () => {
    const selectedColors = [];

    //avoid errors
    if (Array.isArray(this.state.productVariant.colors) === true) {
      this.state.productVariant.colors.forEach((color) => {
        selectedColors.push(this.colorsOptionsToValues[color]);
      });
    }
    this.setState({ selectedColors });
  };

  populateTags = () => {
    let tagsArrayObject = [];
    if (this.state.productVariant && this.state.productVariant.sizes) {
      //avoid errors
      if (Array.isArray(this.state.productVariant.sizes) === true) {
        this.state.productVariant.sizes.forEach((size) => {
          const obj = { id: size, text: size };
          tagsArrayObject.push(obj);
        });
      }
      this.setState({ tags: tagsArrayObject });
    }
  };

  populateExtraVariantTags = () => {
    let { extraVariantsTags } = this.state;
    if (this.state.extraVariants) {
      if (Array.isArray(this.state.extraVariants)) {
        this.state.extraVariants.map((variant, i) => {
          console.log("VARIANT", variant);
          let formattedArray = [];
          variant.value.map(tag => {
            let obj = { id: tag, text: tag };
            formattedArray.push(obj);
          })
          extraVariantsTags.push(formattedArray);
        })
      }
    }

    this.setState({ extraVariantsTags })
  }


  addExtraVariants = () => {
    let { extraVariants } = this.state;
    let newVariant = { name: "", value: [] };
    extraVariants.push(newVariant);
    this.setState({ extraVariants });
    this.generateVariantCombinations()
  }

  handleExtraVariantNameChange = (e) => {
    let index = parseInt(e.target.name);
    let { invalidExtraVariants } = this.state;
    invalidExtraVariants.splice(index, 1);
    let value = e.target.value;
    let extraVariants = this.state.extraVariants;
    extraVariants[index].name = value;
    this.setState({
      extraVariants,
      invalidExtraVariants
    })

  }


  handleExtraVariantAddition = (tag, i) => {
    let tagArray = [];
    let extraVariants = this.state.extraVariants;
    let extraVariantsTags = this.state.extraVariantsTags;
    let invalidExtraVariants = this.state.invalidExtraVariants;
    invalidExtraVariants.splice(i);
    if (extraVariantsTags[i]) {
      extraVariantsTags[i].push(tag);
    } else {
      extraVariantsTags[i] = [tag]
    }
    console.log("extraVariantTags", extraVariantsTags);
    extraVariantsTags[i].map((tag) => {
      console.log("TAG", tag);
      tagArray.push(tag.text);
    });
    extraVariants[i].value = tagArray;
    this.setState((state) => ({
      extraVariantsTags: extraVariantsTags,
      extraVariants: extraVariants,
      invalidExtraVariants
    }));
    console.log("extraVariants state", this.state.extraVariants)
    this.generateVariantCombinations();
  };
  ß

  handleExtraVariantDelete = (j, i) => {
    console.log({ i, j })
    let { extraVariantsTags, extraVariants } = this.state;
    let valueToDelete = extraVariantsTags[i][j].text;
    extraVariantsTags[i] = extraVariantsTags[i].filter((tag, index) => index !== j);
    let deletionIndex = extraVariants[i].value.indexOf(valueToDelete);
    extraVariants[i].value.splice(deletionIndex, 1);

    this.setState({
      extraVariantsTags,
      extraVariants
    });
    this.generateVariantCombinations()
  };

  validateExtraVariants = (variants) => {
    return new Promise((resolve, reject) => {
      variants.map((variant, i) => {
        if (variant.value.length < 1 || variant.name.length < 1 || !variant.name.length) {
          resolve({ valid: false, index: i })
        }
      });
      resolve({ valid: true })
    })
  }

  removeExtraVariant = (i) => {
    let { extraVariants, extraVariantsTags } = this.state;
    extraVariantsTags.splice(i, 1);
    extraVariants.splice(i, 1);

    this.setState({ extraVariants, extraVariantsTags })
    this.generateVariantCombinations()

  }

  validateExtraVariants = (variants) => {
    return new Promise((resolve, reject) => {
      variants.map((variant, i) => {
        if (variant.value.length < 1 || variant.name.length < 1 || !variant.name.length) {
          resolve({ valid: false, index: i })
        }
      });
      resolve({ valid: true })
    })
  }


  generateVariantCombinations = async () => {
    let variantCombo = [];
    let { selectedColors, otherColors, extraVariants, productVariant, variantObject, productPrice, productStock } = this.state;
    let sizes = productVariant.sizes;
    // let arrayHolder = [];

    let queryData = { selectedColors, otherColors, extraVariants, variantObject, productPrice, productStock, sizes, selectedProductColorOptions: this.props.selectedProductColorOptions };

    // let tempVariantObj = {};
    // combinations.map(combination => {
    //   let comboArray = combination.split("-&-");
    //   let variantComboId = combination;
    //   let variantComboPrice = variantObject[variantComboId] ? parseFloat(variantObject[variantComboId].price).toFixed(2) : parseFloat(productPrice).toFixed(2);
    //   let variantComboQuantity = variantObject[variantComboId] && parseInt(variantObject[variantComboId].quantity) > 0 ? parseInt(variantObject[variantComboId].quantity) : parseInt(productStock)
    //   tempVariantObj[variantComboId] = { price: variantComboPrice, values: comboArray, quantity: variantComboQuantity }
    // })


    // console.log("TESTING ", { selectedColors, otherColors, sizes, extraVariants });
    // console.log("VARIANT COMBO", variantCombo)
    // console.log("COMBINATIONS", combinations)
    // console.log("VAR OBJ", variantObject)
    let variantObj = await getVariantCombo(queryData)

    this.setState({
      variantObject: variantObj?.tempVariantObj
    })

  }

  handleVariantPriceChange = (e) => {

    let val = e.target.value;
    let id = e.target.name;
    let { variantObject } = this.state;
    variantObject[id].price = val;
    console.log({ val, id, variantObject })

    this.setState({ variantObject })
  }

  handleVariantQuantityChange = (e) => {

    let val = e.target.value;
    let id = e.target.name;
    let { variantObject } = this.state;
    variantObject[id].quantity = val;
    console.log({ val, id, variantObject })

    this.setState({ variantObject })
  }

  onEditComboPrices = (val) => {
    this.setState({ editCombinationPrices: val })
  }

  componentDidMount() {
    // check if seller is logged in
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        //Check if user already has some products uploaded
        this.checkSellerInfo(user.uid);
        this.populateSelectedCategories();
        this.populateSelectedColors();
        this.populateTags();
        this.populateExtraVariantTags();
        const storeCatalogs = await getCatalogs();
        if(storeCatalogs){
          this.setState(
            {
              storeCatalogs: storeCatalogs.map((catalog, index) => {
                return {
                  label: catalog,
                  value: index,
                };
              }),
            },
            () => {
              const selectedCatalogs = [...this.state.selectedCatalogs];
              const selected = [];
              selectedCatalogs.forEach((catalog) => {
                const idx = storeCatalogs.findIndex((c) => c === catalog);
                if (idx >= 0) selected.push(idx);
              });
              this.setState({ selectedCatalogs: selected });
            }
          );
        }

        let{productWeight,productLength,productWidth, productHeight  }=this.props.productInfo;
        if(this.props.userCurrentCountry?.toLowerCase()=="nigeria"){

          console.log("convert dimensions to metrics");

          productLength=Math.floor(productLength*2.54);
          productWidth=Math.floor(productWidth*2.54);
          productHeight=Math.floor(productHeight*2.54);
          //round down 3 decimal places
          productWeight=Math.floor(productWeight/0.0022* 1000) / 1000

          this.setState({
            productLength,
            productWidth,
            productHeight,
            productWeight
          })

        }
      } else {
        this.props.history.push("/");
      }
    });

    tempFiles = [];
  }

  setStartIndex = (val) => {
    this.setState({
      startIndex: val
    })
  }
  // let variantForms = [];
  // for (const [id, varObj] of Object.entries(this.state.variantObject)) {
  //   let combination = varObj.values;
  //   variantForms.push(<VariantForm
  //     numberOfColumns={combination?.length}
  //     combination={combination}
  //     productPrice={this.state.productPrice}
  //     id={id}
  //     price={varObj.price}
  //     handleVariantPriceChange={this.handleVariantPriceChange}
  //     handleVariantQuantityChange={this.handleVariantQuantityChange}
  //     quantity={varObj.quantity}
  //     productStock={this.state.productStock}
  //   />
  //   )
  // }
  render() {
    let combinationLength = Object.entries(this.state.variantObject).length;
    let variantForms = [];
    const loadVariantForms = () => {
      let variantArrayVals = Object.entries(this.state.variantObject);
      let limit = this.state.startIndex ? this.state.startIndex + 100 : 100;
      for (let i = this.state.startIndex ? this.state.startIndex : 0; i < variantArrayVals.length && i <= limit; i++) {
        const [id, varObj] = variantArrayVals[i]
        let combination = varObj.values;
        variantForms.push(<VariantForm
          numberOfColumns={combination?.length}
          combination={combination}
          productPrice={this.state.productPrice}
          id={id}
          price={varObj.price}
          handleVariantPriceChange={this.handleVariantPriceChange}
          handleVariantQuantityChange={this.handleVariantQuantityChange}
          quantity={varObj.quantity}
          productStock={this.state.productStock}
        />
        )
      }
    }
    const nextVariantPage = () => {
      if (combinationLength - this.state.startIndex > 100) {
        this.setStartIndex(this.state.startIndex + 100);
      }
      // loadVariantForms()
    }

    const prevtVariantPage = () => {
      if (this.state.startIndex - 100 >= 0) {
        this.setStartIndex(this.state.startIndex - 100);
      }
    }

    if (this.state.startIndex > combinationLength) {
      this.setStartIndex(0)
    }

    loadVariantForms();

    let colorVariantsExist = this.state.selectedColors?.length > 0 && !(this.state.selectedColors?.length === 1 && this.state.coloredInputField.includes("other-colors"));
    let sizeVariantsExist = this.state.tags?.length > 0;
    let variantOneExists = this.state.extraVariants[0]?.name && this.state.extraVariantsTags[0]?.length > 0;
    let variantTwoExists = this.state.extraVariants[1]?.name && this.state.extraVariantsTags[1]?.length > 0;
    let variantThreeExists = this.state.extraVariants[2]?.name && this.state.extraVariantsTags[2]?.length > 0;
    let variantExists = colorVariantsExist || variantOneExists || variantTwoExists || variantThreeExists;
    let variantHeaderNumber = 0;
    variantHeaderNumber += colorVariantsExist ? 1 : 0;
    variantHeaderNumber += sizeVariantsExist ? 1 : 0;
    variantHeaderNumber += variantOneExists ? 1 : 0;
    variantHeaderNumber += variantTwoExists ? 1 : 0;
    variantHeaderNumber += variantThreeExists ? 1 : 0;

    return (
      <>
        {this.state.showExample === true ?
          <VariantExamplePop setShowExample={this.setShowExample} />
          : null}
        <div className="panel-center">
          <div className="page-header">
            <h4 className="header-title">Update/Edit Product</h4>
            <div className="store-status">
              <div className="store-state-live">Your Store is Live</div>
            </div>
          </div>
          <div className="main-edit">
            <div className="form-editor">
              <div style={{ minWidth: "90%" }} className="product-edit">
                <div className="block-title-2">
                  <h4 className="page-title">
                    Product&nbsp;Update - Product Name
                  </h4>
                </div>
                <div className="microcopy-signup">
                  Update your product information
                </div>
                <div className="product-builder" />
                <div className="holding-onboarding">
                  <div className="forms w-form">
                    <form
                      style={{ flexDirection: "column" }}
                      onSubmit={this.handleSubmit}
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                      className="update-form"
                    >
                      <div className="update-form">
                        <div className="update-section">
                          <h4 className="product-section-title">
                            Product&nbsp;Information
                          </h4>
                        </div>
                        <input
                          value={this.state.productName}
                          onChange={this.handleChange}
                          type="text"
                          className="input-text input-half w-input"
                          minLength={1}
                          maxLength={256}
                          name="productName"
                          data-name="productName"
                          placeholder="Enter Product Name"
                          id="productName"
                          required
                        />
                        <MyQuillEditor
                          text={this.state.productDescription}
                          onChange={this.handleEditorChange}
                        />
                        {/* <div className="holder-field describe">
                          <input
                            value={this.state.productDescription}
                            onChange={this.handleChange}
                            type="text"
                            className="input-text w-input"
                            minLength={2}
                            maxLength={250}
                            name="productDescription"
                            data-name="productDescription"
                            placeholder="Edit Description"
                            id="productDescription"
                            required
                          />
                          <div className="meta-additions">
                            <div className="meta-text-limits">0/250</div>
                          </div>
                        </div> */}

                        <div className="mc-input-title-holder">
                            <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                              Stock: <span style={{ color: "#6740aa" }}>{this.state.productStock}</span>
                            </div>
                          <input
                            data-tip="Product Stock"
                            value={this.state.productStock}
                            onChange={this.handleChange}
                            type="number"
                            className="input-text w-input mc-quill-bottom-one"
                            step={1}
                            name="productStock"
                            data-name="productStock"
                            placeholder="Product Stock?"
                            id="productStock"
                            required
                          />
                        </div>

                        <div className="mc-input-title-holder">
                          <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                            Price: <span style={{ color: "#6740aa" }}>{priceFormater(this.state.productPrice, this.state.currency)}</span>
                          </div>
                          <input
                            data-tip="Product Price"
                            value={this.state.productPrice}
                            onChange={this.handleChange}
                            type="number"
                            className="input-text w-input mc-quill-bottom-two"
                            min={0.01}
                            step={0.01}
                            name="productPrice"
                            data-name="productPrice"
                            placeholder="Enter Price"
                            id="productPrice"
                            required
                          />
                        </div>
                        <StatefulMultiSelect
                          className="input-text input-one-third w-select"
                          options={options}
                          selected={this.state.selected}
                          onSelectedChanged={(selected) =>
                            this.selectCategory({ selected })
                          }
                          overrideStrings={{
                            selectSomeItems: "Select Categories (MAX 2)",
                            allItemsAreSelected: "All Items are Selected",
                            selectAll: "",
                            search: "Search",
                          }}
                        />
                        <StatefulMultiSelect
                          className="input-text w-select"
                          options={this.state.storeCatalogs}
                          selected={this.state.selectedCatalogs}
                          onSelectedChanged={(selected) => {
                            this.selectCatalog({ selected });
                          }}
                          overrideStrings={{
                            selectSomeItems: "Select Catalogs",
                            allItemsAreSelected: "All Items are Selected",
                            selectAll: "",
                            search: "Search",
                          }}
                        />
                        {this.state.viewOtherColorInput === true ||
                          this.state.otherColors.length > 0 ? (
                            <div className="holder-field select-color-holder">
                              <div className="meta-additions">
                                <div className="meta-size">Selected Colors:</div>
                              </div>
                              <InputTags
                                tags={this.state.colorTags}
                                // suggestions={this.state.sizeSuggestions}
                                handleDelete={this.handleColorDelete}
                                handleAddition={this.handleColorAddition}
                                handleDrag={this.handleColorDrag}
                                inputFieldClass={this.state.coloredInputField.includes("other-colors") ? "input-text w-select mc-input-highlight blink_me" : "input-text w-select"}
                                placeholder="Other Colors e.g: Burgundy, Beige, etc.. "
                                //customClass="mc-input-with-btn"
                                customId="colors-tag"
                                clickAdd={()=>this.clickAdd("colors-tag", this.handleColorAddition)}
                              />
                            </div>
                          ) : null}

                        <div style={{ minWidth: '100%', paddingBottom: "5px" }} className="microcopy-signup">
                          Colors
                        </div>
                        <div className="holder-field">
                          <div className="meta-additions">
                            <div className="meta-size">Selected Colors:</div>
                          </div>
                          <StatefulMultiSelect
                            className="input-text w-select"
                            options={this.colorOptions}
                            selected={this.state.selectedColors}
                            onSelectedChanged={(selectedColors) =>
                              this.selectColor({ selectedColors })
                            }
                            overrideStrings={{
                              selectSomeItems: "Select all available colors",
                              allItemsAreSelected: "All Items are Selected",
                              selectAll: "",
                              search: "Search",
                            }}
                          />
                        </div>


                        <div style={{ minWidth: '100%', paddingBottom: "5px" }} className="microcopy-signup">
                          Sizes
                        </div>
                        <div
                          style={{ minWidth: "100%" }}
                          className="holder-field select-size-holder"
                        >
                          <div className="meta-additions">
                            <div className="meta-size">Selected Sizes:</div>
                          </div>
                          <InputTags
                            tags={this.state.tags}
                            // suggestions={this.state.sizeSuggestions}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            handleDrag={this.handleDrag}
                            inputFieldClass="input-text w-select"
                            placeholder="e.g: Small, Medium, 10, 12.5 etc..."
                            customClass="mc-input-with-btn"
                            customId="sizes-tag"
                            clickAdd={()=>this.clickAdd("sizes-tag", this.handleAddition)}
                          />

                          {/* <button onClick={("sizes-tag")=>this.clickAdd()} className="add-variant-btn" >Add</button> */}
                        </div>

                        {this.state.extraVariants.map((variant, i) => (
                          <ExtraVariant
                            variant={variant}
                            handleExtraVariantNameChange={this.handleExtraVariantNameChange}
                            handleExtraVariantAddition={this.handleExtraVariantAddition}
                            handleExtraVariantDelete={this.handleExtraVariantDelete}
                            extraVariantsTags={this.state.extraVariantsTags}
                            onChange={this.handleChange}
                            index={i}
                            invalidExtraVariants={this.state.invalidExtraVariants}
                            removeExtraVariant={this.removeExtraVariant}
                            customId="extra-variant-tag-"
                            clickAdd={this.clickAddVariant}
                            key={i}
                          />
                        ))}
                        {this.state.extraVariants.length < 3 ? <div style={{ minWidth: '100%', paddingTop: "10px", display: "flex" }}>
                          <span onClick={() => this.addExtraVariants()} className="mc-variant-button">
                            Add another option</span>
                          <a onClick={() => this.setShowExample(true)} style={{ marginLeft: "auto", paddingTop: "5px", fontSize: "14px", cursor: "pointer", color: "#6740aa" }}>View Example</a>
                        </div> : null}
                        <div style={{ minWidth: '100%', paddingTop: "20px", paddingBottom: "10px" }} className="microcopy-signup">
                          <span className="highlight">Variant Prices (optional)</span>
                          {this.state.editCombinationPrices === true ? <span onClick={() => this.onEditComboPrices(false)} style={{ cursor: "pointer", marginLeft: "20px" }} className="mc-variant-button">
                            Hide</span> :
                            <span onClick={() => this.onEditComboPrices(true)} style={{ cursor: "pointer", marginLeft: "20px" }} className="mc-variant-button">
                              Show</span>}
                        </div>
                        <div style={{ paddingTop: "20px", maxHeight: "80vh", overflow: "scroll" }} className="mc-section-variants">

                          {this.state.editCombinationPrices === true ? <>
                            <div style={{ display: "block", minWidth: "fit-content", columns: variantHeaderNumber + 2, paddingBottom: "5px" }} className="mc-variant-headers">
                              {colorVariantsExist ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Color</div> : null}
                              {sizeVariantsExist ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Size</div> : null}
                              {/* {props.extraVariants.map((variant, i) => (
                          variant.name && props.extraVariantsTags[i]?.length > 0 ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header">{variant.name}</div> : null
                        ))} */}
                              {variantOneExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{this.state.extraVariants[0].name}</div> : null}
                              {variantTwoExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{this.state.extraVariants[1].name}</div> : null}
                              {variantThreeExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{this.state.extraVariants[2].name}</div> : null}
                              {variantExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Price</div> : null}
                              {variantExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Quantity</div> : null}

                            </div>
                            {variantForms} </> : null}
                        </div>
                        <div style={{ width: "100%", minWidth: "100%", display: "flex" }} className="mc-variance-nav">
                          {this.state.startIndex - 100 >= 0 && this.state.editCombinationPrices === true ?
                            <button style={{ marginRight: "auto" }} type="button" onClick={() => prevtVariantPage()}>Previous</button> : null
                          }
                          {combinationLength - this.state.startIndex > 100 && this.state.editCombinationPrices === true ?
                            <button style={{ marginLeft: "auto" }} type="button" onClick={() => nextVariantPage()}>Next</button> : null
                          }

                        </div>
                      </div>
                      <div className="update-form">
                        <div className="update-section">
                          <h4 className="product-section-title">
                            Product Dimensions
                          </h4>
                        </div>
                        <div className="edit-dim">
                        {this.props.userCurrentCountry?.toLowerCase()=="nigeria"?
                        <label className="dim-label">Weight (Grams)</label>: 
                        <label className="dim-label">Weight (Oz)</label>}
                          
                          <input
                            data-tip={this.props.userCurrentCountry?.toLowerCase()=="nigeria"?"":"Weight (oz) - 16 oz = 1 lbs"}
                            value={this.state.productWeight}
                            onChange={this.handleChange}
                            type="number"
                            step="0.001"
                            min="1.2"
                            className="input-text w-input"
                            maxLength={256}
                            name="productWeight"
                            data-name="productWeight"
                            placeholder="Weight"
                            id="productWeight"
                            required
                          />
                        </div>

                        <div className="edit-dim">
                        {this.props.userCurrentCountry?.toLowerCase()=="nigeria"?
                        <label className="dim-label">Width (CM)</label>: 
                        <label className="dim-label">Width (Inches)</label>}
                          
                          <input
                            data-tip="Width in Inches"
                            value={this.state.productWidth}
                            onChange={this.handleChange}
                            type="number"
                            step="0.001"
                            className="input-text w-input"
                            maxLength={256}
                            name="productWidth"
                            data-name="productWidth"
                            placeholder="Width"
                            id="productWidth"
                            required
                          />
                        </div>

                        <div className="edit-dim">
                        {this.props.userCurrentCountry?.toLowerCase()=="nigeria"?
                        <label className="dim-label">Height (CM)</label>: 
                        <label className="dim-label">Height (Inches)</label>}
                          
                          <input
                            data-tip="Height"
                            value={this.state.productHeight}
                            onChange={this.handleChange}
                            type="number"
                            step="0.001"
                            className="input-text w-input"
                            maxLength={256}
                            name="productHeight"
                            data-name="productHeight"
                            placeholder="Height"
                            id="productHeight"
                            required
                          />
                        </div>

                        <div className="edit-dim">
                        {this.props.userCurrentCountry?.toLowerCase()=="nigeria"?
                        <label className="dim-label">Length (CM)</label>: 
                        <label className="dim-label">Length (Inches)</label>}
                          <input
                            data-tip="Length"
                            value={this.state.productLength}
                            onChange={this.handleChange}
                            type="number"
                            step="0.001"
                            className="input-text w-input"
                            maxLength={256}
                            name="productLength"
                            data-name="productLength"
                            placeholder="Length"
                            id="productLength"
                            required
                          />
                        </div>
                      </div>
                      <div className="update-form">
                        <div className="update-section">
                          <h4 className="product-section-title">Main Image</h4>
                        </div>
                        <div className="product-image box-quarter">
                          <div
                            style={{
                              backgroundImage: this.state.featuredImage
                                ? `url(${this.state.featuredImage})`
                                : "https://via.placeholder.com/200",
                            }}
                            className="product-img-holder"
                          />
                          <div className="input-upload w-embed">
                            <input
                              onChange={this.handleFeaturedImageChange}
                              type="file"
                              id="myFile"
                              name="featuredImage"
                            />
                            <div
                              onClick={() =>
                                this.removeFeaturedImage(
                                  this.state.featuredImage,
                                  6
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#6740aa",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="update-form">
                        <div className="update-section">
                          <h4 className="product-section-title">
                            Product Images
                          </h4>
                        </div>
                        <div className="product-image box-quarter">
                          <div
                            style={{
                              backgroundImage: this.state.productImages[0]
                                ? `url(${this.state.productImages[0]})`
                                : "https://via.placeholder.com/200",
                            }}
                            className="product-img-holder"
                          />
                          <div className="input-upload w-embed">
                            <input
                              onChange={this.handleImageChange}
                              type="file"
                              id="myFile"
                              name="img2"
                            />
                            <div
                              onClick={() =>
                                this.props.removeImage(
                                  this.state.productImages[0],
                                  0
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#6740aa",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </div>
                          </div>
                        </div>
                        <div className="product-image box-quarter">
                          <div
                            style={{
                              backgroundImage: this.state.productImages[1]
                                ? `url(${this.state.productImages[1]})`
                                : "https://via.placeholder.com/200",
                            }}
                            className="product-img-holder"
                          />
                          <div className="input-upload w-embed">
                            <input
                              onChange={this.handleImageChange}
                              type="file"
                              id="myFile"
                              name="img3"
                            />
                            <div
                              onClick={() =>
                                this.props.removeImage(
                                  this.state.productImages[1],
                                  1
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#6740aa",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </div>
                          </div>
                        </div>
                        <div className="product-image box-quarter">
                          <div
                            style={{
                              backgroundImage: this.state.productImages[2]
                                ? `url(${this.state.productImages[2]})`
                                : "https://via.placeholder.com/200",
                            }}
                            className="product-img-holder"
                          />
                          <div className="input-upload w-embed">
                            <input
                              onChange={this.handleImageChange}
                              type="file"
                              id="myFile"
                              name="img4"
                            />
                            <div
                              onClick={() =>
                                this.props.removeImage(
                                  this.state.productImages[2],
                                  2
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#6740aa",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </div>
                          </div>
                        </div>
                        <div className="product-image box-quarter">
                          <div
                            style={{
                              backgroundImage: this.state.productImages[3]
                                ? `url(${this.state.productImages[3]})`
                                : "https://via.placeholder.com/200",
                            }}
                            className="product-img-holder"
                          />
                          <div className="input-upload w-embed">
                            <input
                              onChange={this.handleImageChange}
                              type="file"
                              id="myFile"
                              name="img5"
                            />
                            <div
                              onClick={() =>
                                this.props.removeImage(
                                  this.state.productImages[3],
                                  3
                                )
                              }
                              style={{
                                cursor: "pointer",
                                color: "#6740aa",
                                fontSize: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        type="submit"
                        defaultValue="Update Product"
                        data-wait="Please wait..."
                        className="submit-primary w-button"
                      />
                      <div
                        style={{
                          marginTop: "10px",
                          display: "flex",
                          minWidth: "100%",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => this.props.closeModal()}
                        >
                          Cancel
                        </div>
                      </div>
                    </form>

                    {/* <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
