import React from "react";
let date = new Date();
const Seller = (props) => (
  <div className="seller">
    <div className="chat-name">{props.storeName}</div>
    <p className="user-chat">{props.messageBody}</p>
    <div className="chat-time">{props.time}</div>
  </div>
);

const Client = (props) => (
  <div className="client">
    <div className="chat-name">Cashaam - BOT</div>
    <p className="user-chat">{props.messageBody}</p>
    <div className="chat-time">{props.time}</div>
  </div>
);

const ChatWindow = (props) => (
  <div className="chat-window">
    <div className="chat-messages">
      <div className="chat-header">
        <div className="chat-avatar-2" />
        <div className="chat-user">{props.userName}</div>
        <div className="chat-date-in">
          Today:{" "}
          {date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear()}
        </div>
      </div>
      <div className="chat-body">
        {props.messages ? (
          props.messages.map((msg) =>
            msg.To !== props.phoneNumber ? (
              <Client
                time={msg.timestamp.toDate().toString()}
                messageBody={msg.content}
              />
            ) : (
              <Seller
                storeName={props.storeName}
                messageBody={msg.content}
                time={msg.timestamp.toDate().toString()}
              />
            )
          )
        ) : (
          <div>No messages as of now.</div>
        )}
      </div>
    </div>
  </div>
);
export default ChatWindow;
