import React from 'react';
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import {
  saveDiscount,
  getDiscounts,
  deleteDiscount,
} from "../../../helpers/discount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddDiscountForm from "./AddDiscountForm";
import firebase from '../../../../firebaseConfig';
import AddStoreDiscount from './AddStoreDiscount';
import getStoreDiscount from '../../../helpers/getStoreDiscount';
import addStoreDiscount from '../../../helpers/addStoreDiscount';
import deleteStoreDiscount from '../../../helpers/deleteStoreDiscount';
import getUserLocation from '../../../helpers/getUserLocation';
import getUnreadChat from "../../../helpers/getUnreadChat";
import getBasicInfo from '../../../helpers/getBasicInfo';
import NProgress from 'nprogress';
class Discount extends React.Component {
  state = {
    sellerId: "",
    storeDiscount: {
      discountName: "",
      discountValue: 0,
      discountType: "percent"
    },
    storeDiscountExists: false,
    discount: {
      discountCode: "",
      discountValue: 0,
      discountType: "percent"
    },
    discounts: [],
    errorMessage: "",
    userCurrentCountry: null
  };

  showErrorMessage = (message, time) => {
    this.setState({ errorMessage: message });
    let duration = time ? time : 10000
    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, duration);
  };

  handleDelete = async (discount) => {
    try {
      const discounts = await deleteDiscount(discount);
      this.setState({
        discounts,
      });
    } catch (err) {
      console.error("An error occured when deleting discounts");
    }
  };

  handleChange = (e) => {
    let discount = this.state.discount;
    if (e.target.name === "discountCode") {
      if (/\s/g.test(e.target.value)) {
        //avoid spaces in discount code
        return this.showErrorMessage("No Spaces allowed")
      }
    }

    discount[e.target.name] = e.target.value;
    this.setState({
      discount
    });
    console.log("state", this.state);
  };

  handleChangeStoreDiscount = (e) => {
    let storeDiscount = this.state.storeDiscount;

    storeDiscount[e.target.name] = e.target.value;
    this.setState({
      storeDiscount
    });
    console.log("state", this.state);
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let discountValue = parseFloat(this.state.discount.discountValue);
      let discountType = this.state.discount.discountType;
      if (!discountValue) {
        return this.showErrorMessage("Discount value must be a number");
      }

      if(!discountType || discountType==="") {
        discountType = "percent";
      }

      let currDiscount = this.state.discount;
      currDiscount["discountValue"] = discountValue;
      currDiscount["discountType"]=discountType;
      console.log("currDiscount discount",currDiscount)
      const discounts = await saveDiscount(currDiscount);
      this.setState({
        discounts, discount: {
          discountCode: "",
          discountValue: 0,
          discountType: "percent"
        }
      });
    } catch (error) {
      console.error("Error occurred when saving discount", error);
      this.setState({ discounts: [] });
    }
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  handleSubmitStoreDiscount = async (e) => {
    e.preventDefault();
    try {
      let { sellerId } = this.state;
      let discountValue = parseFloat(this.state.storeDiscount.discountValue);
      let discountName = this.state.storeDiscount.discountName.toUpperCase();
      let discountType =this.state.storeDiscount.discountType;

      if(!discountType || discountType==="") {
        discountType = "percent";
      }

      if (!discountValue) {
        return this.showErrorMessage("Discount value must be a number");
      }
      let currDiscount = this.state.storeDiscount;
      currDiscount["discountValue"] = discountValue;
      currDiscount["discountName"] = discountName;
      currDiscount["discountType"] = discountType;
      console.log("currDiscount",currDiscount);
      await addStoreDiscount(sellerId, currDiscount.discountName, currDiscount.discountValue, currDiscount.discountType);
      this.setState({ storeDiscountExists: true, storeDiscount: currDiscount });
    } catch (error) {
      console.error("Error occurred when saving store discount", error);
      this.showErrorMessage("Error occurred when saving store discount");
      //   this.setState({ storeDiscount: {} });
    }
  };

  handleDeleteStoreDiscount = async () => {
    try {
      let { sellerId } = this.state;
      await deleteStoreDiscount(sellerId);
      this.setState({
        storeDiscount: {
          discountName: "",
          discountValue: 0,
          discountType: "percent"
        },
        storeDiscountExists: false
      });
    } catch (err) {
      console.error("An error occured when deleting discounts");
    }
  };
  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log({ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  componentDidMount() {
    NProgress.start();
    // check if seller is logged in
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let userCountry=this.getLocation();
        const storeInfo = await getBasicInfo(user.uid);
        this.setState({
          storeInfo,
          sellerId: user.uid
        })
        this.setState({  })
        NProgress.set(0.5);
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );

        const storeDiscount = await getStoreDiscount(user.uid);
        console.log("store dis", storeDiscount)
        const discounts = await getDiscounts();
        this.setState({ 
          discounts: discounts ? discounts : [],
          storeDiscount: storeDiscount ? storeDiscount : { discountName: "", discountValue: 0, discountType: "percent" }, 
          storeDiscountExists: storeDiscount ? true : false });
        NProgress.done();
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <ToastContainer />
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            <div className="panel-center">
              <div className="page-header">
                <h4 className="header-title">Store Discounts</h4>

              </div>
              <AddStoreDiscount
                userCountry={this.state.userCurrentCountry}
                discount={this.state.storeDiscount}
                handleChange={this.handleChangeStoreDiscount}
                handleSubmit={this.handleSubmitStoreDiscount}
                handleDelete={this.handleDeleteStoreDiscount}
                errorMessage={this.state.errorMessage}
                storeDiscountExists={this.state.storeDiscountExists}
              />
              <AddDiscountForm
              userCountry={this.state.userCurrentCountry}
                handleChange={this.handleChange}
                discount={this.state.discount}
                discounts={this.state.discounts}
                handleSubmit={this.handleSubmit}
                handleDelete={this.handleDelete}
                errorMessage={this.state.errorMessage}
              /> </div>
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="addProduct"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
export default Discount;