import React from 'react';
import {NAIRA_EXCHANGE, stripeCheckout} from "../../../../secrets";

function LitePlan(props) {
    return (
        <div
        className={`pricing-redesign__pricing-card-body primary ${props.activeTab==props.period?"tab-active":"tab-inactive"}`}
        data-pricing-card="basic"
      >
        {props.downgrade?
          <span className="pricing-redesign__highlight">Downgrade To Personal Account</span>
          :
          props.paymentMethod == "stripe"?
          <span className="pricing-redesign__highlight">Virtual Card</span>
          :
          <span className="pricing-redesign__highlight">Most Popular</span>
        }
        <div style={{borderTopRightRadius: "0",borderTopLeftRadius: "0"}} className="pricing-redesign__pricing-card pricing-redesign__pricing-card--narrow">
          <div className="pricing-redesign__content-container">
            <div className="main-content">
              <h3 className="title">Card Application Fee</h3>
              <p className="description description--narrow">
                Unlimited Virtual Dollar Card to Make Payments Anywhere in the World.
              </p>
            </div>
            <div className="pricing-content pricing-content__monthly">
              <span aria-hidden="true">&nbsp;</span>
              <p
                aria-hidden="true"
                className="pricing-redesign__price-container"
              >
                {props.downgrade || props.paymentMethod == "stripe"?
                <>
                <sup style={{color: "#119111"}} className="currency">USD</sup>
                <sup style={{color: "#119111"}} className="currency-symbol">$</sup>
                </>
                // null
                :
                  <>
                  <sup className="currency">NGN</sup>
                  <sup className="currency-symbol">₦</sup>
                  </>
                }
                {props.downgrade || props.paymentMethod == "stripe"?
                <span style={{color: "#119111"}} className="price heading--1">3</span>
                :
                <span className="price heading--1">1,000</span>
                }
                {props.downgrade || props.paymentMethod == "stripe"?
                  <sub style={{color:"#119111"}} className="per-month">Onces</sub>
                  :
                  <sub className="per-month">Once</sub>
                }
              </p>
              
            </div>
          </div>
          <hr className="divider" />
          <div className="pricing-redesign__info-section pricing-redesign__info-section--narrow">
            <div className="pricing-redesign__cc-rates pricing-redesign__cc-rates--narrow">
              <div className="static-cc-rates">
                <strong>Exchange Rates and Fees</strong>
                <div className="pricing-redesign__feature-block">

                {props.paymentMethod == "paystack"?
                  <span className="pricing-redesign__feature-item rate-fees">
                    <img 
                        className="sub-icons"
                        src="https://res.cloudinary.com/cashaam/image/upload/v1666055116/icons/conversion_dnzdl7.webp" 
                    />
                        <span className="span-text">₦{NAIRA_EXCHANGE} to $1</span>
                  </span>
                  :null}
                  <span className="pricing-redesign__feature-item rate-fees">
                  <img 
                    className="sub-icons"
                    src="https://res.cloudinary.com/cashaam/image/upload/v1666055118/icons/commission_fz7qnc.webp" 
                  />
                    <span className="span-text">3.5% per card topup</span>
                  </span>
                  
                  
                  
                </div>
              </div>

            </div>
            <div 
              style={{height: props.paymentMethod == "paystack"?"150px":"140px"}} 
              className="pricing-redesign__included-features"
            >
              <strong>What’s included on this plan:</strong>
              <div className="feature-wrap">
                <ul className="static-feature-block pricing-redesign__feature-block">
                  <li className="pricing-redesign__feature-item">

                    <span className="span-text">Virtual Card for Payments</span>
                  </li>
                  {props.paymentMethod == "paystack"?
                  <li className="pricing-redesign__feature-item">

                    <span  className="span-text">Apple & Google Pay</span>
                  </li>
                  :null}
                  {props.paymentMethod == "paystack"?
                  <li className="pricing-redesign__feature-item">

                    <span  className="span-text">Weekly Discounts & Coupons</span>
                  </li>
                  :null}
                  <li className="pricing-redesign__feature-item">

                    <span  className="span-text">Unlimited and Global Payment Card</span>
                  </li>
                  <li className="pricing-redesign__feature-item">

                    <span  className="span-text">Unlimited Virtual Card Top Up</span>
                  </li>

                </ul>
              </div>
            </div>

            {props.paymentMethod == "paystack" ? 
            <>
            {props.downgrade?

              <div className={`${props.freeTrial?"extra-height":"regular-height"}`} 
                    onClick={()=>props.toggleDowngrade(true)}
              >
                  <button
                      type="submit"
                      className={`marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button sub-${props.period}`}
                      data-id="monthly"
                  >
                      Downgrade to Personal
                  </button>
              </div>
              :


              <div className={`${props.freeTrial?"extra-height":"regular-height"}`} 
                    onClick={()=>props.paystackOnetimePay()}
              >
                  <button
                      type="submit"
                      className={`marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button sub-${props.period}`}
                      data-id="monthly"
                  >
                      Get Started
                  </button>
              </div>
            }
            </>
            :
              <form
                action={stripeCheckout}
                method="POST"
              >
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="priceId"
                  name="priceId"
                  value={props.priceId}
                  readOnly
                />
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="email"
                  name="email"
                  value={props.email}
                  readOnly
                />
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="slug"
                  name="slug"
                  value={props.storeInfo?.slug}
                  readOnly
                />
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="sellerId"
                  name="sellerId"
                  value={props.storeInfo?.id}
                  readOnly
                />
                <input
                  style={{ display: "none" }}
                  type="text"
                  id="eventType"
                  name="eventType"
                  value={props.eventType}
                  readOnly
                />
                <button
                  type="submit"
                  className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button`}
                  data-id="monthly"
                >
                  Get Started
                </button>
              </form>

              // <div className="regular-height" 
              //   onClick={()=>props.stripePay(props.priceId)}
              //   >
              //   <button
              //     type="submit"
              //     className={`marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button sub-${props.period}`}
              //     data-id="monthly"
              //   >
              //     Get Started
              //   </button>
              //   </div>

              // <div
              //   onClick={()=>props.activateLite()}
              //   type="submit"
              //   className={`${props.period=="Quarterly"?"":"marketing-button--secondary"} marketing-button js-open-signup start-free-trial__button pricing-redesign__cta-button`}
              //   data-id="monthly"
              // >
              // Get Started for FREE
              // </div>
            }

          </div>
        </div>
        <div className="pricing-redesign__card-extension" />

      </div>
    );
}

export default LitePlan;