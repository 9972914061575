import React from 'react'
import { Link } from "react-router-dom";

import Service from './Service'


const Services = (props) => {
    return (
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Services</h4>
                </div>
                <div className="main">
                    <div className="container-products">
                        <div className="product-header">
                            <h5 className="setting-title">My Services</h5>
                            <div className="div-block" />
                            <Link
                                to="/seller/dashboard/service/addService"
                                className="box-btn-primary w-inline-block"
                            >
                                <div className="box-btn-text-primary">Add Service</div>
                            </Link>
                        </div>
                        <div className="product-holder">
                            {
                                props.services.map((service) => {
                                    return (
                                        <Service
                                            key={service.id}
                                            service={service}
                                            editService={props.onEditService}
                                            deleteService={props.onDeleteService}
                                            // name={service.name}
                                            // duration={service.duration}
                                            // image={service.images.length > 0 ? service.images[0] : null}
                                            // price={service.price}
                                            // duration={service.duration}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Services
