import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import API_KEY from '../../../../secrets';
import ReactTooltip from "react-tooltip";

const ManualScheduleShip = (props) => {

    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        if (props.isScriptLoadSucceed) {
            setScriptLoaded(true)
        }
    }, [props.isScriptLoaded])
    return (scriptLoaded === true ? <>
        <ReactTooltip />

        <div style={{ display: "flex" }} className="tint-background">
            <div className="pop-container">
                <div style={{ display: "flex", maxHeight: "90vh", overflow: "scroll" }} className="pop-us-ship-label">
                    <div className="pop-header">
                        <div className="text-main">Edit Shipping Destination</div>
                        {props.errorCreatingShippingLabel === true ? <a
                            className="box-btn-primary w-inline-block"
                        >
                            <div style={{ borderColor: "red" }} className="btn-text-primary-outline processed">
                                Error Printing Label
              </div>
                        </a> :
                            props.successfulReportSent === true ? <a
                                className="box-btn-primary w-inline-block"
                            >
                                <div className="btn-text-primary-outline processed">
                                    Report Sent to Cashaam
            </div>
                            </a> :
                                null}
                    </div>
                    <div className="pop-content">
                        <div className="sub-title">
                            <div style={{ fontWeight: "normal", marginBottom: "10px" }} >TransactionId: {props.order.transactionId}</div>
                            <div>Customer</div>
                            <div style={{ fontWeight: "normal", paddingRight: "5px" }}>Name: {props.order.customerName}</div>
                            <div style={{ fontWeight: "normal", paddingRight: "5px" }}>Email: <a href={`mailto:${props.order.email}`}>{props.order.email}</a></div>
                            <div style={{ fontWeight: "normal", paddingRight: "5px" }}>Phone Number: <a href={`tel:+${props.order.deliveryPhoneNumber}`}>{props.order.deliveryPhoneNumber}</a></div>
                            {props.errorCreatingShippingLabel === true && props.successfulReportSent !== true ? <>
                                <div style={{ textAlign: "center", fontSize: "16px", color: "red" }}>
                                    Error creating Shipping Label. </div>
                                <div style={{ textAlign: "center" }}> If you believe the information below is correct, <a onClick={() => props.reportUnsuccessfulLabelPrint(props.order)} style={{ fontWeight: "bold", color: "#6942ab", cursor: "pointer" }}>Click here</a> to report the issue to Cashaam.
              </div></> :

                                props.successfulReportSent === true ?
                                    <div style={{ fontWeight: "bold", textAlign: "center", color: "#6740aa", fontSize: "16px" }}>Thanks for reporting this issue to Cashaam. Our team will review the order information and help you as soon as possible.</div>
                                    : null}

                        </div>
                        <form
                            className="update-form">

                            <div style={{ marginTop: "10px" }} className="mc-form-holder">
                                <div className="sub-title">
                                    New Delivery Address </div>

                                <PlacesAutocomplete
                                    value={props.address || ""}
                                    onChange={props.setAddress}
                                    onSelect={props.handleSelectAddress}
                                    shouldFetchSuggestions={props.address ? props.address.length > 3 : false}
                                    onError={props.onError}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <>

                                            <input
                                                {...getInputProps({
                                                    placeholder: "",
                                                })}
                                                data-name="address"
                                                maxLength="5000"
                                                id="address"
                                                name="address"
                                                placeholder="street"
                                                required="required"
                                                className="input-text w-input mc-input"
                                            ></input>
                                            <div
                                                className="mc-suggestion-holder"

                                            >
                                                {loading ? <div>...loading</div> : null}

                                                {suggestions.map((suggestion, i) => {
                                                    const style = {
                                                        backgroundColor: suggestion.active
                                                            ? "#d0bdf0"
                                                            : "white",
                                                        borderBottom:
                                                            "2px solid rgb(219, 219, 219)",
                                                        borderTop:
                                                            i > 0
                                                                ? "0.5px solid rgb(219, 219, 219)"
                                                                : null,
                                                        cursor: "pointer",
                                                        padding: "5px",
                                                        // boxShadow: "0px 0px 2px",
                                                    };

                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                style,
                                                            })}
                                                            key={i}
                                                        >
                                                            {suggestion.description}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </>
                                    )}
                                </PlacesAutocomplete>
                                <input onChange={props.handleChange} className="mc-input input-text w-input"
                                    placeholder="apt" name="apt" value={props.apt && props.apt !== "N/A" ? props.apt : ""} />
                                <input onChange={props.handleChange} className="mc-input input-text w-input"
                                    placeholder="city" name="city" value={props.city} />
                                <input onChange={props.handleChange} className="mc-input input-text w-input"
                                    placeholder="state" name="state" value={props.state} />
                                <input onChange={props.handleChange} className="mc-input input-text w-input"
                                    placeholder="country" name="country" value={props.country} />

                            </div>

                            {props.tracking_number ?
                                <div
                                    style={{ backgroundColor: "green", backgroundImage: "none" }}
                                    className="btn-primary"
                                >
                                    <div className="text-btn-primary">Pickup Scheduled</div>
                                </div> :
                                props.loading === true ? (
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            background: "none",
                                            backgroundColor: "grey",
                                        }}
                                        className="btn-primary"
                                    >
                                        <div className="text-btn-primary">
                                            Scheduling Pickup..
                </div>
                                    </div>
                                ) : (

                                        <input
                                            onClick={() => props.onConfirm()}
                                            style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                                            value="Schedule Pickup"
                                            type="button"
                                            className="btn-primary"
                                        />
                                    )}
                        </form>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => props.showManualScheduleShip(false)}
                        className="btn-secondary"
                    >
                        <div className="text-btn-tertiary">Close</div>
                    </div>
                </div>
            </div>
        </div> </> : <div>Loading...</div>
    );
};
// export default ManualScheduleShip;
export default scriptLoader([
    `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&region=NG`,
])(ManualScheduleShip);