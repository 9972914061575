import React from 'react';

function UserEarnings(props) {
    return (
         <div className="section mt-60">
          <div className="section__header mb-30 earnings-ch">
            <div className="section__title padded-title">Successful Referrals</div>
          </div>
          <div className="affiliate-earning">
            <div className="affiliate-earning__wrapper">
              <div className="affiliate-earning__left">
                <div className="affiliate-card">
                  <div className="affiliate-card__body">
                    <div className="affiliate-card__title has-tooltip">
                      Subscribed User
                      {/* <i className="fd-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                          <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                          <circle cx="7.9" cy="11.4" r="0.7" />
                        </svg>
                      </i> */}
                    </div>
                    <div className="affiliate-card__value">{props.successfullReferral}</div>
                  </div>
                </div>
              </div>
              <div className="affiliate-earning__right">
                <div style={{paddingBottom:"40px"}} className="affiliate-card">
                  <div className="affiliate-card__body">
                    <div className="affiliate-card__title has-tooltip">
                        Reward Amount
                      {/* <i className="fd-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          fill="currentColor"
                        >
                          <path d="M8 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7zM8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" />
                          <path d="M7.9 10c-.3 0-.5-.2-.5-.5 0-.6.3-1.2.7-1.6.2-.2.4-.3.6-.5.5-.4.8-.6.9-1.1V6c-.1-.7-.8-1.2-1.5-1.1-.8 0-1.4.4-1.7 1.1-.1.2-.4.3-.7.1-.2-.1-.3-.4-.2-.6.5-1 1.4-1.6 2.5-1.6 1.2-.1 2.4.8 2.5 2.1v.5c-.2.9-.8 1.4-1.3 1.7-.1.1-.3.3-.5.4-.2.2-.3.5-.3.9 0 .3-.3.5-.5.5z" />
                          <circle cx="7.9" cy="11.4" r="0.7" />
                        </svg>
                      </i> */}
                    </div>
                    {props.country?.toLowerCase()=="nigeria"?
                      parseFloat(props.nigeriaReward)<0?
                      <>
                        <div className="affiliate-card__value">-₦{Math.abs(props.nigeriaReward).toLocaleString("en-US")}</div>
                        <div style={{marginTop: "15px"}} className="affiliate-card__title has-tooltip">
                          You've overdrawn on your reward account 
                        </div>
                        <div className="affiliate-card__title has-tooltip">
                          No worries! Just keep referring us to others
                        </div>
                      </>
                      :
                      <>
                        <div className="affiliate-card__value">₦{props.nigeriaReward}</div>
                      </>
                    :
                    <div className="affiliate-card__value">${props.rewardAmount}</div>
                    }

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default UserEarnings;