import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import API_KEY from '../../../../secrets';
import ReactTooltip from "react-tooltip";

const ManualShippingPop = (props) => {

  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (props.isScriptLoadSucceed) {
      setScriptLoaded(true)
    }
  }, [props.isScriptLoaded])
  return (scriptLoaded === true ? <>
    <ReactTooltip />

    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container">
        <div style={{ display: "flex", maxHeight: "90vh", overflow: "scroll" }} className="pop-us-ship-label">
          <div className="pop-header">
            <div className="text-main">Create Shipping Label</div>
            {props.errorCreatingShippingLabel === true ? <a
              className="box-btn-primary w-inline-block"
            >
              <div style={{ borderColor: "red" }} className="btn-text-primary-outline processed">
                Error Printing Label
              </div>
            </a> :
              props.successfulReportSent === true ? <a
                className="box-btn-primary w-inline-block"
              >
                <div className="btn-text-primary-outline processed">
                  Report Sent to Cashaam
            </div>
              </a> :
                null}
          </div>
          <div className="pop-content">
            <div className="sub-title">
              Before you create your shipping label here’s a few things to note:
            </div>
            <div className="pop-text">
              1. Double check to make sure that the addresses are correct by doing one of the following:
              <br />

              <ul>
                <li>Use the <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">Smarty Streets Tool</a> to validate the addresses. <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">Click here</a></li>
                <li>You may also reach out to your customer to confirm their address:
                  <ul>
                    <li><span style={{ fontWeight: "bold" }}>Name:</span> {props.order.customerName}</li>
                    <li><span style={{ fontWeight: "bold" }}>Phone:</span> <a href={`tel:+${props.order.phoneNumber}`}>{props.order.phoneNumber}</a></li>
                    <li><span style={{ fontWeight: "bold" }}>Email:</span> <a href={`mailto:${props.order.email}`}>{props.order.email} </a></li>
                  </ul> </li>
              </ul>
              2. Check to make sure that the parcel information is accurate. Things to Keep in mind about parcel dimensions:

              <ul>
                <li>Parcel dimensions directly affect shipping availability and price.</li>
                <li>For a <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">USP Guide</a> to measuring package size. <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">Click here</a></li>
              </ul>
              <br />

              {props.errorCreatingShippingLabel === true && props.successfulReportSent !== true ? <>
                <div style={{ textAlign: "center", fontSize: "16px", color: "red" }}>
                  Error creating Shipping Label. </div>
                <div style={{ textAlign: "center" }}> If you believe the information below is correct, <a onClick={() => props.reportUnsuccessfulLabelPrint(props.order)} style={{ fontWeight: "bold", color: "#6942ab", cursor: "pointer" }}>Click here</a> to report the issue to Cashaam.
              </div></> :

                props.successfulReportSent === true ?
                  <div style={{ fontWeight: "bold", textAlign: "center", color: "#6740aa", fontSize: "16px" }}>Thanks for reporting this issue to Cashaam. Our team will review the order information and help you as soon as possible.</div>
                  : null}

            </div>
            <form
              className="update-form"
              onSubmit={props.createLabel}>
              <div className="mc-form-holder">
                <div className="sub-title">
                  Your Address </div>

                {/* <input className="input-text w-input"
                  placeholder="apt" name="sellerApt" value={props.sellerApt} /> */}
                <PlacesAutocomplete
                  value={props.sellerAddress || ""}
                  onChange={props.setSellerAddress}
                  onSelect={props.handleSelectSellerAddress}
                  shouldFetchSuggestions={props.sellerAddress.length > 3}
                  onError={props.onError}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      {/* <label htmlFor="address" className="label-2x">
                                Business Address{" "}
                                <span className="required">*</span>
                                {props.invalidAddress === true ? (
                                  <span style={{ color: "red" }}>
                                    Invalid Address. Please try again
                                  </span>
                                ) : null}
                              </label> */}
                      <input
                        {...getInputProps({
                          placeholder: "",
                        })}
                        data-name="address"
                        maxLength="5000"
                        id="address"
                        name="address"
                        placeholder="street"
                        required="required"
                        className="input-text w-input mc-input"
                      ></input>
                      <div
                        className="mc-suggestion-holder"

                      // style={{
                      //   position: "absolute",
                      //   zIndex: 99,
                      //   maxWidth: "30%",
                      //   // borderLeft: "2px solid rgb(219, 219, 219)",
                      //   // borderRight: "2px solid rgb(219, 219, 219)",
                      //   boxShadow: "0px 0px 2px",
                      // }}
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion, i) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#d0bdf0"
                              : "white",
                            borderBottom:
                              "2px solid rgb(219, 219, 219)",
                            borderTop:
                              i > 0
                                ? "0.5px solid rgb(219, 219, 219)"
                                : null,
                            cursor: "pointer",
                            padding: "5px",
                            // boxShadow: "0px 0px 2px",
                          };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                              key={i}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="city" name="sellerCity" value={props.sellerCity} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="state" name="sellerState" value={props.sellerState} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="country" name="sellerCountry" value={props.sellerCountry} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="zip" name="sellerZipcode" value={props.sellerZipcode} />

              </div>


              <div className="mc-form-holder">
                <div className="sub-title">
                  Buyer's Address </div>
                
                <PlacesAutocomplete
                  value={props.address || ""}
                  onChange={props.setAddress}
                  onSelect={props.handleSelectAddress}
                  shouldFetchSuggestions={props.address.length > 3}
                  onError={props.onError}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      {/* <label htmlFor="address" className="label-2x">
                                Business Address{" "}
                                <span className="required">*</span>
                                {props.invalidAddress === true ? (
                                  <span style={{ color: "red" }}>
                                    Invalid Address. Please try again
                                  </span>
                                ) : null}
                              </label> */}
                      <input
                        {...getInputProps({
                          placeholder: "",
                        })}
                        data-name="address"
                        maxLength="5000"
                        id="address"
                        name="address"
                        placeholder="street"
                        required="required"
                        className="input-text w-input mc-input"
                      ></input>
                      <div
                        className="mc-suggestion-holder"
                      // style={{
                      //   position: "absolute",
                      //   zIndex: 99,
                      //   maxWidth: "30%",
                      //   // borderLeft: "2px solid rgb(219, 219, 219)",
                      //   // borderRight: "2px solid rgb(219, 219, 219)",
                      //   boxShadow: "0px 0px 2px",
                      // }}
                      >
                        {loading ? <div>...loading</div> : null}

                        {suggestions.map((suggestion, i) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#d0bdf0"
                              : "white",
                            borderBottom:
                              "2px solid rgb(219, 219, 219)",
                            borderTop:
                              i > 0
                                ? "0.5px solid rgb(219, 219, 219)"
                                : null,
                            cursor: "pointer",
                            padding: "5px",
                            // boxShadow: "0px 0px 2px",
                          };

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                              key={i}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="apt" name="apt" value={props.apt && props.apt !== "N/A" ? props.apt : ""} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="city" name="city" value={props.city} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="state" name="state" value={props.state} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="country" name="country" value={props.country} />
                <input onChange={props.handleChange} className="mc-input input-text w-input"
                  placeholder="zip" name="zipcode" value={props.zipcode} />
              </div>



              <div className="mc-form-holder">

                <div className="sub-title">
                  Parcel Dimensions
                </div>
                <label style={{fontWeight: "500"}} className="mc-label">Weight</label>
                <input data-tip="Weight in oz (16 oz = 1 lbs)"
                  onChange={props.handleChange} value={parseFloat(props.productWeight)} className="mc-input input-text w-input" type="number"
                  step="0.001"
                  min="1.2"
                  max={parseFloat(props.productWeight) > 1.2 ? props.productWeight : 1.2}
                  placeholder="Weight in oz" name="productWeight" />

                <label style={{fontWeight: "500"}} className="mc-label">Width</label>
                <input data-tip="Width in Inches" onChange={props.handleChange} value={parseFloat(props.productWidth)} className="mc-input input-text w-input"
                  placeholder="Width in Inches" type="number"
                  step="0.001" name="productWidth" />

                <label style={{fontWeight: "500"}} className="mc-label">Height</label>
                <input data-tip="Height in Inches" onChange={props.handleChange} value={parseFloat(props.productHeight)} className="mc-input input-text w-input"
                  placeholder="Height in Inches" type="number"
                  step="0.001" name="productHeight" />

                <label style={{fontWeight: "500"}} className="mc-label">Length</label>
                <input data-tip="Length in Inches" onChange={props.handleChange} value={parseFloat(props.productLength)} className="mc-input input-text w-input"
                  placeholder="Length in Inches" type="number"
                  step="0.001" name="productLength" />
              </div>
              {props.errorCreatingShippingLabel === true ?
                <div style={{ borderColor: "#ff8c00", borderWidth: "2px", fontSize: "14px" }} className="tracking-box">
                  <div>
                    <div style={{ fontWeight: "bold" }}>Error Printing Shipping Label. Please fix the following problems: </div>
                    {props.printShippingLabelErrors.map((message, i) => (
                      <div key={i}>{`${i + 1}. ${message.text}`}</div>
                    ))}
                    <br />
                    Please <a target="_blank" href="https://support.cashaam.com">Contact Us</a> if this problem persists.</div>
                </div> : null}
              {props.loading === true ? (
                <div
                  style={{
                    cursor: "pointer",
                    background: "none",
                    backgroundColor: "grey",
                  }}
                  className="btn-primary"
                >
                  <div className="text-btn-primary">
                    Getting Shipping Labels...
                </div>
                </div>
              ) : (
                  props.shippingLink ? <a
                    href={props.shippingLink}
                    target="_blank"
                    style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                    className="btn-primary"
                  >View Label </a> :
                    <input
                      style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                      value="Get Shipping Label"
                      type="submit"
                      className="btn-primary"
                    />
                )}
            </form>
          </div>

          <div className="pop-footer">

            {props.errorCreatingShippingLabel === true && props.successfulReportSent !== true ? <div
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => props.reportUnsuccessfulLabelPrint(props.order)}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Still having problems? Report to Cashaam</div>
            </div> : null}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.onDisplayManualShipping(false)}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Close</div>
            </div>
          </div>
        </div>
      </div>
    </div> </> : <div>Loading...</div>
  );
};
// export default ManualShippingPop;
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&region=NG`,
])(ManualShippingPop);