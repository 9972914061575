import React from "react";
import getCardImage from "../../../../css/images/Frame-16.svg";
import getProfitImage from "../../../../css/images/Frame-16-1.svg";

class CardOnboarding extends React.Component {

    state ={
        firstPopup: true,
        secondPopup: false,
        onBoarding: true
    }
    showFirstPopup=() => {
        this.setState({
            firstPopup: true,
            secondPopup: false
        })
    }

    showSecondPopup=() => {
        this.setState({
            firstPopup: false,
            secondPopup: true
        })
    }

    closeOnboarding=() => {
        this.setState({
            onBoarding: false
        })
    }

    creatCard=()=>{
        
      let fullName=this.state.fullName;

      console.log("fullName",fullName)
      var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(format.test(fullName)){
        this.setState({
          firstPopup: false,
          secondPopup: false,
          thirdPopup: true,
          nameError:"Your full name cannot contain specials characters."

        })
        
      }else if(fullName.length>24){
        this.setState({
          firstPopup: false,
          secondPopup: false,
          thirdPopup: true,
          nameError:"Your full name cannot be longer than 24 characters."

        })

      }else{
        this.setState({
          firstPopup: false,
          secondPopup: false,
          onBoarding: false
        })

        this.props.displayAgreement()

      }

    }

    componentDidUpdate(prevProps) {

      
      if (
        this.state.storeInfo?.firstName !==
        prevProps.storeInfo?.firstName
      ) {

        console.log("this.state.storeInfo?.firstName",this.state.storeInfo?.firstName)
        console.log("prevProps.storeInfo?.firstName",prevProps.storeInfo?.firstName)
        let storeInfo=this.props?.storeInfo;
        let{
          firstName,
          lastName
        }=storeInfo;

        let fullName="";
        if(firstName && lastName){
          fullName=`${firstName} ${lastName}`
        }
        

        this.setState({
            fullName:fullName?fullName:"",
            storeInfo:storeInfo,
            firstName:firstName,
            lastName:lastName
        })
      }

    }


    componentDidMount() {

      try{

        let storeInfo=this.props?.storeInfo;
        let{
          firstName,
          lastName
        }=storeInfo;

        let fullName="";
        if(firstName && lastName){
          fullName=`${firstName} ${lastName}`
        }
        

        this.setState({
            firstPopup: true,
            secondPopup: false,
            onBoarding: true,
            fullName:fullName?fullName:"",
            storeInfo:storeInfo,
            firstName:firstName,
            lastName:lastName
        })
      }catch(error){
        console.log("error getting user's name")
      }
    }
  render() {
    return (
      <div className={`virtualcardworkpopupmodal ${this.state.onBoarding?"":"hide"}`}>
        <div className={`virtualcardworkpopup ${this.state.firstPopup? "stage-1":"hide"}`}>
          <div
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae42f"
            className="closepopup"
            onClick={()=>this.props.closeOnboarding()}
          />
          <img
            src={getCardImage}
            loading="lazy"
            alt
            className="virtualcardworkpopupimage"
          />
          <div className="virtualcardworkpopuptitle">
            See how your dollar virtual card work
          </div>

          <iframe
            className="shipping-tutorial"
            src="https://www.youtube.com/embed/np-w_y4spQI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowfullscreen
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>

          <div className="virtualcardworkpopuptext">
            Watch our short video about how to use your Dollar Virtual Card.
          </div>
          <a
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae435"
            onClick={()=> this.showSecondPopup()}
            className="popupbutton w-inline-block"
          >
            <div>Next</div>
          </a>
          {/* <a
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae438"
            href="#"
            className="textlink"
          >
            Skip
          </a> */}
          <div className="circlewrap">
            <div className="circle purple" />
            <div className="circle" />
          </div>
        </div>
        <div className={`virtualcardworkpopup ${this.state.secondPopup? "stage-2":"hide"}`}>
          <div
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae43e"
            className="closepopup"
            onClick={()=>this.closeOnboarding()}
          />
          <img
            src={getProfitImage}
            loading="lazy"
            alt
            className="virtualcardworkpopupimage"
          />
          <div className="virtualcardworkpopuptitle xlarge">
            Learn how to run Effective Ads by setting up pixels
          </div>
          <div className="virtualcardworkpopuptext xlarge">
            Learn how to run effective Ads by setting up pixels. Facebook Pixels
            can help you run Ads that convert. Get started!
          </div>
          <a
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae444"
            onClick={()=>this.creatCard()}
            className="popupbutton w-inline-block"
          >
            <div>Done</div>
          </a>
          <div className="circlewrap">
            <div className="circle" />
            <div className="circle purple" />
          </div>
        </div>

        <div className={`virtualcardworkpopup ${this.state.thirdPopup? "stage-2":"hide"}`}>
          <div
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae43e"
            className="closepopup"
            onClick={()=>this.closeOnboarding()}
          />
          <img
            src="https://res.cloudinary.com/cashaam/image/upload/v1682874523/icons/services-parametres-et-icone-d-engrenage-violet_zqu6cb.webp"
            loading="lazy"
            alt
            className="virtualcardworkpopupimage"
            style={{height: "45px", marginBottom:"0px"}}
          />
          <div 
            className="virtualcardworkpopuptitle xlarge"
          >
            Issues with your name
          </div>

          <div 
            style={{fontWeight: "700", marginBottom:"0px"}} 
            className="virtualcardworkpopuptext xlarge"
          >
            Full Name: {this.state.fullName}
          </div>

          <div className="virtualcardworkpopuptext xlarge fix-name-text">
          {this.state.nameError}
          </div>
          <div className="virtualcardworkpopuptext xlarge fix-name-text">
            
            To prevent issues later while using your card, please update your profile in the settings page.
          </div>

          <div className="virtualcardworkpopuptext xlarge fix-name-text">
          
          </div>

          <a
            data-w-id="e9a17bca-b8c1-0529-64b7-9d71ac5ae444"
            href="/seller/dashboard/settings"
            className="popupbutton w-inline-block"
          >
            <div>Go To Settings</div>
          </a>
          <div className="circlewrap">
            <div className="circle" />
            <div className="circle purple" />
          </div>
        </div>
        
      </div>
    );
  }
}

export default CardOnboarding;
