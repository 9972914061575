import React from "react";
import firebase from "../../../src/firebaseConfig";

const compare = (a, b) => {
  if (a.timestamp.seconds < b.timestamp.seconds) {
    return -1;
  }
  if (a.timestamp.seconds > b.timestamp.seconds) {
    return 1;
  }
  return 0;
};

const getSellerChats = (phoneNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const incomingMessages = await db
        .collection("chat")
        .where("chatbot.To", "==", phoneNumber)
        .orderBy("chatbot.timestamp", "desc")
        .limit(10)
        .get();
      const outgoingMessages = await db
        .collection("chat")
        .where("incoming.From", "==", phoneNumber)
        .orderBy("incoming.timestamp", "desc")
        .limit(10)
        .get();

      let incoming = [];
      let outgoing = [];

      incomingMessages.docs.forEach((doc) => {
        incoming.push(doc.data().chatbot);
      });

      outgoingMessages.docs.forEach((doc) => {
        console.log("doc", doc.data());
        outgoing.push(doc.data().incoming);
      });

      //combine the two messages in one object and order by time and display all
      let allMessages = incoming.concat(outgoing);
      let orderedMessages = allMessages.sort(compare);

      resolve(orderedMessages);
    } catch (error) {
      console.log("error getting seller chat", error);
      reject(error);
    }
  });
};
export default getSellerChats;
