import React, { useRef, useEffect } from "react";
import priceFormater from "../../../helpers/priceFormater";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onDisplayShippingOptions) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onDisplayShippingOptions(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const ManualShippingOptions = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.onDisplayShippingOptions);

    return (
        props.shippingOptions.length > 0 ?
            <div style={{ display: "flex" }} className="tint-background">
                <div className="pop-container">
                    <div
                        ref={wrapperRef}
                        style={{ display: "flex", minWidth: "fit-content" }}
                        className="pop-details"
                    >
                        <div className="details-content">
                            {props.shippingOptions.map(option => (<>
                                <div style={{ position: "unset", backgroundColor: props.selectedShippingObject.rateObjectId !== option.object_id ? "rgba(18, 18, 18, 0.05)" : "#dad8ff" }} className="d-content-header">
                                    <div className="d-date">
                                        {/* <div className="box-title details">order date</div> */}
                                        {/* <div className="box-value details">{date}</div> */}
                                        <div className="d-orderid">
                                            <div>
                                                <strong>Provider</strong>: {option.provider}
                                            </div>
                                        </div>
                                    </div>

                                    {props.selectedShippingObject.rateObjectId !== option.object_id ? <a
                                        style={{ cursor: "pointer", marginLeft: "auto", minWidth: "100px", textAlign: "center" }}
                                        onClick={() =>
                                            props.onSelectShippingOption(
                                                option.object_id, option.carrier_account, option.servicelevel.token, option.amount
                                            )
                                        }
                                        className="box-btn-primary w-inline-block"
                                    >
                                        <div className="box-btn-text-primary">Select</div>
                                    </a> : <a
                                        style={{ cursor: "pointer", marginLeft: "auto", minWidth: "100px", textAlign: "center" }}

                                        onClick={() => props.unSelectShippingOption()}
                                        className="box-btn-primary w-inline-block"
                                    >
                                            <div className="box-btn-text-primary">Unselect</div>
                                        </a>}

                                </div>
                                <div style={{ marginBottom: "20px", backgroundColor: props.selectedShippingObject.rateObjectId !== option.object_id ? "rgba(18, 18, 18, 0.05)" : "#dad8ff" }} className="details-product">
                                    <div className="d-prduct-info">
                                        <div style={{ display: "flex", maxWidth: "fit-content" }} className="d-img">
                                            <img src={option.provider_image_75} width={103} alt />
                                        </div>
                                        <div className="d-info">
                                            <div className="d-product-title">
                                                {option.provider}
                                            </div>

                                            <div className="d-product-price">
                                                {priceFormater(option.amount, "USD")}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: "max-content" }} className="d-product-recipient">
                                        <div className="box-title details">Estimated Delivery: <span style={{ fontWeight: "bold" }}>{option.estimated_days} days</span></div>
                                        {/* <div className="d-product-meta name">
                                            {option.estimated_days} days
                </div> */}
                                        <div className="box-title details">Service Level: <span style={{ fontWeight: "bold" }}>{option.servicelevel.name}</span></div>
                                        {/* <div className="d-product-meta">
                                            {option.servicelevel.name}
                                        </div> */}
                                    </div>
                                </div></>))}
                            {props.ShippingOptionSelected === true ?
                                props.loading === true ? (
                                    <div
                                        style={{
                                            cursor: "pointer",
                                            background: "none",
                                            backgroundColor: "grey",
                                        }}
                                        className="btn-primary"
                                    >
                                        <div className="text-btn-primary">
                                            Creating Shipping Label...
                </div>
                                    </div>
                                ) : (
                                        props.shippingLink ? <a
                                            href={props.shippingLink}
                                            target="_blank"
                                            style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                                            className="btn-primary"
                                        >View Label </a> :
                                            <div
                                                onClick={() => props.onConfirmNewLabelCreation()
                                                }
                                                style={{ cursor: "pointer" }}
                                                className="btn-primary"
                                            >
                                                <div className="text-btn-primary">Print Shipping Label</div>
                                            </div>
                                    ) : null}
                            {props.errorCreatingShippingLabel === true && props.successfulReportSent !== true ? <div
                                style={{ cursor: "pointer", marginBottom: "10px" }}
                                onClick={() => props.reportUnsuccessfulLabelPrint(props.order)}
                                className="btn-secondary"
                            >
                                <div className="text-btn-tertiary">Still having problems? Let Cashaam Help</div>
                            </div> : props.successfulReportSent === true ?
                                    <div style={{ fontWeight: "bold", textAlign: "center", color: "#6740aa", fontSize: "16px", padding: "10px" }}>Thanks for reporting this issue to Cashaam. Our team will review the order information and help you as soon as possible.</div>
                                    : null}
                            <div
                                style={{ cursor: "pointer", marginBottom: "10px" }}
                                onClick={() => props.onDisplayShippingOptions(false)}
                                className="btn-secondary"
                            >
                                <div className="text-btn-tertiary">Close</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <div style={{ display: "flex" }} className="tint-background">
                <div className="pop-container">
                    <div
                        // ref={wrapperRef}
                        style={{ display: "flex", minWidth: "fit-content" }}
                        className="pop-details"
                    >
                        <div className="details-content">
                            <div className="d-content-header">
                                <div className="d-date">
                                    {/* <div className="box-title details">order date</div> */}
                                    {/* <div className="box-value details">{date}</div> */}
                                    <div className="d-orderid">
                                        <div>
                                            <strong>No Shipping Options Found</strong>
                                        </div>
                                    </div>
                                </div>
                            </div></div></div></div></div>
    );
};
export default ManualShippingOptions;
