import firebase from '../../firebaseConfig';

const getSellersInfo = (sellerUid) =>{
    return new Promise((resolve,reject)=>{

        try {
            const db = firebase.firestore();

            //retrieve user's store information
            db.collection("sellersAccounts")
            .doc(sellerUid)
            .collection("stores")
            .doc(sellerUid)
            .get()
            .then(doc => {
                if (doc.exists) {
                resolve(doc.data());
                } else {
                resolve("No store");
                }
            });
        } catch (error) {
            console.log('error getting seller info',error);
            reject(error);
        }
    })
} 

export default getSellersInfo;
