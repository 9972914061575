import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import Spinner from "../../../UI/Spinner";
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, displayDomainPop) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                displayDomainPop(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
const AddDomainPop = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.displayDomainPop);

    return (
        <>
            <ReactTooltip />

            <div style={{ display: "flex" }} className="tint-background">
                <div className="pop-container">
                    <div ref={wrapperRef}
                        style={{ display: "flex", maxHeight: "80vh", overflow: "scroll" }} className="pop-us-ship-label">
                        <div className="pop-header">
                            <div className="text-main">Domain Information</div>

                        </div>
                        <div className="pop-content">
                            <div className="sub-title">
                                Please Note the following:
            </div>
                            <div className="pop-text">
                                1. Your Domain: <b>{props.domainName}</b> <br />
                                2. You need to go to your domain provider's website add the following A records: <br />
                                <ul>
                                    <li style={{ fontWeight: "bold" }}>151.101.1.195</li>
                                    <li style={{ fontWeight: "bold" }}>151.101.65.195</li>
                                </ul>
                                3. After adding this information, it will take about <b>24 hours</b> before your new domain is ready to be used. <br />
                                4. If you need Guidance, here are a few resources:
                                <ul>
                                    <li>For a guide from <b>Google Domains</b>{" "}<a target="_blank" rel="noreferrer noopener" href="https://support.google.com/domains/answer/9211383?hl=en">click here </a></li>
                                    <li>For a guide from <b>GoDaddy</b>{" "}<a target="_blank" rel="noreferrer noopener" href="https://www.godaddy.com/help/add-an-a-record-19238">click here </a></li>

                                </ul>
                            </div>

                            {props.loading === false ? <button
                                onClick={props.handleSubmit}
                                type="button"
                                value="Done"
                                defaultValue="Done"
                                data-wait="Please wait..."
                                className="submit-primary w-button"
                            >
                                Done
                         </button> : <Spinner styles={{ marginLeft: "auto", marginRight: "auto" }} />}
                        </div>

                    </div>
                </div>
            </div> </>
    );
};

export default AddDomainPop;