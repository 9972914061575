import firebase from "../../firebaseConfig";

const saveAccountType= (id, account) => {
  return new Promise((resolve, reject) => {
    try {
        console.log("save",id, account)
      const db = firebase.firestore();
      db.collection("sellersAccounts").doc(id).set(
        {
            accountType: account
        },
        { merge: true }
      );

      resolve("success");
    } catch (error) {
      console.log("error saving account type", error);
      reject(error);
    }
  });
};
export default saveAccountType;