import React from "react";

import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import BottomNav from "../../../common/BottomNav";
import MarketingDashboard from "./MarketingDashboard";

import getUserLocation from "../../../helpers/getUserLocation";
import getBasicInfo from "../../../helpers/getBasicInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getShopperInfo from "../../../helpers/getShopperInfo";
import getReferrals from "../../../helpers/getReferrals";
import NProgress from "nprogress";
import Metatags from "react-meta-tags";

import firebase from "../../../../firebaseConfig";
import {STORE_ANALYTICS} from "../../../../secrets";



class Marketing extends React.Component {

    state = {
        firstName: "",
        cartCount: 0,
        shoppingpoints: 10,
        phoneNumber: "",
        userInfo: {},
        isLoggedIn: false,
    
        accountNumber:"",
        bankName:"",
        paypalEmail:"",
        paypalPhone:"",
        displayPaypal: false,
        displayFlutterwave: false,
        customerCount: -1,
        addedToCart: -1
      };



  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  fetchSellerInfo = async (id) => {
    try {
        console.log("fetchSellerInfo",id);
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let shortReferral = id?.slice(0, 6);
      shortReferral = shortReferral?.toUpperCase();

      let { referralCount, successfullReferral } = await getReferrals(
        shortReferral
      );
      let totalReferrals = referralCount;
      console.log("totalReferrals", totalReferrals);
      if (!totalReferrals) {
        totalReferrals = 0;
      }

      let {
        firstName,
        lastName,
        slug,
        activated,
        storeName,
        phoneNumber,
        referralPayment,
        subscriptionActive,
        subscriptionType
      } = basicInfo;

      if(!basicInfo?.subscriptionActive){
        return this.props.history.push("/seller/dashboard/rewards");

      }else{
        console.log("subscriptionActive",subscriptionActive)
      }

      let totalCashedOut = 0;
      let cashedoutReferrals = 0;
      if (referralPayment) {
        referralPayment.forEach((payment) => {
          console.log("Payment", payment);
          totalCashedOut += payment.cashedOutAmount;
          cashedoutReferrals += payment?.completedReferral;
        });
      }
      let successRate = 0;
      if (
        totalReferrals &&
        successfullReferral &&
        cashedoutReferrals <= totalReferrals
      ) {
        successRate = (successfullReferral / totalReferrals) * 100;
        successRate = Math.round(successRate);
      }

      let storeURL = `http://www.cashaam.com/${slug}`;
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        activated: typeof activated !== "undefined" ? activated : true,
        storeInfo: basicInfo,
        totalReferrals,
        totalCashedOut,
        cashedoutReferrals,
        successRate,
        successfullReferral,
        country: basicInfo?.country,
      });

      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  storeAnalytics = async (slug, daysFilter) => {
    try{
  
        console.log("get storeAnalytics",slug, daysFilter);
  
        const res = await fetch(`${STORE_ANALYTICS}?slug=${slug}&days=${daysFilter}`, {
          method: "GET",
        });
  
        let result=await res.json();
        let storeStats ={
          "first_visit":{"event_count": 0},
          "add_to_cart":{"event_count": 0},
          "begin_checkout":{"event_count": 0},
          "add_shipping_info":{"event_count": 0},
          "add_payment_info":{"event_count": 0},
        }
        console.log("store analytics res:",result);
        if(res.status==200) {

            for(let i=0; i<result.length; i++){
                let element = result[i];
                storeStats[element['event_name']]=result[i];
            }


            console.log("storeStats",storeStats)
          this.setState({
            storeStats,
            daysFilter
          })
  
        }else{
          
        }
   
    } catch (error) {
      console.log("error getting store analytics", error);
    }
  }; 

  getCustomers = async (slug) => {
    try {
    const body = { query_field: "slug", query_value: slug };

    const response = await fetch(
      "https://us-central1-cashaam-dev.cloudfunctions.net/sellerquery-querydb",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );

    // console.log('resp--',await response.json());
    
      const resp = await response.json();
      const orders = resp.orders;
      let customersIds = [];


      let addedToCart =0;
      orders.forEach((order) => {
        if (!customersIds.includes(order.customerId)) {
          customersIds.push(order.customerId);
        }
        if(order?.orderStatus==="pending"){
            addedToCart+=1;
        }
      })

      console.log("customerids", customersIds.length);
      console.log("added to Cart", addedToCart)
      this.setState({
        customerCount: customersIds.length,
        addedToCart
      });
    } catch (error) {
      console.log("error getting customers", error);
      this.setState({
        customerCount: 0,
        addedToCart: 0
      });
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData",{ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPaystack =()=>{
    let script = document.createElement("script");
    script.src = "https://js.paystack.co/v1/inline.js";
    script.addEventListener("load", () => {
        console.log("paystack loaded");
        this.setState({ paystackLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPendo =() =>{

    let script = document.createElement("script");

    let pendoKey="6ff8873a-3edf-4dc1-6d19-5a3490191d2a";

    script.src='https://cdn.pendo.io/agent/static/'+pendoKey+'/pendo.js';
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);

  }


  async componentDidMount() {
    try {
      NProgress.start();

      this.loadPaystack();
      this.loadHubspot();
      this.loadPendo();
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {

          if(this.state.pendoLoaded){
            console.log("initialize pendo")
            pendo.initialize({
              visitor: {
                  id: user.uid,   // Required if user is logged in
                  email: user?.email,       // Recommended if using Pendo Feedback, or NPS Email
                  full_name: user?.displayName,   // Recommended if using Pendo Feedback
                  role:  "seller" 
              },
      
              account: {
                  id: user.uid, 
                  planLevel:  "seller",
                  userType: "seller"
              }
            });

          }

          let shortReferral = user.uid?.slice(0, 6);
          shortReferral = shortReferral.toUpperCase();

          console.log("shortReferral",shortReferral)
          let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

          this.setState({
            shortReferral,
            referralLink,
            sellerId: user.uid,
          });
          localStorage.setItem("cashaam_uid", user.uid);
          this.getLocation();

          //check the user registration progress
          let step = await getRegistrationProgress(user.uid);

          step = parseFloat(step);
          if (step === 100) {
            // this.props.history.push("/seller/dashboard");
            console.log("completed registration step:", step);
          } else {
            console.log("MY STEP", step);
            if (step < 4) {
              this.props.history.push("/seller/dashboard/setup/onboarding");
            }
          }

          NProgress.set(0.5);
          const sellerUid = user.uid;
          await this.fetchSellerInfo(sellerUid);

          let shopperSideInfo = await getShopperInfo(user.uid);

          let rewards = shopperSideInfo?.rewards;

          this.setState({
            shopperSideInfo,
            shoppingPoints: rewards?.shoppingpoints
              ? rewards?.shoppingpoints
              : 10,
            wallet: shopperSideInfo?.wallet,
          });

          NProgress.done();

          let slug =this.state.slug;
          this.storeAnalytics(slug, 7)
          this.getCustomers(slug);
          
        } else {
          this.props.history.push("/");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Billing & Referral</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Chat" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>

        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />

        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            <MarketingDashboard 
                storeInfo={this.state.storeInfo}
                customerCount={this.state.customerCount}
                addedToCart={this.state.addedToCart}
                storeStats={this.state.storeStats}
                storeAnalytics={this.storeAnalytics}
                daysFilter={this.state.daysFilter}
            />

            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
        </div>
      </>
    );
  }
}

export default Marketing;
