import React from 'react'
import priceFormater from '../../../../helpers/priceFormater';

const AddOnForm = (props) => {
    return (
        <form
            id="email-form"
            name="email-form"
            data-name="Email Form"
            className="update-form"
            onSubmit={props.submitForm}
        >
            <input
                value={props.addOnFormData.addOnName}
                onChange={props.handleChange}
                type="text"
                className="input-text w-input"
                minLength={1}
                maxLength={256}
                name="addOnName"
                placeholder="Enter Name of Add-on"
                id="addOnName"
                required
            />
            <div style={{ display: "block" }} className="meta-additions">
                <div
                    style={{ textAlign: "left", color: "#6740aa" }}
                    className="meta-text-limits"
                >
                    {priceFormater(props.addOnFormData.addOnPrice, props.currentCountry)}
                </div>
            </div>
            <input
                value={props.addOnFormData.addOnPrice}
                onChange={props.handleChange}
                type="number"
                className="input-text input-half w-input"
                min={0.01}
                step={0.01}
                name="addOnPrice"
                placeholder="Enter Additional Price (No Commas Allowed)"
                id="addOnPrice"
                required
            />
            <input
                value={props.addOnFormData.addOnDuration}
                onChange={props.handleChange}
                type="number"
                className="input-text input-half w-input"
                name="addOnDuration"
                placeholder="Enter Duration in minutes"
                id="addOnPrice"
                required
            />
            <div>
                Select Services
             </div>
            {
                props.services.map((service) => {
                    return (
                        <div key={service.id} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                            <input
                                id={`service-${service.id}`}
                                type="checkbox"
                                value={service.id}
                                style={{ marginRight: 10 }}
                                onChange={props.onCheckboxChange}
                                checked={props.addOnFormData.services.includes(service.id)}
                            />
                            <label htmlFor={`service-${service.id}`} >{service.name}</label>
                        </div>
                    )
                })
            }

            <input
                type="submit"
                value={props.isEditing ? "Update Add-on": "Create Add-On"}
                data-wait="Please wait..."
                className="submit-primary w-button"
            />
            <button
                onClick={props.closeForm}
                className="btn-secondary w-button"
                style={{ marginTop: 10 }}>
                <span className="text-btn-secondary">
                    Cancel
                </span>
            </button>

        </form>
    )
}

export default AddOnForm
