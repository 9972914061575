import React, { useEffect, useState } from "react";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";
import {Pagination} from "./Pagination";

function CardTransactions(props) {

  
    const dataLimit = 10;
    const pageLimit = 4;
    let pages = Math.ceil(props.cardHistory.length / dataLimit);
    const [currentPage, setCurrentPage] = useState(1);

    function goToNextPage() {
      if (pages > currentPage) {
        console.log("currentPage", currentPage);
        window.scrollTo({ behavior: "smooth", top: "0px" });
        setCurrentPage((page) => page + 1);
      }
    }

    function goToPreviousPage() {
      if (currentPage > 1) {
        console.log("currentPage", currentPage);
        window.scrollTo({ behavior: "smooth", top: "0px" });
        setCurrentPage((page) => page - 1);
      }
    }

    function changePage(event) {
      window.scrollTo({ behavior: "smooth", top: "0px" });
      const pageNumber = Number(event.target.textContent);
      setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
      const startIndex = currentPage * dataLimit - dataLimit;
      const endIndex = startIndex + dataLimit;
      return props.cardHistory.slice(startIndex, endIndex);
    };

    const getPaginationGroup = () => {
      let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
      if (pages < pageLimit) {
        return new Array(pages).fill().map((_, idx) => start + idx + 1);
      } else {
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
      }
    };

    const timeConverter=(timestamp, transactionType)=>{

      timestamp=parseInt(timestamp);
      // if(transactionType?.toLowerCase() ==="create"){
      //   timestamp=timestamp*1000;
      // }

      if(timestamp<1641078780000){
        timestamp=timestamp*1000;
      }
      
      var a = new Date(timestamp );
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var time = date + ' ' + month + ', ' + year ;
      return time;
    }

    useEffect(() => {
      pages = Math.ceil(props.cardHistory.length / dataLimit);
      setCurrentPage(1);
    }, [props.cardHistory]);

  

  
    return (
        <div
        id="w-node-b80d8672-faa2-0a10-844a-adb373a0a05d-b87d6bb3"
        className="dashboardbox gridbox4"
      >
        <div className="gridbox4top">
          <div className="gridboxtitle nomargin">{props.historyTpe}</div>
          
        </div>

        <div className={`transactionsitem ${props.historyTpe=="Payments"?"paymentitem":""}`}>
              {/* <div className="row extragap">
                
                <div style={{fontWeight:"900", color:"black", marginLeft:"35px"}} className="transactionsitemusername">Name</div>
              </div> */}
              <div style={{fontWeight:"900", color:"black"}} className="transactionsitemcost">Date</div>
              {props.historyTpe=="Transactions"?
                <div style={{fontWeight:"900", color:"black"}} className="transactionsitemcost">Description</div>
              :null}
              <div style={{fontWeight:"900", color:"black"}} className="transactionsitemcost">Amount</div>
              <div style={{fontWeight:"900", color:"black", margin: "0 auto"}} className="transactionsitemtransfer">Status</div>
              {/* <div className="transactionsitemmore" /> */}
        </div>

        {getPaginatedData().map((transaction) => (

          
            <div className={`transactionsitem ${props.historyTpe=="Payments"?"paymentitem":""}`}>

              {transaction.timeStamp?
              <div className="transactionsitemdate">{timeConverter(transaction.timeStamp,transaction?.type)}</div>
              : (transaction.timestamp?
              <div className="transactionsitemdate">{timeConverter(transaction.timestamp,transaction?.type)}</div>
              :null)}
              {props.historyTpe=="Transactions"?
                (transaction?.metadata && (transaction?.metadata?.merchantData?.name || transaction?.metadata?.name)?
                    (transaction?.metadata?.merchantData?.name?
                      <div className="transactionsitemcost">{transaction?.metadata?.merchantData?.name}</div>
                      :
                      <div className="transactionsitemcost">{transaction?.metadata?.name}</div>
                    )
                :
                  (transaction?.type?.toLowerCase()=="refund"?
                    <div className="transactionsitemcost">Refund from merchant</div>
                    :
                    <div className="transactionsitemcost">ONLINE PURCHASE</div>
                  )
                  
                )
              :null}

              {props.historyTpe=="Transactions"?
                (transaction?.type?.toLowerCase()=="refund"?
                <div className="transactionsitemcost">+{priceFormaterIntl(transaction?.purchaseAmount, transaction?.currency)}</div>
                :
                <div className="transactionsitemcost">-{priceFormaterIntl(transaction?.purchaseAmount, transaction?.currency)}</div>
                )
              :
                <div className="transactionsitemcost">+{priceFormaterIntl(transaction?.purchaseAmount, transaction?.currency)}</div>
              }

              
              {props.historyTpe=="Transactions"?
                (transaction?.type?.toLowerCase()=="refund"?
                  <div 
                    style={{backgroundColor: "#e7e7e7",
                            color: "#393939"
                          }}
                    className="transactionsitemtransfer complete-transaction">Refund</div>
                  :
                  <div className="transactionsitemtransfer complete-transaction">Completed</div>
                )
              :
                <div className="transactionsitemtransfer complete-paymentitem">Paid</div>
              }
              {/* <div className="transactionsitemmore" /> */}
            </div>
          

        ))}

        
        <Pagination
          numPages={props.nbPages}
          getPaginationGroup={getPaginationGroup}
          changePage={changePage}
          currentPage={currentPage}
          pages={pages}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
        />

        {/* <div className="viewallwrap">
          <a href="#" className="viewall">
            View all
          </a>
        </div> */}
      </div>
    );
}

export default CardTransactions;