import React from "react";
import PackageInfo from "../../../common/PackageInfo";
import ProductImageUpload from "../../../common/ProductImageUpload";
import ProductInfoForm from "../../../common/ProductInfoForm";
import ProductAdditionSuccess from "../../../common/ProductAdditionSuccess";

const AddProdForm = (props) => {
  return (
    <div className="panel-center">
      <div className="page-header">
        <h4 className="header-title">Add New Product</h4>
        {/* <div className="store-status">
          <div className="store-state-live">Your Store is Live</div>
        </div> */}
      </div>
      <div className="main">
        {props.productUploadSuccess === false &&
          props.displayUploadImage === true &&
          props.displayPackageInfo === false ? (
            <ProductImageUpload
              handleFeaturedImageChange={props.handleFeaturedImageChange}
              handleImageChange={props.handleImageChange}
              loading={props.loading}
              onDisplayProductInfo={props.onDisplayProductInfo}
              onHideUploadImage={() => props.onHideUploadImage()}
              errorMessage={props.errorMessage}
              featuredImageError={props.featuredImageError}
            />
          ) : null}
        {props.displayPackageInfo === false &&
          props.productUploadSuccess === false &&
          props.displayUploadImage === false ? (
            <ProductInfoForm
              handleChange={props.handleChange}
              productName={props.productName}
              productPrice={props.productPrice}
              currency={props.currency}
              productDescription={props.productDescription}
              userCurrentCountry={props.userCurrentCountry}
              productStock={props.productStock}
              selected={props.selected}
              selectCategory={props.selectCategory}
              selectedColors={props.selectedColors}
              selectColor={props.selectColor}
              tags={props.tags}
              handleDelete={props.handleDelete}
              handleAddition={props.handleAddition}
              handleDrag={props.handleDrag}
              onDisplayPackageInfo={props.onDisplayPackageInfo}
              options={props.options}
              colorOptions={props.colorOptions}
              errorMessage={props.errorMessage}
              viewOtherColorInput={props.viewOtherColorInput}
              colorTags={props.colorTags}
              handleColorDelete={props.handleColorDelete}
              handleColorAddition={props.handleColorAddition}
              handleColorDrag={props.handleColorDrag}
              storeCatalogs={props.storeCatalogs}
              selectedCatalogs={props.selectedCatalogs}
              selectCatalog={props.selectCatalog}
              coloredInputField={props.coloredInputField}
              handleEditorChange={props.handleEditorChange}
              text={props.text}
              addExtraVariants={props.addExtraVariants}
              extraVariants={props.extraVariants}
              handleExtraVariantAddition={props.handleExtraVariantAddition}
              extraVariantsTags={props.extraVariantsTags}
              handleExtraVariantDelete={props.handleExtraVariantDelete}
              handleExtraVariantNameChange={props.handleExtraVariantNameChange}
              invalidExtraVariants={props.invalidExtraVariants}
              removeExtraVariant={props.removeExtraVariant}
              variantObject={props.variantObject}
              handleVariantPriceChange={props.handleVariantPriceChange}
              onEditComboPrices={props.onEditComboPrices}
              editCombinationPrices={props.editCombinationPrices}
              handleVariantQuantityChange={props.handleVariantQuantityChange}
              onHideProductInfo={props.onHideProductInfo}


              productNameError={props.productNameError}
              productDescriptionError={props.productDescriptionError}
              priceError={props.priceError}
              productStockError={props.productStockError}
              productCategoryError={props.productCategoryError}
              clickAdd={props.clickAdd}
              clickAddVariant={props.clickAddVariant}
            />
          ) : null}
        {props.displayUploadImage === false &&
          props.productUploadSuccess === false &&
          props.displayPackageInfo === true ? (
            <PackageInfo
              handleChange={props.handleChange}
              productWeight={props.productWeight}
              productHeight={props.productHeight}
              productLength={props.productLength}
              productWidth={props.productWidth}
              handleSubmit={props.handleSubmit}
              skipForm={props.skipForm}
              onHidePackageInfo={props.onHidePackageInfo}
              loading={props.loading}
              errorMessage={props.errorMessage}


              productWeightError={props.productWeightError}
              productHeightError={props.productHeightError}
              productLengthError={props.productLengthError}
              productWidthError={props.productWidthError}
              userCurrentCountry={props.userCurrentCountry}
            />
          ) : null}
        {props.productUploadSuccess ? (
          <ProductAdditionSuccess addMoreProducts={props.addMoreProducts} />
        ) : null}
      </div>
    </div>
  );
};
export default AddProdForm;