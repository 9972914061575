import React from "react";

class ConfirmDowngrade extends React.Component {
  render() {
    return (
      <div className="fixedmodalwrapper gray-background">
        <div style={{overflow:"visible"}} className="fixedmodalcontainer fixed-modal">
          <div className="fixedmodalhead itemaddedmodalhead">
            {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
            <div
              className="text-block-4"
              style={{ margin: "auto", fontWeight: "bold" }}
            >
              Are You Sure?
            </div>
          </div>


            <div className="airtime-resp-container">
              
              <div style={{color: "black", marginTop:"10px"}} className="airtime-resp">By accepting to downgrade your account from Business to Personal, you are putting your store and business related services on hold.</div>

              <div className="sure-container">

                  <a  onClick={() => this.props.toggleDowngrade(false)} style={{backgroundColor:"#e95454", margin:"0"}} className="helppopupbutton w-inline-block">
                    <div>Cancel</div>
                  </a>

                  <a onClick={()=>this.props.cancelSubscription()} style={{ backgroundColor: "#0bad0b", marginLeft: "0px"}} className="helppopupbutton w-inline-block">
                    <div>Accept</div>
                  </a>


              </div>
            </div>
          
          <a
            onClick={() => this.props.toggleDowngrade(false)}
            className="fixedmodalexitimg w-inline-block"
          />
        </div>
      </div>
    );
  }
}

export default ConfirmDowngrade;
