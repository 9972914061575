import firebase from "../../firebaseConfig";

const getScheduledPickup = (shippingObjectId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const result = await db
        .collection("scheduled_pickup")
        .doc(shippingObjectId)
        .get();
      if (result.exists) {
        const pickupInfo = result.data();
        resolve(pickupInfo);
      } else {
        resolve(false);
      }
    } catch (error) {
      console.log("error getting scheduled pick up", error);
      reject(error);
    }
  });
};
export default getScheduledPickup;
