import React from 'react';
import topUpIcon from "../../../../css/images/add-circle.svg";

import priceFormaterIntl from "../../../helpers/priceFormaterIntl";

function CardInfo(props) {
    return (
        <div
        id="w-node-e56638af-d625-81be-5dea-ceabc8fca7ef-b87d6bb3"
        className="dashboardbox gridbox2"
      >
        <div className="row justify-between margin-bottom-8">
          <div className="gridbox2text">Total Balance</div>
          {/* {props.virtualCards && props.monthBalance>0 && (props.country.toLowerCase()=="nigeria" || (props.stripeVerification && props.cardApproval))?
          <a
            data-w-id="0f15e325-3cb9-d6e7-ef1e-202d589e7c44"
            onClick={props.showTopup}
            className="walletdetail w-inline-block"
          >
            <img src={topUpIcon} loading="lazy" alt />
            <div>Top-up</div>
          </a>
          :null} */}
        </div>
        <div className="gridbox2title">{priceFormaterIntl(props.spendingLimit, "USD")}</div>
        <div className="gridbox2row">
          <div className="gridbox2text">Last Charge</div>
          {props.lastRecharge?
          <div className="gridbox2text">{priceFormaterIntl(props.lastRecharge, "USD")}</div>
          :null
          }
          
        </div>
        {props.totalMonthTopUp && props.topUpLimit?
          <div className="gridbox2statuswrap">
            <div className="gridbox2statustext">
              Last 30 Days Top-Up: {priceFormaterIntl(props.totalMonthTopUp, "USD")}
              {props.limitedTopup?
              <span className="gridbox2statustextspan">/ {priceFormaterIntl(props.topUpLimit, "USD")}</span>
              :null}
            </div>
            <div className="gridbox2status">
              <div style={{
                width: `${(props.totalMonthTopUp/props.topUpLimit)*100}%`,
              }}
              className="gridbox2statusbar" />
            </div>
          </div>
        : null
        }
      </div>
    );
}

export default CardInfo;
