import firebase from "../../firebaseConfig";
const db = firebase.firestore();
const shippo = require("shippo")(
  "shippo_test_d7ea9895ab11c2c33aa09661fd96d14abd68312c"
);

const testShippo = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await fetch("https://api.goshippo.com/tracks/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          carrier: "shippo",
          tracking_number: "SHIPPO_PRE_TRANSIT",
        }),
      });
      console.log("resp", response);
    } catch (error) {
      console.log("error creating shipping label", error);
      reject(error);
    }
  });
};
export default testShippo;
