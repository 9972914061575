import React from "react";
const ButtonLoading = (props) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      className="btn-sign w-button"
    >
      <div style={{ borderTopColor: "#dd6b00" }} className="loader"></div>{" "}
    </div>
  );
};
export default ButtonLoading;
