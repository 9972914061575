import React from "react";
import whatsappIcon from "../../../custom-images/whatsapp-icon.png";
const Whatsapp = (props) => (
  <a
    style={props.style}
    href={`https://api.whatsapp.com/send?phone=${props.phoneNumber}&text=%0A%0A${props.url}`}
    target="_blank"
    className="link-social-sm w-inline-block"
  >
    <img style={{ maxHeight: "20px", maxWidth: "20px" }} src={whatsappIcon} />
  </a>
);
export default Whatsapp;
