import firebase from "../../firebaseConfig";

const getNumberOfProducts = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let products = await db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("products")
        .get();
      resolve(products.size);
    } catch (error) {
      console.log("error getting number of products", error);
      reject(error);
    }
  });
};
export default getNumberOfProducts;
