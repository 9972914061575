import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import Swal from "sweetalert2";
import "./addProduct.css";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";

import storeProductFeaturedImage from "../../../helpers/storeProductFeaturedImage";
import storeProductImagesToStorage from "../../../helpers/storeProductImagesToStorage";
import saveProductToDatabase from "../../../helpers/saveProductToDatabase";
import getSellersInfo from "../../../helpers/getSellersInfo";
import { getCatalogs } from "../../../helpers/catalogs";
import isBankSetup from "../../../helpers/isBankSetup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUserLocation from "../../../helpers/getUserLocation";
import getNumberOfProducts from "../../../helpers/getNumberOfProducts";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import getBasicInfo from "../../../helpers/getBasicInfo";
import AddProdForm from "./AddProdForm";
import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import NProgress from "nprogress";
import { escape, unescape } from "html-escaper";
import getVariantCombo from "../../../helpers/getVariantCombo";
import getUnreadChat from "../../../helpers/getUnreadChat";

let tempFiles = [];
const db = firebase.firestore();
const options = [
  { label: "Clothing, Shoes, Jewelry & Watches", value: 0 },
  { label: "Movies, Music & Games", value: 1 },
  { label: "Electronics, Computers & Office", value: 2 },
  { label: "Home, Garden & Tools", value: 3 },
  { label: "Food & Grocery", value: 4 },
  { label: "Beauty & Health", value: 5 },
  { label: "Toys, Kids & Baby", value: 6 },
  { label: "Handmade", value: 7 },
  { label: "Sports & Outdoors", value: 8 },
  { label: "Automotive & Industrial", value: 9 },
];

const colorOptions = [
  { label: "Black", value: 0 },
  { label: "White", value: 1 },
  { label: "Red", value: 2 },
  { label: "Blue", value: 3 },
  { label: "Green", value: 4 },
  { label: "Yellow", value: 5 },
  { label: "Gold", value: 6 },
  { label: "Grey", value: 7 },
  { label: "Brown", value: 8 },
  { label: "Pink", value: 9 },
  { label: "Orange", value: 10 },
  { label: "Purple", value: 11 },
  { label: "Silver", value: 12 },
  { label: "Other", value: 13 },
];

// const sizeOptions = [
//   { label: "Extra Small", value: 0 },
//   { label: "Small", value: 1 },
//   { label: "Medium", value: 2 },
//   { label: "Large", value: 3 },
//   { label: "Extra Large", value: 4 },
//   { label: "Beauty & Health", value: 5 },
//   { label: "Toys, Kids & Baby", value: 6 },
//   { label: "Handmade", value: 7 },
//   { label: "Sports & Outdoors", value: 8 },
//   { label: "Automotive & Industrial", value: 9 }
// ];
function getCombn(arr) {
  if (arr.length == 1) {
    return arr[0];
  } else {
    var ans = [];

    // recur with the rest of the array.
    var otherCases = getCombn(arr.slice(1));
    for (var i = 0; i < otherCases.length; i++) {
      for (var j = 0; j < arr[0].length; j++) {
        ans.push(arr[0][j] + "-&-" + otherCases[i]);
      }
    }
    return ans;
  }
}

export default class AddProduct extends Component {
  state = {
    firstName: "",
    lastName: "",
    sellerId: "",
    productImages: [],
    featuredImage: "",
    productName: "",
    productDescription: "",
    productPrice: 0,
    productStock: 0,
    productVariant: { colors: [], sizes: [], extraVariants: [] },
    productWeight: 0,
    productHeight: 0,
    productLength: 0,
    productWidth: 0,

    productWeightError: "",
    productHeightError: "",
    productLengthError: "",
    productWidthError: "",

    productCategory: "",
    sellerFullName: "",
    slug: "",
    currency: "",
    storeName: "",
    discount: 0,
    discountedPrice: 0,
    location: "",
    lastUpdate: "",
    likes: 0,
    previewURL: [],
    featuredImageURL: "",
    productUploadSuccess: false,
    productUploadError: false,
    productCategory: [],
    selected: [],
    sizeSuggestions: [
      { id: "Extra Small", text: "Extra Small" },
      { id: "Small", text: "Small" },
      { id: "Medium", text: "Medium" },
      { id: "Large", text: "Large" },
      { id: "Extra Large", text: "Extra Large" },
    ],
    tags: [], // preselected tags,
    selectedColors: [],
    displayUploadImage: true,
    loading: false,
    userCurrentCountry: "",
    displayPackageInfo: false,
    productNumber: 0,
    errorMessage: null,
    showLeftNav: false,
    viewOtherColorInput: false,
    colorTags: [],
    otherColors: [],
    storeCatalogs: [],
    selectedCatalogs: [],
    coloredInputField: [],
    extraVariants: [],
    extraVariantsTags: [],
    invalidExtraVariants: [],
    variantObject: {},
    editCombinationPrices: true,
    productNameError: "",
    productDescriptionError: "",
    priceError: "",
    productStockError: "",
    productCategoryError:"",
    featuredImageError:""
  };

  handleEditorChange = (html) => {
    console.log("quill val", html);
    this.setState({ productDescription: html });
  };

  showErrorMessage = (message) => {
    this.setState({ errorMessage: message });

    setTimeout(() => {
      this.setState({ errorMessage: null });
    }, 10000);
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  handleDelete = (i) => {
    const { tags, productVariant } = this.state;
    let productVar = productVariant;
    productVar.sizes = productVar.sizes.filter((size) => size !== tags[i].text);

    this.setState({
      tags: tags.filter((tag, index) => index !== i),
      productVariant: productVar,
    });
    this.generateVariantCombinations();
  };

  handleColorDelete = async (i) => {
    let { colorTags, otherColors } = this.state;
    otherColors = otherColors.filter((color) => color !== colorTags[i].text);
    await Promise.resolve(
      this.setState({
        colorTags: colorTags.filter((tag, index) => index !== i),
        otherColors,
      })
    );
    this.generateVariantCombinations();
  };


  clickAddVariant = (id, i)=>{

    let customId=`${id}${i}`;
    console.log("customId",customId);
    let inputValue = document.getElementById(customId).value;

    if(inputValue){
      let tag={
        id: inputValue,
        text: inputValue
      }
      this.handleExtraVariantAddition(tag,i);
      document.getElementById(customId).value = ``;
    }

  }

  clickAdd = (id, handleAddition)=>{
    console.log("id",id);
    var inputValue = document.getElementById(id).value;

    if(inputValue){
      let tag={
        id: inputValue,
        text: inputValue
      }
      handleAddition(tag);
      document.getElementById(id).value = ``;
    }
  }
  
  handleAddition = async (tag) => {
    let tagArray = [];
    let productVar = this.state.productVariant;

    tagArray.push(tag.text);
    this.state.tags.map((tag) => {
      console.log("TAG", tag);
      tagArray.push(tag.text);
    });
    productVar.sizes = tagArray;

    await Promise.resolve(
      this.setState((state) => ({
        tags: [...state.tags, tag],
        productVariant: productVar,
      }))
    );
    this.generateVariantCombinations();
  };

  handleColorAddition = async (tag) => {
    let tagArray = [];
    // let productVar = this.state.productVariant;

    tagArray.push(tag.text);
    this.state.colorTags.map((tag) => {
      tagArray.push(tag.text);
    });
    // productVar.colors = tagArray;

    await Promise.resolve(
      this.setState((state) => ({
        colorTags: [...state.colorTags, tag],
        otherColors: tagArray,
      }))
    );
    this.generateVariantCombinations();
  };

  handleDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };

  handleColorDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.colorTags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ colorTags: newTags });
  };

  skipForm = async(e)=>{

    console.log("skip form")
    await this.setState({     
      productWeight: 10,
      productHeight: 10,
      productLength: 10,
      productWidth: 10
    })

    this.handleSubmit(e);
  }

  handleChange = (e) => {
    //enforce numbers only for product dimensions

    if (
      e.target.name === "productWeight" ||
      e.target.name === "productHeight" ||
      e.target.name === "productLength" ||
      e.target.name === "productWidth"
    ) {
      this.setState({
        [e.target.name]: parseFloat(e.target.value),
      });

      return;
    }

    //enfore price format
    if (e.target.name === "productPrice") {
      // let price = e.target.value.replace(/[^0-9 ]/g, "");
      // price = price.replace(/ /g, "");
      this.setState({
        [e.target.name]: e.target.value,
      });
      return;
    }

    if (e.target.name === "productStock") {
      let stock = e.target.value.replace(/[^0-9 ]/g, "");
      stock = stock.replace(/ /g, "");
      this.setState({
        [e.target.name]: stock,
      });
      return;
    }

    if (e.target.name === "productSizes") {
      //turn input into an array
      let sizeArray = e.target.value.split(",");
      let productVariant = this.state.productVariant;
      productVariant.sizes = sizeArray;
      console.log("sizes", sizeArray);
      this.setState({
        [e.target.name]: e.target.value,
        productVariant,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumberInputChange = (e) => {
    const doubleValue = parseFloat(e.target.value);
    this.setState({
      [e.target.name]: doubleValue,
    });
  };

  handleArrayInputChange = (e) => {
    const tempHolder = e.target.value.split(",");
    this.setState({
      [e.target.name]: tempHolder,
    });
  };

  //handle featuredImage change
  handleFeaturedImageChange = async (e) => {
    e.preventDefault();

    let featuredImageError=this.state.featuredImageError;
    this.setState({
      featuredImageError:""
    })

    console.log("Fimage--", e.target.files[0].type);
    const imageFile = e.target.files[0];
    if (
      imageFile.type !== "image/png" &&
      imageFile.type !== "image/jpg" &&
      imageFile.type !== "image/jpeg"
    ) {
      const message = `Image of type ${imageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
      console.log("File type is not supported. Uploaded type:", imageFile.type);
      this.setState({
        featuredImageError:message
      })
      return;
    }
    this.setState({
      featuredImage: imageFile,
    });
    console.log("STATE", this.state.featuredImage);
  };

  // handles the images change handle
  handleImageChange = (e) => {
    e.preventDefault();

    //show preview of uploaded image
    let eTargetFilesArray = Array.from(e.target.files);

    console.log("current files", eTargetFilesArray);

    //enfore image type on upload
    let badImageIndex = [];
    eTargetFilesArray.forEach((imageFile, i) => {
      if (
        imageFile.type !== "image/png" &&
        imageFile.type !== "image/jpg" &&
        imageFile.type !== "image/jpeg"
      ) {
        const message = `Image of type ${imageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;

        badImageIndex.push(i);
        toast.error(message);
        return;
      }
    });

    //remove all unsuported images before uploading them
    badImageIndex.forEach((index) => {
      console.log("target file array BEFORE", eTargetFilesArray);
      eTargetFilesArray.splice(index, 1);
      console.log("target file array AFTER", eTargetFilesArray);
    });

    console.log("tempFiles--", tempFiles.length + eTargetFilesArray.length);
    if (tempFiles.length + eTargetFilesArray.length > 4) {
      return toast.error("Maximum Five (4) Pictures Reached ");
    }
    tempFiles.push(...eTargetFilesArray);

    // e.target.files.forEach(file => {
    //   tempFiles = URL.createObjectURL(file);
    // });
    let tempURLS = [];
    tempFiles.forEach((file) => {
      tempURLS.push(URL.createObjectURL(file));
    });

    this.setState({
      previewURL: tempURLS,
    });

    // tempArray saves the new image into an array and everytime the seller uploads a new image it concats to the tempArray
    let tempArray = this.state.productImages;
    tempArray = tempArray.concat(e.target.files);

    let holdTempImg1 = [];
    tempArray.forEach((image) => {
      holdTempImg1.push(image.name);
    });

    // imageFiles holds all of the image files uploaded by the seller
    this.setState({
      productImages: tempFiles,
      img1: holdTempImg1,
    });
  };

  // this function is the popup when saving the product
  showWaitingPopupWhenProductIsBeingSave = (value) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: "Your product is being saved...",
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {}, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  onDisplayProductInfo = (e) => {
    e.preventDefault();
    const imageFiles = this.state.productImages;
    const featuredImage = this.state.featuredImage;
    this.setState({
      featuredImageError:""
    })

    if(!featuredImage){
      this.setState({
        featuredImageError:"Please upload the main image"
      })
      this.setState({ productUploadError: true });
      return;
    } else {
      this.setState({ displayUploadImage: false });
    }
  };

  onHideProductInfo = () => {
    this.setState({ displayUploadImage: true });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      
      this.showWaitingPopupWhenProductIsBeingSave();
      let {
        productName,
        productDescription,
        productPrice,
        productStock,
        productVariant,
        productWeight,
        productHeight,
        productWidth,
        productLength,
        productCategory,
        sellerFullName,
        currency,
        storeName,
        discount,
        discountedPrice,
        location,
        likes,
        slug,
        sellerId,
        otherColors,
        selectedCatalogs,
        extraVariants,
        variantObject,
        featuredImageError,
        userCurrentCountry
      } = this.state;

      //convert to metric for Nigeria
      if(userCurrentCountry?.toLocaleLowerCase()=="nigeria"){
        productLength=Math.ceil(productLength/2.54);
        productWidth=Math.ceil(productWidth/2.54);
        productHeight=Math.ceil(productHeight/2.54);
        //roundup 3 decimal places
        productWeight=Math.ceil(productWeight*0.0022* 1000) / 1000;
      }else{
        //convert product weight from oz to lbs
        productWeight = parseFloat(productWeight / 16);
      }

      console.log("metric dimensions:",{productWeight,
        productHeight,
        productWidth,
        productLength,})
      const imageFiles = this.state.productImages;
      const featuredImage = this.state.featuredImage;
      console.log("dimensions",productWeight);
      console.log("Storing Featured image");

      productDescription = escape(productDescription); //ESCAPE HTML - this will be decoded when rendering on the front end
      console.log("escaped html product desc", productDescription);
      const featuredImageURL = await storeProductFeaturedImage(
        featuredImage,
        productName,
        productCategory[0]
      );


      const productImagesURL = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const url = await storeProductImagesToStorage(
          imageFiles[i],
          productName,
          productCategory[0]
        );
        productImagesURL.push(url);
      }

      console.log("featImg", featuredImageURL);
      console.log("images", productImagesURL);

      //add other colors to product info if available
      if (otherColors.length > 0) {
        let allColors = productVariant.colors.concat(otherColors);
        productVariant.colors = allColors;
      }

      //Add extra variants
      productVariant.extraVariants = extraVariants;

      //Variant object containing every variant and their corresponding price and quantity
      productVariant.variantObject = variantObject;

      selectedCatalogs.map((catalog) =>
        console.log("Catalog", this.state.storeCatalogs[catalog].label)
      );

      const productInfo = {
        productName,
        productDescription,
        productPrice,
        productStock,
        productVariant,
        productWeight,
        productCategory,
        sellerFullName,
        currency,
        storeName,
        discount,
        discountedPrice,
        location,
        likes,
        slug,
        featuredImageURL,
        productImagesURL,
        productHeight,
        productWidth,
        productLength,
        catalogs: selectedCatalogs.map(
          (value) => this.state.storeCatalogs[value].label
        ), // to extract the catalog name
      };

      console.log("Selected catalogs", productInfo.catalogs);
      const result = await saveProductToDatabase(productInfo);
      console.log("product upload result 10/3", result);

      if (result === "success") {
        this.showWaitingPopupWhenProductIsBeingSave(false);
        let currProdNumber = this.state.productNumber;
        let newProdNumber = currProdNumber + 1;

        //save progress when the user has added the 3rd product
        if (newProdNumber > 2) {
          saveRegisterProgress(sellerId, 5);
        }

        this.setState({
          productUploadSuccess: true,
          productNumber: newProdNumber,
        });
      } else {
        console.log("error saving product", result);
        this.showWaitingPopupWhenProductIsBeingSave(false);
        this.setState({
          productUploadError: true,
        });
      }
    } catch (error) {
      console.log("error uploading product", error);
      if (error === "Wrong Image Type") {

        this.setState({
          featuredImageError:"One or more of your product images were the wrong type. Please only upload JPEG, JPG or PNG type images"
        })
        
        this.showErrorMessage(
          "One or more of your product images were the wrong type. Please only upload JPEG, JPG or PNG type images"
        );
      }
      this.showWaitingPopupWhenProductIsBeingSave(false);
      this.setState({ productUploadError: true });
      return;
    }
  };

  checkSellerInfo = async (sellerUid) => {
    try {
      // const sellerInfo = await getSellersInfo(sellerUid);

      const basicInfo = await getBasicInfo(sellerUid);
      console.log("basicInfo",basicInfo)

      let{subscriptionType}=basicInfo;

      if(!basicInfo?.subscriptionActive || (subscriptionType?.toLocaleLowerCase()=="lite")){
        return this.props.history.push("/seller/dashboard/rewards");

      }

      const sellerInfo = await getSellersInfo(sellerUid);
      const bankSetup = await isBankSetup(sellerUid);
      if (bankSetup === false) {
        toast.error("Please add your bank information before selling");
        return this.props.history.push("/seller/dashboard/setup/bank");
      }

      if (sellerInfo !== "No store") {
        console.log("sellerInfo", sellerInfo);
        const { firstName, lastName, storeName, slug, address } = sellerInfo;
        this.setState({
          firstName,
          lastName,
          sellerFullName: firstName + " " + lastName,
          storeName: storeName,
          slug: slug,
          location: address,
          storeInfo: basicInfo,
        });
      } else {
        toast.error("Please setup a store before adding products");
        return this.props.history.push("/seller/dashboard/setup");
      }
    } catch (error) {
      console.log("error checking seller info", error);
    }
  };
  removeImage = (index) => {
    //Remove image at the index passed in
    this.state.productImages.splice(index, 1);
    this.state.previewURL.splice(index, 1);

    const newProductImagesArray = this.state.productImages;
    const newPreviewURLArray = this.state.previewURL;

    this.setState({
      productImages: newProductImagesArray,
      previewURL: newPreviewURLArray,
    });
  };

  cancel = async () => {
    //empty tempFiles with the user cancels Add Product action
    tempFiles = [];

    this.props.history.push("/seller/dashboard");
  };

  addProductsLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  addMoreProducts = () => {
    window.location.reload();
    //reset everything in the state and input fields/values
    // this.setState({
    //   productImages: [],
    //   productName: "",
    //   productDescription: "",
    //   productPrice: 0,
    //   productStock: 0,
    //   productVariant: { colors: [], sizes: [] },
    //   productWeight: "",
    //   productHeight: 0,
    //   productWidth: 0,
    //   productLength: 0,
    //   productCategory: "",
    //   sellerFullName: "",
    //   slug: "",
    //   currency: "",
    //   storeName: "",
    //   discount: 0,
    //   discountedPrice: 0,
    //   location: "",
    //   lastUpdate: "",
    //   likes: 0,
    //   previewURL: [],
    //   productUploadSuccess: false,
    //   productUploadError: false,
    //   productCategory: [],
    //   selected: [],
    //   displayUploadImage: false,
    //   displayPackageInfo: false,
    // });

    // tempFiles = [];
    // this.componentDidMount();
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };
  selectCategory = ({ selected }) => {
    //limit the number of category selected to 2 MAX

    let productCategoryError = this.state.productCategoryError;
    this.setState({
      productCategoryError:""
    })

    console.log(selected);
    const numberOfItems = selected.length;
    const categoryNames = [];
    if (numberOfItems === 0) {
      this.setState({ selected });
      this.setState({ productCategory: [],
        productCategoryError:"Please select a product category"
      });
    } else if (numberOfItems <= 2) {
      this.setState({ selected });
      selected.forEach((categoryValue) => {
        categoryNames.push(options[categoryValue].label);
        this.setState({ productCategory: categoryNames });
      });
    } else {

      this.setState({
        productCategoryError:"You can only select 2 categories"
      })
      return;
    }
  };

  selectCatalog = ({ selected }) => {
    // console.log(selected)
    this.setState({
      selectedCatalogs: selected,
    });
  };

  selectColor = async ({ selectedColors }) => {
    let productColors = [];
    selectedColors.map((colorsCodes) => {
      const colorName = colorOptions[colorsCodes].label;
      //exclude "Other"
      if (colorName !== "Other") {
        productColors.push(colorName);
        this.displayOtherColorInput(false);
      } else {
        this.displayOtherColorInput(true);
      }
    });
    console.log("prodColor", productColors);
    let productVar = this.state.productVariant;
    productVar.colors = productColors;

    await Promise.resolve(
      this.setState({ selectedColors, productVariant: productVar })
    );
    this.generateVariantCombinations();
  };

  onDisplayPackageInfo = async (e) => {
    e.preventDefault();
    const {
      productName,
      productPrice,
      productDescription,
      currency,
      productStock,
      productCategory,
      extraVariants,
      productNameError,
      productDescriptionError,
      priceError,
      productStockError,
    } = this.state;

    this.setState({
      productNameError: "",
      productDescriptionError: "",
      priceError: "",
      productStockError: "",
      productCategoryError:""
    });

    if (!productName || productName.length <= 1) {
      this.setState({
        productNameError: "Please enter the product name",
      });
      return;
    }

    if(!productDescription || productDescription.length<1){
      this.setState({
        productDescriptionError: "Please enter the product description",
      });
      return;
    }

    if(!productPrice){
      this.setState({
        priceError: "Please enter the product price"
      });
      return;
    }
    //enforce minimum price of N100 for nigeria sellers
    if (productPrice < 100 && (currency === "N" || currency === "NGN")) {
      this.setState({
        priceError: "Please enter a minimum price of N100"
      });
      return;
    }

    if (productStock < 1) {
      this.setState({
        productStockError: "You must have atleast 1 item in stock",
      });
      return;
    }

    let isExtraVariantValid = await this.validateExtraVariants(extraVariants);
    if (!isExtraVariantValid.valid) {
      let index = isExtraVariantValid.index;
      let invalidExtraVariants = this.state.invalidExtraVariants;
      invalidExtraVariants.push(index);
      return this.setState({
        invalidExtraVariants,
      });
    }
    if (
      currency &&
      productName.length > 1 &&
      parseFloat(productPrice) > 0 &&
      parseFloat(productStock) > 0 &&
      productCategory.length > 0
    ) {
      this.setState({ displayPackageInfo: true, displayUploadImage: false });
    } else {

      if (!productCategory.length > 0) {
        this.setState({
          productCategoryError:"Please enter a product category"
        })
        return;
      }
      return toast.error("Please enter all necessary information first");
    }
  };

  onHidePackageInfo = () => {
    this.setState({ displayPackageInfo: false });
  };

  onDisplayUploadImage = (e) => {
    e.preventDefault();
    const {
      productName,
      productPrice,
      productDescription,
      currency,
      productStock,
      productCategory,
      productHeight,
      productWeight,
      productWidth,
      productLength,

      productWeightError,
      productHeightError,
      productLengthError,
      productWidthError,
    } = this.state;

    this.setState({
      productWidthError: "",
      productHeightError: "",
      productLengthError: "",
      productWeightError: "",
    });

    console.log("state", this.state);

    if (!productWeight || parseFloat(productWeight) == 0) {
      this.setState({
        productWeightError: "Please enter the product's weight",
      });
      return;
    } else if (parseFloat(productWeight) > 70) {
      this.setState({
        productWeightError: "The maximum weight for a product is 70 lbs",
      });
      return;
    } else if (parseFloat(productWeight) < 1.2) {
      this.setState({
        productWeightError: "Please enter a weight greater than 1.2 oz",
      });
      return;
    }

    if (!productLength || parseFloat(productLength) <= 0) {
      this.setState({
        productLengthError: "Please enter the product's length",
      });
      return;
    } else if (parseFloat(productLength) > 108) {
      this.setState({
        productLengthError: "The maximum length for a product is 108 Inches",
      });
      return;
    }

    if (!productWidth || parseFloat(productWidth) <= 0) {
      this.setState({
        productWidthError: "Please enter the product's width",
      });
      return;
    } else if (parseFloat(productWidth) > 108) {
      this.setState({
        productWidthError: "The maximum width for a product is 108 Inches",
      });
      return;
    }

    if (!productHeight || parseFloat(productHeight) <= 0) {
      this.setState({
        productHeightError: "Please enter the product's height",
      });
      return;
    } else if (parseFloat(productHeight) > 108) {
      this.setState({
        productHeightError: "The maximum height for a product is 108 Inches",
      });
      return;
    }

    /*
    if (!productHeight || !productWidth || !productLength || !productWeight) {
      return toast.error("Please enter your product dimensions");
    }

    if (
      parseFloat(productHeight) > 108 ||
      parseFloat(productWidth) > 108 ||
      parseFloat(productLength) > 108 ||
      parseFloat(productWeight) > 70
    ) {
      return toast.error("You have exceeded the maximum dimension for product");
    }
*/

    this.setState({ displayUploadImage: true, displayPackageInfo: false });

    /*
    if (
      productName &&
      productPrice &&
      productDescription &&
      currency &&
      productStock &&
      productName.length > 1 &&
      parseFloat(productPrice) > 0 &&
      productDescription.length > 1 &&
      parseFloat(productStock) > 0 &&
      productCategory.length > 0
    ) {
      this.setState({ displayUploadImage: true, displayPackageInfo: false });
    } else {
      return toast.error("Please enter all necessary information first");
    }
*/
  };

  onHideUploadImage = () => {
    this.setState({ displayUploadImage: false, displayPackageInfo: true });
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log({ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
      currency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  getProductNumber = (id) => {
    getNumberOfProducts(id).then((productNumber) => {
      this.setState({
        productNumber,
      });
    });
  };

  getStoreCatalogs = (id) => {
    getCatalogs(id).then((storeCatalogs) => {
      this.setState({
        storeCatalogs: storeCatalogs
          ? storeCatalogs.map((catalog, index) => {
              return {
                label: catalog,
                value: index,
              };
            })
          : [],
      });
    });
  };

  displayOtherColorInput = (val) => {
    if (val === false) {
      //clear the inputed colors from "other" input field
      return this.setState({
        otherColors: [],
        colorTags: [],
        viewOtherColorInput: val,
      });
    }

    this.setState({ viewOtherColorInput: val });
    //highlight the "other color" input field
    this.highLightField("other-colors");
  };

  highLightField = (name) => {
    //highlight input field
    let coloredInputField = this.state.coloredInputField;
    coloredInputField.push(name);
    this.setState({ coloredInputField });

    setTimeout(() => {
      let targetIndex = coloredInputField.indexOf(name);
      coloredInputField.splice(targetIndex, 1);
      this.setState({
        coloredInputField,
      });
    }, 5000);
  };

  addExtraVariants = () => {
    let { extraVariants } = this.state;
    let newVariant = { name: "", value: [] };
    extraVariants.push(newVariant);
    this.setState({ extraVariants });
  };

  handleExtraVariantNameChange = (e) => {
    let index = parseInt(e.target.name);
    let { invalidExtraVariants } = this.state;
    invalidExtraVariants.splice(index, 1);
    let value = e.target.value;
    let extraVariants = this.state.extraVariants;
    extraVariants[index].name = value;
    this.setState({
      extraVariants,
      invalidExtraVariants,
    });
  };

  handleExtraVariantAddition = (tag, i) => {
    let tagArray = [];
    let extraVariants = this.state.extraVariants;
    let extraVariantsTags = this.state.extraVariantsTags;
    let invalidExtraVariants = this.state.invalidExtraVariants;
    invalidExtraVariants.splice(i);
    if (extraVariantsTags[i]) {
      extraVariantsTags[i].push(tag);
    } else {
      extraVariantsTags[i] = [tag];
    }
    console.log("extraVariantTags", extraVariantsTags);
    extraVariantsTags[i].map((tag) => {
      console.log("TAG", tag);
      tagArray.push(tag.text);
    });
    extraVariants[i].value = tagArray;
    this.setState((state) => ({
      extraVariantsTags: extraVariantsTags,
      extraVariants: extraVariants,
      invalidExtraVariants,
    }));
    console.log("extraVariants state", this.state.extraVariants);
    this.generateVariantCombinations();
  };
  ß;

  handleExtraVariantDelete = (j, i) => {
    console.log({ i, j });
    let { extraVariantsTags, extraVariants } = this.state;
    let valueToDelete = extraVariantsTags[i][j].text;
    extraVariantsTags[i] = extraVariantsTags[i].filter(
      (tag, index) => index !== j
    );
    let deletionIndex = extraVariants[i].value.indexOf(valueToDelete);
    extraVariants[i].value.splice(deletionIndex, 1);

    this.setState({
      extraVariantsTags,
      extraVariants,
    });
    this.generateVariantCombinations();
  };

  validateExtraVariants = (variants) => {
    return new Promise((resolve, reject) => {
      variants.map((variant, i) => {
        if (
          variant.value.length < 1 ||
          variant.name.length < 1 ||
          !variant.name.length
        ) {
          resolve({ valid: false, index: i });
        }
      });
      resolve({ valid: true });
    });
  };

  removeExtraVariant = async (i) => {
    let { extraVariants, extraVariantsTags } = this.state;
    extraVariantsTags.splice(i, 1);
    extraVariants.splice(i, 1);

    await Promise.resolve(this.setState({ extraVariants, extraVariantsTags }));
    this.generateVariantCombinations();
  };

  generateVariantCombinations = async () => {
    try {
      let {
        selectedColors,
        otherColors,
        extraVariants,
        productVariant,
        variantObject,
        productPrice,
        productStock,
      } = this.state;
      let sizes = productVariant.sizes;

      let queryData = {
        selectedColors,
        otherColors,
        extraVariants,
        variantObject,
        productPrice,
        productStock,
        sizes,
      };
      let variantObj = await getVariantCombo(queryData);

      this.setState({
        variantObject: variantObj?.tempVariantObj,
      });
    } catch (error) {
      console.log("error generating combinations", error);
    }
  };

  handleVariantPriceChange = (e) => {
    let val = e.target.value;
    let id = e.target.name;
    let { variantObject } = this.state;
    variantObject[id].price = val;
    console.log({ val, id, variantObject });

    this.setState({ variantObject });
  };

  handleVariantQuantityChange = (e) => {
    let val = e.target.value;
    let id = e.target.name;
    let { variantObject } = this.state;
    variantObject[id].quantity = val;
    console.log({ val, id, variantObject });

    this.setState({ variantObject });
  };

  onEditComboPrices = (val) => {
    this.setState({ editCombinationPrices: val });
  };

  componentDidMount() {
    NProgress.start();
    // check if seller is logged in
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.generateVariantCombinations();
        this.checkSellerInfo(user.uid);
        this.getLocation();
        this.getProductNumber(user.uid);
        NProgress.set(0.5);
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );
        this.getStoreCatalogs(user.uid);
        this.setState({
          sellerId: user.uid,
        });
        NProgress.done();
      } else {
        this.props.history.push("/");
      }
    });

    tempFiles = [];
  }

  render() {
    return (
      <>
        <ToastContainer />
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            <AddProdForm
              productUploadSuccess={this.state.productUploadSuccess}
              productUploadError={this.state.productUploadError}
              displayUploadImage={this.state.displayUploadImage}
              displayPackageInfo={this.state.displayPackageInfo}
              productName={this.state.productName}
              productPrice={this.state.productPrice}
              currency={this.state.currency}
              productDescription={this.state.productDescription}
              userCurrentCountry={this.state.userCurrentCountry}
              productStock={this.state.productStock}
              selected={this.state.selected}
              selectedColors={this.state.selectedColors}
              tags={this.state.tags}
              productWeight={this.state.productWeight}
              productWidth={this.state.productWidth}
              productHeight={this.state.productHeight}
              productLength={this.state.productLength}
              productWeightError={this.state.productWeightError}
              productHeightError={this.state.productHeightError}
              productLengthError={this.state.productLengthError}
              productWidthError={this.state.productWidthError}
              loading={this.state.loading}
              productNumber={this.state.productNumber}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              handleFeaturedImageChange={this.handleFeaturedImageChange}
              handleImageChange={this.handleImageChange}
              selectCategory={this.selectCategory}
              selectColor={this.selectColor}
              handleAddition={this.handleAddition}
              handleDrag={this.handleDrag}
              handleDelete={this.handleDelete}
              onDisplayPackageInfo={this.onDisplayPackageInfo}
              onHidePackageInfo={this.onHidePackageInfo}
              onHideUploadImage={this.onHideUploadImage}
              onHideProductInfo={this.onHideProductInfo}
              onDisplayUploadImage={this.onDisplayUploadImage}
              onDisplayProductInfo={this.onDisplayProductInfo}
              addMoreProducts={this.addMoreProducts}
              options={options}
              colorOptions={colorOptions}
              errorMessage={this.state.errorMessage}
              viewOtherColorInput={this.state.viewOtherColorInput}
              colorTags={this.state.colorTags}
              handleColorAddition={this.handleColorAddition}
              handleColorDrag={this.handleColorDrag}
              handleColorDelete={this.handleColorDelete}
              storeCatalogs={this.state.storeCatalogs}
              selectedCatalogs={this.state.selectedCatalogs}
              selectCatalog={this.selectCatalog}
              coloredInputField={this.state.coloredInputField}
              handleEditorChange={this.handleEditorChange}
              text={this.state.productDescription}
              addExtraVariants={this.addExtraVariants}
              extraVariants={this.state.extraVariants}
              handleExtraVariantAddition={this.handleExtraVariantAddition}
              extraVariantsTags={this.state.extraVariantsTags}
              handleExtraVariantDelete={this.handleExtraVariantDelete}
              handleExtraVariantNameChange={this.handleExtraVariantNameChange}
              invalidExtraVariants={this.state.invalidExtraVariants}
              removeExtraVariant={this.removeExtraVariant}
              variantObject={this.state.variantObject}
              handleVariantPriceChange={this.handleVariantPriceChange}
              onEditComboPrices={this.onEditComboPrices}
              editCombinationPrices={this.state.editCombinationPrices}
              handleVariantQuantityChange={this.handleVariantQuantityChange}
              productNameError={this.state.productNameError}
              productDescriptionError={this.state.productDescriptionError}
              priceError={this.state.priceError}
              productStockError={this.state.productStockError}
              productCategoryError={this.state.productCategoryError}
              featuredImageError={this.state.featuredImageError}
              skipForm={this.skipForm}
              clickAdd={this.clickAdd}
              clickAddVariant={this.clickAddVariant}
            />
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="addProduct"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
