import firebase from "../../firebaseConfig";

const getDispatchedOrders = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      var today = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // change to  var today = new Date(Date.now()) for Sales Today, # 30 days change 7 to 30days
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + " " + time;

      let transactions = await db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("orders")
        .where("orderStatus", "==", "fulfilled") // change to 'fulfilled' #Dispatched
        // .where("orderTimestamp", "<", dateTime)  //comment out to remove date range
        .orderBy("orderTimestamp", "desc")
        .get();
      if (transactions.empty) {
        console.log("No dispatched orders found.");
        resolve(0);
      }
      let orders = [];
      let count = 0;
      let sum = 0;

      transactions.forEach((doc) => {
        orders.push(doc.data());
        let orderCount = doc.data().orderIds ? doc.data().orderIds.length : 1;
        count += orderCount;
        // sum = sum + (doc.data().productPrice - 1.5% for nigeria, - 3% for united states)
      });
      resolve(count);
    } catch (error) {
      console.log("error getting dispatched orders", error);
      reject(error);
    }
  });
};
export default getDispatchedOrders;
