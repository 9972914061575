import React, { useState, useEffect } from "react";
import PaymentPlans from "./PaymentPlans";
import SubscriptionTable from "./SubscriptionTable";
import BillingTypes from "./BillingTypes";
import SubscriptionNavBar from "./SubscriptionNavBar";
import LitePlan from "./LitePlan";
import MonthlyPlan from "./MonthlyPlan";
import YearlyPlan from "./YearlyPlan";
import SubPlan from "./SubPlan";
import ConfirmDowngrade from "./ConfirmDowngrade";

import { FLUTTERWAVE_KEY,
  flutterwaveMonthly,
  flutterwaveQuarterly,
  flutterwaveBiAnnually,
  flutterwaveYearly,

  stripeMonthly,
  stripeQuarterly,
  stripeBiAnnually,
  stripeYearly,

  paystackMonthly,
  paystackQuarterly,
  paystackBiAnnually,
  paystackYearly,
  paystackLite,
  free_planId,

  PAYSTACK_SUBACCOUNT,
  PAYSTACK_KEY,
  FREE_TRIAL,
  CANCEL_SUBSCRIPTION,
  cardApplicationFee

} from "../../../../secrets";

import firebase from "../../../../firebaseConfig";

const FREE_TRIAL_PRICE=100;


function SubscriptionInfo(props) {
  const [showInfo, setShowInfo] = useState(false);
  const [activeTab, setActiveTab] =useState("Quarterly");
  const [subscriptionType, setSubscriptionType] = useState({
    monthly: false,
    quarterly: false,
    annually: false,
    personal: false,
    downgrade: false
  });
  const [showDowngrade, setShowDowngrade] = useState(false)

  const toggleDowngrade=(display)=>{
    setShowDowngrade(display)

  }

  const changeTab=(tab)=>{
    console.log("changeTab",tab);
    setActiveTab(tab);
  }

  const displayInfo = () => {
    console.log("toggle show info", !showInfo);
    setShowInfo(!showInfo);
  };

  const cancelSubscription =async()=>{

    try{
      setShowDowngrade(false);
      let { firstName, lastName, phoneNumber, email, id, slug } = props.storeInfo;

      let period="lite";

      let downgrade=subscriptionType?.downgrade;
      let successType="downgrade";
      props.activateSubscription(period,successType);
      setSubscriptionType({
        monthly: false,
        quarterly: false,
        annually: false,
        personal: false,
        downgrade: false
      });

      let accountType="Personal";
      updateSellerDoc(id, period,accountType );

      if(downgrade){
        callDowngradeApi(email);
      }

    }catch(error){
      console.log("error",error)
    }

  }

  const activateLite=()=>{

    let { id } = props.storeInfo;

    let period="Lite";
    let successType="personal";
    props.activateSubscription(period,successType); 
    setSubscriptionType({
      monthly: false,
      quarterly: false,
      annually: false,
      personal: false,
      downgrade: false
    });

    let accountType="Personal";
    updateSellerDoc(id, period,accountType );


  }

  const paystackOnetimePay= async()=>{

    setShowDowngrade(false);
    let { firstName, lastName, phoneNumber, email, id, slug } = props.storeInfo;

    let period="Lite";
    let price=100000;

    var handler = PaystackPop.setup({
      subaccount: PAYSTACK_SUBACCOUNT,
      key: PAYSTACK_KEY,
      email,
      amount: price,
      currency: "NGN",
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: phoneNumber,
          },
        ],
        
        sellerId: id,
        slug,
        paymentMethod: "paystack",
        amount: price/100,
        currency: "NGN",
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
      },
      callback: (response) => {
        
        let downgrade=subscriptionType?.downgrade;
        let successType="personal";
        props.activateSubscription(period,successType); 
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: false,
          personal: false,
          downgrade: false
        });

        let accountType="Personal";
        updateSellerDoc(id, period,accountType );

        if(downgrade){
          callDowngradeApi(email);
        }

      },
      onClose: () => {
        console.log("window closed");
      },
    });
    handler.openIframe();
  }


  const paystackSubscription = async (price, priceId, period) => {
    console.log("Start Subscription", { price, priceId });

    let { firstName, lastName, phoneNumber, email, id, slug } = props.storeInfo;


    price=price*100;
    var handler = PaystackPop.setup({

      plan: priceId,
      subaccount: PAYSTACK_SUBACCOUNT,
      key: PAYSTACK_KEY,
      email,
      amount: price,
      currency: "NGN",
      channels: ['card'],
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: phoneNumber,
          },
        ],
        
        sellerId: id,
        slug,
        paymentMethod: "paystack",
        amount: price/100,
        currency: "NGN",
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
      },
      callback: (response) => {
        console.log("paystack Responce: ", response);
        console.log("success. transaction ref is " + response.reference);

        console.log("Flutterwave Responce: ", response);
        console.log("success. transaction ref is " + response.transaction);
        let successType="subscription";
        props.activateSubscription(period,successType); 
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: false,
          personal: false,
          downgrade: false
        });

        let accountType="Business";
        updateSellerDoc(id, period,accountType);
      },
      onClose: () => {
        console.log("window closed");
      },
    });
    handler.openIframe();


  };

  const updateSellerDoc= async(sellerId, period,accountType)=>{
    try{

      let subscriptionType=period?.toLowerCase();

      if(subscriptionType=="yearly"){
        subscriptionType="annually"
      }
      if(!subscriptionType){
        subscriptionType="";
      }

      const db = firebase.firestore();
      const sellerRef = await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .get();

      let sellerData = sellerRef.data();
      console.log("sellerData=>", sellerData);

      await db.collection("sellersAccounts").doc(sellerId).set(
        {
          subscriptionActive: true,
          subscriptionType,
          accountType
        },
        { merge: true }
      );

    }catch(error){
      console.log("error updating seller subaccount", error)
    }

  }



  const startFreeTrial =(priceId, period) => {

    let {
      firstName, 
      lastName, 
      email,
      phoneNumber,
      id,
      slug}=props.storeInfo;

    let price=FREE_TRIAL_PRICE;
    //Convert to cents
    price=price*100;

    var handler = PaystackPop.setup({

      // plan: free_planId,
      subaccount: PAYSTACK_SUBACCOUNT,
      key: PAYSTACK_KEY,
      email,
      amount: price,
      currency: "NGN",
      channels: ['card'],
      metadata: {
        custom_fields: [
          {
            display_name: "Mobile Number",
            variable_name: "mobile_number",
            value: phoneNumber,
          },
        ],
        
        sellerId: id,
        slug,
        paymentMethod: "paystack",
        amount: price/100,
        currency: "NGN",
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
      },
      callback: (response) => {
        console.log("paystack Responce: ", response);
        console.log("success. transaction ref is " + response.reference);
        console.log("success. transaction ref is " + response.transaction);
        
        callFreeTrialAPI(email, id, priceId, period);
        // window.location.reload(false);

      },
      onClose: () => {
        
      },
    });
    handler.openIframe();

  }

  const disableFree= async(sellerId,period)=>{

    let subscriptionType=period?.toLowerCase();

    if(subscriptionType=="yearly"){
      subscriptionType="annually"
    }
    if(!subscriptionType){
      subscriptionType="";
    }

    const db = firebase.firestore();
    const sellerRef = await db
      .collection("sellersAccounts")
      .doc(sellerId)
      .get();

    let sellerData = sellerRef.data();
    console.log("sellerData=>", sellerData);

    await db.collection("sellersAccounts").doc(sellerId).set(
      {
        freeTrialUsed: true,
        subscriptionActive: true,
        subscriptionType:"freetrial",
        accountType:"Business"
      },
      { merge: true }
    );

  }


  const callDowngradeApi = async (email)=>{

    try{
      
      let sellerId=props.storeInfo?.id;
      let slug=props.storeInfo?.slug;

      const queryData = {
        "email":email,
        sellerId,
        slug
      };
      console.log("callDowngradeApi",queryData);
      const res = await fetch(  CANCEL_SUBSCRIPTION, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });
      let response = await res?.json();
      console.log("response",response);


    }catch (error) {

      console.log(" failed to create subscription=>",error);
        
    }

  }

  const callFreeTrialAPI= async (email, id, priceId, period)=>{

    try{
      

      const queryData = {
        "email":email,
        "plan":  priceId,// Standard Monthly Plan - NOT FREE TRIAL
        "code":"CASHAAM30"

      };
      console.log("callFreeTrialAPI",queryData);
      const res = await fetch(  FREE_TRIAL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });
      let response = await res?.json();
      console.log("response",response);

      // window.location.reload(false);

      if (res.status === 200) {

        let successType="subscription";
        props.activateSubscription("freetrial",successType);  
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: false,
          personal: false,
          downgrade: false
        });

        disableFree(id, period);

      } else {
        
      }

    }catch (error) {

      console.log(" failed to create subscription=>",error);
        
    }

  }



  const flutterwaveSubscription = async (price, priceId) => {
    console.log("Start Subscription", { price, priceId });

    let { firstName, lastName, phoneNumber, email, id } = props.storeInfo;

    window.FlutterwaveCheckout({
      public_key: FLUTTERWAVE_KEY,
      amount: price,
      currency: "NGN",
      payment_options: "card",
      payment_plan: priceId,
      tx_ref: `${id}`,
      meta: {},
      customer: {
        email,
        phonenumber: phoneNumber,
        name: `${firstName} ${lastName}`,
      },
      subaccounts: [{}],
      callback: (response) => {
        console.log("Flutterwave Responce: ", response);
        console.log("success. transaction ref is " + response.transaction_id);
      },
      customizations: {
        title: "Cashaam",
        description: "Subscription to Cashaam",
        logo: "https://cdn.filestackcontent.com/sF94ilF6TY2vgh9thMrH",
      },
    });
  };

  // useEffect(() => {
  //   let subscriptionActive = props.storeInfo?.subscriptionActive;

  //   if (!subscriptionActive) {
  //     setShowInfo(true);
  //   }


  // }, []);

  const upgradeSubscription =()=>{

    let subscriptionActive = props.storeInfo?.subscriptionActive;
    let subscriptionType = props.storeInfo?.subscriptionType;
    let subscriptionHistory=props.storeInfo?.subscriptionHistory;
    console.log("show upgrade from",subscriptionType);

    if(subscriptionActive){

      if(subscriptionType?.toLowerCase()=="lite"||subscriptionType?.toLowerCase()=="personal"){
        setSubscriptionType({
          monthly: true,
          quarterly: true,
          annually: true,
          personal: false,
          downgrade: false
        });

        setActiveTab("Monthly")
      }else if(subscriptionType?.toLowerCase()=="monthly"){
        setSubscriptionType({
          monthly: false,
          quarterly: true,
          annually: true,
          // personal: subscriptionHistory? true: false,
          personal:false,
          downgrade: false
        });


      }else if(subscriptionType?.toLowerCase()=="quarterly"){
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: true,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });
        setActiveTab("Yearly")


      }else if(subscriptionType?.toLowerCase()=="biannually"){
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: true,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });
        setActiveTab("Yearly")

      }else if(subscriptionType?.toLowerCase()=="annually"){
        setSubscriptionType({
          monthly: false,
          quarterly: false,
          annually: false,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });

        setActiveTab("Personal")

      }else if(subscriptionType?.toLowerCase()=="freetrial"){

        setSubscriptionType({
          monthly: true,
          quarterly: true,
          annually: true,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });

      }

    }else{

      let accountType= props.storeInfo?.accountType;
      if(accountType?.toLowerCase()=="personal"){
        setSubscriptionType({
          monthly: true,
          quarterly: false,
          annually: false,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });

        setActiveTab("Personal")
      }else{
        setSubscriptionType({
          monthly: true,
          quarterly: true,
          annually: false,
          // personal: subscriptionHistory? true: false,
          personal: false,
          downgrade: false
        });
      }
      

    }

  }

  useEffect(() => {

    let subscriptionActive = props.storeInfo?.subscriptionActive;
    let subscriptionType = props.storeInfo?.subscriptionType;
    let subscriptionHistory=props.storeInfo?.subscriptionHistory;

    console.log("default sub",subscriptionActive);
    console.log("default storeInfo",props.storeInfo);
    if(props.storeInfo && !subscriptionActive){

      let accountType=props.storeInfo?.accountType;
      // if(accountType?.toLowerCase()=="personal" &&(subscriptionType!="lite")){
      //   console.log("set personal account")
      //   setSubscriptionType({
      //     monthly: true,
      //     quarterly: true,
      //     annually: false,
      //     personal: false,
      //     downgrade: false
      //   });

      //   setActiveTab("Personal")
      // }else{
      //   setSubscriptionType({
      //     monthly: true,
      //     quarterly: true,
      //     annually: true,
      //     personal: false,
      //     downgrade: false
      //   });
      //   setActiveTab("Monthly")
      // }

      setSubscriptionType({
        monthly: true,
        quarterly: true,
        annually: true,
        personal: false,
        downgrade: false
      });
      setActiveTab("Monthly")
      

    }

  },[props.storeInfo])


  return (
    <div className="subscription-info">
      {props.storeInfo?.subscriptionActive ? (
        <div className="subscription-status sub-active">
          {props.storeInfo?.subscriptionType && props.storeInfo?.subscriptionType?.length > 1 ?
            (props.storeInfo?.subscriptionType=="freetrial"?
              <div className="subscription-text">Your Free Trial is <strong>Active</strong>.</div>
              :
              (props.storeInfo?.accountType=="Personal" || props.storeInfo?.subscriptionType=="lite"?
              <div className="subscription-text">You are currently on a <strong>Personal Plan</strong>.</div>
              :
              <div className="subscription-text">Your {props.storeInfo?.subscriptionType[0].toUpperCase()}{props.storeInfo?.subscriptionType?.slice(1).toLowerCase()} Subscription is <strong>Active</strong>.</div>
              )
            )
          : 
          <div className="subscription-text">Your Subscription is <strong>Active</strong>.</div>
          }
          
          {props.storeInfo?.subscriptionType?.toLowerCase()!="annually"?
            <div 
              className="upgrade-btn"
              onClick={upgradeSubscription}
            >
              Upgrade for Exciting Features
            </div>
            :null}
          
        </div>
      ) : (
        <>
          <div style={{padding:"15px", display: "block"}} className="subscription-status not-active">
            Your Subscription is <strong>Not Active</strong>.
          </div>
          <div className="get-started">
            Get started with a Cashaam subscription that works for you.
          </div>
        </>
      )}


      {subscriptionType?.monthly || subscriptionType?.quarterly || subscriptionType?.annually || subscriptionType?.personal?
        <div style={{ marginTop: "20px", marginBottom: "30px" }} className="grid__item">
          <div className="pricing-redesign__cards-layout pricing-redesign__cards">
            <div className="static_pricing-cards__wrapper">
              <SubscriptionNavBar 
              changeTab={changeTab}
              activeTab={activeTab}
              country={props.storeInfo?.country}
              monthly={subscriptionType?.monthly}
              quarterly={subscriptionType?.quarterly}
              annually={subscriptionType?.annually}
              personal={subscriptionType?.personal}
              downgrade={subscriptionType?.downgrade}
              accountType={props.storeInfo?.accountType}
              subscriptionType={props.storeInfo?.subscriptionType}
              />

              {showDowngrade?
                <ConfirmDowngrade 
                  cancelSubscription={cancelSubscription}
                  toggleDowngrade={toggleDowngrade}
                />
              :null}

              {props.storeInfo?.country &&
              props.storeInfo?.country?.toLowerCase() == "nigeria" ? 
                <div className="static-container pricing-redesign__cards-container pricing-redesign__cards-container">

                  {/* {props.storeInfo?.accountType=="Personal" && props.storeInfo?.subscriptionType!="lite"?
                  <>
                    {subscriptionType?.personal?
                    <LitePlan 
                      period="Personal"
                      price={1000}
                      shortForm=""
                      description="Our most popular plan let's you save for 2 month. Pay the whole year for only ₦60,500"
                      priceId={paystackLite}
                      paymentMethod="paystack"
                      paystackOnetimePay={paystackOnetimePay}
                      currency="₦"

                      activeTab={activeTab}
                      freeTrial={props.freeTrial}
                      downgrade={subscriptionType?.downgrade}
                      toggleDowngrade={toggleDowngrade}
                    />
                    :null
                    }

                    {subscriptionType?.monthly?
                      <SubPlan 
                        period="Monthly"
                        price={6078}
                        shortForm="mo"
                        description="Everything you need to create your store, ship products, and process payments. "
                        priceId={paystackMonthly}
                        paymentMethod="paystack"
                        paystackSubscription={paystackSubscription}
                        currency="₦"
    
                        activeTab={activeTab}
                        freeTrial={props.freeTrial}
                        startFreeTrial={startFreeTrial}
                        subscriptionType={props.storeInfo?.subscriptionType}
                        accountType={props.storeInfo?.accountType}
                      />
                      :null}
                    </>
                  : */}
                  <>

                    {subscriptionType?.personal?
                    <LitePlan 
                      period="Personal"
                      price={1000}
                      shortForm=""
                      description="Our most popular plan let's you save for 2 month. Pay the whole year for only ₦60,500"
                      priceId={paystackLite}
                      paymentMethod="paystack"
                      paystackOnetimePay={paystackOnetimePay}
                      currency="₦"

                      activeTab={activeTab}
                      freeTrial={props.freeTrial}
                      downgrade={subscriptionType?.downgrade}
                      toggleDowngrade={toggleDowngrade}

                    />
                    :null}

                  {subscriptionType?.monthly?
                    <SubPlan 
                      period="Monthly"
                      price={1000}
                      shortForm="mo"
                      description="Everything you need to create your store, ship products, and process payments. "
                      priceId={paystackMonthly}
                      paymentMethod="paystack"
                      paystackSubscription={paystackSubscription}
                      currency="₦"
  
                      activeTab={activeTab}
                      freeTrial={props.freeTrial}
                      startFreeTrial={startFreeTrial}
                      subscriptionType={props.storeInfo?.subscriptionType}
                      accountType={props.storeInfo?.accountType}
                    />
                    :null}

                  {subscriptionType?.quarterly?
                  <SubPlan 
                    period="Quarterly"
                    price={15210}
                    shortForm="3mo"
                    description="This plan will let you pay every 3 month and grants you access to all premium features in Cashaam."
                    priceId={paystackQuarterly}
                    paymentMethod="paystack"
                    paystackSubscription={paystackSubscription}
                    currency="₦"

                    activeTab={activeTab}
                    freeTrial={props.freeTrial}
                    startFreeTrial={startFreeTrial}
                    subscriptionType={props.storeInfo?.subscriptionType}
                    accountType={props.storeInfo?.accountType}
                  />
                  :null}

                  {subscriptionType?.annually?
                  <SubPlan 
                    period="Yearly"
                    price={60500}
                    shortForm="yr"
                    description="Get the best of Cashaam, Amazon, Instagram Shopping and more."
                    priceId={paystackYearly}
                    paymentMethod="paystack"
                    paystackSubscription={paystackSubscription}
                    currency="₦"

                    activeTab={activeTab}
                    freeTrial={props.freeTrial}
                    startFreeTrial={startFreeTrial}
                    subscriptionType={props.storeInfo?.subscriptionType}
                    accountType={props.storeInfo?.accountType}
                  />
                  :null}
                  </>
                  
                  
                </div>
                :
                
                <div className="static-container pricing-redesign__cards-container pricing-redesign__cards-container">

                  {subscriptionType?.personal?
                    <LitePlan 
                      period="Personal"
                      price={1000}
                      shortForm=""
                      description="Our most popular plan let's you save for 2 month. Pay the whole year for only ₦60,500"
                      priceId={cardApplicationFee}
                      paymentMethod="stripe"
                      paystackOnetimePay={paystackOnetimePay}
                      currency="₦"
                      email={props.storeInfo?.email}
                      storeInfo={props.storeInfo}
                      eventType="CARD_APPLICATION_FEE"

                      activeTab={activeTab}
                      freeTrial={props.freeTrial}
                      downgrade={subscriptionType?.downgrade}
                      toggleDowngrade={toggleDowngrade}
                      activateLite={activateLite}
                    />
                    :null
                    }
                  {subscriptionType?.monthly?
                  <SubPlan 
                    period="Monthly"
                    price={9.99}
                    shortForm="mo"
                    description="Everything you need to create your store, ship products, and process payments."
                    priceId={stripeMonthly}
                    paymentMethod="stripe"
                    currency="$"
                    email={props.storeInfo?.email}

                    activeTab={activeTab}   
                    subscriptionType={props.storeInfo?.subscriptionType} 
                    accountType={props.storeInfo?.accountType}             
                  />
                  :null}

                  {subscriptionType?.quarterly?
                  <SubPlan 
                    period="Quarterly"
                    price={26.99}
                    shortForm="3mo"
                    description="This plan will let you pay every 3 month and grants you access to all premium features in Cashaam for only $8.99/mo."
                    priceId={stripeQuarterly}
                    paymentMethod="stripe"
                    currency="$"
                    email={props.storeInfo?.email}

                    activeTab={activeTab} 
                    subscriptionType={props.storeInfo?.subscriptionType}
                    accountType={props.storeInfo?.accountType}
                  />
                  :null}

                  {subscriptionType?.annually?
                  <SubPlan 
                    period="Yearly"
                    price={99.99}
                    shortForm="yr"
                    description="Get the best of Cashaam, Amazon, Instagram Shopping and more for only $99.99."
                    priceId={stripeYearly}
                    paymentMethod="stripe"
                    currency="$"
                    email={props.storeInfo?.email}

                    activeTab={activeTab}
                    subscriptionType={props.storeInfo?.subscriptionType}
                    accountType={props.storeInfo?.accountType}
                  />
                  :null}

                </div>
              }
            </div>
          </div>
        </div>

        :null
      }

      {props.storeInfo?.subscriptionHistory?
        <SubscriptionTable subscriptionHistory={props.storeInfo?.subscriptionHistory}/> 
      :null}     

    </div>
  );
}

export default SubscriptionInfo;
