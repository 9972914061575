import React from "react";
import Loader from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";

function PaypalTransfer(props) {
  return (
    <div className="fixedmodalwrapper gray-background">
      <div className="fixedmodalcontainer fixed-modal">
        <div className="fixedmodalhead itemaddedmodalhead">
          {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
          <div className="text-block-4" style={{ margin: "auto", fontWeight: "bold" }}>
            Transfer Money
          </div>
        </div>

        {props.loadingContent === true ? (
          <div className="loading-content" style={{ width: "300px" }}>
            <Loader type="TailSpin" color="#7455c1" height={100} width={100} />
            <div>Loading...</div>
          </div>
        ) : (
            props.userMessage? 
            
            (<div className="airtime-resp-container">
              {props.transferSuccess===true? 
             <div className="success-payment"></div>
             :
             <div className="error-payment"></div>
              }
              <div className="airtime-resp">{props.userMessage}</div>
              
                
            </div>):


          <div className="paymentmodalbuttonwrap">
            <div className="column transfer-container">

              {props.errorEmail ? (
                <div className="error-message">{props.errorEmail}</div>
              ) : (
                <label htmlFor="updateProfileFirstName" className="formlabel">
                 Email
                </label>
              )}
              <input
                value={props.paypalEmail}
                onChange={props.handleChange}
                type="text"
                className={`formtextfield tabsprofiletextfield ${
                  props.errorEmail ? "input-error" : ""
                }`}
                maxLength={256}
                name="paypalEmail"
                data-name="Enter Your Paypal Email"
                placeholder="Enter Your Paypal Email"
                id="updateProfileFirstName"
              />

              {props.errorPhone ? (
                <div className="error-message">{props.errorPhone}</div>
              ) : (
                <label htmlFor="updateProfileFirstName" className="formlabel">
                  Phone
                </label>
              )}
              
              <PhoneInput
                containerClass="tabsprofiletextfield"
                containerStyle={{
                  marginBottom: "16px",
                }}
                inputStyle={{
                  marginLeft: "9%",
                  width: "90%",
                  height: "50px",
                  padding: "13px 17px",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "#d1d5db",
                  borderRadius: "5px",
                  boxShadow: " 0 -1px 2px 0 rgb(0 0 0 / 5%)",
                  fontSize: "16px",
                  lineHeight: "1.5em",
                }}
                inputClass={`${props.errorPhone ? "input-error" : ""}`}
                dropdownClass="phone-dropdown"
                country={"us"}
                value={props.paypalPhone?.toString()}
                onChange={(paypalPhone) => props.onPhoneChange(paypalPhone)}
              />

              <input
                type="button"
                onClick={props.onSubmit}
                defaultValue="Transfer"
                data-wait="Please wait..."
                id="updateProfileSubmit"
                className="formselectbutton tabupdatebutton"
              />
            </div>
          </div>
        )}
        <a
          onClick={props.onHide}
          className="fixedmodalexitimg w-inline-block"
        />
      </div>
    </div>
  );
}

export default PaypalTransfer;
