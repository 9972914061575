import React from "react";

function ReferralTable(props) {
  return (
    <>
      {/* <h2 className="history-title">Subscription History</h2> */}
      <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Subscription</th>
          <th>Reward</th>
          <th>Status</th>
        
        </tr>
      </thead>
      <tbody>
        {props.referralList?.map((item, index) => {

            let paidReferrals=0;
          return (
            <>
              <tr>
                <td style={{textTransform: "uppercase"}}>{item.date}</td>
                {item.subscriptionActive && item.subscriptionType?
                    <td>{item.subscriptionType}</td>
                    :
                    <td>-</td>
                }

                {item.subscriptionActive?
                    (item.subscriptionType=="lite"?
                        <td>₦100</td>
                        :
                        (item.subscriptionType=="monthly" 
                        || item.subscriptionType=="quarterly" 
                        || item.subscriptionType=="annually"?
                            <td>₦3,000</td>
                            :
                            <td>-</td>
                        )
                    )
                    :
                    <td>-</td>
                }

                {item.subscriptionActive && item.subscriptionType?

                  item.subscriptionType=="lite"?(
                    <td className="referral-status" style={{textAlign: "center"}}>
                        <div className="referral-paid">Active</div>
                    </td>
                  )
                  :(item?.subscriptionHistory && item.subscriptionHistory?.length>1?
                    <td className="referral-status" style={{textAlign: "center"}}>
                        <div className="referral-paid">Active</div>
                    </td>
                    :
                    <td className="referral-status" style={{textAlign: "center"}}>
                      <div className="referral-incomplete">Inactive</div>
                    </td>
                  )
                    :
                    <td className="referral-status" style={{textAlign: "center"}}>
                        <div className="referral-incomplete">Inactive</div>
                    </td>
                }

              </tr>
            </>
          );
        })}
      </tbody>
      <tfoot></tfoot>
      </table>
    </>
  );
}

export default ReferralTable;
