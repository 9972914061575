import firebase from "../../firebaseConfig";

const saveSelfShipping = (
    selfShippingObj
) => {
    return new Promise(async (resolve, reject) => {
        try {

            //let { orderIds, customerId, sellerId, shippingId, provider, trackingNumber } = selfShippingObj;

            let{

              sellerEmail,
              sellerPhoneNumber,
              sellerId,
              slug,
              shippingPrice,
              trackingNumber,
              trackingUrl,
              trackingUrlProvider,
              customerId,
              customerName,
              customerEmail,
              phoneNo,
              trackingId,
              orderIds,
              orderStatus,
              transactionId,
              shippingObject,
              estimatedPrice,
              eta,
              paidSeller,
      
            }=selfShippingObj;
            const db = firebase.firestore();
            let batch = db.batch();

            await Promise.all(
                orderIds.map((orderId) => {

                console.log("updating order with id ==>", orderId);
                  let sellerRef = db
                    .collection("sellersAccounts")
                    .doc(sellerId)
                    .collection("stores")
                    .doc(sellerId)
                    .collection("orders")
                    .doc(orderId);
        
                  let buyerRef = db
                    .collection("buyersAccounts")
                    .doc(customerId)
                    .collection("orders")
                    .doc(orderId);
        
        
                  batch.set(
                    sellerRef,
                    {
                        // shippingId,
                        trackingNumber,
                        provider: trackingUrlProvider,
                        scheduleObject: selfShippingObj,
                        orderStatus: "dispatched",
                    },
                    { merge: true }
                  );
        
                  batch.set(
                    buyerRef,
                    {
                        // shippingId,
                        trackingNumber,
                        provider: trackingUrlProvider,
                        scheduleObject: selfShippingObj,
                        orderStatus: "dispatched",
                      
                    },
                    { merge: true }
                  );
        
                })
            );

            let selfShippingRef = db
                .collection("selfShipping")
                .doc(transactionId);



            batch.set(selfShippingRef, {
              sellerEmail,
              sellerPhoneNumber,
              sellerId,
              slug,
              shippingPrice,
              trackingNumber,
              trackingUrl,
              trackingUrlProvider,
              customerId,
              customerName,
              customerEmail,
              phoneNo,
              trackingId,
              orderIds,
              orderStatus,
              transactionId,
              shippingObject,
              estimatedPrice,
              eta,
              paidSeller,
            }, { merge: true });


            // let buyerOrderRef = buyerRef.doc(orderId);
            // let sellerOrderRef = sellerRef.doc(orderId);

            // batch.set(
            //     buyerOrderRef,
            //     {
            //         shippingId,
            //         trackingNumber,
            //         provider,
            //         scheduleObject: selfShippingObj,
            //         orderStatus: "dispatched",
            //     },
            //     { merge: true }
            // );
            // batch.set(
            //     sellerOrderRef,
            //     {
            //         shippingId,
            //         trackingNumber,
            //         provider,
            //         scheduleObject: selfShippingObj,
            //         orderStatus: "dispatched",
            //     },
            //     { merge: true }
            // );

            await batch.commit();

            resolve("success");
        } catch (error) {
            console.log("error saving scheduled pick up", error);
            reject(error);
        }
    });
};
export default saveSelfShipping;
