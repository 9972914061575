import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";

class UserReferral extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: "60px" }} className="card affiliate-code">
        <div className="card__body affiliate-code__body">
          <span
            role="img"
            aria-label="Share flodesk"
            className="affiliate-code__icon"
          >
            💸
          </span>
          <div className="section">
            <div className="section__header affiliate-code__header">
              <div className="section__title">
                <div style={{ maxWidth: 234 }}>Share and Earn</div>
              </div>
              <div className="section__subtitle affiliate-code__subtitle">
                <div style={{ maxWidth: 303 }}>
                  Earn $5 to signup friends and Family on Cashaam
                </div>
              </div>
            </div>
            {this.props.shortReferral ? (
              this.props.copied ? (
                <label
                  className="form-label affiliate-code__label copied-clipboard"
                  htmlFor
                >
                  Copied referral link to clipboard!
                </label>
              ) : (
                <label className="form-label affiliate-code__label" htmlFor>
                  Copy your referral link
                </label>
              )
            ) : (
              <label
                style={{ width: "100%" }}
                className="form-label affiliate-code__label copied-clipboard"
                htmlFor
              >
                Login to get a referral code
              </label>
            )}

            {this.props.shortReferral ? (
              <div className="affiliate-code__form">
                <div className="form-group">
                  <form
                    className="form form-edit"
                    autoComplete="off"
                    noValidate
                  >
                    <div className="form__body">
                      <div className="form-edit__desktop">
                        <div className="input-group is-size-md">
                          <div className="input-group is-size-md is-compact code-snippet-input">
                            <input
                              readOnly
                              data-testid="code-snippet-input__input"
                              tabIndex={-1}
                              id
                              className="rewards-form-control is-size-md code-snippet-input__input"
                              type="text"
                              name
                              value={this.props.shortReferral}
                            />
                            <div className="input-group__addon">
                              <button
                                type="button"
                                className={`code-snippet-input__button ${
                                  this.props.copied ? "grey-out-btn" : ""
                                }`}
                                data-testid="code-snippet-input__button"
                                onClick={this.props.copyReferral}
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-edit__mobile">
                        <div className="input-group is-size-lg referral-show-input">
                          <div className="input-group is-size-md is-compact code-snippet-input">
                            <input
                              readOnly
                              data-testid="code-snippet-input__input"
                              tabIndex={-1}
                              id
                              className="rewards-form-control is-size-md code-snippet-input__input"
                              type="text"
                              name
                              value={this.props.shortReferral}
                            />
                            <div className="input-group__addon">
                              <button
                                type="button"
                                className={`code-snippet-input__button ${
                                  this.props.copied ? "grey-out-btn" : ""
                                }`}
                                data-testid="code-snippet-input__button"
                                onClick={this.props.copyReferral}
                              >
                                Copy
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            <div
              style={{ position: "relative" }}
              className="affiliate-code__share"
            >
              <FacebookShareButton
                onShareWindowClose={this.props.earnGold}
                url={`${this.props.referralLink}`}
                children={
                  <div className="affiliate-code__share__item">
                    <div
                      className="tooltip is-top remove-el"
                      style={{
                        position: "absolute",
                        top: "-55px",
                        left: "38px",
                        width: "145.109px",
                        height: 46,
                        zIndex: 1401,
                      }}
                    >
                      <div className="tooltip__angle" />
                      <div className="tooltip__inner is-compact">
                        Share on Facebook
                      </div>
                    </div>

                    <button
                      type="button"
                      className="affiliate-code__share__button"
                      data-testid="affiliate-code__share__item--facebook"
                      aria-label="Facebook share"
                    >
                      <i className="fd-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24.75 49.5"
                          fill="currentColor"
                        >
                          <path d="M6.19 16.5H0v8.25h6.19V49.5H16.5V24.75H24l.74-8.25H16.5v-3.44c0-2 .4-2.75 2.3-2.75h5.95V0H16.9C9.48 0 6.19 3.26 6.19 9.52z" />
                        </svg>
                      </i>
                    </button>
                  </div>
                }
              />

              <WhatsappShareButton
                onShareWindowClose={this.props.earnGold}
                url={`${this.props.referralLink}`}
                children={
                  <div
                    className="affiliate-code__share__item"
                  >
                    <div
                      className="tooltip is-top is-open-temporary remove-el"
                      style={{
                        position: "absolute",
                        top: "-55px",
                        left: "89px",
                        width: "150px",
                        height: 46,
                        zIndex: 1401,
                      }}
                    >
                      <div className="tooltip__angle" />
                      <div className="tooltip__inner is-compact">
                        Share on WhatsApp
                      </div>
                    </div>

                    <button
                      type="button"
                      className="affiliate-code__share__button"
                      data-width={780}
                      data-testid="affiliate-code__share__item--pinterest"
                      aria-label="Pinterest share"
                    >
                      <img
                        className="whatsapp-share-img"
                        style={{ width: "80%" }}
                        src="https://res.cloudinary.com/cashaam/image/upload/v1653495316/logos/grey-whatsapp_qnjc0z.png"
                      />
                    </button>
                  </div>
                }
              />

              <TwitterShareButton
                onShareWindowClose={this.props.earnGold}
                url={`${this.props.referralLink}`}
                children={
                  <div className="affiliate-code__share__item">
                    <div
                      className="tooltip is-top remove-el"
                      style={{
                        position: "absolute",
                        top: "-55px",
                        left: "151px",
                        width: "145.109px",
                        height: 46,
                        zIndex: 1401,
                      }}
                    >
                      <div className="tooltip__angle" />
                      <div className="tooltip__inner is-compact">
                        Share on Twitter
                      </div>
                    </div>

                    <button
                      type="button"
                      className="affiliate-code__share__button"
                      data-testid="affiliate-code__share__item--twitter"
                      aria-label="Twitter share"
                    >
                      <i className="fd-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 54.45 44.25"
                          fill="currentColor"
                        >
                          <path d="M54.45 5.24A22.28 22.28 0 0148 7a11.21 11.21 0 005-6.18 22.35 22.35 0 01-7.1 2.71 11.18 11.18 0 00-19 10.18A31.68 31.68 0 013.79 2a11.18 11.18 0 003.46 15 11.1 11.1 0 01-5.06-1.4 11.19 11.19 0 009 11.1 11.39 11.39 0 01-5.05.19 11.19 11.19 0 0010.4 7.71A22.42 22.42 0 010 39.23a31.61 31.61 0 0017.12 5c20.75 0 32.46-17.52 31.76-33.23a22.84 22.84 0 005.57-5.76z" />
                        </svg>
                      </i>
                    </button>
                  </div>
                }
              />

              <EmailShareButton
                onShareWindowClose={this.props.earnGold}
                url={`${this.props.referralLink}`}
                children={
                  <div style={{paddingLeft: "5px"}} className="affiliate-code__share__item">
                    <div
                      className="tooltip is-top remove-el"
                      style={{
                        position: "absolute",
                        top: "-55px",
                        left: "193px",
                        width: "145.109px",
                        height: 46,
                        zIndex: 1401,
                      }}
                    >
                      <div className="tooltip__angle" />
                      <div className="tooltip__inner is-compact">
                        Share with Email
                      </div>
                    </div>

                    <button
                      type="button"
                      className="affiliate-code__share__button"
                      data-width={990}
                      data-height={600}
                      data-testid="affiliate-code__share__item--linkedin"
                    >
                      <img src="https://res.cloudinary.com/cashaam/image/upload/v1653496020/logos/grey-email-transparent_ortlhl.png" />
                    </button>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserReferral;
