import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import API_KEY from '../../../../secrets';
import ReactTooltip from "react-tooltip";
import SelectStates from "./SelectStates";
import SelectCountry from "./SelectCountry";

const EditDeliveryPopup = (props) => {

  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (props.isScriptLoadSucceed) {
      setScriptLoaded(true)
    }
  }, [props.isScriptLoaded])
  return (scriptLoaded === true ? <>
    <ReactTooltip />

    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container">
        <div style={{ display: "flex", maxHeight: "90vh", overflow: "scroll" }} className="pop-us-ship-label">
          <div className="pop-header">
            <div className="text-main">Edit Shipping Information</div>
            {
            props.successfulReportSent === true ? <a
                className="box-btn-primary w-inline-block"
              >
                <div className="btn-text-primary-outline processed">
                  Report Sent to Cashaam
            </div>
              </a> :
                null}
          </div>
          <div className="pop-content">
            <div className="sub-title">
              Before you create your shipping label here’s a few things to note:
            </div>
            <div className="pop-text">
              Double check to make sure that the addresses are correct by doing one of the following:
              <br />

              <ul>
                <li>Use the <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">Smarty Streets Tool</a> to validate the addresses. <a href="https://smartystreets.com/products/single-address" target="_blank" rel="noreferrer noopener">Click here</a></li>
                <li>You may also reach out to your customer to confirm their address:
                  <ul>
                    <li><span style={{ fontWeight: "bold" }}>Name:</span> {props.customerName}</li>
                    <li><span style={{ fontWeight: "bold" }}>Phone:</span> <a href={`tel:+${props.phoneNumber}`}>{props.phoneNumber}</a></li>
                    <li><span style={{ fontWeight: "bold" }}>Email:</span> <a href={`mailto:${props.customerEmail}`}>{props.customerEmail} </a></li>
                  </ul> </li>
              </ul>

              {props.errorSchedulingDelivery === true && props.successfulReportSent !== true ? <>
                
                <div style={{ textAlign: "center" }}> If you believe the information below is correct, <a onClick={() => props.reportUnsuccessfullDHLSchedule(props.order)} style={{ fontWeight: "bold", color: "#6942ab", cursor: "pointer" }}>Click here</a> to report the issue to Cashaam.
              </div></> :

                props.successfulReportSent === true ?
                  <div style={{ fontWeight: "bold", textAlign: "center", color: "#6740aa", fontSize: "16px" }}>Thanks for reporting this issue to Cashaam. Our team will review the order information and help you as soon as possible.</div>
                  : null}

                {props.addressesError?
                  <div
                    style={{
                      borderColor: "#ff8c00",
                      borderWidth: "2px",
                      fontSize: "14px",
                    }}
                    className="tracking-box"
                  >
                    <div>
                      <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        Error verifying addresses.
                        <br />
                        
                      </div>

                      <div style={{textAlign: "center"}}>
                        {props.addressesError}
                      </div>
                      {props.sellerPostCodeError || props.buyerPostCodeError?
                        <div style={{textAlign: "center"}}>
                          Please use this site to validate the UK postcode and address: 
                          <a href="https://www.royalmail.com/find-a-postcode" target="_blank" rel="noopener noreferrer">Royal Mail</a>
                        </div>
                      :null}
                    </div>
                  </div>

                :null}


            </div>
            <form
              className="update-form"
              onSubmit={props.submitEditedSchedule}
              >
              <div className="mc-form-holder edit-address-only">
                <div className="sub-title">
                  Your Address </div>

                  {props.sellerStreet?.length>45?
                    <label className="error-message">
                      Street address cannot exceed 45 characters
                    </label>
                  :
                  props.sellerStreet?.length<=1?
                    <label className="error-message">
                      Please enter your street address
                    </label>
                  :null
                  }


                {props.sellerCountry?.toLowerCase()=="nigeria"?
                  <input
                    onChange={props.handleDeliveryChange}
                    value={props.sellerStreet}
                    className={`mc-input input-text w-input ${
                      props.sellerStreet?.length<=1 || props.sellerStreet?.length>45 ? "input-error" : ""
                    }`}
                    placeholder="Street" name="sellerStreet"
                  />
                            :
                  <PlacesAutocomplete
                    value={props.sellerAddress || ""}
                    onChange={props.setSellerAddress}
                    onSelect={props.handleSelectSellerAddress}
                    shouldFetchSuggestions={props.sellerAddress.length > 3}
                    onError={props.onError}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <>
                        <input
                          {...getInputProps({
                            placeholder: "",
                          })}
                          data-name="address"
                          maxLength="5000"
                          id="address"
                          name="address"
                          placeholder="street"
                          required="required"
                          className={`input-text w-input mc-input ${
                            props.sellerStreet?.length<=1 || props.sellerStreet?.length>45 ? "input-error" : ""
                          }`}
                        ></input>
                        <div
                          className="mc-suggestion-holder"
                        >
                          {loading ? <div>...loading</div> : null}

                          {suggestions.map((suggestion, i) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#d0bdf0"
                                : "white",
                              borderBottom:
                                "2px solid rgb(219, 219, 219)",
                              borderTop:
                                i > 0
                                  ? "0.5px solid rgb(219, 219, 219)"
                                  : null,
                              cursor: "pointer",
                              padding: "5px",
                              // boxShadow: "0px 0px 2px",
                            };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                key={i}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>
                }

                {props.sellerApt?.length>10?
                    <label className="error-message">
                      Apt cannot exceed 10 characters
                    </label>
                  :
                  <label>Apt</label>
                  }
                <input 
                    onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                      props.sellerApt?.length>10 ? "input-error" : ""
                          }`}
                      placeholder="Apt" name="sellerApt" value={props.sellerApt && props.sellerApt !== "N/A" ? props.sellerApt : ""} />

                {props.sellerCountry?.toLowerCase()=="nigeria"?
                  null:
                  <>
                  {props.sellerCity?.length<1?
                    <label className="error-message">
                      Please enter the city
                    </label>
                  :
                  <label>City</label>
                  }
                  <input onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                      props.sellerCity?.length<1 ? "input-error" : ""
                          }`}
                    placeholder="city" name="sellerCity" value={props.sellerCity} />
                  </>
                }
                  {props.sellerCountry?.toLowerCase()=="nigeria"?
                    <>
                    {props.sellerState?.length<1?
                    <label className="error-message">
                      Please enter the state
                    </label>
                    :
                      <label>State</label>
                    }

                      <SelectStates 
                        handleDeliveryChange={props.handleDeliveryChange}
                        state={props.sellerState}
                        name={"sellerState"}
                      />
                    </>
                     :
                     <>
                      {props.sellerState?.length<1?
                        <label className="error-message">
                          Please enter the state
                        </label>
                        :
                          <label>State</label>
                        }

                      <input onChange={props.handleDeliveryChange} 
                          className={`mc-input input-text w-input ${
                            props.sellerState?.length<1 ? "input-error" : ""
                              }`}
                        placeholder="state" name="sellerState" value={props.sellerState} />
                      </>
                  }
                  {props.sellerCountry?.toLowerCase()=="nigeria" && props.sellerLGA?
                  <>
                    {props.sellerCity?.length<1?
                      <label className="error-message">
                        Please enter the city
                      </label>
                    :
                    <label>City</label>
                    }
                    <select
                        value={props.sellerCity}
                        id="sellerCity"
                        className={`mc-input input-text w-input ${
                          props.sellerCity?.length<1 ? "input-error" : ""
                              }`}
                        placeholder="City" name="sellerCity"

                        onChange={props.handleDeliveryChange}
                        >
                            {props.sellerLGA.map((lga, index) => {
                            return <option value={lga} name="state"> {lga}</option>
                            })}
                    </select>
                    </>
                  :null}

                  {props.sellerCountry?.length<1?
                      <label className="error-message">
                        Please enter the country
                      </label>
                    :
                    <label>Country</label>
                  }
                {/* <input onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                      props.sellerCountry?.length<1 ? "input-error" : ""
                              }`}
                  placeholder="country" name="sellerCountry" value={props.sellerCountry} /> */}

                  <SelectCountry 
                    handleDeliveryChange={props.handleDeliveryChange}
                    country={props.sellerCountry}
                    name="sellerCountry"
                  />

                {props.sellerCountry?.toLowerCase()=="nigeria"?
                  null
                  :
                  <>
                    {props.sellerPostCodeError?
                    <label className="error-message">Invalid Zipcode/Postcode</label>
                      :
                        <label>Zipcode</label>
                      }
                    <input onChange={props.handleDeliveryChange} className={`mc-input input-text w-input ${props.sellerPostCodeError?"input-error":""}`}
                      placeholder="zip" name="sellerZipcode" value={props.sellerZipcode} />
                  </>
                }

              </div>


              <div className="mc-form-holder edit-address-only">
                <div className="sub-title">
                  Buyer's Address </div>
                
                  {props.street?.length>45?
                    <label className="error-message">
                      Street address cannot exceed 45 characters
                    </label>
                  :
                  props.street?.length<=1?
                    <label className="error-message">
                      Please enter the buyer's street address
                    </label>
                  :null
                  }

                  {props.country?.toLowerCase()=="nigeria"?
                  <input
                    onChange={props.handleDeliveryChange}
                    value={props.street}
                    className={`mc-input input-text w-input ${
                      props.street?.length<=1 || props.street?.length>45 ? "input-error" : ""
                    }`}
                    placeholder="Street" name="street"
                  />
                  : 
                  <PlacesAutocomplete
                    value={props.address || ""}
                    onChange={props.setAddress}
                    onSelect={props.handleSelectAddress}
                    shouldFetchSuggestions={props.address.length > 3}
                    onError={props.onError}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <>
                        
                        <input
                          {...getInputProps({
                            placeholder: "",
                          })}
                          data-name="address"
                          maxLength="5000"
                          id="address"
                          name="address"
                          placeholder="street"
                          required="required"
                          className={`input-text w-input mc-input ${
                            props.street?.length<=1 || props.street?.length>45 ? "input-error" : ""
                          }`}
                        ></input>
                        <div
                          className="mc-suggestion-holder"
                        >
                          {loading ? <div>...loading</div> : null}

                          {suggestions.map((suggestion, i) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#d0bdf0"
                                : "white",
                              borderBottom:
                                "2px solid rgb(219, 219, 219)",
                              borderTop:
                                i > 0
                                  ? "0.5px solid rgb(219, 219, 219)"
                                  : null,
                              cursor: "pointer",
                              padding: "5px",
                              // boxShadow: "0px 0px 2px",
                            };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                                key={i}
                              >
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </PlacesAutocomplete>
                }

                  {props.apt?.length>10?
                    <label className="error-message">
                      Apt cannot exceed 10 characters
                    </label>
                  :
                    <label>Apt</label>
                  }
                <input onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                      props.apt?.length>10 ? "input-error" : ""
                          }`}
                  placeholder="Apt" name="apt" value={props.apt && props.apt !== "N/A" ? props.apt : ""} />

                {props.country?.toLowerCase()=="nigeria"?
                  null:
                    <>
                    {props.city?.length<1?
                      <label className="error-message">
                        Please enter the city
                      </label>
                    :
                    <label>City</label>
                    }
                    <input onChange={props.handleDeliveryChange} 
                        className={`mc-input input-text w-input ${
                          props.city?.length<1 ? "input-error" : ""
                              }`}
                      placeholder="City" name="city" value={props.city} />
                    </>
                }

                {props.state?.length<1?
                      <label className="error-message">
                        Please enter the state
                      </label>
                      :
                        <label>State</label>
                }
                {props.country?.toLowerCase()=="nigeria"?
                  <>
                    <SelectStates 
                      handleDeliveryChange={props.handleDeliveryChange}
                      state={props.state}
                      name={"state"}
                    />
                  </>
                :                
                  <input onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                    props.state?.length<1 ? "input-error" : ""
                        }`}
                    placeholder="State" name="state" value={props.state} />
                }

                {props.country?.toLowerCase()=="nigeria" && props.buyerLGA?
                  <>
                    {props.city?.length<1?
                      <label className="error-message">
                        Please enter the city
                      </label>
                    :
                      <label>City</label>
                    }
                    <select
                        value={props.city}
                        id="buyerCity"
                        className="mc-input input-text w-input"
                        placeholder="City" name="city"
                        onChange={props.handleDeliveryChange}
                        >
                            {props.buyerLGA.map((lga, index) => {
                            return <option value={lga} name="state"> {lga}</option>
                            })}
                    </select>
                    </>
                  :null}

                  {props.country?.length<1?
                      <label className="error-message">
                        Please enter the country
                      </label>
                    :
                    <label>Country</label>
                  }
                {/* <input onChange={props.handleDeliveryChange} 
                    className={`mc-input input-text w-input ${
                      props.country?.length<1 ? "input-error" : ""
                          }`}
                  placeholder="Country" name="country" value={props.country} /> */}

                  <SelectCountry 
                    handleDeliveryChange={props.handleDeliveryChange}
                    country={props.country}
                    name="country"
                  />

                {props.country?.toLowerCase()=="nigeria"?
                  null
                  : 
                  <>
                  {props.buyerPostCodeError?
                    <label className="error-message">Invalid Zipcode</label>
                  :
                    <label>Zipcode</label>
                  }
                    <input onChange={props.handleDeliveryChange} className={`mc-input input-text w-input ${props.buyerPostCodeError?"input-error":""}`}
                      placeholder="Zip" name="zipcode" value={props.zipcode} />
                  </>
                }
              </div>


              {/* {props.errorCreatingShippingLabel === true ?
                <div style={{ borderColor: "#ff8c00", borderWidth: "2px", fontSize: "14px" }} className="tracking-box">
                  <div>
                    <div style={{ fontWeight: "bold" }}>Error Printing Shipping Label. Please fix the following problems: </div>
                    {props.printShippingLabelErrors.map((message, i) => (
                      <div key={i}>{`${i + 1}. ${message.text}`}</div>
                    ))}
                    <br />
                    Please <a target="_blank" href="http://wa.me/+2347025130024">Contact Us</a> if this problem persists.</div>
                </div> : null} */}
              {props.loading === true ? (
                <div
                  style={{
                    cursor: "pointer",
                    background: "none",
                    backgroundColor: "grey",
                  }}
                  className="btn-primary"
                >
                  <div className="text-btn-primary">
                    Getting Shipping Labels...
                </div>
                </div>
              ) : (
                  props.shippingLabelLink || props.labelObject? 
                  <a
                    onClick={props.openLink}
                    style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                    className="btn-primary"
                  >View Label 
                  </a> :
                    <input
                      style={{ color: "white", fontSize: "16px", lineHeight: "20px", cursor: "pointer" }}
                      value="Pick a Date"
                      type="submit"
                      className="btn-primary"
                    />
                )}
            </form>
          </div>

          <div className="pop-footer">

            {!(props.shippingLabelLink || props.labelObject) && props.errorSchedulingDelivery === true && props.successfulReportSent !== true ? <div
              style={{ cursor: "pointer", marginBottom: "10px" }}
              onClick={() => props.reportUnsuccessfullDHLSchedule(props.order)}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Still having problems? Report to Cashaam</div>
            </div> : null}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => props.onEditShipping(false)}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Close</div>
            </div>
          </div>
        </div>
      </div>
    </div> </> : <div>Loading...</div>
  );
};
// export default ManualShippingPop;
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&region=NG`,
])(EditDeliveryPopup);