import React, { Component } from "react";
import firebase from "../../../../firebaseConfig/index";
import scriptLoader from "react-async-script-loader";

import API_KEY from "../../../../secrets";
import { Link } from "react-router-dom";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import Metatags from "react-meta-tags";
import StoreSetupForm from "../../../UI/StoreSetupForm";
import StoreSetupSuccess from "../../../UI/StoreSetupSuccess";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getUserLocation from "../../../helpers/getUserLocation";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import AboutStoreForm from "../../../UI/AboutStoreForm";
import saveAboutStore from "../../../helpers/saveAboutStore";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import setUserTheme from "../../../helpers/theme/setUserTheme";
import AccountForm from "../../../UI/AccountForm";
import PersonalDetails from "../../../UI/PersonalDetails";
import NigerianAddresses from '../../../helpers/NigerianAddresses.json';

const options = [
  { label: "cosmetic", value: 0 },
  { label: "electronics", value: 1 },
  { label: "fashion", value: 2 },
  { label: "food", value: 3 },
  { label: "fragrances", value: 4 },
  { label: "home", value: 5 },
  { label: "media", value: 6 },
  { label: "clothing", value: 7 },
  { label: "shoes", value: 8 },
  { label: "jewelry", value: 9 },
  { label: "watches", value: 10 },
  { label: "movies", value: 11 },
  { label: "music", value: 12 },
  { label: "games", value: 13 },
  { label: "computers", value: 14 },
  { label: "office_equipment", value: 15 },
  { label: "home_equipment", value: 16 },
  { label: "garden_tools", value: 17 },
  { label: "tools", value: 18 },
  { label: "groceries", value: 19 },
  { label: "toys", value: 20 },
  { label: "kids", value: 21 },
  { label: "baby_products", value: 22 },
  { label: "sports", value: 23 },
  { label: "automotive", value: 24 },
  { label: "industrial", value: 25 },
  { label: "handmade", value: 26 },
];

const optionsToValues = {
  cosmetic: 0,
  electronics: 1,
  fashion: 2,
  food: 3,
  fragrances: 4,
  home: 5,
  media: 6,
  clothing: 7,
  shoes: 8,
  jewelry: 9,
  watches: 10,
  movies: 11,
  music: 12,
  games: 13,
  computers: 14,
  office_equipment: 15,
  home_equipment: 16,
  garden_tools: 17,
  tools: 18,
  groceries: 19,
  toys: 20,
  kids: 21,
  baby_products: 22,
  sports: 23,
  automotive: 24,
  industrial: 25,
  handmade: 26,
};

class StoreSetup extends Component {
  state = {
    sellerId: "",
    storeName: "",
    description: "",
    address: "",
    profileImage: [],
    shopLogo: [],
    storeCategories: "",
    socialInstagram: "",
    socialFacebook: "",
    socialTwitter: "",
    slug: "",

    previewURL: "",
    previewLogoURL: "",
    profileImageUploaded: false,
    logoImageUploaded: false,
    scriptLoaded: false,
    invalidSlug: false,
    takenSlugs: [],
    sellerName: "",
    invalidStoreName: false,
    alreadySuggestedSlug: false,
    spaceDetectetInSlug: false,
    upperCaseDetectedInSlug: false,
    specialCharDetectedInSlug: false,
    selected: [],
    storeSetupSuccess: false,
    loading: false,
    addressSelected: false,
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
    invalidAddress: false,
    userCurrentCountry: "",
    displayAboutStoreForm: true,
    sellerType: "I'm a full time seller",
    sellerGender: "N/A",
    shopLanguange: "English",
    shopCurrency: "",
    storeNameError: "",
    descriptionError: "",
    slugError: "",
    addressError: "",
    categoryError:"",
    registedBusiness: false,
    business: "",
    stateLGA: null,
  };

  goBackToPickAccount=()=>{

    this.props.history.push("/seller/dashboard/setup/pickaccount");
  }

  handleChange = (e) => {
    console.log("event target name", e.target.name);
    console.log("event target value", e.target.value);

    if (e.target.name === "storeName") {
      const name = e.target.value;
      //check if name is longer than 20 chars
      this.validateStoreName(name);
      this.setState({
        [e.target.name]: name,
      });
    } else if (e.target.name === "socialInstagram") {
      if (e.target.value.length > 24) {
        // if we set the "https://www.instagram.com/" as a tooltip
        const value = e.target.value.slice(26);
        this.setState({
          [e.target.name]: value,
        });
      }

      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "socialFacebook") {
      if (e.target.value.length > 24) {
        // if we set the "https://www.facebook.com/" as a tooltip
        const value = e.target.value.slice(26);
        this.setState({
          [e.target.name]: value,
        });
      }

      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if(e.target.name==="state"){
      let stateLGA=this.state.NigeriaLGA[e.target.value];
      this.setState({
        stateLGA,
        [e.target.name]: e.target.value,
      })
    } else if(e.target.name==="country"){
      let stateLGA=this.state.NigeriaLGA["Lagos"];
      this.setState({
        stateLGA,
        state:"Lagos",
        [e.target.name]: e.target.value,
      })
    }else if(e.target.name=="birthday"){
      let dod=e.target.value;
      console.log("dob",dod)
      if(new Date(dod) > new Date('2010-04-30')){
        console.log("dob is before 2010")
        let dobError="You must be older than 13yrs old."
        this.setState({
          dobError,
          [e.target.name]: e.target.value,
        });

      }else if(new Date(dod) < new Date('1900-01-30')){
        let dobError="Please enter your birthday"
        this.setState({
          dobError,
          [e.target.name]: e.target.value,
        });
      }else{
        this.setState({
          [e.target.name]: e.target.value,
          dobError:""
        });
      }

    }else {
      this.setState({
        [e.target.name]: e.target.value,
        countryError: ""
        
      });
    }
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  showInvalidAddress = () => {
    this.setState({ invalidAddress: true });
    setTimeout(() => this.setState({ invalidAddress: false }), 5000);
  };

  hideInvalidAddress = () => {
    this.setState({ invalidAddress: false });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.displayLoading();

    let {
      // storeName,
      // description,
      address,
      street,
      city,
      state,
      country,
      zipcode,
      profileImage,
      shopLogo,
      socialInstagram,
      socialFacebook,
      socialTwitter,
      slug,
      storeCategories,
      addressSelected,
      invalidAddress,
      sellerType,
      sellerGender,
      shopLanguange,
      shopCurrency,
      addressError,
      birthday,
      dobError
    } = this.state;
    console.log("state--", this.state);

    this.setState({
      addressError: "",
      countryError:"",
      cityError:"",
      stateError:"",
    });

    if(country?.toLowerCase()=="nigeria"){
      address=street;
      this.setState({
        invalidAddress: false
      })
    }


    if(!country) {
      this.hideLoading();
      this.setState({
        countryError: "Please enter the country",
      });
      return;
    }

    if (!address) {
      if(street){
        address=street;
      }else{
        this.hideLoading();
        this.setState({
          addressError: "Please enter a valid address",
        });
        return;
      }
    }

    if(!state){
      this.hideLoading();
      this.setState({
        stateError: "Please enter the state",
      });
      return;
    }

    if(!city){
      this.hideLoading();
      this.setState({
        cityError: "Please enter the city",
      });
      return;
    }

    if(!zipcode){
      zipcode="N/A"
    }
    if(!apt){
      apt="N/A"
    }

    if(!birthday && !dobError){
      this.hideLoading();
      this.setState({
        dobError: "Please enter your birthday",
      });
      return;
    }
    if(dobError){
      this.hideLoading();
      return;
    }
    // capture the current user uid
    const sellerUid = firebase.auth().currentUser.uid;
    const db = firebase.firestore();
    const storage = firebase.storage();
    let batch = db.batch();

    if (addressSelected === false && country?.toLowerCase()!="nigeria") {
      //validate address first before submiting
      await this.handleSelectAddress(address);
    }

    //filter out the @ symbols if user entered them
    if (socialInstagram.includes("@")) {
      socialInstagram = socialInstagram.slice(1);
    }

    if (socialFacebook.includes("@")) {
      socialFacebook = socialFacebook.slice(1);
    }

    if (socialInstagram.includes("https://")) {
      //filter out https://www.instagram.com/ if user entered it
      socialInstagram = socialInstagram.slice(26);
    } else if (socialInstagram.includes("www.")) {
      //www.instagram.com/
      socialInstagram = socialInstagram.slice(18);
    } else if (socialInstagram.includes(".com")) {
      //instagram.com/
      socialInstagram = socialInstagram.slice(14);
    }

    //filter out https://www.facebook.com/
    if (socialFacebook.includes("https://")) {
      socialFacebook = socialFacebook.slice(25);
    } else if (socialFacebook.includes("www.")) {
      //www.instagram.com/
      socialFacebook = socialFacebook.slice(17);
    } else if (socialFacebook.includes(".com")) {
      //instagram.com/
      socialFacebook = socialFacebook.slice(13);
    }

    const holdProfileImage = []; //uploaded images url will be stored here which will then be stored in firestore
    const holdLogoImage = [];

    const storeDataToDatabase = async () => {
      console.log("test PROF--", holdProfileImage);
      console.log("test LOGO--", holdLogoImage);

      let {
        sellerId,
        storeName, 
        description,
        street,
        city,
        state,
        country,
        zipcode,
        apt,
        longitude,
        latitude,
        invalidAddress,
        sellerType,
        sellerGender,
        shopCurrency,
        registedBusiness,
        business,
        birthday
      } = this.state;

      if (address && slug) {
        if (invalidAddress === true) {
          this.hideLoading();
          this.setState({
            addressError: "Invalid address, please enter a valid address",
          });
          return;
        }

        await db
          .collection("sellersAccounts")
          .doc(sellerUid)
          .get()
          .then((doc) => {
            console.log("testing doc--", doc.data());

            //extract other data from the sellersAccounts/uid document
            const {
              firstName,
              lastName,
              email,
              id,
              phoneNumber,
              storeName,
              description,
            } = doc.data();
            // create a sub-collection inside the sellers document to save the user's stores
            var docRef = db
              .collection("sellersAccounts")
              .doc(sellerUid)
              .collection("stores")
              .doc(sellerUid);

            batch.set(
              docRef,
              {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                storeName: storeName,
                description: description,
                address: address,
                id: id,
                stars: 5,
                status: "active",
                socialInstagram: socialInstagram ? socialInstagram : "",
                socialFacebook: socialFacebook ? socialFacebook : "",
                socialTwitter: socialTwitter ? socialTwitter : "",
                slug: slug,
                storeCategories: storeCategories
                  ? Array.isArray(storeCategories) === true
                    ? storeCategories[0]
                    : storeCategories
                  : "fashion",
                street,
                apt: apt?apt:"N/A",
                city,
                state,
                country,
                zipcode:zipcode?zipcode:"N/A",
                longitude,
                latitude,
                sellerGender,
                sellerType:sellerType?sellerType:"N/A",
                shopCurrency,
                registedBusiness: registedBusiness,
                business: business?business:""
              },
              { merge: true }
            );

            var sellersAccountsRef = db
              .collection("sellersAccounts")
              .doc(sellerUid);
            batch.update(sellersAccountsRef, {
              storeName: storeName,
              slug: slug,
              description: description,
              storeCategories: storeCategories
                ? Array.isArray(storeCategories) === true
                  ? storeCategories[0]
                  : storeCategories
                : "fashion",
              street,
              apt: apt?apt:"N/A",
              city,
              state,
              country,
              zipcode:zipcode?zipcode:"N/A",
              longitude,
              latitude,
              sellerType:sellerType?sellerType:"N/A",
              sellerGender,
              shopCurrency,
              registedBusiness: registedBusiness,
              business: business?business:"",
              dob:birthday?birthday:""
            });

            batch
              .commit()
              .then(async () => {
                // after the store has been setup direct the seller to setup their bank info
                // this.props.history.push("/seller/dashboard/setup/bank");
                await saveRegisterProgress(sellerUid, 3);
                if (
                  country?.toLowerCase() !==
                  "nigeria"
                ) {
                  await setUserTheme(sellerUid, "moda");
                }
                this.hideLoading();
                // this.displayStoreSetupSuccess();
                if (
                  country?.toLowerCase() !==
                  "nigeria"
                ) {
                  this.props.history.push("/seller/dashboard/setup/USbank");
                } else {
                  this.props.history.push("/seller/dashboard/setup/bank");
                }
              })
              .catch((err) => {
                console.log(err, "<--- error with saving store");
              });

              
          });


          var saveUser = JSON.parse( sessionStorage.getItem(sellerId)) || {};

          saveUser.sellerId= sellerId
          saveUser.storeName= storeName
          saveUser.description= description
          saveUser.slug=slug
          saveUser.address=address
          saveUser.socialInstagram=socialInstagram ? socialInstagram : ""
          saveUser.socialFacebook=socialFacebook ? socialFacebook : ""
          saveUser.socialTwitter=socialTwitter ? socialTwitter : ""
          saveUser.storeCategories=storeCategories
              ? Array.isArray(storeCategories) === true
                ? storeCategories[0]
                : storeCategories
              : "fashion"
          saveUser.sellerType=sellerType

          saveUser.street=street
          saveUser.city=city
          saveUser.state=state
          saveUser.zipcode=zipcode
          saveUser.country=country
          
              city,
              state,
              country,
              zipcode,
          console.log("Store info in sessionStorage: ",saveUser)
          // update info in sessionStorage
          sessionStorage.setItem(sellerId, JSON.stringify(saveUser));
          

      } else {
        toast.error("Please enter all necessary information");
        return;
      }
    };

    //validate all inputs before storing anything.
    //after storeImages stores the images, it'll then call database function to store other values in database
    //storing the images first because you need the return url to be stored in firestore db
    if (address) {
      storeDataToDatabase();
    } else {
      toast.error("Please enter all necessary information");
      return;
    }
  };

  // checkForSellerStore = () => {
  //   const db = firebase.firestore();
  //   const sellerUid = firebase.auth().currentUser.uid;
  //   db.collection("sellersAccounts")
  //     .doc(sellerUid)
  //     .collection("stores")
  //     .get()
  //     .then((sellerStoreData) => {
  //       // if the seller already has a store in collection then direct them back to dashboard
  //       if (sellerStoreData.empty === false) {
  //         this.props.history.push("/seller/dashboard");
  //       }
  //     });
  // };

  selectCategory = ({ selected }) => {

    this.setState({
      categoryError:""
    })
    console.log("selected category",selected)
    //limit the number of category selected to 2 MAX
    const numberOfItems = selected.length;
    const categoryNames = [];
    if (numberOfItems === 0) {
      this.setState({ selected });
      //this.setState({ storeCategories: [] });
      this.setState({
        categoryError:"Please select a category that best describes your store"
      })
      return;

    } else if (numberOfItems <= 1 || (numberOfItems==2 && selected[0]==undefined)) {
      if(numberOfItems==2 && selected[0]==undefined){
        selected.splice(0, 1)
      }

      this.setState({ selected });
      selected.forEach((categoryValue) => {
        categoryNames.push(options[categoryValue].label);
        this.setState({ storeCategories: categoryNames });
      });
    } else {

      this.setState({
        categoryError:"You can only select 1 category at the moment"
      })
      return;
    }
  };

  displayStoreSetupSuccess = () => {
    this.setState({ storeSetupSuccess: true });
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("getLocation",locationData)

    let country = locationData.country_name === "India"
    ? "Nigeria"
    : locationData.country_name

    let stateLGA=[];
    let state = ""
    if(country === "Nigeria"){
      stateLGA=this.state.NigeriaLGA["Lagos"];
      state="Lagos"
    }

    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      shopCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
        country,
        state,
        stateLGA
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
    // this.showInvalidAddress();
  };

  handleSelectAddress = async (address) => {
    let { addressError } = this.state;
    console.log("state--", this.state);

    this.setState({
      addressError: "",
      countryError: ""
    });

    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ addressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;
        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        let phoneNumber =this.state.phoneNumber;
        console.log("phoneNumber",phoneNumber)
        if((phoneNumber.substring(0, 1) == 1 || phoneNumber.substring(0, 2) == "+1") && !(country?.toLowerCase() == "us" || country?.toLowerCase() == "usa" || country?.toLowerCase() == "united states")) {
          console.log("Phone country (US)",phoneNumber.substring(0, 1))
          country="United States";

          this.setState({
            countryError: "You can only enter a United States address"
          })
          return;

        }else if(phoneNumber.substring(0, 3) == "234" || phoneNumber.substring(0, 4) == "+234" && (country?.toLowerCase() != "nigeria")){
          console.log("Phone country (NG)",phoneNumber.substring(0, 3))
          country="Nigeria";

          this.setState({
            countryError: "You can only enter a Nigerian address"
          })
          return;
        }

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();
          resolve(
            this.setState({
              address: formattedAddress,
              street: street,
              city: city !== null ? city : "N/A",
              state: state !== null ? state : "N/A",
              country: country !== null ? country : "N/A",
              zipcode: zipcode !== null ? zipcode : "NA",
              latitude: lat,
              longitude: lng,
            })
          );
          // await this.getAddressFromGeocode(LatLng);
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.setState({ addressSelected: false });
        this.showInvalidAddress();
        if (error === "ZERO_RESULTS") {
          this.hideLoading();

          this.setState({
            addressError: "This address is invalid. Please check and try again",
          });
          return;
        }
        reject(error);
      }
    });
  };

  setAddress = (address) => {
    if (this.state.addressSelected === true) {
      this.setState({ addressSelected: false });
    }
    this.setState({ address: address });
  };

  setUpLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  handleSlugChange = async (e) => {
    e.preventDefault();
    // const db = firebase.firestore();

    this.setState({
      descriptionError: "",
      storeNameError: "",
      slugError: "",
    });

    if(!this.state.accountType || this.state.accountType!="Personal"){

    
      if (!this.state.storeName || this.state.storeName.length < 2) {
        this.setState({
          storeNameError: "Please enter a valid store name first",
        });
        return; //toast.error("Please enter a valid store name first");
      }

      if (!this.state.description || this.state.description.length < 25) {
        this.setState({
          descriptionError:
            "Please enter atleast 25 characters to describe your store",
        });
        return;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
    this.validateSlug(e.target.value);
  };

  getSlugSuggestion = (passedName) => {
    //this if statement avoids giving multiple suggestions
    //this is done to avoid annoying users with new suggestions everytime they enter a new name

    console.log("getSlugSuggestion:",passedName)
    this.setState({
      descriptionError: "",
      storeNameError: "",
      slugError: "",
    });

    if (!this.state.storeName || this.state.storeName.length < 2) {
      this.setState({
        storeNameError: "",
      });
      return; //toast.error("Please enter a valid store name first");
    }

    if (!this.state.description || this.state.description.length < 25) {
      this.setState({
        descriptionError:
          "Please enter atleast 25 characters to describe your store",
      });
      return;
    }

    if (this.state.alreadySuggestedSlug === false) {
      let storeName = passedName ? passedName : this.state.storeName;
      storeName.trim();
      storeName = storeName.replace(/[^a-zA-Z ]/g, ""); // take out any character that's not a letter
      storeName = storeName.split(" ");
      storeName = storeName.join("").toLowerCase();
      this.setState({
        slug: storeName,
        alreadySuggestedSlug: true,
      });
      console.log(storeName);
      return this.validateSlug(storeName);
    }
  };

  isSlugTaken = async (slug) => {
    return new Promise(async (resolve, reject) => {
      try {

        const db = firebase.firestore();
        const sellers = await db
          .collection("sellersAccounts")
          .where("slug", "==", slug)
          .get();
        if (sellers.empty) {
          // couldn't find slug
          console.log("no slug found");
          resolve(false);
        } else {

          //capture the current user id
          const sellerId = firebase.auth().currentUser.uid;
          // slug exist
          var id=sellers.docs[0].data().id;

          if(sellerId!==id){
            console.log("Got an existing slug:", sellers.docs[0].data());
            resolve(true);
          }else{
            console.log("Slug used by same user");
            resolve(false);
          }
          
        }
      } catch (error) {
        console.log("error checking for slug", error);
        reject(error);
      }
    });
  };

  validateSlug = async (url) => {
    const slug = url || "invalid";
    const forbiddenSlugs = [
      "invalid",
      "testurl",
      "test",
      "about",
      "careers",
      "career",
      "cashaam",
      ""
    ];
    console.log(this.state.takenSlugs);
    this.setState({
      invalidSlug:false
    })

    

    // if (this.state.takenSlugs.includes(slug)) {
    const taken = await this.isSlugTaken(slug);
    if (taken === true) {
      this.setState({ invalidSlug: true });
    } else {
      
      //check if slug is forbidden or is not long enough
      if (!forbiddenSlugs.includes(slug) && slug.length > 4) {
        //check if the slug is lowercase
        if (slug !== slug.toLowerCase()) {
          this.setState({
            invalidSlug: true,
            upperCaseDetectedInSlug: true,
          });
        }
        //avoid spaces
        else if (/\s/g.test(slug)) {
          this.setState({
            invalidSlug: true,
            spaceDetectetInSlug: true,
          });
        }

        //avoid special Chars
        else if (!slug.match("^[a-zA-Z]*$")) {
          this.setState({
            invalidSlug: true,
            specialCharDetectedInSlug: true,
          });
        } else {
          this.setState({
            invalidSlug: false,
            spaceDetectetInSlug: false,
            upperCaseDetectedInSlug: false,
            specialCharDetectedInSlug: false,
          });
        }
      } else {
        this.setState({ invalidSlug: true });
      }
    }
  };

  validateStoreName = (name) => {
    if (name.length > 20) {
      this.setState({
        invalidStoreName: true,
      });
    } else {
      this.setState({
        invalidStoreName: false,
      });
    }
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  showAboutStoreForm = (val) => {
    this.setState({
      displayAboutStoreForm: val,
    });
  };


  submitAboutStore = async (e) => {
    e.preventDefault();
    this.displayLoading();

    this.setState({
      descriptionError: "",
      storeNameError: "",
      slugError: "",
    });

    let { sellerId, storeName, description, slug } = this.state;

    if(this.state.accountType=="Personal"){

      let firstName=this.state.firstName;
      storeName=firstName;
      description="Personal Account, upgrade to convert it to business account";

      this.setState({
        storeName:firstName,
        description,
        storeCategories: "electronics"
      })
      
    }else{

      if (!this.state.storeName || this.state.storeName.length < 2) {
        this.hideLoading();
        this.setState({
          storeNameError: "Please enter a valid store name first",
        });
        return;
      }

      if (!this.state.description || this.state.description.length < 25) {
        this.hideLoading();
        this.setState({
          descriptionError:
            "Please enter atleast 25 characters to describe your store",
        });
        return;
      }
    }

    try {

      if (slug) {
        console.log("Slug is invalid? ",this.state.invalidSlug)
        if (this.state.invalidSlug === true && this.state.accountType!="Personal") {
          this.hideLoading();
          this.setState({
            slugError: "Please enter a unique and valid url for your store",
          });
          return;
        }else if(this.state.invalidSlug === true && this.state.accountType=="Personal") {
          this.hideLoading();
          this.setState({
            slugError: "Please enter a different tag",
          });
          return;
        }
      } else {

        if(this.state.accountType=="Personal"){
          this.hideLoading();
          this.setState({
            slugError: "Please enter a unique tag for your account",
          });
          return;
        }else{
          this.hideLoading();
          this.setState({
            slugError: "Please enter a url for your store",
          });
          return;
        }
        
      }
     
      if (this.state.invalidStoreName === true) {
        this.hideLoading();
        this.setState({
          storeNameError:
            "Store Name must be less than or equal to 20 characters",
        });
        return;
      }
     
      await saveAboutStore(sellerId, storeName, description, slug);     
      await saveRegisterProgress(sellerId, 2);  
      await this.getAboutFormValue(sellerId);
      

      var saveUser = JSON.parse( sessionStorage.getItem(sellerId)) || {};

      saveUser.sellerId=sellerId
      saveUser.storeName= storeName
      saveUser.description=description
      saveUser.slug=slug

      console.log("Store About Store info in sessionStorage: ",saveUser)
      // update info in sessionStorage
      sessionStorage.setItem(sellerId, JSON.stringify(saveUser));


      this.showAboutStoreForm(false);
      this.hideLoading();


    } catch (error) {
      this.hideLoading();
      console.log("error submiting about store info", error);
    }
  };

  getAboutFormValue = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = firebase.firestore();
        let result = await db.collection("sellersAccounts").doc(id).get();
        let storeInfo = result.data();
        let { storeName, description } = storeInfo;
        this.setState({ storeName, description });
        resolve(this.getSlugSuggestion(storeName));
      } catch (error) {
        console.log("error getting about form value", error);
        reject(error);
      }
    });
  };

  getAccountInfo = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log("getAccountInfo");
        const db = firebase.firestore();
        let result = await db.collection("sellersAccounts").doc(id).get();
        let storeInfo = result.data();
        let { phoneNumber, accountType, firstName,lastName, tag, slug, dob, country } = storeInfo;

        let description="";
        let storeName="";
        if(accountType && accountType=="Personal"){
          description="Personal Account, upgrade to convert it to business account",
          storeName= firstName
        }
        this.setState({ 
          accountType, firstName,lastName, tag, slug,description,storeName,
          birthday:dob?dob:"",
          country,
          phoneNumber
        });   

        this.getSlugSuggestion(firstName);

        resolve({ accountType, firstName, tag, slug,phoneNumber});
      } catch (error) {
        console.log("error getting about form value", error);
        reject(error);
      }
    });
  };

  goBackToAboutStoreForm = async (e) => {
    console.log("GO BACK");

    this.showAboutStoreForm(true);

    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged(async (user) => {

      //Check if info is available in sessionStorage
      if (sessionStorage.getItem(user.uid)) {
        console.log("Retrive from sessionStorage")
        // Retrieve the user from storage
        var user = await sessionStorage.getItem(user.uid);
  
        console.log("USER INFO: ", JSON.parse(user));


        let storeInfo = JSON.parse(user);
        let { sellerId, storeName, description, slug } = storeInfo;

        this.setState({
          sellerId: sellerId,
          sellerName: user.displayName,
          storeName: storeName,
          description: description,
          slug: slug,
        });
        
  
      }else if (user) {

        console.log("Retrive from firestore")

        let step = await getRegistrationProgress(user.uid);
        step = parseFloat(step);


        //Get user data from firestore
        const db = firebase.firestore();
        let result = await db.collection("sellersAccounts").doc(user.uid).get();
        let storeInfo = result.data();
        let { storeName, description, slug } = storeInfo;

        this.setState({
          sellerId: user.uid,
          sellerName: user.displayName,
          storeName: storeName,
          description: description,
          slug: slug,
        });
      } else {
        this.props.history.push("/");
      }
    });
  };


  goBackToStoreSetup = async (displayAboutStoreForm) => {

    //Show the form for store setup
    this.setState({ 
      storeSetupSuccess: false,
      displayAboutStoreForm
    });



    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged(async (user) => {

      let { accountType, firstName, tag, slug,phoneNumber} = await this.getAccountInfo(user.uid);
      console.log("accountType",accountType)
      let location = await new Promise((resolve, reject) =>
          resolve(this.getLocation())
      );

      this.setState({
        userCurrentCountry:location
      })


      //Check if info is available in sessionStorage
      if (sessionStorage.getItem(user.uid)) {

        console.log("Retrive from sessionStorage")
        // Retrieve the user from storage
        var user = await sessionStorage.getItem(user.uid);
  
        console.log("USER INFO: ", JSON.parse(user));


        let storeInfo = JSON.parse(user);

        let { 
          sellerId, 
          storeName, 
          description, 
          slug, 
          sellerType, 
          address, 
          street,
          city,
          state,
          zipcode,
          country,
          storeCategories, 
          socialFacebook, 
          socialInstagram 
        } = storeInfo;

        console.log("default country",this.state.country)
        if(!country && !state) {
          country=this.state.country;
          state=this.state.state;
        }

        console.log("Phone prefix",phoneNumber.substring(0, 4))
        if(phoneNumber.substring(0, 1) == 1 || phoneNumber.substring(0, 2) == "+1"){
          console.log("Phone country (US)",phoneNumber.substring(0, 1))
          country="United States";
        }else if(phoneNumber.substring(0, 3) == "234" || phoneNumber.substring(0, 4) == "+234"){
          console.log("Phone country (NG)",phoneNumber.substring(0, 3))
          country="Nigeria";
        }

        
        this.setState({
          sellerId: sellerId,
          sellerName: user.displayName,
          storeName: storeName,
          description: description,
          slug: slug,
          sellerType: sellerType,
          address: address,
          storeCategories: storeCategories,
          socialInstagram: socialInstagram ? socialInstagram : "",
          socialFacebook: socialFacebook ? socialFacebook : "",
          accountType,
          street,
          city,
          state,
          zipcode,
          country,
        });

        //Find the index of the selected storeCategories
        var index;
        for(var i = 0; i < options.length; i++) {
            if (options[i].label == storeCategories) {
              index = i;
              break;
            }
        }
        this.setState({
          selected:[index]
        })
        
  
      } else if (user) {
        let { accountType, firstName, tag,phoneNumber} = await this.getAccountInfo(user.uid);
        console.log("accountType",accountType)
        let step = await getRegistrationProgress(user.uid);
        step = parseFloat(step);

        console.log("STEP", step);
        console.log("ID: ", user.uid);

        const db = firebase.firestore();
        let result = await db.collection("sellersAccounts").doc(user.uid).collection("stores").doc(user.uid).get();
        let storeInfo = result.data();
        let { storeName, description, slug, sellerType, address, storeCategories, socialInstagram, socialFacebook,
              apt, street, city, state, country, zipcode } = storeInfo;

        console.log("FACEBOOK", socialFacebook);
        console.log("INSTA", socialInstagram);
        
        console.log("storeInfo",storeInfo)
        let mobile=phoneNumber;
        console.log("Phone prefix",mobile?.substring(0, 4))
        if(mobile?.substring(0, 1) == 1 || mobile?.substring(0, 2) == "+1"){
          console.log("Phone country (US)",mobile?.substring(0, 1))
          country="United States";
        }else if(mobile?.substring(0, 3) == "234" || mobile?.substring(0, 4) == "+234"){
          console.log("Phone country (NG)",mobile?.substring(0, 3))
          country="Nigeria";
        }

        this.setState({
          sellerId: user.uid,
          sellerName: user.displayName,
          storeName: storeName,
          description: description,
          slug: slug,
          sellerType: sellerType,
          address: address,
          storeCategories: storeCategories,
          socialInstagram: socialInstagram ? socialInstagram : "",
          socialFacebook: socialFacebook ? socialFacebook : "",
          accountType,
          street, city, state, country, zipcode 
        });

        //Find the index of the selected storeCategories
        var index;
        for (var i = 0; i < options.length; i++) {
          if (options[i].label == storeCategories) {
            index = i;
            break;
          }
        }

       this.setState({
         selected:[index]
       })
        
      } else {
        this.props.history.push("/");
      }
    });
  };
  
  getNigerianAddresses = async()=>{

    let stateNames = Object.keys(NigerianAddresses);
    let NigeriaLGA = {};
    await stateNames.forEach((state) => {
      let LGA=NigerianAddresses[state]
      
      NigeriaLGA[state] = LGA;
    });

    this.setState({
        NigeriaLGA
    })
  }


  componentDidMount() {

    this.getNigerianAddresses();
    if (this.props.location.state) {

      console.log("goToStoreSetup: ",this.props.location.state.goToStoreSetup)
      if(this.props.location.state.goToStoreSetup){
        this.goBackToStoreSetup(false);
        
      }else{
        this.goBackToStoreSetup(true);
      }

    } else {
      // checks for the auth state change of the user
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          let step = await getRegistrationProgress(user.uid);
          step = parseFloat(step);

          let location = await new Promise((resolve, reject) =>
            resolve(this.getLocation())
          );
          // step = parseFloat(step);
          console.log("STEP", step);

          if (step === 100) {
            this.props.history.push("/seller/dashboard");
          }

          let account= await this.getAccountInfo(user.uid);
          console.log("ACCOUNT", account);

          let country=this.state.country;
          console.log("user country",country)
          if (step > 2) {
            //already filled out the store info form
            //NEXT Bank setup
            if (country.toLowerCase() !== "nigeria") {
              this.props.history.push("/seller/dashboard/setup/usBank");
            } else {
              this.props.history.push("/seller/dashboard/setup/bank");
            }
          } else if (step > 1) {
            //already filled out the about store form
            //NEXT store info
            await this.getAboutFormValue(user.uid);
            this.showAboutStoreForm(false);
          }

          // this.checkForSellerStore();
          this.setState({
            sellerId: user.uid,
            sellerName: user.displayName,
          });
        } else {
          this.props.history.push("/");
        }
      });
    }

    //load Google Places Javascript Library
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({
        scriptLoaded: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    //load Google Places Javascript Library

    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({
          scriptLoaded: true,
        });
      } else this.props.onError();
    }
  }

  render() {
    return this.state.scriptLoaded ? (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Cashaam - Setup your Store</title>
          <meta
            content="Cashaam - Setup your Store and Start Selling"
            name="description"
          />
          <meta content="Cashaam - Setup your Store" property="og:title" />
          <meta
            content="Cashaam - Setup your Store and Start Selling"
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <meta name="msapplication-TileColor" content="#870000" />
          <meta name="theme-color" content="#870000" />
        </Metatags>
        <ToastContainer />

        {this.state.displayAboutStoreForm === true && this.state.accountType?.toLowerCase() ==="personal"? 
          <AccountForm 
            loading={this.state.loading}
              handleChange={this.handleChange}
              handleSubmit={this.submitAboutStore}
              location={this.state.userCurrentCountry}
              description={this.state.description}
              handleSlugChange={this.handleSlugChange}
              slug={this.state.slug}
              invalidSlug={this.state.invalidSlug}
              upperCaseDetectedInSlug={this.state.upperCaseDetectedInSlug}
              spaceDetectetInSlug={this.state.spaceDetectetInSlug}
              specialCharDetectedInSlug={this.state.specialCharDetectedInSlug}
              getSlugSuggestion={this.getSlugSuggestion}
              storeName={this.state.storeName}
              firstName={this.state.firstName}
              storeNameError={this.state.storeNameError}
              descriptionError={this.state.descriptionError}
              slugError={this.state.slugError}
              goBackToPickAccount={this.goBackToPickAccount}

          />
          :
          this.state.displayAboutStoreForm === true ? (
            <AboutStoreForm
              loading={this.state.loading}
              handleChange={this.handleChange}
              handleSubmit={this.submitAboutStore}
              location={this.state.userCurrentCountry}
              description={this.state.description}
              handleSlugChange={this.handleSlugChange}
              slug={this.state.slug}
              invalidSlug={this.state.invalidSlug}
              upperCaseDetectedInSlug={this.state.upperCaseDetectedInSlug}
              spaceDetectetInSlug={this.state.spaceDetectetInSlug}
              specialCharDetectedInSlug={this.state.specialCharDetectedInSlug}
              getSlugSuggestion={this.getSlugSuggestion}
              storeName={this.state.storeName}
              storeNameError={this.state.storeNameError}
              descriptionError={this.state.descriptionError}
              slugError={this.state.slugError}
              goBackToPickAccount={this.goBackToPickAccount}
            />
          ) : null}

          {this.state.storeSetupSuccess === false &&
              this.state.displayAboutStoreForm === false &&
              this.state.accountType?.toLowerCase() ==="personal" ?
            <PersonalDetails 
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
              options={options}
              selected={this.state.selected}
              selectCategory={this.selectCategory}
              address={this.state.address}
              sellerType={this.state.sellerType}
              setAddress={this.setAddress}
              handleSelectAddress={this.handleSelectAddress}
              socialInstagram={this.state.socialInstagram}
              socialFacebook={this.state.socialFacebook}
              loading={this.state.loading}
              shouldFetchSuggestions={this.state.address?.length > 3}
              onError={this.onError}
              invalidAddress={this.state.invalidAddress}
              location={this.state.userCurrentCountry}
              addressError={this.state.addressError}
              categoryError={this.state.categoryError}
              goBackToAboutStoreForm={this.goBackToAboutStoreForm}

              birthday={this.state.birthday}
              dobError={this.state.dobError}
              business={this.state.business}
              registedBusiness={this.state.registedBusiness}
              street={this.state.street}
              city={this.state.city}
              state={this.state.state}
              country={this.state.country}
              zipcode={this.state.zipcode}
              stateLGA={this.state.stateLGA}
              countryError={this.state.countryError}
              cityError={this.state.cityError}
              stateError={this.state.stateError}

            />
          :
            this.state.storeSetupSuccess === false &&
            this.state.displayAboutStoreForm === false ? (
              <StoreSetupForm
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                options={options}
                selected={this.state.selected}
                selectCategory={this.selectCategory}
                address={this.state.address}
                sellerType={this.state.sellerType}
                setAddress={this.setAddress}
                handleSelectAddress={this.handleSelectAddress}
                socialInstagram={this.state.socialInstagram}
                socialFacebook={this.state.socialFacebook}
                loading={this.state.loading}
                shouldFetchSuggestions={this.state.address?.length > 3}
                onError={this.onError}
                invalidAddress={this.state.invalidAddress}
                location={this.state.userCurrentCountry}
                addressError={this.state.addressError}
                categoryError={this.state.categoryError}
                goBackToAboutStoreForm={this.goBackToAboutStoreForm}

                street={this.state.street}
                city={this.state.city}
                state={this.state.state}
                country={this.state.country}
                zipcode={this.state.zipcode}
                stateLGA={this.state.stateLGA}
                countryError={this.state.countryError}
                cityError={this.state.cityError}
                stateError={this.state.stateError}
                birthday={this.state.birthday}
                dobError={this.state.dobError}
              />
            ) : null}
        {/* {this.state.storeSetupSuccess === true ? (
          <StoreSetupSuccess
            storeURL={`https://cashaam.com/${this.state.slug}`}
            country={this.state.userCurrentCountry}
          />
        ) : null} */}
      </>
    ) : (
      <div>loading... please wait</div>
    );
  }
}

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&region=NG`,
])(StoreSetup);