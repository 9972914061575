import React, { Component } from "react";
import firebase from "../../../../firebaseConfig/index";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import AddBankForm from "../../../UI/AddBankForm";
import AddBankSuccess from "../../../common/AddBankSuccess";
import UpdateBankFailed from "../../../common/UpdateBankFailed";
import BvnInfo from "../../../UI/BvnInfo";
import { ToastContainer, toast } from "react-toastify";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import "react-toastify/dist/ReactToastify.css";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import BankData from "../../../../secrets/NigerianBank.json";
import getBasicInfo from "../../../helpers/getBasicInfo";
import {BVN_API, VERIFY_ACCOUNT, PAYSTACK_SECRET_KEY} from "../../../../secrets/index.js";

const DUMMY_BVN="01234567890";
class AddBankInfo extends Component {
  state = {
    sellerName: "",
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    bvn: "",
    validBVN: false,
    bankNames: [],
    banks: {},
    submitFailed: false,
    showAccNum: "password",
    showBvn: "password",
    loading: false,
    submitSuccess: false,
    displayBvnInfo: false,
    accountNameError: "",
    accountNumberError: "",
    bankNameError: "",
    bvnError: ""
    // subaccount_code: ""
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  showBankFormOnly=()=>{
    this.setState({
      displayBvnInfo: false,
      bankFormOnly: true
    })

  }

  handleBVN=(e)=>{
    e.preventDefault();
    this.displayLoading();

    const {
      accountHolderName,
      bankName,
      accountNumber,
      bvn,
      bvnError
      // subaccount_code
    } = this.state;

    this.setState({
      bvnError: ""
    });

    //check that bvn 11 digits
    var bvnNum = parseInt(bvn);
    if (bvn.length != 11 && bvnNum.toString().length != 11) {
      this.hideLoading();

      this.setState({
        bvnError: "Please enter your 11 digit BVN"
      });
      return;
    }

    this.showBankFormOnly();

  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.displayLoading();

    const {
      accountHolderName,
      bankName,
      accountNumber,
      bvn,
      bvnError
      // subaccount_code
    } = this.state;

    this.setState({
      bvnError: ""
    });

    //check that bvn 11 digits
    var bvnNum = parseInt(bvn);
    if (bvn.length != 11 && bvnNum.toString().length != 11) {
      this.hideLoading();

      this.setState({
        bvnError: "Please enter your 11 digit BVN"
      });
      return;
    }

    if (accountHolderName && bankName && accountNumber && bvn) {
      // validate input with bvn info first
      const result = await this.handleBankInfo();

      console.log("BvnCheck result Status: ", result.valid);
      //There was an error in handleBankInfo if result.valid is undefined
      if (result.valid == null) {
        // toast.error("Incorrect info, please verify that you have entered the correct bank information.")
        this.hideLoading();
        return this.displayError();
      }
    } else {
      this.hideLoading();
      toast.error("Please enter all necessary information");
      return;
    }
  };

  displayError = () => {
    this.setState({
      submitFailed: true
    });
    // setTimeout(() => this.hideError(), 5000);
  };

  hideError = () => {
    this.setState({ submitFailed: false });
  };

  //Valides bvn and stores the bank info into the databse
  handleBankInfo = async () => {
    try {
      //capture the current user uid for save their progress
      const sellerUid = firebase.auth().currentUser.uid;

      const { accountHolderName, accountNumber, bvn, bankName } = this.state;

      let nameArray = accountHolderName.split(" ");
      let firstName = nameArray[0];
      let lastName = nameArray[1];

      const { banks } = this.state;
      const bankCode = banks[bankName];
      let api = BVN_API + "/bvncheck";
      let response = await fetch(
        api,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            sellerId: sellerUid,
            account_number: accountNumber,
            bank_code: bankCode,
            bvn: bvn,
            first_name: accountHolderName,
            last_name: ""
          })
        }
      );

      if (response.status === 200) {
        // after the store has been setup, save the user's progress
        this.hideLoading();
        await saveRegisterProgress(sellerUid, 4);
        this.setState({ submitSuccess: true });

        let responseJson = await response.json();
        console.log("responseJson",responseJson)
        let { account_name, subaccount_code } = responseJson;
        let valid = true;
        return { valid, subaccount_code };
      } else {
        console.log("bank validation failed");
        this.displayError();
        this.hideLoading();
        return false;
      }
    } catch (error) {
      console.log("error validating bankInfo--", error);
      this.displayError();
      this.hideLoading();
      return false;
    }
  };

  checkForSellerStore = () => {
    const db = firebase.firestore();
    const sellerUid = firebase.auth().currentUser.uid;
    db.collection("sellersAccounts")
      .doc(sellerUid)
      .collection("bank")
      .get()
      .then((sellerStoreData) => {
        // if the seller already has a store in collection then direct them back to dashboard
        if (sellerStoreData.empty === false) {
          this.props.history.push("/seller/dashboard");
        }
      });
  };

  onShowAccNum = (val) => {
    if (val === "password") {
      this.setState({ showAccNum: "text" });
    } else {
      this.setState({ showAccNum: "password" });
    }
  };

  onShowBvn = (val) => {
    if (val === "password") {
      this.setState({ showBvn: "text" });
    } else {
      this.setState({ showBvn: "password" });
    }
  };

  selectBank = (value) => {
    this.setState({ bankName: value });
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    //retrieve all bank names from json file
    let bankNames = Object.keys(BankData);
    let banks = {};
    bankNames.forEach((bankName) => {
      banks[bankName] = BankData[bankName].code;
    });
    this.setState({
      bankNames: bankNames,
      banks: banks
    });

    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged(async (user) => {
      //Check if info is available in sessionStorage
      if (sessionStorage.getItem(user.uid)) {
        console.log("Retrive bank info from sessionStorage");
        // Retrieve the user from storage   
        // var user = await sessionStorage.getItem(user.uid);

        // console.log("USER INFO ", JSON.parse(user));

        //  let savedStoreInfo = JSON.parse(user);
        // let {
        //   sellerId,
        //   accountHolderName,
        //   accountNumber,
        //   bankName,
        // } = savedStoreInfo;

        const storeInfo = await getBasicInfo(user.uid);
        let{
          id,
          firstName,
          lastName,
          accountType
        }=storeInfo;


        this.setState({
          sellerId: id,
          // sellerName: user.displayName,
          // accountHolderName: accountHolderName,
          // accountNumber: accountNumber,
          // bankName: bankName,
          accountType
        });
      } else if (user) {
        // this.checkForSellerStore();
        let step = await getRegistrationProgress(user.uid);
        step = parseFloat(step);

        if (step === 100) {
          this.props.history.push("/seller/dashboard");
        }
        if (step <= 2) {
          //user did not complete store info form
          //SEND BACK to store setup
          this.props.history.push("/seller/dashboard/setup");
        }

        if (step > 3) {
          //user already setup their bank
          //NEXT add products
          this.props.history.push("/seller/dashboard/addProduct");
        }
      } else {
        this.props.history.push("/");
      }
    });
  }

  setUpLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  bankExists = (bankName) => {
    let banks = Object.keys(BankData);
    if (banks.length === 0) {
      console.error("BankData returned 0 values");
      return false;
    }
    let exists = false;
    banks.forEach((name) => {
      if (name.toUpperCase() == bankName.toUpperCase()) {
        exists = true;
      }
    });
    if (exists) {
      console.log("Bank exists");
      return true;
    } else {
      console.log("Bank does not exist");
      return false;
    }
  };

  failValidation=()=>{
    this.setState({
      accountNumber: "",
      accountHolderName: "",
      validateAccount:""
    })
  }

  completeValidation = async()=>{
    let accountName=this.state.validateAccount;

    let names=accountName.split(" ");
    //accountName is first, middle, and last name
    //Get first and last name. Use Middle name if there are 2 names
    let firstAndLast=`${names[0]} ${names[2]? names[2]: names[1]}`

    let bvn=this.state.bvn;
      
    if(!this.state.bvn){
      bvn= DUMMY_BVN
    }

    await this.setState({
      bvn,
      accountHolderName: accountName,
      validateAccount:"",
      loadingBankUpload: true
    })

    const result = await this.handleBankInfo();

    console.log("BvnCheck result Status: ", result.valid);
    //There was an error in handleBankInfo if result.valid is undefined
    this.setState({
      loadingBankUpload: false
    })
    if (result.valid == null) {
      this.hideLoading();
      this.setState({
        verificationError: true
      })

      return;
      
    }


  }

  submitBankInfo = async (e) => {
    e.preventDefault();
    const {
      accountNumber,
      accountHolderName,
      bankName,
      accountNameError,
      accountNumberError,
      bankNameError
    } = this.state;

    this.setState({
      accountNameError: "",
      accountNumberError: "",
      bankNameError: "",
      loadingBankUpload: true,
      verificationError:false,
      validateAccount: ""
    });

    if (!bankName) {
      this.setState({
        bankNameError: "Please enter your bank"
      });
      return;
    }

    // if (this.state.takenSlugs.includes(slug)) {
    const exists = this.bankExists(bankName);
    if (exists !== true) {
      this.setState({
        bankNameError: "Enter Your Bank Account Information",
        loadingBankUpload: false
      });
      return;
    }

    if(this.state.manualBankEntry){
      
    
      if (!accountHolderName) {
        this.setState({
          accountNameError: "Please enter your full name",
          loadingBankUpload: false
        });
        return;
      }

      var hasNumber = /\d/;

      if (hasNumber.test(accountHolderName)) {
        this.setState({
          accountNameError: "Please remove any numbers from your name",
          loadingBankUpload: false
        });
        return;
      }

      let nameArray = accountHolderName.split(" ");
      if (nameArray.length < 2) {
        this.setState({
          accountNameError: "Please enter your full name",
          loadingBankUpload: false
        });
        return;
      }

      let firstName = nameArray[0];
      let lastName = nameArray[1];

      if (!firstName || !lastName) {
        this.setState({
          accountNameError: "Please enter your first and last name",
          loadingBankUpload: false
        });
        return;
      }
    }

    if (accountNumber.length != 10) {
      this.setState({
        accountNumberError: "Please enter a 10 digit account number",
        loadingBankUpload: false
      });
      return;
    }

    if (!/^-?\d+$/.test(accountNumber)) {
      this.setState({
        accountNumberError:
          "Please enter only numbers (no spaces, or special characters",
          loadingBankUpload: false
      });
      return;
    }

    //capture the current user id
    const sellerId = firebase.auth().currentUser.uid;

    var saveUser = JSON.parse(sessionStorage.getItem(sellerId)) || {};

    saveUser.accountNumber = accountNumber;
    saveUser.accountHolderName = accountHolderName;
    saveUser.bankName = bankName;

    console.log("Saved B info in sessionStorage: ", saveUser);
    // update info in sessionStorage
    sessionStorage.setItem(sellerId, JSON.stringify(saveUser));

    const { banks } = this.state;
    const bankCode = banks[bankName];
    let accountName=await this.verifyBankAccount(accountNumber,bankCode );
    console.log("verified account:", accountName);
    

    console.log("accountName",accountName)
    if(accountName){
      this.setState({ 
        validateAccount: accountName,
        loadingBankUpload: false
      })

    }else{
      this.setState({ 
        verificationError: true,
        loadingBankUpload: false
       })
    }

  };

  goToBVN=()=>{
    this.setState({ displayBvnInfo: true });
  }

  verifyBankAccount= async(accountNumber,bankCode)=>{

    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${PAYSTACK_SECRET_KEY}`);


      const res = await fetch(`${VERIFY_ACCOUNT}=${accountNumber}&bank_code=${bankCode}`, {
        method: "GET",
        headers: myHeaders,
      });

      let accountInfo=await res.json();
      console.log("verifyBankAccount",accountInfo);
      if(res.status==200 && accountInfo.status==true) {
        let{data} = accountInfo;
        let accountName = data?.account_name;
        
        return accountName;

      }else{
        return null
      }

    }catch (error) {
      console.log("error verifyBankAccount:", error);
      return null
    }

  }
  onShowBankForm = () => {
    this.setState({
      displayBvnInfo: false
    });
  };

  retry = () => {
    this.setState({
      submitSuccess: false,
      displayBvnInfo: false,
      submitFailed: false,
      loading: false
    });
  };

  goToAddProduct = () => {
    // Direct the seller to the seller's addProduct dashboard
    this.props.history.push("/seller/dashboard/addProduct");
  };

  goToAddProduct = () => {
    // Direct the seller to the seller's addProduct dashboard
    this.props.history.push("/seller/dashboard/addProduct");
  };

  goBackToStoreSetup = () => {
    //Go back to the Store Setup Form (Previous page)
    this.props.history.push({
      pathname: "/seller/dashboard/setup",
      state: { goToStoreSetup: true }
    });
  };

  render() {
    return (
      <>
        <ToastContainer />
        {this.state.submitSuccess === false &&
        this.state.displayBvnInfo === false &&
        this.state.submitFailed === false ? (
          <AddBankForm
            submitFailed={this.state.submitFailed}
            submitBankInfo={this.submitBankInfo}
            goToBVN={this.goToBVN}
            handleChange={this.handleChange}
            bankName={this.state.bankName}
            bankNames={this.state.bankNames}
            selectBank={this.selectBank}
            showAccNum={this.state.showAccNum}
            onShowAccNum={this.onShowAccNum}
            showBvn={this.state.showBvn}
            onShowBvn={this.onShowBvn}
            loading={this.state.loading}
            accountHolderName={this.state.accountHolderName}
            accountNumber={this.state.accountNumber}
            bvn={this.state.bvn}
            goBackToStoreSetup={this.goBackToStoreSetup}
            bankNameError={this.state.bankNameError}
            accountNameError={this.state.accountNameError}
            accountNumberError={this.state.accountNumberError}
            bankFormOnly={this.state.bankFormOnly}
            manualBankEntry={this.state.manualBankEntry}
            verificationError={this.state.verificationError}
            loadingBankUpload={this.state.loadingBankUpload}
            validateAccount={this.state.validateAccount}
            completeValidation={this.completeValidation}
            failValidation={this.failValidation}
            accountType={this.state.accountType}
          />
        ) : null}
        {this.state.displayBvnInfo === true &&
        this.state.submitSuccess !== true &&
        this.state.submitFailed !== true ? (
          <BvnInfo
            handleChange={this.handleChange}
            // submitFailed={this.state.submitFailed}
            loading={this.state.loading}
            // handleSubmit={this.handleSubmit}
            handleSubmit={this.handleBVN}
            goBack={() => this.onShowBankForm()}
            bvnError={this.state.bvnError}
          />
        ) : null}
        {this.state.submitSuccess === true ? (
          <AddBankSuccess goToAddProduct={this.goToAddProduct} />
        ) : null}
        {this.state.submitFailed === true ? (
          <UpdateBankFailed retry={this.retry} />
        ) : null}{" "}
      </>
    );
  }
}

export default AddBankInfo;
