import React from "react";

class PickupPopup extends React.Component {
  render() {
    return (
      <div className="fixedmodalwrapper gray-background">
        <div style={{overflow:"visible"}} className="fixedmodalcontainer fixed-modal">
          <div className="fixedmodalhead itemaddedmodalhead">
            {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
            <div
              className="text-block-4"
              style={{ margin: "auto", fontWeight: "bold" }}
            >
              Confirm Pick Up From Store
            </div>
          </div>

          
            <div style={{margin: "0"}} className="paymentmodalbuttonwrap">
              
              <div className="pickup-disclaimer">By turning on Pick Up From Store, you are accepting to <b>DELIVER THE ORDER</b> to the customers at <b>NO CHARGE</b> and you cannot print shipping labels.</div>

              <div className="option-btns">
                
              <div className="updatestatusbutton w-button disable-btn" 
                    style={{cursor: "pointer", backgroundColor:"red"}}
                    onClick={()=>this.props.handlePickup(false)}
              >
                    <div className="text-btn-primary">Disabled</div>
                </div>

                <div className="updatestatusbutton enable-btn w-button" 
                     style={{cursor: "pointer", backgroundColor:"#5fbf5f"}}
                     onClick={()=>this.props.handlePickup(true)}
                >
                    <div className="text-btn-primary">Enable</div>
                </div>

              </div>
              
            </div>
          <a
            onClick={() => this.props.hidePickup()}
            className="fixedmodalexitimg w-inline-block"
          />
        </div>
      </div>
    );
  }
}

export default PickupPopup;
