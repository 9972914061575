import firebase from "../../firebaseConfig";
import { SHIPO_KEY } from "../../secrets";
const db = firebase.firestore();
const shippo = require("shippo")(SHIPO_KEY);
//Default parcel size: 34x 17 x 17

var defaultParcel = {
    length: "5", //max 108 inches
    width: "5", //max 108 inches
    height: "5", //max 108 inches
    distance_unit: "in",
    weight: "5", //max 70 lbs for USPS and 150 lbs for UPS
    mass_unit: "lb",
};

const getShippingOptions = (addressTo, addressFrom, parcel) => {
    return new Promise((resolve, reject) => {
        try {
            let myParcel = parcel;
            if (myParcel) {
                if (parseFloat(myParcel.length) < 7) {
                    myParcel.length = 6;
                }

                if (parseFloat(myParcel.width) < 4) {
                    myParcel.width = 3;
                }

                if (parseFloat(myParcel.height) < 0.3) {
                    myParcel.height = 0.25;
                }
                if (parseFloat(myParcel.weight) < 0.075) {
                    myParcel.weight = 0.075;
                }
            }
            console.log("my parcel", myParcel);
            shippo.shipment.create(
                {
                    address_from: addressFrom,
                    address_to: addressTo,
                    parcels: myParcel !== null ? [myParcel] : [defaultParcel],
                    async: false,
                },
                function (err, shipment) {
                    // asynchronously called
                    // var rate = shipment.rates[0];
                    resolve(shipment);
                }
            );
        } catch (error) {

            console.log("error getting shipping options", error);
            reject(error);
        }
    });
};
export default getShippingOptions;

