import React from "react";

class SuccessSubscription extends React.Component {
  render() {
    return (
      <div className="fixedmodalwrapper gray-background">
        <div style={{overflow:"visible"}} className="fixedmodalcontainer fixed-modal">
          <div className="fixedmodalhead itemaddedmodalhead">
            {/* <div className="fixedmodalheadimg"><img src="images/Group-1.svg" loading="lazy" alt /></div> */}
            <div
              className="text-block-4"
              style={{ margin: "auto", fontWeight: "bold" }}
            >
              {this.props.accountType=="Personal"?"Payment was Successful"
              :
                this.props.successType=="downgrade"?
                "Downgrade Successful"
                :
                "Subscription Successful"}
            </div>
          </div>


            <div className="airtime-resp-container">
              
              <div className="success-payment"></div>
              {this.props.successType=="personal"?
                <>
                <div style={{color: "black"}} className="airtime-resp">We have activated your personal account!</div>
                </>
              :
                (this.props.successType=="subscription"?
                  <div style={{color: "black"}} className="airtime-resp">We have received your subscription payment!</div>
                  :
                  this.props.successType=="downgrade"?
                    <div style={{color: "black"}} className="airtime-resp">You have successfully downgraded your account to a personal account.</div>
                    :
                    <div style={{color: "black"}} className="airtime-resp">We have received your subscription payment!</div>
                )
              }

              {this.props.accountType=="Personal"&&this.props.successType=="subscription"?
                <div style={{color: "black"}} className="airtime-resp">Please change your slug before adding products</div>
              :null}

              <div className="next-steps">
                
                    <a style={{marginTop: "10px"}} href="https://calendly.com/cashaam-support" target="_blank" className="helppopupbutton w-inline-block">
                      <img src="https://res.cloudinary.com/cashaam/image/upload/v1664206323/logos/Phone_gjtytu.svg" loading="lazy" alt />
                      <div>Schedule Time with Us</div>
                    </a>
                

                  {this.props.successType=="subscription"?
                    <a href={`https://shop.cashaam.com/${this.props.slug}`} target="_blank" className="helppopupbutton w-inline-block visit-ur-store">

                      <div>Visit Your Store</div>
                    </a>

                    :null

                  }

              </div>
            </div>
          
          <a
            onClick={() => this.props.hideSuccesMsg()}
            className="fixedmodalexitimg w-inline-block"
          />
        </div>
      </div>
    );
  }
}

export default SuccessSubscription;
