import React from "react";
import CardAgreement from "./CardAgreement"

function UserAgreement(props) {
  return (
    <div class="popupmodal" style={{ display: "flex" }}>
      <div className="popup firststep" style={{ display: "flex" }}>
        <div
          data-w-id="a3788408-9a71-6de8-31ec-24277824e6da"
          className="closepopup"
          onClick={props.hideAgreement}
        />
        <div className="popuptitle">CASHAAM USER DOLLAR VIRTUAL AGREEMENT</div>
        <div className="instructiontitle">Please Read and Accept To Proceed</div>
        <div style={{fontWeight: "900"}} className="instructiontext">
          This Card is can ONLY to be Used to Make Online Payments
        </div>
        <div className="instructionbox">
          <div className="w-richtext">
           
            <CardAgreement />

          </div> 
        </div>
        <div className="popupbuttonwrap">
          <a
            data-w-id="a3788408-9a71-6de8-31ec-24277824e6f9"
            onClick={props.hideAgreement}
            className="skipbutton"
          >
            Disagree
          </a>
          <a
            data-w-id="a3788408-9a71-6de8-31ec-24277824e6fb"
            onClick={props.createAndTopup}
            className="popupbutton w-inline-block"
          >
            <div>Agree</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default UserAgreement;
