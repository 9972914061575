import firebase from "../../firebaseConfig";

const getSoldItemsCount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let transactions = await db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("transactions")
        .orderBy("date", "desc")
        .get();
      if (transactions.empty) {
        console.log("No matching documents.");
        resolve(0);
      }
      let sold = [];
      let count = 0;

      transactions.forEach((doc) => {
        sold.push(doc.data());
        let orderCount = doc.data().orderIds.length;
        count += orderCount;
      });

      resolve(count);
    } catch (error) {
      console.log("error getting basic info", error);
      reject(error);
    }
  });
};
export default getSoldItemsCount;
