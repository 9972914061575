
import ChatListItems from "./ChatListItems";

import React, { Component, useState, useRef, useEffect } from "react";
import firebase from "../../../../../firebaseConfig";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData, useDocumentOnce,useDocument,useCollectionData } from 'react-firebase-hooks/firestore';


function ChatList(props){


    const messagesEndRef = useRef();

  
    const auth = firebase.auth();
    const firestore = firebase.firestore();
  
    
    var messagesRef = firestore.collection("messages")
    // const query = messagesRef.orderBy('sellerId',"desc").orderBy('updatedAt',"desc");
    var query = messagesRef.where("sellerId", "==", props.sellerId);
    //query = messagesRef.orderBy('updatedAt',"desc");
    // query = messagesRef.orderBy('updatedAt');

  
    const [chatList, loading, error] = useCollectionData(query, { idField: 'id' });
  
    return (
      <div className={`main__chatlist ${props.showMenu}`}>
        {/* <button className="btn">
          <i className="fa fa-plus"></i>
          <span>New conversation</span>
        </button> */}
        <div className="chatlist__heading">
          <h2 style={{margin:"0 auto"}}>Chats</h2>
          {/* <button className="btn-nobg">
            <i className="fa fa-ellipsis-h"></i>
          </button> */}
        </div>
        {/* <div className="chatList__search">
          <div className="search_wrap">
            <input type="text" placeholder="Search Here" required />
            <button className="search-btn">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div> */}
        <div className="chatlist__items">

          {chatList?.map((item, index) => {
            return (
              <ChatListItems
                key={item.messagesId}
                messagesId={item.id}
                animationDelay={index + 1}
                active={item.active ? "active" : ""}
                isOnline={item.isOnline ? "active" : ""}
                image={item.buyerAvatar}
                goToChat={props.goToChat}
                sellerId={item.sellerId}
                sellerAvatar={item.sellerAvatar}
                sellerName={item.sellerName}
                buyerId={item.buyerId}
                buyerAvatar={item.buyerAvatar}
                buyerName={item.buyerName}
                updatedAt={item.updatedAt}
                sellerUnread={item.sellerUnread?item.sellerUnread:0}
              />
            );
          })}
        </div>
      </div>
    );
  
}

export default ChatList;
