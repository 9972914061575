import React from "react";

function ConnectorItem(props) {
  return (
    <div
      className="s3RriZe o3IHXlx--selected o3IHXlx---size-6-medium o3IHXlx---contentAlignment-6-center sPKtd3I"
      data-selected="true"
      data-disabled="false"
      tabIndex={0}
      data-hook="thumbnail-wrapper"
      onClick={(e)=>props.selectCategory(props.category,e)}
      style={{height: 204, border:`${props.selectedCategory == props.category?"2px solid #7455c1":"1px solid #d0d7f0"}`}}
    >
      {props.selectedCategory == props.category ? (
        <div className="s1RMXDY" data-hook="thumbnail-selected-icon">
          <svg viewBox="0 0 10 8" fill="currentColor" width={10} height="7.8">
            <path d="M3.5 5.2L1.2 3 0 4.2 3.5 7.8 10 1.2 8.8 0z" />
          </svg>
        </div>
      ) : null}

      <div>
        <div className="si-0C4M" data-hook="thumbnail-image">
          <img alt="ad-goal" height={60} width={60} src={props.icon} />
        </div>
        <div
          data-hook="thumbnail-title"
          data-mask="false"
          style={{ fontWeight: "bold" }}
          className="s1-qb7n o1G-RQh---size-6-medium o1G-RQh---skin-8-standard o1G-RQh---weight-6-normal o1G-RQh---list-style-9-checkmark s2rcAW7"
          data-size="medium"
          data-secondary="false"
          data-skin="standard"
          data-light="false"
          data-weight="normal"
          data-list-style="checkmark"
        >
          {props.title}
        </div>
        <div
          data-hook="thumbnail-description"
          data-mask="false"
          className="s1-qb7n o1G-RQh---size-6-medium o1G-RQh--secondary o1G-RQh---skin-8-standard o1G-RQh---weight-4-thin o1G-RQh---list-style-9-checkmark sJkIOGl"
          data-size="medium"
          data-secondary="true"
          data-skin="standard"
          data-light="false"
          data-weight="thin"
          data-list-style="checkmark"
        >
          {props.description}
        </div>
      </div>
    </div>
  );
}

export default ConnectorItem;
