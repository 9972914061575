import React from "react";
import Loader from "react-loader-spinner";
import Autocomplete from "react-autocomplete";

function FreeTrial(props) {
  return (
    <div className="fixedmodalwrapper gray-background">
      <div className="fixedmodalcontainer fixed-modal">
        {/* <div className="fixedmodalhead itemaddedmodalhead">
          <div
            className="text-block-4"
            style={{ margin: "auto", fontWeight: "bold" }}
          >
            Free Trial
          </div>
        </div> */}

        {props.loadingSubscription === true ? (
          <div className="loading-content" style={{ width: "300px" }}>
            <Loader type="TailSpin" color="#7455c1" height={100} width={100} />
            <div>Loading...</div>
          </div>
        )
          :
          (

            props.subscribed?
            (
                (props.subscriptionSuccess===true? 
                    <div className="airtime-resp-container">
                        <div className="success-payment"></div>
                        <div className="airtime-resp">You have successfully Subscribed to Cashaam. Please refresh the page to get the updated status.</div>
                    </div>
                :
                    <div className="airtime-resp-container">
                        <div className="error-payment"></div>
                        <div className="airtime-resp">There was an error with your subscription. Please {" "}
                        <a target="_blank" href="https://support.cashaam.com">
                            Contact Us
                        </a> {" "}
                        to help you fix this issue.</div>
                        
                    </div>
                )
            ):
        

        <div 
          style={{
            marginTop: "10px"
          }}
          className="paymentmodalbuttonwrap">
          <div className="column transfer-container">

            <div style={{lineHeight:"20px"}} className="free-trial-title">
                Try for 1 month and Get your free Virtual Dollar Card today with $5
            </div>


            <div 
                className="free-trial-btn"
                onClick={props.startFreeTrial}
            >
                <div>Try For ₦100</div>
                <img className="targeting-img" src="https://res.cloudinary.com/cashaam/image/upload/v1664356257/icons/free-target-icon-777-thumb-removebg-preview_kjrf00.png" />
            </div>

            <div>
                *Only for the 1st month (30 days). Standard Monthly pricing after trial.
            </div>

          </div>
        </div>
        )}
        <a
            style={{top: "20px", right: "20px"}}
            onClick={props.hideFreeTrial}
          className="fixedmodalexitimg w-inline-block"
        />
      </div>
    </div>
  );
}

export default FreeTrial;
