import React from "react";
import priceFormaterIntl from "../helpers/priceFormaterIntl"

function ProductItem(props) {
  return (
    <div className="productlistitem">
      <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Product</div>
        </div>
        <a 
            className="plproductimg w-inline-block"
            style={{backgroundImage: `url(${props.image}`}} 
        />
      </div>
      <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Name</div>
        </div>
        <a href="#" className="plproductname w-inline-block">
          <div>{props.productName}</div>
        </a>
      </div>
      <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Quantity</div>
        </div>
        <div>{props?.orderQuantity}</div>
      </div>
      <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Base Price</div>
          {/* <div className="productlistsubtitle">Tax included</div> */}
        </div>
        <div>{priceFormaterIntl(props.productPrice, props.currency)}</div>
      </div>


      {/* <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Variant </div>
        </div>
        {props.selectedColor ? 
          <div>Color:</div>
        :null}

        {props.selectedSize ? 
          <div>Size:</div>
        :null}

        {props.selectedExtraVariants.map((variant, i) => (
          <div>{variant.name}:</div>
        ))}
      </div> */}

      <div className="wrapbox variant-boxes">
        {props.selectedColor || props.selectedSize ||props.selectedExtraVariants.length>0?
            <div className="productlisttitletext mobilepltext">
            Variant
          </div>:null
       }

          <div className="variant-container checkoutitemtitle"> 
          
            {props.selectedColor ? (
              <div className="checkoutvariantitem">
                <div className="checkoutvarianttitle">Color: </div>
                <div className="checkoutvariantvalue">{props.selectedColor}</div>
              </div>
            ) : null}

            {props.selectedSize ? (
              <div className="checkoutvariantitem">
                <div className="checkoutvarianttitle">Size:</div>
                <div className="checkoutvariantvalue">{props.selectedSize}</div>
              </div>
            ) : null}

            {props.selectedExtraVariants.map((variant, i) => (
              <div className="checkoutvariantitem">
                <div className="checkoutvarianttitle">{`${variant.name}`}:</div>
                <div className="checkoutvariantvalue">{variant.value}</div>
              </div>
            ))}
        </div>
        
      </div>

      <div className="wrapbox">
        <div className="productlisttitletext mobilepltext">
          <div>Total</div>
          {/* <div className="productlistsubtitle">Tax included</div> */}
        </div>
        <div>{priceFormaterIntl(props.productPrice *props.orderQuantity, props.currency)}</div>
      </div>


      <div className="plempty" />
    </div>
  );
}

export default ProductItem;
