import { CREATE_STRIPE_USER } from "../../secrets";

const createStripeUser =(userId,accountHolderName,accountHolderType,accountNumber,routingNumber)=>{
    return new Promise( async (resolve,reject)=>{
        try {
            const queryData = {
                userId,
                bankAccount: {
                    country: "US",
                    account_holder_name: accountHolderName,
                    account_holder_type: accountHolderType,
                    routing_number: routingNumber,
                    account_number: accountNumber
                }
              };
              const res = await fetch(CREATE_STRIPE_USER, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(queryData),
              });
              console.log("createStripeUser res",res);
        
              if (res.status === 200) {
                resolve("success");
              } else {
                console.log("Error creating stripe user", res);
                reject(res);
              }
        } catch (error) {

            console.log("error creating stripe user",error);
            reject(error);
        }
    })
}
export default createStripeUser;