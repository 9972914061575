import React from "react";

function CashoutOption(props) {
  return (
    <div 
    onClick={() =>
        props.showTransfer()
      }
        className={`paymentmodalpointswrapper longpointswrapper ${props.paymentMethod===props.name? "selectedlongpointswrapper":""}`}
    >
      <div className="paymentmodalpointscontainer shortpointscontainer">
        
        {/* <div className="paymentmodalpointstext">
          Pay with <span className="paymentmodalpointstextbold">{props.name}</span>
        </div> */}
        <div className="paymentmodalcoincounterwrap">
          <div>Transfer your money using</div>
        </div>
      </div>
      <div className="paymentmodalpointsbrandimg sub-img-holder">
        <img className={`sub-img-${props.name}`} src={props.image} loading="lazy" width={137} alt />
      </div>
    </div>
  );
}

export default CashoutOption;