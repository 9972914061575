import firebase from "../../../firebaseConfig";

const setUserTheme = (id, themeName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let batch = db.batch();
      const sellerRef = db.collection("sellersAccounts").doc(id);
      batch.set(
        sellerRef,
        { customTheme: themeName, themeName },
        { merge: true }
      );
      const storeRef = db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id);
      batch.set(
        storeRef,
        { customTheme: themeName, themeName },
        { merge: true }
      );

      await batch.commit();
      resolve("success");
    } catch (error) {
      console.log("error setting user theme", error);
      reject(error);
    }
  });
};
export default setUserTheme;
