import React, { Component } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export default class ScheduleDelivery extends Component {

  state={
    date: new Date(),
    time:"",

  }

  submitDelivery = () => {
    let{date, time, dateError}=this.state;
    if (!date || dateError) {
      return;
    }

    let dateObj = date;
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    let totalProductWeight = 0;
    this.props.orderList.map((order) => {
      totalProductWeight += parseFloat(order?.productWeight);
    });
    console.log("totalProductWeight", totalProductWeight);

    if (totalProductWeight >= 4.5) {
      this.props.handleOverWeight(totalProductWeight);
      return;
    }

    this.props.scheduleDelivery(month, day, year);
  };

  changeDate = async (e) => {
    let val= e.target.value;
    this.setState({
      dateError: ""
    })

    console.log("changeDate",val)

    let splitDate=val.split("-");
    console.log("splitDate",splitDate)
    let dateObj = new Date(`${val}`);
    console.log("picked date",dateObj)

    let hour=this.props.deliveryObj?.hour;

    dateObj.setTime(dateObj.getTime() + 15 * 60 * 60 * 1000);
    
    
    console.log("time after shift by 15hr",dateObj)
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    console.log(`${month}, ${day}, ${year}`);



    if(dateObj>this.state.tenDayAhead){
      console.log("Date is more than 10 days in the future !!!");
      console.log("last date allowed",this.state.tenDayAhead)
      this.setState({
        dateError: "You cannot schedule for more than 10 days in the future."
      })
      return;
    }

    console.log("today before check: ",this.state.today);
    console.log("Schedule date before check:",dateObj)


    let originalDate = new Date(dateObj.valueOf());
    
    
    if(dateObj.setHours(0, 0, 0, 0) <= this.state.today.setHours(0, 0, 0, 0)){
      console.log("Date is in the past !!!")
      console.log("today's date:",this.state.today);
      console.log("Schedule date:",dateObj)
      this.setState({
        dateError: "You cannot schedule for today or the past days"
      })
      return;
    }

    console.log("ruined dateObj",dateObj)
    console.log("originalDate => ",originalDate);
    this.props.handleChangedDate(month, day, year, originalDate);

    // onChange(val);
    this.setState({ 
      date: originalDate
    })

  };

  componentDidMount() {
    const tenDayAhead= new Date(Date.now() + 10 * 24 * 60 * 60 * 1000);
    var maxMonth = tenDayAhead.getUTCMonth() + 1;
    var maxDay = tenDayAhead.getUTCDate();
    var maxYear = tenDayAhead.getUTCFullYear();
    if (maxDay < 10) {
      maxDay = "0" + maxDay;
    }
    if (maxMonth < 10) {
      maxMonth = "0" + maxMonth;
    }

    let maxDate=maxYear + "-" + maxMonth+"-" + maxDay;


    const today=new Date();
    var minMonth = today.getUTCMonth() + 1;
    var minDay = today.getUTCDate();
    var minYear = today.getUTCFullYear();
    if (minDay < 10) {
      minDay = "0" + minDay;
    }
    if (minMonth < 10) {
      minMonth = "0" + minMonth;
    }

    let minDate=minYear + "-" + minMonth+"-" + minDay;

    this.setState({
      tenDayAhead,
      today,
      maxDate,
      minDate
    });

  }

    render() {
    return (
    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container calendar-pop">
        <div
          style={{ display: "flex", overflow: "scroll", maxHeight: "90vh" }}
          className="pop-us-ship-label"
        >
          <div className="pop-header">
            <div className="text-main">Schedule Delivery</div>
          </div>

          {this.props.overWeight ? (
            <div
              style={{
                borderColor: "#ff8c00",
                borderWidth: "2px",
                fontSize: "14px",
              }}
              className="tracking-box"
            >
              <div>
                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  Error scheduling for delivery.
                  <br />
                  
                </div>

                <div style={{textAlign: "left"}}>
                  This order exceeds the weight limit of 4.5 lbs/2kg 
                </div>
                <div style={{textAlign: "left"}}>
                  Your total order weight is {this.props.totalOrderWeight} lbs.          
                </div>
                <br />
                <div style={{textAlign: "left"}}>Please{" "}
                <a target="_blank" href="https://support.cashaam.com">
                  Contact Us
                </a>{" "}
                to shedule a pickup by DHL.
                </div>
              </div>
            </div>
          ) : (
          
          <>
            <div className="pop-content">
              <label className="dim-label">Pick Delivery Date</label>
              <div className="schedule info">
                <em>
                  *Delivery Date cannot be in the past or less than 24 hours from now. You cannot schedule for more than 10 days in Future.
                </em>
              </div>

              {this.state.dateError?
                <label className="error-message">
                  {this.state.dateError}
                </label>
              :null}
              <input 
                  type="date" id="start" name="trip-start"
                  onChange={this.changeDate}
                  // value={this.state.value}
                  // min={this.state.minDate} 
                  // max={this.state.maxDate}
                  className={`time-input ${
                    this.state.dateError ? "input-error" : ""
                  }`}
              />
              {/* <Calendar
                // onChange={onChange}
                onChange={changeDate}
                value={value}
                tileClassName="calendar-day"
                className="calendar-div"
              /> */}

              <div className="time-weight">
                <div style={{ width: "100%" }}>
                  <div className="schedule info">
                    <em>*Select a time between 9:00am and 2:00pm.</em>
                  </div>
                  {this.props.timeError ? (
                    <label className="error-message">{this.props.timeError}</label>
                  ) : (
                    <label>Select a time:</label>
                  )}
                  <input
                    onChange={this.props.handleTime}
                    type="time"
                    id="time"
                    name="time"
                    style={{ width: "100%" }}
                    className={`time-input ${
                      this.props.timeError ? "input-error" : ""
                    }`}
                  />
                </div>
              </div>
            </div>

            </>
          )}

          {this.props.schedulingError ? (
            <div
              style={{
                borderColor: "#ff8c00",
                borderWidth: "2px",
                fontSize: "14px",
              }}
              className="tracking-box"
            >
              <div>
                {this.props.dhlMessage?
                <div>
                <div style={{ fontWeight: "bold", }}>There has been an error with scheduling DHL pickup</div>
                      <div>DHL ERROR: {this.props.dhlMessage}</div>
                      <br />
                </div>
                    :

                <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                  Error scheduling for delivery. Please change the one or more following
                  information
                  <br />             
                  <ul
                    style={{
                      margin: "0",
                      paddingLeft: "29px",
                      fontWeight: "500",
                    }}
                  >
                    <li style={{ textAlign: "left" }}>
                      Pick a different day for DHL to pick up your
                      package
                    </li>
                    <li style={{ textAlign: "left" }}>
                      Click on "Edit Shipping Info" to fix address errors
                    </li>
                  </ul>
                </div>
                }

                {this.props.errorSchedulingDelivery === true && this.props.successfulReportSent !== true ? <>
                  
                  <div style={{ textAlign: "center" }}> If you believe the information you provided is correct, <a onClick={() => this.props.reportUnsuccessfullDHLSchedule(this.props.order)} style={{ fontWeight: "bold", color: "#6942ab", cursor: "pointer" }}>Click here</a> to report the issue to Cashaam.
                </div></> :
    
                this.props.successfulReportSent === true ?
                    <div style={{ fontWeight: "bold", textAlign: "center", color: "#6740aa", fontSize: "16px" }}>The issue has been successfully reported to Cashaam. Our team will review the order information and help you as soon as possible.</div>
                : null}

                {/* Please{" "}
                <a target="_blank" href="https://support.cashaam.com">
                  Contact Us
                </a>{" "}
                if this problem persists. */}
              </div>
            </div>
          ) : null}

          <div className="pop-footer">
            {this.props.overWeight?
              null
              :
              <>
                  {!this.props.scheduleLoading ? (
                  <div
                    onClick={this.submitDelivery}
                    style={{ cursor: "pointer" }}
                    className="btn-primary"
                  >
                    <div className="text-btn-primary">Submit</div>
                  </div>
                ) : (
                  <div style={{ cursor: "pointer" }} className="btn-primary">
                    <div className="text-btn-primary">Loading...</div>
                  </div>
                )
              }

               {this.props.schedulingError?
                (<div
                  onClick={() => this.props.onEditShipping(true)}
                  style={{ cursor: "pointer" }}
                  className="btn-primary"
                >
                  <div className="text-btn-primary">Edit Shipping Info</div>
                </div>
                )
                : null
                }       
                </> 
            }

            <div
              onClick={this.props.hideSchedule}
              style={{ cursor: "pointer" }}
              className="btn-secondary"
            >
              <div className="text-btn-tertiary">Cancel</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}
}


