import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";

export default class LandingPage extends Component {
  render() {
    return (
      <div className="section-full">
        <Link to="/" className="logo-bg-dark w-inline-block">
          {/* <img
            src={logo}
            //   srcSet="/storefront-sellers/src/images/cashaam-logo-p-500.png 500w, /storefront-sellers/src/images/cashaam-logo.png 560w"
            sizes="(max-width: 767px) 80px, 100px"
            alt
            className="cashaam-logo-dark"
          /> */}
          <img
            src={logoWhite}
            //   srcSet="/storefront-sellers/src/images/cashaam-logo-white-p-500.png 500w, /storefront-sellers/src/images/cashaam-logo-white.png 560w"
            sizes="100vw"
            alt
            className="cashaam-logo-white"
          />
        </Link>
        <div className="container-left">
          <div className="bg-img-login">
            <div className="holder-copy">
              <h1 className="h1">A better way to sell online.</h1>
              <h1 className="h1 hidden">Create Your Free Account</h1>
              <h3 className="h3 hidden">Sell on your Website, Amazon and more.</h3>
              <h3 className="h3">Sell on your Website, Amazon and more.</h3>
              <div className="copy-18">
                Sign up and get an Online&nbsp;Store. We can help you reach more
                people and expose your business to millions of customers.
              </div>
              <div className="copy-18 hidden">
                Sell more, grow your business online. Millions of customers are
                waiting! We can help you reach more people and expose your
                business for it for free.
              </div>
            </div>
          </div>
        </div>
        <div className="container-right">
          <div className="holder-option">
            <div className="options">
              <h1 className="h2">Create Your Online Store</h1>
              <div className="copy-18 black">
                Join thousands of businesses today. Showcase and sell your
                products.
              </div>
              <div className="button-holder">
                <Link
                  style={{ textDecoration: "none" }}
                  to="/seller/signup"
                  className="btn-primary"
                >
                  <div className="text-btn-primary">
                    I'm Interested! Sign me Up
                  </div>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/seller/signin"
                  className="btn-secondary"
                >
                  <div className="text-btn-secondary">
                    Already have an account? Log In
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
