import React from "react";
import firebase from "../../firebaseConfig";

const isBankSetup = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const ngBankSnap = await db
        .collection("sellersAccounts")
        .doc(userId)
        .collection("bank")
        .get();

      const usBankSnap = await db
        .collection("sellersAccounts")
        .doc(userId)
        .collection("stripe")
        .get();

      if (ngBankSnap.docs.length < 1 && usBankSnap.docs.length < 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    } catch (error) {
      console.log("error checking if bank has been set up", error);
      reject(error);
    }
  });
};
export default isBankSetup;
