import firebase from "../../firebaseConfig";

const getResources = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const result = await db.collection("trainingVideos").get();
      let resources = [];
      result.docs.map((doc) => {
        resources.push(doc.data());
      });

      resolve(resources);
    } catch (error) {
      console.log("error getting resources", error);
      reject(error);
    }
  });
};
export default getResources;
