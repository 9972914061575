import React from "react";
import MessageHolder from "./MessageHolder";
import ChatWindow from "./ChatWindow";
const CustomerChat = (props) => (
  <div data-w-tab="Customer Chat" className="w-tab-pane w--tab-active">
    {props.messages ? (
      <div className="chat-customer">
        <div className="page-title">
          <h3 className="title-chats">Customers Chats</h3>
          <div className="page-description">
            Your chat conversations between Customers &amp;&nbsp;Cashaam Bot
          </div>
        </div>
        <div className="chat-holder">
          <div className="chat-list">
            <MessageHolder />
          </div>
          <ChatWindow />
        </div>
      </div>
    ) : (
      <div className="chat-customer">
        <div className="page-title">
          <h3 className="title-chats">Customers Chats</h3>
          <div className="page-description">
            Your store's chat bot has not been activated yet.
          </div>
        </div>
      </div>
    )}
  </div>
);
export default CustomerChat;
