import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import { Link } from "react-router-dom";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";
import SelectStates from "../seller/dashboard/orders/SelectStates";

const StoreSetupForm = (props) => (
  <>
    <div className="section-onboarding">
      <div style={{height:"100%", overflow: "scroll"}} className="onboarding-steps">
        <div className="block-title-1">
          <h4 className="h4">Tell Us About Your Business</h4>
        </div>
        {/* <div className="microcopy-signup">
          To enable us to tailor your experience and your customers, describe
          more about your business. Describe yourself, your kind of business,
          your preferred currency, and language.
        </div> */}
        <div className="holding-onboarding">
          <div className="forms w-form">
            <form
              onSubmit={props.handleSubmit}
              id="email-form"
              name="email-form"
              data-name="Email Form"
            >
              <div className="holder-radio">
                <div className="input-label">What best describes you?</div>
                <div className="block-radio">
                  <div className="radio-form">
                    <label className="btn-radio w-radio">
                      <input
                        checked={props.sellerType==="fullTime"}
                        onChange={props.handleChange}
                        type="radio"
                        data-name="sellerType"
                        id="sellerType"
                        name="sellerType"
                        defaultValue="fullTime"
                        className="w-form-formradioinput w-radio-input"                     
                        
                      />
                      <span
                        htmlFor="fullTime"
                        className="input-radio w-form-label"
                      >
                        Full Time Seller
                      </span>
                    </label>
                  </div>
                  <div className="radio-form">
                    <label className="btn-radio w-radio">
                      <input
                        onChange={props.handleChange}
                        checked={props.sellerType==="partTime"}
                        type="radio"
                        data-name="sellerType"
                        id="sellerType"
                        name="sellerType"
                        defaultValue="partTime"
                        className="w-form-formradioinput w-radio-input"
                      />
                      <span
                        htmlFor="partTime"
                        className="input-radio w-form-label"
                      >
                        Part-Time Seller
                      </span>
                    </label>
                  </div>
                  <div className="radio-form">
                    <label className="btn-radio w-radio">
                      <input
                        onChange={props.handleChange}
                        checked={props.sellerType==="newSeller"}
                        type="radio"
                        data-name="sellerType"
                        id="sellerType"
                        name="sellerType"
                        defaultValue="newSeller"
                        className="w-form-formradioinput w-radio-input"
                      />
                      <span
                        htmlFor="newSeller"
                        className="input-radio w-form-label"
                      >
                        New Seller
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="holder-radio">
                <div className="input-label">What's your Gender</div>
                <div className="block-radio">
                  <div className="radio-form">
                    <label id="gender" className="btn-radio w-radio">
                      <input
                        onChange={props.handleChange}
                        type="radio"
                        data-name="sellerGender"
                        id="sellerGender"
                        name="sellerGender"
                        defaultValue="female"
                        required
                        className="w-form-formradioinput w-radio-input"
                      />
                      <span className="input-radio w-form-label">Female</span>
                    </label>
                  </div>
                  <div className="radio-form">
                    <label id="gender" className="btn-radio w-radio">
                      <input
                        onChange={props.handleChange}
                        type="radio"
                        data-name="sellerGender"
                        id="sellerGender"
                        name="sellerGender"
                        defaultValue="male"
                        required
                        className="w-form-formradioinput w-radio-input"
                      />
                      <span className="input-radio w-form-label">Male</span>
                    </label>
                  </div>
                  <div className="radio-form">
                    <label id="gender" className="btn-radio w-radio">
                      <input
                        onChange={props.handleChange}
                        type="radio"
                        data-name="sellerGender"
                        id="sellerGender"
                        name="sellerGender"
                        defaultValue="N/A"
                        required
                        className="w-form-formradioinput w-radio-input"
                      />
                      <span className="input-radio w-form-label">
                        I rather not mention
                      </span>
                    </label>
                  </div>
                </div>
              </div> */}

              {/* <PlacesAutocomplete
                value={props.address || ""}
                onChange={props.setAddress}
                onSelect={props.handleSelectAddress}
                shouldFetchSuggestions={props.shouldFetchSuggestions}
                onError={props.onError}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                  {props.addressError? <div style={{ color: "red" }}>{props.addressError}</div> : null}
                    <input
                      value={props.address}
                      {...getInputProps({
                        placeholder: "",
                      })}
                      data-name="address"
                      maxLength="5000"
                      id="address"
                      name="address"
                      placeholder="Your Business/Home Address "
                      //required="required"
                      className={props.addressError? "input-text w-input input-glow input-error" : "input-text w-input input-glow"}
                      
                    ></input>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        minWidth: "100%",
                        // borderLeft: "2px solid rgb(219, 219, 219)",
                        // borderRight: "2px solid rgb(219, 219, 219)",
                        boxShadow: "0px 0px 2px",
                      }}
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion, i) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#d0bdf0"
                            : "white",
                          borderBottom: "2px solid rgb(219, 219, 219)",
                          borderTop:
                            i > 0 ? "0.5px solid rgb(219, 219, 219)" : null,
                          cursor: "pointer",
                          padding: "5px",
                          // boxShadow: "0px 0px 2px",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                            key={i}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </PlacesAutocomplete> */}

<div className="address-elements">
               {props.countryError ?
                <div style={{ color: "red" }}>{props.countryError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">
                  Country
                </label>
                }
                {/* <input
                  onChange={props.handleChange}
                  value={props.country}
                  type="text"
                  className={`input-text w-input half-width-inputs ${props.countryError?"input-error":""}`}
                  maxLength={256}
                  name="country"
                  data-name="country"
                  placeholder="Enter your country"
                  id="country"
                /> */}
                <div
                  className={`input-text w-input half-width-inputs ${props.countryError?"input-error":""}`}
                  
                >
                  {props.country}
                </div>
              </div>


              {props.country?.toLowerCase()=="nigeria"?

              <div className="address-elements">
                {props.addressError ?
                <div style={{ color: "red" }}>{props.addressError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">
                  Street
                </label>
                }
                <input
                  onChange={props.handleChange}
                  value={props.street}
                  type="text"
                  className={`input-text w-input half-width-inputs ${props.addressError?"input-error":""}`}
                  maxLength={256}
                  name="street"
                  data-name="street"
                  placeholder="Enter your street"
                  id="street"
                />
              </div>

              :
              <>
              {props.addressError ?
                <div style={{ color: "red" }}>{props.addressError}</div>
                : 
                <label htmlFor="productWeight" className="label-2x">Address</label>
              }
              
              <PlacesAutocomplete
                value={props.address || ""}
                onChange={props.setAddress}
                onSelect={props.handleSelectAddress}
                shouldFetchSuggestions={props.shouldFetchSuggestions}
                onError={props.onError}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    
                    <input
                      value={props.address}
                      {...getInputProps({
                        placeholder: "",
                      })}
                      data-name="address"
                      maxLength="5000"
                      id="address"
                      name="address"
                      placeholder="Your Business/Home Address "
                      //required="required"
                      className={
                        props.addressError
                          ? "input-text w-input input-glow input-error"
                          : "input-text w-input input-glow"
                      }
                    ></input>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99,
                        minWidth: "100%",
                        // borderLeft: "2px solid rgb(219, 219, 219)",
                        // borderRight: "2px solid rgb(219, 219, 219)",
                        boxShadow: "0px 0px 2px",
                      }}
                    >
                      {loading ? <div>...loading</div> : null}

                      {suggestions.map((suggestion, i) => {
                        const style = {
                          backgroundColor: suggestion.active
                            ? "#d0bdf0"
                            : "white",
                          borderBottom: "2px solid rgb(219, 219, 219)",
                          borderTop:
                            i > 0 ? "0.5px solid rgb(219, 219, 219)" : null,
                          cursor: "pointer",
                          padding: "5px",
                          // boxShadow: "0px 0px 2px",
                        };

                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              style,
                            })}
                            key={i}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </PlacesAutocomplete>
              </>
              }

              <div className="half-width">
                  {props.country?.toLowerCase()=="nigeria"?
                     <div className="address-element">
                        {props.stateError ?
                            <div style={{ color: "red" }}>{props.stateError}</div>
                            : 
                            <label>State</label>
                        }
                            <SelectStates 
                              handleDeliveryChange={props.handleChange}
                              state={props.state}
                              name={"state"}
                              stateError={props.stateError}
                            />
                          </div>
                        :
                      <div className="address-element">
                        {props.stateError ?
                            <div style={{ color: "red" }}>{props.stateError}</div>
                            : 
                        <label htmlFor="productWeight" className="label-2x">
                          State
                        </label>
                        }
                        <input
                          onChange={props.handleChange}
                          value={props.state}
                          type="text"
                          className={`input-text w-input half-width-inputs ${props.stateError?"input-error":""}`}
                          maxLength={256}
                          name="state"
                          data-name="state"
                          placeholder="Enter your state"
                          id="state"
                        />
                      </div>
                  }



                {props.country?.toLowerCase()=="nigeria" && props.stateLGA?
                  <div className="address-element">
                    {props.cityError ?
                      <div style={{ color: "red" }}>{props.cityError}</div>
                      : 
                      <label>City</label>
                    }
                        <select
                            value={props.city}
                            id="buyerCity"
                            className={`mc-input input-text w-input ${props.cityError?"input-error":""}`}
                            placeholder="City" name="city"
                            onChange={props.handleChange}
                            >
                                {props.stateLGA.map((lga, index) => {
                                return <option value={lga} name="state"> {lga}</option>
                                })}
                        </select>
                      </div>
                      :

                    <div className="address-element">
                      {props.cityError ?
                        <div style={{ color: "red" }}>{props.cityError}</div>
                        : 
                        <label htmlFor="productWeight" className="label-2x">
                          City
                        </label>
                        }
                        <input
                          onChange={props.handleChange}
                          value={props.city}
                          type="text"
                          className={`mc-input input-text w-input ${props.cityError?"input-error":""}`}
                          maxLength={256}
                          name="city"
                          data-name="city"
                          placeholder="Enter your city"
                          id="city"
                        />
                    </div>
                  }
              </div>

              <div className="half-width">
              {/* {props.country?.toLowerCase()!="nigeria"? */}
                <div className="address-element">
                  <label htmlFor="productWeight" className="label-2x">
                    Zipcode
                  </label>
                  <input
                    onChange={props.handleChange}
                    value={props.zipcode}
                    type="text"
                    className="input-text w-input half-width-inputs"
                    maxLength={256}
                    name="zipcode"
                    data-name="zipcode"
                    placeholder="Enter your zipcode (optional)"
                    id="zipcode"
                  />
                </div>
                {/* :null} */}
                <div className="address-element">
                  <label htmlFor="productWeight" className="label-2x">
                    Apt/Floor
                  </label>
                  <input
                    onChange={props.handleChange}
                    value={props.apt}
                    type="text"
                    className="input-text w-input half-width-inputs"
                    maxLength={256}
                    name="apt"
                    data-name="apt"
                    placeholder="Enter your Apt/Floor"
                    id="apt"
                  />
                </div>
              </div>


              {/* <select
                id="Country"
                name="Country"
                data-name="Country"
                required
                className="input-text w-select"
              >
                <option value>Choose your Country</option>
                <option value="Nigeria">Nigeria</option>
                <option value="United States">United States</option>
              </select> */}
              <select
                id="shopCurrency"
                name="shopCurrency"
                data-name="shopCurrency"
                required
                className="input-text w-select"
              >
                {props.location.toLowerCase() !== "nigeria" ? (
                  <option value="USD">USD</option>
                ) : (
                  <option value="NGN">NGN</option>
                )}
              </select>
              {props.categoryError? <div style={{ color: "red" }}>{props.categoryError}</div> : null}
              <StatefulMultiSelect
                className="input-text w-select"
                options={props.options}
                selected={props.selected}

                onSelectedChanged={(selected) =>
                  props.selectCategory({ selected })
                }
                overrideStrings={{
                  selectSomeItems: "Choose Your Store Category",
                  allItemsAreSelected: "All Items are Selected",
                  selectAll: "",
                  search: "Search",
                }}
              />

                <div className="half-width">
                  <div className="address-element">
                    {props.dobError?
                    <div style={{ color: "red",marginBottom: "5px"}}>{props.dobError}</div>
                    :
                    <label for="step1">Date of Birth<em>(13yrs or older)</em></label> 
                    }
                      <input
                      value={props.birthday}
                      onChange={props.handleChange}
                      type="date"
                      className={`input-text w-input half-width-inputs`}
                      maxLength={256}
                      name="birthday"
                      placeholder="Enter Your Date of Birth"
                      id="updateProfileFirstName"
                      max="2010-04-30"
                      />
                  </div>
                  <div className="address-element">
                    <label htmlFor="productWeight" className="label-2x">Instagram handle (optional)</label>
                    <input
                      onChange={props.handleChange}
                      value={props.socialInstagram}
                      type="text"
                      className="input-text w-input"
                      maxLength={256}
                      name="socialInstagram"
                      data-name="socialInstagram"
                      placeholder="Your Instagram URL (optional)"
                      id="socialInstagram"
                    />
                  </div>
                </div>
              {/* <input
                onChange={props.handleChange}
                value={props.socialFacebook}
                type="text"
                className="input-text w-input"
                maxLength={256}
                name="socialFacebook"
                data-name="socialFacebook"
                placeholder="Your Facebook URL (optional)"
                id="socialFacebook"
              /> */}
              <input
                type="submit"
                defaultValue="Next"
                data-wait="Please wait..."
                className="submit-primary w-button"
              />
            </form>
            
            <div
              onClick={() => props.goBackToAboutStoreForm()}
              style={{ cursor: "pointer" }}
              className="microcopy-signup go-back"
            >
              Go Back
            </div>

            {/* <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div> */}
            {/*props.invalidAddress !== false ? (
              <div style={{ display: "flex" }} className="w-form-fail">
                <div>Invalid address, Please check and try again.</div>
              </div>
            ) : null*/}
          </div>
        </div>
      </div>
    </div>
    <Link to="/" className="logo-black w-inline-block">
      <img
        src={logo}
        // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
        sizes="(max-width: 767px) 80px, 100px"
        alt
        className="cashaam-logo-dark"
      />
      <img
        src={logoWhite}
        // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
        sizes="100vw"
        alt
        className="cashaam-logo-white"
      />
    </Link>
  </>
);
export default StoreSetupForm;