import React from 'react'
import StatefulMultiSelect from "@khanacademy/react-multi-select";

import priceFormater from "../../../helpers/priceFormater";

import styles from './ServiceForm.module.css'

const ServiceForm = (props) => {
    return (
        <div className="form-holder">
            <div style={{ minWidth: "60%" }} className="product-steps">
                <div className="block-title-2">
                    <h4 className="page-title">{props.isEditing ? "Update " : ""}Service Information</h4>
                </div>
                {
                    !props.isEditing &&
                    <div className="microcopy-signup">
                        1. <span className="highlight">Service Name</span> - A good name attracts customers.
                        <br />‍<br />
                        2. <span className="highlight">Service Description</span> - Craft a
                        compelling service description that tells shoppers about the item
                        and how it was created.
                    </div>
                }
                <div className="product-builder" />
                <div className="holding-onboarding">
                    <div className="forms w-form">
                        <form
                            id="email-form"
                            name="email-form"
                            data-name="Email Form"
                            className="update-form"
                            onSubmit={props.submitForm}
                        >
                            <div style={{ display: "flex" }}>
                                {
                                    props.previewImageUrls.map((url, i) => {
                                        return (
                                            <div className={styles.imageLabel} key={i}>
                                                <span style={{ position: "absolute", color: "red", cursor: "pointer" }} onClick={() => props.onDeleteImage(i)}>
                                                    <i class="fas fa-trash-alt fa-2x"></i>
                                                </span>
                                                <img style={{ height: "100%", width: "100%" }} src={url} alt="picture" />
                                            </div>
                                        )
                                    })
                                }
                                {
                                    props.previewImageUrls.length < 4 &&
                                    <label for="image-upload" className={styles.imageLabel}>
                                        <i class="fas fa-plus-circle"></i>
                                        <span>Add Image</span>
                                    </label>
                                }
                                <input
                                    style={{ display: "none" }}
                                    id="image-upload"
                                    type="file"
                                    onChange={props.onImageUpload}
                                    onClick={(e) => { e.target.value = '' }}
                                />
                            </div>
                            <input
                                value={props.serviceName}
                                onChange={props.handleChange}
                                type="text"
                                className="input-text w-input"
                                minLength={1}
                                maxLength={256}
                                name="serviceName"
                                data-name="serviceName"
                                placeholder="Enter Service Name"
                                id="serviceName"
                                required
                            />
                            <textarea
                                value={props.serviceDescription}
                                onChange={props.handleChange}
                                placeholder="Enter Service Description"
                                data-name="serviceDescription"
                                minLength={2}
                                maxLength={250}
                                id="serviceDescription"
                                name="serviceDescription"
                                required
                                className="input-text w-input"
                                defaultValue={""}
                            />

                            <div style={{ display: "block" }} className="meta-additions">
                                <div
                                    style={{ textAlign: "left", color: "#6740aa" }}
                                    className="meta-text-limits"
                                >
                                    {priceFormater(props.servicePrice, props.currency)}
                                </div>
                            </div>
                            <input
                                value={props.servicePrice}
                                onChange={props.handleChange}
                                type="number"
                                className="input-text input-half w-input"
                                min={0.01}
                                step={0.01}
                                name="servicePrice"
                                data-name="servicePrice"
                                placeholder="Enter Price (No Commas Allowed)"
                                id="servicePrice"
                                required
                            />
                            <input
                                value={props.serviceDuration}
                                onChange={props.handleChange}
                                type="number"
                                className="input-text input-half w-input"
                                name="serviceDuration"
                                data-name="serviceDuration"
                                placeholder="Enter Duration in minutes"
                                id="serviceDuration"
                                required
                            />
                            <input
                                value={props.location}
                                onChange={props.handleChange}
                                type="text"
                                className="input-text w-input"
                                minLength={1}
                                maxLength={256}
                                name="location"
                                placeholder="Enter Location: address, zoom link, etc"
                                id="location"
                                required
                            />

                            <input
                                value={props.email}
                                onChange={props.handleChange}
                                type="email"
                                className="input-text input-half w-input"
                                min={0.01}
                                step={0.01}
                                name="email"
                                data-name="email"
                                placeholder="Enter Email"
                                id="email"
                                required
                            />
                            <input
                                value={props.phoneNumber}
                                onChange={props.handleChange}
                                type="text"
                                className="input-text input-half w-input"
                                name="phoneNumber"
                                data-name="phoneNumber"
                                placeholder="Enter Phone Number"
                                id="phoneNumber"
                            />
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }} className={styles.tooltip}>
                                <label htmlFor="addGuestEnabled" style={{ fontSize: 13, fontWeight: "inherit", margin: "0px 10px 0px 0px" }}>
                                    Enable Add Guest
                                    <span style={{color: "#7e55bc"}}> <i class="far fa-question-circle"></i> </span>
                                </label>
                                <span className={styles.tooltiptext}>Allow customers to include a guest when booking this service</span>
                                <input
                                    checked={props.addGuestEnabled}
                                    onChange={props.handleCheckboxChange}
                                    type="checkbox"
                                    id="addGuestEnabled"
                                />
                            </div>

                            <StatefulMultiSelect
                                className="input-text w-select"
                                options={props.options}
                                selected={props.selected}
                                onSelectedChanged={(selected) =>
                                    props.selectCategory({ selected })
                                }
                                overrideStrings={{
                                    selectSomeItems: "Select Categories (MAX 2)",
                                    allItemsAreSelected: "All Items are Selected",
                                    selectAll: "",
                                    search: "Search",
                                }}
                            />
                            <button
                                type="submit"
                                className="submit-primary w-button"
                            >
                                {props.isEditing ? "Update Service" : "Add Service"}
                            </button>
                            {
                                props.isEditing &&
                                <button
                                    onClick={props.cancelEditing}
                                    className="btn-secondary w-button"
                                    style={{ marginTop: 10 }}>
                                    <span className="text-btn-secondary">
                                        Cancel
                                    </span>
                                </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceForm
