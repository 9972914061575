 const API_KEY = "AIzaSyDZgQN1qtFXWZCtQnJvMR0ScQpLFjxu_Ic";
export const STRIPE_CLIENT_ID = "ca_GuE7ubTbwiD8P8qEEPLbs9xQV2eA7aJv";
export const SHIPO_KEY = "shippo_test_d7ea9895ab11c2c33aa09661fd96d14abd68312c";
export const FB_APP_ID = "152392722810117";
export const USER_SIGNUP_API_URL =
  "https://usersignup-skbrnd7esa-uc.a.run.app";
export const UPDATE_USER_CREDS_API_URL = "https://update-user-creds-skbrnd7esa-uc.a.run.app/update-creds";
export const SELLER_DELIVERY_TYPE_URL =
  "https://gigl-location-skbrnd7esa-uc.a.run.app/pickuplocation-gigl";
export const BUYER_DELIVERY_TYPE_URL =
  "https://gigl-location-skbrnd7esa-uc.a.run.app/homedeliverylocation-gigl";
export const NG_REQ_PICKUP_DELIVERY_URL =
  "https://request-pickupdelivery-skbrnd7esa-uc.a.run.app/request-pickupdelivery";
export const CREATE_STRIPE_USER = "https://us-central1-cashaam-dev.cloudfunctions.net/createStripeCustomer-createStripeCustomer";
export const VARIANT_COMBO_URL = "https://variant-skbrnd7esa-uc.a.run.app/get-combinations";
export const BVN_API = "https://bvncheck-skbrnd7esa-uc.a.run.app";

export const FLUTTERWAVE_KEY="FLWPUBK_TEST-7fc74b610f54511b3b84ec121a1e1b6b-X";
export const stripeCheckout="https://checkout-stripe-subscription-skbrnd7esa-uc.a.run.app/create-checkout-session";
export const stripeTopUp="https://checkout-stripe-subscription-skbrnd7esa-uc.a.run.app/create-topup-session";

export const topUpProductId="prod_Np84IDzwX1tJZk";

export const flutterwaveMonthly="20977";
export const flutterwaveQuarterly="20979";
export const flutterwaveBiAnnually="20980";
export const flutterwaveYearly="20981";

export const paystackMonthly="PLN_4ddu1r9ac40gwdf";
export const paystackQuarterly="PLN_qqphqwfxd3keke3";
export const paystackBiAnnually="PLN_ky2okcc523818mu";
export const paystackYearly="PLN_yhiv6p5dry04rip";
export const paystackLite="PLN_eizb2pn0t6ruerp";

export const stripeMonthly="price_1L8mUEIaJe7EYHRMRGba4HNZ";
export const stripeQuarterly="price_1L8mSYIaJe7EYHRMh0C7N2Aq";
export const stripeBiAnnually="price_1L8mThIaJe7EYHRMcUvRGCzd";
export const stripeYearly="price_1L8mV3IaJe7EYHRMdJ0NeuP6";
export const cardApplicationFee="price_1NRgtHIaJe7EYHRM352ggQQu";

export const PAYPAL_TRANSFER="https://rewards-skbrnd7esa-uc.a.run.app/paypal-cashout";
export const FLUTTERWAVE_TRANSFER="https://rewards-skbrnd7esa-uc.a.run.app/flw-cashout";
export const SCHEDULE_DELIVERY="https://dhl-shipping-skbrnd7esa-uc.a.run.app/schedule-pickup";

export const PAYSTACK_KEY = "pk_test_de0b87d37fe8234d96c5b7d8ad29eeddb974ac81";
export const PAYSTACK_SUBACCOUNT="ACCT_lvujc0vqhqku08n";
export const VirtualCardAPI ="https://ads-credit-skbrnd7esa-uc.a.run.app";

export const VERIFY_ACCOUNT="https://api.paystack.co/bank/resolve?account_number";
export const PAYSTACK_SECRET_KEY="sk_test_be800ca4c0a13d5d27744c3664ec32f7d87ae7e3";
export const FREE_TRIAL="https://create-subscription-skbrnd7esa-uc.a.run.app/create-subscription";
export const CANCEL_SUBSCRIPTION="https://create-subscription-skbrnd7esa-uc.a.run.app/cancel-subscription";
export const free_planId="PLN_h9m61xno6o6cslt";

export const STORE_STATS="https://us-central1-cashaam-dev.cloudfunctions.net/sellerquery-querydb";
export const STORE_ANALYTICS="https://analytics-skbrnd7esa-uc.a.run.app/store-analytics";

export const STRIPE_VERIFICATION="https://verification-skbrnd7esa-uc.a.run.app";
export const STRIPE_KEY="pk_test_Jj5npyFchVVXSINr3LRbpz8n00fGyc9BqO";
export const NAIRA_EXCHANGE=750;

export const VERTEX_DEFAULT=`Return the response in Markdown format which will be used in a website. Shorten the response to fit the 1024 token limit.
For [INPUT]: `;

export const VERTEX_PROMPT=`All text should be in English. 

I want you to act as a marketing specialist and ad expert to create a comprehensive ad campaign for the provided product name, objective, placement platform, and target location. Please follow the instructions below:
Input the product name, placement platform, objective, and location country provided by the user.
Then Analyze the Facebook ad libraries to identify the best-performing ad sets for the target audience based on factors such as ad type, format, placement, and copy.
Use natural language processing to identify the key characteristics of the target audience, including age, gender, location, interests, and location.
Provide Age Limit sets, Gender Sets, location, Interests, Specific Time Period ads should run or not run on (if applicable), and Locations cities-wise to create the target audience for the ad campaign.
Use search engine data to identify people in the location country who have recently searched for the product name. This will form one of the target audience sets for the ad campaign.
Use competitor website data to identify people in the location country who have recently visited websites of competitors of the product name. This will form another target audience set for the ad campaign.
Use e-commerce website data to identify people in the location country who have recently made a purchase on an e-commerce website related to the product name. This will form another target audience set for the ad campaign.
Use online forums or community data to identify people in the location country who have shown interest in similar products to the product name. This will form another target audience set for the ad campaign.
Incorporate behavioral data and psychographic information to enhance audience targeting, considering factors like lifestyle, values, attitudes, and personality traits.
Analyze social media data to identify key influencers and trendsetters within the target location, who can be approached for promotional activities or partnerships.
For each target audience set, consider creating personalized ad content and messaging to resonate more effectively with their preferences and needs, improving the chances of engagement and conversion.
Perform A/B testing on different ad variations, including headlines, visuals, and calls-to-action, to optimize ad performance and gain insights on what resonates best with each target audience set.
Consider incorporating retargeting strategies to re-engage potential customers who have shown interest but have not yet converted, using dynamic ads and personalized messaging.
Recommend two daily ad budgets and durations, one with the name "For Small Business" (should be equal to or lower than 10$(provided in local currencies)) and the other with the name "For Brands," based on historical ad performance data, target audience characteristics, and campaign goals.
Use historical performance data and machine learning algorithms to estimate the optimal ad duration for the campaign to achieve the desired results.
Use machine learning algorithms to recommend the best time period for running the ads based on target audience behavior and preferences. This can include factors such as days of the week, times of day, and time zones.
Provide the three best performing ad sets. For the ad set, specify the ad placement platform (based on user input)
Consider utilizing programmatic advertising and real-time bidding to optimize ad placements and reach the right audience segments more efficiently.
For the ad set, also include ad formats (e.g., image, video, carousel, story, collection) best suited for the respective ad placement platform and target audience preferences.
Monitor ad performance and engagement metrics regularly, adjusting campaign strategies and ad sets as needed to improve overall results.
Output the three best performing ad sets with their respective target audiences and ad placement platforms, along with recommendations for the target audience, daily ad budgets with two labels; First Label "For Small Business:" (should equal to or lower than 20$(provide in local currencies)) and with Second Label "For Brands:" (i.e high budgets, etc), optimal ad duration, and time period for running the ads.
Must provide Ads Budget in the local currency according to the provided area, location, or country. 
Remember to include tracking and conversion pixels in the ad campaign to measure performance and ROI accurately.

Please Immediately After this, now I want you to act as a professional copywriter with experience in writing high-converting Facebook ads. The ad copy should be written in fluent English and should be between 100-150 words long. I want you to write a Facebook ad copy for a product/service that I will provide as the following "[INPUT]", using the following guidelines:
-Create a compelling headline that grabs attention and highlights the main benefit of the product/service
-Use clear and concise language in the body copy that focuses on the benefits  of the product/service and addresses any potential objections based on the objective of the ads
-Include a strong call to action that encourages users to take the desired action based on their objective
-Use an image or video that visually demonstrates the product/service and resonates with the target audience
-Research the target audience demographics, such as age, gender, location, interests, and other characteristics that would help you to have a better understanding of the target audience, and create an ad that would be more appealing to them.

Please ensure that the ad sets are precise and comprehensive, with a focus on detailed aspects. Do not echo the prompts or provide unnecessary information. Stick to the main points and provide data in a complete format. Don't provide any extra text or sentences other than Ad sets.
I am also feeding you with one ideal output example for you better learning for output design
Note: This is just an example of output for your understanding, this information is a dummy data that doesn't have anything to do with user input or result, keep that in mind.

Output Example:
**Use Any of These Target Audiences for your [user selected Platform name for ad placement] Ads Campaign**
 
Target Audience 1:
Age: 25-45
Gender: All
Location: Lagos, Abuja
Interests: Clothings, Shoes, bags, accessories
Ad Format: Image, Carousel, Story
Ad Budget (For Small Business): NGN2,000 per day
Ad Budget (For Brands): NGN 5,000 per day
Optimal Ad Duration: 1 week
Recommended Time Period: Thursdays to Sundays

Target Audience 2:
Age: 18-34
Gender: All
Location: Lagos, Abuja
Interests: Sunglasses, Watches, Handbags
Ad Format: Image, Video, Story, Carousel
Ad Budget (For Small Business): NGN 2500 per day
Ad Budget (For Brands): NGN 7,000 per day
Optimal Ad Duration: 2 weeks
Recommended Time Period: Weekdays


Target Audience 3:
Age: 25-55
Gender: All
Location: Lagos, Abuja
Interests: Food delivery apps, Online shopping, Pakistani cuisine, Desserts
Ad Format: Image, Video, Carousel
Ad Budget (For Small Business): NGN 1500 per day
Ad Budget (For Brands): NGN 10,000 per day
Optimal Ad Duration: 4 weeks
Recommended Time Period: Weekends Only (Saturday, Sunday)

Ad Copy:
Attention all Lahore food lovers! Indulge in the heavenly taste of Gajar Halwa, freshly made with love and the finest ingredients. Our traditional recipe has been passed down through generations, bringing you the true essence of Pakistani cuisine.
Satisfy your dessert cravings and experience the rich, authentic flavors of Lahore with every bite. Our Gajar Halwa is the perfect combination of sweetness and texture, guaranteed to leave you wanting more.
Don't miss out on this delectable treat. Visit us today and taste the goodness of our Gajar Halwa. Order now and get a complimentary serving of our special homemade ice cream.
Hurry, limited-time offer! Visit us at [location] or order online. Don't let your taste buds miss out on this delightful experience.

Call to Action: Order Now

Image: The image should be a high-quality picture of the Gajar Halwa, showcasing its cultural motif and high-quality ingredients.
Note: again reminder this is only sample data just to make you understand better about the output template.

Use real-time data according to user input and following the given output pattern in the output.

Note: Don't mix dummy data with user input and output.

Note: Use Any of These Target Audiences for your Ads Campaign should be bolded
and also give it a good amount of space

Note: Ad copy should also come next after Target Audience 3 including image and call to action 

Important Note: at the end of the response must provide the label " This Target Audience is generated by the help of Cashaam Ads AI.”

Also note that user objectives must be any of the following  (awareness, Sales, engagement, or retargeting). Platform name for ad placement must be any of [Facebook Ads, Instagram Ads, Facebook & Instagram Ads.]
For [INPUT]: `;
export const API_ENDPOINT = "us-central1-aiplatform.googleapis.com";
export const PROJECT_ID = "cashaam-dev";
export const MODEL_ID = "text-bison@001";
export const REGION = "us-central1";

export const VERTEX_TEMPERATURE = 0.6;
export const VERTEX_MAX_TOKENS= 1024;
export const VERTEX_TOP_P=0.8;
export const VERTEX_TOP_K=1;

export const client_email="firebase-adminsdk-wvlo9@cashaam-dev.iam.gserviceaccount.com";
export const private_key="-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCwgdbCy7TMrLtm\niq5Nt/xiK2Bv3J4cFZbvKKdxmJRgh26DMSi0QmVmbWbX6bRA7BACAXWyTZRizmXn\nkJpkbgjXGG6udyobUcNB17dZaMUNm62NoNeEzKE2BGzUYjvrOr/gMOUjrf1DIhNe\nQkfnFMCKUS5s1ZqiDXN08qjYNrhygvS0rHaqOSILN3IFyfXCqGDH0NDx5KWdf/GY\nUzhpK7CFyAJJwRshgSULp231OGPIhZOeeKxH+hvpfSPmDPvWKa3GKfKue3qr1Id1\nUOGc8e7CnzF8Ch7z5YMJdt3K0bRpGlFnB/DlR+6bonOqYj5GTYLxf0ceSD4xfmV+\nAciUUzRvAgMBAAECggEABIrK0od4dx3o0qo18xUNfx87o5VLUlCx6iSjLnatNtC1\nZNXihMUCpACjuWGvvE322X5dH4IElAhYsf1UI8p7GsHrszyozjwr6Kla9AeQrpl3\nfWIKucWkTmYNwOdoQFTqNCQ9+U2Ua2JYmj/dZtJvY//T0IQsrwfmlTdbZfj+hR/T\njdPohzzOVIAyDt8Y9OVrUsE0PJ5Vv0L5nuAXmdTER+WuNKrxG8mvLzkMJNlmKPDp\n5sXSJcWgvpyoLyEr7U/MEwpFhlQfBMdh6dBTWEzH6lvgUs/rl0DordFdFJhPRa4n\n/1Q94jTCKz02Dg3jZB3DcY4ezRXSc2lY462o8tEPyQKBgQDz/6lnB2jp4HNYq5M2\ntNoMKNLb0pSagTjx4w9AhrOQTlSNuHijm0BD747Bc7sxPxy4dAPYnfO7Fmn3pme/\nlTcm1puqumIrw9lfu5yLBOBvqd8BvNVKLSOh/AAis5nzDtt0VkyYb12AnmH7tkGy\nqCJPDC/BD7tWL2lqCmDBQw5VeQKBgQC5MFma9d6hKWVe8Iq226gycOsi6j/5QpJO\nb49WY0lHz1OQJB78O62kyS6NZ1+/gF3f/rS+eY+Y4DhRPiZAWstOQ/n4BNmT/Mq0\nLj7m2taky9KterXe2CND1foMOw7KiZ21JVbm2dms6HlsJjpIX2Dtt/2Tr+NsVjTQ\nL8UPcSTnJwKBgGVN/r2iG/MOl5V3FxYdnHJz+KgAFGbZxBTZ2xlFVARUIVL+BkiX\nSuVp7vFu5Yea1ofW00n6upkwwyppUqX+g/mCHPuTivL0y6mtsV8e1ThHZSnJhxzX\nBPZaNIwz2XVK896nfgHEKyQdnjzt/PT6TdRYEPOtXnB4GuJGEMns12SxAoGABC6b\nV2yGAo20xFgaSdbcDYePR9KSb0aZlzvduM21MfH0qSSnrnqKiVYhvv++ocx8Ujqm\nSkbkf0dQ/kSxCHMEdFgEpR027Kigy0DgGsaGYmJX96L1yHqJEVcbtnr+8oP5E8Ys\nGDqDN+xoEZErb8b3oWauS0+Fi0THHIx3nsmnU7ECgYEAu0rA9Dud2rjvD0Ix0ryV\ngGwPiPXkzLvrIJn6BeQ6AJTCocDOXHBvenYPa7BxdI03pA4BYcveXri1BgKg2Q1Q\nvLumYiSipm61UAAwTxl5mJT3UneJ/TFXjcLe9OBwI3ym2TmzOYlXm3a/g0YERC27\nCRjhIjAsgwMv5wj2IfpEL98=\n-----END PRIVATE KEY-----\n";

export const TRANSFERWISE_KEY="841c3f52-1403-475a-b8a9-793263b3de39";

export default API_KEY;
