import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import Metatags from "react-meta-tags";
// import star from "../../../../images/star_.svg";
import Loading from "../../../common/Loading";
import getSellerOrders from "../../../../components/helpers/getSellerOrders";
import getCustomerInfo from "../../../../components/helpers/getCustomerInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getUnreadChat from "../../../helpers/getUnreadChat";
import getBasicInfo from "../../../helpers/getBasicInfo";
import getUserLocation from "../../../helpers/getUserLocation";
import BottomNav from "../../../common/BottomNav";
import RightNav from "../../../common/RightNav";
import Customer from "./Customer";
import Subscriber from "./Subscriber";
const star = null;
export default class Customers extends Component {
  state = {
    sellerId: "",
    sellerName: "",
    slug: "",
    customers: [],
    customerOrderQuantities: {},
    pendingCustomers: [],
    pendingCustomerOrderQuantities: {},
    viewAllCustomers: true,
    viewEmailSubscribers: false,
    viewAbandonedCheckouts: false,
    subscribers: [],
    customerTotalOrderPrice: {},
    customerInfo: {},
    pendingCustomerTotalOrderPrice: {},
    pendingCustomerInfo: {},
    customerEmailToSubscribeStatus: {}
  };

  loadCustomerIds = (orders) => {
    return new Promise((resolve, reject) => {
      let customersIds = [];
      let customerOrderQuantities = {};
      let customerTotalOrderPrice = {};
      let customerInfo = {};
      // resolve(
      orders.forEach((order) => {
        console.log(`${order.orderStatus} order`, order);
        let price = order.discountedPrice ? parseFloat(order.discountedPrice) : parseFloat(order.productPrice);

        //get the total amount of orders each customer has made for this specific seller
        if (customerOrderQuantities[order.customerId]) {
          customerOrderQuantities[order.customerId] += parseInt(
            order.orderQuantity
          );
          customerTotalOrderPrice[order.customerId] += price;
        } else {
          customerOrderQuantities[order.customerId] = parseInt(
            order.orderQuantity
          );
          customerTotalOrderPrice[order.customerId] = price;

        }

        //get every customer that has ordered form this specific seller
        //avoid duplicate customers with the below if statement
        if (!customersIds.includes(order.customerId)) {
          customersIds.push(order.customerId);
          let { firstName, lastName, email, phoneNumber, city, state, location, customerName, currency } = order;
          customerInfo[order.customerId] = { firstName, lastName, email, phoneNumber, city, state, location, customerName, currency };
        }
      });
      // );
      resolve({ customersIds, customerOrderQuantities, customerTotalOrderPrice, customerInfo });
    });
  };

  getCustomers = async () => {
    Loading(true, "Loading...");
    const { sellerId, slug } = this.state;
    // const body = { query_field: "slug", query_value: slug };

    if (!slug || typeof slug === "undefined" || slug === null) {
      Loading(false);
      return;
    }
    const result = await getSellerOrders(sellerId, slug);
    const { orders, pendingOrders } = result;

    const customerResult = await this.loadCustomerIds(orders);
    const pendingCustomerResult = await this.loadCustomerIds(pendingOrders);

    const { customersIds, customerOrderQuantities, customerTotalOrderPrice, customerInfo } = customerResult;
    const pendingCustomersIds = pendingCustomerResult.customersIds;
    const pendingCustomerOrderQuantities =
      pendingCustomerResult.customerOrderQuantities;
    const pendingCustomerTotalOrderPrice = pendingCustomerResult.customerTotalOrderPrice;
    const pendingCustomerInfo = pendingCustomerResult.customerInfo;

    this.setState({ customerOrderQuantities, customerTotalOrderPrice, customerInfo, pendingCustomerOrderQuantities, pendingCustomerTotalOrderPrice, pendingCustomerInfo });

    let pendingCustomers = [];
    let customers = [];

    //turn bellow into a function
    customersIds.forEach(async (id) => {

      const customer = await getCustomerInfo(id);

      if (customer !== null) {
        Loading(false);
        console.log(customer);
        customers.push(customer);
        this.setState({ customers: customers });
      }
      Loading(false);
    });

    pendingCustomersIds.forEach(async (id) => {

      const pendingCustomer = await getCustomerInfo(id);

      if (pendingCustomer !== null) {
        Loading(false);

        pendingCustomers.push(pendingCustomer);
        this.setState({ pendingCustomers: pendingCustomers });
      }
      Loading(false);
    });
    Loading(false);
  };

  fetchSellerInfo = (id) =>
    new Promise((resolve, reject) => {
      Loading(true, "Loading...");
      //  (id) =>{
      const db = firebase.firestore();
      resolve(
        db
          .collection("sellersAccounts")
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists && doc.data().slug) {
              this.setState({
                sellerId: id,
                slug: await doc.data().slug,
              });
            }
          })
      );
    });

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    }
    {
      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");

      }
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  displayAllCustomers = () => {
    this.setState({
      viewAllCustomers: true,
      viewEmailSubscribers: false,
      viewAbandonedCheckouts: false
    })
  }
  displayEmailsubscribers = () => {
    this.setState({
      viewEmailSubscribers: true,
      viewAllCustomers: false,
      viewAbandonedCheckouts: false
    })
  }

  displayAbandonedCheckouts = () => {
    this.setState({
      viewAbandonedCheckouts: true,
      viewAllCustomers: false,
      viewEmailSubscribers: false
    })
  }

  getSubscribers = async () => {
    try {
      let subscribers = [];
      let customerEmailToSubscribeStatus = {};
      let { sellerId } = this.state;
      const db = firebase.firestore();
      let result = await db.collection("sellersAccounts").doc(sellerId).collection("stores").doc(sellerId).collection("subscribers").get();
      if (result.docs.length < 1) {
        return this.setState({
          subscribers: []
        })
      }

      result.docs.map(subscriber => {
        let userData = subscriber.data()
        subscribers.push(userData);
        if (!customerEmailToSubscribeStatus[userData.email]) {
          customerEmailToSubscribeStatus[userData.email] = true
        }

      })
      return this.setState({
        subscribers,
        customerEmailToSubscribeStatus
      })
    } catch (error) {
      console.log("error getting subscribers", error);
    }
  }

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let location = await this.getLocation();
        this.checkRegistrationCompletion(user.uid, location);

        const storeInfo = await getBasicInfo(user.uid);
        this.setState({ storeInfo})
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );

        this.setState({
          sellerName: user.displayName,
        });
        const sellerUid = user.uid;
        await this.fetchSellerInfo(sellerUid);
        this.getCustomers();
        this.getSubscribers();
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    console.log("oquantity--", this.state.customerOrderQuantities);
    console.log("pending customer state", this.state.pendingCustomers);
    return (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Seller - Customers</title>
          <meta
            content="Cashaam - Check on your customers"
            name="description"
          />
          <meta content="Seller - Customers" property="og:title" />
          <meta
            content="Cashaam - Check on your customers"
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <meta name="msapplication-TileColor" content="#870000" />
          <meta name="theme-color" content="#870000" />
        </Metatags>
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">

            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />

            <div className="panel-center">
              <div className="page-header">
                <h4 className="header-title">Customers</h4>
              </div>
              <div className="main">
                <div className="page-tab">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayAllCustomers()}
                    className={
                      this.state.viewAllCustomers === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    All
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayEmailsubscribers()}
                    aria-current="page"
                    className={
                      this.state.viewEmailSubscribers === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    Email Subscribers
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayAbandonedCheckouts()}
                    aria-current="page"
                    className={
                      this.state.viewAbandonedCheckouts === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    Abandoned Checkouts
                  </a>

                </div>

                {this.state.viewAllCustomers ?
                  this.state.customers.map(customer => (
                    <Customer
                      customer={customer}
                      orderCount={this.state.customerOrderQuantities[customer.customerId]}
                      firstName={this.state.customerInfo[customer.customerId].firstName || this.state.customerInfo[customer.customerId].customerName}
                      lastName={this.state.customerInfo[customer.customerId].lastName}
                      email={this.state.customerInfo[customer.customerId].email}
                      phoneNumber={this.state.customerInfo[customer.customerId].phoneNumber}
                      city={this.state.customerInfo[customer.customerId].city}
                      state={this.state.customerInfo[customer.customerId].state}
                      totalAmountSpent={this.state.customerTotalOrderPrice[customer.customerId]}
                      currency={this.state.customerInfo[customer.customerId].currency}
                      subscribed={this.state.customerEmailToSubscribeStatus[customer.email]}
                    />

                  ))
                  : this.state.viewEmailSubscribers ?
                    this.state.subscribers.map(customer => (
                      <Subscriber
                        subscriber={customer}
                      />
                    ))

                    : this.state.viewAbandonedCheckouts ?
                      this.state.pendingCustomers.map(customer => (
                        (typeof (customer) !== "undefined" && typeof(this.state.pendingCustomerInfo[customer.customerId]) !== "undefined") && !this.state.pendingCustomerInfo[customer.customerId].email?.includes("N/A") && !customer.email?.includes("N/A") ?
                          <Customer
                            customer={customer}
                            firstName={this.state.pendingCustomerInfo[customer.customerId].firstName || this.state.pendingCustomerInfo[customer.customerId].customerName}
                            lastName={this.state.pendingCustomerInfo[customer.customerId].lastName}
                            email={this.state.pendingCustomerInfo[customer.customerId].email || customer.email}
                            phoneNumber={this.state.pendingCustomerInfo[customer.customerId].phoneNumber || customer.phoneNumber}
                            orderCount={this.state.pendingCustomerOrderQuantities[customer.customerId]}
                            city={this.state.pendingCustomerInfo[customer.customerId].location}
                            subscribed={this.state.customerEmailToSubscribeStatus[customer.email]}
                          /> : null
                      ))
                      : null
                }

              </div>
            </div>
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="orders"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
