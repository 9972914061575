import firebase from "../../firebaseConfig";

const getBlogs = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const result = await db.collection("blog").get();
      let blogs = [];
      result.docs.map((doc) => {
        blogs.push(doc.data());
      });

      resolve(blogs);
    } catch (error) {
      console.log("error getting blogs", error);
      reject(error);
    }
  });
};
export default getBlogs;
