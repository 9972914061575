import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import firebase from "../../../../../firebaseConfig";
import getUserLocation from "../../../../helpers/getUserLocation";

import { getServices } from '../../../../helpers/service';
import Layout from '../Layout'
import AddOnForm from './AddOnForm'
import { createAddOn, getAddOns, updateAddOn, deleteAddOn } from "../../../../helpers/addons";
import Loading from '../../../../common/Loading';
import AddOn from './AddOn';

const AddOns = (props) => {
    const [pageInfo, setPageInfo] = useState({
        userId: "",
        currentCountry: "",
        firstName: ""
    })
    const [addOns, setAddOns] = useState([])
    const [addOnFormData, setAddOnFormData] = useState({
        addOnName: "",
        addOnPrice: "",
        addOnDuration: "",
        services: []
    })
    const [services, setServices] = useState([])
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    /**
     * Function fetches user info (userId, country, name) from data base when component is mounted
     */
    const fetchData = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                Loading(true, "Loading")
                const locationData = await getUserLocation();
                const country = locationData.country_name === "India"
                    ? "Nigeria"
                    : locationData.country_name;

                // const country = await getLocation()
                setPageInfo({
                    userId: user.uid,
                    firstName: user.displayName,
                    currentCountry: country
                })
                const addOns = await getAddOns(user.uid)
                const services = await getServices(user.uid)
                setAddOns(addOns)
                setServices(services)
                Loading(false)
            } else {
                this.props.history.push("/");
            }
        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    /**
     * Submits the add-on information that would be saved
     * @param {Event} e 
     */
    const onCreateAddOn = async (e) => {
        e.preventDefault()
        try {
            Loading(true, "Add-On is being created")
            const addOns = await createAddOn(pageInfo.userId, addOnFormData)
            setAddOns(addOns)
            Loading(false)
        } catch (err) {

        }
        closeForm()
    }

    const onUpdateAddOn = async (e) => {
        e.preventDefault()
        try {
            Loading(true, "Add-On is being updated")
            const addOns = await updateAddOn(pageInfo.userId, addOnFormData)
            setAddOns(addOns)
            Loading(false)
        } catch (err) {

        }
        closeForm()
    }

    /**
     * Updates the input field values
     * @param {Event} e 
     */
    const handleChange = (e) => {
        setAddOnFormData({
            ...addOnFormData,
            [e.target.name]: e.target.value
        })
    }

    /**
     * Handles change of type checkbox input
     * @param {InputEvent} e 
     */
    const onCheckboxChange = (e) => {
        let addOnServices = []
        console.log(e.target.checked)
        if (e.target.checked) {
            addOnServices = [...addOnFormData.services]
            addOnServices.push(e.target.value)
        }
        else {
            addOnServices = addOnFormData.services.filter(service => service !== e.target.value)
        }
        setAddOnFormData({
            ...addOnFormData,
            services: addOnServices
        })
    }

    const onEditAddOn = (addOn) => {
        setAddOnFormData(addOn)
        setIsEditing(true)
        setShowCreateForm(false)
    }

    const closeForm = () => {
        setAddOnFormData({
            addOnName: "",
            addOnPrice: "",
            addOnDuration: "",
            services: []
        })
        setIsEditing(false)
        setShowCreateForm(false)
    }

    const onDeleteAddOn = async (addOn) => {
        console.log(`Deleteing`, addOn.id)
        const addOns = await deleteAddOn(pageInfo.userId, addOn.id)
        setAddOns(addOns)
    }
    return (
        <Layout
            firstName={pageInfo.firstName}
            userCurrentCountry={pageInfo.currentCountry}
        >
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Add-Ons</h4>
                </div>
                <div className="main">
                    <div className="container-products">
                        <div className="product-header">
                            <h5 className="setting-title">Add-Ons (Optional)</h5>
                            <div className="div-block" />

                            {!showCreateForm && <div className="box-btn-text-primary" onClick={() => setShowCreateForm(true)}>Create Add-Ons</div>}

                        </div>
                        <div className="microcopy-signup">
                            <p style={{ fontSize: 12 }}>
                                Add-ons are additional packages that a customer can include when scheduling your services.
                            </p>
                        </div>
                        <div className="forms w-form">
                            {
                                showCreateForm ?
                                    <AddOnForm
                                        submitForm={onCreateAddOn}
                                        addOnFormData={addOnFormData}
                                        handleChange={handleChange}
                                        services={services}
                                        onCheckboxChange={onCheckboxChange}
                                        currentCountry={pageInfo.currentCountry}
                                        closeForm={closeForm}
                                    />
                                    :
                                    isEditing ?
                                        <AddOnForm
                                            isEditing={isEditing}
                                            submitForm={onUpdateAddOn}
                                            addOnFormData={addOnFormData}
                                            handleChange={handleChange}
                                            services={services}
                                            onCheckboxChange={onCheckboxChange}
                                            currentCountry={pageInfo.currentCountry}
                                            closeForm={closeForm}
                                        />
                                        :
                                        addOns.map((addOn, i) => {
                                            return (
                                                <AddOn
                                                    key={i}
                                                    addOn={addOn}
                                                    editAddOn={() => onEditAddOn(addOn)}
                                                    deleteAddOn={() => onDeleteAddOn(addOn)} />
                                            )
                                        })
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AddOns
