import firebase from "../../firebaseConfig";

const saveShippingLabel = (
  sellerId,
  orderIds,
  customerId,
  link,
  trackingNumber,
  trackingUrlProvider,
  labelObject,
  transactionId,
  manualPrintingObject
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let batch = db.batch();

      await Promise.all(
        orderIds.map((orderId) => {
          let sellerRef = db
            .collection("sellersAccounts")
            .doc(sellerId)
            .collection("stores")
            .doc(sellerId)
            .collection("orders")
            .doc(orderId);

          let buyerRef = db
            .collection("buyersAccounts")
            .doc(customerId)
            .collection("orders")
            .doc(orderId);

          let trackingRef = db.collection("tracking").doc(trackingNumber);

          batch.set(
            sellerRef,
            {
              shippingLabelLink: link,
              trackingNumber,
              trackingUrlProvider,
              labelObject,
              orderStatus: "dispatched",
              manualPrintingObject: manualPrintingObject ? manualPrintingObject : false
            },
            { merge: true }
          );

          batch.set(
            buyerRef,
            {
              shippingLabelLink: link,
              trackingNumber,
              trackingUrlProvider,
              labelObject,
              orderStatus: "dispatched",
              manualPrintingObject: manualPrintingObject ? manualPrintingObject : false
            },
            { merge: true }
          );

          batch.set(
            trackingRef,
            {
              transactionId,
              orderId,
              sellerId,
              customerId,
              shippingLabelLink: link,
              trackingNumber,
              trackingUrlProvider,
              labelObject,
              orderStatus: "dispatched",
              manualPrintingObject: manualPrintingObject ? manualPrintingObject : false
            },
            { merge: true }
          );
        })
      );

      await batch.commit();

      resolve("success");
    } catch (error) {
      console.log("error saving shipping label", error);
      reject(error);
    }
  });
};
export default saveShippingLabel;
