import React from "react";
const VariantExamplePop = (props) => {
    return (
        <div style={{ display: "flex", overflow: "scroll" }} className="tint-background">
            <div className="pop-container">
                <div style={{ display: "flex", maxWidth: "fit-content" }} className="pop-us-ship-label">
                    <div className="pop-header">
                        <div className="text-main">Adding more product options</div>
                        <div><img onClick={() => props.setShowExample(false)} style={{ maxWidth: "25px", cursor: "pointer", paddingLeft: "5px" }} src="https://res.cloudinary.com/cashaam/image/upload/v1606273258/icons/cancel_nhpzcw.jpg" /></div>
                    </div>
                    <div className="pop-content">
                        <div className="sub-title">
                            In this example the user adds:
            </div>

                        <div className="pop-text">
                            1. Two Color options (Black, White)
              <br />
              2. Two Size options (Small, Medium)
              <br />
              3. Two Material options (Leather, Cloth)
              <br />
                This just an example, you may add more or less.
            </div>
                    </div>
                    <div style={{ display: "flex" }} className="pop-footer">
                        <img style={{ maxHeight: "60vh", marginLeft: "auto", marginRight: "auto" }} src="https://res.cloudinary.com/cashaam/image/upload/v1606271588/Gifs/ezgif.com-gif-maker_zptgkk.gif" />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default VariantExamplePop;
