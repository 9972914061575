import React from "react";
import { Link } from "react-router-dom";
import priceFormater from "../../../helpers/priceFormater";

const Product = (props) => {
  return (
    <div className="item-product">
      <div className="product-block">
        <div
          style={{ maxWidth: "fit-content", width: "50%", minWidth: "50%" }}
          className="product-meta"
        >
          <div style={{ marginLeft: "unset" }} className="item-img">
            <img src={props.product.featuredImage} alt className="stock-img" />
          </div>
          <div className="box-item">
            <div className="box-value">
              {props.product.productName?.length > 30
                ? `${props.product.productName.slice(0, 27)}...`
                : props.product.productName}
            </div>
            <div className="box-value stock-value">
              {props.product.productStock} in stock
            </div>
          </div>
        </div>
        <div className="meta-right">
          <div className="box-item quater-box">
            <div className="box-value price">
              {priceFormater(
                props.product.productPrice,
                props.product.currency
              )}
            </div>
          </div>

          <div className="box-item quater-box">
            <div className="box-title hide">Details</div>
            <a
              onClick={() => props.editProduct(props.product.id, props.product)}
              style={{ cursor: "pointer" }}
              className="btn-edit w-inline-block"
            >
              <div>Edit</div>
            </a>

            <a
              onClick={() =>
                props.deleteProduct(
                  props.product.id,
                  props.product.productImages,
                  props.index,
                  props.product.featuredImage
                )
              }
              style={{ cursor: "pointer" }}
              href="#"
              class="btn-delete w-inline-block"
            >
              <div>Delete</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductPage = (props) => {
  return (
    <div className="panel-center">
      <div className="page-header">
        <h4 className="header-title">Product Inventory</h4>
        {/* <div className="store-status">
          <div className="store-state-live">Your Store is Live</div>
        </div> */}
      </div>
      <div className="main">
        <div className="container-products">
          <div className="product-header">
            <h5 className="setting-title">Products in-Stock</h5>
            <div className="div-block" />
            <Link
              to="/seller/dashboard/addProduct"
              className="box-btn-primary w-inline-block"
            >
              <div className="box-btn-text-primary">Add Product</div>
            </Link>
          </div>
          <div className="product-holder">
            {props.products.map((product, index) => (
              <Product
                index={index}
                deleteProduct={props.deleteProduct}
                product={product}
                editProduct={props.editProduct}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductPage;
