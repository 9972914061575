import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import logoutHandler from "../../../helpers/logout";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import Modal from "../../../UI/Modal";
import EditProduct from "./EditProduct-OLD";
import priceFormater from "../../../helpers/priceFormater";
// import placeholder from "../../../../images/pattern-9.png";
const placeholder = null;

export default class Details extends Component {
  state = {
    sellerId: "",
    sellerName: "",
    product: {},
    editing: false,
  };

  getProductInfo = (sellerId, productId) => {
    const db = firebase.firestore();
    db.collection("allProducts")
      .doc(productId)
      .get()
      .then((doc) => {
        console.log(doc.data());
        const product = doc.data();
        this.setState({ product });
      });
  };

  editProduct = (productId, product) => {
    // alert("edit");
    this.setState({
      selectedProductId: productId,
      selectedProductInfo: product,
    });
    this.showModal();
  };
  showModal = () => {
    this.setState({ editing: true });
  };

  closeModal = () => {
    this.setState({ editing: false });

    this.componentDidMount();
  };

  deleteProduct = async (productId, imagesURLS, featuredImageURL) => {
    const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerId = this.state.sellerId;
    let batch = db.batch();

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = db
      .collection("sellersAccounts")
      .doc(sellerId)
      .collection("stores")
      .doc(sellerId)
      .collection("products")
      .doc(productId);
    batch.delete(sellersAccountsRef);

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.delete(allProductsRef);

    //execute the batch writes
    batch
      .commit()
      .then((message) => {
        this.props.history.push("/seller/dashboard/products");
        console.log("Image delete successful");
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        console.log("error deleting image--", error);
      });
  };

  componentDidMount() {
    const { params } = this.props.match;
    const { productId } = params;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          sellerId: user.uid,
          sellerName: user.displayName,
        });
        this.getProductInfo(user.uid, productId);
      } else {
        this.props.history.push("/");
      }
    });
  }

  logoutHandler = logoutHandler;

  render() {
    const { product } = this.state;

    return (
      <>
        <div className="storefront">
          <div className="main">
            <TopHeader
              sellerFullName={this.state.sellerName}
              logoutHandler={this.logoutHandler}
            />
            <div className="container">
              <LeftNav
                sellerName={this.state.sellerName}
                logoutHandler={this.logoutHandler}
                sellerId={this.state.sellerId}
              />
              <div className="content">
                {this.state.editing ? (
                  <EditProduct
                    closeModal={this.closeModal}
                    productId={product.id}
                    sellerUid={this.state.sellerId}
                    productInfo={product}
                  />
                ) : (
                  <div className="dashboard-holder">
                    <div className="product-page-header">
                      <h1 className="breadcrumb-welcome">Products</h1>
                      <div className="product-name">
                        {product.productName} - {product.sku}
                      </div>
                    </div>
                    <div className="page-content">
                      <div className="product-view">
                        <div className="manage-product">
                          <button
                            type="button"
                            onClick={() => {
                              this.editProduct();
                            }}
                            className="edit"
                          >
                            Edit Product
                          </button>
                          <button
                            onClick={() => {
                              this.deleteProduct(
                                product.id,
                                product.productImages,
                                product.featuredImage
                              );
                            }}
                            type="button"
                            className="delete"
                          >
                            Delete Product
                          </button>
                        </div>
                        <div className="product-container">
                          <div className="pro-img-view">
                            <div className="img-featured">
                              <img
                                src={
                                  product.featuredImage
                                    ? product.featuredImage
                                    : placeholder
                                }
                              />
                            </div>
                            <div className="img-more">
                              <div className="img-thumb">
                                <img
                                  src={
                                    product.productImages
                                      ? product.productImages[0]
                                        ? product.productImages[0]
                                        : placeholder
                                      : placeholder
                                  }
                                />
                              </div>
                              <div className="img-thumb">
                                <img
                                  src={
                                    product.productImages
                                      ? product.productImages[1]
                                        ? product.productImages[1]
                                        : placeholder
                                      : placeholder
                                  }
                                />
                              </div>
                              <div className="img-thumb">
                                <img
                                  src={
                                    product.productImages
                                      ? product.productImages[2]
                                        ? product.productImages[2]
                                        : placeholder
                                      : placeholder
                                  }
                                />
                              </div>
                              <div className="img-thumb">
                                <img
                                  src={
                                    product.productImages
                                      ? product.productImages[3]
                                        ? product.productImages[3]
                                        : placeholder
                                      : placeholder
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="pro-info-view">
                            <div className="pro-desc">
                              <h4 className="title-view">
                                {product.productName}
                              </h4>
                              <div className="description-view">
                                {product.productDescription}
                              </div>
                              <div className="description-view">
                                <br />
                                <span style={{ color: "#cc2936" }}>
                                  Inventory:
                                </span>{" "}
                                {product.productStock}
                                <br />
                                <span style={{ color: "#cc2936" }}>
                                  units Weight:
                                </span>{" "}
                                {product.productWeight}
                                <br />
                                <span style={{ color: "#cc2936" }}>
                                  sku:
                                </span>{" "}
                                {product.sku} <br />
                                <span style={{ color: "#cc2936" }}>
                                  Categories:
                                </span>{" "}
                                {product.productCategory} <br />
                                {product.productVariant ? (
                                  <>
                                    {product.productVariant.sizes ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "baseline",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <span style={{ color: "#cc2936" }}>
                                          Sizes:
                                        </span>
                                        {product.productVariant.sizes.map(
                                          (size) => (
                                            <>
                                              <div
                                                style={{
                                                  minWidth: "fit-content",
                                                }}
                                                className="tag-wrapper tagClass"
                                              >
                                                {size}
                                              </div>
                                            </>
                                          )
                                        )}
                                        <br />{" "}
                                      </div>
                                    ) : null}

                                    {product.productVariant.colors ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <span style={{ color: "#cc2936" }}>
                                          Colors:
                                        </span>{" "}
                                        {product.productVariant.colors.map(
                                          (color) => {
                                            return (
                                              <>
                                                <div
                                                  style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    backgroundColor: color,
                                                    marginLeft: "5px",
                                                  }}
                                                ></div>
                                              </>
                                            );
                                          }
                                        )}
                                        <br />{" "}
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                            <div className="pro-numbers-view">
                              <h4 className="price-view">
                                {priceFormater(
                                  product.productPrice,
                                  product.currency
                                )}
                                {/* {product.currency}
                              {product.productPrice} */}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
