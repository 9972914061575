import React,{useRef,useEffect} from 'react';
import InputTags from "./InputTags";

const ExtraVariant = (props) => {

    const optionName = useRef(null)
    useEffect(() =>{
        if(optionName.current){
          optionName.current.focus();
        }
      },[])
    console.log("ex props", props)
    return (<>
        <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
            Option {props.index + 1}
            {props.invalidExtraVariants.includes(props.index) ? <>
                <span style={{ color: "red", paddingLeft: "3px" }}>Missing value</span></> : null}
            <span onClick={() => props.removeExtraVariant(props.index)} style={{ cursor: "pointer", position: "absolute", right: "0px" }}>Remove</span>

        </div>
        <div className="mc-extra-variant-holder extra-holder">

            <input style={{ marginTop: "auto" }} value={props.variant.name} name={`${props.index}`} onChange={props.handleExtraVariantNameChange}
                placeholder="Option Name eg. Material" type="text" className="input-text input-half w-input input-glow" ref={optionName}/>
            <InputTags
                tags={props.extraVariantsTags[props.index]}
                handleDelete={(index) => props.handleExtraVariantDelete(index, props.index)}
                handleAddition={(tag) => props.handleExtraVariantAddition(tag, props.index)}
                handleDrag={props.handleDrag}
                inputFieldClass="input-text w-select input-glow"
                placeholder="e.g: Leather, Cloth"
                customClass="mc-input-tags-half"
                customId={`${props.customId}${props.index}`}
                clickAdd={()=>props.clickAdd(props.customId,props.index)}
            /></div>
        <div className="mc-extra-variants-note" style={{ color: "rgb(103, 64, 170)" }}>Must be separated by a COMMA or ENTER</div>
    </>
    )
}
export default ExtraVariant;