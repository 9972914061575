import React, { Component } from "react";
import { unescape } from 'html-escaper';
import RichTextQuill from "./RichTextQuill";
import AIContector from "./AIContector";

class AdsHelp extends Component {
  render() {
    return (
      <div className="adshelpcontent flexwrapper rewards-wrapper">
        {/* <div className="adshelpheader">
          <div className="adshelpheadercontent">
            <div>Ads Help</div>
            
          </div>
        </div> */}
        {/* <div className="adshelptips">
          <div className="adshelptipstitle">Tips of the day</div>
          <div className="adshelptipstext w-richtext">
            <ul role="list">
              {this.props.tips?.map((tip, index) => {
                return <li>{tip?.tipTitle}</li>;
              })}
            </ul>
          </div>
        </div> */}
        
        <AIContector 
            adsRecommendation={this.props.adsRecommendation}
            selectCategory={this.props.selectCategory}
            selectedCategory={this.props.selectedCategory}
          />

        {this.props.trendingArticles && this.props.trendingArticles?.length > 0?

            <div className="adshelparticle">
                <div className="adshelparticlecontent">
                    <div className="adshelpcontenttitle">{this.props.trendingArticles[0]?.articleTitle}</div>
                    <div className="quillDiv">
                    <RichTextQuill
                        text={unescape(this.props.trendingArticles[0]?.content)}
                    />
                    </div>
                </div>
                <div className="orderdetailscontent">
                    <iframe
                        className="adshelparticleimage"
                        src={this.props.trendingArticles[0]?.embededYoutube}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                </div>

                {/* <img
                    src={this.props.trendingArticles[0]?.image}
                    loading="lazy"
                    className=""
                /> */}
            </div>
              :null
        }


        <div className="adshelpgridwrap">
          <div className="row justify-between">
            <div className="adshelpcontenttitle">Blog Articles</div>
            <a target="_blank" href="https://blog.cashaam.com/homepage" className="viewall">
              See all
            </a>
          </div>
          <div className="adshelpgrid" style={{height:"215px"}}>


          {this.props.blogs?.map((blog, index) => {
                return (
                    <a
                    id="w-node-_8013c410-f860-e5bb-ea42-2303070c4ce0-4bc00d62"
                    className={`adshelpgriditem ${(index+1)/1==1?"green":((index+1)/2==1?"purple":"orange")}`}
                    target="_blank" href={blog.blogLink}
                    style={{
                        textDecoration: "none",
                        backgroundImage: `url(${blog.image}`,
                        backgroundSize:"cover",
                        padding:"0px",
                        height:"169px"
                    }}
                    
                  >
                    <div style={{margin:"10px"}} className="adshelpgriditemlabel">
                      <img
                        src="https://assets.website-files.com/62ff758642899091147d6bb2/630f359938a693a8e41afff9_Star.svg"
                        loading="lazy"
                        alt
                      />
                      <div>{blog?.blogTag}</div>
                    </div>
                    <div 
                    style={{padding:"0px 10px", backgroundColor:"black", textAlign:"center",
                            borderBottomRightRadius: "5px",
                            borderBottomLeftRadius: "5px",
                            position:"relative",
                            top:"39px"
                        }} 

                    className="adshelpgriditemtext">{blog?.blogTitle}</div>
                  </a>

                );
            })}

            
          </div>
        </div>
        <div className="mobilelinefullwidth" />
        {this.props.trendingArticles && this.props.trendingArticles?.length >= 1?

            <div className="adshelparticle">
                <div className="adshelparticlecontent">
                    <div className="adshelpcontenttitle">{this.props.trendingArticles[1]?.articleTitle}</div>
                    <div className="quillDiv">
                    <RichTextQuill
                        text={unescape(this.props.trendingArticles[1]?.content)}
                    />
                    </div>
                </div>
                <div className="orderdetailscontent">
                    <iframe
                        className="adshelparticleimage"
                        src={this.props.trendingArticles[1]?.embededYoutube}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="allowfullscreen"
                        mozallowfullscreen="mozallowfullscreen" 
                        msallowfullscreen="msallowfullscreen" 
                        oallowfullscreen="oallowfullscreen" 
                        webkitallowfullscreen="webkitallowfullscreen"
                    ></iframe>
                </div>
            </div>
            :null
            }

        {/* <div className="adshelpgridwrap">
          <div className="row justify-between">
            <div className="adshelpcontenttitle">Click below to get recommendations for your ads</div>
          </div>
          <div className="adshelptopsellersgrid">

          {this.props.adsRecommendation?.map((ads, index) => {
                return (
                <a href={`/seller/dashboard/content?category=${ads.category}`} className="adshelptopsellersgriditem">
                    <div>{ads.title}</div>
                    <div className="adshelptopsellersgriditembottom">
                      <div className="adshelptopsellersgriditemname increase">
                        {ads.category}
                      </div>
                      <div className="adshelptopsellersgriditemlabel increase">
                        <img
                          src="https://assets.website-files.com/62ff758642899091147d6bb2/630f5ff0c0454efc9c72b013_Icon.svg"
                          loading="lazy"
                          alt
                        />
                        <div>XX%</div>
                      </div>
                    </div>
                </a>
                );
              })}

          </div>
        </div> */}
      </div>
    );
  }
}

export default AdsHelp;
