import firebase from "../../firebaseConfig";

const sendMessage = async (sellerId, buyerId, senderId, receiverId, msg, sellerAvatar, buyerAvatar, sellerName, buyerName) => {

    try {
        const db = firebase.firestore();

        let chatRoomId = `${sellerId}-${buyerId}`;

        

        let chatRef = db.collection("messages").doc(chatRoomId);

        let chatDocument= await chatRef.get();

        console.log("chatDocument",chatDocument.data());
        let buyerUnread=chatDocument.data()?.buyerUnread;
        
        if(!buyerUnread){
            buyerUnread=1;
        }else{
            buyerUnread++;
        }

        console.log("Chat exists?",chatDocument.exists)

        // if(!chatDocument.exists){
            console.log("set ChatRef")

            await chatRef.set({
                sellerId,
                buyerId,
                sellerAvatar,
                buyerAvatar, 
                sellerName,
                buyerName,
                sellerUnread: 0,
                buyerUnread,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })

        //}

        let threadRef = chatRef.collection("threads");

        await threadRef.add({
            sellerId,
            buyerId,
            senderId,
            receiverId,
            msg,
            chatRoomId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
        });

        return true;
    } catch (error) {
        console.log("error sending message", error);
        return false;
    }

}
export default sendMessage;