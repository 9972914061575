import firebase from "../../firebaseConfig";

export const saveDiscount = (discount) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;
      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const discounts = store.data().discounts ? store.data().discounts : [];
      let code = discount.discountCode.toUpperCase();
      discount["discountCode"]=code;
      discounts.push(discount);
      discounts.sort();
      await docRef.update({
        discounts,
      });
      resolve(discounts);
    } catch (error) {
      console.log("error adding discount", error);
      reject(error);
    }
  });
};

export const getDiscounts = () => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;

      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const discounts = store.data().discounts;

      resolve(discounts);
    } catch (error) {
      console.log("error getting discounts", error);
      reject(error);
    }
  });
};

export const deleteDiscount = (deletedDiscount) => {
  return new Promise(async (resolve, reject) => {
    const db = firebase.firestore();
    try {
      // get current user logged in uid
      const sellerUid = firebase.auth().currentUser.uid;

      var docRef = "";
      docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid);

      const store = await docRef.get();
      const discounts = store.data().discounts;
      const updatedDiscounts = discounts.filter(
        (discount) => discount.discountCode !== deletedDiscount.discountCode
      );
      await docRef.update({
        discounts: updatedDiscounts,
      });
      resolve(updatedDiscounts);
    } catch (error) {
      console.log("error deleting discount", error);
      reject(error);
    }
  });
};
