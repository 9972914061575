import firebase from "../../firebaseConfig";

const getDocRef = (userId) => {
    const db = firebase.firestore();
    // get current user logged in uid
    // console.log(firebase.auth().currentUser)
    var docRef = db
        .collection("sellersAccounts")
        .doc(userId)
        .collection("stores")
        .doc(userId)
        .collection("addOns")
    return docRef
}

/**
 * Saves add on to the firestore
 * @param {string} userId 
 * @param {Object} addOn 
 */
export const createAddOn = async (userId, addOn) => {
    return new Promise(async (resolve, reject) => {
        try {
            var docRef = getDocRef(userId)
            var doc = docRef.doc()
            var time = Date.now()
            addOn.createdAt = time
            console.log(addOn)
            await doc.set({id: doc.id, ...addOn})
            const data = await docRef.orderBy("createdAt", "desc").get()
            const addOns = data.docs.map(service => service.data())
            resolve(addOns)
        } catch (error) {
            console.log("error saving service", error);
            reject(error);
        }
    });
}

/**
 * Retrieves a users add ons
 * @param {String} userId 
 */
export const getAddOns = (userId) => {
    return new Promise(async (resolve, reject) => {
        try {
            var docRef = getDocRef(userId)
            const data = await docRef.orderBy("createdAt", "desc").get()
            const addOns = data.docs.map(service => service.data())
            resolve(addOns)
        } catch (error) {
            console.log("error getting services", error);
            reject(error);
        }
    })
}

export const updateAddOn = (userId, addOn) => {
    return new Promise(async (resolve, reject) => {
        try {
            var docRef = getDocRef(userId)
            var doc = docRef.doc(addOn.id)
            await doc.set(addOn)
            const data = await docRef.get()
            const addOns = data.docs.map(service => service.data())
            resolve(addOns)
        } catch (error) {
            console.log("error updating add-on", error);
            reject(error);
        }
    });
}

export const deleteAddOn = (userId, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            var docRef = getDocRef(userId)
            var doc = docRef.doc(id)
            await doc.delete()
            const data = await docRef.get()
            const addOns = data.docs.map(service => service.data())
            resolve(addOns)
        } catch (error) {
            console.log("error deleting service", error);
            reject(error);
        }
    });
}