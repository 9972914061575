import React from "react";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";

function DeliveryItem(props) {
  return (
    <div className="delivery-item-container">
      <div className="productlistitem delivery-list">
        <div className="wrapbox">
          <div className="productlisttitletext mobilepltext">
            <div>Name</div>
          </div>
          <a className="item-name w-inline-block">
            <div>{props?.productName}</div>
          </a>
        </div>
        <div className="wrapbox">
          <div className="productlisttitletext mobilepltext">
            <div>Quantity</div>
          </div>
          <div>{props?.orderQuantity}</div>
        </div>
        <div className="wrapbox">
          <div className="productlisttitletext mobilepltext">
            <div>Base Price</div>
          </div>
          <div>{priceFormaterIntl(props.productPrice, props.currency)}</div>
        </div>
      </div>
      <div className="wrapbox variant-boxes">
        <div className="productlisttitletext mobilepltext">Variant</div>
        <div className="variant-container checkoutitemtitle delivery-variant">
          {props.selectedColor ? (
            <div className="checkoutvariantitem item-variant">
              <div className="checkoutvarianttitle">Color: </div>
              <div className="checkoutvariantvalue">{props.selectedColor}</div>
            </div>
          ) : null}

          {props.selectedSize ? (
            <div className="checkoutvariantitem item-variant">
              <div className="checkoutvarianttitle">Size:</div>
              <div className="checkoutvariantvalue">{props.selectedSize}</div>
            </div>
          ) : null}

          {props.selectedExtraVariants.map((variant, i) => (
            <div className="checkoutvariantitem item-variant">
              <div className="checkoutvarianttitle">{`${variant.name}`}:</div>
              <div className="checkoutvariantvalue">{variant.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DeliveryItem;
