import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../../../../firebaseConfig/index";
import { USER_SIGNUP_API_URL } from "../../../../secrets";
// import "../css/main.css";
// import "../css/cashaam-seller.css";
// import "../css/normalize.css";
import Lottie from "react-lottie";
import * as animation from "../../../../documents/manage-store.json";
import Metatags from "react-meta-tags";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import updateUserCreds from "../../../helpers/updateUserCreds";
import reAuthenticate from "../../../helpers/reAuthenticate";
import Loading from "../../../common/Loading";
import phone from "phone";
import ConfirmCode from "../../../common/ConfirmCode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveRegisterProgress from "../../../helpers/saveRegisterProgress";
import RegisterPage from "./RegisterPage";
import getUserLocation from "../../../helpers/getUserLocation";
import checkPhoneAvail from "../../../helpers/authentication/checkPhoneAvail";
import checkEmailAvail from "../../../helpers/authentication/checkEmailAvail";

export default class Signup extends Component {
  _isMounted = false;

  state = {
    name: "", //fullName
    email: "",
    mobile: "",
    password: "",
    confirmationCode: "",
    signedUp: false,
    showConfirmationCodeInput: false,
    firstName: "",
    lastName: "",
    // confirming: false,
    // confirmComplete: false,
    loading: false,
    showPassword: false,
    errorMessage: null,
    userCurrentCountry: "",
    referralCode: "",
    firstNameError: "",
    lastNameError: "",
    phoneError: "",
    emailError: "",
    passwordError: "",
    loginSuggestion: "",
    isToggleOn: true,
  };
  // handles the event change based on input from the user
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: false,
    });
  };

  handlePhoneInput = (mobile) => {
    console.log(this.state.mobile);
    this.setState({ mobile });
  };

  confResult = null;

  displayLoading = () => {
    // this.setState({ confirming: true, confirmComplete: false });
    this.setState({ loading: true });
  };

  hideLoading = () => {
    // this.setState({ confirming: false, confirmComplete: true });
    this.setState({ loading: false });
  };

  submitConfResult = async (e) => {
    e.preventDefault();
    // Loading(true, "Creating your account. Please wait...");
    try {
      this.displayLoading();
      const { firstName, lastName, email, mobile, password } = this.state;
      const db = firebase.firestore();
      //enforce phone Number format before saving to firebase
      let formatedPhoneNumber = mobile.replace(/[- )(]/g, "");
      var credentials = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      var code = this.state.confirmationCode;
      if (!code) {
        this.hideLoading();
        // Loading(false);
        toast.error("Please enter a confirmation code");
        return this.setErrorMessage("Please enter a confirmation code");
      }
      await this.confResult.confirm(code);
      // User signed in successfully.
      // var user = result.user;
      // console.log("user", user);

      console.log("curr user", firebase.auth().currentUser);

      await firebase.auth().currentUser.linkWithCredential(credentials);

      // var user = usercred.user;
      // console.log("Account linking success", user);

      //add user info to the user object
      var user = firebase.auth().currentUser;
      const sellerUid = user.uid;
      if (user) {
        await updateUserCreds(
          sellerUid,
          firstName,
          email,
          password,
          `+${mobile}`
        );

        //re-authenticate user with new email and password credentials
        // this is necessary because after chaning user auth credentials, firebase automatically logs user out
        await reAuthenticate(email, password);

        await db.collection("sellersAccounts").doc(sellerUid).set(
          {
            id: sellerUid,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: formatedPhoneNumber,
            activated: false,
          },
          { merge: true }
        );
        var user = firebase.auth().currentUser;

        await saveRegisterProgress(sellerUid, 1);
        this.props.history.push("/seller/dashboard/setup/onboarding");
        this.hideLoading();
      }
    } catch (error) {
      this.hideLoading();
      console.log("error confirming code", error);

      if (error.code === "auth/provider-already-linked") {
        toast.error(
          "This Phone number and/or Email is already linked to an existing account."
        );
        this.setErrorMessage(
          "This Phone number and/or Email is already linked to an existing account."
        );
        // this.props.history.push("/seller/signin");
      }

      if (error.code === "auth/email-already-in-use") {
        toast.error("This email is already linked to an existing account");
        this.setErrorMessage(
          "This email is already linked to an existing account"
        );

        // this.props.history.push("/seller/signin");
      }

      if (error.code === "auth/invalid-verification-code") {
        toast.error("Invalid code, please try again");
        return this.setErrorMessage("Invalid code, please try again");
      } else if (error.code === "auth/missing-verification-code") {
        toast.error("Please enter the verification code sent to your phone");
        return this.setErrorMessage(
          "Please enter the verification code sent to your phone"
        );
      }
      console.log("error confirming code", error);
    }
  };

  toggleReferral = (e) => {
    e.preventDefault();
    const { isToggleOn } = this.state;
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      this.displayLoading();

      const {
        firstName,
        lastName,
        name,
        email,
        mobile,
        password,
        referralCode,
        firstNameError,
        lastNameError,
        phoneError,
        emailError,
        passwordError,
        loginSuggestion,
      } = this.state;

      this.setState({
        firstNameError: "",
        lastNameError: "",
        phoneError: "",
        emailError: "",
        passwordError: "",
        loginSuggestion: "",
      });

      //enforce first & last name input

      if (!firstName || firstName.length < 2) {
        this.hideLoading();
        this.setState({
          firstNameError: "Please enter your first name",
        });
        return;
      }

      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      if(format.test(firstName)){
        this.hideLoading();
        this.setState({
          firstNameError: "Remove space or special character",
        });
        return;
      }

      if(/\d/.test(firstName)){
        this.hideLoading();
        this.setState({
          firstNameError: "Remove numbers from your name",
        });
        return;
      }

      if(firstName && firstName.length >12){
        this.hideLoading();
        this.setState({
          firstNameError: "Name can't be more than 12 letters",
        });
        return;
      }

      if (!lastName || lastName.length < 2) {
        this.hideLoading();
        this.setState({
          lastNameError: "Please enter your last name",
        });
        return;
      }

      if(format.test(lastName)){
        this.hideLoading();
        this.setState({
          lastNameError: "Remove space or special character",
        });
        return;
      }

      if(/\d/.test(lastName)){
        this.hideLoading();
        this.setState({
          lastNameError: "Remove numbers from your name",
        });
        return;
      }

      if(lastName && lastName.length >12){
        this.hideLoading();
        this.setState({
          lastNameError: "Name can't be more than 12 letters",
        });
        return;
      }

      //check if the user input a phone number
      if (!mobile || mobile.length < 11) {
        this.hideLoading();
        this.setState({
          phoneError:"Please enter a valid phone number"
        });
        return;
      }

      // US & CANADA CHECK -  1 513-641-8158
      if (mobile.substring(0, 1) == 1 && mobile.length > 11) {
        this.hideLoading();
        this.setState({
          phoneError:"Please enter a valid phone number"
        })
        return;
      }else if (mobile.substring(0, 3) == "234" && mobile.length >13) {
        // //Nigerian Number e.g.234 080-307-0045-0-
        this.hideLoading();
        this.setState({
          phoneError:"Please enter a valid phone number"
        })
        return;
      }

      if(mobile.substring(0, 1) != 1 && mobile.substring(0, 3) != "234"){
        this.hideLoading();
        this.setState({
          phoneError:"Please enter a Nigerian or US phone number."
        })
        return;
      }
      console.log("phone: ", mobile);

      if (!email) {
        this.hideLoading();
        this.setState({
          emailError: "Please enter your email",
        });
        return;
      }

      if (password.length < 6) {
        this.hideLoading();
        this.setState({
          passwordError:
            "Please enter a stronger password. (Minimum length of 6)",
        });
        return;
      }

      /*
      //check if phone number is available for use
      let isPhoneAvailable = await checkPhoneAvail(mobile);
      if (isPhoneAvailable === false) {
        toast.error("Phone number already taken");
        this.setErrorMessage("Phone number already taken");
        return;
      }
      //check if email is available for use
      let isEmailAvailable = await checkEmailAvail(email);
      if (isEmailAvailable === false) {
        console.log("Email taken")
        
        
        
        //toast.error("Email already taken");
        
        return this.setErrorMessage("Email already taken");
      }

*/

      var encodedPass = window.btoa(password);


      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNo: mobile,
        password: encodedPass,
        referralcode: referralCode,
      };

      let response = await fetch(USER_SIGNUP_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        console.log("Success");

        const content = await response.json();

        //Account Creation successful
        if (content.newAccountCreated) {
          var customToken = content.customToken;

          await firebase
            .auth()
            .signInWithCustomToken(customToken)
            .then((userCredential) => {
              var user = userCredential.user;
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log("Failed reAuthenication: ", errorMessage);
            });
          var sellerUid = content.userId;

          await saveRegisterProgress(sellerUid, 1);
          this.props.history.push("/seller/dashboard/setup/pickaccount");
          this.hideLoading();
        } else {
          if (content.phoneAlreadyUsed) {
            this.hideLoading();
            this.setState({
              loginSuggestion: "phone",
            });

            return;
          } else if (content.emailAlreadyUsed) {
            this.hideLoading();
            this.setState({
              loginSuggestion: "email",
            });
            return;
          }
        }
      } else {
        console.log("STATUS: ", response.status);
      }

      /*
      let userCreds = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      var user = firebase.auth().currentUser;
      await user.sendEmailVerification();
      const sellerUid = user.uid;
      if (user) {
        //add user info to the user object
        await updateUserCreds(
          sellerUid,
          firstName,
          email,
          password,
          `+${mobile}`,
          false //set email verified as false
        );

        //re-authenticate user with new email and password credentials
        // this is necessary because after changing user auth credentials, firebase automatically logs user out
        await reAuthenticate(email, password);
        let db = firebase.firestore();

        //enforce phone Number format before saving to firestore
        let formatedPhoneNumber = mobile.replace(/[- )(]/g, "");
        await db
          .collection("sellersAccounts")
          .doc(sellerUid)
          .set({
            id: sellerUid,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: formatedPhoneNumber,
            activated: false,
            referralCode: referralCode ? referralCode : "N/A",
          });

          await saveRegisterProgress(sellerUid, 1);
          this.props.history.push("/seller/dashboard/setup/onboarding");
          this.hideLoading();


      }

      */
    } catch (error) {
      console.log("error signin up", error);

      return this.setErrorMessage(
        "Error signing up. Please check your inputs and try again."
      );
    }
  };

  // handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const { name, email, mobile, password } = this.state;
  //     // create user account with email and password with firebase.auth()
  //     const splitName = name.split(" ");

  //     //enforce "NO MIDDLE NAME"
  //     //ignore extra empty spaces between first and last name
  //     const splitNameNoSpaces = splitName.filter((name) => name.length > 1);

  //     if (splitNameNoSpaces.length > 2) {
  //       toast.error("No middle names, Please enter First and Last name ONLY");
  //       this.setErrorMessage(
  //         "No middle names, Please enter First and Last name ONLY"
  //       );

  //       return;
  //     }

  //     const firstName = splitNameNoSpaces[0];
  //     const lastName = splitNameNoSpaces[1];

  //     //enforce first & last name input
  //     if (!lastName) {
  //       toast.error("Please enter your full name. (First and Last)");
  //       this.setErrorMessage("Please enter your full name. (First and Last)");

  //       return;
  //     } else {
  //       if (firstName.length < 2 || lastName.length < 2) {
  //         toast.error("Please enter your full name. (First and Last");
  //         this.setErrorMessage("Please enter your full name. (First and Last");

  //         return;
  //       }
  //     }

  //     //check if the user input a phone number
  //     if (!mobile) {
  //       toast.error("Please enter a phone valid number");
  //       this.setErrorMessage("Please enter a phone valid number");
  //       return;
  //     }

  //     if (password.length < 6) {
  //       toast.error("Please enter a stronger password. (Minimum length of 6)");
  //       this.setErrorMessage(
  //         "Please enter a stronger password. (Minimum length of 6)"
  //       );
  //       return;
  //     }

  //     let user = null;
  //     let sellerUid = null;
  //     const db = firebase.firestore();

  //     // Loading(true, "Please wait...");
  //     this.displayLoading();
  //     // const onSignInSubmit = () => {
  //     var appVerifier = window.recaptchaVerifier;
  //     console.log("Number", `+${mobile}`);
  //     // let eSixFourFormatNumberArray = phone(`${mobile}`);
  //     // console.log("e64 array", eSixFourFormatNumberArray);
  //     // let eSixFourFormatNumber = eSixFourFormatNumberArray[0];
  //     // console.log("e64", eSixFourFormatNumber);
  //     const confirmationResult = await firebase
  //       .auth()
  //       .signInWithPhoneNumber(`+${mobile}`, appVerifier);
  //     // .then(async (confirmationResult) => {
  //     // Loading(false);
  //     this.hideLoading();
  //     // SMS sent. Prompt user to type the code from the message, then sign the
  //     // user in with confirmationResult.confirm(code).
  //     // window.confirmationResult = confirmationResult;
  //     this.setState({
  //       showConfirmationCodeInput: true,
  //       firstName: firstName,
  //       lastName: lastName,
  //     });
  //     this.confResult = confirmationResult;
  //   } catch (error) {
  //     console.log("error signin up", error);

  //     this.hideLoading();
  //     if (error.code === "auth/invalid-phone-number") {
  //       toast.error("invalid phone number format");
  //       this.setErrorMessage("invalid phone number format");
  //     }

  //     if (error.code === "auth/argument-error") {
  //       toast.error(
  //         "error completing sign up. Please try again. If the error persists, please contact us."
  //       );
  //       this.setErrorMessage(
  //         "error completing sign up. Please try again. If the error persists, please contact us."
  //       );
  //     }
  //     console.log(error);
  //     // Error; SMS not sent
  //     // ...
  //     // Or, if you haven't stored the widget ID:
  //     // window.recaptchaVerifier.render().then(function(widgetId) {
  //     //   grecaptcha.reset(widgetId);
  //     // });
  //     // });
  //     // };
  //   }
  // };

  tryAnotherMethod = () => {
    this.setState({
      showConfirmationCodeInput: false,
      // firstName: firstName,
      // lastName: lastName,
    });
    this.confResult = null;
  };

  onResendVerificationCode = async () => {
    try {
      this.displayLoading();
      var appVerifier = window.recaptchaVerifier;
      const { mobile } = this.state;

      const confirmationResult = await firebase
        .auth()
        .signInWithPhoneNumber(`+${mobile}`, appVerifier);

      this.hideLoading();
    } catch (error) {
      console.log("error resending verification code", error);
      this.hideLoading();

      if (error.code === "auth/too-many-requests") {
        toast.error("Too many attempts. Please try again later");

        return this.setErrorMessage(
          "Too many attempts. Please try again later"
        );
      }

      return toast.error("error resending code");
    }
  };

  onShowPassword = (val) => {
    this.setState({
      showPassword: val,
    });
  };

  setErrorMessage = (val) => {
    this.setState({
      errorMessage: val,
      loading: false,
    });

    setTimeout(() => {
      this.setErrorMessage(null);
    }, 15000);
  };

  getLocation = async () => {
    try {
      const locationData = await getUserLocation();
      this.setState({
        userCurrentCountry:
          locationData.country_name === "India"
            ? "Nigeria"
            : locationData.country_name,
        shopCurrency:
          locationData.currency.code === "IRN"
            ? "NGN"
            : locationData.currency.code,
      });
      return locationData.country_name === "India"
        ? "Nigeria"
        : locationData.country_name;
    } catch (error) {
      console.log("error getting user location", error);
      Promise.reject(error);
    }
  };
  getReferalCodeFromURL = () => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let referral_code = url.searchParams.get("referral_code");
    this.setState({
      referralCode: referral_code,
    });
  };

  loginWithPhone = (val) => {
    this.props.history.push({
      pathname: "/seller/signin",
      state: { phoneLogin: val === "phone" },
    });
  };

  async componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      this.getReferalCodeFromURL();

      await Promise.resolve(this.getLocation());
    }

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <>
        <Metatags>
          <title>Sign Up to Cashaam</title>
          <meta
            content="Cashaam - Create a Free Seller&#x27;s profile and start selling. Give your products more visibility, more customers may be looking for you."
            name="description"
          />
          <meta content="Sign Up to Cashaam" property="og:title" />
          <meta
            content="Cashaam - Create a Free Seller&#x27;s profile and start selling. Give your products more visibility, more customers may be looking for you."
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />

          <meta
            name="keywords"
            content="Free Website,Free online,store, Lagos, Nigeria, Cashaam, Ship products, online stores, stores, custom links,instagram, facebook, sell online,online store, free online store"
          />
          <meta name="msapplication-TileColor" content="#dd4200" />
          <meta name="theme-color" content="#dd4200" />
        </Metatags>
        <ToastContainer />
        {this.state.showConfirmationCodeInput === false ? (
          <RegisterPage
            handleSubmit={this.handleSubmit}
            toggleReferral={this.toggleReferral}
            handleChange={this.handleChange}
            handlePhoneInput={this.handlePhoneInput}
            loading={this.state.loading}
            mobile={this.state.mobile}
            showPassword={this.state.showPassword}
            onShowPassword={this.onShowPassword}
            loginWithPhone={this.loginWithPhone}
            errorMessage={this.state.errorMessage}
            firstNameError={this.state.firstNameError}
            lastNameError={this.state.lastNameError}
            phoneError={this.state.phoneError}
            emailError={this.state.emailError}
            passwordError={this.state.passwordError}
            loginSuggestion={this.state.loginSuggestion}
            location={
              this.state.userCurrentCountry
                ? this.state.userCurrentCountry.toLocaleLowerCase()
                : "us"
            }
            referralCode={this.state.referralCode}
            isToggleOn={this.state.isToggleOn}
          />
        ) : (
          <ConfirmCode
            onChange={this.handleChange}
            tryAnotherMethod={() => {
              this.tryAnotherMethod();
            }}
            onSubmit={this.submitConfResult}
            phoneNumber={this.state.mobile}
            displayLoading={this.state.loading}
            onResendVerificationCode={() => this.onResendVerificationCode()}
            errorMessage={this.state.errorMessage}
          />
        )}

        <div id="recaptcha-container"></div>
      </>
    );
  }
}