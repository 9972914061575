import firebase from "../../firebaseConfig";

const getProductCount = (id) => {
  return new Promise(async (resole, reject) => {
    try {
      const db = firebase.firestore();
      const products = await db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("products")
        .get();

      resole(products.size);
    } catch (error) {
      console.log("error getting basic info", error);
      reject(error);
    }
  });
};
export default getProductCount;
