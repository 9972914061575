import React from "react";
import shapes from "../../images/shapes.png";
import ReactTooltip from "react-tooltip";

const PackageInfo = (props) => {
  return (
    <>
      <ReactTooltip />

      <div className="form-holder">
        <div style={{ minWidth: "60%" }} className="product-steps">
          <div className="block-title-2">
            <h4 className="page-title">Product Dimensions</h4>
          </div>
          <div className="microcopy-signup">
            To accurately determine the cost of shipping your products to
            customers, it's important to calculate the right size and
            dimensions. <br />
            <br />
            Enter the dimensions below for your product. Or Skip if unsure.
            <br />

            <span
              style={{
                paddingRight: "6px",
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              {" "}
              {"Max Weight: "}
              {props.userCurrentCountry?.toLowerCase()=="nigeria"?
              "31,752 grams":"1120 oz"}
              
            </span>

            <span
              style={{
                fontWeight: "bold",
                fontSize: "10px",
              }}
              className="dim-span"
            >
              {" "}

              {"Max Length: "}
              {props.userCurrentCountry?.toLowerCase()=="nigeria"?
              "274 cm":"108 Inches"}
            </span>
            <br />
            <span
              style={{
                paddingRight: "6px",
                fontWeight: "bold",
                fontSize: "10px",
              }}
            >
              {"Max Width: "}
              {props.userCurrentCountry?.toLowerCase()=="nigeria"?
              "274 cm":"108 Inches"}
            </span>

            <span
              style={{
                fontWeight: "bold",
                fontSize: "10px",
              }}
              className="dim-span-height"
            >

              {"Max Height: "}
              {props.userCurrentCountry?.toLowerCase()=="nigeria"?
              "274 cm":"108 Inches"}
            </span>
          </div>

          {props.productWeightError? <div class="error-message group-error"><p>{props.productWeightError}</p></div> : null}
          {props.productLengthError? <div class="error-message group-error"><p>{props.productLengthError}</p></div> : null}
          {props.productWidthError? <div class="error-message group-error"><p>{props.productWidthError}</p></div> : null}
          {props.productHeightError? <div class="error-message group-error"><p>{props.productHeightError}</p></div> : null}



          <div className="product-builder" />
          <div className="holding-onboarding group-error">
            <div className="forms w-form">
              <form
              onSubmit={props.handleSubmit}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                <input
                  data-tip="16 oz = 1 lbs"
                  /*This lets us use the placeholder if there is no user input 
                   The initial value of weight=0, so placeholder will not show */
                  value={!props.productWeight?"":props.productWeight}
                  onChange={props.handleChange}
                  type="number"
                  step="0.001"
                  className={props.productWeightError? "input-text input-half w-input input-glow input-error" : "input-text input-half w-input input-glow"}
                  name="productWeight"
                  data-name="productWeight"
                  placeholder={props.userCurrentCountry?.toLowerCase()=="nigeria"?"Weight (grams)":"Weight (oz)"}
                  id="productWeight"
                />
                
                <input
                   
                  value={!props.productLength?"":props.productLength}
                  onChange={props.handleChange}
                  type="number"
                  step="0.001"
                  className={props.productLengthError? "input-text input-half w-input input-glow input-error" : "input-text input-half w-input input-glow"}
                  maxLength={256}
                  name="productLength"
                  data-name="productLength"
                  placeholder={props.userCurrentCountry?.toLowerCase()=="nigeria"?"Length in cm":"Length in Inches"}
                  id="productLength"
                  //required
                />
                
                <input
                  onChange={props.handleChange}
                  value={!props.productWidth?"":props.productWidth}
                  type="number"
                  step="0.001"
                  className={props.productWidthError? "input-text input-half w-input input-glow input-error" : "input-text input-half w-input input-glow"}
                  maxLength={256}
                  name="productWidth"
                  data-name="productWidth"
                  placeholder={props.userCurrentCountry?.toLowerCase()=="nigeria"?"Width in cm":"Width in Inches"}
                  id="productWidth"
                  //required
                />
                
                <input
                  onChange={props.handleChange}
                  value={!props.productHeight?"":props.productHeight}
                  type="number"
                  step="0.001"
                  className={props.productHeightError? "input-text input-half w-input input-glow input-error" : "input-text input-half w-input input-glow"}
                  maxLength={256}
                  name="productHeight"
                  data-name="productHeight"
                  placeholder={props.userCurrentCountry?.toLowerCase()=="nigeria"?"Height in cm":"Height in Inches"}
                  id="productHeight"
                  //required
                />
                <input
                  // onClick={() => props.onDisplayUploadImage()}
                  type="submit"
                  value="Submit"
                  defaultValue="Next"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
                  onClick={() => props.onHidePackageInfo()}
                  style={{ marginTop: "10px", cursor: "pointer" }}
                  className="btn-secondary"
                >
                  <div className="text-btn-secondary">Go Back</div>
                </div>

                <div
                onClick={(e) => props.skipForm(e)}
                  style={{ marginTop: "10px", cursor: "pointer",color: "#dddddd",
                  backgroundColor:"#4999f2",
                  borderColor: "#fafafa",
               }}
                  className="btn-secondary"
                >
                  <div style={{color: "white"}}className="text-btn-secondary">Skip</div>
                </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
          <div className="microcopy-signup bold">
            See Measuring Tips to measure ODD shape packages:
          </div>
          <div
            style={{ marginLeft: "auto", marginRight: "auto" }}
            className="meta-measure-tip"
          >
            <img src={shapes} alt />
          </div>
        </div>
      </div>
    </>
  );
};
export default PackageInfo;
