import React from 'react'
import { Link } from 'react-router-dom';

const Confirmation = (props) => {
  return (
    <div className="form-holder">
      <div style={{ minWidth: "60%" }} className="product-steps">
        <div style={{ minWidth: "100%" }} className="onboarding-steps">
          <div className="block-title-1">
            <div className="icon-success w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-circle-check"
                width={84}
                height={84}
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <path d="M9 12l2 2l4 -4" />
              </svg>
            </div>
            <h4 className="h4">Your Service was added successfully!</h4>
          </div>
          <div className="holder-btn">
            {
              !props.hasAvailability &&
              <>
                <div>Complete your service setup</div>
                <Link
                  to="/seller/dashboard/service/availability"
                  className="btn-secondary"
                  style={{ marginBottom: 10 }}
                >
                  <div className="text-btn-secondary">Add Availability</div>
                </Link>
              </>
            }
            <Link
              style={{ cursor: "pointer" }}
              className="btn-primary"
              to="/seller/dashboard/service/addOns"
            >
              <div className="text-btn-primary">Include Add-Ons to Service</div>
            </Link>
            <Link to="/seller/dashboard/service/allServices" className="btn-secondary">
              <div className="text-btn-secondary">Go to All Services Page</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confirmation
