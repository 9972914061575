import React,{ useEffect, useRef } from "react";
import AIPost from "./AIPost";
import DefaultPost from './DefaultPost';
import Specifier from "./Specifier";

function AIContent(props) {

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    console.log("scrollToBottom")
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    console.log("useEffect")
    if(props.postLoading){
      scrollToBottom()
    }
    
  }, [props.prevGeneratedContent?.length, props.postLoading]);

  return (
    <div className="__main chat-main panel-center">
      <div className="main__chatbody" style={{flexDirection:"column"}}>
      <div className="back-holder" style={{display:"flex", flexDirection:"row", width:"130px"}} >
      <a style={{marginTop:"10px"}} 
      className="back-arrow odpag leftodpag w-inline-block ads-back"
      href="/seller/dashboard/ads"
      >
      </a>
      <div className="back-txt">Back</div>
      </div>

        <div className="main__chatcontent show-chat">

          <div className="content__body" style={{paddingTop:"0px"}} id="vertex_chat">
            <div className="chat__items">
              <div className="ai-results">
                {props.prevGeneratedContent?.map((post,index) => {
                  return(
                    <AIPost 
                    generatedContent={post?.text?.predictions[0]?.content}
                    type={post?.type}
                    initials={`${props.firstName?.charAt(0)}${props.lastName?.charAt(0)}`}
                    dateCreated={post?.dateCreated}
                    postLoading={props.postLoading}
                    showVideo={props.showVideo}
                    />
                    
                  )

                })}
                
                {props.prevGeneratedContent?.length==0?
                    <DefaultPost
                      storeDescription={props.storeDescription=="Personal Account, upgrade to convert it to business account"?
                      "We sell African inspired Organic Baby skincare product.":
                      props.storeDescription
                      }
                      
                      pickSuggestion={props.pickSuggestion}
                    />
                  :
                  null
                }

                {props.postLoading?
                <div style={{ position: "relative" }} className={`jss224`}>
                  <div className={`jss225 ${props.type!="ai"?"user-borders":""}`} />
                    <div className="jss233">
                      <div
                        role="textbox"
                        aria-multiline="true"
                        className="jss236 jss226 jss238"
                        data-testid="MarkedEditable"
                        data-slate-editor="true"
                        data-slate-node="value"
                        zindex={-1}
                        style={{
                          position: "relative",
                          outline: "none",
                          whiteSpace: "pre-wrap",
                          overflowWrap: "break-word",
                        }}
                      >

                      <div data-slate-node="element" style={{ position: "relative", display:"flex", flex: 1, flexDirection: "row"}}>
                        <div style={{ alignItems: "flex-start"}} className="holder-avatar ai-avatars">
                          <div style={{width:"40px", height:"40px",backgroundColor:"white",marginTop:"15px"}} className="user-avatar">
                            <img src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif" />
                          </div>
                        </div>

                        <div style={{marginTop:"30px"}} className="typing">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                :null}


              </div>
            </div>

            <div ref={messagesEndRef} />
          </div>

          <div className="content__footer">
            {props.showSpecifier?
            <Specifier 
              objective={props.objective}
              location={props.location}
              platform={props.platform}
              handleChange={props.handleChange}
              hideSpecifier={props.hideSpecifier}
            />
            :null}

            <div className="sendNewMessage">
              <form className="message-form" 
              onSubmit={props.generatePosts}
              data-hs-cf-bound="true"
              >
                <input
                  type="text"
                  placeholder={props.showSpecifier?"Enter your product name":"Ask me anything"}
                  value={props.userPrompt}
                  onChange={(e) => props.handleChange(e)}
                  name="userPrompt"
                />
                <button
                  style={{ width: "75px" }}
                  className="generate-btn"
                  id="sendMsgBtn"
                  type="submit"
                >
                  Generate
                </button>
              </form>
            </div>
            <p _ngcontent-ng-c2749521857="" class="gmat-caption ng-tns-c2749521857-1"> We may display inaccurate or offensive information that doesn’t represent Cashaam’s views.</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default AIContent;
