import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import LeftNav from "../../../common/LeftNav";
import CompletedOrders from "./CompletedOrders";
import FulfilledOrders from "./FulfilledOrders";
import Returned from "./Returned";
import Metatags from "react-meta-tags";
import fulfillOrder from "../../../helpers/fulfillOrder";
import getTransaction from "../../../helpers/getTransaction";
import createShippingLabel from "../../../helpers/createShippingLabel";
import getSellersInfo from "../../../helpers/getSellersInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import saveShippingLabel from "../../../helpers/saveShippingLabel";
import getShippingLabel from "../../../helpers/getShippingLabel";
import testShippo from "../../../helpers/testShippo";
import getScheduledPickup from "../../../helpers/getScheduledPickup";
import saveScheduledPickup from "../../../helpers/saveScheduledPickup";
import scheduleNigeriaPickup from "../../../helpers/scheduleNigeriaPickup";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getUserLocation from "../../../helpers/getUserLocation";
import getUnreadChat from "../../../helpers/getUnreadChat";
import TopHeader from "../../../common/TopHeader";
import RightNav from "../../../common/RightNav";
import OrderDetailsPop from "./OrderDetailsPop";
import PickupPop from "./PickupPop";
import ShippingLabelPop from "./ShippingLabelPop";
import ScheduleDelivery from "./ScheduleDelivery"
import BottomNav from "../../../common/BottomNav";
import getParcelFromTransaction from "../../../helpers/getParcelFromTransaction";
import DispatchedOrders from "./DispatchedOrders";
import getSellerDeliveryType from "../../../helpers/getSellerDeliveryType";
import getBuyerDeliveryType from "../../../helpers/getBuyerDeliveryType";
import getBasicInfo from "../../../helpers/getBasicInfo"
import NProgress from 'nprogress';
import ManualShippingPop from "./ManualShippingPop";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import getShippingOptions from "../../../helpers/getShippingOptions";
import ManualShippingOptions from "./ManualShippingOptions";
import formatDate from "../../../helpers/formatDate";
import ManualScheduleShip from "./ManualScheduleShip";
import OrderList from "./OrderList";
import TransactionDetails from "./TransactionDetails";
import ShippingInfo from "./ShippingInfo";
import EditDeliveryPopup from "./EditDeliveryPopup";
import priceFormater from "../../../helpers/priceFormater";
import SelfShippingPop from "./SelfShippingPop";
import saveSelfShipping from "../../../helpers/saveSelfShipping";
import updateAllOrders from "../../../helpers/updateAllOrders";
import DeliveryCarrierAccount from "../../../../secrets/DeliveryCarrierAccount.json";
import {NG_REQ_PICKUP_DELIVERY_URL, SCHEDULE_DELIVERY} from "../../../../secrets";
import NigerianAddresses from '../../../helpers/NigerianAddresses.json';
import updateOrderAddress from "../../../helpers/updateOrderAddress";
import DeliveryInfoPop from "./DeliveryInfoPop";

var today = new Date();
var date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
var time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var dateTime = date + " " + time;

const compare = (a, b) => {
  let aDate = new Date(formatDate(a.orderTimestamp));
  let bDate = new Date(formatDate(b.orderTimestamp));

  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }
  return 0;
};
export default class Orders extends Component {
  state = {
    sellerId: "",
    sellerName: "",
    storeName: "",
    firstName: "",
    lastName: "",
    sellerPhone: "",
    sellerEmail: "",
    orders: [],
    fullfilledOrders: [],
    dispatchedOrders: [],
    returnedOrders: [],
    viewCompletedOrders: true,
    viewDispatchedOrders: false,
    viewFulfilledOrders: false,
    viewReturned: false,
    viewConfirmSchedulePickup: false,
    transactionId: "",
    order: {},
    viewShippingLabelPop: false,
    viewOrderDetails: false,
    tracking_number: null,
    loading: false,
    showLeftNav: false,
    sellerPickup: null,
    sellerPickupAddress: null,
    buyerPickup: null,
    buyerPickupAddress: null,
    userCurrentCountry: null,
    errorPrintingShippingLabel: false,
    displayManualShipping: false,
    errorCreatingShippingLabel: false,
    address: "",
    apt: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    sellerAddress: "",
    sellerApt: "",
    sellerStreet: "",
    sellerCity: "",
    sellerState: "",
    sellerCountry: "",
    sellerZipcode: "",
    sellerLatitude: "",
    sellerLongitude: "",
    addressSelected: false,
    sellerAddressSelected: false,
    invalidAddress: false,
    invalidSellerAddress: false,
    productWeight: 0,
    productWidth: 0,
    productHeight: 0,
    productLength: 0,
    manualPrintingObject: {},
    prevParcel: {},
    displayShippingOptions: false,
    shippingOptions: [],
    selectedShippingObject: {},
    ShippingOptionSelected: false,
    errorCreatingShippingLabel: false,
    successfulReportSent: null,
    shippingLink: null,
    errorMessage: null,
    displayManualScheduleShip: false,
    printShippingLabelErrors: [],
    shippingProvider: null,
    displayTransactionDetails: false,
    currentTransaction:{},
    showSelfShippingPop: false,
    selfShipProviderName: "",
    selfShipPrice: 0,
    selfShipTrackNumber: "",
    selectedOrder: {},
    carrierAccounts: {},
    errorDisplayManualShipping: false,
    deliveryObj: {},
    dhlError:{}
  };

  getTransactionInfo =async (id, orderStatus) => {
    try {

      const db = firebase.firestore();
      const sellerUid = id;
      let Orders= await db.collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("orders")
      .where("orderStatus", "==", orderStatus)
      .orderBy("orderTimestamp", "asc")
      .get();
      const orderList = Orders.docs.map((order) => order.data());
      // console.log("orderList-", orderList);

      let transactionList=[];
      

        if(orderList.length>0){
          await db.collection("sellersAccounts")
            .doc(sellerUid)
            .collection("stores")
            .doc(sellerUid)
            .collection("transactions")
            .get()
            .then( async (transactionData) => {
              console.log("transactionData",transactionData)

              await transactionData.forEach((transaction,index) => {
                let transactionValue = transaction.data();

                // console.log("get transaction=", transactionValue);
                let orderIdList=transactionValue?.orderIds;
                // console.log("orderIdList",orderIdList);

                let relevantOrderList= orderList.filter(order => orderIdList.includes(order.orderId));
                // console.log("[Transaction ,Relevant OrderList]", [transactionValue, relevantOrderList]);
                //orderValues.transaction = relevantTransaction;

                if(relevantOrderList.length>0){
                  let order=relevantOrderList[0];
                  let customerName=order?.customerName;
                  let customerId=order?.customerId;
                  transactionValue.customerId=customerId;
                  transactionValue.customerName=customerName;

                  transactionValue.orderList=relevantOrderList;
                  transactionValue.orderTimestamp=transactionValue?.date;
                  let transactionObject=this.calculatePayment(transactionValue);

                  // console.log("transactionObject",transactionObject)
                  transactionValue.amount=parseFloat(transactionObject.transactionAmount);
                  transactionValue.transactionObject=transactionObject;

                  if(!transactionValue?.transactionId){
                    transactionValue.transactionId=transactionValue?.tId;
                  }

                  transactionList.push(transactionValue);
                }
                
              });


          });

          let sortedTransactionList= transactionList.sort(compare);
          
          return sortedTransactionList;
        }else{
          return [];
        }
      } catch (error) {
        console.log("error getting orders--", error);
        return [];
      }
  }

  getAllTransaction =async (id, orderStatus) => {
    try {

      const db = firebase.firestore();
      const sellerUid = id;
      let Orders= await db.collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("orders")
      .where("orderStatus", "!=", "pending")
      .get();
      const orderList = Orders.docs.map((order) => order.data());
      console.log("all orderList-", orderList);

      let transactionList=[];
      

        if(orderList.length>0){
          await db.collection("sellersAccounts")
            .doc(sellerUid)
            .collection("stores")
            .doc(sellerUid)
            .collection("transactions")
            .get()
            .then((transactionData) => {
              console.log("transactionData",transactionData)

              transactionData.forEach((transaction,index) => {
                let transactionValue = transaction.data();

                console.log("get transaction=", transactionValue);
                let orderIdList=transactionValue?.orderIds;
                console.log("orderIdList",orderIdList);

                let relevantOrderList= orderList.filter(order => orderIdList.includes(order.orderId));
                console.log("ALL [Transaction ,Relevant OrderList]", [transactionValue, relevantOrderList]);
                //orderValues.transaction = relevantTransaction;

                if(relevantOrderList.length>0){
                  let order=relevantOrderList[0];
                  let customerName=order?.customerName;
                  let customerId=order?.customerId;
                  transactionValue.customerId=customerId;
                  transactionValue.customerName=customerName;

                  transactionValue.orderList=relevantOrderList;
                  transactionValue.orderTimestamp=transactionValue?.date;
                  let transactionObject=this.calculatePayment(transactionValue);
                  console.log("transactionObject",{transactionObject,transactionValue});

                  transactionValue.amount=parseFloat(transactionObject.transactionAmount);
                  transactionValue.transactionObject=transactionObject;

                  if(!transactionValue?.transactionId){
                    transactionValue.transactionId=transactionValue?.tId;
                  }

                  transactionList.push(transactionValue);
                }
                
              });

          });

          let sortedTransactionList= transactionList.sort(compare);
          
          return sortedTransactionList;
        }else{
          return [];
        }
      } catch (error) {
        console.log("error getting orders--", error);
        return [];
      }
  }

  estimateTransactionFormat =(currentTransaction)=>{

    try{

    let transactionAmount, shippingPrice, applicationFeeAmount, taxes, paymentStatus;

    let date=currentTransaction?.date;
    let dateArray = date.split(" ");
    let dateComponents = dateArray[0];
    let timeComponents = dateArray[1];
    dateComponents = dateComponents.split("-");
    timeComponents = timeComponents.split(":");
    let year = parseFloat(dateComponents[0]);
    let month = parseFloat(dateComponents[1]);

    let current=currentTransaction?.currency;
    let savedTotal=currentTransaction?.amount

    if((year<=2021 && month<=11) && 
    ((current?.toLowerCase()=="usd" && savedTotal>200) 
      || (current?.toLowerCase()=="ngn" && savedTotal>80000))){

      console.log("date=>",{month,year});
      transactionAmount=parseFloat(currentTransaction?.amount);
      shippingPrice=currentTransaction?.shippingPrice;
      applicationFeeAmount=currentTransaction?.applicationFeeAmount;
      taxes=currentTransaction?.taxes;

      transactionAmount=parseFloat(transactionAmount)/100;

      if(shippingPrice){
        shippingPrice=parseFloat(shippingPrice)/100;
      }else{
        shippingPrice=0;
      }
      
      if(applicationFeeAmount){
        applicationFeeAmount=parseFloat(applicationFeeAmount)/100;
      }else{
        applicationFeeAmount=0.03*transactionAmount;
      }
      
      if(taxes){
        taxes=parseFloat(taxes)/100;
      }else{
        taxes=0;
      }
      
    }else{
      transactionAmount=parseFloat(currentTransaction?.amount);
      shippingPrice=currentTransaction?.shippingPrice;
      applicationFeeAmount=currentTransaction?.applicationFeeAmount;
      taxes=currentTransaction?.taxes;

      if(applicationFeeAmount){
        applicationFeeAmount=parseFloat(applicationFeeAmount);
      }else{
        applicationFeeAmount=0.03*transactionAmount;
      }

      if(shippingPrice){
        shippingPrice=parseFloat(shippingPrice);
      }else{
        shippingPrice=0;
      }

      if(taxes){
        taxes=parseFloat(taxes);
      }else{
        taxes=0;
      }

    }

    paymentStatus=currentTransaction?.status;
    let transactionId=currentTransaction?.transactionId;

    let estimateDone=true;
    return{ transactionAmount, shippingPrice, applicationFeeAmount, taxes, paymentStatus,estimateDone};

  }catch(err){

    console.log("error estimating",err)
    let transactionAmount=parseFloat(currentTransaction?.amount);
    let shippingPrice=parseFloat(currentTransaction?.shippingPrice);
    let applicationFeeAmount=parseFloat(currentTransaction?.applicationFeeAmount);
    let taxes=currentTransaction?.taxes?currentTransaction.taxes:0;
    let paymentStatus=currentTransaction?.status;
    let estimateDone=true;

    return{ transactionAmount, shippingPrice, applicationFeeAmount, taxes, paymentStatus,estimateDone};
    
  }
    
  }

  calculatePayment = (currentTransaction)=>{

    let transactionAmount,transactionCurrency,shippingPrice,applicationFeeAmount,taxes, paymentStatus,paymentMethod, taxAndFee, sellerPayout;
    try{

      let estimate={};
      estimate.estimateDone=false;
      paymentMethod = currentTransaction?.paymentMethod;
      paymentMethod=paymentMethod.toLowerCase();

      transactionCurrency=currentTransaction?.currency;

      if(paymentMethod==="paystack" || paymentMethod==="flutterwave" || paymentMethod==="paystack.com"){

        estimate=this.estimateTransactionFormat(currentTransaction);


      }else if(paymentMethod==="stripe"){

        //Stripe paypalMetadata is always in cents
        let stripeMetadata=currentTransaction?.paypalMetadata;
        transactionAmount=stripeMetadata?.amount;

        transactionAmount=parseFloat(transactionAmount)/100;

        let cashaamSavedAmount=parseFloat(currentTransaction?.amount);
        cashaamSavedAmount=Math.round(cashaamSavedAmount);
        //console.log("[cashaamSavedAmount, metadataAmount]",[cashaamSavedAmount,transactionAmount]);
        //Check for data intergrity (cents or dollar), paypalMetadata is the true value
        if(transactionAmount==(cashaamSavedAmount/100)){
          //Amount is in cents, convert to ONES
          shippingPrice=currentTransaction?.shippingPrice;
          applicationFeeAmount=currentTransaction?.applicationFeeAmount;
          taxes=currentTransaction?.taxes;

          if(shippingPrice){
            shippingPrice=parseFloat(shippingPrice)/100;
          }else{
            shippingPrice=0;
          }

          if(applicationFeeAmount){
            applicationFeeAmount=parseFloat(applicationFeeAmount)/100;
          }else{
            applicationFeeAmount=0.03*transactionAmount;
          }

          if(taxes){
            taxes=parseFloat(taxes)/100;
          }else{
            taxes=0;
          }

        }else {
         estimate=this.estimateTransactionFormat(currentTransaction);

        }

        paymentStatus=stripeMetadata?.status;



      }else if(paymentMethod==="paypal"){
        //Stripe paypalMetadata is always in ONES
        let paypalMetadata=currentTransaction?.paypalMetadata;

        let paymentInfo=paypalMetadata?.details?.purchase_units;

        //console.log("paypal purchased_units",paymentInfo);


        if(paymentInfo){
          let purchaseInfo=paymentInfo[0].amount;
          paymentStatus=paymentInfo?.status;
          transactionAmount=purchaseInfo?.value;
          transactionAmount=parseFloat(transactionAmount);

          let cashaamSavedAmount=parseFloat(currentTransaction?.amount);
          cashaamSavedAmount=Math.round(cashaamSavedAmount);
          //console.log("[cashaamSavedAmount, metadataAmount]",[cashaamSavedAmount,transactionAmount]);
          //Check for data intergrity (cents or dollar), paypalMetadata is the true value
          if(transactionAmount==(cashaamSavedAmount/100)){
            //Amount is in cents
            shippingPrice=currentTransaction?.shippingPrice;
            applicationFeeAmount=currentTransaction?.applicationFeeAmount;
            taxes=currentTransaction?.taxes;

            if(shippingPrice){
              shippingPrice=parseFloat(shippingPrice)/100;
            }else{
              shippingPrice=0;
            }
  
            if(applicationFeeAmount){
              applicationFeeAmount=parseFloat(applicationFeeAmount)/100;
            }else{
              applicationFeeAmount=0.03*transactionAmount;
            }
  
            if(taxes){
              taxes=parseFloat(taxes)/100;
            }else{
              taxes=0;
            }

          }else {
            
            estimate=this.estimateTransactionFormat(currentTransaction);
          }


        }else{

          estimate=this.estimateTransactionFormat(currentTransaction);

        }


      }else{
        estimate=this.estimateTransactionFormat(currentTransaction);
        paymentMethod="Cashaam";


      }
      
      if(estimate?.estimateDone==true){

        //console.log("estimate",estimate);
        transactionAmount=parseFloat(estimate?.transactionAmount);
        shippingPrice=parseFloat(estimate?.shippingPrice);
        applicationFeeAmount=parseFloat(estimate?.applicationFeeAmount);
        taxes=parseFloat(estimate?.taxes);
        paymentStatus=estimate?.paymentStatus;

      }

      if(!taxes){
        taxes=0;
      }

      if(!applicationFeeAmount){
        applicationFeeAmount=0.03*transactionAmount;
      }
      
      taxAndFee=applicationFeeAmount;
      let sellerPayout=0;

      let payoutCurrency=currentTransaction?.currency;
      let payout=currentTransaction?.payout;
      let payoutStatus="";
      if(payout && payout?.amount && parseFloat(payout?.amount)>0){
        sellerPayout=parseFloat(payout?.amount);
        payoutStatus=payout?.status;

        if(currentTransaction?.currency?.toLowerCase()!="ngn"){
          payoutCurrency="USD";
        }else{
          payoutCurrency="NGN";
        }
        
      }else{
        sellerPayout=transactionAmount-(taxAndFee+shippingPrice);
      }

      transactionAmount=parseFloat(transactionAmount).toFixed(2);
      applicationFeeAmount=parseFloat(applicationFeeAmount).toFixed(2);
      taxes=parseFloat(taxes).toFixed(2);
      shippingPrice=parseFloat(shippingPrice).toFixed(2);
      taxAndFee=parseFloat(taxAndFee).toFixed(2);
      sellerPayout=parseFloat(sellerPayout).toFixed(2);

      //console.log("calculated Payment",applicationFeeAmount);

      let transactionObject={
        transactionAmount,
        applicationFeeAmount,
        taxes,
        shippingPrice,
        transactionCurrency,
        paymentStatus,
        taxAndFee,
        paymentMethod,
        sellerPayout,
        payoutStatus,
        payoutCurrency
      };


      return transactionObject;

    }catch(error){
      console.log("error calculating Transaction",error);
      transactionAmount=parseFloat(currentTransaction?.amount);
      shippingPrice=parseFloat(currentTransaction?.shippingPrice);
      applicationFeeAmount=parseFloat(currentTransaction?.applicationFeeAmount);
      taxes=currentTransaction?.taxes?currentTransaction.taxes:0;
      paymentStatus=currentTransaction?.status;
      transactionCurrency=currentTransaction?.currency;
      paymentMethod = currentTransaction?.paymentMethod;
      taxAndFee=applicationFeeAmount;

      let sellerPayout=0;
      let payoutCurrency=currentTransaction?.currency;
      let payout=currentTransaction?.payout;
      let payoutStatus="";
      if(payout && payout?.amount && parseFloat(payout?.amount)>0){
        sellerPayout=parseFloat(payout?.amount);
        payoutStatus=payout?.status;

        if(currentTransaction?.currency?.toLowerCase()!="ngn"){
          payoutCurrency="USD";
        }else{
          payoutCurrency="NGN";
        }
        
      }else{
        sellerPayout=transactionAmount-(taxAndFee+shippingPrice);
      }

      let transactionObject={
        transactionAmount,
        applicationFeeAmount,
        taxes,
        shippingPrice,
        transactionCurrency,
        paymentStatus,
        taxAndFee,
        paymentMethod,
        sellerPayout,
        payoutStatus,
        payoutCurrency
      };
      return transactionObject;
    }
  }


  getOrders = async (id) => {
    try {
      const db = firebase.firestore();
      const sellerUid = id;
      const transactions = await db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("transactions").get();
      const transactionList = transactions.docs.map((transaction) => transaction.data());
      console.log("transactions-", transactionList);
      let orderList = [];
      return db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("orders")
        .where("orderStatus", "==", "completed")
        .orderBy("orderTimestamp", "desc")
        .get()
        .then((ordersData) => {
          ordersData.forEach((order) => {
            console.log({ order });
            let orderValues = order.data();
            const relevantTransaction = transactionList.filter(transaction => transaction.orderIds.includes(orderValues.orderId));
            console.log("[Order,Relevant Transaction]", [orderValues, relevantTransaction]);
            orderValues.transaction = relevantTransaction;
            console.log("order val transaction", orderValues.transaction);
            orderValues.productCost =
              parseFloat(orderValues.orderQuantity) *
              parseFloat(
                orderValues.discountedPrice
                  ? orderValues.discountedPrice
                  : orderValues.productPrice
              );
            //Convert undefined shipping Prices to 0
            orderValues.deliveryFee = orderValues.transaction ?
              orderValues.transaction.shippingPrice ?
                parseFloat(orderValues.transaction.shippingPrice) : 0 : 0;
            //Calculate Cashaam fee of 3%
            orderValues.cashaamFee = parseFloat(
              (orderValues.deliveryFee + orderValues.productCost)
              * .03);
            console.log(orderValues.orderId, " => ", orderValues);
            orderList.push(orderValues);
          });

          //const orderList = ordersData.docs.map((order) => order.data());
          let sortedOrderList = orderList.sort(compare);
          
          this.setState({ orders: sortedOrderList });
        });
    } catch (error) {
      console.log("error getting orders--", error);
    }
  };

  getDispatchedOrders = async (id) => {
    try {
      const db = firebase.firestore();
      const sellerUid = id;
      return db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("orders")
        .where("orderStatus", "==", "dispatched")
        .orderBy("orderTimestamp", "desc")
        .get()
        .then((ordersData) => {
          const orderList = ordersData.docs.map((order) => order.data());
          let sortedOrderList = orderList.sort(compare);
          
          this.setState({ dispatchedOrders: sortedOrderList });
        });
    } catch (error) {
      console.log("error getting orders--", error);
    }
  };

  getFullfilledOrders = async (id) => {
    try {
      const db = firebase.firestore();
      const sellerUid = id;
      return db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("orders")
        .where("orderStatus", "==", "fulfilled")
        .orderBy("orderTimestamp", "desc")
        .get()
        .then((ordersData) => {
          const orderList = ordersData.docs.map((order) => order.data());
          let sortedOrderList = orderList.sort(compare);
          
          this.setState({ fullfilledOrders: sortedOrderList });
        });
    } catch (error) {
      console.log("error getting orders--", error);
    }
  };

  getPendingOrders = async (id) => {
    try {
      const db = firebase.firestore();
      const sellerUid = id;
      return db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("orders")
        .where("orderStatus", "==", "pending")
        .orderBy("orderTimestamp", "desc")
        .get()
        .then((ordersData) => {
          const orderList = ordersData.docs.map((order) => order.data());
          let sortedOrderList = orderList.sort(compare);
          

          return sortedOrderList;
        });
    } catch (error) {
      console.log("error getting orders--", error);
      return [];
    }
  };

  getReturnedOrders = async (id) => {
    try {
      const db = firebase.firestore();
      const sellerUid = id;
      db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("orders")
        .where("orderStatus", "==", "returned")
        .orderBy("orderTimestamp", "desc")
        .get()
        .then((ordersData) => {
          const orderList = ordersData.docs.map((order) => order.data());
          let sortedOrderList = orderList.sort(compare);
          
          this.setState({ returnedOrders: sortedOrderList });
        });
    } catch (error) {
      console.log("error getting orders--", error);
    }
  };

  displayCompletedOrders = () => {
    this.setState({
      viewCompletedOrders: true,
      viewFulfilledOrders: false,
      viewReturned: false,
      viewDispatchedOrders: false,
    });
  };

  displayFulfilledOrders = () => {
    this.setState({
      viewFulfilledOrders: true,
      viewCompletedOrders: false,
      viewReturned: false,
      viewDispatchedOrders: false,
    });
  };

  displayReturnedOrders = () => {
    this.setState({
      viewReturned: true,
      viewCompletedOrders: false,
      viewFulfilledOrders: false,
      viewDispatchedOrders: false,
    });
  };

  displayDispatchedOrders = () => {
    this.setState({
      viewDispatchedOrders: true,
      viewReturned: false,
      viewCompletedOrders: false,
      viewFulfilledOrders: false,
    });
  };

  onViewProduct = (id) => {
    this.props.history.push(`/seller/dashboard/products/${id}`);
  };

  markAsFullFilled = async (orderId, customerId) => {
    try {
      const { sellerId } = this.state;
      const result = await fulfillOrder(sellerId, customerId, orderId);
      if (result === true) {
        this.displayDetails(false);
        this.hideShippingLabel();
        this.showManualScheduleShip(false);
        this.onDisplayShippingOptions(false);
        this.cancelSchedulePickup()
        this.componentDidMount();
      } else {
        console.log("an error occured while marking order as fulfilled");
        this.displayDetails(false);
        this.hideShippingLabel();
        this.showManualScheduleShip(false);
        this.onDisplayShippingOptions(false);
        this.cancelSchedulePickup()
        this.componentDidMount();
      }
    } catch (error) {
      console.log("error marking as fulfilled", error);
      this.displayDetails(false);
      this.hideShippingLabel();
      this.showManualScheduleShip(false);
      this.onDisplayShippingOptions(false);
      this.cancelSchedulePickup()
      this.componentDidMount();
    }
  };

  markAsShipped = async (currentTransaction, customerId)=>{

    try {
      const { sellerId} = this.state;
      console.log("currentTransaction",currentTransaction);

      let {shippingCarrierAccount,orderIds,orderList}=currentTransaction;
      shippingCarrierAccount=shippingCarrierAccount?.toLowerCase();
      if(shippingCarrierAccount==="pickup"||shippingCarrierAccount==="customdelivery"||shippingCarrierAccount==="undefined"){

        this.onShowSelfShippingPop(orderList);

      }else{
      
        const result=await updateAllOrders(orderIds, sellerId,customerId,"dispatched");

        if (result === true) {
          console.log("All orders dispatched");
          this.displayDetails(false);
          this.hideShippingLabel();
          this.showManualScheduleShip(false);
          this.onDisplayShippingOptions(false);
          this.cancelSchedulePickup();
          this.backToTransactions();
          this.componentDidMount();
        } else {
          console.log("an error occured while marking order as fulfilled");
          this.displayDetails(false);
          this.hideShippingLabel();
          this.showManualScheduleShip(false);
          this.onDisplayShippingOptions(false);
          this.cancelSchedulePickup();
        }
    }
    } catch (error) {
      console.log("error marking as fulfilled", error);
      this.displayDetails(false);
      this.hideShippingLabel();
      this.showManualScheduleShip(false);
      this.onDisplayShippingOptions(false);
      this.cancelSchedulePickup()
      this.componentDidMount();
    }


  }

  markAsDelivered = async (currentTransaction, customerId)=>{

    try{

      const { sellerId} = this.state;
      let {shippingCarrierAccount,orderIds,orderList}=currentTransaction;
      const result=await updateAllOrders(orderIds, sellerId,customerId,"fulfilled");
      this.backToTransactions();
      this.componentDidMount();
    }catch(error){
      console.log("error marking as delivered", error);
    }
    
  }

  checkShippingLabel = (orderList)=>{

    let labelLink=null;
    orderList.forEach((order)=>{

      let { shippingLabelLink} =order;

      if (shippingLabelLink) {
        console.log("found shipping label", shippingLabelLink);
        labelLink=shippingLabelLink;
        return;
      }
    });

    return labelLink;

  }

  callShippingLabelApi=async(currentTransaction,addressFrom, addressTo, order,sellerInfo, parcel)=>{

    let { shippingCarrierAccount, shippingServiceLevelToken, transactionId } = currentTransaction;
  
    let {
      deliveryPhoneNumber,
      email
    } = order;

        let buyerApt=addressTo?.apt;
        let buyerStreet=addressTo?.street;
        let buyerCity=addressTo?.city;
        let buyerState=addressTo?.state;
        let buyerCountry=addressTo?.country;
        let buyerZipcode=addressTo?.zipcode;
        let buyerName=addressTo?.customerName;



        let{
          sellerName,
          storeName,
          sellerStreet,
          sellerCity,
          sellerState,
          sellerZipcode,
          sellerCountry,
          sellerPhone,
          sellerEmail
        }=addressFrom;

        let {
          customerName,
          apt, 
          street, 
          city, 
          state,
          zipcode,
          country
        }=addressTo;


        
        this.setState({
          manualPrintingObject: {
            sellerName, storeName, sellerStreet, sellerCity, sellerState, sellerZipcode, sellerCountry, sellerPhone, sellerEmail,
            customerName, apt, street, city, state, zipcode, country, parcel, shippingCarrierAccount, shippingServiceLevelToken,
            orderIds: currentTransaction.orderIds, order, transactionId, transactionData: currentTransaction
          },
          productWeight: parseFloat(parcel.weight * 16),
          productHeight: parcel.height,
          productWidth: parcel.width,
          productLength: parcel.length,
          sellerInfo
        })
        console.log("addressFrom",addressFrom);
        console.log("addressTo",addressTo);

        const queryData = {
          "carrier_account": shippingCarrierAccount,
          "servicelevel_token": shippingServiceLevelToken,
          "addressFrom": sellerStreet,
          "addressTo": buyerStreet,
          "sellerCity": sellerCity,
          "buyerCity": buyerCity,
          "buyerEmail": email,
          "sellerEmail": sellerEmail,
          "buyerPhoneNumber": deliveryPhoneNumber,
          "sellerPhoneNumber": sellerPhone,
          "buyerName": buyerName,
          "sellerName": sellerName,
          "buyerLocation": {
              "Latitude": "6.6027022",
              "Longitude": "3.3168825"
          },
          "sellerLocation": {
              "Latitude": "6.5324201",
              "Longitude": "3.3499156"
          },
          "buyerState": buyerState,
          "sellerState": sellerState,
          "buyerCountry": buyerCountry,
          "sellerCountry": sellerCountry,
          "buyerZipcode": buyerZipcode,
          "sellerZipcode": sellerZipcode,
          parcels:[parcel]
        };

        console.log("queryData", queryData);
        const res = await fetch(NG_REQ_PICKUP_DELIVERY_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            countrycode: "ng",
          },
          body: JSON.stringify(queryData),
        });

  
        if (res.status === 200) {
          const result = await res.json();
  
          console.log("api result",result);

          if(result?.object_state==="VALID" && result?.status ==="SUCCESS"){
            //let scheduleResult = result.status ? result : result[0]; //handle result being returned as an array
    
            return result;
  
          } else {
            
            console.log("Shipping Label Invalid: ",result);
            return false;
          }
          
        } else {
          const result = await res.json();
          console.log("error schedule getting shipping label",result);

          return false
          
        }



  }

  openLink=()=>{

    let {shippingLink, labelObject}=this.state;
    labelObject=this.state.labelObject? 
      this.state.labelObject
      :this.state.currentTransaction?.orderList[0]?.labelObject;

      shippingLink=this.state.shippingLink?
      this.state.shippingLink : this.state.currentTransaction?.orderList[0]?.shippingLabelLink;

    if(labelObject?.content){

      console.log("labelObject",labelObject);
      var byteCharacters = atob(labelObject?.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return;
    }
    window.open(shippingLink,"Cashaam");
  }

  showShippingInstructions=()=>{
    this.setState({
      displayShippingInstructions: true,
      displaySchedule: false,
      displayEditShipping: false,
    })
  }
  hideShippingInstructions=()=>{
    this.setState({
      displaySchedule: false,
      displayShippingInstructions: false
    })
  }

  onEditShipping=(status)=>{
    this.setState({
      displayEditShipping: status,
      displaySchedule: false,
      displayShippingInstructions: false
    })
  }

  showDeliveryInfo=()=>{
    this.setState({
      displayDeliveryInfo: true,
    })
  }

  hideDeliveryInfo=()=>{
    this.setState({
      displayDeliveryInfo: false,
    })
  }

  changeSellerAddress = (address) => {

    let deliveryObj=this.state.deliveryObj;
    if (this.state.sellerAddressSelected === true) {
      this.setState({ sellerAddressSelected: false });
    }
    deliveryObj["sellerStreet"]=address;
    this.setState({ deliveryObj });
  };

  changeBuyerAddress = (address) => {

    let deliveryObj=this.state.deliveryObj;
    if (this.state.addressSelected === true) {
      this.setState({ addressSelected: false });
    }
    deliveryObj["street"]=address;
    this.setState({ deliveryObj });
  };

  handleDeliveryChange=(e)=>{

    let deliveryObj=this.state.deliveryObj;
    deliveryObj[e.target.name]=e.target.value;

    this.setState({
      deliveryObj
    })

  }

  selectSellerAddress = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ addressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;

        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();

          let deliveryObj=this.state.deliveryObj;
          deliveryObj["sellerStreet"]= street;
          deliveryObj["sellerCity"]=city !== null ? city : "N/A";
          deliveryObj["sellerState"]=state !== null ? state : "N/A";
          deliveryObj["sellerCountry"]=country !== null ? country : "N/A";
          deliveryObj["sellerZipcode"]=zipcode !== null ? zipcode : "N/A";

          resolve(
            this.setState({
              deliveryObj
            })
          );
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.showInvalidAddress();
        if (error === "ZERO_RESULTS") {
          return toast.error(
            "this address is invalid. Please check and try again"
          );
        }
        reject(error);
      }
    });
  };

  selectBuyerAddress = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ addressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;

        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();

          let deliveryObj=this.state.deliveryObj;
          deliveryObj["street"]= street;
          deliveryObj["city"]=city !== null ? city : "N/A";
          deliveryObj["state"]=state !== null ? state : "N/A";
          deliveryObj["country"]=country !== null ? country : "N/A";
          deliveryObj["zipcode"]=zipcode !== null ? zipcode : "N/A";

          resolve(
            this.setState({
              deliveryObj
            })
          );
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.showInvalidAddress();
        if (error === "ZERO_RESULTS") {
          return toast.error(
            "this address is invalid. Please check and try again"
          );
        }
        reject(error);
      }
    });
  };


  hideSchedule=()=>{
    this.setState({
      displaySchedule: false
    })
  }
  showScheduleDelivery=()=>{

    if(this.state.labelObject?.content){

      let{labelObject}=this.state;
      var byteCharacters = atob(labelObject?.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);

    }else{
      this.setState({
        displayEditShipping: true,
        displaySchedule: false,
        viewShippingLabelPop: false,
        displayManualShipping: false
      })
    }
    
  }

  updateSellerAddress= async()=>{
    try{

      const sellerUid = this.state.sellerId;
      console.log(sellerUid)
      const db = firebase.firestore();
      let batch = db.batch();

      let deliveryObj=this.state.deliveryObj;
      let{ 
        sellerStreet,
        sellerCity,
        sellerState,
        sellerCountry,
        sellerZipcode,
        sellerApt,
      }=deliveryObj;

      console.log("update address to: ",{
        sellerStreet,
        sellerCity,
        sellerState,
        sellerCountry,
        sellerZipcode,
        sellerApt,
      })

      var sellersAccountsRef = db
      .collection("sellersAccounts")
      .doc(sellerUid);
      
      batch.set(
        sellersAccountsRef, 
        {
          address: sellerStreet,
          street: sellerStreet,
          city: sellerCity,
          state: sellerState,
          country: sellerCountry,
          zipcode: sellerZipcode,
          apt: sellerApt
        },
        { merge: true }
      );


      var docRef = db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid);

      batch.set(
        docRef,
        {
          address: sellerStreet,
          street: sellerStreet,
          city: sellerCity,
          state: sellerState,
          country: sellerCountry,
          zipcode: sellerZipcode,
          apt: sellerApt
        },
        { merge: true }
      );


      await batch.commit();


    }catch(err){
      console.log("update seller address error:",err);
    }
  }

  submitEditedSchedule =async (e)=>{

    e.preventDefault();
    try{
      this.setState({ 
        scheduleLoading: true, 
        sellerStreetError:"",
        buyerStreetError:"",
        addressesError:"",
        buyerPostCodeError:false,
        sellerPostCodeError:false
      })
      let{sellerId, deliveryObj, currentTransaction}=this.state;
      let{orderIds, customerId}=currentTransaction;
      let{street,
        city,
        state,
        country,
        zipcode,
        apt
      }=deliveryObj

      deliveryObj.sellerStreet=deliveryObj.sellerStreet?.trim();
      deliveryObj.sellerCity=deliveryObj.sellerCity?.trim();
      deliveryObj.sellerState=deliveryObj.sellerState?.trim();
      deliveryObj.sellerCountry=deliveryObj.sellerCountry?.trim();
      deliveryObj.sellerZipcode=deliveryObj.sellerZipcode?.trim();
      deliveryObj.sellerApt=deliveryObj.sellerApt?.trim();

      deliveryObj.street=deliveryObj.street?.trim();
      deliveryObj.city=deliveryObj.city?.trim();
      deliveryObj.state=deliveryObj.state?.trim();
      deliveryObj.country=deliveryObj.country?.trim();
      deliveryObj.zipcode=deliveryObj.zipcode?.trim();
      deliveryObj.apt=deliveryObj.apt?.trim();


      if(!deliveryObj?.sellerStreet){
        this.setState({
          addressesError: "Your street address is missing",
          errorSchedulingDelivery: true ,
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }
  
      if(deliveryObj?.sellerStreet?.length>44){
        this.setState({
          addressesError: "Your street address cannot be more than 45 characters",
          errorSchedulingDelivery: true ,
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }
      
      if(deliveryObj?.sellerApt?.length>10){
        this.setState({
          addressesError: "Your apt cannot be more than 10 characters",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.sellerCity?.length<1){
        this.setState({
          addressesError: "Your city is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.sellerState?.length<1){
        this.setState({
          addressesError: "Your state is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.sellerCountry?.length<1){
        this.setState({
          addressesError: "Your country is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.sellerCountry=="United Kingdom" && deliveryObj?.sellerZipcode){

        let postcode = deliveryObj?.sellerZipcode?.replace(/\s/g, "");
        let regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
        let validPostCode= regex.test(postcode);
        console.log("validPostCode",validPostCode);

        if(!validPostCode){
          this.setState({
            addressesError: "Invalid Zipcode format. The UK Postcode/Zipcode consists of 2 parts separated by a space. (e.g PO1 3AX, CF47 0HW, GU16 7HF).",
            shippingLink: null,
            scheduleLoading: false,
            sellerPostCodeError:true
          });
          return;
        }
      }
      

      if(!deliveryObj?.street){
        this.setState({
          addressesError: "The buyer's street address is missing",
          errorSchedulingDelivery: true ,
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }
      
      if(deliveryObj?.street?.length>45){
        this.setState({
          addressesError: "The buyer's street address cannot be more than 45 characters",
          errorSchedulingDelivery: true ,
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }
  
      if(deliveryObj?.apt?.length>10){
        this.setState({
          addressesError: "The buyer's apt cannot be more than 10 characters",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.city?.length<1){
        this.setState({
          addressesError: "The buyer's city is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.state?.length<1){
        this.setState({
          addressesError: "The buyer's state is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.country?.length<1){
        this.setState({
          addressesError: "The buyer's country is missing",
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }

      if(deliveryObj?.country=="United Kingdom" && deliveryObj?.zipcode){

        let postcode = deliveryObj?.zipcode?.replace(/\s/g, "");
        let regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
        let validPostCode= regex.test(postcode);
        console.log("validPostCode",validPostCode);

        if(!validPostCode){
          this.setState({
            addressesError: "Invalid Zipcode format. The UK Postcode/Zipcode consists of 2 parts separated by a space. (e.g PO1 3AX, CF47 0HW, GU16 7HF)",
            shippingLink: null,
            scheduleLoading: false,
            buyerPostCodeError:true,
          });
          return;
        }
      }

      // let status=await this.callScheduleApi(deliveryObj);


      // if(status==false){

      //   console.log("Failed scheduling delivery");
      //   let order=this.state.currentTransaction?.orderList[0];
      //   this.reportUnsuccessfullDHLSchedule();
      // }else{

      //   await this.updateSellerAddress();
      //   await updateOrderAddress(
      //     orderIds,
      //     sellerId,
      //     customerId,
      //     street,
      //     city,
      //     state,
      //     country,
      //     zipcode,
      //     apt)
      // }
      
      this.setState({ scheduleLoading: false, 
        displaySchedule: true,
        displayEditShipping: false
       })

    }catch(e){

      console.log("scheduleDelivery error",err);
      this.displayLoading(false);
        
      this.setState({ 
        errorSchedulingDelivery: true ,
        shippingLink: null,
        schedulingError: "There has been an error with scheduling for delivery. Please try again." ,
        scheduleLoading: false,
        buyerPostCodeError:false,
        sellerPostCodeError:false
      })

    }
  }

  handleChangedDate=(month, day, year, dateObj)=>{

    let {deliveryObj}=this.state;
    deliveryObj["year"]=`${year}`;
    deliveryObj["month"]=`${month}`;
    deliveryObj["day"]=`${day}`;

    this.setState({deliveryObj, dateObj})

  
  }

  handleOverWeight =(totalWeight)=>{

    this.setState({
      overWeight: true,
      totalOrderWeight: totalWeight,
    })

  }
  scheduleDelivery =async (month, day, year)=>{

    try{
        
      this.setState({ 
        errorSchedulingDelivery: false ,
        shippingLink: null,
        schedulingError: "" ,
        scheduleLoading: true,
        timeError:"",
        sellerStreetError:"",
        buyerStreetError:"",
        dateError:""
      })

      console.log("calendar",{month, day, year});
      let{scheduleTime,sellerId, storeInfo,deliveryObj, currentTransaction}=this.state;
      let{orderIds, customerId}=currentTransaction;
      let{street,
        city,
        state,
        country,
        zipcode,
        apt
      }=deliveryObj;


      let time=scheduleTime?.split(":");

      if(!scheduleTime){
        this.setState({
          timeError:"Enter the time for the scheduled shipping",
          errorSchedulingDelivery: true ,
          shippingLink: null,
          scheduleLoading: false
        });
        return;
      }


      deliveryObj["year"]=`${year}`;
      deliveryObj["month"]=`${month}`;
      deliveryObj["day"]=`${day}`;
      deliveryObj["hour"]=`${time[0]}`;
      deliveryObj["minute"]=`${time[1]}`;

      // if(!deliveryObj.year || isNaN(parseFloat(deliveryObj.year)) || 
      //     !deliveryObj.month || isNaN(parseFloat(deliveryObj.month)) || 
      //     !deliveryObj.day || isNaN(parseFloat(deliveryObj.day)) 
      // ){
      //   this.setState({
      //     dateError: "Please enter the date for scheduling delivery",
      //     errorSchedulingDelivery: true ,
      //     shippingLink: null,
      //     scheduleLoading: false
      //   });
      //   return;
        
      // }

      deliveryObj.sellerStreet=deliveryObj.sellerStreet?.trim();
      deliveryObj.sellerCity=deliveryObj.sellerCity?.trim();
      deliveryObj.sellerState=deliveryObj.sellerState?.trim();
      deliveryObj.sellerCountry=deliveryObj.sellerCountry?.trim();
      deliveryObj.sellerZipcode=deliveryObj.sellerZipcode?.trim();
      deliveryObj.sellerApt=deliveryObj.sellerApt?.trim();

      deliveryObj.street=deliveryObj.street?.trim();
      deliveryObj.city=deliveryObj.city?.trim();
      deliveryObj.state=deliveryObj.state?.trim();
      deliveryObj.country=deliveryObj.country?.trim();
      deliveryObj.zipcode=deliveryObj.zipcode?.trim();
      deliveryObj.apt=deliveryObj.apt?.trim();
      
      let status=await this.callScheduleApi(deliveryObj);

      if(status==false){

        // console.log("Failed scheduling delivery");
        // let order=this.state.currentTransaction?.orderList[0];
        // this.reportUnsuccessfullDHLSchedule();
      }else{

        await this.updateSellerAddress();
        await updateOrderAddress(
          orderIds,
          sellerId,
          customerId,
          street,
          city,
          state,
          country,
          zipcode,
          apt)
      }


      

    }catch(err){
      console.log("scheduleDelivery error",err);
      this.displayLoading(false);
        
      this.setState({ 
        errorSchedulingDelivery: true ,
        shippingLink: null,
        schedulingError: "There has been an error with scheduling for delivery. Please try again." ,
        scheduleLoading: false
      })
      
    }

  }

  callScheduleApi =async(query)=>{

    try{

      console.log("schedule delivery query",query);
      let res = await fetch(SCHEDULE_DELIVERY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(query),
      });

      // console.log("res",await res.text());
      const responce = await res.json();

      console.log("responce",responce);

      if (res.status === 200) {

        let{
          packages,
          trackingUrl,
          documents,
          dispatchConfirmationNumber
        }=responce;

        let{trackingNumber}=packages[0];

        let currentTransaction=this.state.currentTransaction;
        let orderList=currentTransaction.orderList;
        let customerId,sellerId,transactionId;
        if (orderList.length > 0) {
          let order=orderList[0];
          customerId=order?.customerId;
          sellerId=order?.sellerId;
          transactionId=order?.transactionId;
        }

        let orderIds=currentTransaction.orderIds;

        let label_url="";
        let tracking_number=trackingNumber;
        let labelObject=documents[0];
        let tracking_url_provider="DHL"
        console.log("saveShippingLabel", {
          sellerId,
          orderIds,
          customerId,
          transactionId,
          label_url,
          tracking_number,
          tracking_url_provider,
          labelObject
          });


          saveShippingLabel(
          sellerId,
          orderIds,
          customerId,
          label_url,
          tracking_number,
          tracking_url_provider,
          labelObject,
          transactionId
        );

        this.displayLoading(false);
        this.setState({ shippingLink: label_url, labelObject });
        this.hideSchedule();
        this.showShippingInstructions();

        return true;
      }else{

        this.displayLoading(false);

        if(responce?.detail){
          let dhlError={
            detail: responce?.detail,
            message: responce?.message,
            title: responce?.title
          }

          let dhlDetails=responce?.detail;
          let errorCode=dhlDetails?.split(":");
          console.log("errorCode",errorCode);

          let dhlMessage="";
          if(errorCode?.length>0){

            if(errorCode[0]=="7013"){
              dhlMessage="DHL does not allow pickup for this shipment date. Please pick a different date.";
            }else if(errorCode[0]=="420505"){
              dhlMessage="The destination location is invalid.";
            }else if(errorCode[0]=="420506"){
              dhlMessage="The zipcode is not found.";
            }else if(errorCode[0]=="410107"){
              dhlMessage="The item being shipped has exceeds the maximum height";
            }
          }

          console.log("dhlMessage",dhlMessage);

          this.setState({ 
            dhlError,
            dhlMessage
          })
        }
        
        
        
        this.setState({ 
          errorSchedulingDelivery: true ,
          shippingLink: null,
          schedulingError: "There has been an error with scheduling for delivery. Please try again.",
          scheduleLoading: false 
        })
        return false;
      }

      

    }catch(error){
      console.log("schedule api error",error)
      this.displayLoading(false);
        
      this.setState({ 
        errorSchedulingDelivery: true ,
        shippingLink: null,
        schedulingError: "There has been an error with scheduling for delivery. Please try again.", 
        scheduleLoading: false
      })
      return false;
    }

  }


  shippingOption= async()=>{


    let shipFrom = {
      name: sellerName,
      company: storeName,
      street1: sellerStreet,
      city: sellerCity,
      state: sellerState,
      zip: sellerZipcode,
      country: sellerCountry,
      phone: sellerPhone,
      email: sellerEmail,
    };

    let shipTo = {
      name: customerName,
      street1: apt && apt !== "N/A" ? `${street} ${apt}` : street,
      // "street2": "",
      city: city,
      state: state,
      zip: zipcode,
      country: country,
      // "phone": "+1 555 341 9393",
      // "email": "mrhippo@goshippo.com",
      // "metadata": "Hippos dont lie"
    };

    console.log("getShippingOptions",
    {shipTo,
      shipFrom,
      parcel})

    const result = await getShippingOptions(
      shipTo,
      shipFrom,
      parcel
    );
  }
  
  printShippingLabel = async () => {

    try{
      this.displayLoading(true);
      const { sellerId, transactionId, orderList, shippingLink, currentTransaction } = this.state;

      console.log("printShippingLabel",{sellerId, transactionId, orderList, shippingLink, currentTransaction});
      
      if (shippingLink) {
        this.setState({ shippingLink: null })
      }

      
      //first check database for existing shipping label to avoid unecessary API calls
      const shippingLabelLink = await this.checkShippingLabel(orderList);
      //const result = await getShippingLabel(sellerId, order.orderId);
      if (shippingLabelLink) {
        // this.hideShippingLabel();
        window.open(shippingLabelLink, "cashaam");
        this.setState({ shippingLink: shippingLabelLink });
        this.displayLoading(false);
      }else{
        //fetch necessary info from transaction collection
        console.log("Transaction data", currentTransaction);

        //get parcel information and all orders associated with this transaction
        const { shippingObjectId, orderIds, transactionId } = currentTransaction;
        const parcel = await getParcelFromTransaction(transactionId);
        console.log("PARCEL", parcel);
        if (!parcel || typeof parcel === "undefined") {
          console.log("No parcel information found parcel:", parcel);
          this.displayLoading(false);
          this.setState({ errorPrintingShippingLabel: true })
          return toast.error("Missing Parcel info");
        }
        const sellerInfo = await getSellersInfo(sellerId);
        console.log("sellersInfo", sellerInfo);

        let order=orderList[0];
        let {
          apt,
          street,
          city,
          state,
          country,
          zipcode,
          customerName,
          deliveryPhoneNumber,
          email
        } = order;

        let buyerApt=order?.apt;
        let buyerStreet=order?.street;
        let buyerCity=order?.city;
        let buyerState=order?.state;
        let buyerCountry=order?.country;
        let buyerZipcode=order?.zipcode;
        let buyerName=order?.customerName;

        let sellerApt = sellerInfo.apt;
        let sellerStreet = sellerInfo.street;
        let sellerCity = sellerInfo.city;
        let sellerState = sellerInfo.state;
        let sellerCountry = sellerInfo.country;
        let sellerZipcode = sellerInfo.zipcode;
        let sellerPhone = sellerInfo.phoneNumber;
        let sellerEmail = sellerInfo.email;
        let { firstName, lastName, storeName } = sellerInfo;
        let sellerName = `${firstName} ${lastName}`;

        let addressFrom = {
          sellerName,
          storeName,
          sellerStreet,
          sellerCity,
          sellerState,
          sellerZipcode,
          sellerCountry,
          sellerPhone,
          sellerEmail,
        };

        var addressTo = {
          customerName,
          street,
          apt,
          city,
          state,
          zipcode,
          country,
        };

        let labelObject=await this.callShippingLabelApi(currentTransaction,addressFrom, addressTo,order,sellerInfo,parcel);


        if(labelObject!==false){

          let customerId=orderList[0].customerId;

          
          let {
            label_url,
            tracking_url_provider,
            tracking_number
          } = labelObject;
          
          console.log("saveShippingLabel", {
            sellerId,
            orderIds,
            customerId,
            label_url,
            tracking_number,
            tracking_url_provider,
            labelObject,
            transactionId});

            await saveShippingLabel(
            sellerId,
            orderIds,
            customerId,
            label_url,
            tracking_number,
            tracking_url_provider,
            labelObject,
            transactionId
          );
          this.setState({ errorPrintingShippingLabel: false });

          this.displayLoading(false);
          window.open(label_url);
          this.setState({ shippingLink: label_url });

        }else{

          this.displayLoading(false);
          this.setState({ errorPrintingShippingLabel: true })
          this.setState({ shippingLink: null });

          console.log("error creating label", labelObject);
          let errorMessages = labelObject?.messages;
          if (errorMessages.length > 0) {
            this.setState({ printShippingLabelErrors: errorMessages })
          }

        }
      }

    }catch(error){
      this.displayLoading(false);
      this.setState({ errorPrintingShippingLabel: true })

      console.log("error printing label", error);


    }

  }

 

  displayLoading = (val) => {
    this.setState({ loading: val });
  };

  validateShippingAddress = async (shippingObjectId) => {
    try {


      //check delivery type HOME or BRANCH (because GIG does not pick up or drop off everywhere in nigeria)
      // HOME = when GIG can come pick up or drop off item at user's place based on their address
      // BRANCH = when GIG does NOT pick up or drop off item at user's place based on their address

      //CHECK FOR SELLER'S ADDRESS
      const sellerDeliveryInfo = await getSellerDeliveryType(
        shippingObjectId
      );
      console.log("sellerDeliveryInfo", sellerDeliveryInfo.scheduleResult);
      let sellerPickup = sellerDeliveryInfo.scheduleResult.pickUp;
      let sellerPickupAddress =
        sellerDeliveryInfo.scheduleResult.pickUpOfficeAddress;
      //CHECK FOR BUYER'S ADDRESS
      const buyerDeliveryInfo = await getBuyerDeliveryType(
        shippingObjectId
      );
      let buyerPickup = buyerDeliveryInfo.scheduleResult.pickUp;
      let buyerPickupAddress =
        buyerDeliveryInfo.scheduleResult.pickUpOfficeAddress;
      console.log("buyerDeliveryInfo", buyerDeliveryInfo.scheduleResult);

      return Promise.resolve({ sellerPickup, sellerPickupAddress, buyerPickup, buyerPickupAddress })

    } catch (error) {
      //any error here means the address has problems
      let message = "We are unable to schedule your pickup because the address is not supported by your delivery provided. Please edit the address";
      console.log(message);
      this.displayErrorMessage(message)
      return false;
    }
  }

  completeSchedulePickup = async () => {
    try {
      this.displayLoading(true);
      let { transactionId, order, sellerId } = this.state;
      console.log({ transactionId, order, sellerId });
      //get shipping info from transaction doc
      const transData = await getTransaction(sellerId, transactionId);
      let { shippingObjectId, orderIds } = transData;
      let { customerId } = order;
      console.log({ shippingObjectId, orderIds, customerId });

      //first check if this order already has a scheduled pick up
      const pickupInfo = await getScheduledPickup(shippingObjectId);
      console.log("info", pickupInfo);

      if (pickupInfo === false) {

        let validationResult = await this.validateShippingAddress(shippingObjectId);
        if (!validationResult) {
          this.reportUnsuccessfullPickupSchedule()
          this.displayLoading(false);
          return;
        }
        let { sellerPickup, sellerPickupAddress, buyerPickup, buyerPickupAddress } = validationResult;

        const result = await scheduleNigeriaPickup(shippingObjectId);
        console.log("result", result);
        let { scheduleResult } = result;
        if (scheduleResult.status === "SUCCESS") {
          let { tracking_number, provider } = scheduleResult;
          //save this in the scheduledPickups collection
          console.log({
            shippingObjectId,
            tracking_number,
            provider,
            scheduleResult,
            orderIds,
            sellerId,
            customerId,
          });



          await saveScheduledPickup(
            shippingObjectId,
            tracking_number,
            provider,
            scheduleResult,
            orderIds,
            sellerId,
            customerId,
            sellerPickup,
            sellerPickupAddress,
            buyerPickup,
            buyerPickupAddress
          );
          this.setState({
            // viewConfirmSchedulePickup: false,
            tracking_number,
            sellerPickup,
            sellerPickupAddress,
            buyerPickup,
            buyerPickupAddress,
            shippingProvider: provider
          });
          this.displayLoading(false);
          this.showManualScheduleShip(false);
          return toast.success("Pickup successfully scheduled");
        } else {
          this.displayLoading(false);
          this.reportUnsuccessfullPickupSchedule()

          console.log(
            "scheduling result was unsuccessful. result:",
            scheduleResult
          );
          return toast.error("scheduling result was unsuccessful.");
        }
      } else {
        this.displayLoading(false);
        console.log("pickupInfo", pickupInfo);
        const { tracking_number } = pickupInfo;
        this.setState({
          tracking_number,
        });
        return toast.info(
          "You have already scheduled a pick up for this order"
        );
      }
    } catch (error) {
      this.displayLoading(false);
      console.log("ERROR scheduling pickup", error);
      let message = "Error scheduling your pickup. Network issue - Please try again later";
      this.reportUnsuccessfullPickupSchedule()
      return this.displayErrorMessage(message)

    }
  };

  displayErrorMessage = (message, errorType) => {

    if(errorType){
      this.setState({
        errorMessage: message,
        errorType
      });
    }else{
      this.setState({
        errorMessage: message
      });
    }
    
    setTimeout(() => {
      this.hideErrorMessage()
    }, 60000);

  }

  hideErrorMessage = () => {
    this.setState({ errorMessage: null, errorType:"" })
  }

  cancelSchedulePickup = () => {
    this.setState({
      viewConfirmSchedulePickup: false,
      transactionId: "",
      order: {},
      tracking_number: null,
    });
    this.componentDidMount();
  };

  schedulePickup = async (sellerId, transactionId, order) => {
    //display confirm schedule option
    this.setState({
      viewOrderDetails: false,
      viewConfirmSchedulePickup: true,
      transactionId,
      order,
      sellerId,
    });
  };

  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    } else {
      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");

      }
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };



  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  displayDetails = (val, order) => {
    if (val === false) {
      this.setState({
        viewOrderDetails: val,
        order: {},
      });
    } else {
      this.setState({
        viewOrderDetails: val,
        order,
      });
    }
  };

  displayShippingLabel = (sellerId, transactionId, orderList, orderIds) => {
    console.log("displayShippingLabel",{ sellerId, transactionId, orderList});
    this.setState({
      viewOrderDetails: false,
      viewShippingLabelPop: true,
      sellerId,
      transactionId,
      orderList,
      orderIds,
      shippingLink: null
    });
  };

  hideShippingLabel = () => {
    this.componentDidMount();
    this.setState({
      viewShippingLabelPop: false,
      transactionId: "",
      order: {},
      orderList:[],
      orderIds:[],
      errorPrintingShippingLabel: false,
      selectedShippingObject: {},

    });
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  onDisplayManualShipping = (val, order) => {

    console.log("Manual shipping object", this.state.manualPrintingObject);
    try{

      let { sellerName, storeName, sellerStreet, sellerCity, sellerState, sellerZipcode, sellerCountry, sellerPhone, sellerEmail,
        customerName, apt, street, city, state, zipcode, country, parcel } = this.state.manualPrintingObject;
      let { productWeight } = this.state; //the selected order
      productWeight = parseFloat(productWeight * 16).toFixed(2); //convert the weights from lbs to oz, since that's what is used on the input fields
      parcel.weight = productWeight;
      console.log("prev parcel weight", parcel.weight);
      if (val === true) {
        this.setState({
          displayManualShipping: val,
          sellerName, storeName, sellerStreet, sellerCity, sellerState, sellerZipcode, sellerCountry, sellerPhone, sellerEmail,
          customerName, apt, street, city, state, zipcode, country, sellerAddress: sellerStreet, address: street,
          prevParcel: parcel, productWeight,
          errorDisplayManualShipping: false
        })
      } else {
        this.setState({
          displayManualShipping: val,
          loading: false,
          successfulReportSent: false,
          errorDisplayManualShipping: false
        })
      }

    }catch(err){

      this.setState({
        errorDisplayManualShipping: true
      })

      console.log("Error displaying manual shipping",err);
    }
  }

  reportUnsuccessfullDHLSchedule = async () => {
    try {

      let { deliveryObj, currentTransaction, dhlError } = this.state;
      let {transactionId, orderList}=currentTransaction;

      const db = firebase.firestore();
      await db.collection("failedPickupScheduling").doc(transactionId).set({
        orderList: orderList ? orderList : [],
        timestamp: Date.now(),
        deliveryObj,
        date: dateTime,
        transactionId, 
        dhlError
        
      });
      this.setState({
        successfulReportSent: true
      })

      //await this.createHubspotTicket(transactionId);
      //await this.slackMsg(transactionId);
      
      return;
    } catch (error) {
      console.log("error reporting to unsuccessful pickup scheduling", error);
      return this.setState({
        successfulReportSent: false
      })
    }
  }

  slackMsg=async (transactionId)=>{

    try{
      const slackAPI="https://hooks.slack.com/services/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"

      let storeInfo = this.state.storeInfo;
      let{slug}=storeInfo;

      let query={
        text:`Schedule Shipping with DHL failed for seller with slug: ${slug}. TransactionId: ${transactionId}`
      }
      let body=JSON.stringify(query)
      console.log("body",body);

      let res = await fetch(slackAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: body,
      });

      console.log("res",res);
      
      if (res.status === 200) {
        console.log("slack msg successful");

      }else{
        console.log("slack msg failed")
      }

    }catch(error){
      console.log("slack message error",error);
    }

  }

  createHubspotTicket=async (transactionId)=>{

    try{
      let storeInfo = this.state.storeInfo;
      let{slug}=storeInfo;

      let query=[
          {
            "name": "subject",
            "value": `Schedule Shipping with DHL failed for seller with slug: ${slug}. TransactionId: ${transactionId}`
          },
          {
            "name": "content",
            "value": `Schedule Shipping with DHL failed for seller with slug: ${slug}. TransactionId: ${transactionId}`
          },
          {
            "name": "hs_pipeline",
            "value": "0"
          },
          {
            "name": "hs_pipeline_stage",
            "value": "1"
          }
      ];

      let body=JSON.stringify(query)
      console.log("body",body)
      const hubSpotTicket="https://api.hubapi.com/crm-objects/v1/objects/tickets?hapikey=a24289f2-4506-4639-b526-f6259735c564";
      let res = await fetch(hubSpotTicket, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body:body,
      });

      console.log("res",res);
      
      if (res.status === 200) {
        console.log("hubspot successful")
        // let{objectId}=result;
        // console.log("objectId",objectId);
      }else{
        console.log("hubspot failed with")
      }

    }catch(error){
      console.log("hubspot api error: ",error);
    }
  }

  reportUnsuccessfulLabelPrint = async (order) => {
    //save order into a collection with all reported failed shipping label printing
    try {
      // this.displayLoading(true);
      let { manualPrintingObject, currentTransaction } = this.state;
      let order=currentTransaction?.orderList[0];
      const db = firebase.firestore();
      await db.collection("failedLabelPrinting").doc(order?.orderId).set({
        order: order ? order : {},
        timestamp: Date.now(),
        date: dateTime,
        manualPrintingObject: manualPrintingObject ? manualPrintingObject : {}
      });
      toast.success("Report sent");
      return this.setState({
        successfulReportSent: true
      })
    } catch (error) {
      toast.error("label print report unsuccessful");
      console.log("error reporting to unsuccessful label creation", error);
      return this.setState({
        successfulReportSent: false
      })
    }
  }

  reportUnsuccessfullPickupSchedule = async () => {
    //save order into a collection with all reported failed shipping label printing
    try {
      // this.displayLoading(true);

      let { order } = this.state;
      const db = firebase.firestore();
      await db.collection("failedPickupScheduling").doc(order.orderId).set({
        order: order ? order : {},
        timestamp: Date.now(),
        date: dateTime,
      });
      return this.setState({
        successfulReportSent: true
      })
    } catch (error) {
      console.log("error reporting to unsuccessful pickup scheduling", error);
      return this.setState({
        successfulReportSent: false
      })
    }
  }

  createLabel = async (e) => {
    e.preventDefault();
    try {

      this.displayLoading(true)
      //create shipping label based on user's manual input

      let { apt, street, city, state, country, zipcode, sellerStreet, sellerCity,
        sellerState, sellerCountry, sellerZipcode, prevParcel, productWeight,
        productHeight, productLength, productWidth, storeName, sellerPhone, sellerEmail, sellerId,orderList,sellerInfo } = this.state;
      let { customerName, sellerName, shippingServiceLevelToken, shippingCarrierAccount, orderIds, order, transactionId, transactionData} = this.state.manualPrintingObject;
      let { shippingPrice } = transactionData;
      let parcel = this.state.prevParcel;
      let orderWeight=parseFloat(parcel.weight)*2.5;
      // if (parseFloat(productWeight) > orderWeight && (parseFloat(productWeight) > 1.2)) {
      //   this.displayLoading(false)
      //   console.log("Order Weight:",orderWeight);
      //   console.log("parcel weight:",productWeight);
      //   return toast.error("You cannot increase product weight");
      // };

      let addressFrom = {
        sellerName,
        storeName,
        sellerStreet,
        sellerCity,
        sellerState,
        sellerZipcode,
        sellerCountry,
        sellerPhone,
        sellerEmail,
      };

      var addressTo = {
        customerName,
        street,
        apt,
        city,
        state,
        zipcode,
        country,
      };

      console.log("create Label", orderList);
      const shippingLabelLink=await this.checkShippingLabel(orderList);

      //const result = await getShippingLabel(sellerId, order.orderId);
      if (shippingLabelLink) {
        // this.hideShippingLabel();
        window.open(shippingLabelLink, "cashaam");
        this.setState({ shippingLink: shippingLabelLink});
        return this.displayLoading(false);
      }

      console.log("transactionData",transactionData);
      //get parcel info and all orderIds from this transaction
      parcel["weight"] = parseFloat(productWeight / 16).toFixed(2); //convert to lbs
      parcel["height"] = productHeight;
      parcel["width"] = productWidth;
      parcel["length"] = productLength;

      console.log("new addressFromt", addressFrom);
      console.log("new addressTo", addressTo);
      console.log("new shippingCarrierAccount", shippingCarrierAccount);
      console.log("new shippingServiceLevelToken", shippingServiceLevelToken);
      if (shippingCarrierAccount && shippingServiceLevelToken) {

        let labelObject=await this.callShippingLabelApi(transactionData,addressFrom, addressTo, orderList[0],sellerInfo,parcel)

        console.log("labelObject", labelObject);
        if(labelObject!==false){
          toast.success("Label successfully created");
          //save shipping label download link with order
          let customerId=orderList[0].customerId;
          let {
            label_url,
            tracking_url_provider,
            tracking_number
          } = labelObject;
          await saveShippingLabel(
            sellerId,
            orderIds,
            customerId,
            label_url,
            tracking_number,
            tracking_url_provider,
            labelObject,
            transactionId, this.state.manualPrintingObject
          );
          this.setState({ errorCreatingShippingLabel: false })

          // await saveShippoTransaction()
          this.displayLoading(false);
          // this.hideShippingLabel();
          window.open(label_url);
          this.setState({ shippingLink: label_url });
        } else {
          this.displayLoading(false);
          this.setState({ errorCreatingShippingLabel: true })

          console.log("error manually creating label", labelObject);
          let errorMessages = labelObject?.messages;
          if (errorMessages.length > 0) {
            this.setState({ printShippingLabelErrors: errorMessages })
          }

          // toast.error("An error occured. Please try again");
        }
      } else {
        //you must fetch shipping options and display to seller so they can pick which shipping option they want to use for the order

        let shipFrom = {
          name: sellerName,
          company: storeName,
          street1: sellerStreet,
          city: sellerCity,
          state: sellerState,
          zip: sellerZipcode,
          country: sellerCountry,
          phone: sellerPhone,
          email: sellerEmail,
        };
  
        let shipTo = {
          name: customerName,
          street1: apt && apt !== "N/A" ? `${street} ${apt}` : street,
          // "street2": "",
          city: city,
          state: state,
          zip: zipcode,
          country: country,
          // "phone": "+1 555 341 9393",
          // "email": "mrhippo@goshippo.com",
          // "metadata": "Hippos dont lie"
        };

        console.log("getShippingOptions",
        {shipTo,
          shipFrom,
          parcel})

        const result = await getShippingOptions(
          shipTo,
          shipFrom,
          parcel
        );
        console.log("shiping options", result);
        const options = result.rates;

        // if(options.length <1){
        //   //NO SHIPPING OPTIONS AT ALL
        // return this.setState({noShippingOptionsFound:true})
        // }

        let sortedOptions = await Promise.resolve(
          this.sortShippingOptions(options)
        );
        console.log("sorted options", sortedOptions);
        const shippingObjectId = result.object_id;

        //when you get shipping options, you must filter to display ONLY shipping options that are cheaper than the shipping price paid by the customer when checking out (shippingPrice)
        //if ALL of the options are more expensive than what the customer paid, then display the cheapest option ONLY
        let filteredOptions = [];
        sortedOptions.map(option => {
          if (parseFloat(option.amount) <= parseFloat(shippingPrice)) {
            filteredOptions.push(option)
          }
        });
        if (filteredOptions.length === 0 && sortedOptions[0]) {
          filteredOptions.push(sortedOptions[0])
        }

        console.log("filtered options", filteredOptions);

        if (filteredOptions.length < 1) {
          toast.error("Error creating new label");
          this.displayLoading(false);
          return this.setState({
            shippingOptions: filteredOptions,
            displayShippingOptions: false,
            errorCreatingShippingLabel: true
          })
        }

        this.displayLoading(false);
        this.setState({
          shippingOptions: filteredOptions,
          displayShippingOptions: true,
          errorCreatingShippingLabel: false,
          displayManualShipping: false,
          successfulReportSent: false
        })
      }
      return console.log("new parcel", parcel);


    } catch (error) {
      console.log("error manually creating shipping label");
      this.displayLoading(false);
      this.setState({ errorCreatingShippingLabel: true })

    }
  }

  sortShippingOptions = async (options) => {
    let sortedOptions = [];

    options.sort(function (a, b) {
      return a.amount - b.amount;
    });
    // console.log("sorted options", options);
    return options;
  };

  handleSelectAddress = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ addressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;

        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();
          resolve(
            this.setState({
              address: street,
              street: street,
              city: city !== null ? city : "N/A",
              state: state !== null ? state : "N/A",
              country: country !== null ? country : "N/A",
              zipcode: zipcode !== null ? zipcode : "NA",
              latitude: lat,
              longitude: lng,
            })
          );
          // await this.getAddressFromGeocode(LatLng);
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.showInvalidAddress();
        if (error === "ZERO_RESULTS") {
          return toast.error(
            "this address is invalid. Please check and try again"
          );
        }
        reject(error);
      }
    });
  };

  setAddress = (address) => {
    if (this.state.addressSelected === true) {
      this.setState({ addressSelected: false });
    }
    this.setState({ address: address });
  };

  onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
    // this.showInvalidAddress();
  };

  showInvalidAddress = () => {
    this.setState({ invalidAddress: true });
    // setTimeout(() => this.setState({ invalidAddress: false }), 5000);
  };

  hideInvalidAddress = () => {
    this.setState({ invalidAddress: false });
  };


  handleSelectSellerAddress = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ sellerAddressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;

        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();
          resolve(
            this.setState({
              sellerAddress: street,
              sellerStreet: street,
              sellerCity: city !== null ? city : "N/A",
              sellerState: state !== null ? state : "N/A",
              sellerCountry: country !== null ? country : "N/A",
              sellerZipcode: zipcode !== null ? zipcode : "NA",
              sellerLatitude: lat,
              sellerLongitude: lng,
            })
          );
          // await this.getAddressFromGeocode(LatLng);
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.showInvalidSellerAddress();
        if (error === "ZERO_RESULTS") {
          return toast.error(
            "this address is invalid. Please check and try again"
          );
        }
        reject(error);
      }
    });
  };

  setSellerAddress = (address) => {
    if (this.state.sellerAddressSelected === true) {
      this.setState({ sellerAddressSelected: false });
    }
    this.setState({ sellerAddress: address });
  };

  showInvalidSellerAddress = () => {
    this.setState({ invalidSellerAddress: true });
    // setTimeout(() => this.setState({ invalidAddress: false }), 5000);
  };

  hideInvalidSellerAddress = () => {
    this.setState({ invalidSellerAddress: false });
  };

  changeDate = async (e) => {
    let val= e.target.value;

    console.log("changeDate",val)
    let dateObj = new Date(`${val}`);
    console.log("picked date",dateObj)

    let hour=this.props.deliveryObj?.hour;

    dateObj.setTime(dateObj.getTime() + 15 * 60 * 60 * 1000);
    
    
    console.log("time after shift by 15hr",dateObj)

    this.setState({ estimatedDeliveryDate:dateObj });

  }

  selectShippingProvider=(provider)=>{
    this.setState({ 
      selfShipProviderName: provider
    })

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })

    if (this.state.errorMessage !== null) {
      this.setState({ errorMessage: null })
    }

  }

  handleTime =(e)=>{

    let deliveryObj=this.state.deliveryObj;
    let dateObj=this.state.dateObj;
    let scheduleTime=e.target.value;
    let time=scheduleTime?.split(":");

    if(scheduleTime ){

      let hour=time[0];
      let minute=time[1];

      console.log("time",{hour,minute});
      if(parseFloat(hour)<9 || (parseFloat(hour)>=14)){
        this.setState({
          timeError:"Please enter a time between 9am and 2pm",
        });

        return
      }

      // if(dateObj){
      //   if(hour<12){
      //     this.shiftDate(dateObj, 24)
      //     console.log("shift by 24 hrs")
      //   }else{
      //     this.shiftDate(dateObj, 12)
      //     console.log("shift by 12 hrs")
      //   }
        
      // } 

      deliveryObj["hour"]=`${hour}`;
      deliveryObj["minute"]=`${minute}`;

      this.setState({
        scheduleTime: e.target.value,
        deliveryObj,
        timeError:""
      })
    }
    
  }

  shiftDate=(dateObj, byHours)=>{
    
    const originalDate=new Date(dateObj.valueOf());
    dateObj.setTime(dateObj.getTime() + byHours * 60 * 60 * 1000);

    console.log("time after shift",dateObj)
    console.log("originalDate",originalDate);
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    console.log({ month, day, year})
    this.handleChangedDate(month, day, year, originalDate);

  }

  onDisplayShippingOptions = (val) => {
    if (val === false) {
      return this.setState({
        displayShippingOptions: val,
        successfulReportSent: false,
        errorCreatingShippingLabel: false
      })
    }
    this.setState({
      displayShippingOptions: val
    })
  }

  onSelectShippingOption = (rateObjectId,
    carrierAccount,
    servicelevelToken,
    price) => {
    this.setState({
      selectedShippingObject: {
        rateObjectId,
        carrierAccount,
        servicelevelToken,
        price
      },
      ShippingOptionSelected: true
    })
  }

  unSelectShippingOption = () => {
    this.setState({
      selectedShippingObject: {},
      ShippingOptionSelected: false
    })
  }

  onConfirmNewLabelCreation = async () => {
    this.displayLoading(true);
    try {

      let { rateObjectId,
        carrierAccount,
        servicelevelToken,
        price } = this.state.selectedShippingObject;

      let { apt, street, city, state, country, zipcode, sellerStreet, sellerCity,
        sellerState, sellerCountry, sellerZipcode, productWeight,
        productHeight, productLength, productWidth, storeName, sellerPhone, sellerEmail, sellerId, currentTransaction } = this.state;
      let { customerName, sellerName, orderIds, order, transactionId, transactionData } = this.state.manualPrintingObject;
      let { shippingPrice } = transactionData; //price of shipping paid by buyer when checking out

      let addressFrom = {
        sellerName,
        storeName,
        sellerStreet,
        sellerCity,
        sellerState,
        sellerZipcode,
        sellerCountry,
        sellerPhone,
        sellerEmail,
      };

      var addressTo = {
        customerName,
        street,
        apt,
        city,
        state,
        zipcode,
        country,
      };

      let parcel = this.state.prevParcel;
      parcel["weight"] = parseFloat(productWeight / 16).toFixed(2); //convert to lbs
      parcel["height"] = productHeight;
      parcel["width"] = productWidth;
      parcel["length"] = productLength;

      const result = await getShippingLabel(sellerId, order.orderId);
      if (result !== false) {
        // this.hideShippingLabel();
        window.open(result.shippingLabelLink, "cashaam");
        this.setState({ shippingLink: result.shippingLabelLink });
        return this.displayLoading(false);
      }

      console.log("creating new shipping label with data:", {
        addressFrom,
        addressTo,
        carrierAccount,
        servicelevelToken,
        parcel
      })

      let orderList=currentTransaction?.orderList;

      const sellerInfo = await getSellersInfo(sellerId);
      console.log("sellersInfo", sellerInfo);

      let labelObject=await this.callShippingLabelApi(currentTransaction,addressFrom, addressTo, orderList[0],sellerInfo,parcel);

      console.log("labelObject", labelObject);
      if(labelObject!==false){
        toast.success("Label successfully created");
        //save shipping label download link with order
        let {
          label_url,
          tracking_url_provider,
          tracking_number
        } = labelObject;
        await saveShippingLabel(
          sellerId,
          orderIds,
          order.customerId,
          label_url,
          tracking_number,
          tracking_url_provider,
          labelObject,
          transactionId, this.state.manualPrintingObject
        );
        this.setState({ errorCreatingShippingLabel: false })

        // await saveShippoTransaction()
        this.displayLoading(false);
        // this.hideShippingLabel();
        this.setState({ shippingLink: result.shippingLabelLink });
        window.open(label_url);
      } else {
        this.displayLoading(false);
        this.setState({ errorCreatingShippingLabel: true })
        console.log("error creating label", labelObject);
        toast.error("Error Creating Label")
      }
    } catch (error) {
      toast.error("Error Creating Label")
      this.displayLoading(false);
      console.log("error creating new shipping label", error);
      this.setState({ errorCreatingShippingLabel: true })
    }
  }

  showManualScheduleShip = (val) => {
    let order = this.state.order;
    let { apt, street, city, state, country } = order;
    this.setState({ displayManualScheduleShip: val, apt, street, city, state, country, address: street })
  }

  onConfirmNewScheduleShip = async () => {
    try {
      let { apt, street, city, state, country } = this.state;
      let { transactionId, order, sellerId } = this.state;

      const transData = await getTransaction(sellerId, transactionId);
      let { shippingObjectId } = transData;
      const db = firebase.firestore();

      //RETRIEVE PREVIOUS SHIPPING INFO
      const shipmentSnap = await db
        .collection("shipping")
        .doc(shippingObjectId)
        .get();
      const shipmentInfo = shipmentSnap.data();
      console.log("shipmentInfo", shipmentInfo);
      let { carrier_account, servicelevel_token } = shipmentInfo;
      let shipment = shipmentInfo.shipment;
      let { nigeriaShippingInfo } = shipment;

      //MODIFY PREVIOUS SHIPPING INFO
      let addressTo = apt && apt !== "" && apt !== "N/A" ? `${street} apt ${apt}, ${city}, ${state}, ${country}` : `${street}, ${city}, ${state}, ${country}`
      let buyerState = state;
      let buyerCity = city;
      nigeriaShippingInfo["addressTo"] = addressTo;
      nigeriaShippingInfo["buyerState"] = buyerState;
      nigeriaShippingInfo["buyerCity"] = buyerCity;
      shipment["nigeriaShippingInfo"] = nigeriaShippingInfo;
      //SAVE NEW SHIPPING INFO
      await db.collection("shipping").doc(shippingObjectId).set({
        shipment,
        manuallyEdited: true
      }, { merge: true })


      //save the new address into the selected order in state
      if (apt) {
        order["apt"] = apt;
      }
      order["street"] = street;
      order["city"] = city;
      order["state"] = state;
      order["country"] = country;
      this.setState({ order })

      //COMPLETE SCHEDULE SHIPPING
      this.completeSchedulePickup();


    } catch (error) {
      console.log("error confirming new schedule shipping address", error);
      let message = "Error scheduling pickup with new address. Please try fixing the address";
      this.showManualScheduleShip(false);
      this.displayErrorMessage(message);
    }

  }

  getCarrierName =(carrierAccount)=>{


    if(carrierAccount?.toLowerCase()=="pickup"){
        
      return "PICKUP";
    }else if(carrierAccount?.toLowerCase()=="customdelivery"||carrierAccount?.toLowerCase()=="undefined"){
      return "Custom Delivery";
    }else{

      let {carrierAccounts} = this.state;
      let carrierInfo=carrierAccounts[carrierAccount];
      let carrierName=carrierInfo?.name;

      if(carrierName){
        return carrierName.toUpperCase();
      }else{
        return "Custom Delivery";
      }
    }

  }

  getCorrectShippingType=(carrierAccount)=> {

    try{

      //console.log("carrierAccount:",carrierAccount);
      if(carrierAccount?.toLowerCase()=="pickup"){
        
        return <div className="delivery-name">PICKUP</div>;
      }else if(carrierAccount?.toLowerCase()=="customdelivery"||carrierAccount?.toLowerCase()=="undefined"){
        return <div className="delivery-name">Custom Delivery</div>;
      }else{

        let {carrierAccounts} = this.state;
        let carrierInfo=carrierAccounts[carrierAccount];
        let carrierLogo=carrierInfo?.logo;
        if(carrierLogo){
          return <img className="carrier-logo" src={carrierLogo} />;
        }else{
          return <div className="delivery-name">Custom Delivery</div>;
        }
        
      }
  
      
    }catch(error){
      console.log("error getting shipping logo", error);


    }

  }



  showTransactionDetails =(transactionDetails)=>{

    let {storeInfo}=this.state;
    let currentTransaction=transactionDetails;

    let{
      firstName, 
      lastName, 
      phoneNumber, 
      street,
      city,
      state, 
      country,
      zipcode,
      apt,
      storeName,
      email
    }=storeInfo;

    let {
      deliveryPhoneNumber,
      orderList,
      shippingPrice,
      currency
    }=currentTransaction;

    let customerName,
    customerEmail,
    shippingStreet,
    shippingState,
    shippingCity,
    shippingCountry,
    shippingZip,
    shippingApt,
    customerPhone;

    if (orderList.length > 0) {
      let order = orderList[0];

      shippingStreet = order?.street;

      if(shippingStreet){
        shippingStreet=shippingStreet.replace('N/A', '');
      }
      shippingCity = order?.city;
      shippingState = order?.state;
      shippingCountry = order?.country;
      shippingZip = order?.zipcode;
      shippingApt = order?.apt;
      customerName=order.customerName;
      customerEmail=order.email;
      customerPhone=order.deliveryPhoneNumber
    }

    let nameArray=customerName.split(" ");

    let products=[];
    orderList.map((order)=>{
      let prod={};
      prod.productName=order?.productName;
      prod.productWeight=0.187;
      prod.productLength=order?.productLength;
      prod.productWidth=order?.productWidth;
      prod.productHeight=order?.productHeight;
      prod.productPrice=parseFloat(order?.productPrice);
      prod.productCurrency=order?.currency;
      prod.productQuantity=order?.orderQuantity;
      products.push(prod);
    })

    console.log("products",products);

    if(shippingPrice<=0){

      if(currency.toLowerCase()=="ngn"){
        shippingPrice=5000;
      }else{
        shippingPrice=10;
      }
      
    }

    let deliveryObj={
      sellerFirstName:firstName?firstName.trim():"N/A",
      sellerLastName:lastName?lastName.trim():"N/A",
      sellerPhoneNumber:phoneNumber?phoneNumber:"+2347025130024",
      sellerStreet:street,
      sellerCity:city,
      sellerState:state,
      sellerCountry:country,
      sellerZipcode:zipcode?zipcode:"N/A",
      sellerApt:apt?apt:"N/A",
      storeName:storeName,
      sellerEmail:email?email?.trim():"shops@cashaam.com",
      unitOfMeasurement:"imperial",

      firstName:nameArray[0]?nameArray[0]?.trim():"N/A",
      lastName:nameArray[1]?nameArray[1]?.trim():"N/A",
      phoneNumber:customerPhone?customerPhone:"+2347025130024",
      street:shippingStreet,
      city:shippingCity,
      state:shippingState,
      country:shippingCountry,
      zipcode:shippingZip?shippingZip:"N/A",
      apt:shippingApt?shippingApt:"N/A",
      email:customerEmail?customerEmail?.trim():"shop@cashaam.com",
      
      products,
      shippingPrice: shippingPrice?shippingPrice:0,
      currency: currency?currency:"NGN"
    }


    this.setState({
      displayTransactionDetails: true,
      currentTransaction: transactionDetails,
      deliveryProduct: products,
      deliveryObj

    })
    
  }

  backToTransactions =()=>{
    this.setState({
      displayTransactionDetails: false,
      currentTransaction: {}
    })
  }

  onHideSelfShippingPop = () => {
    this.setState({
      showSelfShippingPop: false,
      selectedOrderList: {},
      selfShipProviderName: "", selfShipPrice: 0, selfShipTrackNumber: ""

    })
  }

  onShowSelfShippingPop = (orderList) => {
    console.log({ orderList });
    this.setState({
      showSelfShippingPop: true,
      selectedOrderList: orderList,
      displayDeliveryInfo: false
    })
  }

  onCompleteSelfShipping = async () => {
    try {

      this.setState({
        errorType:""
      })

      let { selfShipProviderName, selfShipTrackNumber,selfShipPrice, currentTransaction, estimatedDeliveryDate,otherShipping } = this.state;
      
      console.log("selfShipping Info",{selfShipProviderName,estimatedDeliveryDate,selfShipTrackNumber})
      if (!selfShipProviderName || selfShipProviderName.length < 2) {
        let errorType="selfShipProviderName";
        return this.displayErrorMessage("Please enter the shipping provider name", errorType);

      }

      if(selfShipProviderName=="Other" && (!otherShipping || otherShipping.length < 2)){
        let errorType="selfShipProviderName";
        return this.displayErrorMessage("Please enter the shipping provider name - Type 'N/A' if not available", errorType);
      }

      if(!selfShipTrackNumber){
        let errorType="selfShipTrackNumber";
        return this.displayErrorMessage("Please enter the tracking number",errorType);
      }

      if(!estimatedDeliveryDate){
        let errorType="estimatedDeliveryDate";
        return this.displayErrorMessage("Please enter the estimated delivery date", errorType);
      }


      if(selfShipProviderName=="Other" && otherShipping){
        selfShipProviderName=otherShipping;
        console.log("selfShipProviderName",selfShipProviderName);
      }

      // let price = parseFloat(selfShipPrice).toFixed(2);
      //let shippingId = selectedOrder.shipping_id ? selectedOrder.shipping_id : selectedOrder.shippingId ? selectedOrder.shippingId : null;
      let shippingId=currentTransaction?.shippingRateObjectId;
      let orderIds=currentTransaction?.orderIds;

      let sellerId = this.state.sellerId;
      

      if (!shippingId || typeof shippingId === "undefined") {
        //probably a PICK UP type shipping - NOT APPLICABLE
        return this.displayErrorMessage("Sorry, this order is not eligible for self shipping");

      }


      
      let{
        shippingPrice,
        transactionId,
        orderList,
      }=currentTransaction;

      orderList = currentTransaction?.orderList;

      let customerName,
        customerEmail,
        shippingStreet,
        shippingState,
        shippingCity,
        shippingCountry,
        shippingZip,
        shippingApt,
        customerPhone,
        customerId;
      if (orderList.length > 0) {
        let order = orderList[0];
  
        shippingStreet = order?.street;
  
        if(shippingStreet){
          shippingStreet=shippingStreet.replace('N/A', '');
        }
        shippingCity = order?.city;
        shippingState = order?.state;
        shippingCountry = order?.country;
        shippingZip = order?.zip;
        shippingApt = order?.apt;
        customerName=order.customerName;
        customerEmail=order.email;
        customerPhone=order.deliveryPhoneNumber
        customerId=order?.customerId;
      }

      let sellerInfo=this.state?.sellerInfo;
      let sellerEmail=sellerInfo?.email;
      let sellerPhoneNumber=sellerInfo?.phoneNumber;
      let slug=sellerInfo?.slug;
      console.log("sellerInfo",sellerInfo);
      console.log("slug",slug);

      let sellerCity=sellerInfo?.city;
      let sellerCountry=sellerInfo?.country;
      let sellerState=sellerInfo?.state;
      let sellerStreet=sellerInfo?.street;
      let sellerZip=sellerInfo?.zipcode;

      let address_from={
        "city": sellerCity,
        "country": sellerCountry,
        "state": sellerState,
        "street1": sellerStreet,
        "street2": "N/A",
        "zip": sellerZip?sellerZip:"N/A"
      }

      console.log("address_from",address_from);

      let address_to={
        "city": shippingCity,
        "country": shippingCountry,
        "state": shippingState,
        "street1": shippingStreet,
        "street2": "N/A",
        "zip": shippingZip?shippingZip:"N/A"
      }
      console.log("address_to",address_to)
      let shippingObject={
        address_from,
        address_to
      }

      let selfShippingObj = {

        sellerEmail,
        sellerPhoneNumber,
        sellerId,
        slug,
        shippingPrice,
        trackingNumber: selfShipTrackNumber,
        trackingUrl: "N/A",
        trackingUrlProvider: selfShipProviderName,
        
        customerId,
        customerName,
        customerEmail,
        phoneNo: customerPhone, 
        trackingId: transactionId,
        orderIds,
        orderStatus: "dispatched",
        transactionId,
        shippingObject,
        estimatedPrice: shippingPrice,

        eta: `${estimatedDeliveryDate}`,
        paidSeller: false,

      }

      console.log("selfShippingObj",selfShippingObj);
      console.log({ selfShippingObj });

      //save the new info under selfShipping field in the shipping doc
      await saveSelfShipping(selfShippingObj);

      this.setState({
        successSubmission: true
      })

      // this.onHideSelfShippingPop();
      // return this.componentDidMount();

      

    } catch (error) {
      console.log("error doing self shipping", error);
      this.displayErrorMessage("Self Shipping was unsuccessful.")
    }

  }

  getCarrierAccounts = async()=>{

    let carrierKeys = Object.keys(DeliveryCarrierAccount);
    let carrierAccounts = {};
    await carrierKeys.forEach((carrier) => {
      let logo=DeliveryCarrierAccount[carrier].logo;
      let name=DeliveryCarrierAccount[carrier].carrier;
      let carrierInfo={
        name,
        logo
      }
      carrierAccounts[carrier] = carrierInfo;
    });

    this.setState({
      carrierAccounts
    })
  }

  getNigerianAddresses = async()=>{

    let stateNames = Object.keys(NigerianAddresses);
    let NigeriaLGA = {};
    await stateNames.forEach((state) => {
      let LGA=NigerianAddresses[state]
      
      NigeriaLGA[state] = LGA;
    });

    this.setState({
        NigeriaLGA
    })
}

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  componentDidMount() {
    NProgress.start();

    this.loadHubspot();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );

        this.getNigerianAddresses();
        const storeInfo = await getBasicInfo(user.uid);
        this.setState({ storeInfo})

        await this.getCarrierAccounts();
        let location = await this.getLocation();
        this.checkRegistrationCompletion(user.uid, location);

        // this.renderComponent("completed");
        this.setState({ firstName: user.displayName, sellerId: user.uid });
        NProgress.set(0.3);
        //await this.getOrders(user.uid);
        let allTransactions=await this.getAllTransaction(user.uid);
        console.log("allTransactions",allTransactions);
        NProgress.set(0.5);
        let completeTransactions =await this.getTransactionInfo(user.uid,"completed");
        console.log("completeTransactions",completeTransactions);
        let dispatchedTransactions=await this.getTransactionInfo(user.uid,"dispatched");
        console.log("dispatchedTransactions",dispatchedTransactions);
        let fulfilledTransactions=await this.getTransactionInfo(user.uid,"fulfilled");
        console.log("fulfilledTransactions",fulfilledTransactions);
        NProgress.set(0.7);
        let pendingOrders=await this.getPendingOrders(user.uid);
        console.log("pendingOrders",pendingOrders);
        // await this.getDispatchedOrders(user.uid);
        // await this.getFullfilledOrders(user.uid);
        const sellerInfo = await getSellersInfo(user.uid);
        NProgress.set(0.8);

        this.setState({
          allTransactions,
          completeTransactions,
          dispatchedTransactions,
          fulfilledTransactions,
          pendingOrders,
          sellerInfo
          
        })
        this.getReturnedOrders(user.uid);
        NProgress.done()
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Seller - Orders</title>
          <meta
            content="Cashaam - Manage your Product with Ease"
            name="description"
          />
          <meta content="Seller - Orders" property="og:title" />
          <meta
            content="Cashaam - Manage your Product with Ease"
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <meta name="msapplication-TileColor" content="#870000" />
          <meta name="theme-color" content="#870000" />
        </Metatags>
        <ToastContainer />
        {/* {this.state.viewOrderDetails === true ? (
          <OrderDetailsPop
            displayDetails={this.displayDetails}
            order={this.state.order}
            schedulePickup={this.schedulePickup}
            displayShippingLabel={this.displayShippingLabel}
            markAsFullFilled={this.markAsFullFilled}
          />
        ) : null} */}



        {this.state.viewConfirmSchedulePickup === true ? (
          <PickupPop
            cancelSchedulePickup={this.cancelSchedulePickup}
            completeSchedulePickup={this.completeSchedulePickup}
            tracking_number={this.state.tracking_number}
            sellerPickup={this.state.sellerPickup}
            sellerPickupAddress={this.state.sellerPickupAddress}
            // sellerPickupAddress="Town Planning Road Town Planning Complex by, Sumal Foods, MKO Abiola Way, Ibadan, Nigeria"
            buyerPickup={this.state.buyerPickup}
            buyerPickupAddress={this.state.buyerPickupAddress}
            // buyerPickupAddress="11 Ernest Orachire, Gbagada, Lagos Nigeria"
            loading={this.state.loading}
            order={this.state.order}
            errorMessage={this.state.errorMessage}
            showManualScheduleShip={this.showManualScheduleShip}
            markAsFullFilled={this.markAsFullFilled}
            shippingProvider={this.state.shippingProvider}
          />
        ) : null}
        {this.state.viewShippingLabelPop === true && this.state.displayManualShipping === false ? (
          <ShippingLabelPop
            hideShippingLabel={this.hideShippingLabel}
            printLabel={this.printShippingLabel}
            loading={this.state.loading}
            errorPrintingShippingLabel={this.state.errorPrintingShippingLabel}
            displayManualShipping={this.state.displayManualShipping}
            onDisplayManualShipping={this.onDisplayManualShipping}
            order={this.state.order}
            shippingLink={this.state.shippingLink}
            printShippingLabelErrors={this.state.printShippingLabelErrors}
            errorDisplayManualShipping={this.state.errorDisplayManualShipping}
            showScheduleDelivery={this.showScheduleDelivery}
          />
        ) : null}

          {this.state.displaySchedule?
              <ScheduleDelivery 
              handleTime={this.handleTime}
              scheduleTime={this.state.scheduleTime}
              scheduleDelivery={this.scheduleDelivery}
              shippingWeight={this.state.shippingWeight}
              handleChange={this.handleChange}
              hideSchedule={this.hideSchedule}
              orderList={this.state.currentTransaction?.orderList}
              schedulingError={this.state.schedulingError}
              scheduleLoading={this.state.scheduleLoading}
              timeError={this.state.timeError}
              onEditShipping={this.onEditShipping}
              handleChangedDate={this.handleChangedDate}
              handleOverWeight={this.handleOverWeight}
              overWeight={this.state.overWeight}
              totalOrderWeight={this.state.totalOrderWeight}
              deliveryObj={this.state.deliveryObj}
              dateError={this.state.dateError}
              reportUnsuccessfullDHLSchedule={this.reportUnsuccessfullDHLSchedule}
              successfulReportSent={this.state.successfulReportSent}
              errorSchedulingDelivery={this.state.errorSchedulingDelivery}
              dhlMessage={this.state.dhlMessage}
            />
          :null}

            {this.state.displayShippingInstructions?  
              <ShippingInfo  
              openLink={this.openLink} 
              hideShippingInstructions={this.hideShippingInstructions} 
              labelObject={this.state.labelObject? 
                this.state.labelObject
                :this.state.currentTransaction?.orderList[0]?.labelObject} 
              shippingLabelLink={this.state.shippingLink?
                this.state.shippingLink : this.state.currentTransaction?.orderList[0]?.shippingLabelLink}
              />
              :null}

            {this.state.displayEditShipping? 
              <EditDeliveryPopup 
              setSellerAddress={this.changeSellerAddress}
              setAddress={this.changeBuyerAddress}
              onError={this.onError}
              handleSelectSellerAddress={this.selectSellerAddress}
              handleSelectAddress={this.selectBuyerAddress}
              handleDeliveryChange={this.handleDeliveryChange}
              submitEditedSchedule={this.submitEditedSchedule}
              onEditShipping={this.onEditShipping}
              reportUnsuccessfullDHLSchedule={this.reportUnsuccessfullDHLSchedule}
              openLink={this.openLink}
              successfulReportSent={this.state.successfulReportSent}
              errorSchedulingDelivery={this.state.errorSchedulingDelivery}
              order={this.state.currentTransaction?.orderList[0]}

              labelObject={this.state.labelObject? 
                this.state.labelObject
                :this.state.currentTransaction?.orderList[0]?.labelObject} 
              shippingLabelLink={this.state.shippingLink?
                this.state.shippingLink : this.state.currentTransaction?.orderList[0]?.shippingLabelLink}
              
              loading={this.state.scheduleLoading}

              NigeriaLGA={this.state.NigeriaLGA}
              buyerLGA={this.state.deliveryObj?.state? this.state.NigeriaLGA[this.state.deliveryObj?.state]: []}
              address={this.state.deliveryObj?.street}
              street={this.state.deliveryObj?.street}
              apt={this.state.deliveryObj?.apt}
              city={this.state.deliveryObj?.city}
              state={this.state.deliveryObj?.state}
              country={this.state.deliveryObj?.country}
              zipcode={this.state.deliveryObj?.zipcode}

              sellerLGA={this.state.deliveryObj?.sellerState? this.state.NigeriaLGA[this.state.deliveryObj?.sellerState]: []}
              sellerAddress={this.state.deliveryObj?.sellerStreet}
              sellerStreet={this.state.deliveryObj?.sellerStreet}
              sellerApt={this.state.deliveryObj?.sellerApt}
              sellerCity={this.state.deliveryObj?.sellerCity}
              sellerState={this.state.deliveryObj?.sellerState}
              sellerCountry={this.state.deliveryObj?.sellerCountry}
              sellerZipcode={this.state.deliveryObj?.sellerZipcode}

              sellerStreetError={this.state.sellerStreetError}
              buyerStreetError={this.state.buyerStreetError}

              customerName={`${this.state.deliveryObj?.firstName} ${this.state.deliveryObj?.lastName}`}
              phoneNumber={this.state.deliveryObj?.phoneNumber}
              customerEmail={this.state.deliveryObj?.email}
              addressesError={this.state.addressesError}
              buyerPostCodeError={this.state.buyerPostCodeError}
              sellerPostCodeError={this.state.sellerPostCodeError}
              />
              :null}

        {this.state.displayManualShipping === true && this.state.displayShippingOptions === false ?
          <ManualShippingPop
            errorCreatingShippingLabel={this.state.errorCreatingShippingLabel}
            order={this.state.order}
            reportUnsuccessfulLabelPrint={this.reportUnsuccessfulLabelPrint}
            loading={this.state.loading}
            address={this.state.address}
            apt={this.state.apt}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            zipcode={this.state.zipcode}
            setAddress={this.setAddress}
            handleSelectAddress={this.handleSelectAddress}
            onError={this.onError}
            sellerAddress={this.state.sellerAddress}
            sellerApt={this.state.sellerApt}
            sellerCity={this.state.sellerCity}
            sellerState={this.state.sellerState}
            sellerCountry={this.state.sellerCountry}
            sellerZipcode={this.state.sellerZipcode}
            setSellerAddress={this.setSellerAddress}
            handleSelectSellerAddress={this.handleSelectSellerAddress}
            // prevParcel={this.state.prevParcel}
            productHeight={this.state.productHeight}
            productWeight={this.state.productWeight}
            productLength={this.state.productLength}
            productWidth={this.state.productWidth}
            createLabel={this.createLabel}
            handleChange={this.handleChange}
            onDisplayManualShipping={this.onDisplayManualShipping}
            // errorCreatingShippingLabel={this.state.errorCreatingShippingLabel}
            successfulReportSent={this.state.successfulReportSent}
            shippingLink={this.state.shippingLink}
            printShippingLabelErrors={this.state.printShippingLabelErrors}
          /> : null}

        {this.state.displayShippingOptions === true ? (
          <ManualShippingOptions
            onDisplayShippingOptions={this.onDisplayShippingOptions}
            onSelectShippingOption={this.onSelectShippingOption}
            onConfirmNewLabelCreation={this.onConfirmNewLabelCreation}
            shippingOptions={this.state.shippingOptions}
            ShippingOptionSelected={this.state.ShippingOptionSelected}
            selectedShippingObject={this.state.selectedShippingObject}
            unSelectShippingOption={this.unSelectShippingOption}
            errorCreatingShippingLabel={this.state.errorCreatingShippingLabel}
            successfulReportSent={this.state.successfulReportSent}
            reportUnsuccessfulLabelPrint={this.reportUnsuccessfulLabelPrint}
            order={this.state.order}
            shippingLink={this.state.shippingLink}
          />
        ) : null}

        {this.state.displayManualScheduleShip === true ?
          <ManualScheduleShip
            onConfirm={this.onConfirmNewScheduleShip}
            order={this.state.order}
            showManualScheduleShip={this.showManualScheduleShip}
            loading={this.state.loading}
            address={this.state.address}
            apt={this.state.apt}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            // zipcode={this.state.zipcode}
            setAddress={this.setAddress}
            handleSelectAddress={this.handleSelectAddress}
            handleChange={this.handleChange}
            onError={this.onError}
            tracking_number={this.state.tracking_number}
          /> : null}
          {this.state.showSelfShippingPop === true ?
          <SelfShippingPop
            loading={this.state.loading}
            onHideSelfShippingPop={this.onHideSelfShippingPop}
            onCompleteSelfShipping={this.onCompleteSelfShipping}
            selectShippingProvider={this.selectShippingProvider}
            errorMessage={this.state.errorMessage}
            handleChange={this.handleChange}
            sellerCountry={this.state.sellerInfo?.country}
            changeDate={this.changeDate}
            errorType={this.state.errorType}
            successSubmission={this.state.successSubmission}
            selfShipProviderName={this.state.selfShipProviderName}
          /> : null}

          {this.state.displayDeliveryInfo?
            <DeliveryInfoPop
            loading={this.state.loading}
            hideDeliveryInfo={this.hideDeliveryInfo}
            onCompleteSelfShipping={this.onCompleteSelfShipping}
            handleChange={this.handleChange}
            sellerCountry={this.state.sellerInfo?.country}
            currentTransaction={this.state.currentTransaction}
            onShowSelfShippingPop={this.onShowSelfShippingPop}
            /> 
          : null}
        

        {/* <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
        /> */}
        <div className="section-inside">
          <div className="panel-main">
            {this.state.displayDeliveryInfo?
            null:
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            }
            <div className="panel-center" style={{padding: "0px"}}>
              <div className="main">
                

              {this.state.displayTransactionDetails===false?
                <OrderList 
                allTransactions={this.state.allTransactions}
                completeTransactions={this.state.completeTransactions}
                dispatchedTransactions={this.state.dispatchedTransactions}
                fulfilledTransactions={this.state.fulfilledTransactions}
                pendingOrders={this.state.pendingOrders}
                showTransactionDetails={this.showTransactionDetails}
                getCorrectShippingType={this.getCorrectShippingType}
                sellerCountry={this.state.sellerInfo?.country}
                slug={this.state.sellerInfo?.slug}
                logoutHandler={this.logoutHandler}
                notification={this.state.notification}
                />
                :null}
              </div>
              {this.state.displayTransactionDetails===true?
                <TransactionDetails 
                currentTransaction={this.state.currentTransaction}
                backToTransactions={this.backToTransactions}
                getCorrectShippingType={this.getCorrectShippingType}
                getCarrierName={this.getCarrierName}
                displayShippingLabel={this.displayShippingLabel}
                markAsShipped={this.markAsShipped}
                markAsDelivered={this.markAsDelivered}
                sellerCountry={this.state.sellerInfo?.country}
                slug={this.state.sellerInfo?.slug}
                notification={this.state.notification}
                showScheduleDelivery={this.showScheduleDelivery}
                schedulingError={this.state.schedulingError}
                scheduleLoading={this.state.scheduleLoading}
                showShippingInstructions={this.showShippingInstructions}
                showDeliveryInfo={this.showDeliveryInfo}
                sellerInfo={this.state.sellerInfo}
                />: null
              }
              
            </div>
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="orders"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          {/* <RightNav logoutHandler={this.logoutHandler} /> */}
        </div>
      </>
    );
  }
}
