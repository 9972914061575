import React, { Component } from 'react'

import logoutHandler from "../../../helpers/logout";
import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";


export class Layout extends Component {
    state = {
        showLeftNav: false,
    }

    openLeftNav = () => {
        this.setState({
            showLeftNav: !this.state.showLeftNav,
        });
    };
    render() {
        return (
            <>
                <TopHeader
                    firstName={this.props.firstName}
                    lastName={this.props.lastName}
                    logoutHandler={logoutHandler}
                />
                <div className="section-inside">
                    <div className="panel-main">
                        <LeftNav
                            showLeftNav={this.state.showLeftNav}
                            logoutHandler={logoutHandler}
                            sellerId={this.props.sellerId}
                            userCurrentCountry={this.props.userCurrentCountry}
                        />
                        {this.props.children}
                        <BottomNav
                            openLeftNav={this.openLeftNav}
                            page="products"
                            logoutHandler={logoutHandler}
                        />
                    </div>
                    <RightNav logoutHandler={logoutHandler} />
                </div>
            </>
        )
    }
}

export default Layout