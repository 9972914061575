import React from "react";
import ButtonLoading from "./ButtonLoading";

const ProductImageUpload = (props) => {
  return (
    <div className="form-holder">
      <div style={{ minWidth: "60%" }} className="product-steps">
        <div className="block-title-2">
          <h4 className="page-title">Product Images</h4>
        </div>
        <div className="microcopy-signup">
          <span className="highlight">Tip:</span>
          Be sure to use natural light, a plain background, and take photos of
          the product in use and by itself.
        </div>
        <div className="product-builder" />
        <div className="holding-onboarding">
          <div className="forms w-form">
            <form
              onSubmit={props.onDisplayProductInfo}
              id="email-form"
              name="email-form"
              data-name="Email Form"
              className="form-2-old"
            >
              {props.featuredImageError? <div class="error-message">{props.featuredImageError}</div> : null}
              <label>Main Image <em>(required)</em></label>
              <div className="input-upload w-embed">
                <input
                  onChange={props.handleFeaturedImageChange}
                  type="file"
                  id="myFile"
                  name="featuredImage"
                />
              </div>
              <label>More Images</label>
              <div className="input-upload w-embed">
                <input
                  onChange={props.handleImageChange}
                  type="file"
                  id="myFile"
                  name="img2"
                />
              </div>
              <div className="input-upload w-embed">
                <input
                  onChange={props.handleImageChange}
                  type="file"
                  id="myFile"
                  name="img3"
                />
              </div>
              <div className="input-upload w-embed">
                <input
                  onChange={props.handleImageChange}
                  type="file"
                  id="myFile"
                  name="img4"
                />
              </div>
              <div className="input-upload w-embed">
                <input
                  onChange={props.handleImageChange}
                  type="file"
                  id="myFile"
                  name="img5"
                />
              </div>
              {props.loading === false ? (
                <input
                  type="submit"
                  value="Next"
                  defaultValue="Save Product"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
              ) : (
                <ButtonLoading />
              )}
            </form>

            {props.errorMessage !== null ? (
              <div style={{ display: "flex" }} className="w-form-fail">
                <div> {props.errorMessage}</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductImageUpload;
