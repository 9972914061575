import React from "react";

function Specifier(props) {
  return (
    <div
      id="language-select-wrapper"
      className="AIPRM__flex AIPRM__gap-3 lg:AIPRM__max-w-3xl md:last:AIPRM__mb-6 AIPRM__mx-2 AIPRM__pt-2 AIPRM__stretch AIPRM__justify-between AIPRM__text-sm AIPRM__items-end AIPRM__pb-2 AIPRM__mb-2 AIPRM__border-b AIPRM__flex-col sm:AIPRM__flex-row"
    >
        <a
            onClick={() => props.hideSpecifier()}
            style={{    left: "auto",
                top: "7px",
                right: "10px",
                width: "15px",
                height: "15px"}}
            className="fixedmodalexitimg w-inline-block"
          />

      <div className="AIPRM__flex AIPRM__w-full">
        <div className="AIPRM__ml-2">
        <label
            htmlFor="toneSelect"
            className="AIPRM__block AIPRM__text-sm AIPRM__font-medium"
          >
            Objective
          </label>

          <select
            value={props.objective}
            name="objective"
            id="toneSelect"
            className="AIPRM__bg-gray-100 AIPRM__border-0 AIPRM__text-sm AIPRM__rounded AIPRM__block AIPRM__w-full dark:AIPRM__bg-gray-600 dark:AIPRM__border-gray-600 dark:hover:AIPRM__bg-gray-900 dark:AIPRM__placeholder-gray-400 dark:AIPRM__text-white hover:AIPRM__bg-gray-200 focus:AIPRM__ring-0"
            style={{padding: "0.5rem 0.75rem"}}
            onChange={props.handleChange}
            >
                <option value="Default" name="objective">Default</option>
                <option value="Awareness" name="objective">Awareness</option>
                <option value="Sales" name="objective">Sales</option>
                <option value="Engagement" name="objective">Engagement</option>
                <option value="Retargeting" name="objective">Retargeting</option>
            </select>

            

            

        </div>
        <div className="AIPRM__ml-2">
        <label
            htmlFor="toneSelect"
            className="AIPRM__block AIPRM__text-sm AIPRM__font-medium"
          >
            Location
          </label>

            <select
            value={props.location}
            name="location"
            id="toneSelect"
            className="AIPRM__bg-gray-100 AIPRM__border-0 AIPRM__text-sm AIPRM__rounded AIPRM__block AIPRM__w-full dark:AIPRM__bg-gray-600 dark:AIPRM__border-gray-600 dark:hover:AIPRM__bg-gray-900 dark:AIPRM__placeholder-gray-400 dark:AIPRM__text-white hover:AIPRM__bg-gray-200 focus:AIPRM__ring-0"
            style={{padding: "0.5rem 0.75rem"}}
            onChange={props.handleChange}
            >
                <option value="United States" name="location">United States</option>
                <option value="Nigeria" name="location">Nigeria</option>
                
            </select>
        </div>
        <div className="AIPRM__ml-2">
          <label
            htmlFor="toneSelect"
            className="AIPRM__block AIPRM__text-sm AIPRM__font-medium"
          >
            Platform
          </label>

            <select
            value={props.platform}
            name="platform"
            id="toneSelect"
            className="AIPRM__bg-gray-100 AIPRM__border-0 AIPRM__text-sm AIPRM__rounded AIPRM__block AIPRM__w-full dark:AIPRM__bg-gray-600 dark:AIPRM__border-gray-600 dark:hover:AIPRM__bg-gray-900 dark:AIPRM__placeholder-gray-400 dark:AIPRM__text-white hover:AIPRM__bg-gray-200 focus:AIPRM__ring-0"
            style={{padding: "0.5rem 0.75rem"}}
            onChange={props.handleChange}
            >
                <option value="Default" name="platform">Default</option>
                <option value="Facebook" name="platform">Facebook</option>
                <option value="Instagram" name="platform">Instagram</option>
                <option value="Facebook & Instagram" name="platform">Facebook & Instagram</option>
                
            </select>
        </div>
      </div>
    </div>
  );
}

export default Specifier;
