import firebase from "../../firebaseConfig";
import getRegistrationProgress from "./getRegistrationProgress";

const saveRegisterProgress = (id, step) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const currentStep = await getRegistrationProgress(id);

      await db
        .collection("sellersAccounts")
        .doc(id)
        .set(
          {
            registrationStep: step > currentStep ? step : currentStep,
          },
          { merge: true }
        );
      resolve("success");
    } catch (error) {
      console.log("error saving registration progress", error);
      reject(error);
    }
  });
};
export default saveRegisterProgress;
