import React from "react";
import getResources from "../helpers/getResources";
import getBlogs from "../helpers/getBlogs";

class RightNav extends React.Component {
  state = {
    resources: [],
    blogs: [],
  };

  async componentDidMount() {
    const resources = await getResources();
    const blogs = await getBlogs();
    this.setState({ resources, blogs });
  }

  render() {
    return (
      <div className="panel-right">
        <div className="holder-resources">
          <div className="holder-panel-title">
            <h5 className="section-title">Growth Resources</h5>
          </div>
          <div className="block-holder">
            <div className="block-blog">
              <h6 className="title-sub-section">Key Growth Resources</h6>
              {this.state.blogs && typeof this.state.blogs !== "undefined"
                ? this.state.blogs.map((blog, i) => (
                    <div className="blog-link" key={i}>
                      <a
                        key={i}
                        style={{ textDecoration: "none", color: "unset" }}
                        target="_blank"
                        href={blog.link}
                      >
                        <div className="blog-title">{blog.title}</div>
                      </a>
                    </div>
                  ))
                : null}
            </div>
            <div className="block-videos">
              <h6 className="title-sub-section">Key Video Resources</h6>
              <div className="video-link">
                {this.state.resources &&
                typeof this.state.resources !== "undefined"
                  ? this.state.resources.map((resource, i) => (
                      <a
                        key={i}
                        style={{ textDecoration: "none", color: "unset" }}
                        target="_blank"
                        href={resource.link}
                      >
                        <div
                          style={{ paddingBottom: "10px" }}
                          className="blog-title"
                        >
                          {resource.title}
                        </div>
                      </a>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RightNav;
