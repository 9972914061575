import React, { Component } from "react";

export default class DropdownTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  handleMouseIn() {
    this.setState({ hover: true });
  }

  handleMouseOut() {
    this.setState({ hover: false });
  }

  render() {
    const tooltipStyle = {
      width: "50%",
      maxWidth:"300px",
      backgroundColor: "white",
      color: "grey",
      textAlign: "left",
      padding: "10px 5px 5px 10px",
      borderRadius: "6px",
      margin: "auto",
      zIndex:"1",
      /* Position the tooltip text - see examples below! */
      position: "absolute",
      visibility: this.state.hover ? "visible" : "hidden"
    };
    

    return (
      <div>
        <div
          onMouseOver={this.handleMouseIn.bind(this)}
          onMouseOut={this.handleMouseOut.bind(this)}
          className="tooltip"
        >
          <i className="fa fa-question-circle"></i>
        </div>
        <div>
          <div style={tooltipStyle}>
          <div style={{"textDecoration": "underline"}}>Schedule Pickup</div>
            <div className="box-title">
              You can deliver or ship certain orders using our couriers. Not
              available if you've received funds for the delivery.
            </div>
            <br/>
            <div style={{"textDecoration": "underline"}}>Mark As Delivered</div>
            <div className="box-title">If you've fulfilled and delivered the order.</div>
            <br/>
            <div style={{"textDecoration": "underline"}}>Email Customer</div>
            <div className="box-title">You can reach out for more info from the buyer</div>
          </div>
        </div>
      </div>
    );
  }
}
