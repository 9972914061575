import firebase from "../../firebaseConfig";

const updateAllOrders = (
  orderIds,
  sellerId,
  customerId,
  status
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let batch = db.batch();

      await Promise.all(
        orderIds.map((orderId) => {
          let sellerRef = db
            .collection("sellersAccounts")
            .doc(sellerId)
            .collection("stores")
            .doc(sellerId)
            .collection("orders")
            .doc(orderId);

          let buyerRef = db
            .collection("buyersAccounts")
            .doc(customerId)
            .collection("orders")
            .doc(orderId);


          batch.set(
            sellerRef,
            {
              orderStatus: status,
            },
            { merge: true }
          );

          batch.set(
            buyerRef,
            {
              orderStatus: status,
              
            },
            { merge: true }
          );

        })
      );

      await batch.commit();

      resolve("success");
    } catch (error) {
      console.log("error saving shipping label", error);
      reject(error);
    }
  });
};
export default updateAllOrders;
