import firebase from "../../firebaseConfig";
/**
 * Contains all functions to perform CRUD operations to the bookings collection
 */

/**
 * Gets firebase reference for the bookings collection in sellers account
 * @param {String} storeId 
 */
const getStoreDocRef = (storeId) => {
    const db = firebase.firestore();
    var docRef = db
        .collection("sellersAccounts")
        .doc(storeId)
        .collection("stores")
        .doc(storeId)
        .collection("bookings")
    return docRef
}

/**
 * Gets firebase reference for the bookings collection in buyers account
 * @param {String} userId 
 */
const getCustomerDocRef = (userId) => {
    const db = firebase.firestore();
    var docRef = db
        .collection("buyersAccounts")
        .doc(userId)
        .collection("bookings")
    return docRef
}

/**
 * Saves a booking information to firebase
 * @param {String} storeId 
 * @param {String} userId 
 * @param {{}} bookingInfo 
 */
export const saveBooking = async (storeId, userId, bookingInfo) => {

    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId).doc()
            const customerRef = getCustomerDocRef(userId).doc(storeRef.id)

            await storeRef.set({ id: storeRef.id, ...bookingInfo })
            await customerRef.set({ id: storeRef.id, ...bookingInfo })
            resolve("Booking saved")
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Updates a booking
 * @param {String} storeId 
 * @param {String} userId 
 * @param {{}} bookingInfo 
 */
export const updateBooking = async (storeId, userId, bookingInfo) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId).doc(bookingInfo.id)
            const customerRef = getCustomerDocRef(userId).doc(bookingInfo.id)

            await storeRef.set(bookingInfo)
            await customerRef.set(bookingInfo)
            resolve("Booking cancelled")
        } catch (err) {
            reject(err)
        }
    })
}

// /**
//  * Updates a booking with a status of completed
//  * @param {String} storeId 
//  * @param {String} userId 
//  * @param {{}} bookingInfo 
//  */
// export const markAsCompleted = async (storeId, userId, bookingInfo) => {
//     return new Promise(async (resolve, reject) => {
//         try {
//             const storeRef = getStoreDocRef(storeId).doc(bookingInfo.id)
//             const customerRef = getCustomerDocRef(userId).doc(bookingInfo.id)

//             await storeRef.set(bookingInfo)
//             await customerRef.set(bookingInfo)
//             resolve("Booking cancelled")
//         } catch (err) {
//             reject(err)
//         }
//     })
// }

/**
 * Gets all bookings for a day
 * @param {String} storeId 
 * @param {String} dayDate eg "12/1/2020"
 */
export const getBookingForADay = async (storeId, dayDate) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId)
            const data = await storeRef.where("date", "==", dayDate).orderBy("bookingTimestamp").get()
            const bookings = data.docs.map(booking => booking.data())
            resolve(bookings)
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Gets upcoming bookings
 * @param {String} storeId 
 */
export const getUpcomingBookings = async (storeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId)
            const data = await storeRef.where("bookingTimestamp", ">=", Date.now()).where("status", "==", "Pending").get()
            console.log("BOOKING DATA::", data)
            const bookings = data.docs.map(booking => booking.data())
            resolve(bookings)
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Gets past bookings
 * @param {String} storeId 
 */
export const getPastBookings = async (storeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId)
            const data = await storeRef.where("bookingTimestamp", "<", Date.now()).where("status", "==", "Pending").orderBy("bookingTimestamp").get()
            const bookings = data.docs.map(booking => booking.data())
            resolve(bookings)
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Gets cancelled bookings
 * @param {Sring} storeId 
 */
export const getCancelledBookings = async (storeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId)
            const data = await storeRef.where("status", "==", "Cancelled").orderBy("bookingTimestamp").get()
            const bookings = data.docs.map(booking => booking.data())
            resolve(bookings)
        } catch (err) {
            reject(err)
        }
    })
}

/**
 * Gets Completed bookings
 * @param {Stirng} storeId 
 */
export const getCompletedBookings = async (storeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storeRef = getStoreDocRef(storeId)
            const data = await storeRef.where("status", "==", "Completed").orderBy("bookingTimestamp").get()
            const bookings = data.docs.map(booking => booking.data())
            resolve(bookings)
        } catch (err) {
            reject(err)
        }
    })
}