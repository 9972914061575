import React from "react";
import getDateFromTimestamp from "../../../helpers/getDateFromTimestamp";

function OrderItem(props) {
  let currency = props.currency?.toLowerCase();
  let customerName=props.customerName;
  if(customerName){
    customerName = customerName.charAt(0).toUpperCase() + customerName.slice(1);
  }
  
  let date = getDateFromTimestamp(props.date);
  let string = props.status;
  let status = string.charAt(0).toUpperCase() + string.slice(1);

  if (status.toLowerCase() === "completed") {
    status = "New";
  } else if (status.toLowerCase() === "fulfilled") {
    status = "Delivered";
  }

  return (
    <a
      className="orderlistitem white w-inline-block"
      onClick={() => props.showTransactionDetails(props.transactionDetails)}
    >
      <div className="orderlistnumber">{props.index + 1}.</div>
      <div className="orderlisttextwrap">
        <div className="orderlistorderstitle mobile">Orders</div>
        <div className="orderlistorderstext id-name">
          <div>{props.transactionId}</div>
          <div>{customerName}</div>
        </div>
      </div>
      <div className="orderlisttextwrap">
        <div className="orderlistdatetitle mobile">Date</div>
        <div className="orderlistdatetext">{date}</div>
      </div>
      {props.status == "completed" ? (
        <div className="orderlisttextwrap">
          <div className="orderliststatustitle mobile">Status</div>
          <div className="orderliststatus complated">
            <div>{status}</div>
          </div>
        </div>
      ) : props.status == "dispatched" ? (
        <div className="orderlisttextwrap">
          <div className="orderliststatustitle mobile">Status</div>
          <div className="orderliststatus processing">
            <div>{status}</div>
          </div>
        </div>
      ) : props.status == "fulfilled" ? (
        <div className="orderlisttextwrap">
          <div className="orderliststatustitle mobile">Status</div>
          <div className="orderliststatus delivered">
            <div>{status}</div>
          </div>
        </div>
      ) : (
        <div className="orderlisttextwrap">
          <div className="orderliststatustitle mobile">Status</div>
          <div className="orderliststatus cancelled">
            <div>{status}</div>
          </div>
        </div>
      )}

      <div className="orderlisttextwrap">
        <div className="orderlistdeliverytitle mobile">Delivery</div>
        {/* <div className="orderlistdeliverytext">DHL Express</div> */}
        {props.getCorrectShippingType(props.shippingCarrierAccount)}
      </div>
      <div className="orderlisttextwrap lastitem">
        <div className="orderlisttotaltitle mobile">Total</div>
        <div className="orderlisttotaltext">
          {currency == "usd"
            ? "$"
            : currency == "cad"
            ? "CA$"
            : currency == "ngn"
            ? "₦"
            : currency == "gbp"
            ? "£"
            : "$"}
          {props.amount}
        </div>
      </div>
      <div className="orderlisttextwrap lastitem">
        <div className="orderlistactionstitle mobile">Actions</div>
        <div className="receipt" />
        <div className="actionbox">
          <div className="orderlistactionsbox" />
          <div className="orderlistactionsbox" />
        </div>
      </div>
      <div className="orderlistpag" />
    </a>
  );
}

export default OrderItem;
