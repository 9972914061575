import firebase from "../../firebaseConfig";

const deleteStoreDiscount = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();

      await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .update({
          storeDiscount: firebase.firestore.FieldValue.delete(),
        });
      resolve("success");
    } catch (error) {
      console.log("error adding store discount", error);
      reject(error);
    }
  });
};
export default deleteStoreDiscount;
