import React, { Component } from "react";
import anon_buyer from "../../../../../images/anon_buyer.png";

export default class Avatar extends Component {
  constructor(props) {
    super(props);
  }

  state={
    backgroundColor:"",

  }

  componentDidMount() {
    let buyerName=this.props.buyerName;
    let nameArray=this.props.buyerName?.split(" ");

    let avatarLetter=nameArray[0]?.charAt(0);
    if(nameArray?.length>1){
      avatarLetter=nameArray[0]?.charAt(0)+nameArray[1]?.charAt(0);
    }
    let backgroundColor="#512da8";

    if(/^[A-F]/.test(buyerName)){
      backgroundColor="#5eba7d";

    }else if(/^[G-L]/.test(buyerName)){
      backgroundColor="#0b95ff"
    }else if(/^[M-Q]/.test(buyerName)){
      backgroundColor="#f48225"
    }else if(/^[R-U]/.test(buyerName)){
      backgroundColor="##512da8"
    }else if(/^[V-X]/.test(buyerName)){
      backgroundColor="#e05151"
    }else{
      backgroundColor="#000000"
    }

    if(buyerName=="Anonymous"){
      backgroundColor="#000000"
    }

    this.setState({
      avatarLetter,
      backgroundColor
    })

  }

  componentDidUpdate(prevProps) {
    if (
      this.props.buyerName !==
      prevProps.buyerName
    ) {

      let buyerName=this.props.buyerName;
      let nameArray=this.props.buyerName?.split(" ");
  
      let avatarLetter=nameArray[0]?.charAt(0);
      if(nameArray?.length>1){
        avatarLetter=nameArray[0]?.charAt(0)+nameArray[1]?.charAt(0);
      }
      let backgroundColor="#512da8";
  
      if(/^[A-F]/.test(buyerName)){
        backgroundColor="#5eba7d";
  
      }else if(/^[G-L]/.test(buyerName)){
        backgroundColor="#0b95ff"
      }else if(/^[M-Q]/.test(buyerName)){
        backgroundColor="#f48225"
      }else if(/^[R-U]/.test(buyerName)){
        backgroundColor="##512da8"
      }else if(/^[V-X]/.test(buyerName)){
        backgroundColor="#e05151"
      }else{
        backgroundColor="#000000"
      }
  
  
      this.setState({
        avatarLetter,
        backgroundColor
      })


    }

  }
  render() {
    return (
      <div className="avatar">
        {/* <div className="avatar-img">
          <img src={this.props.image} alt="profile-pic" loading="lazy"/>
        </div> */}
        <div 
          className="avatar-img avatar-letter"
          style={{backgroundColor:this.state.backgroundColor}}
          >
          {this.state.avatarLetter}
        </div>
        {/* <span className={`isOnline ${this.props.isOnline}`}></span> */}
      </div>
    );
  }
}
