import React from "react";

function SelectStates(props) {
  return (
    <select
      onChange={props.handleDeliveryChange} 
      className={`mc-input input-text w-input ${
        props.stateError ? "input-error" : ""
          }`}
      placeholder="state" name={props.name}
      value={props.state}
    //   onChange={props.selectState}
    >
      <option disabled value>
        Select State
      </option>
      <option value="Lagos" name="state">
        Lagos
      </option>
      <option value="Abia" name="state">
        Abia
      </option>
      <option value="Abuja" name="state">
        Abuja
      </option>
      <option value="Adamawa" name="state">
        Adamawa
      </option>
      <option value="Akwa Ibom" name="state">
        Akwa Ibom
      </option>
      <option value="Anambra" name="state">
        Anambra
      </option>
      <option value="Bauchi" name="state">
        Bauchi
      </option>
      <option value="Bayelsa" name="state">
        Bayelsa
      </option>
      <option value="Benue" name="state">
        Benue
      </option>
      <option value="Borno" name="state">
        Borno
      </option>
      <option value="Cross River" name="state">
        Cross River
      </option>
      <option value="Delta" name="state">
        Delta
      </option>
      <option value="Ebonyi" name="state">
        Ebonyi
      </option>
      <option value="Edo" name="state">
        Edo
      </option>
      <option value="Ekiti" name="state">
        Ekiti
      </option>
      <option value="Enugu" name="state">
        Enugu
      </option>
      <option value="Gombe" name="state">
        Gombe
      </option>
      <option value="Imo" name="state">
        Imo
      </option>
      <option value="Jigawa" name="state">
        Jigawa
      </option>
      <option value="Kaduna" name="state">
        Kaduna
      </option>
      <option value="Kano" name="state">
        Kano
      </option>
      <option value="Katsina" name="state">
        Katsina
      </option>
      <option value="Kebbi" name="state">
        Kebbi
      </option>
      <option value="Kogi" name="state">
        Kogi
      </option>
      <option value="Kwara" name="state">
        Kwara
      </option>
      <option value="Nasarawa" name="state">
        Nasarawa
      </option>
      <option value="Niger" name="state">
        Niger
      </option>
      <option value="Ogun" name="state">
        Ogun
      </option>
      <option value="Ondo" name="state">
        Ondo
      </option>
      <option value="Osun" name="state">
        Osun
      </option>
      <option value="Oyo" name="state">
        Oyo
      </option>
      <option value="Plateau" name="state">
        Plateau
      </option>
      <option value="Rivers" name="state">
        Rivers
      </option>
      <option value="Sokoto" name="state">
        Sokoto
      </option>
      <option value="Taraba" name="state">
        Taraba
      </option>
      <option value="Yobe" name="state">
        Yobe
      </option>
    </select>
  );
}

export default SelectStates;
