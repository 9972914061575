import React, { Component } from "react";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import BottomNav from "../../../common/BottomNav";

import getUserLocation from "../../../helpers/getUserLocation";
import getBasicInfo from "../../../helpers/getBasicInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getReferrals from "../../../helpers/getReferrals";
import NProgress from "nprogress";
import Metatags from "react-meta-tags";
import AdsHelp from "./AdsHelp";

import firebase from "../../../../firebaseConfig";

class AdsDashboard extends Component {
  state = {
    firstName: "",
    cartCount: 0,
    shoppingpoints: 10,
    phoneNumber: "",
    userInfo: {},
    isLoggedIn: false,

    accountNumber: "",
    bankName: "",
    paypalEmail: "",
    paypalPhone: "",
    displayPaypal: false,
    displayFlutterwave: false,
    customerCount: -1,
    addedToCart: -1,
  };

  selectCategory=(category)=>{
    this.setState({
      selectedCategory:category
    }) 
  }

  getAdsHelp = async() => {

    try{

        console.log("getAdsHelp")
        const db = firebase.firestore();
    
        let adsHelp = await db.collection("adsHelp")
          .limit(1)
          .get();    
    
        console.log("featurePromo",adsHelp)
        let pageContent = null;
        if (adsHelp.empty) {
          console.log("No promo found");
          return;
        } else {
            pageContent = adsHelp.docs[0].data();
        }
        console.log("pageContent =>", pageContent);
    
        this.setState({
          blogs: pageContent?.blogs,
          adsRecommendation: pageContent?.adsRecommendation,
          news: pageContent?.news,
          tips: pageContent?.tipsOfTheDay,
          trendingArticles: pageContent?.trendingArticles
        })

    }catch(error){
        console.log("getAdsHelp err",error)
    }

  }

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  fetchSellerInfo = async (id) => {
    try {
      console.log("fetchSellerInfo", id);
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let shortReferral = id?.slice(0, 6);
      shortReferral = shortReferral?.toUpperCase();

      let { referralCount, successfullReferral } = await getReferrals(
        shortReferral
      );
      let totalReferrals = referralCount;
      console.log("totalReferrals", totalReferrals);
      if (!totalReferrals) {
        totalReferrals = 0;
      }

      let {
        firstName,
        lastName,
        slug,
        activated,
        storeName,
        phoneNumber,
        referralPayment,
        subscriptionActive,
        subscriptionType,
      } = basicInfo;

      // if (!basicInfo?.subscriptionActive) {
      //   return this.props.history.push("/seller/dashboard/rewards");
      // } else {
      //   console.log("subscriptionActive", subscriptionActive);
      // }

      let totalCashedOut = 0;
      let cashedoutReferrals = 0;
      if (referralPayment) {
        referralPayment.forEach((payment) => {
          console.log("Payment", payment);
          totalCashedOut += payment.cashedOutAmount;
          cashedoutReferrals += payment?.completedReferral;
        });
      }
      let successRate = 0;
      if (
        totalReferrals &&
        successfullReferral &&
        cashedoutReferrals <= totalReferrals
      ) {
        successRate = (successfullReferral / totalReferrals) * 100;
        successRate = Math.round(successRate);
      }

      let storeURL = `http://www.cashaam.com/${slug}`;
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        activated: typeof activated !== "undefined" ? activated : true,
        storeInfo: basicInfo,
        totalReferrals,
        totalCashedOut,
        cashedoutReferrals,
        successRate,
        successfullReferral,
        country: basicInfo?.country,
      });

      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData", { locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  loadHubspot = () => {
    let script = document.createElement("script");
    script.src = "//js.hs-scripts.com/21585626.js";
    script.id = "hs-script-loader";
    script.addEventListener("load", () => {
      console.log("hubspotLoaded");
      this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  };

  loadPaystack = () => {
    let script = document.createElement("script");
    script.src = "https://js.paystack.co/v1/inline.js";
    script.addEventListener("load", () => {
      console.log("paystack loaded");
      this.setState({ paystackLoaded: true });
    });
    document.body.appendChild(script);
  };

  loadPendo = () => {
    let script = document.createElement("script");

    let pendoKey = "6ff8873a-3edf-4dc1-6d19-5a3490191d2a";

    script.src = "https://cdn.pendo.io/agent/static/" + pendoKey + "/pendo.js";
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);
  };


  async componentDidMount() {
    try {
      NProgress.start();

      this.loadPaystack();
      this.loadHubspot();
      this.loadPendo();
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          if (this.state.pendoLoaded) {
            console.log("initialize pendo");
            pendo.initialize({
              visitor: {
                id: user.uid, // Required if user is logged in
                email: user?.email, // Recommended if using Pendo Feedback, or NPS Email
                full_name: user?.displayName, // Recommended if using Pendo Feedback
                role: "seller",
              },

              account: {
                id: user.uid,
                planLevel: "seller",
                userType: "seller",
              },
            });
          }

          let shortReferral = user.uid?.slice(0, 6);
          shortReferral = shortReferral.toUpperCase();

          console.log("shortReferral", shortReferral);
          let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

          this.setState({
            shortReferral,
            referralLink,
            sellerId: user.uid,
          });
          localStorage.setItem("cashaam_uid", user.uid);
          this.getLocation();

          //check the user registration progress
          let step = await getRegistrationProgress(user.uid);

          step = parseFloat(step);
          if (step === 100) {
            // this.props.history.push("/seller/dashboard");
            console.log("completed registration step:", step);
          } else {
            console.log("MY STEP", step);
            if (step < 4) {
              this.props.history.push("/seller/dashboard/setup/onboarding");
            }
          }

          NProgress.set(0.5);
          this.getAdsHelp();

          NProgress.set(0.7);
          const sellerUid = user.uid;
          await this.fetchSellerInfo(sellerUid);

          NProgress.done();
        } else {
          this.props.history.push("/");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Billing & Referral</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Chat" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>

        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />

        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />

            <AdsHelp 
                blogs={this.state.blogs}
                adsRecommendation={this.state.adsRecommendation} 
                news={this.state.news}
                tips={this.state.tips}
                trendingArticles={this.state.trendingArticles}
                selectCategory={this.selectCategory}
                selectedCategory={this.state.selectedCategory}

            />

            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AdsDashboard;
