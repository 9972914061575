import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/cashaam-logo.png";
import logoWhite from "../../images/cashaam-logo-white.png";

const ConfirmCode = (props) => {
  return (
    <div className="section-full">
      <Link to="/" className="logo-bg-dark w-inline-block">
        {/* <img
          src={logo}
          //   srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
          sizes="100vw"
          alt
          className="cashaam-logo-dark"
        /> */}
        <img
          src={logoWhite}
          //   srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
          sizes="(max-width: 479px) 60px, (max-width: 767px) 90px, 100px"
          alt
          className="cashaam-logo-white"
        />
      </Link>
      <div className="container-left">
        <div className="bg-img-login">
          <div className="holder-copy">
            <h1 className="h1">A better way to sell online.</h1>
            <h1 className="h1 hidden">Create Your Free Account</h1>
            <h3 className="h3 hidden">Sell on your Website, Amazon and more.</h3>
            <h3 className="h3">Sell on your Website, Amazon and more.</h3>
            <div className="copy-18">
              You can have your store up and ready to launch in 15 minutes or
              less.
            </div>
            <div className="copy-18 hidden">
              Sell more, grow your business online. Millions of customers are
              waiting! We can help you reach more people and expose your
              business for it for free.
            </div>
          </div>
        </div>
      </div>
      <div className="container-right">
        <div className="holder-signup-otp">
          <div className="verify-otp">
            <h1 className="h2">Verify Your Phone Number</h1>
            <div className="microcopy-signup">
              Enter the verification code sent to your phone number **** ****{" "}
              {props.phoneNumber.slice(
                props.phoneNumber.length -
                  (props.phoneNumber.length - (props.phoneNumber.length - 4)),
                props.phoneNumber.length
              )}
            </div>
            {props.errorMessage !== null ? (
              <div className="holder-error otp">
                <div className="txt-error">{props.errorMessage}</div>
              </div>
            ) : null}
            <div className="form-signup w-form">
              <form
                onSubmit={props.onSubmit}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form"
              >
                <input
                  onChange={props.onChange}
                  type="confirmationCode"
                  className="input-text w-input"
                  maxLength={256}
                  name="confirmationCode"
                  data-name="confirmationCode"
                  placeholder="Verification code"
                  id="confirmationCode"
                  required
                />
                {props.displayLoading === false ? (
                  <input
                    style={{ fontSize: "16px" }}
                    type="submit"
                    value="Next"
                    defaultValue="Next"
                    data-wait="Please wait..."
                    className="submit-button w-button"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="submit-button w-button"
                  >
                    <div
                      style={{ borderTopColor: "#6740aa" }}
                      className="loader"
                    ></div>
                  </div>
                )}
              </form>
              {/* <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div> */}
              {props.errorMessage ? (
                <div style={{ display: "flex" }} className="w-form-fail">
                  <div>{props.errorMessage}</div>
                </div>
              ) : null}
            </div>
            <div
              onClick={props.onResendVerificationCode}
              style={{ cursor: "pointer" }}
              className="btn-secondary"
            >
              <div className="text-btn-secondary">Resend Code</div>
            </div>
            <div className="divider">
              <div style={{ textAlign: "center" }} className="text-block">
                or
              </div>
            </div>
            <div
              onClick={props.tryAnotherMethod}
              style={{ cursor: "pointer" }}
              className="btn-tertiary"
            >
              <div className="text-btn-secondary">
                Try another way to sign up
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmCode;
