import firebase from "../../firebaseConfig";
import addToCloudinaryDeleteList from "./addToCloudinaryDeleteList";

const uploadSmallBanner = (sellerId, url, prevUrl) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .set(
          {
            customSmallBanner: url,
          },
          { merge: true }
        );

      if (prevUrl && prevUrl.includes("http://res.cloudinary.com")) {
        await addToCloudinaryDeleteList(prevUrl);
      }

      resolve(true);
    } catch (error) {
      console.log("error uploading big banner", error);
      reject(error);
    }
  });
};
export default uploadSmallBanner;
