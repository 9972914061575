import firebase from "../../firebaseConfig";

const getStoreDiscount = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("sellerId", sellerId);
      const db = firebase.firestore();

    let storeDoc =  await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId).get();
    let storeDiscount = storeDoc.data().storeDiscount ? storeDoc.data().storeDiscount : null;
        // .set(
        //   {
        //     storeDiscountName: storeDiscountName,
        //     storeDiscountValue: storeDiscountValue,
        //   },
        //   { merge: true }
        // );
      resolve(storeDiscount);
    } catch (error) {
      console.log("error adding store discount", error);
      reject(error);
    }
  });
};
export default getStoreDiscount;
