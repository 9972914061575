import React from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ConfirmCode from "./ConfirmCode";

const PhoneLogin = (props) => {
  return (
    <div className="container-right">
      <div className="holder-signup-otp">
        <div style={{ display: "flex" }} className="signup mobile-signing">
          <h1 className="h2">Log into your Account</h1>
          {props.showConfirmationCodeInput === false ? <div className="microcopy-signup">Login with your Phone number </div> : <div className="microcopy-signup">Please enter your One Time Password - OTP </div>}
          <div className="form-signup w-form">
            {props.showConfirmationCodeInput === false ? (
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form"
                onSubmit={props.loginWithPhone}
              >
                <PhoneInput
                onlyCountries={["us", "ng"]}
                  inputClass="input-text w-input"
                  inputStyle={{
                    minWidth: "100%",
                  }}
                  containerStyle={{
                    marginBottom: "8px",
                  }}
                  country={props.location !== "nigeria" ? "us" : "ng"}
                  value={props.mobile}
                  onChange={(mobile) => props.handlePhoneInput(mobile)}
                />
                {props.loading === false ? (
                  <input
                    type="submit"
                    value="Login"
                    defaultValue="Login"
                    data-wait="Please wait..."
                    className="submit-button w-button"
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="submit-button w-button"
                  >
                    <div
                      style={{ borderTopColor: "#6740aa" }}
                      className="loader"
                    ></div>
                  </div>
                )}
                {props.loading === false ? (
                  <a
                  style={{ cursor: "pointer" }}
                  onClick={() => props.goBackToNormalLogin()}
                  className="btn-secondary"
                >
                  <div className="text-btn-secondary">Login with Email</div>{" "}
                </a>
                ) : null}
              </form>
            ) : (
              <ConfirmCode
                onChangeHandler={props.onChangeHandler}
                submitConfResult={props.submitConfResult}
                loading={props.loading}
                goBackToNormalLogin={props.goBackToNormalLogin}
              />
            )}
            {/* <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div> */}
            {/* {props.errorMessage !== null ? (
              <div style={{ display: "flex" }} className="w-form-fail">
                <div>{props.errorMessage}</div>
              </div>
            ) : null} */}
          </div>
          <Link style={{ textDecoration: "none" }} to="/seller/signup">
            <div style={{ cursor: "pointer"}} className="microcopy-signup">
              Don't have an account ? <span style={{ textDecoration: "underline", color: "#261ab1"}}>Sign up</span>
            </div>{" "}
          </Link>

          <div className="terms-conditions">
              By continuing, you agree to the <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Terms of Service</a>, <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Privacy Policy</a> and <a className="link-blue" href="https://business.cashaam.com/terms-of-use">Cookie Use</a>.
          </div>

        </div>
      </div>
    </div>
  );
};
export default PhoneLogin;
