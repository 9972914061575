import { UPDATE_USER_CREDS_API_URL } from "../../secrets";

const updateUserCreds = (userId, firstName, email, password, phoneNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryData = {
        uid: userId,
        email: email,
        phoneNumber: phoneNumber,
        password: password,
        firstName: firstName,
      };
      const res = await fetch(UPDATE_USER_CREDS_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });

      if (res.status === 200) {
        const userRecord = await res.json();
        console.log("user recs", userRecord);
        resolve(userRecord);
      } else {
        console.log("Error updating user auth credentials", res);
        reject(res);
      }
    } catch (error) {
      console.log("Error updating user auth credentials", error);
      reject(error);
    }
  });
};
export default updateUserCreds;
