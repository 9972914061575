import React from 'react'

import styles from './AvailabilityDisplay.module.css'

import { reverseTimeMap } from "../../../../../constants";

const AvailabilityDisplay = (props) => {
    return (
        <div>
            {
                !props.availability ?
                    <div>Add your availability</div>
                    :
                    <div style={{ marginTop: 10 }}>
                        {props.availability.map((availability, i) => {
                            return (
                                <div className={styles.timeRow} style={{ marginTop: 10 }}>
                                    <div>
                                        <p>{availability.day}</p>
                                    </div>
                                    <div>
                                        {availability.isOpen ? "Open" : "Close"}
                                    </div>
                                    <div>
                                        {
                                            availability.hours.map((hour, index) => {
                                                if(hour.open === 24) return <div>24 hours</div>
                                                return (
                                                    <div>
                                                        {reverseTimeMap[hour.open]} - {reverseTimeMap[hour.close]}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
            }
        </div>
    )
}

export default AvailabilityDisplay
