import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import API_KEY from "../../../../secrets";
import { Link } from "react-router-dom";
import UpdateStoreSuccess from "../../../common/UpdateStoreSuccess";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectStates from "../../dashboard/orders/SelectStates";
import NigerianAddresses from '../../../helpers/NigerianAddresses.json';
import PickupPopup from "./PickupPopup";

const options = [
  { label: "cosmetic", value: 0 },
  { label: "electronics", value: 1 },
  { label: "fashion", value: 2 },
  { label: "food", value: 3 },
  { label: "fragrances", value: 4 },
  { label: "home", value: 5 },
  { label: "media", value: 6 },
  { label: "clothing", value: 7 },
  { label: "shoes", value: 8 },
  { label: "jewelry", value: 9 },
  { label: "watches", value: 10 },
  { label: "movies", value: 11 },
  { label: "music", value: 12 },
  { label: "games", value: 13 },
  { label: "computers", value: 14 },
  { label: "office_equipment", value: 15 },
  { label: "home_equipment", value: 16 },
  { label: "garden_tools", value: 17 },
  { label: "tools", value: 18 },
  { label: "groceries", value: 19 },
  { label: "toys", value: 20 },
  { label: "kids", value: 21 },
  { label: "baby_products", value: 22 },
  { label: "sports", value: 23 },
  { label: "automotive", value: 24 },
  { label: "industrial", value: 25 },
  { label: "handmade", value: 26 },
];

const optionsToValues = {
  cosmetic: 0,
  electronics: 1,
  fashion: 2,
  food: 3,
  fragrances: 4,
  home: 5,
  media: 6,
  clothing: 7,
  shoes: 8,
  jewelry: 9,
  watches: 10,
  movies: 11,
  music: 12,
  games: 13,
  computers: 14,
  office_equipment: 15,
  home_equipment: 16,
  garden_tools: 17,
  tools: 18,
  groceries: 19,
  toys: 20,
  kids: 21,
  baby_products: 22,
  sports: 23,
  automotive: 24,
  industrial: 25,
  handmade: 26,
};

class UpdateStore extends Component {
  state = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    storeName: "",
    description: "",
    address: "",
    profileImage: [],
    shopLogo: [],
    storeCategories: "",
    socialInstagram: "",
    socialFacebook: "",
    socialTwitter: "",
    slug: "",
    previewURL: "",
    previewLogoURL: "",
    profileImageUploaded: false,
    logoImageUploaded: false,
    scriptLoaded: false,
    invalidSlug: false, //if they got to this point, this means that their slug was validated already when they set up their store
    takenSlugs: [],
    sellerName: "",
    updateSuccess: false,
    updateError: false,
    invalidStoreName: false,
    selected: [],
    alreadySuggestedSlug: false,
    spaceDetectetInSlug: false,
    upperCaseDetectedInSlug: false,
    specialCharDetectedInSlug: false,
    newStore: true,
    invalid: false,
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
    addressSelected: false,
    invalidAddress: false,
    pickup: false,
    slugChange: false
  };

  handlePickup = (pickupStatus) => {

    console.log(pickupStatus);
    this.setState({
      pickup: pickupStatus,
      showPickup: false
    })
  }


  hidePickup=()=>{

    this.setState({
      showPickup: false
    })
  }

  displayPickup=()=>{
    this.setState({
      showPickup: true
    })
  }

  handleChange = (e) => {
    if (e.target.name === "storeName") {
      const name = e.target.value;
      //check if name is longer than 20 chars
      this.validateStoreName(name);
      this.setState({
        [e.target.name]: name,
      });
    } else if (e.target.name === "socialInstagram") {
      if (e.target.value.length > 24) {
        const value = e.target.value.slice(26);
        this.setState({
          [e.target.name]: value,
        });
      }

      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "socialFacebook") {
      if (e.target.value.length > 24) {
        const value = e.target.value.slice(25);
        this.setState({
          [e.target.name]: value,
        });
      }

      this.setState({
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "socialTwitter") {
      if (e.target.value.length > 23) {
        const value = e.target.value.slice(24);
        this.setState({
          [e.target.name]: value,
        });
      }
    } else {
      console.log("e=>",e)
      this.setState({
        [e.target.name]: e.target.value,
      });

      if(e.target.name==="state"){
        let stateLGA=this.state.NigeriaLGA[e.target.value];
        this.setState({
          stateLGA
        })
      }
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // capture the current user uid
    const sellerUid = firebase.auth().currentUser.uid;
    const db = firebase.firestore();
    const storage = firebase.storage();
    let batch = db.batch();
    const {
      storeName,
      description,
      address,
      profileImage,
      shopLogo,
      socialInstagram,
      socialFacebook,
      socialTwitter,
      slug,
      storeCategories,
      invalidAddress,
      addressSelected,
    } = this.state;
    console.log("state--", this.state);

    const holdProfileImage = []; //uploaded images url will be stored here which will then be stored in firestore
    const holdLogoImage = [];

    if (this.state.country?.toLowerCase()!=="nigeria"&& addressSelected === false) {
      //validate address first before submiting
      await this.handleSelectAddress(address);
    }

    const storeDataToDatabase = async () => {
      try {
        //extract other data from the sellersAccounts/uid document
        let {
          firstName,
          lastName,
          email,
          phoneNumber,
          storeName,
          description,
          address,
          id,
          stars,
          status,
          socialInstagram,
          socialFacebook,
          socialTwitter,
          slug,
          storeCategories,
          addressSelected,
          street,
          city,
          state,
          country,
          zipcode,
          longitude,
          latitude,
          invalidAddress,
          pickup,
          apt
        } = this.state;

        if(country?.toLowerCase()=="nigeria"){
          address=`${street}, ${city}, ${state}, ${country}`;
        }

        this.setState({slugError:""});

        console.log("test PROF--", holdProfileImage);
        console.log("test LOGO--", holdLogoImage);
        // check for seller account in the collection 'sellersAccount' based on the seller's uid
        if (storeName && address && slug) {
          if (invalidAddress === true) {
            return toast.error("Invalid address, please enter a valid address");
          }

          if (this.state.invalidStoreName === true) {
            return toast.error(
              "Please enter a unique and valid url for your store"
            );
          } else if (this.state.invalidSlug === true) {
            return toast.error(
              "Please enter a unique and valid url for your store"
            );
          }

          let updatedSlug=this.state.slug;
          if(this.state.slugChange){
            updatedSlug=this.state.newSlug
          
            if(this.state.invalidSlug){
              this.setState({
                slugError: "Please enter a unique and valid url for your store"
              })
              return;
            }
          }

          let pickupStatus;
          if(pickup===true){
            pickupStatus="enabled"

          }else{
            pickupStatus="disabled"
          }

          //filter out the @ symbols if user entered them
          if (socialInstagram.includes("@")) {
            socialInstagram = socialInstagram.slice(1);
          }

          if (socialFacebook.includes("@")) {
            socialFacebook = socialFacebook.slice(1);
          }

          if (socialInstagram.includes("https://")) {
            //filter out https://www.instagram.com/ if user entered it
            socialInstagram = socialInstagram.slice(26);
          } else if (socialInstagram.includes("www.")) {
            //www.instagram.com/
            socialInstagram = socialInstagram.slice(18);
          } else if (socialInstagram.includes(".com")) {
            //instagram.com/
            socialInstagram = socialInstagram.slice(14);
          }

          //filter out https://www.facebook.com/
          if (socialFacebook.includes("https://")) {
            socialFacebook = socialFacebook.slice(25);
          } else if (socialFacebook.includes("www.")) {
            //www.instagram.com/
            socialFacebook = socialFacebook.slice(17);
          } else if (socialFacebook.includes(".com")) {
            //instagram.com/
            socialFacebook = socialFacebook.slice(13);
          }

          // const { city, state, country, longitude, latitude } = this.state;

          // create a sub-collection inside the sellers document to save the user's stores
          var docRef = db
            .collection("sellersAccounts")
            .doc(sellerUid)
            .collection("stores")
            .doc(sellerUid);

          console.log("pickupStatus",pickupStatus);

          batch.set(
            docRef,
            {
              firstName: firstName,
              lastName: lastName,
              email: email,
              phoneNumber: phoneNumber ? phoneNumber : "",
              storeName: storeName,
              description: description,
              address: address,
              id: id,
              stars: 5,
              status: "active",
              socialInstagram: socialInstagram ? socialInstagram : "",
              socialFacebook: socialFacebook ? socialFacebook : "",
              socialTwitter: socialTwitter ? socialTwitter : "",
              slug: slug,
              storeCategories: storeCategories
                ? Array.isArray(storeCategories) === true
                  ? storeCategories[0]
                  : storeCategories
                : "fashion",
              street,
              city,
              state,
              country,
              zipcode,
              apt:apt?apt:"",
              longitude,
              latitude,
              pickupStatus: pickupStatus,
              slug:updatedSlug
            },
            { merge: true }
          );

          var sellersAccountsRef = db
            .collection("sellersAccounts")
            .doc(sellerUid);
          batch.update(sellersAccountsRef, {
            storeName: storeName,
            slug: slug,
            description: description,
            storeCategories: storeCategories
              ? Array.isArray(storeCategories) === true
                ? storeCategories[0]
                : storeCategories
              : "fashion",
            address: address,
            street,
            city,
            state,
            country,
            zipcode,
            longitude,
            latitude,
            pickupStatus: pickupStatus,
            apt:apt?apt:"",
            slug:updatedSlug
          });

          batch
            .commit()
            .then(() => {
              // after the store has been setup direct the seller to the seller's dashboard
              // this.props.history.push("/seller/dashboard");
              // this.setState({
              //   updateSuccess: true,
              // });
              this.backToSettings();
            })
            .catch((err) => {
              this.setState({
                updateError: true,
              });
              console.log(err, "<--- error with saving store");
            });
          // });
        } else {
          toast.error("Please enter all necessary information");
          return;
        }
      } catch (error) {
        console.log("error saving data", error);
        toast.error("Error saving store info. Please try again.");
        return;
      }
    };
    storeDataToDatabase();
  };

  // handles the images change handle
  handleProfileImageChange = async (e) => {
    e.preventDefault();
    let previewFile = [];
    let tempURL = [];
    const inputFilesArray = Array.from(e.target.files);

    //show preview of uploaded image
    // eTargetFilesArray.push(...inputFilesArray);
    // tempFiles.push(...eTargetFilesArray);
    previewFile.push(...inputFilesArray);

    previewFile.forEach((file) => {
      const blob = URL.createObjectURL(file);
      tempURL.push(blob);
    });

    this.setState({
      previewURL: tempURL,
      profileImage: previewFile,
      profileImageUploaded: true,
    });
  };

  handleLogoImageChange = async (e) => {
    e.preventDefault();
    let previewFile = [];
    let tempURL = [];
    const inputFilesArray = Array.from(e.target.files);

    //show preview of uploaded image
    // eTargetFilesArray.push(...inputFilesArray);
    // tempFiles.push(...eTargetFilesArray);
    previewFile.push(...inputFilesArray);

    previewFile.forEach((file) => {
      const blob = URL.createObjectURL(file);
      tempURL.push(blob);
    });

    this.setState({
      previewLogoURL: tempURL,
      shopLogo: previewFile,
      logoImageUploaded: true,
    });
  };

  checkForSellerStore = () => {
    console.log(this.state);
    const db = firebase.firestore();
    const sellerUid = firebase.auth().currentUser.uid;
    return db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .get()
      .then(async (sellerStoreData) => {
        sellerStoreData.docs.forEach((doc) => {
          const {
            firstName,
            lastName,
            email,
            phoneNumber,
            storeName,
            description,
            address,
            id,
            stars,
            status,
            socialInstagram,
            socialFacebook,
            socialTwitter,
            slug,
            storeCategories,
            pickupStatus
          } = doc.data();

          let pickup;
          if(!pickupStatus || pickupStatus==="disabled") {
            pickup=false;
          }else{
            pickup=true;
          }

          this.setState({
            firstName,
            lastName,
            email,
            phoneNumber,
            storeName,
            description,
            address,
            id,
            stars,
            status,
            socialInstagram,
            socialFacebook,
            socialTwitter,
            slug,
            storeCategories,
            newStore: false,
            pickup,
            newSlug:slug
          });
        });
      })
      .catch((error) => {
        console.log("error getting user store", error);
      });
  };

  cancel = () => {
    this.props.history.push("/seller/dashboard");
  };

  selectCategory = ({ selected }) => {
    //limit the number of category selected to 2 MAX
    const numberOfItems = selected.length;
    const categoryNames = [];
    if (numberOfItems === 0) {
      this.setState({ selected });
      this.setState({ storeCategories: [] });
    } else if (numberOfItems <= 1) {
      this.setState({ selected });
      selected.forEach((categoryValue) => {
        categoryNames.push(options[categoryValue].label);
        this.setState({ storeCategories: categoryNames });
      });
    } else {
      toast.error("You can only select 1 category at the moment");
      return;
    }
  };

  populateSelectedCategories = () => {
    const selected = [];
    console.log("store cat", this.state.storeCategories);
    console.log("test", optionsToValues[this.state.storeCategories]);

    //check if the store category exists is part of our supported list
    // second condition added because Javascript considers '0' as falsey
    if (
      optionsToValues[this.state.storeCategories] ||
      optionsToValues[this.state.storeCategories] === 0
    ) {
      //avoid errors with storeCategories not being an array sometimes. (don't know why this happens right now. shouldn't happen but just to be safe)
      if (Array.isArray(this.state.storeCategories) === true) {
        this.state.storeCategories.forEach((category) => {
          selected.push(optionsToValues[category]);
        });
      } else {
        selected.push(optionsToValues[this.state.storeCategories]);
      }
      this.setState({ selected });
    }
  };

  populateSellerInfo = (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = firebase.firestore();
        const sellerInfo = await db
          .collection("sellersAccounts")
          .doc(userId)
          .get();
        const { firstName, 
          lastName, 
          email, 
          phoneNumber,
          address,
          street,
          city,
          state,
          country,
          zipcode,
          apt,
          virtualCards
        } = sellerInfo.data();
        let NigeriaLGA=this.state.NigeriaLGA;
        let stateLGA=[];
        if(NigeriaLGA){
          stateLGA=NigeriaLGA[state];
        }
        resolve(
          this.setState({ firstName, lastName, email, phoneNumber, id: userId,
            address,
            street,
            city,
            state,
            country,
            zipcode,
            stateLGA,
            apt,
            virtualCards
          })
        );
      } catch (error) {
        console.log("error getting seller info", error);
        reject(error);
      }
    });
  };

  // getAddressFromGeocode = (latlng) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       geocoder.geocode({ location: latlng }, function (results, status) {
  //         if (status === "OK") {
  //           if (results[0]) {
  //             console.log("rev geocode", results[0].formatted_address);
  //           } else {
  //             toast.error("No results found");
  //           }
  //         } else {
  //           toast.error("Geocoder failed due to: " + status);
  //         }
  //       });
  //     } catch (error) {
  //       console.log("error getting address from geocode", error);
  //     }
  //   });
  // };

  handleSelectAddress = async (address) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ addressSelected: true });
        let result = await geocodeByAddress(address);
        let LatLng = await getLatLng(result[0]);
        let lat = LatLng.lat;
        let lng = LatLng.lng;
        let addressCompArray = result[0].address_components;
        let formattedAddress = result[0].formatted_address;
        let street = formattedAddress.split(",");
        street = street[0];
        let city = null;
        let state = null;
        let country = null;
        let backupCity = null;
        let zipcode = null;

        addressCompArray.forEach((comp) => {
          if (comp.types.includes("locality")) {
            city = comp.long_name;
          }
          if (comp.types.includes("sublocality_level_1")) {
            //back up city name instead locality wasn't returned
            backupCity = comp.long_name;
          }
          if (comp.types.includes("administrative_area_level_1")) {
            state = comp.long_name;
          }
          if (comp.types.includes("country")) {
            country = comp.long_name;
          }
          if (comp.types.includes("postal_code")) {
            zipcode = comp.long_name;
          }
        });

        if (city === null) {
          city = backupCity;
        }

        console.log("geocode res", result[0]);
        console.log("lat", lat);
        console.log("long", lng);
        console.log("city", city);
        console.log("state", state);
        console.log("country", country);

        if (
          lat &&
          lng &&
          typeof lat !== "undefined" &&
          typeof lng !== "undefined"
        ) {
          this.hideInvalidAddress();
          resolve(
            this.setState({
              address: formattedAddress,
              street: street,
              city: city !== null ? city : "N/A",
              state: state !== null ? state : "N/A",
              country: country !== null ? country : "N/A",
              zipcode: zipcode !== null ? zipcode : "NA",
              latitude: lat,
              longitude: lng,
            })
          );
          // await this.getAddressFromGeocode(LatLng);
        }
      } catch (error) {
        console.log("error selecting address", error);
        this.showInvalidAddress();
        if (error === "ZERO_RESULTS") {
          return toast.error(
            "this address is invalid. Please check and try again"
          );
        }
        reject(error);
      }
    });
  };

  setAddress = (address) => {
    if (this.state.addressSelected === true) {
      this.setState({ addressSelected: false });
    }
    this.setState({ address: address });
  };

  onError = (status, clearSuggestions) => {
    console.log("Google Maps API returned error with status: ", status);
    clearSuggestions();
    // this.showInvalidAddress();
  };

  showInvalidAddress = () => {
    this.setState({ invalidAddress: true });
    // setTimeout(() => this.setState({ invalidAddress: false }), 5000);
  };

  hideInvalidAddress = () => {
    this.setState({ invalidAddress: false });
  };

  validateStoreName = (name) => {
    if (name.length > 20) {
      this.setState({
        invalidStoreName: true,
      });
    } else {
      this.setState({
        invalidStoreName: false,
      });
    }
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  backToSettings = () => {
    //re-display the settings form to do more updates
    this.setState({
      updateSuccess: false,
    });

    //hide the window
    this.props.cancel();
  };

  handleSlugChange = async (e) => {
    e.preventDefault();
    console.log("new slug:",e.target.value)
    this.setState({
      newSlug: e.target.value,
    });
    this.validateSlug(e.target.value);
  };

  getSlugSuggestion = () => {
    //this if statement avoids giving multiple suggestions
    //this is done to avoid annoying users with new suggestions everytime they enter a new name
    if (this.state.alreadySuggestedSlug === false) {
      let storeName = this.state.storeName;
      storeName.trim();
      storeName = storeName.replace(/[^a-zA-Z ]/g, ""); // take out any character that's not a letter
      storeName = storeName.split(" ");
      storeName = storeName.join("").toLowerCase();
      this.setState({
        slug: storeName,
        alreadySuggestedSlug: true,
      });
      this.validateSlug(storeName);
    }
  };

  isSlugTaken = async (slug) => {
    return new Promise(async (resolve, reject) => {
      try {
        const db = firebase.firestore();
        const sellers = await db
          .collection("sellersAccounts")
          .where("slug", "==", slug)
          .get();
        if (sellers.empty) {
          // couldn't find slug
          console.log("no slug found");
          resolve(false);
        } else {
          // slug exist
          console.log("fetched documents", sellers.docs[0].data());
          resolve(true);
        }
      } catch (error) {
        console.log("error checking for slug", error);
        reject(error);
      }
    });
  };

  validateSlug = async (url) => {
    const slug = url || "invalid";
    const forbiddenSlugs = [
      "invalid",
      "testurl",
      "test",
      "about",
      "careers",
      "career",
      "cashaam",
    ];
    console.log(this.state.takenSlugs);

    // if (this.state.takenSlugs.includes(slug)) {
    const taken = await this.isSlugTaken(slug);
    if (taken === true) {
      this.setState({ invalidSlug: true });
    } else {
      //check if slug is forbidden or is not long enough
      if (!forbiddenSlugs.includes(slug) && slug.length > 4) {
        //check if the slug is lowercase
        if (slug !== slug.toLowerCase()) {
          this.setState({
            invalidSlug: true,
            upperCaseDetectedInSlug: true,
          });
        }
        //avoid spaces
        else if (/\s/g.test(slug)) {
          this.setState({
            invalidSlug: true,
            spaceDetectetInSlug: true,
          });
        }

        //avoid special Chars
        else if (!slug.match("^[a-zA-Z]*$")) {
          this.setState({
            invalidSlug: true,
            specialCharDetectedInSlug: true,
          });
        } else {
          this.setState({
            invalidSlug: false,
            spaceDetectetInSlug: false,
            upperCaseDetectedInSlug: false,
            specialCharDetectedInSlug: false,
          });
        }
      } else {
        this.setState({ invalidSlug: true });
      }
    }
  };

  geocoder = null;

  getNigerianAddresses = async()=>{

    let stateNames = Object.keys(NigerianAddresses);
    let NigeriaLGA = {};
    await stateNames.forEach((state) => {
      let LGA=NigerianAddresses[state]
      
      NigeriaLGA[state] = LGA;
    });

    this.setState({
        NigeriaLGA
    })
  }

  getProductList = async (id) => {
    try {
      
      const db = firebase.firestore();
      const sellerUid = id;

      let products=await db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("products")
        .get();

        console.log("virtualCards", this.state.virtualCards)

        if (products.empty && !this.state.virtualCards) {
          this.setState({
            slugChange: true
          })
        }else{
          this.setState({
            slugChange: false
          })
        }

        console.log("products",products)
    } catch (error) {
      
      console.log("error getting products--", error);
    }
  };


  componentDidMount() {
    try {
      // console.log("window google", google);
      // if (window.google) {
      // this.geocoder = new google.maps.Geocoder();
      // }
      //check for the auth stage change of the user
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.getNigerianAddresses();
          await this.populateSellerInfo(user.uid);
          await this.checkForSellerStore();
          this.populateSelectedCategories();

          await this.getProductList(user.uid);
        } else {
          // this.props.history.push("/");
        }
      });

      //load Google Places Javascript Library
      const { isScriptLoaded, isScriptLoadSucceed } = this.props;
      if (isScriptLoaded && isScriptLoadSucceed) {
        this.setState({
          scriptLoaded: true,
        });
      }
    } catch (error) {
      console.log("error loading component", error);
    }
  }

  UNSAFE_componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed }) {
    //load Google Places Javascript Library

    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({
          scriptLoaded: true,
        });
      } else this.props.onError();
    }
  }

  render() {
    return this.state.scriptLoaded ? (
      <>
        <ToastContainer />
        <div style={{ display: "flex" }} className="pop-settings">
          <div className="pop-container">
            <div className="pop-store-update">
              <div className="profile-update">
                <div className="profile-block"
                  style={{    
                    height: "600px",
                    overflow: "scroll"
                  }}
                >
                  <div className="block-title-2">
                    <h4 className="page-title">Update your Store</h4>
                  </div>
                  <div className="microcopy-signup">
                    You can update or change your Name or Mobile Number. You
                    cannot change your email, because your email is linked to
                    your account.
                  </div>
                  <div className="product-builder" />
                  <div className="holding-onboarding">
                    <div className="forms w-form">
                      <form
                        onSubmit={this.handleSubmit}
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        className="update-form"
                      >
                        {this.state.invalidStoreName ? (
                          <div style={{ color: "red" }} className="info">
                            Store name must be less than 20 characters
                          </div>
                        ) : 
                        <label>Store Name</label>
                        }
                        <div className="checker">
                          {this.state.invalidStoreName ? (
                            // <div className="closed w-embed">
                            //   <svg
                            //     xmlns="http://www.w3.org/2000/svg"
                            //     className="icon icon-tabler icon-tabler-circle-x"
                            //     width={24}
                            //     height={24}
                            //     viewBox="0 0 24 24"
                            //     strokeWidth="1.5"
                            //     stroke="#F44336"
                            //     fill="none"
                            //     strokeLinecap="round"
                            //     strokeLinejoin="round"
                            //   >
                            //     <path stroke="none" d="M0 0h24v24H0z" />
                            //     <circle cx={12} cy={12} r={9} />
                            //     <path d="M10 10l4 4m0 -4l-4 4" />
                            //   </svg>
                            // </div>
                            null
                          ) : (
                            <div className="check w-embed">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-circle-check"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#4CAF50"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <circle cx={12} cy={12} r={9} />
                                <path d="M9 12l2 2l4 -4" />
                              </svg>
                            </div>
                          )}
                        </div>

                        <input
                          value={this.state.storeName || ""}
                          onChange={this.handleChange}
                          type="text"
                          className="input-text w-input"
                          maxLength={256}
                          name="storeName"
                          data-name="Store-name"
                          id="Store-name"
                          required
                        />
                        <label>Store Category</label>
                        <StatefulMultiSelect
                          className="input-text w-select"
                          options={options}
                          selected={this.state.selected}
                          onSelectedChanged={(selected) =>
                            this.selectCategory({ selected })
                          }
                          overrideStrings={{
                            selectSomeItems: "Select Categories (MAX 2)",
                            allItemsAreSelected: "All Items are Selected",
                            selectAll: "",
                            search: "Search",
                          }}
                        />

                        <label>Store Description</label>
                        <textarea
                          value={this.state.description}
                          onChange={this.handleChange}
                          required
                          maxLength={5000}
                          id="description"
                          name="description"
                          data-name="description"
                          className="input-text w-input"
                          defaultValue={""}
                        />

                        <label>Address</label>
                        {this.state.country?.toLowerCase()=="nigeria"?
                        <input
                          onChange={this.handleChange}
                          value={this.state.street}
                          className="mc-input input-text w-input"
                          placeholder="Street" name="street"
                        />
                        :
                        <PlacesAutocomplete
                          value={this.state.address || ""}
                          onChange={this.setAddress}
                          onSelect={this.handleSelectAddress}
                          shouldFetchSuggestions={this.state.address?.length > 3}
                          onError={this.onError}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <>
                              {/* <label htmlFor="address" className="label-2x">
                                Business Address{" "}
                                <span className="required">*</span>
                                {this.state.invalidAddress === true ? (
                                  <span style={{ color: "red" }}>
                                    Invalid Address. Please try again
                                  </span>
                                ) : null}
                              </label> */}
                              <input
                                {...getInputProps({
                                  placeholder: "",
                                })}
                                data-name="address"
                                maxLength="5000"
                                id="address"
                                name="address"
                                placeholder="Business Address "
                                required="required"
                                className="input-text w-input"
                                autoComplete="new-password"
                              />
                              <div
                                style={{
                                  position: "relative",
                                  zIndex: 99,
                                  minWidth: "100%",
                                  // borderLeft: "2px solid rgb(219, 219, 219)",
                                  // borderRight: "2px solid rgb(219, 219, 219)",
                                  boxShadow: "0px 0px 2px",
                                }}
                              >
                                {loading ? <div>...loading</div> : null}

                                {suggestions.map((suggestion, i) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#d0bdf0"
                                      : "white",
                                    borderBottom:
                                      "2px solid rgb(219, 219, 219)",
                                    borderTop:
                                      i > 0
                                        ? "0.5px solid rgb(219, 219, 219)"
                                        : null,
                                    cursor: "pointer",
                                    padding: "5px",
                                    // boxShadow: "0px 0px 2px",
                                  };

                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        style,
                                      })}
                                      key={i}
                                    >
                                      {suggestion.description}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </PlacesAutocomplete>
                        }


                        <label>Apt</label>
                        <input onChange={this.handleChange} className="mc-input input-text w-input"
                          placeholder="Apt" name="apt" value={this.state.apt && this.state.apt !== "N/A" ? this.state.apt : ""} />

                        {this.state.country?.toLowerCase()=="nigeria"?
                          null:
                            <>
                            <label>City</label>
                            <input onChange={this.handleChange} className="mc-input input-text w-input"
                              placeholder="City" name="city" value={this.state.city} />
                            </>
                        }

                        {this.state.country?.toLowerCase()=="nigeria"?
                          <>
                            <label>State</label>
                            <SelectStates 
                              handleDeliveryChange={this.handleChange}
                              state={this.state.state}
                              name={"state"}
                            />
                          </>
                        :                
                          <input onChange={this.handleChange} className="mc-input input-text w-input"
                            placeholder="State" name="state" value={this.state.state} />
                        }

                        {this.state.country?.toLowerCase()=="nigeria" && this.state.stateLGA?
                          <>
                            <label>City</label>
                            <select
                                value={this.state.city}
                                id="buyerCity"
                                className="mc-input input-text w-input"
                                placeholder="City" name="city"
                                onChange={this.handleChange}
                                >
                                    {this.state.stateLGA.map((lga, index) => {
                                    return <option value={lga} name="state"> {lga}</option>
                                    })}
                            </select>
                            </>
                          :null}

                        <label>Country</label>
                        <input onChange={this.handleChange} className="mc-input input-text w-input"
                          placeholder="Country" name="country" value={this.state.country} />

                        {this.state.country?.toLowerCase()=="nigeria"?
                          null
                          : 
                          <>
                            <label>Zipcode</label>
                            <input onChange={this.handleChange} className="mc-input input-text w-input"
                              placeholder="Zip" name="zipcode" value={this.state.zipcode} />
                          </>
                        }



                        <div className="pickup-container">
                          <label style={{color:"black"}} for="pickup">Allow order pickup at your store:</label>
                          {this.state.pickup?
                          <div onClick={()=>this.displayPickup()} style={{color:"green", fontWeight:"bold"}}>Enabled</div>
                          :
                          <div onClick={()=>this.handlePickup(false)} style={{color:"red", fontWeight:"bold"}}>Disabled</div>}
                          {/* <input 
                            type="checkbox" 
                            className="pickup-checkbox" 
                            id="pickup" name="pickup" 
                            onChange={this.handlePickup}
                            checked={this.state.pickup}
                            defaultChecked={true}
                          /> */}
                          {this.state.pickup?
                            <div onClick={()=>this.handlePickup(false)} className="freebadge cashaam-btn">Turn OFF</div>
                          :
                            <div onClick={()=>this.displayPickup()} className="freebadge cashaam-btn">Turn ON</div>
                          }

                        </div>

                        {this.state.showPickup?
                          <PickupPopup 
                          hidePickup={this.hidePickup}
                          handlePickup={this.handlePickup}
                          />
                        :null}

                        {this.state.slugChange?
                        <div className="holder-url">
                          <div className="input-label">Customize your Store URL</div>
                          <div className="live-url ">
                            

                            {this.state.slugError?  (<div className= "error-message">{this.state.slugError}</div>
                              ) : this.state.invalidSlug === true ? (
                              this.state.newSlug?.length==0 ? (
                                null
                              ) :
                                this.state.upperCaseDetectedInSlug ? (
                                <div className= "error-message">No Upper Case letters allowed</div>
                              ) : this.state.spaceDetectetInSlug ? (
                                <div className= "error-message">No Space allowed</div>
                              ) : this.state.specialCharDetectedInSlug ? (
                                <div className= "error-message">No Special Characters or Numbers allowed</div>
                              ) : (
                                <div className= "error-message">Unavailable</div>
                              )
                            ) : null}
                          </div>
                          <div className="store-url">
                            <div className="input-side">
                              <div>www.cashaam.com/</div>
                            </div>
                            <input
                              value={this.state.newSlug}
                              onChange={(e)=>this.handleSlugChange(e)}
                              type="text"
                              className="input-text url w-input input-glow"
                              maxLength={256}
                              name="slug"
                              data-name="slug"
                              placeholder="slug"
                              id="url"
                              //required
                            />
                            {/* <div className="input-text url w-input input-glow">{this.state.slug}</div> */}
                          </div>
                        </div>
                        :
                        <div className="holder-url">
                          <div style={{color:"black"}} className="input-label">You can no longer change your store url due to transactions in your account.</div>
                          <div className="live-url">
                          
                          </div>
                          <div className="store-url">
                            <div className="input-side">
                              <div>www.cashaam.com/</div>
                            </div>
                            
                            <div className="input-text url w-input input-glow">{this.state.slug}</div>
                          </div>
                        </div>

                        }



                        <input
                          onChange={this.handleChange}
                          value={`${this.state.socialInstagram}`}
                          type="text"
                          className="input-text w-input"
                          maxLength={256}
                          placeholder="Instagram Handle - e.g. @cashaamofficial"
                          name="socialInstagram"
                          data-name="socialInstagram"
                          id="socialInstagram"
                        />
                        <input
                          onChange={this.handleChange}
                          value={`${this.state.socialFacebook}`}
                          type="text"
                          className="input-text w-input"
                          maxLength={256}
                          placeholder="Facebook Handle"
                          name="socialFacebook"
                          data-name="socialFacebook"
                          id="socialFacebook"
                        />
                        <div className="meta-additions" />
                        <input
                          type="submit"
                          value="Submit"
                          defaultValue="Submit"
                          data-wait="Please wait..."
                          className="submit-primary w-button"
                        />
                      </form>
                      {/* <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div>loading... please wait</div>
    );
  }
}

export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&region=NG`,
])(UpdateStore);
