import React from 'react';
import Spinner from "../../../UI/Spinner";
import priceFormaterIntl from "../../../helpers/priceFormaterIntl";

function Snapshot(props) {
    return (
        <>
                <div className="dashboardbox todaysbox">
          <div className="todayscontentwrap">
            <div className="todaysicon" />
            <div className="todayscontent">
              <div className="todayscontenttitle">TODAY’S SNAPSHOT</div>
              <div className="todayscontenttext">
                How you run effective Ads
              </div>
            </div>
          </div>
          <div className="todaysbadge">
            <div>1 min</div>
          </div>
        </div>
        <div className="dashboardbox headerbox">
          <div className="dashboardboxitem">
            <div className="dashboardboxitemicon1" />
            <div className="dashboardboxgriditemcontent">
              <div className="dashboardboxitemcontenttitle">
                Card Balance
              </div>
              <div className="dashboardboxitemcontenttext">{priceFormaterIntl(props.spendingLimit, "USD")}</div>
            </div>
          </div>
          <div className="dashboardboxitem middleboxitem">
            <div className="dashboardboxitemicon2" />
            <div className="dashboardboxgriditemcontent">
              <div className="dashboardboxitemcontenttitle">
                Visits to your Online Store
              </div>
              {props.customerCount !== -1?
                <div className="dashboardboxitemcontenttext">{props.customerCount}</div>
                :
                <div className="dashboardboxitemcontenttext"> <Spinner /></div>
            }
              
            </div>
          </div>
          <div className="dashboardboxitem">
            <div className="dashboardboxitemicon3" />
            <div className="dashboardboxgriditemcontent">
              <div className="dashboardboxitemcontenttitle">Added to Cart</div>
              {props.addedToCart !==-1?
                <div className="dashboardboxitemcontenttext">{props.addedToCart}</div>
                :<div className="dashboardboxitemcontenttext"> <Spinner /></div>
              }
              
            </div>
          </div>
        </div>

        </>
    );
}

export default Snapshot;