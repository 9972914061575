import React from "react";
import { Link } from "react-router-dom";

const BottomNav = (props) => {
  return (
    <div className="bottom-bar">
      <div className="holder-bar-content">
        <Link
          to="/seller/dashboard"
          aria-current="page"
          className={
            props.page === "dashboard"
              ? "menu w-inline-block w--current"
              : "menu w-inline-block"
          }
        >
          <div className="bar-icon">
            <div className="icon-bar w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-compass"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="8 16 10 10 16 8 14 14 8 16" />
                <circle cx={12} cy={12} r={9} />
              </svg>
            </div>
          </div>
          <div className="icon-text">Overview</div>
        </Link>

        {props.accountType=="Personal" || props.subscriptionType=="lite"?
        // <Link
        //   to="/seller/dashboard/marketing"
        //   className={
        //     props.page === "orders"
        //       ? "menu w-inline-block w--current"
        //       : "menu w-inline-block"
        //   }
        // >
        //   <div className="bar-icon">
        //     <div className="icon-bar w-embed">
        //       <img style={{height: "25px"}} src="https://res.cloudinary.com/cashaam/image/upload/v1677645312/logos/credit-card-icon-png-4408-removebg-preview_ezm1nn.webp" />
        //     </div>
        //   </div>
        //   <div className="icon-text">My Card</div>
          
        // </Link>
        null
        :
          <Link
            to="/seller/dashboard/orders"
            className={
              props.page === "orders"
                ? "menu w-inline-block w--current"
                : "menu w-inline-block"
            }
          >
            <div className="bar-icon">
              <div className="icon-bar w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-shopping-cart"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={9} cy={19} r={2} />
                <circle cx={17} cy={19} r={2} />
                <path d="M3 3h2l2 12a3 3 0 0 0 3 2h7a3 3 0 0 0 3 -2l1 -7h-15.2" />
              </svg>
                
              </div>
            </div>
            <div className="icon-text">New Orders</div>
            <div className="new-order-count" />
          </Link>
          }

        <div
          onClick={() => (props.openLeftNav ? props.openLeftNav() : null)}
          data-w-id="e4d78ce6-10af-ecb3-401f-1d21361f9ee7"
          className="menu pop-menu"
        >
          <div className="bar-icon">
            <div className="icon-bar w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-layout"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <rect x={4} y={4} width={6} height={5} rx={2} />
                <rect x={4} y={13} width={6} height={7} rx={2} />
                <rect x={14} y={4} width={6} height={16} rx={2} />
              </svg>
            </div>
          </div>
          <div className="icon-text">Menu</div>
        </div>

        {props.accountType=="Personal" || props.subscriptionType=="lite"?
            <Link
              to="/seller/dashboard/content"
              className={
                props.page === "orders"
                  ? "menu w-inline-block w--current"
                  : "menu w-inline-block"
              }
            >
              <div className="bar-icon">
                <div className="icon-bar w-embed">
                  {/* <img style={{height: "25px"}} src="https://res.cloudinary.com/cashaam/image/upload/v1677645722/logos/upgrade-btn-removebg-preview_mn6kgn.webp" /> */}
                  <img 
                    style={{height: "25px"}}
                    src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif"
                  />
                </div>
              </div>
              <div className="icon-text">My AI</div>
              
              </Link>
              :
          <Link
            to="/seller/dashboard/addProduct"
            className={
              props.page === "addProduct"
                ? "menu w-inline-block w--current"
                : "menu w-inline-block"
            }
          >
            <div className="bar-icon">
              <div className="icon-bar w-embed">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-apps"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentcolor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <rect x={4} y={4} width={6} height={6} rx={1} />
                  <rect x={4} y={14} width={6} height={6} rx={1} />
                  <rect x={14} y={14} width={6} height={6} rx={1} />
                  <line x1={14} y1={7} x2={20} y2={7} />
                  <line x1={17} y1={4} x2={17} y2={10} />
                </svg>
              </div>
            </div>
            <div className="icon-text">Add Product</div>
          </Link>
        }
        <Link
          to="/seller/dashboard/settings"
          className={props.page === "settings" ? "menu w--current" : "menu"}
        >
          <div className="bar-icon">
            <div className="icon-bar w-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-settings"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentcolor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                <circle cx={12} cy={12} r={3} />
              </svg>
            </div>
          </div>
          <div className="icon-text">Settings</div>
        </Link>
      </div>
    </div>
  );
};
export default BottomNav;
