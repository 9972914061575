import React from "react";

const MessageHolder = (props) => (
  <>
    {props.active === true ? (
      <div className="message-holder active">
        <div className="chat-avatar" />
        <div className="chat-prop">
          <div className="chat-name">{props.name}</div>
          <div className="chat-date">{props.date}</div>
          <div className="chat-snippet">{props.lastMessage}</div>
        </div>
      </div>
    ) : (
      <div className="message-holder">
        <div className="chat-avatar" />
        <div className="chat-prop">
          <div className="chat-name">{props.name}</div>
          <div className="chat-date">{props.date}</div>
          <div className="chat-snippet">{props.lastMessage}</div>
        </div>
      </div>
    )}
  </>
);
export default MessageHolder;
