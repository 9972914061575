import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import UpdateStore from "./UpdateStore";
import UpdateBank from "./UpdateBank";
import UpdateLogin from "./UpdateLogin";
import uploadBigBanner from "../../../helpers/uploadBigBanner";
import uploadSmallBanner from "../../../helpers/uploadSmallBanner";
import getBigBanner from "../../../helpers/getBigBanner";
import getSmallBanner from "../../../helpers/getSmallBanner";
import deleteBigBanner from "../../../helpers/deleteBigBanner";
import deleteSmallBanner from "../../../helpers/deleteSmallBanner";
import addStoreDiscount from "../../../helpers/addStoreDiscount";
import deleteStoreDiscount from "../../../helpers/deleteStoreDiscount";
import getUnreadChat from "../../../helpers/getUnreadChat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StripeConnect from "./StripeConnect";
import connectStripe from "../../../helpers/connectStripe";
import getUserLocation from "../../../helpers/getUserLocation";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getBasicInfo from "../../../helpers/getBasicInfo";
import SettingsPage from "./SettingsPage";
import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import SecondaryAccount from "./SecondaryAccount";
import NProgress from 'nprogress';
const stripe = require("stripe")("sk_test_tzunOvPyk3dvQTWvUPquMWKJ00lXGq5M7f");

class Settings extends Component {
  state = {
    sellerName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    storeName: "",
    description: "",
    address: "",
    id: "",
    stars: "",
    status: "",
    socialInstagram: "",
    socialFacebook: "",
    socialTwitter: "",
    slug: "",
    storeCategories: "",
    accountHolderName: "",
    bankName: "",
    accountNumber: "",
    bvn: "",
    apt: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    viewLoginUpdate: false,
    viewStoreUpdate: false,
    viewBankUpdate: false,
    customBigBanner: "",
    customSmallBanner: "",
    storeURL: "",
    storeDiscountName: "",
    storeDiscountValue: "",
    storeDiscountSet: false,
    viewStripeConnect: false,
    userCurrentCountry: "",
    stripeSetupComplete: false,
    viewStoreInfo: true,
    viewPersonalInfo: false,
    viewBankInfo: false,
    showLeftNav: false,
    themeName: false,
  };

  displayStoreUpdate = () => {
    this.setState({
      viewStoreUpdate: true,
      viewBankUpdate: false,
      viewLoginUpdate: false,
      viewStripeConnect: false,
    });
  };

  displayBankUpdate = () => {
    this.setState({
      viewBankUpdate: true,
      viewStoreUpdate: false,
      viewLoginUpdate: false,
      viewStripeConnect: false,
    });
  };

  addSecondaryAccount =()=>{
    this.setState({
      viewAddAccount: true,
      viewBankUpdate: false,
      viewStoreUpdate: false,
      viewLoginUpdate: false,
      viewStripeConnect: false,
    });
  }

  displayLoginUpdate = () => {
    this.setState({
      viewLoginUpdate: true,
      viewStoreUpdate: false,
      viewBankUpdate: false,
      viewStripeConnect: false,
    });
  };

  displayStripeConnect = () => {
    this.setState({
      viewLoginUpdate: false,
      viewStoreUpdate: false,
      viewBankUpdate: false,
      viewStripeConnect: true,
    });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  getBankInfo = () => {
    const db = firebase.firestore();
    const sellerUid = firebase.auth().currentUser.uid;
    if (this.state.userCurrentCountry === "Nigeria") {
      db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("bank")
        .get()
        .then(async (sellerStoreData) => {
          sellerStoreData.docs.forEach((doc) => {
            const {
              accountHolderName,
              bankName,
              accountNumber,
              bvn,
            } = doc.data();
            this.setState({
              accountHolderName,
              bankName,
              accountNumber,
              bvn,
            });
          });
        });
    } else {
      db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stripe")
        .get()
        .then(async (sellerStoreData) => {
          if (sellerStoreData.docs.length > 0) {
            this.setState({ stripeSetupComplete: true });
          }
        });
    }
  };

  checkForSellerStore = () => {
    console.log(this.state);
    const db = firebase.firestore();
    const sellerUid = firebase.auth().currentUser.uid;
    return db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .get()
      .then(async (sellerStoreData) => {
        sellerStoreData.docs.forEach((doc) => {
          const {
            firstName,
            lastName,
            email,
            phoneNumber,
            storeName,
            description,
            address,
            id,
            stars,
            status,
            socialInstagram,
            socialFacebook,
            socialTwitter,
            slug,
            storeCategories,
            customSmallBanner,
            customBigBanner,
            storeDiscountName,
            storeDiscountValue,
            apt,
            street,
            city,
            state,
            country,
            zipcode,
            themeName,
          } = doc.data();
          this.setState({
            firstName,
            lastName,
            email,
            phoneNumber,
            storeName,
            description,
            address,
            id,
            stars,
            status,
            socialInstagram,
            socialFacebook,
            socialTwitter,
            slug,
            storeCategories,
            newStore: false,
            customSmallBanner,
            customBigBanner,
            storeURL: `http://www.cashaam.com/${slug}`,
            storeDiscountName,
            storeDiscountValue,
            storeDiscountSet:
              storeDiscountName &&
              storeDiscountValue &&
              parseFloat(storeDiscountValue) > 0,
            apt,
            street,
            city,
            state,
            country,
            zipcode,
            themeName,
          });
        });
      })
      .catch((error) => {
        console.log("error getting user store", error);
      });
  };

  cancel = () => {
    this.setState({
      viewStoreUpdate: false,
      viewBankUpdate: false,
      viewLoginUpdate: false,
      viewAddAccount: false
    });
    this.componentDidMount();
  };

  showBigWidget = () => {
    this.bigWidget.open();
  };

  hideBigWidget = () => {
    this.bigWidget.close();
  };

  showSmallWidget = () => {
    this.smallWidget.open();
  };

  hideSmallWidget = () => {
    this.smallWidget.close();
  };

  bigWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "cashaam",
      uploadPreset: "bigbanner",
      sources: [
        "local",
        // "camera",
        // "image_search",
        // "facebook",
        // "dropbox",
        // "instagram",
        // "shutterstock",
      ],
      // googleApiKey: "<image_search_google_api_key>",
      cropping: true,
      showSkipCropButton: true,
      croppingAspectRatio: 3.25862069, // ratio = 945/290
      minImageWidth: 890,
      minImageHeight: 220,
      croppingValidateDimensions: true,
      multiple: false,
      defaultSource: "local",
      showSkipCropButton: false,
      croppingShowDimensions: true,
      // publicId: `${this.state.slug}_smallBanner`,
      styles: {
        palette: {
          window: "#c2bffc",
          windowBorder: "#000000",
          tabIcon: "#FFFFFF",
          menuIcons: "#FFFFFF",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#c2bffc",
          action: "#F55B07",
          inactiveTabIcon: "#FFFFFF",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#E4EBF1",
        },
        fonts: {
          default: {
            active: true,
          },
        },
      },
    },
    async (error, result) => {
      console.log("upload result", result);
      if (result.event === "success") {
        console.log("info", result.info);
        const url = result.info.secure_url;
        const { sellerId, customBigBanner } = this.state;
        await uploadBigBanner(sellerId, url, customBigBanner);
        this.componentDidMount();
      }
    }
  );

  smallWidget = window.cloudinary.createUploadWidget(
    {
      cloudName: "cashaam",
      uploadPreset: "smallbanner",
      sources: [
        "local",
        // "camera",
        // "image_search",
        // "facebook",
        // "dropbox",
        // "instagram",
        // "shutterstock",
      ],
      // googleApiKey: "<image_search_google_api_key>",
      cropping: true,
      showSkipCropButton: true,
      croppingAspectRatio: 1.5, // ratio = 420/280
      minImageWidth: 420,
      minImageHeight: 280,
      croppingValidateDimensions: true,
      multiple: false,
      defaultSource: "local",
      showSkipCropButton: false,
      croppingShowDimensions: true,
      // publicId: `${this.state.slug}_smallBanner`,
      styles: {
        palette: {
          window: "#c2bffc",
          windowBorder: "#000000",
          tabIcon: "#FFFFFF",
          menuIcons: "#FFFFFF",
          textDark: "#000000",
          textLight: "#FFFFFF",
          link: "#c2bffc",
          action: "#F55B07",
          inactiveTabIcon: "#FFFFFF",
          error: "#F44235",
          inProgress: "#0078FF",
          complete: "#20B832",
          sourceBg: "#E4EBF1",
        },
        fonts: {
          default: {
            active: true,
          },
        },
      },
    },
    async (error, result) => {
      console.log("upload result", result);
      if (result.event === "success") {
        console.log("info", result.info);
        const url = result.info.secure_url;
        const { sellerId, customSmallBanner } = this.state;
        await uploadSmallBanner(sellerId, url, customSmallBanner);
        this.componentDidMount();
      }
    }
  );

  useDefaultBigBanner = async () => {
    try {
      const { sellerId, customBigBanner } = this.state;
      await deleteBigBanner(sellerId, customBigBanner);
      this.componentDidMount();
      toast.error(
        "Successfully changed. This may take some time to reflect on your store."
      );
    } catch (error) {
      console.log("error setting big banner to default", error);
      toast.error("Sorry, There was an error. Please try again.");
    }
  };

  useDefaultSmallBanner = async () => {
    try {
      const { sellerId, customSmallBanner } = this.state;
      await deleteSmallBanner(sellerId, customSmallBanner);
      this.componentDidMount();
      toast.error(
        "Successfully changed. This may take some time to reflect on your store."
      );
    } catch (error) {
      console.log("error setting big banner to default", error);
      toast.error("Sorry, There was an error. Please try again.");
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  activateDiscount = async (e) => {
    e.preventDefault();
    try {
      const { sellerId, storeDiscountName, storeDiscountValue } = this.state;
      if (storeDiscountValue && storeDiscountName) {
        if (parseFloat(storeDiscountValue) > 0) {
          await addStoreDiscount(
            sellerId,
            storeDiscountName,
            storeDiscountValue
          );
          this.componentDidMount();
          toast.error("Discount successfully enabled");
        } else {
          return toast.error("Store discount must be greater than 0");
        }
      } else {
        return toast.error("Invalid input");
      }
    } catch (error) {
      console.log("error activating discount", error);
      return toast.error("error activating discount. Please try again");
    }
  };

  disableDiscount = async () => {
    try {
      const { sellerId } = this.state;
      await deleteStoreDiscount(sellerId);
      this.componentDidMount();
      toast.error("Discount successfully disabled");
    } catch (error) {
      console.log("error disabling discount", error);
      return toast.error("error disabling store discount. Please try again.");
    }
  };

  // checkForStripeRes = (sellerId) => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let url = window.location.href;
  //       let urlArray = url.split("?");
  //       let params = urlArray[1];
  //       let paramArray = params.split("&");
  //       let codeParam = paramArray[0];
  //       let code = codeParam.split("=")[1];
  //       let stateParam = paramArray[1];
  //       let state = stateParam.split("=")[1];
  //       console.log("code", code);
  //       console.log("state", state);

  //       const result = await connectStripe(code, state, sellerId);
  //     } catch (error) {
  //       console.log("error checking for stripe redirect", error);
  //     }
  //   });
  // };
  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    } else {
      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");
      }

    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  displayStoreInfo = () => {
    this.setState({
      viewStoreInfo: true,
      viewBankInfo: false,
      viewPersonalInfo: false,
    });
  };

  displayBankInfo = () => {
    this.setState({
      viewBankInfo: true,
      viewStoreInfo: false,
      viewPersonalInfo: false,
    });
  };

  displayPersonalInfo = () => {
    this.setState({
      viewPersonalInfo: true,
      viewStoreInfo: false,
      viewBankInfo: false,
    });
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  getSecondaryAccount=async (sellerId)=>{
    try{
      
      const db = firebase.firestore();

      const info = await db.collection("sellersAccounts").doc(sellerId).collection("bank").doc(sellerId).get();
      const { secondaryAccount } = info.data();
      console.log("secondaryAccount",secondaryAccount);
      this.setState({ 
        secondaryAccount
      })
    }catch (error) {
      console.log("error getting secondary account",error);
      this.setState({
        secondaryAccount:false
      })
    }
  }

  async componentDidMount() {
    NProgress.start();
    //check for the auth stage change of the user
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {

        const storeInfo = await getBasicInfo(user.uid);
        this.setState({
          storeInfo,
          sellerId: user.uid,
          sellerName: user.displayName,
        });
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );
        let location = await this.getLocation();
        NProgress.set(0.3);
        this.checkRegistrationCompletion(user.uid, location);

        await this.checkForSellerStore();
        NProgress.set(0.5)
        this.getBankInfo();
        this.getSecondaryAccount(user.uid);
        NProgress.done();
        //check for stripe redirect
        // await this.checkForStripeRes(user.uid);
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <ToastContainer />
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            {!this.state.viewLoginUpdate &&
              !this.state.viewStoreUpdate &&
              !this.state.viewBankUpdate &&
              !this.state.viewStripeConnect &&
              !this.state.viewAddAccount ? (
                <SettingsPage
                  sellerId={this.state.sellerId}
                  displayBankInfo={this.displayBankInfo}
                  displayPersonalInfo={this.displayPersonalInfo}
                  displayStoreInfo={this.displayStoreInfo}
                  viewStoreInfo={this.state.viewStoreInfo}
                  viewPersonalInfo={this.state.viewPersonalInfo}
                  viewBankInfo={this.state.viewBankInfo}
                  displayLoginUpdate={this.displayLoginUpdate}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  phoneNumber={this.state.phoneNumber}
                  email={this.state.email}
                  storeName={this.state.storeName}
                  description={this.state.description}
                  address={this.state.address}
                  storeCategories={this.state.storeCategories}
                  socialInstagram={this.state.socialInstagram}
                  socialFacebook={this.state.socialFacebook}
                  userCurrentCountry={this.state.userCurrentCountry}
                  bankName={this.state.bankName}
                  accountNumber={this.state.accountNumber}
                  displayBankUpdate={this.displayBankUpdate}
                  stripeSetupComplete={this.state.stripeSetupComplete}
                  displayStripeConnect={this.displayStripeConnect}
                  customSmallBanner={this.state.customSmallBanner}
                  customBigBanner={this.state.customBigBanner}
                  showSmallWidget={this.showSmallWidget}
                  useDefaultBigBanner={this.useDefaultBigBanner}
                  showBigWidget={this.showBigWidget}
                  storeURL={this.state.storeURL}
                  displayStoreUpdate={this.displayStoreUpdate}
                  themeName={this.state.themeName}
                  addSecondaryAccount={this.addSecondaryAccount}
                  secondaryAccount={this.state.secondaryAccount}
                />
              ) : (
                <>
                  {this.state.viewStoreUpdate === true ? (
                    <UpdateStore cancel={this.cancel} />
                  ) : this.state.viewBankUpdate === true ? (
                    <UpdateBank 
                    sellerId={this.state.sellerId}
                    country={this.state.country}
                    cancel={this.cancel} />
                  ) : this.state.viewLoginUpdate === true ? (
                    <UpdateLogin cancel={this.cancel} />
                  ) : this.state.viewStripeConnect === true ? (
                    <StripeConnect
                      firstName={this.state.firstName}
                      lastName={this.state.lastName}
                      email={this.state.email}
                      phoneNumber={this.state.phoneNumber}
                      street={this.state.street}
                      city={this.state.city}
                      state={this.state.state}
                      country={this.state.country}
                      zipcode={this.state.zipcode}
                      businessName={this.state.storeName}
                      sellerId={this.state.sellerId}
                      businessURL={`https://cashaam.com/${this.state.slug}`}
                      stripeState={JSON.stringify({
                        sellerId: this.state.sellerId,
                        origin: window.location.href,
                      })}
                    />
                  ) : this.state.viewAddAccount? (
                    <SecondaryAccount cancel={this.cancel} 
                    sellerId={this.state.id}
                    />
                  ):null
                }
                </>
              )}
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="settings"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}

export default Settings;
