import React from "react";
import cashaamLogo from "../../../../css/images/Shape.svg";

class VirtualCard extends React.Component {

    state ={
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        lastFourDigits: this.props.lastFourDigits

    }

    componentDidMount() {

    }
  render() {
    return (
      <div>
      <div style={{height: "160px"}} className="sidebarwallet dashboardwallet">
        <div className="sidebarwallettop">
          <div className="sidebarwallettopleft">
            <div className="sidebarwallettoplefttitle">NAME</div>
            <div className="sidebarwallettoplefttext">{`${this.props.firstName} ${this.props.lastName}`}</div>
          </div>
          <div className="sidebarwallettopright">
            <div className="sidebarwallettoprighttext">
              <span className="censoredtext">**** **** **** ****</span>{" "}
              {this.props.lastFourDigits}
            </div>
          </div>
          <img src={cashaamLogo} loading="lazy" alt />
        </div>
        <div className="sidebarwalletbottom">
          <a
            data-w-id="009f638f-e4ca-26c4-5d8f-ef2b27e16880"
            onClick={()=>this.props.displayCard(true)}
            className="walletbutton w-inline-block"

          >
            <div>Reveal Info</div>
          </a>
          <div className="sidebarwalletbottomright">
            <div className="sidebarwalletcvv">***</div>
            <div>MM/YY</div>
          </div>
        </div>
      </div>
      {/* {this.props.virtualCards?null:
      <a onClick={()=>this.props.displayCard(true)} style={{cursor: "pointer"}} className="walletdetail w-inline-block">
        <img src="https://res.cloudinary.com/cashaam/image/upload/v1664277455/logos/add-circle_iaflry.svg" loading="lazy" alt />
        <div>Create your Virtual Wallet</div>
      </a>
      } */}
      <div className="card-zip">
        {/* <div>Country: USA</div>
        <div>Zipcode: 78701</div> */}
        <div className="billing-title">Billing Address</div>
        {this.props.storeInfo?.country?.toLowerCase()=="united states" || this.props.storeInfo?.country?.toLowerCase()=="usa"?
        <div style={{textAlign: "left", width: "100%"}}>{this.props.storeInfo?.street}, {this.props.storeInfo?.city}, {this.props.storeInfo?.state}, {this.props.storeInfo?.zipcode}</div>
          :
          <div style={{textAlign: "left", width: "100%"}}>301 Congress Ave, Texas, TX 78701 USA.</div>
        }
      </div>
      </div>
    );
  }
}

export default VirtualCard;
