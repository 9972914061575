import React, { Component } from 'react'

const modules = {
    toolbar: [
    ],
};

export default class RichTextQuill extends Component {
    constructor(props) {
        super(props)
        if (document) {
            this.quill = require('react-quill')
        }
    }


    render() {
        const Quill = this.quill
        if (Quill) {
            return (
                <Quill
                    readOnly
                    value={this.props.text || ""}
                    modules={modules}
                />
            )
        } else {
            return null
        }
    }
}
