import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="body-404">
      <div className="utility-page-wrap">
        <div className="wrapper-404">
          <div className="_404-content w-form">
            <h1 className="error">Oops!</h1>
            <h2 className="message">
              We can't seem to find the page you're looking for.
            </h2>
            <div className="help-404">
              <div className="_404">Error Code: 404</div>
              <div className="help-tip-title">
                Here are some helpful links instead:
              </div>
              <div className="error-code">
                <Link to="/seller/dashboard" className="links">
                  Dashboard
                </Link>
                <Link to="/seller/dashboard/products" className="links">
                  Products
                </Link>
                <Link to="/seller/dashboard/orders" className="links">
                  Orders
                </Link>
                <Link to="/seller/help" className="links">
                  Help
                </Link>
                <Link to="/seller/dashboard/settings" className="links">
                  Settings
                </Link>
              </div>
            </div>
          </div>
          <Link
            to="/seller/dashboard"
            className="brand-holder-2 w-inline-block"
          >
            <h1 className="brand-name">cashaam</h1>
          </Link>
        </div>
      </div>
    </div>
  );
}
