import firebase from "../../firebaseConfig";

const addStoreDiscount = (sellerId, discountName, discountValue, discountType) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("sellerId", sellerId);

      let storeDiscount = {
        discountName: discountName,
        discountValue: discountValue,
        discountType: discountType
      }

      console.log("store discount to be saved",storeDiscount)

      const db = firebase.firestore();

      await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .set(
          {storeDiscount},
          { merge: true }
        );
      resolve("success");
    } catch (error) {
      console.log("error adding store discount", error);
      reject(error);
    }
  });
};
export default addStoreDiscount;
