import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";

import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";

import {
    saveDomain,
    getDomain,
    deleteDomain,
} from "../../../helpers/domains";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RightNav from "../../../common/RightNav";
import BottomNav from "../../../common/BottomNav";
import AddDomainForm from "./AddDomainForm";
import getUserLocation from "../../../helpers/getUserLocation";
import getUnreadChat from "../../../helpers/getUnreadChat";
import NProgress from 'nprogress';
import getBasicInfo from "../../../helpers/getBasicInfo";
import AddDomainPop from "./AddDomainPop";

const db = firebase.firestore();

export default class Domain extends Component {
    state = {
        slug: "",
        sellerId: "",
        // domain: null,
        domainName: null,
        errorMessage: null,
        domainSetSuccess: null,
        userCurrentCountry: null,
        showDomainForm: false,
        ready: false,
        loading: false,
        showDomainPop: false,
        hasDomain: false,
        activeDomain: false
    };

    showErrorMessage = (message) => {
        this.setState({ errorMessage: message });

        setTimeout(() => {
            this.setState({ errorMessage: null });
        }, 10000);
    };

    handleDelete = async (domainName) => {
        try {
            let success = await deleteDomain(domainName);
            if (success) {
                this.setState({
                    domainName: "", hasDomain: false
                });
            } else {
                this.showErrorMessage("Error deleting domain");
            }

        } catch (error) {
            console.error("An error occured when deleting domain", error);
            this.showErrorMessage("Error deleting domain");
        }
    };

    handleChange = (e) => {
        if (this.state.errorMessage) {
            this.showErrorMessage(null)
        }
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    showLoading = (val) => {
        this.setState({
            loading: val
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        try {
            this.showLoading(true);
            let { slug, domainName } = this.state;
            domainName = domainName.trim();
            const domain = await saveDomain(domainName, slug);
            this.setState({ domainName: domain, showDomainPop: false, showDomainForm: false, hasDomain: true, activeDomain: false });
            this.showLoading(false);

            console.log("new domain saved successfully")
        } catch (error) {
            console.error("Error occurred when saving catalog", error);
            this.showErrorMessage("Error adding domain");
            this.showLoading(false);

        }
    };

    logoutHandler = async () => {
        await firebase
            .auth()
            .signOut()
            .then(function () {
                // Sign-out successful.
            })
            .catch(function (error) {
                // An error happened.
                console.log(error, "error with signout");
            });
    };

    openLeftNav = () => {
        this.setState({
            showLeftNav: !this.state.showLeftNav,
        });
    };

    getLocation = async () => {
        const locationData = await getUserLocation();
        this.setState({
            userCurrentCountry:
                locationData.country_name === "India"
                    ? "Nigeria"
                    : locationData.country_name,
        });
        return locationData.country_name === "India"
            ? "Nigeria"
            : locationData.country_name;
    };

    displayDomainPop = (val) => {
        this.setState({
            showDomainPop: val
        })
    }

    addDomain = (e) => {
        e.preventDefault();
        let { domainName } = this.state;
        domainName = domainName.trim();
        domainName = domainName.toLowerCase();
        let domainArray = domainName.split(".");
        let topLevelDomain = domainArray[domainArray.length - 1];
        let name = domainArray[domainArray.length - 2];

        if (domainName.includes("https:") || domainName.includes("http:") || domainName.includes("www.")) {
            console.log(`"${domainName}" is not a valid to domain name. Please remove any "https:", "http:" or "www." and simply enter your domain name. Ex: mystore.com`)
            return this.showErrorMessage(`"${domainName}" is not a valid to domain name. Please remove any "https" or "http" and simply enter your domain name. Ex: mystore.com`);
        }


        let subDomain = domainArray[domainArray.length - 3];

        if (!this.state.acceptedTopLevelDomains.includes(topLevelDomain)) {
            console.log(`".${topLevelDomain}" is not an accepted to level domain.`)
            return this.showErrorMessage(`".${topLevelDomain}" is not an accepted to level domain.`);
        }


        this.setState({ domainName })
        this.displayDomainPop(true);
    }

    handleEdit = () => {
        this.setState({
            showDomainForm: true
        });
    }

    getAcceptedDomains = async () => {
        try {
            let result = await db.collection("topLevelDomains").doc("valid").get();
            let acceptedDomains = result.data().list;

            return acceptedDomains;
        } catch (error) {
            console.log("error getting accepted domains", error);
        }
    }

    componentDidMount() {
        NProgress.start();
        // check if seller is logged in
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const basicInfo = await getBasicInfo(user.uid);
                if (basicInfo === false) {
                    return this.props.history.push("/seller/dashboard");
                }

                let{subscriptionType}=basicInfo;

                if(!basicInfo?.subscriptionActive || (subscriptionType?.toLocaleLowerCase()=="lite")){
                    return this.props.history.push("/seller/dashboard/rewards");
                }
                this.setState({
                    storeInfo: basicInfo
                })
                getUnreadChat(user.uid).then(
                    result => {
                      this.setState({
                        notification: result
                      })
                    }
                );

                let { slug } = basicInfo;
                this.getLocation();
                const domainData = await getDomain(slug);

                //fetch the list of accepted top level domains
                const acceptedTopLevelDomains = await this.getAcceptedDomains();
                console.log("domains", acceptedTopLevelDomains)
                this.setState({
                    domainName: domainData ? domainData.domain : null, activeDomain: domainData ? domainData.active : false, sellerId: user.uid,
                    slug, ready: true, hasDomain: domainData ? true : false, acceptedTopLevelDomains
                });
                NProgress.done();
            } else {
                this.props.history.push("/");
            }
        });
    }

    render() {
        return (
            <>
                <ToastContainer />
                <TopHeader
                    firstName={this.state.firstName}
                    lastName={this.state.lastName}
                    logoutHandler={this.logoutHandler}
                    notification={this.state.notification}
                />
                <div className="section-inside">
                    <div className="panel-main">
                        <LeftNav
                            showLeftNav={this.state.showLeftNav}
                            logoutHandler={this.logoutHandler}
                            sellerId={this.state.sellerId}
                            userCurrentCountry={this.state.userCurrentCountry}
                            subscriptionActive={this.state.storeInfo?.subscriptionActive}
                            subscriptionType={this.state.storeInfo?.subscriptionType}
                            accountType={this.state.storeInfo?.accountType}
                        />
                        <AddDomainForm
                            handleChange={this.handleChange}
                            handleDelete={this.handleDelete}
                            handleSubmit={this.addDomain}
                            handleEdit={this.handleEdit}
                            showDomainForm={this.state.showDomainForm}
                            domainName={this.state.domainName}
                            ready={this.state.ready}
                            hasDomain={this.state.hasDomain}
                            errorMessage={this.state.errorMessage}
                            activeDomain={this.state.activeDomain}

                        />
                        {this.state.showDomainPop === true ?
                            <AddDomainPop
                                handleSubmit={this.handleSubmit}
                                domainSetSuccess={this.state.domainSetSuccess}
                                errorMessage={this.state.errorMessage}
                                handleChange={this.handleChange}
                                displayDomainPop={this.displayDomainPop}
                                domainName={this.state.domainName}
                                loading={this.state.loading}
                            /> : null}
                        <BottomNav
                            openLeftNav={this.openLeftNav}
                            page="addProduct"
                            logoutHandler={this.logoutHandler}
                            subscriptionActive={this.state.storeInfo?.subscriptionActive}
                            subscriptionType={this.state.storeInfo?.subscriptionType}
                            accountType={this.state.storeInfo?.accountType}
                        />
                    </div>
                    <RightNav 
                    notification={this.state.notification}
                    logoutHandler={this.logoutHandler} />
                </div>
            </>
        );
    }
}
