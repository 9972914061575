import React, {Component} from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ConfirmCode from "../../authentication/signIn/ConfirmCode";

const PhoneVerification = (props) => {
  return (
    <div className="container-right">
      <div className="holder-signup-otp">
        <div style={{ display: "flex" }} className="signup mobile-signing">
          <h1 className="h2">Verify Your Account</h1>
          <div className="microcopy-signup">You are about to access sensitive information, to verify your identity please enter your One Time Password - OTP to continue.</div>
          <div/>
          {props.showSMSVerification === false ?
            <input
          onClick={() => props.verifyPhone()}
          type="button"
          value="Next"
          defaultValue="Login"
          data-wait="Please wait..."
          className="submit-button w-button"
        /> : <div className="form-signup w-form">
    <input
      onChange={props.handleChange}
      type="text"
      className="input-text w-input"
      maxLength={256}
      name="confirmationCode"
      data-name="confirmationCode"
      placeholder="e.g: 123456 - Confirmation Code"
      id="confirmationCode"
      required
    />
    {props.loading === false ? (
      <input
        onClick={() => props.submitConfResult()}
        type="button"
        value="Confirm"
        defaultValue="Login"
        data-wait="Please wait..."
        className="submit-button w-button"
      />
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="submit-button w-button"
      >
        <div style={{ borderTopColor: "#6740aa" }} className="loader"></div>
      </div>
    )}
    {props.loading === false ? (
      <input
        onClick={() => props.backToSettings()}
        style={{ marginTop: "8px", backgroundColor: "grey" }}
        type="button"
        defaultValue="Return To Settings"
        data-wait="Please wait..."
        className="submit-button w-button"
      />
    ) : null}
        </div>}
        </div>
      </div>
    </div>
  );
};

export default PhoneVerification;