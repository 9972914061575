import React from 'react';
import OrderItem from "./OrderItem";

function Transactions(props) {
    let transactions=props.transactionInfo;
    return (
        <div className="orderlistwrap">
                <div className="orderlisttitle">
                  <div className="orderlistcheckboxtitle"> </div>
                  <div className="orderlistorderstitle">Orders</div>
                  <div className="orderlistdatetitle">Date</div>
                  <div className="orderliststatustitle">Status</div>
                  <div className="orderlistdeliverytitle">Delivery</div>
                  <div className="orderlisttotaltitle">Total</div>
                  <div className="orderlistactionstitle">Actions</div>
                  <div className="empty" />
                </div>

                {transactions? transactions.map((transaction, index) => (
                  <OrderItem
                    getCorrectShippingType={props.getCorrectShippingType}
                    showTransactionDetails={props.showTransactionDetails}
                    transactionDetails={transaction}
                    index={index}
                    amount={transaction.amount}
                    currency={transaction.currency}
                    transactionId={transaction.transactionId}
                    status={transaction.orderList[0]?.orderStatus}
                    date={transaction.date}
                    shippingCarrierAccount={transaction?.shippingCarrierAccount}
                    customerName={transaction?.customerName}
                //     id={order.id}
                //     orderQuantity={order.orderQuantity}
                //     discountedPrice={order.discountedPrice}
                //     productPrice={order.productPrice}
                //     currency={order.currency}
                //     customerName={order.customerName}
                //     orderDeliveryAddress={order.orderDeliveryAddress}
                //     productDescription={order.productDescription}
                //     selectedColors={order.selectedColors}
                //     featuredImage={order.featuredImage}
                //     sellerId={order.sellerId}
                //     transactionId={order.transactionId}
                //     order={order}
                //     trackingNumber={order.trackingNumber}
                //     customerId={order.customerId}
                //     orderTimestamp={order.orderTimestamp}
                //     productName={order.productName}
                //     orderId={order.orderId}
                //     onViewProduct={props.onViewProduct}
                //     markAsFullFilled={props.markAsFullFilled}
                //     displayShippingLabel={props.displayShippingLabel}
                //     schedulePickup={props.schedulePickup}
                //     country={order.country}
                //   displayDetails={props.displayDetails}
                //   getCorrectShippingType={props.getCorrectShippingType}
                  />
                ))
              : null}
                
                
              </div>
    );
}

export default Transactions;