import firebase from "../../firebaseConfig";

const getRegistrationProgress = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const result = await db.collection("sellersAccounts").doc(id).get();
      if (result.exists) {
        let step = result.data().registrationStep;

        if (step && typeof step !== "undefined") {
          resolve(parseFloat(step));
        } else {
          //check if it's an old store
          db.collection("sellersAccounts")
            .doc(id)
            .collection("stores")
            .get()
            .then((snap) => {
              console.log("CHECK", snap.docs.length);
              if (snap.docs.length < 1) {
                resolve(1);
              } else {
                resolve(100);
              }
            });
        }
      } else {
        //check if it's an old store
        db.collection("sellersAccounts")
          .doc(id)
          .collection("stores")
          .get()
          .then((snap) => {
            console.log("CHECK", snap.docs.length);
            if (snap.docs.length < 1) {
              resolve(1);
            } else {
              resolve(100);
            }
          });
      }
    } catch (error) {
      console.log("error getting registration progress", error);
      reject(error);
    }
  });
};
export default getRegistrationProgress;
