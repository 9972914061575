import React from "react";
import { useState, setState } from "react";
import firebase from "../../../../firebaseConfig";
import Loading from "../../../common/Loading";

const StoreBannerInfo = (props) => {
  return (
    <div style={{ display: "flex" }} className="pop-settings">
      <div className="pop-container">
        <div className="pop-store-photo">
          <div className="profile-update">
            <div className="profile-block">
              <div className="block-title-2">
                <h4 className="page-title">Upload Store Banner</h4>
              </div>
              <div className="microcopy-signup">
                Upload or change your Store Banner. Your store banner allows you
                to customize the look and expression of your store. You can
                create your Banner on Canva, download, and upload the image to
                your store. <br />
                <br />
                Your banner must be{" "}
                <strong className="bold-text-2">
                  890 (width) x 230 (height)
                </strong>
                . If you don’t have a banner, we selected a default.{" "}
                <a href="#" className="link">
                  Check it out here
                </a>
                .
              </div>
              <div className="product-builder" />
              <div className="holding-onboarding">
                <div className="forms w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="update-form"
                  >
                    <input
                      onClick={() => props.showBigWidget()}
                      type="button"
                      value="Upload Banner"
                      defaultValue="Upload Banner"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    />
                    <input
                      style={{
                        backgroundImage: "none",
                        backgroundColor: "grey",
                      }}
                      onClick={() => props.setShowBanner(false)}
                      type="button"
                      value="Done"
                      defaultValue="Done"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StoreLogoInfo = (props) => {
  return (
    <div style={{ display: "flex" }} className="pop-settings">
      <div className="pop-container">
        <div className="pop-store-photo">
          <div className="profile-update">
            <div className="profile-block">
              <div className="block-title-2">
                <h4 className="page-title">Upload Store Logo</h4>
              </div>
              <div className="microcopy-signup">
                Upload or change your Store Logo. If you have a brand logo,
                ensure your banner is{" "}
                <strong className="bold-text-3">
                  420 (width) x 280 (height)
                </strong>
                . <br />
                <br />
                If your logo does not fit, use{" "}
                <a href="#" className="link">
                  <strong>Canva</strong>
                </a>{" "}
                to place it into a shape that will fit into the size. We have
                composed an article to assist you,{" "}
                <a href="#" className="link">
                  click here to review it out here
                </a>
                .
              </div>
              <div className="product-builder" />
              <div className="holding-onboarding">
                <div className="forms w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="update-form"
                  >
                    <input
                      onClick={() => props.showSmallWidget()}
                      type="button"
                      value="Upload Logo"
                      defaultValue="Upload Logo"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    />
                    <input
                      style={{
                        backgroundImage: "none",
                        backgroundColor: "grey",
                      }}
                      onClick={() => props.setShowLogo(false)}
                      type="button"
                      value="Done"
                      defaultValue="Done"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tabs = (props) => {
  return (
    <div className="page-tab">
      <a
        style={{ cursor: "pointer" }}
        onClick={() => props.displayStoreInfo()}
        aria-current="page"
        className={
          props.viewStoreInfo === true ? "link-tab w--current" : "link-tab"
        }
      >
        Store<span className="setting-cut">Information</span>
      </a>
      <a
        style={{ cursor: "pointer" }}
        onClick={() => props.displayPersonalInfo()}
        className={
          props.viewPersonalInfo === true ? "link-tab w--current" : "link-tab"
        }
      >
        Profile<span className="setting-cut">Information</span>
      </a>
      <a
        style={{ cursor: "pointer" }}
        onClick={() => props.displayBankInfo()}
        className={
          props.viewBankInfo === true ? "link-tab w--current" : "link-tab"
        }
      >
        Bank<span className="setting-cut">Information</span>
      </a>
    </div>
  );
};

const BankInfo = (props) => {
  return (
    <div className="main">
      <div className="container-settings">
        <div className="setting-holder">
          <div className="setting-header">
            <h5 className="setting-title">Bank information</h5>
          </div>
          <div style={{ alignItems: "baseline" }} className="setting-meta">
            <div className="store-meta">
              <div className="meta-item-2">
                {props.stripeSetupComplete !== true &&
                  props.userCurrentCountry.toLowerCase() !== "nigeria" ? (
                    <>
                      {" "}
                      <div className="box-value">
                        Connect your account via Stripe
                    </div>{" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => props.displayStripeConnect()}
                        className="account-link stripe"
                      >
                        <div>Stripe</div>
                      </div>
                    </>
                  ) : props.userCurrentCountry.toLowerCase() === "nigeria" ? (
                    <>
                      {" "}
                      <div className="box-value">
                        Your account is connected via
                    </div>{" "}
                      <div className="account-link bvn">
                        <div>BVN</div>
                      </div>

                    </>
                  ) : (
                      <>
                        <div className="box-value">
                          Your account is connected via
                    </div>
                        <div className="account-link stripe">
                          <div>Stripe</div>
                        </div>
                      </>
                    )}
              </div>
              {props.secondaryAccount?
                <div className="meta-item-2"> 
                  <div class="box-value">Your secondary account is</div> 
                  <div style={{marginLeft: "40px"}} class="account-link bvn"><div>Added</div></div>
                </div>
              :null}
            </div>
            <div className="store-action">

              <a
                onClick={() => { props.addSecondaryAccount() }}
                style={{ cursor: "pointer" }}
                className="box-btn-primary w-inline-block"
              >
                {props.secondaryAccount?
                <div style={{backgroundColor: "#10bc1085"}} className="box-btn-text-action">Edit US Domiciliary Account</div>
                :
                <div style={{backgroundColor: "#10bc1085"}} className="box-btn-text-action">Add US Domiciliary Account</div>
                }
              </a>

              <a
                onClick={() => { props.displayBankUpdate() }}
                style={{ cursor: "pointer" }}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-action">Update Bank Info</div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

const PersonalInfo = (props) => {
  return (
    <div className="main">
      <div className="container-settings">
        <div className="setting-holder">
          <div className="setting-header">
            <h5 className="setting-title">Personal information</h5>
          </div>
          <div className="setting-meta">
            <div className="store-meta">
              <div className="meta-item half">
                <div className="box-title">Name</div>
                <div className="box-value">
                  {props.firstName} {props.lastName}
                </div>
              </div>
              <div className="meta-item half">
                <div className="box-title">Mobile</div>
                <div className="box-value">{props.phoneNumber}</div>
              </div>
              <div className="meta-item half">
                <div className="box-title">Email</div>
                <div className="box-value">{props.email}</div>
              </div>
              <div className="meta-item half">
                <div className="box-title">Password</div>
                <div className="box-value">*********</div>
              </div>
              {/* <div className="meta-item">
                <div className="box-title">Date Joined</div>
                <div className="box-value">23 July, 2020</div>
              </div> */}
            </div>
            <div className="store-action">
              <a
                onClick={() => props.displayLoginUpdate()}
                style={{ cursor: "pointer" }}
                className="box-btn-primary w-inline-block"
              >
                <div className="box-btn-text-action">Update Profile</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const removeCustomTheme = async (id, name) => {
  try {
    Loading(true, `Removing ${name} theme`);
    const db = firebase.firestore();
    await db
      .collection("sellersAccounts")
      .doc(id)
      .set({ customTheme: false, themeName: false }, { merge: true });
    await db
      .collection("sellersAccounts")
      .doc(id)
      .collection("stores")
      .doc(id)
      .set({ customTheme: false, themeName: false }, { merge: true });
    Loading(false);
    window.location.reload();
  } catch (error) {
    console.log("error removing custom theme", error);
    alert("Error removing theme");
    return Loading(false);
  }
};

const StoreInfo = (props) => {
  return (
    <div className="main">
      <div className="container-settings">
        <div className="setting-holder">
          <div className="setting-header">
            <h5 className="setting-title">Store information</h5>
          </div>
          <div className="setting-meta">
            <div className="store-meta">
              <div className="meta-item half">
                <div className="box-title">Store Name</div>
                <div className="box-value">{props.storeName}</div>
              </div>
              <div className="meta-item half">
                <div className="box-title">Store Category</div>
                <div className="box-value">{props.storeCategories}</div>
              </div>
              <div className="meta-item">
                <div className="box-title">Store Slogan</div>
                <div className="box-value">{props.description}</div>
              </div>
              <div className="meta-item">
                <div className="box-title">Store Address</div>
                <div className="box-value">{props.address}</div>
              </div>
              <div className="meta-item">
                <div className="box-title">Instagram Handle</div>
                <div className="box-value link">{props.socialInstagram}</div>
              </div>
              <div className="meta-item">
                <div className="box-title">Facebook Link</div>
                <div className="box-value link">{props.socialFacebook}</div>
              </div>
            </div>
            <div className="store-action">
              {/* <div className="site-stat">
                <div className="store-status">
                  <div className="store-state-live">Your Store is Live</div>
                </div>
              </div> */}
              <a className="box-btn-primary w-inline-block">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.displayStoreUpdate()}
                  className="box-btn-text-action"
                >
                  Update Store
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.setShowLogo(true)}
                  className="box-btn-text-action"
                >
                  Change Logo
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.setShowBanner(true)}
                  className="box-btn-text-action"
                >
                  Change Banner
                </div>
                {props.themeName && props.sellerId ? (
                  <div
                    style={{ cursor: "pointer", textTransform: "capitalize" }}
                    onClick={() =>
                      removeCustomTheme(props.sellerId, props.themeName)
                    }
                    className="box-btn-text-action"
                  >
                    Remove {props.themeName} Theme
                  </div>
                ) : null}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingsPage = (props) => {
  const [showBanner, setShowBanner] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  return (
    <>
      {showBanner === true ? (
        <StoreBannerInfo
          setShowBanner={setShowBanner}
          showBigWidget={props.showBigWidget}
        />
      ) : null}
      {showLogo === true ? (
        <StoreLogoInfo
          setShowLogo={setShowLogo}
          showSmallWidget={props.showSmallWidget}
        />
      ) : null}
      <div className="panel-center">
        <div className="page-header">
          <h4 className="header-title">Settings</h4>
          {/* <div className="store-status">
            <div className="store-state-live">Your Store is Live</div>
          </div> */}
        </div>
        <Tabs
          displayBankInfo={props.displayBankInfo}
          displayPersonalInfo={props.displayPersonalInfo}
          displayStoreInfo={props.displayStoreInfo}
          viewStoreInfo={props.viewStoreInfo}
          viewPersonalInfo={props.viewPersonalInfo}
          viewBankInfo={props.viewBankInfo}
        />

        {props.viewStoreInfo === true ? (
          <StoreInfo
            email={props.email}
            storeName={props.storeName}
            description={props.description}
            address={props.address}
            storeURL={props.storeURL}
            storeCategories={props.storeCategories}
            socialInstagram={props.socialInstagram}
            socialFacebook={props.socialFacebook}
            displayStoreUpdate={props.displayStoreUpdate}
            useDefaultBigBanner={props.useDefaultBigBanner}
            showBigWidget={props.showBigWidget}
            // useDefaultBigBanner={props.useDefaultSmallBanner}
            setShowBanner={setShowBanner}
            setShowLogo={setShowLogo}
            sellerId={props.sellerId}
            themeName={props.themeName}
          />
        ) : null}
        {props.viewPersonalInfo === true ? (
          <PersonalInfo
            displayLoginUpdate={props.displayLoginUpdate}
            firstName={props.firstName}
            lastName={props.lastName}
            phoneNumber={props.phoneNumber}
            email={props.email}
          />
        ) : null}
        {props.viewBankInfo === true ? (
          <BankInfo
            stripeSetupComplete={props.stripeSetupComplete}
            displayStripeConnect={props.displayStripeConnect}
            userCurrentCountry={props.userCurrentCountry}
            displayBankUpdate={props.displayBankUpdate}
            addSecondaryAccount={props.addSecondaryAccount}
            secondaryAccount={props.secondaryAccount}
          />
        ) : null}
      </div>
    </>
  );
};
export default SettingsPage;
