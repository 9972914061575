import React, { useState } from "react";
import priceFormater from "../../../../helpers/priceFormater";
import getDateFromTimestamp from "../../../../helpers/getDateFromTimestamp";

import styles from './UpcomingBookings.module.css'

import { days, months, reverseTimeMap } from "../../../../../constants";
import Modal from "../../../../UI/Modal";
import BookingDetails from "./BookingDetails";


/**
 * This component displays completed bookings in a table
 * @param {Object} props 
 */
const CompletedBookings = (props) => {
    const [bookingDetails, setBookingDetails] = useState({})
    const [showBookingDetails, setShowBookingDetails] = useState(false)
    const [bookingDate, setBookingDate] = useState(new Date())

    const openModal = (booking) => {
        setBookingDetails(booking)
        setBookingDate(new Date(booking.bookingTimestamp))
        setShowBookingDetails(true)
    }

    const onCancelBooking = () => {
        props.cancelBooking(bookingDetails)
        setShowBookingDetails(false)
    }
    return (
        <div style={{ minWidth: "100%" }} className="main">
            <Modal show={showBookingDetails} closeModal={() => setShowBookingDetails(false)}>
                <BookingDetails
                    bookingDetails={bookingDetails}
                    bookingDate={bookingDate}
                />
            </Modal>
            <div className="container-orders">
                <div className="order-holder">
                    <div className="order-day">
                        <h5 className="day-title">Completed Bookings</h5>
                        {props.completedBookings.length > 0 &&
                            <table className={styles.table}>
                                <col style={{ width: "22%" }} />
                                <col style={{ width: "14%" }} />
                                <col style={{ width: "50%" }} />
                                <col style={{ width: "14%" }} />
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Amount</th>
                                        <th>Service</th>
                                        {/* <th>Status</th> */}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.completedBookings.map((booking, i) => {
                                            let date = new Date(booking.bookingTimestamp)
                                            return (
                                                <tr>
                                                    <td>{`${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}, ${reverseTimeMap[booking.time]}`}</td>
                                                    <td>{priceFormater(
                                                        booking.amount,
                                                        'USD'
                                                    )}</td>
                                                    <td>{booking.serviceName}</td>
                                                    {/* <td>{booking.status}</td> */}
                                                    <td onClick={() => openModal(booking)} style={{ color: "#6741AA", cursor: "pointer" }}>Details &gt;</td>
                                                </tr>
                                            )

                                        })
                                    }
                                </tbody>
                            </table>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompletedBookings;
