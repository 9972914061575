import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import UpdateBankSuccess from "../../../common/UpdateBankSuccess";
// import checkInfo from "../../../../images/check-info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BvnInfo from "../../../UI/BvnInfo";
import UpdateBankForm from "./UpdateBankForm";
import UpdateUSBankForm from "./UpdateUSBankForm"
import UpdateBankFailed from "../../../common/UpdateBankFailed";
import PhoneVerification from "./PhoneVerification.js";
import createStripeUser from "../../../helpers/createStripeUser";
class UpdateBank extends Component {
  state = {
    sellerName: "",
    accountHolderName: "",
    accountHolderType: "individual",
    routingNumber: "",
    bankName: "",
    accountNumber: "",
    sellerId: this.props.sellerId,
    country: this.props.country,
    phoneNumber: "",
    bvn: "",
    validBVN: false,
    bankNames: [],
    banks: {},
    updateSuccess: false,
    updateError: false,
    showAccNum: "password",
    showBvn: "password",
    // subaccount_code: "",
    loading: false,
    displayBankInfo: false,
    confirmationCode: "",
    showSMSVerification: false,
    accountNumberError: ""
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getBankInfo = () => {
    const db = firebase.firestore();
    const sellerUid = firebase.auth().currentUser.uid;
    db.collection("sellersAccounts")
      .doc(sellerUid)
      .collection("bank")
      .get()
      .then(async (sellerStoreData) => {
        sellerStoreData.docs.forEach((doc) => {
          const {
            accountHolderName,
            bankName,
            accountNumber,
            bvn,
          } = doc.data();
          this.setState({
            accountHolderName,
            bankName,
            accountNumber,
            bvn,
          });
        });
      });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.displayLoading();
    // capture the current user uid
    const sellerUid = firebase.auth().currentUser.uid;
    const db = firebase.firestore();
    const {
      accountHolderName,
      bankName,
      accountNumber,
      // subaccount_code
    } = this.state;
    let bvn = "";
    if (typeof (this.state.bvn) === 'undefined' || this.state.bvn === bvn) {
      bvn = "01234567890";
      this.setState({
        bvn: "01234567890"
      });
    } else {
      bvn = this.state.bvn;
    }
    console.log("state--", this.state);
    /* Reject their input if the string
    * is not of length 10 
    * is not a Number
    * contains a letter or period
    */
    let regEx = /[a-zA-Z\.\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()_]/g
    if (accountNumber.length !== 10 || isNaN(accountNumber) || regEx.test(accountNumber)) {
      this.hideLoading();
      return toast.error("Please enter a 10 digit account number");
    }

    const storeDataToDatabase = async (subaccount_code) => {
      console.log("storing subAcc ", subaccount_code);
      // check for seller account in the collection 'sellersAccount' based on the seller's uid

      // create a sub-collection inside the sellers document to save the user's stores
      var docRef = db
        .collection("sellersAccounts")
        .doc(sellerUid)
        .collection("bank")
        .doc(sellerUid);
      docRef
        .set({
          accountHolderName,
          bankName,
          accountNumber,
          bvn,
          subaccount_code,
        })
        .then(
          // after the store has been setup direct the seller to the seller's dashboard
          this.setState({
            updateSuccess: true,
          })
        )
        .catch((err) => {
          // this.setState({
          //   updateError: true,
          // });
          console.log(err, "<--- error with saving store");
          this.displayError();
        });
    };
    if (accountHolderName && bankName && accountNumber && bvn) {
      // validate input with bvn info first
      const result = await this.validateBVN();
      if (result.valid === true) {
        storeDataToDatabase(result.subaccount_code);
      } else {
        // toast.error("Incorrect info, please verify that you have entered the correct bank information.")
        // this.setState({
        //   updateError: true,
        // });
        // return;
        this.hideLoading();
        return this.displayError();
      }
    } else {
      toast.error("Please enter all necessary information");
      return;
    }
  };

  displayLoading = () => {
    this.setState({ loading: true });
  };

  hideLoading = () => {
    this.setState({ loading: false });
  };

  displayError = () => {
    this.setState({
      updateError: true,
    });
    // setTimeout(() => this.hideError(), 60000);
  };

  hideError = () => {
    this.setState({ updateError: false });
  };

  validateBVN = async () => {
    try {
      this.displayLoading();
      const { accountHolderName, accountNumber, bankName } = this.state;
      let bvn = "";
      if (typeof (this.state.bvn) === 'undefined' || this.state.bvn === bvn ) {
      bvn = "01234567890";
    } else {
      bvn = this.state.bvn;
    }
      if (!accountHolderName) {
        let message = "Please enter your full name";
        alert(message);
        console.log(message);
        this.hideLoading();
        return false;
      }

      let nameArray = accountHolderName.split(" ");
      if (nameArray.length < 2) {
        let message = "Please enter your full name";
        alert(message);
        console.log(message);
        this.hideLoading();
        return false;
      }

      let firstName = nameArray[0];
      let lastName = nameArray[1];

      if (!firstName || !lastName) {
        let message = "Please enter your full name";
        alert(message);
        console.log(message);
        this.hideLoading();
        return false;
      }
      const { banks } = this.state;
      const bankCode = banks[bankName];
      let response = await fetch(
        "https://us-central1-cashaam-dev.cloudfunctions.net/bvn-bvncheck",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account_number: accountNumber,
            bank_code: bankCode,
            bvn: bvn,
            first_name: firstName,
            last_name: lastName
          }),
        }
      );
      console.log("response", response);
      let responseJson = await response.json();
      let { account_name, subaccount_code } = responseJson;
      // this.setState({ subaccount_code });
      account_name = account_name.toLowerCase();

      console.log("resp--", response);
      console.log("respJSON--", responseJson);
      console.log("acc Name--", account_name);
      console.log("bank Name--", bankName);
      console.log("bank code--", bankCode);

      let names = accountHolderName.toLowerCase().split(" ");
      console.log("input acc-hold-name--", accountHolderName);
      console.log("input names--", names);

      let valid = null;
      names.forEach((name) => {
        if (account_name.includes(name)) {
          console.log("PASSED--", name);
          valid = true;
        } else {
          valid = false;
        }
      });
      this.hideLoading();
      return { valid, subaccount_code };
    } catch (error) {
      // this.hideLoading();
      console.log("error validating bankInfo--", error);
      // this.displayError();
      // toast.error("error validing bank, Please check your input and try again");
      return false;
    }
  };

  onShowAccNum = (val) => {
    if (val === "password") {
      this.setState({ showAccNum: "text" });
    } else {
      this.setState({ showAccNum: "password" });
    }
  };

  onShowBvn = (val) => {
    if (val === "password") {
      this.setState({ showBvn: "text" });
    } else {
      this.setState({ showBvn: "password" });
    }
  };

  selectBank = (value) => {
    this.setState({ bankName: value });
  };

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          //onSignInSubmit();
        },
      }
    );

    //fetch all bank names from firebase
    const db = firebase.firestore();
    let bankNames = [];
    let banks = {};
    db.collection("bank")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          banks[doc.data().bankName] = doc.data().code;
          bankNames.push(doc.data().bankName);
        });
      })
      .then(
        this.setState({
          bankNames: bankNames,
          banks: banks,
        })
      )
      .catch((error) => {
        console.log("error getting bank data form firestore", error);
      });
    var user = firebase.auth().currentUser;
    this.setState({
      phoneNumber: user.phoneNumber,
    })

    // checks for the auth state change of the user
    firebase.auth().onAuthStateChanged((u) => {
      if (u) {
        this.setState({
          sellerName: u.displayName,
        });
        this.getBankInfo();
      } else {
        // this.props.history.push("/");
      }
    });
  }

  setUpLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  backToSettings = () => {
    //re-display the settings form to do more updates
    this.setState({
      updateSuccess: false,
    });

    //hide this window
    this.props.cancel();
  };

  retry = () => {
    this.setState({
      updateSuccess: false,
      displayBvnInfo: false,
      updateError: false,
      loading: false,
    });
  };

  onGoToBvnForm = (e) => {
    e.preventDefault();
    const { accountNumber } = this.state;
    if (accountNumber.length !== 10 ) {
      return toast.error("Please enter a 10 digit account number");
    }
    this.setState({ displayBvnInfo: true });
  };

  onShowBankForm = () => {
    this.setState({
      displayBvnInfo: false,
    });
  };

  confResult = null;

  verifyPhone = () => {
    var appVerifier = window.recaptchaVerifier;
    console.log(this.state.phoneNumber);
    firebase
    .auth()
    .signInWithPhoneNumber(`${this.state.phoneNumber}`, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      this.setState({
        showSMSVerification: true,
      });
      this.confResult = confirmationResult;
      console.log(this.confResult);
      console.log(confirmationResult);
    })
    .catch(function (error) {
      // Error; SMS not sent
      // ...
      console.log(error);
    });
  };
  
  submitConfResult = async () => {
    this.displayLoading();
    var code = this.state.confirmationCode;
    this.confResult
      .confirm(code)
      .then(async (result) => {
        this.hideLoading();
        this.setState({
          displayBankInfo: true,
        })
      }).catch((error) => {
        this.hideLoading();
        // Loading(false);
        // User couldn't sign in (bad verification code?)
        // ...
        if (error.code === "auth/invalid-verification-code") {
          toast.error("Wrong code, please check your phone and try again");
        }
        console.log("error confirming code", error);
      })
  };

  submitBankForm = async (e) => {
    e.preventDefault();
    this.displayLoading();
    try {
      let { sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber } = this.state;

      if(!accountHolderName || accountHolderName.length<2){
        this.hideLoading();
        return toast.error("Please enter the name on the account");
      }


      // let regEx = /[a-zA-Z\.\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()_]/g
      if (isNaN(accountNumber)) {
        this.hideLoading();
        return toast.error("Please only enter numbers for the Account Number");
      }

      if (isNaN(routingNumber)) {
        this.hideLoading();
        return toast.error("Please only enter numbers for the Routing Number");
      }

      let result = await createStripeUser(sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber);

      this.hideLoading();
      this.setState({updateSuccess: true})
    } catch (error) {
      console.log("error submiting form", error);
      // this.displayError()

      //if it fails, save the info into firebase so we can manually add it later (temporary solution so the user does not get stuck on this page)
      this.saveFailedBankAttempt();
    }
  }

  saveFailedBankAttempt = async () => {
    console.log("saveFailedBankAttempt");
    const db = firebase.firestore();
    let { sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber } = this.state;

    await db.collection("failedStripeAccounts").doc(sellerId).set({ sellerId, accountHolderName, accountHolderType, accountNumber, routingNumber, date: Date.now() })
    await db.collection("sellersAccounts").doc(sellerId).collection("stripe").doc(sellerId).set({
      stripe_user_id: "failed"
    })
    // await saveRegisterProgress(sellerId, 4);
    this.hideLoading();
    this.setState({updateSuccess: true})
    // this.props.history.push("/seller/dashboard/addProduct")

  }

  render() {
    return (
      <>
        {this.state.displayBankInfo === false &&
          this.state.updateSuccess === false &&
          this.state.updateError === false ? (
            <PhoneVerification
              submitConfResult={this.submitConfResult} //This sends the SMS
              verifyPhone={this.verifyPhone} //This checks the sent code
              showSMSVerification={this.state.showSMSVerification}
              backToSettings={this.backToSettings}
              handleChange={this.handleChange}
              loading={this.state.loading}
            />) : null}
        {this.state.updateSuccess === false &&
          this.state.displayBankInfo === true &&
          this.state.updateError === false ? (

            this.state.country?.toLowerCase()=="united states" || this.state.country?.toLowerCase()=="us"?
            <UpdateUSBankForm 
              accountHolderName={this.state.accountHolderName}
              accountNumber={this.state.accountNumber}
              routingNumber={this.state.routingNumber}
              loading={this.state.loading}
              handleChange={this.handleChange}
              backToSettings={this.backToSettings}
              onSubmit={this.submitBankForm}
              
              />:
            <UpdateBankForm
              handleSubmit={this.handleSubmit}
              updateError={this.state.updateError}
              onGoToBvnForm={this.onGoToBvnForm}
              handleChange={this.handleChange}
              bankName={this.state.bankName}
              bankNames={this.state.bankNames}
              selectBank={this.selectBank}
              showAccNum={this.state.showAccNum}
              onShowAccNum={this.onShowAccNum}
              showBvn={this.state.showBvn}
              onShowBvn={this.onShowBvn}
              loading={this.state.loading}
              accountHolderName={this.state.accountHolderName}
              accountNumber={this.state.accountNumber}
              bvn={this.state.bvn}
              action="update"
              backToSettings={this.backToSettings}
            />): null}

            
        {this.state.updateSuccess ? (
          <UpdateBankSuccess backToSettings={this.backToSettings} />
        ) : null}

        {this.state.updateError === true ? (
          <UpdateBankFailed retry={this.retry} />
        ) : null}
        <div id="recaptcha-container"></div>
      </>
    );
  }
}

export default UpdateBank;
