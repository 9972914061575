import firebase from "../../firebaseConfig";

const saveScheduledPickup = (
  shippingObjectId,
  tracking_number,
  provider,
  scheduleObject,
  orderIds,
  sellerId,
  customerId,
  sellerPickup,
  sellerPickupAddress,
  buyerPickup,
  buyerPickupAddress
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      let batch = db.batch();

      let schedulePickupRef = db
        .collection("scheduled_pickup")
        .doc(shippingObjectId);
      let sellerRef = db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("orders");

      let buyerRef = db
        .collection("buyersAccounts")
        .doc(customerId)
        .collection("orders");

      batch.set(schedulePickupRef, {
        shippingObjectId,
        tracking_number,
        provider,
        scheduleObject,
        orderIds,
        sellerId,
        customerId,
        sellerPickup,
        sellerPickupAddress,
        buyerPickup,
        buyerPickupAddress,
      });

      //save all orders in this specific transaction with pickup info
      //this is done together because shipping is only paid once for each transaction
      let updateAllOrders = new Promise((resolve, reject) => {
        orderIds.map((id, index, array) => {
          console.log("updating order with id ==>", id);

          let buyerOrderRef = buyerRef.doc(id);
          let sellerOrderRef = sellerRef.doc(id);

          batch.set(
            buyerOrderRef,
            {
              shippingObjectId,
              trackingNumber: tracking_number,
              provider,
              scheduleObject,
              orderStatus: "dispatched",
            },
            { merge: true }
          );
          batch.set(
            sellerOrderRef,
            {
              shippingObjectId,
              trackingNumber: tracking_number,
              provider,
              scheduleObject,
              orderStatus: "dispatched",
            },
            { merge: true }
          );
          if (index === array.length - 1) {
            resolve();
          }
        });
      });
      updateAllOrders
        .then(
          batch.commit().then(async function () {
            resolve("success");
          })
        )
        .catch((error) => {
          console.log("error updating orders in saveScheduledPickups", error);
        });
      resolve("success");
    } catch (error) {
      console.log("error saving scheduled pick up", error);
      reject(error);
    }
  });
};
export default saveScheduledPickup;
