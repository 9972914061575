import React from 'react';
import PendingOrderItem from "./PendingOrderItem";

function PendingOrders(props) {
    let pendingOrders=props.pendingOrders;
    return (
        <div className="orderlistwrap">
                <div className="orderlisttitle">
                  <div className="orderlistcheckboxtitle"> </div>
                  <div className="orderlistorderstitle">Orders</div>
                  <div className="orderlistdatetitle">Date</div>
                  <div className="orderliststatustitle">Status</div>
                  <div className="orderlistdeliverytitle">Quantity</div>
                  <div className="orderlisttotaltitle">Total</div>
                  <div className="orderlistactionstitle">Actions</div>
                  <div className="empty" />
                </div>

                {pendingOrders? pendingOrders.map((order, index) => (
                  <PendingOrderItem
                    order={order}
                    key={index}
                    index={index}
                    amount={order.productPrice}
                    currency={order.currency}
                    orderId={order.id}
                    status={order?.orderStatus}
                    date={order.orderTimestamp}
                    orderQuantity={order.orderQuantity}
                    email={order?.email}
                  />
                ))
              : null}
                
                
              </div>
    );
}

export default PendingOrders;