import React from "react";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import BottomNav from "../../../common/BottomNav";

import getUserLocation from "../../../helpers/getUserLocation";
import getBasicInfo from "../../../helpers/getBasicInfo";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getShopperInfo from "../../../helpers/getShopperInfo";
import getReferrals from "../../../helpers/getReferrals";
import FeaturePromo from "../FeaturePromo";
import NProgress from "nprogress";
import Metatags from "react-meta-tags";

import AIContent from "./AIContent";

import firebase from "../../../../firebaseConfig";
import { 
  STORE_ANALYTICS,
  VERTEX_PROMPT,
  VERTEX_DEFAULT,
  API_ENDPOINT,
  PROJECT_ID,
  MODEL_ID,
  REGION,
  VERTEX_TEMPERATURE,
  VERTEX_MAX_TOKENS,
  VERTEX_TOP_P,
  VERTEX_TOP_K,
  client_email,
  private_key
} from "../../../../secrets";

class ContentGenerator extends React.Component {
  state = {
    firstName: "",
    cartCount: 0,
    shoppingpoints: 10,
    phoneNumber: "",
    userInfo: {},
    isLoggedIn: false,

    accountNumber: "",
    bankName: "",
    paypalEmail: "",
    paypalPhone: "",
    displayPaypal: false,
    displayFlutterwave: false,
    customerCount: -1,
    addedToCart: -1,
    prevGeneratedContent:[],

    location:"Nigeria",
    platform: "Facebook",
    showSpecifier: true
  };


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getAIObjective = () => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let objective = url.searchParams.get("category");
    this.setState({
      objective: objective,
    });
  };


  createToken = async () => {
    console.log("createToken ");
    try {
      let jwt = require("jsonwebtoken");

      console.log("init jwt", jwt);
      // # Google Endpoint for creating OAuth 2.0 Access Tokens from Signed-JWT
      let auth_url = "https://www.googleapis.com/oauth2/v4/token";

      // # Permissions to request for Access Token
      let scopes = "https://www.googleapis.com/auth/cloud-platform";

      // # Set how long this token will be valid in seconds
      let expires = Math.floor(Date.now() / 1000) + 60 * 60;

      // # JWT Headers - Google uses SHA256withRSA
      let additional_headers = {
        keyid: private_key,
        algorithm: "RS256",
      };

      // # JWT Payload
      let payload = {
        iss: client_email,
        sub: client_email,
        aud: auth_url,
        iat: Math.floor(Date.now() / 1000),
        exp: expires,
        scope: scopes,
      };
      console.log("Signed JWT Param", {
        payload,
        private_key,
        additional_headers,
      });
      // # Encode the headers and payload and sign creating a Signed JWT (JWS)
      let signed_jwt = await jwt.sign(payload, private_key, additional_headers);

      console.log("fetched token now");

      //Fetch Access Token using the Signed JWT
      let params = {
        grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        assertion: signed_jwt,
      };
      const r = await fetch(auth_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });

      let token = await r.json();
      // Use the Access Token to call Vertex API
      let access_token = token["access_token"];
      console.log("access token retrieved");

      this.setState({
        access_token,
      });
    } catch (error) {
      console.log("Error Fetching Token:", error);
    }
  };

  showVideo=()=>{
    this.setState({
      displayVideo: true
    })
  }

  hideVideo=()=>{
    this.setState({
      displayVideo: false
    })
  }

  hideSpecifier=()=>{
    this.setState({
      showSpecifier: false
    })
  }
  
  pickSuggestion= async(suggestion) => {
    await this.setState({
        userPrompt:suggestion
    })
    this.generatePosts();

  }
  generatePosts = async (e) => {
    try {
      e?.preventDefault();
      let { access_token, userPrompt } = this.state;
      console.log("userPrompt", userPrompt);

      if(userPrompt){
        this.setState({
          postLoading: true
        })
        let userDate = firebase.firestore.FieldValue.serverTimestamp();
        let userTimestamp = Date.now();
        //Push back user's question by 2 seconds
        userTimestamp=userTimestamp-2000;

        let {location,platform,objective}=this.state;
        if(!location){
          location="United States"
        }

        if(!platform){
          platform="Social Media"
        }

        if(!objective){
          objective="Advertisement"
        }

        let vertex_url = `https://${API_ENDPOINT}/v1/projects/${PROJECT_ID}/locations/${REGION}/publishers/google/models/${MODEL_ID}:predict`;
        
        
        let content="";
        if(this.state.showSpecifier){
          content=`"${VERTEX_PROMPT}"
          ${userPrompt} - ${location} - ${platform} - ${objective}.`;
        }else{
          content=`${VERTEX_DEFAULT}
          ${userPrompt}
          `
        }
        
        console.log("content",content);

        let request_body = {
          instances: [
            {
              content: content,
            },
          ],
          parameters: {
            temperature:   VERTEX_TEMPERATURE,
            maxOutputTokens: VERTEX_MAX_TOKENS,
            topP: VERTEX_TOP_P,
            topK: VERTEX_TOP_K
          },
        };

        this.setState({
          userPrompt: "",
          initialPrompt: true
        })

        const vertex = await fetch(vertex_url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
          body: JSON.stringify(request_body),
        });

        console.log("vertex resp", vertex);

        let prediction = await vertex.json();

        console.log("prediction", prediction);
        let result = prediction?.predictions[0]?.content;
        console.log("result", result);

        this.setState({
          generatedContent: result,
          previousPrompt: userPrompt,
          userDate,
          userTimestamp,
          prediction: prediction?.predictions
        });

        await this.saveContent();
    }
    } catch (error) {
      console.log("error getting vertex generated content", error);
      this.setState({
        postLoading: false
      })
    }
  };

  saveContent = async() => {
    try {
      const db = firebase.firestore();

      let { storeInfo, userDate, userTimestamp,prediction, previousPrompt } = this.state;

      let { slug, id, phoneNumber, email } = storeInfo;
      let userDoc=db.collection("contentGenerator").doc();
      let userObjectId = userDoc.id;

      let aiDoc=db.collection("contentGenerator").doc();
      let aiObjectId = aiDoc.id;
      
      let aiDate = firebase.firestore.FieldValue.serverTimestamp();
      let aiTimestamp = Date.now();

      let userObject = {
        text: {
          predictions: [
            {
              content: previousPrompt,
            },
          ],
        },
        images: {},
        dateCreated: userDate,
        timestamp: userTimestamp,
        type: "user",
        slug: slug,
        userId: id,
        phoneNumber: phoneNumber,
        email: email,
        id: userObjectId,
      };

      let aiObject = {
        text: {
          predictions: prediction,
        },
        images: {},
        dateCreated: aiDate,
        timestamp: aiTimestamp,
        type: "ai",
        slug: slug,
        userId: id,
        phoneNumber: phoneNumber,
        email: email,
        id: aiObjectId,
      };


      console.log("userDoc",userDoc)
      userDoc.set(userObject);
      aiDoc.set(aiObject);


      let prevGeneratedContent=this.state.prevGeneratedContent;

      prevGeneratedContent.push(userObject);
      prevGeneratedContent.push(aiObject);

      let sortedPosts = prevGeneratedContent.sort(function(x, y){
        let yTimeStamp=y.timestamp;
        let xTimeStamp=x.timestamp;
        
        return xTimeStamp -yTimeStamp;
      })

      this.setState({
        prevGeneratedContent:sortedPosts,
        postLoading: false
      })


    } catch (error) {
      console.log("Error saving the generated Content", error);
      this.setState({
        postLoading: false
      })
    }
  };

  getGeneratedPost =async(sellerId)=>{
    try{
        console.log("getGeneratedPost",sellerId)

        if(sellerId){
            
            const db = firebase.firestore();
            let snapshot = await db.collection("contentGenerator")
            .where("userId", "==", sellerId)
            .orderBy("timestamp", "desc")
            .limit(6)
            .get();

            if (snapshot.empty) {
            console.log("No buyer found");
            }
            console.log("snapshot",snapshot)

            let prevGeneratedContent = [];
            snapshot.docs.forEach((doc) => {
                prevGeneratedContent.push(doc.data());
            });

            let sortedPosts = prevGeneratedContent.sort(function(x, y){

                let yTimeStamp=y.timestamp;
                let xTimeStamp=x.timestamp;
                
                return xTimeStamp -yTimeStamp;
            })


            console.log("sortedPosts",sortedPosts)
            this.setState({
                prevGeneratedContent: sortedPosts
            })
        }


    }catch(error){

        console.log("Error getGeneratedPost",error)
    }

  }

  getNewFeature= async()=>{
    try{
      let {activated, storeInfo}=this.state;
      console.log("getNewFeature")
      const db = firebase.firestore();

      let featurePromo = await db.collection("newFeature")
        .where("use", "==", "Facebook Ads Tutorial")
        .limit(1)
        .get();
    

      console.log("featurePromo",featurePromo)
      let featureInfo = null;
      if (featurePromo.empty) {
        console.log("No promo found");
        return;
      } else {
        featureInfo = featurePromo.docs[0].data();
      }
      console.log("featureInfo =>", featureInfo);

      this.setState({
        newFeature: true,
        featureTitle: featureInfo?.title,
        featureDescription: featureInfo?.description,
        featureVideo: featureInfo?.embedVideo,
        featureHeading: featureInfo?.heading?featureInfo?.heading:"Tutorial",
        featureLink: featureInfo?.link,

      })
    }catch (error) {
      console.log("error getNewFeature: ",error)
    }
  }


  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  fetchSellerInfo = async (id) => {
    try {
      console.log("fetchSellerInfo", id);
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let shortReferral = id?.slice(0, 6);
      shortReferral = shortReferral?.toUpperCase();

      let {
        firstName,
        lastName,
        slug,
        activated,
        storeName,
        phoneNumber,
        referralPayment,
        subscriptionActive,
        subscriptionType,
      } = basicInfo;

      let storeURL = `http://www.cashaam.com/${slug}`;
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        activated: typeof activated !== "undefined" ? activated : true,
        storeInfo: basicInfo,

        country: basicInfo?.country,
      });

      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData", { locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  loadHubspot = () => {
    let script = document.createElement("script");
    script.src = "//js.hs-scripts.com/21585626.js";
    script.id = "hs-script-loader";
    script.addEventListener("load", () => {
      console.log("hubspotLoaded");
      this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  };

  loadPaystack = () => {
    let script = document.createElement("script");
    script.src = "https://js.paystack.co/v1/inline.js";
    script.addEventListener("load", () => {
      console.log("paystack loaded");
      this.setState({ paystackLoaded: true });
    });
    document.body.appendChild(script);
  };

  loadPendo = () => {
    let script = document.createElement("script");

    let pendoKey = "6ff8873a-3edf-4dc1-6d19-5a3490191d2a";

    script.src = "https://cdn.pendo.io/agent/static/" + pendoKey + "/pendo.js";
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);
  };

  async componentDidMount() {
    try {
      NProgress.start();

      this.loadPaystack();
      this.loadHubspot();
      this.loadPendo();
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.createToken();
          if (this.state.pendoLoaded) {
            console.log("initialize pendo");
            pendo.initialize({
              visitor: {
                id: user.uid, // Required if user is logged in
                email: user?.email, // Recommended if using Pendo Feedback, or NPS Email
                full_name: user?.displayName, // Recommended if using Pendo Feedback
                role: "seller",
              },

              account: {
                id: user.uid,
                planLevel: "seller",
                userType: "seller",
              },
            });
          }

          //await this.getGeneratedPost(user.uid);

          let shortReferral = user.uid?.slice(0, 6);
          shortReferral = shortReferral.toUpperCase();

          console.log("shortReferral", shortReferral);
          let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

          this.setState({
            shortReferral,
            referralLink,
            sellerId: user.uid,
          });
          localStorage.setItem("cashaam_uid", user.uid);
          this.getLocation();

          this.getAIObjective();
          //check the user registration progress
          let step = await getRegistrationProgress(user.uid);

          step = parseFloat(step);
          if (step === 100) {
            // this.props.history.push("/seller/dashboard");
            console.log("completed registration step:", step);
          } else {
            console.log("MY STEP", step);
            if (step < 4) {
              this.props.history.push("/seller/dashboard/setup/onboarding");
            }
          }

          NProgress.set(0.5);
          const sellerUid = user.uid;
          await this.fetchSellerInfo(sellerUid);
          this.getNewFeature();

          NProgress.done();
        } else {
          this.props.history.push("/");
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Billing & Referral</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Chat" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>

        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />

        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            {/* <MarketingDashboard
              storeInfo={this.state.storeInfo}
              customerCount={this.state.customerCount}
              addedToCart={this.state.addedToCart}
              storeStats={this.state.storeStats}
              storeAnalytics={this.storeAnalytics}
              daysFilter={this.state.daysFilter}
            /> */}

            <AIContent
              generatedContent={this.state.generatedContent}
              handleChange={this.handleChange}
              generatePosts={this.generatePosts}
              pickSuggestion={this.pickSuggestion}
              hideSpecifier={this.hideSpecifier}
              showVideo={this.showVideo}
              userPrompt={this.state.userPrompt}
              prevGeneratedContent={this.state.prevGeneratedContent}
              firstName={this.state.storeInfo?.firstName}
              lastName={this.state.storeInfo?.lastName}
              storeDescription={this.state.storeInfo?.description}
              postLoading={this.state.postLoading}
              objective={this.state.objective}
              location={this.state.location}
              platform={this.state.platform}
              showSpecifier={this.state.showSpecifier}
            />

            {this.state.displayVideo?
              <FeaturePromo 
                hideNewFeature={this.hideVideo}
                featureTitle={this.state.featureTitle}
                featureDescription={this.state.featureDescription}
                featureVideo={this.state.featureVideo}
                featureHeading={this.state.featureHeading}
                featureLink={this.state.featureLink}
                activated={true}
              />
            :null}

            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ContentGenerator;
