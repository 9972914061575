import React from "react";

class TestPage extends React.Component {
  state = {
    displayVerification: false,
    step1: false,
    step2: false,
    step3: false,
  };

  setupInsta = async () => {
    console.log("setupInsta");

    let appId = "595165849472744";
    // let redUri = window.location.origin + "/insta";
    let redUri = "https://store.cashaam.dev/insta-response";
    let url = `https://api.instagram.com/oauth/authorize?client_id=${appId}&redirect_uri=${redUri}&scope=user_profile,user_media&response_type=code`;
    window.open(url, "_blank").focus();

  };

  getReferalCodeFromURL = async () => {
    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");

    console.log("Instagram temposrary code",code)

    if(code){
      
      const queryData ={
          "code": code,
          "redirectUri": "https://store.cashaam.dev/insta-response",
          "sellerId":"xIht1AuxVXPeUpSHrITYveSzPLh2"
      };
        const res = await fetch(
          "http://localhost:8080/get-access-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(queryData),
          }
        );

        if (res.status === 200) {
          const access = await res.json();
          console.log("access",access)
        } else {
          console.log("error getting long lived access", res.status);
          reject(res);
        }

    }

     // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
    if(window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('Code is passed!')
    }

  };

  closeBrowser=()=>{

    console.log("close browser")
    let url_string = window.location.href;
    let url = new URL(url_string);
    let code = url.searchParams.get("code");
    // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
    if(window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(code)
    }
  }

  async componentDidMount() {
    try {
         
        this.getReferalCodeFromURL();
    } catch (err) {
      console.log(err);
    }
  }


  render() {
    return (
      <>
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1">
          <div className="icon-success w-embed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-circle-check"
              width={84}
              height={84}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <circle cx={12} cy={12} r={9} />
              <path d="M9 12l2 2l4 -4" />
            </svg>
          </div>
          <h4 style={{lineHeight: "79px", fontSize: "27px"}} className="h4">Instagram Connection</h4>
        </div>
        <div className="microcopy-signup">
          We've successfully connected to your public instagram account. We'll give you personaized post recommendations depending on your history posts.
        </div>
        <div className="holder-btn">
          <div className="btn-primary">
            <div style={{ cursor: "pointer" }} onClick={this.closeBrowser} className="text-btn-primary">Continue</div>
          </div>

        </div>
      </div>
    </div>



      </>
    );
  }
}

export default TestPage;
