import React, { Component }  from "react";

class UpgradePopup extends Component {


  render() {
    return (
      <div style={{ display: "flex" }} className="tint-background">
        <div className="pop-container calendar-pop">
          <div
            style={{ display: "flex", overflow: "scroll", maxHeight: "90vh" }}
            className="pop-us-ship-label"
          >
            <div className="pop-header">
              <div className="text-main">UPGRAGE</div>
              <img 
              onClick={()=> this.props.hideUpgrade()} 
              style={{height:"35px"}} src="https://res.cloudinary.com/cashaam/image/upload/v1661174410/logos/red-x-removebg-preview_tmupc9.png" />
              
            </div>
            <div className="pop-content">
              <div 
              style={{paddingBottom:"10px"}}
              className="sub-title">
              {this.props.title}
              </div>
              <div className="pop-text bold">
                {this.props.description}
              </div>

              
            </div>
            

            <div className="pop-footer">
              <a
                href="/seller/dashboard/rewards"
                style={{ cursor: "pointer" }}
                className="btn-secondary"
              >
                
                <div className="text-btn-tertiary">Upgrade</div>
                
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpgradePopup;
