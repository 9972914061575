import React, { Component } from "react";

import ChatBody from "./chat-ui/chatBody/ChatBody";
import Metatags from "react-meta-tags";
import TopHeader from "../../common/TopHeader";
import LeftNav from "../../common/LeftNav";
import RightNav from "../../common/RightNav";
import BottomNav from "../../common/BottomNav";

import firebase from "../../../firebaseConfig";
import getRegistrationProgress from "../../helpers/getRegistrationProgress";
import getBasicInfo from "../../helpers/getBasicInfo";
import getProductCount from "../../helpers/getProductCount";
import getOrderCount from "../../helpers/getOrderCount";
import getSoldItemsCount from "../../helpers/getSoldItemsCount";
import getDispatchedOrders from "../../helpers/getDispatchedOrder";
import getPeriodicOrderInfo from "../../helpers/getPeriodicOrderInfo";
import getUnreadChat from "../../helpers/getUnreadChat";
import getChatList from "../../helpers/getChatList";
import getUserLocation from "../../helpers/getUserLocation";
import NProgress from "nprogress";

export default class Messaging extends Component {
  state = {
    storeName: "",
    sellerId: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    slug: "",
    storeURL: "",
    productCount: -1,
    orderCount: -1,
    customerCount: -1,
    chatCount: -1,
    storeAlreadySetup: true,
    bankAlreadySetup: true,
    emailVerified: true,
    userCurrentCountry: "",
    localCurrency: "",
    soldItemsCount: -1,
    dispatchedOrdersCount: -1,
    oneDayRevenue: -1,
    sevenDaysRevenue: -1,
    thirtyDaysRevenue: -1,
    oneDayOrderCount: -1,
    sevenDayOrderCount: -1,
    thirtyDayOrderCount: -1,
    activated: null,
    showLeftNav: false,
    showGoLive: false,
    resources: [],
    chatList: [],
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.

        this.props.history.push("https://store.cashaam.com/seller/signin");
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  fetchSellerInfo = async (id) => {
    try {
      const db = firebase.firestore();

      const basicInfo = await getBasicInfo(id);

      let { firstName, lastName, slug, activated, storeName, phoneNumber } =
        basicInfo;

      let storeURL = `http://www.cashaam.com/${slug}`;
      let country = this.state.userCurrentCountry.toLocaleLowerCase();
      this.setState({
        storeName,
        firstName,
        lastName,
        phoneNumber,
        slug,
        storeURL,
        activated: typeof activated !== "undefined" ? activated : true,
        storeInfo: basicInfo
      });

      let productCount = 0;
      let orderCount = 0;
      let soldItemsCount = 0;
      let dispatchedOrdersCount = 0;
      let oneDayOrderInfo = 0;
      let sevenDayOrderInfo = 0;
      let thirtyDayOrderInfo = 0;
      getProductCount(id).then((result) => {
        productCount = result;
        this.setState({
          productCount,
        });
      });
      getOrderCount(id).then((result) => {
        orderCount = result;
        this.setState({
          orderCount,
        });
      });
      getSoldItemsCount(id).then((result) => {
        soldItemsCount = result;
        this.setState({
          soldItemsCount,
        });
      });

      getDispatchedOrders(id).then((result) => {
        dispatchedOrdersCount = result;
        this.setState({
          dispatchedOrdersCount,
        });
      });
      // getPeriodicOrderInfo(id, 1, country).then((result) => {
      //   oneDayOrderInfo = result;
      //   let oneDayRevenue = oneDayOrderInfo.totalAmount;
      //   let oneDayOrderCount = oneDayOrderInfo.count;
      //   this.setState({
      //     oneDayRevenue,
      //     oneDayOrderCount,
      //   });
      // });
      // getPeriodicOrderInfo(id, 7, country).then((result) => {
      //   sevenDayOrderInfo = result;
      //   let sevenDaysRevenue = sevenDayOrderInfo.totalAmount;
      //   let sevenDayOrderCount = sevenDayOrderInfo.count;
      //   this.setState({
      //     sevenDaysRevenue,
      //     sevenDayOrderCount,
      //   });
      // });
      // getPeriodicOrderInfo(id, 30, country).then((result) => {
      //   thirtyDayOrderInfo = result;
      //   let thirtyDaysRevenue = thirtyDayOrderInfo.totalAmount;
      //   let thirtyDayOrderCount = thirtyDayOrderInfo.count;
      //   this.setState({
      //     thirtyDaysRevenue,
      //     thirtyDayOrderCount,
      //   });
      // });

      return Promise.resolve(slug);
    } catch (error) {
      console.log("error fetching seller info", error);
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log("locationData",{ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  async componentDidMount() {
    NProgress.start();

    this.loadHubspot();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let shortReferral = user.uid?.slice(0, 6);
        shortReferral = shortReferral.toUpperCase();

        let referralLink = `https://cashaam.com/launch?referral_code=${shortReferral}`;

        this.setState({
          shortReferral,
          referralLink,
          sellerId: user.uid,
        });
        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );
        localStorage.setItem("cashaam_uid", user.uid);
        this.getLocation();

        //check the user registration progress
        let step = await getRegistrationProgress(user.uid);

        step = parseFloat(step);
        if (step === 100) {
          // this.props.history.push("/seller/dashboard");
          console.log("completed registration step:", step);
        } else {
          console.log("MY STEP", step);
          if (step < 4) {
            this.props.history.push("/seller/dashboard/setup/onboarding");
          }
        }

        NProgress.set(0.5);
        const sellerUid = user.uid;
        await this.fetchSellerInfo(sellerUid);
        let chatList = await getChatList(sellerUid);

        console.log("chatList", chatList);

        this.setState({
          chatList,
          user,
        });

        NProgress.done();
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <div className="wrapper">
          <Metatags>
            <meta charSet="utf-8" />
            <title>Seller - Chat</title>
            <meta
              content="Cashaam - Manage your Product with Ease"
              name="description"
            />
            <meta content="Seller - Chat" property="og:title" />
            <meta
              content="Cashaam - Manage your Product with Ease"
              property="og:description"
            />
            <meta content="summary" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <meta content="Webflow" name="generator" />
            <meta name="msapplication-TileColor" content="#870000" />
            <meta name="theme-color" content="#870000" />
          </Metatags>
        </div>

        
        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />

        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />

            <div className="__main chat-main panel-center">
              <ChatBody user={this.state.user} chatList={this.state.chatList} />
            </div>
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="dashboard"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          {/* <RightNav logoutHandler={this.logoutHandler} /> */}
        </div>
      </>
    );
  }
}
