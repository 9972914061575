import React, { useState, useEffect,useRef } from "react";
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import InputTags from "./InputTags";
import priceFormater from "../helpers/priceFormater";
import ReactTooltip from "react-tooltip";
import MyQuillEditor from "./MyQuillEditor";
import ExtraVariant from "./ExtraVariant";
import VariantForm from "./VariantForm";
import VariantExamplePop from "./VariantExamplePop";

const ProductInfoForm = (props) => {

  const inputProdName = useRef(null)

  const [startIndex, setStartIndex] = useState(0);
  const [showExample, setShowExample] = useState(false);
  let combinationLength = Object.entries(props.variantObject).length;
  let variantForms = [];

  const loadVariantForms = () => {
    let variantArrayVals = Object.entries(props.variantObject);
    let limit = startIndex ? startIndex + 100 : 100;
    for (let i = startIndex ? startIndex : 0; i < variantArrayVals.length && i <= limit; i++) {
      const [id, varObj] = variantArrayVals[i]
      let combination = varObj.values;
      variantForms.push(<VariantForm
        numberOfColumns={combination?.length}
        combination={combination}
        productPrice={props.productPrice}
        id={id}
        price={varObj.price}
        handleVariantPriceChange={props.handleVariantPriceChange}
        handleVariantQuantityChange={props.handleVariantQuantityChange}
        quantity={varObj.quantity}
        productStock={props.productStock}
      />
      )
    }
  }
  const nextVariantPage = () => {
    if (combinationLength - startIndex > 100) {
      setStartIndex(startIndex + 100);
    }
    // loadVariantForms()
  }

  const prevtVariantPage = () => {
    if (startIndex - 100 >= 0) {
      setStartIndex(startIndex - 100);
    }
  }
  if (startIndex > combinationLength) {
    setStartIndex(0)
  }
  loadVariantForms();

  let colorVariantsExist = props.selectedColors?.length > 0 && !(props.selectedColors?.length === 1 && props.coloredInputField.includes("other-colors"));
  let sizeVariantsExist = props.tags?.length > 0;
  let variantOneExists = props.extraVariants[0]?.name && props.extraVariantsTags[0]?.length > 0;
  let variantTwoExists = props.extraVariants[1]?.name && props.extraVariantsTags[1]?.length > 0;
  let variantThreeExists = props.extraVariants[2]?.name && props.extraVariantsTags[2]?.length > 0;
  let variantExists = colorVariantsExist || variantOneExists || variantTwoExists || variantThreeExists;
  let variantHeaderNumber = 0;
  variantHeaderNumber += colorVariantsExist ? 1 : 0;
  variantHeaderNumber += sizeVariantsExist ? 1 : 0;
  variantHeaderNumber += variantOneExists ? 1 : 0;
  variantHeaderNumber += variantTwoExists ? 1 : 0;
  variantHeaderNumber += variantThreeExists ? 1 : 0;
  console.log("HEADER COUNT", variantHeaderNumber);
  // useEffect(() => {
  //   loadVariantForms();

  // }, [props.variantObject])

  // for (const [id, varObj] of Object.entries(props.variantObject)) {
  //   let combination = varObj.values;
  //   if (variantForms.length < 101) {
  //     variantForms.push(<VariantForm
  //       numberOfColumns={combination?.length}
  //       combination={combination}
  //       productPrice={props.productPrice}
  //       id={id}
  //       price={varObj.price}
  //       handleVariantPriceChange={props.handleVariantPriceChange}
  //       handleVariantQuantityChange={props.handleVariantQuantityChange}
  //       quantity={varObj.quantity}
  //       productStock={props.productStock}
  //     />
  //     )
  //   }
  // }

  //When the page load, it focuses on the Sizes input and the user is forced to scroll back up to product name
  //This puts the focus on product name input
  useEffect(() =>{
    if(inputProdName.current){
      inputProdName.current.focus();
    }
  },[])
  
  return (
    <>
      <ReactTooltip />
      {showExample === true ?
        <VariantExamplePop setShowExample={setShowExample} />
        : null}
      <div className="form-holder">
        <div style={{ minWidth: "90%" }} className="product-steps">
          <div className="block-title-2">
            <h4 className="page-title">Product&nbsp;Information</h4>
          </div>
          <div className="microcopy-signup">
            1. <span className="highlight">Product Name</span> - A good name
            attracts customers.
            <br />‍<br />
            2. <span className="highlight">Product Description</span> - Craft a
            compelling product description that tells shoppers about the item
            and how it was created.
          </div>
          <div className="product-builder" />
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                onSubmit={props.onDisplayPackageInfo}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="update-form"
              >
                {props.productNameError? <div class="error-message">{props.productNameError}</div> : null}
                <input
                  value={props.productName}
                  onChange={props.handleChange}
                  type="text"
                  //className="input-text w-input"
                  className={props.productNameError? "input-text w-input input-glow input-error" : "input-text w-input input-glow"}
                  minLength={1}
                  maxLength={256}
                  name="productName"
                  data-name="productName"
                  placeholder="Enter Product Name"
                  id="productName"
                  ref={inputProdName}
                />
                {props.productDescriptionError? <div class="error-message">{props.productDescriptionError}</div> : null}
                <div className="forms w-form mc-quill-holder">
                  <MyQuillEditor
                    text={props.text}
                    onChange={props.handleEditorChange}
                  />
                </div>

                <div className="mc-input-title-holder">
                  <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                    Price: <span style={{ color: "#6740aa" }}>{priceFormater(props.productPrice, props.currency)}</span>
                  </div>
                  {props.priceError? <div style={{ color: "red" }}>{props.priceError}</div> : null}
                  <input
                    style={{ minWidth: "100%" }}
                    value={!props.productPrice?"":props.productPrice}
                    onChange={props.handleChange}
                    type="number"
                    className={props.priceError? "input-text w-input input-glow input-error" : "input-text w-input input-glow span-space"}   
                    //min={0.01}
                    //step={0.01}
                    name="productPrice"
                    data-name="productPrice"
                    placeholder="Enter Price (No Commas Allowed)"
                    id="productPrice"
                    //required
                  /></div>
                <div className="mc-input-title-holder">
                  <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                    Stock <span style={{ color: "#6740aa" }}>{props.productStock}</span>
                  </div>
                  {props.productStockError? <div style={{ color: "red" }}>{props.productStockError}</div> : null}
                  <input
                    style={{ minWidth: "100%" }}
                    value={!props.productStock?"":props.productStock}
                    onChange={props.handleChange}
                    type="number"
                    className={props.productStockError ? "input-text w-input input-glow input-error" : "input-text w-input input-glow span-space"}
                    //min={1}
                    //step={1}
                    name="productStock"
                    data-name="productStock"
                    placeholder="Product Stock"
                    id="productStock"
                    //required
                  /></div>

                <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                  <span className="highlight">Categories and Catalogs</span>
                </div>
                {props.productCategoryError? <div class="error-message">{props.productCategoryError}</div> : null}
                <StatefulMultiSelect
                  className={props.productCategoryError ? "input-text w-select input-glow input-error" : "input-text w-select input-glow"}

                  options={props.options}
                  selected={props.selected}
                  onSelectedChanged={(selected) =>
                    props.selectCategory({ selected })
                  }
                  overrideStrings={{
                    selectSomeItems: "Select Categories (MAX 2)",
                    allItemsAreSelected: "All Items are Selected",
                    selectAll: "",
                    search: "Search",
                  }}

                />
                <StatefulMultiSelect
                  className="input-text w-select"
                  options={props.storeCatalogs}
                  selected={props.selectedCatalogs}
                  onSelectedChanged={(selected) => {
                    props.selectCatalog({ selected })
                  }}
                  overrideStrings={{
                    selectSomeItems: "Select Catalogs",
                    allItemsAreSelected: "All Items are Selected",
                    selectAll: "",
                    search: "Search",
                  }}
                />
                <div className="mc-section-variants">
                  {/* <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                    <span className="highlight">Colors and Sizes</span>
                  </div> */}
                  <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                    Colors
                  </div>
                  {props.viewOtherColorInput === true ? (
                    <>
                      <div className="holder-field select-color-holder new-prod">
                      <InputTags
                        tags={props.colorTags}
                        // suggestions={props.state.sizeSuggestions}
                        handleDelete={props.handleColorDelete}
                        handleAddition={props.handleColorAddition}
                        handleDrag={props.handleColorDrag}
                        inputFieldClass={props.coloredInputField.includes("other-colors") ? "input-text w-select mc-input-highlight blink_me" : "input-text w-select"}
                        placeholder="Other Colors e.g: Burgundy, Beige, etc.. "
                        customId="colors-tag"
                        clickAdd={()=>props.clickAdd("colors-tag", props.handleColorAddition)}
                      />
                      </div>
                    </>
                  ) : null}

                  <StatefulMultiSelect
                    className="input-text w-select"
                    options={props.colorOptions}
                    selected={props.selectedColors}
                    onSelectedChanged={(selectedColors) =>
                      props.selectColor({ selectedColors })
                    }
                    overrideStrings={{
                      selectSomeItems: "Select all available colors",
                      allItemsAreSelected: "All Items are Selected",
                      selectAll: "",
                      search: "Search",
                    }}
                  />
                    <div style={{ minWidth: '100%', paddingTop: "10px", paddingBottom: "5px" }} className="microcopy-signup">
                      Sizes
                    </div>
                    <div
                      style={{ minWidth: "100%" }}
                      className="holder-field select-size-holder"
                    >
                    <InputTags
                      tags={props.tags}
                      // suggestions={props.state.sizeSuggestions}
                      handleDelete={props.handleDelete}
                      handleAddition={props.handleAddition}
                      handleDrag={props.handleDrag}
                      inputFieldClass="input-text w-select input-glow"
                      placeholder="Sizes e.g: Small, Medium, 10, 12.5 etc..."
                      customClass="mc-input-with-btn"
                      customId="sizes-tag"
                      clickAdd={()=>props.clickAdd("sizes-tag", props.handleAddition)}
                    />
                  </div>
                  <>
                    {props.extraVariants.map((variant, i) => (
                      <ExtraVariant
                        variant={variant}
                        handleExtraVariantNameChange={props.handleExtraVariantNameChange}
                        handleExtraVariantAddition={props.handleExtraVariantAddition}
                        handleExtraVariantDelete={props.handleExtraVariantDelete}
                        extraVariantsTags={props.extraVariantsTags}
                        onChange={props.handleChange}
                        index={i}
                        invalidExtraVariants={props.invalidExtraVariants}
                        removeExtraVariant={props.removeExtraVariant}
                        customId="extra-variant-tag-"
                        clickAdd={props.clickAddVariant}
                        key={i}
                      />

                    ))}
                    {props.extraVariants.length < 3 ? <div style={{ minWidth: '100%', paddingTop: "10px", display: "flex" }}>
                      <span onClick={() => props.addExtraVariants()} className="mc-variant-button">
                        Add another option</span> <a onClick={() => setShowExample(true)} style={{ marginLeft: "auto", paddingTop: "5px", fontSize: "14px", cursor: "pointer", color: "#6740aa" }}>View Example</a>
                    </div> : null}
                  </>
                  <div style={{ minWidth: '100%', paddingTop: "20px", paddingBottom: "10px" }} className="microcopy-signup">
                    <span className="highlight">Variant Prices (optional)</span>
                    {props.editCombinationPrices === true ? <span onClick={() => props.onEditComboPrices(false)} style={{ cursor: "pointer", marginLeft: "20px" }} className="mc-variant-button">
                      Hide</span> :
                      <span onClick={() => props.onEditComboPrices(true)} style={{ cursor: "pointer", marginLeft: "20px" }} className="mc-variant-button">
                        Show</span>}
                  </div>
                  <div style={{ paddingTop: "20px", maxHeight: "80vh", overflow: "scroll" }} className="mc-section-variants">

                    {props.editCombinationPrices === true ? <>
                      <div style={{ minWidth: "fit-content", display: "block", columns: variantHeaderNumber + 2, paddingBottom: "5px" }} className="mc-variant-headers">
                        {colorVariantsExist ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Color</div> : null}
                        {sizeVariantsExist ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Size</div> : null}
                        {/* {props.extraVariants.map((variant, i) => (
                          variant.name && props.extraVariantsTags[i]?.length > 0 ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header">{variant.name}</div> : null
                        ))} */}
                        {variantOneExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{props.extraVariants[0].name}</div> : null}
                        {variantTwoExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{props.extraVariants[1].name}</div> : null}
                        {variantThreeExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">{props.extraVariants[2].name}</div> : null}
                        {variantExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Price</div> : null}
                        {variantExists ? <div style={{ minWidth: "100px", paddingLeft: "5px" }} className="mc-header microcopy-signup">Quantity</div> : null}

                      </div>
                      {variantForms} </> : null}


                  </div>
                  <div style={{ width: "100%", minWidth: "100%", display: "flex" }} className="mc-variance-nav">
                    {startIndex - 100 >= 0 && props.editCombinationPrices === true ?
                      <button style={{ marginRight: "auto" }} type="button" onClick={() => prevtVariantPage()}>Previous</button> : null
                    }
                    {combinationLength - startIndex > 100 && props.editCombinationPrices === true ?
                      <button style={{ marginLeft: "auto" }} type="button" onClick={() => nextVariantPage()}>Next</button> : null
                    }

                  </div>
                </div>
                <input
                  // onClick={() => props.onDisplayPackageInfo()}
                  type="submit"
                  value="Next"
                  defaultValue="Next"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
                <div
                onClick={() => props.onHideProductInfo()}
                style={{ marginTop: "10px", cursor: "pointer" }}
                className="btn-secondary"
                >
                <div className="text-btn-secondary">Go Back</div>
              </div>
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default ProductInfoForm;