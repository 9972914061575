// Set your secret key. Remember to switch to your live secret key in production!
// See your keys here: https://dashboard.stripe.com/account/apikeys
import firebase from "../../firebaseConfig";

const stripe = require("stripe")("sk_test_tzunOvPyk3dvQTWvUPquMWKJ00lXGq5M7f");
const redirectURL = "http://localhost:5000/seller/dashboard/settings";
const db = firebase.firestore();

const stateMatches = (state_parameter) => {
  // Load the same state value that you randomly generated for your OAuth link.
  const saved_state = "123456";

  return saved_state == state_parameter;
};

const saveAccountId = (id, sellerId) => {
  // Save the connected account ID from the response to your database.
  console.log("Connected account ID: " + id);
  return db
    .collection("buyersAccounts")
    .doc(sellerId)
    .collection("stripe")
    .doc(sellerId)
    .set({ stripe_user_id: id });
};

const connectStripe = (code, state, sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      // Assert the state matches the state you provided in the OAuth link (optional).
      if (!stateMatches(state)) {
        console.log("error: Incorrect state parameter" + state);
        reject({ error: "Incorrect state parameter: " + state });
      }
      console.log("in stripe connect", stripe.oauth);
      console.log("code", code);
      console.log("state", state);
      const response = await stripe.oauth.token({
        grant_type: "authorization_code",
        code: "ac_HIzEXJqCdRoEbD6LygTIzPDRLVUUmwDQ",
      });

      console.log("response", Promise.resolve(response));

      var connected_account_id = response.stripe_user_id;
      console.log("id", connected_account_id);

      // Send the authorization code to Stripe's API.
      //   stripe.oauth
      //     .token({
      //       grant_type: "authorization_code",
      //       code,
      //     })
      //     .then(
      //       async (response) => {
      //         console.log("res", response);
      //         var connected_account_id = response.stripe_user_id;
      //         console.log("id", connected_account_id);
      //         await saveAccountId(connected_account_id, sellerId);

      //         // Render some HTML or redirect to a different page.
      //         //   return res.status(200).json({success: true});
      //         resolve("success");
      //       },
      //       (err) => {
      //         console.log("stripe oauth error", err);
      //         if (err.type === "StripeInvalidGrantError") {
      //           //   return res.status(400).json({error: 'Invalid authorization code: ' + code});
      //           reject({ error: "Invalid authorization code: " + code });
      //         } else {
      //           //   return res.status(500).json({error: 'An unknown error occurred.'});
      //           reject({ error: "An unknown error occurred." });
      //         }
      //       }
      //     );
    } catch (error) {
      console.log("error connecting stripe", error);
      reject(error);
    }
  });
};
export default connectStripe;
