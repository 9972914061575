import React, { Component }  from "react";

class ShippingInfo extends Component {
  state = {
  };

  openLink = () => {
    let { shippingLink, labelObject } = this.props;

    if (labelObject?.content) {
      console.log("labelObject", labelObject);
      var byteCharacters = atob(labelObject?.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return;
    }
    window.open(shippingLink, "Cashaam");
  };

  render() {
    return (
      <div style={{ display: "flex" }} className="tint-background">
        <div className="pop-container calendar-pop">
          <div
            style={{ display: "flex", overflow: "scroll", maxHeight: "90vh" }}
            className="pop-us-ship-label"
          >
            <div className="pop-header">
              <div className="text-main">Shipping Instructions</div>
            </div>
            <div className="pop-content">
              <div className="sub-title">
                Download &amp; Print Shipping Labels
              </div>
              <div className="pop-text bold">
                Before you print your shipping labels here’s a few things to
                note.
              </div>
              <div className="pop-text">
                1. Contact DHL to inform that they have a pickup :
                <br />
                <a
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    textAlign: "center",
                  }}
                  href="tel:+2348039077000"
                >
                  +234 803 907 7000
                </a>
                <br />
                2. Prepare their package.
                <br />
                3. Make sure someone is around when DHL arrive at the designated
                time.
                <br />
              </div>
            </div>

            <div className="pop-footer">
              <div
                onClick={this.openLink}
                style={{ cursor: "pointer" }}
                className="btn-primary"
              >
                <div className="text-btn-primary">View Label</div>
              </div>
            </div>

            <div className="pop-footer">
              <div
                onClick={()=> this.props.hideShippingInstructions()}
                style={{ cursor: "pointer" }}
                className="btn-secondary"
              >
                <div className="text-btn-tertiary">Done</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingInfo;
