import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "../../../../firebaseConfig/index";
import logo from "../../../../images/cashaam-logo.png";
import logoWhite from "../../../../images/cashaam-logo-white.png";

import Autocomplete from "react-autocomplete";
import BankData from "../../../../secrets/NigerianBank.json";
import {BVN_API, VERIFY_ACCOUNT, PAYSTACK_SECRET_KEY} from "../../../../secrets/index.js";

class SecondaryAccount extends Component {
  state = {
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    swiftCode: "",
    bankLocation: "",


    bankNameError:"",
    accountNameError:"",
    accountNumberError:"",

    loading:false,
  };


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  selectBank = (value) => {
    this.setState({ bankName: value });
  };

  handleSubmit= async(e)=>{
    try{
        e.preventDefault();

        this.setState({ 
            bankNameError:"",
            accountNameError:"",
            accountNumberError:"",
            swiftCodeError:"",
            bankLocationError:"",
            paymentProcessorError:"",
            zelleNumberError:"",
            paypalUsernameError:"",
            paypalEmailError:"",
            paypalPhoneError:"",
            otherBankNameError:"",
            loading: true,
            verificationError:""
        })
        
        let{ 
            bankName,
            otherBankName,
            accountHolderName,
            accountNumber,
            swiftCode,
            bankLocation,
            paymentProcessor,
            zelleNumber,
            paypalUsername,
            paypalEmail,
            paypalPhone
        }=this.state;

        if(!bankName){
            this.setState({
                bankNameError:"Please Enter Your Bank",
                loading:false
            })
            return;
        }

        if(bankName=="Other" && !otherBankName){
            this.setState({
                otherBankNameError:"Please enter the name of your bank",
                loading:false
            })
            return;
        }

        if(!accountHolderName || accountHolderName?.length<3){
            this.setState({
                accountNameError:"Please enter the name of the account holder",
                loading:false
            })
            return;
        }

        if(!accountNumber || accountNumber?.length<10){
            this.setState({
                accountNumberError:"Please enter your account number",
                loading:false
            })
            return;
        }

        if(!swiftCode){
            this.setState({
                swiftCodeError:"Please enter your swift code",
                loading:false
            })
            return;
        }

        if(!bankLocation){
            this.setState({
                bankLocationError:"Please enter your bank's location",
                loading:false
            })
            return;
        }

        if(bankLocation=="United States"){

            if(!paymentProcessor){
                this.setState({
                    paymentProcessorError:"Please enter your payment processor",
                    loading:false
                })
                return;
            }else{

                if(paymentProcessor=="Zelle" && !zelleNumber){
                    this.setState({
                        zelleNumberError:"Please enter your zelle phone number",
                        loading:false
                    })
                    return;
                }

                if(paymentProcessor=="Paypal"){

                    if(!paypalUsername){
                        this.setState({
                            paypalUsernameError:"Please enter your paypal username",
                            loading:false
                        })
                        return;
                    }else if(!paypalEmail){
                        this.setState({
                            paypalEmailError:"Please enter your paypal email",
                            loading:false
                        })
                        return;
                    }else if(!paypalPhone){
                        this.setState({
                            paypalPhoneError:"Please enter your paypal phone number",
                            loading:false
                        })
                        return;
                    }
                    
                }

            }
            
        }

        if(bankLocation=="Nigeria"){
        
            const { banks } = this.state;
            const bankCode = banks[bankName];
            let validatedAccountName=await this.verifyBankAccount(accountNumber,bankCode );
            console.log("validatedAccountName",validatedAccountName);

            console.log("accountName",validatedAccountName)
            if(validatedAccountName){
            this.setState({ 
                validateAccount: validatedAccountName,
                accountHolderName: validatedAccountName,
                loading: false
            })
        
            }else{
            this.setState({ 
                verificationError: true,
                loading: false
            })
            }
        }else{
            await this.saveSecondaryAccount();
        }
        

    }catch(e){
        console.log("error verifying secondary account",e);
        this.setState({ 
            bankNameError:"",
            accountNameError:"",
            accountNumberError:"",
            swiftCodeError:"",
            bankLocationError:"",
            paymentProcessorError:"",
            zelleNumberError:"",
            paypalUsernameError:"",
            paypalEmailError:"",
            paypalPhoneError:"",
            otherBankNameError:"",
            loading: false,
            verificationError:""
        })
    }

  }

  verifyBankAccount= async(accountNumber,bankCode)=>{

    try{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${PAYSTACK_SECRET_KEY}`);


      const res = await fetch(`${VERIFY_ACCOUNT}=${accountNumber}&bank_code=${bankCode}`, {
        method: "GET",
        headers: myHeaders,
      });

      let accountInfo=await res.json();
      console.log("verifyBankAccount",accountInfo);
      if(res.status==200 && accountInfo.status==true) {
        let{data} = accountInfo;
        let accountName = data?.account_name;
        
        return accountName;

      }else{
        return null
      }

    }catch (error) {
      console.log("error verifyBankAccount:", error);
      return null
    }

  }

  clearValidation=()=>{
    this.setState({
        validateAccount:"",
    })
  }

  saveSecondaryAccount= async()=>{

    try{
        let{ 
            bankName,
            otherBankName,
            accountHolderName,
            accountNumber,
            swiftCode,
            bankLocation,
            paymentProcessor,
            zelleNumber,
            paypalUsername,
            paypalEmail,
            paypalPhone
        }=this.state;

        if(bankName=="Other"){
            bankName=otherBankName;
        }

        if(bankLocation=="Nigeria"){
            paymentProcessor="N/A"
            zelleNumber="N/A"
            paypalUsername="N/A"
            paypalEmail="N/A"
            paypalPhone="N/A"
        }else{

            if(paymentProcessor=="Zelle"){
                paypalUsername="N/A"
                paypalEmail="N/A"
                paypalPhone="N/A"
            }
            if(paymentProcessor=="Paypal"){
                zelleNumber="N/A"
            }
        }


        let secondaryAccount={
            bankName,
            accountHolderName,
            accountNumber,
            swiftCode,
            bankLocation,
            paymentProcessor,
            zelleNumber,
            paypalUsername,
            paypalEmail,
            paypalPhone
        }

        console.log("secondaryAccount",secondaryAccount);
        let sellerId=this.props.sellerId;
        const db = firebase.firestore();

        await db.collection("sellersAccounts").doc(sellerId).collection("bank").doc(sellerId).set({
            secondaryAccount
        },
        { merge: true }
        )

        this.props.cancel();

    }catch(e){
        console.log("error verifying secondary account",e)
    }


  }

  componentDidMount() {
    try {
      //retrieve all bank names from json file
      let bankNames = Object.keys(BankData);
      let banks = {};
      bankNames.forEach((bankName) => {
        banks[bankName] = BankData[bankName].code;
      });

      bankNames.push("Other");
      this.setState({
        bankNames: bankNames,
        banks: banks,
      });
    } catch (err) {}
  }
  render() {
    return (
      <>
        <div className="section-onboarding secondary-account-top-con">
          <div className="onboarding-steps secondary-account-container">
            <div style={{marginBottom:"10px"}} className="block-title-1">
              <h4 className="h5">Let’s Setup a Secondary Bank Account for Payment.</h4>
            </div>
            <div className="microcopy-signup">
              When a customer buys your product, where do you want your money to
              be deposited?
              <br />
              <br />

            </div>

            <div style={{position: "relative"}}>
              <div className="forms w-form">

              {this.state.verificationError?
                <div
                style={{
                    borderColor: "#ff8c00",
                    borderWidth: "2px",
                    fontSize: "14px",
                }}
                className="tracking-box"
                >
                <div>
                    <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Error verifying your bank account
                    <br />
                    </div>
                    <div style={{textAlign: "center"}}>
                    Please use different account number or {" "}
                    <a target="_blank" href="https://support.cashaam.com">
                            contact us
                            </a>{" "}
                            if this issue persist
                    </div>
                </div>
                </div>
            : null}


              {this.state.validateAccount?
                <>
                    <div className="account-user">
                        {this.state.validateAccount}
                    </div>
                        
                    <div className="validate-user">
                        <div>
                        Is this your account?
                        </div>
                        <button 
                        onClick={()=>this.saveSecondaryAccount()} 
                        className="yes-btn">Yes</button>
                        <button 
                        onClick={()=>this.clearValidation()} 
                        className="no-btn">No</button>
                    </div>
                    </>
                :

                <form
                    onSubmit={this.handleSubmit}
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                >


                  {this.state.bankNameError ? (
                    <div className="error-message">{this.state.bankNameError}</div>
                  ) : <label>Bank (Select 'Other' if it's not in the list)</label>}
                  <Autocomplete
                    value={this.state.bankName}
                    inputProps={{
                      type: "text",
                      className: `${this.state.bankNameError? "input-text w-select input-glow input-error": "input-text w-select"}`,
                      maxLength: 256,
                      name: "bankName",
                      placeholder: "Enter your bank (example: Sterling Bank)",
                      id: "bankName",
                    }}
                    wrapperStyle={{}} //keep this empty in order to avoid default styling from messing with the input's style inherited from css
                    items={this.state.bankNames}
                    getItemValue={(item) => item}
                    shouldItemRender={(item, value) =>
                      item.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    onChange={this.handleChange}
                    onSelect={(value) => this.selectBank(value)}
                    menuStyle={{
                      borderRadius: "3px",
                      boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                      background: "rgba(255, 255, 255, 0.9)",
                      padding: "2px 0",
                      fontSize: "90%",
                      position: "fixed",
                      overflow: "auto",
                      maxHeight: "10%",
                      zIndex: 1,
                    }}
                    renderItem={(item, isHighlighted, i) => (
                      <div
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                        key={item}
                      >
                        {item}
                      </div>
                    )}
                  />                  
                  
                  {this.state.bankName=="Other"?   
                    <input
                        onChange={this.handleChange}
                        value={this.state.otherBankName}
                        type="text"
                        className={
                        this.state.otherBankNameError
                            ? "input-text w-input input-glow input-error"
                            : "input-text input-glow w-input"
                        }
                        maxLength={256}
                        name="otherBankName"
                        data-name="otherBankName"
                        placeholder="Enter the name of your bank"
                        id="otherBankName"
                    />
                  :null}


                  {this.state.accountNameError ? (
                    <div className="error-message">
                      {this.state.accountNameError}
                    </div>
                  ) : <label>Account Name</label>}
                  <input
                    onChange={this.handleChange}
                    value={this.state.accountHolderName}
                    type="text"
                    className={
                      this.state.accountNameError
                        ? "input-text w-input input-glow input-error"
                        : "input-text input-glow w-input"
                    }
                    maxLength={256}
                    name="accountHolderName"
                    data-name="accountHolderName"
                    placeholder="Enter the first and last name on the account"
                    id="accountHolderName"
                    //required
                  />

                  {this.state.accountNumberError ? (
                    <div className="error-message">
                      {this.state.accountNumberError}
                    </div>
                  ) : <label>Account Number</label>}
                  <input
                    onChange={this.handleChange}
                    value={this.state.accountNumber}
                    type="text"
                    className={
                      this.state.accountNumberError
                        ? "input-text w-input input-glow input-error"
                        : "input-text input-glow w-input"
                    }
                    maxLength={256}
                    name="accountNumber"
                    data-name="accountNumber"
                    placeholder="Enter your 10-digit account number"
                    id="accountNumber"
                    //required
                  />


                    <label>
                    {`Swift Code (Use the link for find your bank's `}
                    <a href="https://bank.codes/swift-code/nigeria/" target="_blank" rel="noopener noreferrer">Swift Code</a>
                    {')'}
                    </label>
                    {this.state.swiftCodeError ? (
                        <div className="error-message">
                        {this.state.swiftCodeError}
                        </div>
                    ) : 
                    null
                    }
                  <input
                    onChange={this.handleChange}
                    value={this.state.swiftCode}
                    type="text"
                    className={
                      this.state.swiftCodeError
                        ? "input-text w-input input-glow input-error"
                        : "input-text input-glow w-input"
                    }
                    maxLength={256}
                    name="swiftCode"
                    data-name="swiftCode"
                    placeholder="Enter Your Bank's Swift Code"
                    id="swiftCode"
                  />

                    {this.state.bankLocationError ? (
                        <div className="error-message">
                        {this.state.bankLocationError}
                        </div>
                    ) : <label>Bank Location</label>}

                    <select
                        id="banks-2"
                        name="bankLocation"
                        data-name="bankLocation"
                        required
                        className={this.state.bankLocationError?"input-text w-select input-glow input-error": "input-text w-select"}
                        onChange={this.handleChange}
                    >
                        <option disabled selected value>Select your bank's location</option>
                        <option value="United States">United States</option>
                        <option value="Nigeria">Nigeria</option>
                    </select>

                    
                    {this.state.bankLocation=="United States"?
                    <>
                        {this.state.paymentProcessorError? (
                            <div className="error-message">
                            {this.state.paymentProcessorError}
                            </div>
                        ) : <label>Payment Processor</label>}
                        
                        <select
                            id="banks-2"
                            name="paymentProcessor"
                            data-name="paymentProcessor"
                            required
                            className={this.state.paymentProcessorError?"input-text w-select input-glow input-error": "input-text w-select"}
                            onChange={this.handleChange}
                        >
                            <option disabled selected value>Select your payment processor</option>
                            <option value="Zelle">Zelle</option>
                            <option value="Paypal">Paypal</option>
                        </select>

                        {this.state.paymentProcessor=="Zelle"?
                            <>
                            <label>Zelle Phone Number</label>
                            <input
                            onChange={this.handleChange}
                            value={this.state.zelleNumber}
                            type="text"
                            className={
                                this.state.zelleNumberError
                                ? "input-text w-input input-glow input-error"
                                : "input-text input-glow w-input"
                            }
                            maxLength={256}
                            name="zelleNumber"
                            data-name="zelleNumber"
                            placeholder="Enter Your Zelle PHone Number"
                            id="zelleNumber"
                            />

                            </>
                            :null
                        }

                        {this.state.paymentProcessor=="Paypal"?
                            <>
                            <label>Paypal Username</label>
                            <input
                            onChange={this.handleChange}
                            value={this.state.paypalUsername}
                            type="text"
                            className={
                                this.state.paypalUsernameError
                                ? "input-text w-input input-glow input-error"
                                : "input-text input-glow w-input"
                            }
                            maxLength={256}
                            name="paypalUsername"
                            data-name="paypalUsername"
                            placeholder="Enter Your Paypal Username"
                            id="paypalUsername"
                            />

                            <label>Paypal Email</label>
                            <input
                            onChange={this.handleChange}
                            value={this.state.paypalEmail}
                            type="text"
                            className={
                                this.state.paypalEmailError
                                ? "input-text w-input input-glow input-error"
                                : "input-text input-glow w-input"
                            }
                            maxLength={256}
                            name="paypalEmail"
                            data-name="paypalEmail"
                            placeholder="Enter Your Paypal Email"
                            id="paypalEmail"
                            />

                            <label>Paypal Phone Number</label>
                            <input
                            onChange={this.handleChange}
                            value={this.state.paypalPhone}
                            type="text"
                            className={
                                this.state.paypalPhoneError
                                ? "input-text w-input input-glow input-error"
                                : "input-text input-glow w-input"
                            }
                            maxLength={256}
                            name="paypalPhone"
                            data-name="paypalPhone"
                            placeholder="Enter Your Paypal Phone Number"
                            id="paypalPhone"
                            />

                            </>
                            :null
                        }

                    </>
                    :null}


                  {this.state.loading === false ? (
                    <input
                      type="submit"
                      defaultValue="Submit"
                      value="Submit"
                      data-wait="Please wait..."
                      className="submit-primary w-button"
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className="submit-button w-button"
                    >
                      <div
                        style={{ borderTopColor: "#6740aa" }}
                        className="loader"
                      ></div>
                    </div>
                  )}
                </form>
                }

                {/* <div
                  onClick={() => props.backToSettings()}
                  style={{ cursor: "pointer" }}
                  className="microcopy-signup go-back"
                >
                  Cancel
                </div> */}
                <div />

                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/" className="logo-black w-inline-block">
          <img
            src={logo}
            // srcSet="images/cashaam-logo-p-500.png 500w, images/cashaam-logo.png 560w"
            sizes="(max-width: 767px) 80px, 100px"
            alt="Cashaam Logo"
            className="cashaam-logo-dark"
          />
          <img
            src={logoWhite}
            // srcSet="images/cashaam-logo-white-p-500.png 500w, images/cashaam-logo-white.png 560w"
            sizes="100vw"
            alt="Cashaam Logo"
            className="cashaam-logo-white"
          />
        </Link>
      </>
    );
  }
}

export default SecondaryAccount;
