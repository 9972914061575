import React, { Component } from 'react'
import StatefulMultiSelect from "@khanacademy/react-multi-select";
import InputTags from "../../../common/InputTags";
import styles from './AddCatalog.module.css'

export class AddCatalogForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Add New Collection</h4>

                </div>

                <div className="main">
                    <div className="form-holder">
                        <div style={{ minWidth: "60%" }} className="product-steps">
                            <div className="block-title-2">
                                <h4 className="page-title">Collections</h4>
                            </div>

                            <div className="product-builder" />
                            <div className="holding-onboarding">
                                <div className="forms w-form">
                                    <form
                                        id="email-form"
                                        name="email-form"
                                        data-name="Email Form"
                                        className="update-form"
                                        onSubmit={this.props.handleSubmit}
                                    >
                                        <input
                                            value={this.props.catalog}
                                            onChange={this.props.handleChange}
                                            type="text"
                                            className="input-text w-input"
                                            maxLength={256}
                                            name="catalog"
                                            placeholder="Enter Catalog"
                                            required
                                        />

                                        <button
                                            // onClick={this.props.handleSubmit}
                                            type="submit"
                                            value="Next"
                                            defaultValue="AD"
                                            data-wait="Please wait..."
                                            className="submit-primary w-button"
                                        >
                                            Add
                                            </button>
                                    </form>

                                    <div>
                                        {
                                            this.props.catalogs.map((catalog, index) => {
                                                return (
                                                    <div className={styles.catalogs} key={index}>
                                                        <p style={{ margin: 0, fontSize: 14 }}>{catalog}</p>
                                                        <button
                                                            className={styles.deleteBtn}
                                                            onClick={() => this.props.handleDelete(catalog)}>
                                                            Delete
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default AddCatalogForm