import React, { useRef, useEffect } from "react";
import priceFormater from "../../../helpers/priceFormater";
import getDateFromTimestamp from "../../../helpers/getDateFromTimestamp";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, displayDetails) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        displayDetails(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const OrderDetailsPop = (props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.displayDetails);
  let date = getDateFromTimestamp(props.orderTimestamp);

  return (
    <div style={{ display: "flex" }} className="tint-background">
      <div className="pop-container">
        <div
          ref={wrapperRef}
          style={{ display: "flex", minWidth: "fit-content" }}
          className="pop-details"
        >
          {/* <div className="details-header">
            <div className="d-title">
              <div className="text-main">Items Ordered</div>
            </div>
            <div className="d-status">
              <a href="#" className="box-btn-primary w-inline-block">
                <div className="btn-text-primary-outline processed">
                  Unprocessed
                </div>
              </a>
            </div>
          </div> */}
          <div className="details-content">
            <div className="d-content-header">
              <div className="d-date">
                <div className="box-title details">order date</div>
                <div className="box-value details">{date}</div>
                <div className="d-orderid">
                  <div>
                    <strong>OrderID</strong>: {props.order.orderId}
                  </div>
                </div>
              </div>
              <div>
                {props.order.country ? (
                  props.order.country.toLowerCase() !== "nigeria" ? (
                    <a
                      style={{ cursor: "pointer", padding: "10px" }}
                      onClick={() =>
                        props.displayShippingLabel(
                          props.order.sellerId,
                          props.order.transactionId,
                          props.order
                        )
                      }
                      className="box-btn-primary w-inline-block"
                    >
                      <div className="box-btn-text-primary">Print Label</div>
                    </a>
                  ) : props.order.trackingNumber ? (
                    <a className="box-btn-primary w-inline-block">
                      <div className="box-btn-text-primary">
                        Tracking #: {props.order.trackingNumber}
                      </div>
                    </a>
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="details-product">
              <div className="d-prduct-info">
                <div style={{ display: "flex", maxWidth: "40%" }} className="d-img">
                  <img src={props.order.featuredImage} width={103} alt />
                </div>
                <div className="d-info">
                  <div className="d-product-title">
                    {props.order.productName}
                  </div>
                  <div className="d-product-meta">
                    {props.order.selectedSizes.toString() !== "N/A" ? (
                      <div>Size - {props.order.selectedSizes}</div>
                    ) : null}
                    {props.order.selectedColors.toString() !== "N/A" ? (
                      <div>Color - {props.order.selectedColors}</div>
                    ) : null}
                    {props.order.selectedExtraVariants?.map(variant => (
                      <div>{variant.name} - {variant.value}</div>
                    ))}
                    Quantity -{" "}
                    {props.order.orderQuantity !== "N/A"
                      ? props.order.orderQuantity
                      : null}
                  </div>
                  <div className="d-product-price">
                    {priceFormater(
                      parseFloat(props.order.orderQuantity) *
                      parseFloat(
                        props.order.discountedPrice
                          ? props.order.discountedPrice
                          : props.order.productPrice
                      ),
                      props.order.currency
                    )}
                  </div>
                </div>
              </div>
              <div style={{ minWidth: "200px" }} className="d-product-recipient">
                <div className="box-title details">Recipient Name</div>
                <div className="d-product-meta name">
                  {props.order.customerName}
                </div>
                <div className="box-title details">Recipient Phone Number</div>
                <div className="d-product-meta name">
                  {props.order.deliveryPhoneNumber}
                </div>
                <div className="box-title details">Recipient Email</div>
                <div className="d-product-meta name">
                  {props.order.email}
                </div>
                <div className="box-title details">Recipient Address</div>
                <div className="d-product-meta">
                  {props.order.orderDeliveryAddress},{props.order.city},
                  {props.order.state},{props.order.country},
                  {props.order.zipcode ? props.order.zipcode : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderDetailsPop;
