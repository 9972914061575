import React, { useState, useEffect } from 'react'

import { ToastContainer, toast } from "react-toastify";

import firebase from "../../../../../firebaseConfig";
import getUserLocation from "../../../../helpers/getUserLocation";
import AddAvailability from './AddAvailability';
// import { getServices } from '../../../helpers/service';
import Layout from '../Layout'

import { timeMap, reverseTimeMap } from '../../../../../constants'
import { getAvailability, saveAvailability } from '../../../../helpers/availability';
import AvailabilityDisplay from './AvailabilityDisplay';
import Loading from '../../../../common/Loading';

const Availability = () => {
    const [pageInfo, setPageInfo] = useState({
        userId: "",
        currentCountry: "",
        firstName: ""
    })
    const [availability, setAvailability] = useState([])
    const [isAddingAvailability, setIsAddingAvailability] = useState(false)
    const [isEditingAvailability, setIsEditingAvailability] = useState(false)
    const [availabilityFormData, setAvailabilityFormData] = useState([
        {
            day: "Sunday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                },
            ],
        },
        {
            day: "Monday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        },
        {
            day: "Tuesday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        },
        {
            day: "Wednesday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        },
        {
            day: "Thursday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        },
        {
            day: "Friday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        },
        {
            day: "Saturday",
            isOpen: false,
            hours: [
                {
                    openTime: "",
                    closeTime: ""
                }
            ],
        }
    ])

    /**
     * Function fetches user info (userId, country, name) from data base when component is mounted
     */
    const fetchData = async () => {
        firebase.auth().onAuthStateChanged(async (user) => {
            if (user) {
                const locationData = await getUserLocation();
                const country = locationData.country_name === "India"
                    ? "Nigeria"
                    : locationData.country_name;

                // const country = await getLocation()
                setPageInfo({
                    userId: user.uid,
                    firstName: user.displayName,
                    currentCountry: country
                })
                const data = await getAvailability(user.uid)
                setAvailability(data)
                console.log(data)

            } else {
                this.props.history.push("/");
            }
        });
    }

    useEffect(() => {
        fetchData()
    }, [])



    /**
     * Sets a day as open or close
     * @param {Number} index 
     */
    const toggleIsOpen = (index) => {
        const updateAvailability = [...availabilityFormData]
        if (updateAvailability[index].isOpen) {
            updateAvailability[index].isOpen = !updateAvailability[index].isOpen
        }
        else {
            if (updateAvailability[index].hours.length === 0) {
                updateAvailability[index].hours = [{
                    openTime: "",
                    closeTime: ""
                }]
            }
            updateAvailability[index].isOpen = !updateAvailability[index].isOpen
        }
        setAvailabilityFormData(updateAvailability)
    }

    /**
     * Adds hours to a day
     * @param {Number} index 
     */
    const addHours = (index) => {
        const updateAvailability = [...availabilityFormData]
        updateAvailability[index].hours.push({
            openInputValue: "",
            closeInputValue: ""
        })
        setAvailabilityFormData(updateAvailability)
    }

    /**
     * Deletes hours from a day
     * @param {Number} index 
     * @param {Number} hoursIndex 
     */
    const deleteHour = (index, hoursIndex) => {
        const updateAvailability = [...availabilityFormData]
        updateAvailability[index].hours.splice(hoursIndex, 1)
        setAvailabilityFormData(updateAvailability)
    }

    /**
     * 
     * @param {Event} e 
     * @param {Number} index 
     * @param {Number} hoursIndex 
     * @param {String} target 
     */
    const handleAvailabilityChange = (e, index, hoursIndex, target) => {
        const updateAvailability = [...availabilityFormData]
        if (e.target.value.trim() === "24 hours") {
            updateAvailability[index].hours[hoursIndex]["openTime"] = e.target.value.trim()
            updateAvailability[index].hours[hoursIndex]["closeTime"] = ""
            updateAvailability[index].open24Hours = true
        }
        else {
            updateAvailability[index].hours[hoursIndex][target] = e.target.value.toUpperCase()
        }
        setAvailabilityFormData(updateAvailability)
    }

    /**
     * Sets the value of an input field to the time options that was selected
     * @param {Number} index 
     * @param {Number} hoursIndex 
     * @param {String} target 
     * @param {Number} value 
     */
    const setTimeValueOption = (index, hoursIndex, target, value) => {
        const updateAvailability = [...availabilityFormData]
        if (value === "24 hours") {
            updateAvailability[index].open24Hours = true
            updateAvailability[index].hours[hoursIndex]["openTime"] = value
            updateAvailability[index].hours[hoursIndex]["closeTime"] = ""
        }
        else {
            updateAvailability[index].hours[hoursIndex][target] = value
        }
        setAvailabilityFormData(updateAvailability)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            Loading(true, "Saving")
            const data = availabilityFormData.map(day => {
                let hours = []
                const intervals = []
                let openTime, closeTime;
                if (day.isOpen) {
                    for (var i = 0; i < day.hours.length; i++) {
                        if (day.hours[i].openTime == "24 hours") {
                            hours = [{ open: 24, close: false }]
                            return {
                                day: day.day,
                                isOpen: day.isOpen,
                                hours
                            }
                        }
                        if (day.hours[i].openTime) {
                            openTime = day.hours[i].openTime
                            closeTime = day.hours[i].closeTime
                            if (!timeMap[openTime] && timeMap[openTime] !== 0.0) {
                                toast(`Invalid opening time on ${day.day}. Please select from the time options`)
                                throw new Error("Time error")
                            }
                            if (!timeMap[closeTime] && timeMap[closeTime] !== 0.0) {
                                toast(`Invalid closing time on ${day.day}. Please select from the time options`)
                                throw new Error("Time error")
                            }
                            if (timeMap[openTime] >= timeMap[closeTime]) {
                                toast(`Invalid hours on ${day.day}. Make sure closing time is after opening time`)
                                throw new Error("Time error")
                            }
                            // hours.push({
                            //     open: timeMap[openTime],
                            //     close: timeMap[closeTime]
                            // })
                            intervals.push([timeMap[openTime], timeMap[closeTime]])
                        }
                    }
                }
                const merged = mergeIntervals(intervals)
                console.log("MERGED",merged)
                hours = merged.map(interval => ({
                    open: interval[0],
                    close: interval[1]
                }))
                return {
                    day: day.day,
                    isOpen: day.isOpen,
                    hours
                }
            })
            await saveAvailability(pageInfo.userId, data)
            closeForms()
            fetchData()
            Loading(false)
        } catch (err) {

        }
    }

    /**
     * 
     * @param {[Array]} intervals 
     * @returns {[Array]}
     */
    const mergeIntervals = (intervals) => {
        if (intervals.length < 2) {
            return intervals
        }
        let n = 1
        intervals.sort((a, b) => a[0] - b[0])
        let merged = [intervals[0]]
        console.log("INTERVALS", intervals)
        for (var i = 1; i < intervals.length; i++) {
            if (merged[n - 1][1] < intervals[i][0]) {
                merged.push(intervals[i])
                n += 1
            } else {
                if (merged[n - 1][1] < intervals[i][1]) {
                    merged[n - 1][1] = intervals[i][1]
                }
            }
        }
        return merged
    }

    const onAddingAvailability = () => {
        setIsAddingAvailability(true)
    }

    const onUpdatingAvailability = () => {
        const formData = availability.map(day => {
            let hours = []
            if (day.isOpen) {
                for (var i = 0; i < day.hours.length; i++) {
                    if (day.hours[i].open === 24) {
                        hours = [{ openTime: "24 hours", closeTime: "" }]
                        break
                    }

                    hours.push({
                        openTime: reverseTimeMap[day.hours[i].open],
                        closeTime: reverseTimeMap[day.hours[i].close]
                    })
                }
            }
            return {
                day: day.day,
                isOpen: day.isOpen,
                hours
            }
        })
        setIsEditingAvailability(true)
        setAvailabilityFormData(formData)
    }

    const closeForms = () => {
        setIsEditingAvailability(false)
        setIsAddingAvailability(false)
    }

    return (
        <Layout
            firstName={pageInfo.firstName}
            userCurrentCountry={pageInfo.currentCountry}
        >
            <ToastContainer />
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Business Hours</h4>
                </div>
                <div className="form-holder">
                    <div style={{ minWidth: "90%" }} className="product-steps">
                        <div className="product-header">
                            <h5 className="setting-title">Availability</h5>
                            <div className="div-block" />

                            {
                                !(isEditingAvailability && !isAddingAvailability) &&
                                (!availability ?
                                    <div className="box-btn-text-primary" onClick={onAddingAvailability}>
                                        Add Availability
                                    </div>
                                    :
                                    <div className="box-btn-text-primary" onClick={onUpdatingAvailability}>
                                        Update Availability
                                    </div>)
                            }

                        </div>
                        <div className="product-builder" />
                        {
                            isAddingAvailability ?
                                <AddAvailability
                                    availability={availabilityFormData}
                                    handleAvailabilityChange={handleAvailabilityChange}
                                    setTimeValueOption={setTimeValueOption}
                                    toggleIsOpen={toggleIsOpen}
                                    addHours={addHours}
                                    deleteHour={deleteHour}
                                    handleSubmit={handleSubmit}
                                    cancel={closeForms}
                                />
                                :
                                isEditingAvailability ?
                                    <AddAvailability
                                        isEditing={isEditingAvailability}
                                        availability={availabilityFormData}
                                        handleAvailabilityChange={handleAvailabilityChange}
                                        setTimeValueOption={setTimeValueOption}
                                        toggleIsOpen={toggleIsOpen}
                                        addHours={addHours}
                                        deleteHour={deleteHour}
                                        handleSubmit={handleSubmit}
                                        cancel={closeForms}
                                    />
                                    :
                                    <AvailabilityDisplay
                                        availability={availability}
                                    />
                        }
                    </div>
                </div>{" "}
            </div>
        </Layout>

    )
}

export default Availability
