import React from "react";
import getDateFromTimestamp from "../../../helpers/getDateFromTimestamp";
import DeliveryItem from "./DeliveryItem";

class DeliveryInfoPop extends React.Component {

  state={
    shippingStreet:"",
    shippingState:"",
    shippingCity:"",
    shippingCountry:"",
    shippingZip:"",
    shippingApt:"",
    customerName:"",
    customerEmail:"",
    customerPhone:"",
    purchasedDate:""
  }

  printPage=()=>{
    window.print();

  }
  componentDidMount(){
    try{
      let currentTransaction = this.props.currentTransaction;
  
      let orderList = [];
      orderList = currentTransaction?.orderList;
      let purchasedDate = currentTransaction?.date;


      let customerName,
        customerEmail,
        shippingStreet,
        shippingState,
        shippingCity,
        shippingCountry,
        shippingZip,
        shippingApt,
        customerPhone;
      if (orderList.length > 0) {
        let order = orderList[0];
  
        shippingStreet = order?.street;
  
        if(shippingStreet){
          shippingStreet=shippingStreet.replace('N/A', '');
        }
        shippingCity = order?.city;
        shippingState = order?.state;
        shippingCountry = order?.country;
        shippingZip = order?.zip;
        shippingApt = order?.apt;
        customerName=order.customerName;
        customerEmail=order.email;
        customerPhone=order.deliveryPhoneNumber
      }
  
      this.setState({
        orderList,
        purchasedDate,
        shippingStreet,
        shippingState,
        shippingCity,
        shippingCountry,
        shippingZip,
        shippingApt,
        customerName,
        customerEmail,
        customerPhone,
      });
  
    }catch(err) {
      console.error("error loading transaction info",err);
    }


  }
  render() {
    return (
      <div style={{ display: "flex" }} className="tint-background" id="divcontents">

        <div className="pop-container">
          <div
            style={{ display: "block" }}
            className="pop-ng-pickup mc-pop-ng-pickup full-height-pop"
          >
            <div className="pop-header">
              <div className="text-main">Self Shipping</div>
              <a
                onClick={() => this.props.hideDeliveryInfo()}
                className="box-btn-primary w-inline-block"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-x-circle"
                >
                  <circle cx={12} cy={12} r={10} />
                  <line x1={15} y1={9} x2={9} y2={15} />
                  <line x1={9} y1={9} x2={15} y2={15} />
                </svg>
              </a>
            </div>

            {this.props.errorMessage ? (
              <div
                style={{
                  borderColor: "#ff8c00",
                  borderWidth: "2px",
                  fontSize: "14px",
                }}
                className="tracking-box"
              >
                <div>{this.props.errorMessage}</div>{" "}
              </div>
            ) : null}

            <div className="pop-content delivery-info-pop">
              {/* <div className="send-to-yourself">
                <div>
                  <div>ORDERED DATE</div>
                  <div>23 JULY 2020</div>
                </div>
                <div>Send info to yourself</div>
              </div> */}

              <div>
              <div className="orderdetailsbox">
        <div style={{justifyContent: "space-between"}} className="orderdetailstop">
          <div>Customer: {this.state.customerName}</div>
          
          {/* <div
            onClick={() =>this.printPage()}
            style={{ cursor: "pointer", backgroundColor: "#40aa54" }}
            className="ship-order"
          >
                Print Order
          </div> */}

        </div>
        <div className="orderdetailscontent delivery-detail-div">
          

          <div style={{width: '50%', margin:"0"}} className="orderdetailswhitesmoke extramargin">
            <div className="orderdetailsaddress">
              <div className="odaddresswrap">
                <div className="odaddresstop">
                  <div className="label">Contact Details</div>
                  
                </div>
                <p>
                  Email: {this.state.customerEmail}
                  <br />
                  Phone: {this.state.customerPhone}
                  <br />
                  Date: {getDateFromTimestamp(this.state.purchasedDate)}
                  <br />
                </p>
              </div>
            </div>
          </div>


          <div style={{width: '50%', margin:"0", borderLeft: "1px solid #d3d3d3"}} className="orderdetailswhitesmoke extramargin">
            <div className="orderdetailsaddress">
              <div className="odaddresswrap">
                <div className="odaddresstop">
                  <div className="label">Shipping Details</div>
                  
                </div>
                <p>
                  {this.state.shippingStreet}
                  <br />
                  {this.state.shippingCity}, {this.state.shippingState} {this.state.shippingZip}
                  <br />
                  {this.state.shippingCountry}
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
              </div>
              <div className="delivery-list-container">
                <div className="productlistwrap">
                  <div style={{gridTemplateColumns: "1fr 1fr 1fr !important"}} className="productlisttitle rows-3">
                    <div className="productlisttitletext">
                      <div>Product</div>
                    </div>
                    <div className="productlisttitletext">
                      <div>Quantity</div>
                    </div>
                    {/* <div className="productlisttitletext">
                      <div>Variant</div>
                    </div> */}
                    <div className="productlisttitletext">
                      <div>Base Price</div>
                    </div>

                  </div>

                  {this.state.orderList?.map((order,index) => {
                    return (
                      <DeliveryItem
                        key={index}
                        productDescription={order?.productDescription}
                        productName={order?.productName}
                        orderQuantity={order?.orderQuantity}
                        image={order?.mainImage?.main}
                        productPrice={order?.productPrice}
                        productStock={order?.productStock}
                        currency={order?.currency}
                        selectedSize={
                          order.selectedSizes && typeof order.selectedSizes === 'string' 
                          ? order.selectedSizes
                          :(order.selectedSizes && order.selectedSizes[0] !== "N/A"
                              ? order.selectedSizes[0]
                              : null)
                        }
                        selectedColor={
                          order.selectedColors && typeof order.selectedColors === 'string' 
                          ?order.selectedColors
                          :(order.selectedColors && order.selectedColors[0] !== "N/A"
                            ? order.selectedColors[0]
                            : null)
                        }
                        selectedExtraVariants={
                          order.selectedExtraVariants ? order.selectedExtraVariants : []
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <div style={{ marginTop:"20px"}} className="pop-footer tracking-btn-container">
              {this.props.loading === false ? (
                <>
                  <div
                    onClick={() => this.props.onShowSelfShippingPop()}
                    style={{ cursor: "pointer" }}
                    className="ship-order"
                  >
                    Mark As Shipped
                  </div>

                  <div>


                    <div style={{color: "#6640aa"}} className="customer-support">Mark as Shipped to get paid</div>
                  </div>

                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DeliveryInfoPop;
