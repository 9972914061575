import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import shortid from "shortid";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import StatefulMultiSelect from "@khanacademy/react-multi-select";

import storeProductFeaturedImage from "../../../helpers/storeProductFeaturedImage";
import storeProductImagesToStorage from "../../../helpers/storeProductImagesToStorage";
import saveProductToDatabase from "../../../helpers/saveProductToDatabase";
import getSellersInfo from "../../../helpers/getSellersInfo";
import InputTags from "../../../common/InputTags";
import priceFormater from "../../../helpers/priceFormater";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let tempFiles = [];
const db = firebase.firestore();
const options = [
  { label: "Clothing, Shoes, Jewelry & Watches", value: 0 },
  { label: "Movies, Music & Games", value: 1 },
  { label: "Electronics, Computers & Office", value: 2 },
  { label: "Home, Garden & Tools", value: 3 },
  { label: "Food & Grocery", value: 4 },
  { label: "Beauty & Health", value: 5 },
  { label: "Toys, Kids & Baby", value: 6 },
  { label: "Handmade", value: 7 },
  { label: "Sports & Outdoors", value: 8 },
  { label: "Automotive & Industrial", value: 9 },
];

const optionsToValues = {
  "Clothing, Shoes, Jewelry & Watches": 0,
  "Movies, Music & Games": 1,
  "Electronics, Computers & Office": 2,
  "Home, Garden & Tools": 3,
  "Food & Grocery": 4,
  "Beauty & Health": 5,
  "Toys, Kids & Baby": 6,
  Handmade: 7,
  "Sports & Outdoors": 8,
  "Automotive & Industrial": 9,
};

const colorOptions = [
  { label: "Black", value: 0 },
  { label: "White", value: 1 },
  { label: "Red", value: 2 },
  { label: "Blue", value: 3 },
  { label: "Green", value: 4 },
  { label: "Yellow", value: 5 },
  { label: "Gold", value: 6 },
  { label: "Grey", value: 7 },
  { label: "Other", value: 8 },
];

const colorsOptionsToValues = {
  Black: 0,
  White: 1,
  Red: 2,
  Blue: 3,
  Green: 4,
  Yellow: 5,
  Gold: 6,
  Grey: 7,
  Other: 8,
};

export default class EditProduct extends Component {
  state = {
    productImages: this.props.productInfo.productImages
      ? this.props.productInfo.productImages
      : [],
    featuredImage: this.props.productInfo.featuredImage
      ? this.props.productInfo.featuredImage
      : "",
    productName: this.props.productInfo.productName
      ? this.props.productInfo.productName
      : "",
    productDescription: this.props.productInfo.productDescription
      ? this.props.productInfo.productDescription
      : "",
    productPrice: this.props.productInfo.productPrice
      ? this.props.productInfo.productPrice
      : 0,
    productStock: this.props.productInfo.productStock
      ? this.props.productInfo.productStock
      : 0,
    productVariant:
      this.props.productInfo.productVariant &&
      Array.isArray(this.props.productInfo.productVariant) === false
        ? this.props.productInfo.productVariant
        : {},
    productWeight: this.props.productInfo.productWeight
      ? this.props.productInfo.productWeight
      : 0,
    productHeight: this.props.productInfo.productHeight
      ? this.props.productInfo.productHeight
      : 0,
    productWidth: this.props.productInfo.productWidth
      ? this.props.productInfo.productWidth
      : 0,
    productLength: this.props.productInfo.productLength
      ? this.props.productInfo.productLength
      : 0,
    // productCategory: this.props.productInfo.productCategory
    //   ? this.props.productInfo.productCategory
    //   : "",
    sellerFullName: this.props.productInfo.sellerFullName
      ? this.props.productInfo.sellerFullName
      : "",
    slug: this.props.productInfo.slug ? this.props.productInfo.slug : "",
    currency: this.props.productInfo.currency
      ? this.props.productInfo.currency
      : "N",
    storeName: this.props.productInfo.storeName
      ? this.props.productInfo.storeName
      : "",
    discount: this.props.productInfo.discount
      ? this.props.productInfo.discount
      : 0,
    discountedPrice: this.props.productInfo.discountedPrice
      ? this.props.productInfo.discountedPrice
      : 0,
    location: this.props.productInfo.location
      ? this.props.productInfo.location
      : "",
    lastUpdate: this.props.productInfo.lastUpdate
      ? this.props.productInfo.lastUpdate
      : "",
    likes: this.props.productInfo.likes ? this.props.productInfo.likes : 0,
    newFiles: [],
    newFeaturedImageFile: [],
    featuredImageURL: "",
    productCategory: this.props.productInfo.productCategory
      ? this.props.productInfo.productCategory
      : [],
    selected: [],
    noProducts: false,
    productUploadSuccess: false,
    productUploadError: false,
    sizeSuggestions: [
      { id: "Extra Small", text: "Extra Small" },
      { id: "Small", text: "Small" },
      { id: "Medium", text: "Medium" },
      { id: "Large", text: "Large" },
      { id: "Extra Large", text: "Extra Large" },
    ],
    tags: [], // preselected tags,
    selectedColors: [],
  };

  handleDelete = (i) => {
    const { tags } = this.state;
    let newTagArray = [];
    let productVar = this.state.productVariant;

    let newTags = tags.filter((tag, index) => index !== i);
    newTags.map((tag) => {
      newTagArray.push(tag.text);
    });
    productVar.sizes = newTagArray;
    this.setState({
      tags: newTags,
      productVariant: productVar,
    });
  };

  handleAddition = (tag) => {
    let tagArray = [];
    let productVar = this.state.productVariant;

    tagArray.push(tag.text);
    this.state.tags.map((tag) => {
      tagArray.push(tag.text);
    });
    productVar.sizes = tagArray;

    this.setState((state) => ({
      tags: [...state.tags, tag],
      productVariant: productVar,
    }));
  };

  handleDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };

  handleChange = (e) => {
    //enforce numbers only for product dimensions
    if (
      e.target.name === "productWeight" ||
      e.target.name === "productHeight" ||
      e.target.name === "productLength" ||
      e.target.name === "productWidth"
    ) {
      let value = e.target.value.replace(/[^0-9 ]/g, "");
      value = value.replace(/ /g, "");
      this.setState({
        [e.target.name]: value,
      });
      return;
    }

    //enfore price format
    if (e.target.name === "productPrice") {
      // let price = e.target.value.replace(/[^0-9 ]/g, "");
      // price = price.replace(/ /g, "");
      this.setState({
        [e.target.name]: e.target.value,
      });
      return;
    }

    if (e.target.name === "productStock") {
      let stock = e.target.value.replace(/[^0-9 ]/g, "");
      stock = stock.replace(/ /g, "");
      this.setState({
        [e.target.name]: stock,
      });
      return;
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleNumberInputChange = (e) => {
    const doubleValue = parseFloat(e.target.value);
    this.setState({
      [e.target.name]: doubleValue,
    });
  };

  handleArrayInputChange = (e) => {
    const tempHolder = e.target.value.split(",");
    this.setState({
      [e.target.name]: tempHolder,
    });
  };

  //handle featuredImage change
  handleFeaturedImageChange = async (e) => {
    e.preventDefault();
    // const {productName, productCategory}=this.state;
    const productName = this.state.productName
      ? this.state.productName
      : "image";
    const productCategory = this.state.productCategory
      ? this.state.productCategory
      : ["default"];

    const featuredImageFile = e.target.files[0];

    //enfore image type on upload
    if (
      featuredImageFile.type !== "image/png" &&
      featuredImageFile.type !== "image/jpg" &&
      featuredImageFile.type !== "image/jpeg"
    ) {
      const message = `Image of type ${featuredImageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
      console.log(
        "File type is not supported. Uploaded type:",
        featuredImageFile.type
      );
      toast.error(message);
      return;
    }

    this.showAwaitingImageUpload(true);

    const featuredImageURL = await storeProductFeaturedImage(
      featuredImageFile,
      productName,
      productCategory
    );
    this.showAwaitingImageUpload(false);

    this.setState({ featuredImage: featuredImageURL });
  };

  // handles the images change handle
  handleImageChange = async (e) => {
    e.preventDefault();
    if (this.state.productImages.length + 1 > 4) {
      toast.error("You can only upload a maximum of 4 images");
      return;
    }

    const imageFile = e.target.files[0];

    //enfore image type on upload
    if (
      imageFile.type !== "image/png" &&
      imageFile.type !== "image/jpg" &&
      imageFile.type !== "image/jpeg"
    ) {
      const message = `Image of type ${imageFile.type} are not supported. Please upload a JPEG, JPG or PNG`;
      console.log("File type is not supported. Uploaded type:", imageFile.type);
      toast.error(message);
      return;
    }

    this.showAwaitingImageUpload(true);
    const productName = this.state.productName
      ? this.state.productName
      : "image";
    const productCategory = this.state.productCategory
      ? this.state.productCategory
      : ["default"];
    const imageURL = await storeProductImagesToStorage(
      imageFile,
      productName,
      productCategory
    );
    const allImages = this.state.productImages;
    this.showAwaitingImageUpload(false);
    allImages.push(imageURL);
    this.setState({ productImages: allImages });
  };

  showAwaiting = (value, message) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: message,
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {}, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  // this function is the popup when saving the product
  showAwaitingImageUpload = (value) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: "Your Image is being uploaded...",
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {}, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  // this function is the popup when saving the product
  showWaitingPopupWhenProductIsBeingSave = (value) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: "Your product is being saved...",
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {}, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    //Enfore user to select atleast one category
    if (this.state.productCategory.length === 0) {
      toast.error("Please select atleast one category");
      return;
    }

    //enforce minimum price of N100 for nigeria sellers
    if (
      this.state.productPrice < 100 &&
      (this.state.currency === "N" || this.state.currency === "NGN")
    ) {
      return toast.error("Please enter a minimum price of N100");
    }

    //enforce price format
    if (
      parseFloat(this.state.productPrice).toString() !== this.state.productPrice
    ) {
      return toast.error(
        "Please enter a valid number as price. NO SPACES, LETTERS OR SPECIAL CHARACTERS"
      );
    }

    const featuredImageURL = this.state.featuredImage;

    let productImagesURL = this.state.productImages;

    //check if featured Image exists
    if (!featuredImageURL) {
      toast.error("Please upload a featured Image for your product");
      return;
    }

    this.showWaitingPopupWhenProductIsBeingSave();

    console.log("featImg", featuredImageURL);
    console.log("images", productImagesURL);

    const {
      productName,
      productDescription,
      productPrice,
      productStock,
      productVariant,
      productWeight,
      productHeight,
      productWidth,
      productLength,
      productCategory,
      sellerFullName,
      currency,
      storeName,
      discount,
      discountedPrice,
      location,
      likes,
      slug,
    } = this.state;
    console.log("Before PROD INF0", productVariant);
    const productInfo = {
      productName,
      productDescription,
      productPrice,
      productStock,
      productVariant,
      productWeight,
      productHeight,
      productWidth,
      productLength,
      productCategory,
      sellerFullName,
      currency,
      storeName,
      discount,
      discountedPrice,
      location,
      likes,
      slug,
      featuredImageURL,
      productImagesURL,
      productId: this.props.productId,
    };

    console.log("first PROD INFO", productInfo);

    const result = await saveProductToDatabase(productInfo);

    if (result === "success") {
      this.showWaitingPopupWhenProductIsBeingSave(false);
      this.props.closeModal();
      this.setState({
        productUploadSuccess: true,
      });
    } else {
      console.log("error saving product", result);
      this.showWaitingPopupWhenProductIsBeingSave(false);
      this.setState({
        productUploadError: true,
      });
    }
  };

  checkSellerInfo = async (sellerUid) => {
    try {
      const sellerInfo = await getSellersInfo(sellerUid);
      if (sellerInfo !== "No store") {
        const { firstName, lastName, storeName, slug, address } = sellerInfo;
        this.setState({
          sellerFullName: firstName + " " + lastName,
          storeName: storeName,
          slug: slug,
          location: address,
        });
      } else {
        toast.error("Please setup a store before adding products");
        this.props.history.push("/seller/dashboard/setup");
      }
    } catch (error) {
      console.log("error checking seller info", error);
    }
  };

  populateSelectedCategories = () => {
    const selected = [];
    //avoid errors with productCategory not being an array sometimes. (don't know why this happens right now. shouldn't happen but just to be safe)
    if (Array.isArray(this.state.productCategory) === true) {
      this.state.productCategory.forEach((category) => {
        selected.push(optionsToValues[category]);
      });
    }
    this.setState({ selected });
  };

  populateSelectedColors = () => {
    const selectedColors = [];

    //avoid errors
    if (Array.isArray(this.state.productVariant.colors) === true) {
      this.state.productVariant.colors.forEach((color) => {
        selectedColors.push(colorsOptionsToValues[color]);
      });
    }
    this.setState({ selectedColors });
  };

  populateTags = () => {
    let tagsArrayObject = [];
    if (this.state.productVariant && this.state.productVariant.sizes) {
      //avoid errors
      if (Array.isArray(this.state.productVariant.sizes) === true) {
        this.state.productVariant.sizes.forEach((size) => {
          const obj = { id: size, text: size };
          tagsArrayObject.push(obj);
        });
      }
      this.setState({ tags: tagsArrayObject });
    }
  };

  componentDidMount() {
    // check if seller is logged in
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //Check if user already has some products uploaded
        this.checkSellerInfo(user.uid);
        this.populateSelectedCategories();
        this.populateSelectedColors();
        this.populateTags();
      } else {
        this.props.history.push("/");
      }
    });

    tempFiles = [];
  }

  removeImage = async (imageURL, index) => {
    if (!imageURL) {
      return toast.error("No image to be deleted");
    }

    const onEditImageCount = this.props.productInfo.productImages.length;

    // const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerUid = this.props.sellerUid;
    let batch = db.batch();
    const productId = this.props.productId;

    this.state.productImages.splice(index, 1);
    const newProductImagesArray = this.state.productImages;

    console.log("url--", imageURL.includes("blob:http"));
    console.log("img index--", index);

    if (imageURL.includes("blob:http")) {
      //delete a picture after selecting and BEFORE SAVING CHANGES to database
      return this.setState({
        productImages: newProductImagesArray,
        // newFiles: newNewFiles
      });
    }

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = await db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("products")
      .doc(productId);
    batch.update(sellersAccountsRef, { productImages: newProductImagesArray });

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.update(allProductsRef, { productImages: newProductImagesArray });

    await batch
      .commit()
      .then(() => {
        var imageRef = storage.refFromURL(imageURL);

        imageRef
          .delete()
          .then(() => {
            this.setState({
              productImages: newProductImagesArray,
              newFiles: [],
            });
            console.log("image removed");
          })
          .catch((error) => {
            console.log("error deleting image--", error);
          });
      })
      .catch((error) => {
        console.log("error deleting image reference");
      });
  };

  cancel = async () => {
    //empty tempFiles with the user cancels Add Product action
    tempFiles = [];

    this.props.history.push("/seller/dashboard");
  };

  addProductsLater = () => {
    this.props.history.push("/seller/dashboard");
  };

  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };
  selectCategory = ({ selected }) => {
    //limit the number of category selected to 2 MAX
    const numberOfItems = selected.length;
    const categoryNames = [];
    if (numberOfItems === 0) {
      this.setState({ selected });
      this.setState({ productCategory: [] });
    } else if (numberOfItems <= 2) {
      this.setState({ selected });
      selected.forEach((categoryValue) => {
        categoryNames.push(options[categoryValue].label);
        this.setState({ productCategory: categoryNames });
      });
    } else {
      toast.error("You can only select 2 categories");
      return;
    }
  };

  removeFeaturedImage = async (imageURL) => {
    if (!imageURL) {
      return toast.error("No image to be deleted");
    }

    // const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerUid = this.props.sellerUid;
    let batch = db.batch();
    const productId = this.props.productId;

    // setFeaturedImageUploaded(false);
    this.setState({ featuredImageUploaded: false });

    if (imageURL.includes("blob:http")) {
      //delete a picture after selecting and BEFORE SAVING CHANGES to database
      return this.setState({
        featuredImage: "",
        newFeaturedImageFile: "",
        featuredImageURL: "",
        previewFeaturedImage: "",
      });
    }

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = await db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("products")
      .doc(productId);
    batch.update(sellersAccountsRef, { featuredImage: "" });

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.update(allProductsRef, { featuredImage: "" });

    await batch
      .commit()
      .then(() => {
        var imageRef = storage.refFromURL(imageURL);

        imageRef
          .delete()
          .then(() => {
            this.setState({
              featuredImage: "",
              newFeaturedImageFile: "",
              featuredImageURL: "",
            });
            console.log("image removed");
          })
          .catch((error) => {
            console.log("error deleting image--", error);
          });
      })
      .catch((error) => {
        console.log("error deleting image reference");
      });
  };

  selectColor = ({ selectedColors }) => {
    let productColors = [];
    selectedColors.map((colorsCodes) => {
      const colorName = colorOptions[colorsCodes].label;
      productColors.push(colorName);
    });
    console.log("prodColor", productColors);
    let productVar = this.state.productVariant;
    productVar.colors = productColors;
    this.setState({ selectedColors, productVariant: productVar });
  };

  render() {
    console.log(this.state.newFeaturedImageFile.length);
    console.log("tags", this.state.tags);
    console.log("selected colors", this.state.selectedColors);
    console.log("product variant", this.state.productVariant);
    console.log("category", this.state.productCategory);
    return (
      <div className="dashboard-holder">
        <ToastContainer />
        <div className="form-holder">
          <div className="form-store-setup">
            <div className="copy-holder">
              <h4 className="h3">Edit Product</h4>
              <div className="copy-description">Edit product info</div>
            </div>
            <div className="w-form">
              <form
                onSubmit={this.handleSubmit}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form"
              >
                <div className="form-group">
                  <label htmlFor="productName" className="label-2x">
                    Product Name<span className="required">*</span>
                  </label>
                  <input
                    value={this.state.productName}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productName"
                    data-name="productName"
                    placeholder="e.g: Black leather shoes"
                    id="productName"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="productPrice" className="label-2x">
                    Product Price<span className="required">*</span>{" "}
                    <span style={{ fontStyle: "italic", color: "#cc2936" }}>
                      {priceFormater(this.state.productPrice, "N")}
                    </span>
                  </label>
                  <input
                    value={this.state.productPrice}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productPrice"
                    data-name="productPrice"
                    placeholder="e.g: 5000"
                    id="productPrice"
                    required
                  />
                </div>
                <div className="form-group-stretch">
                  <label htmlFor="productDescription" className="label-2x">
                    Product Description<span className="required">*</span>
                  </label>
                  <textarea
                    value={this.state.productDescription}
                    onChange={this.handleChange}
                    data-name="productDescription"
                    maxLength={5000}
                    id="productDescription"
                    name="productDescription"
                    placeholder="e.g: Most comfortable dress shoes for men"
                    required
                    className="input-field w-input"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="productCurrency" className="label-2x">
                    Currency <span className="required">*</span>
                  </label>
                  <select
                    id="productCurrency"
                    name="productCurrency"
                    required
                    data-name="productCurrency"
                    className="input-field w-select"
                  >
                    {this.state.currency === "N" ||
                    this.state.currency === "NGN" ? (
                      <option value="ng">Naira</option>
                    ) : (
                      <option value="ng">USD</option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="productStock" className="label-2x">
                    Product Stock <span className="required">*</span>
                  </label>
                  <input
                    value={this.state.productStock}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productStock"
                    data-name="productStock"
                    placeholder="e.g: 100"
                    id="productStock"
                    required
                  />
                </div>
                <div className="form-group-stretch">
                  <label htmlFor="productColors" className="label-2x">
                    Product Colors
                  </label>

                  <StatefulMultiSelect
                    className="input-field w-input"
                    options={colorOptions}
                    selected={this.state.selectedColors}
                    onSelectedChanged={(selectedColors) =>
                      this.selectColor({ selectedColors })
                    }
                    overrideStrings={{
                      selectSomeItems: "Select all available colors",
                      allItemsAreSelected: "All Items are Selected",
                      selectAll: "",
                      search: "Search",
                    }}
                  />
                </div>
                <div
                  style={{ paddingTop: "20px" }}
                  className="form-group-stretch"
                >
                  <label htmlFor="productSizes" className="label-2x">
                    Product Sizes{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#bf360c",
                        paddingLeft: "5px",
                      }}
                    >
                      (enter each size separated by a COMMA or SPACE)
                    </span>
                  </label>
                  <InputTags
                    tags={this.state.tags}
                    // suggestions={this.state.sizeSuggestions}
                    handleDelete={this.handleDelete}
                    handleAddition={this.handleAddition}
                    handleDrag={this.handleDrag}
                    inputFieldClass="input-field w-input"
                    placeholder="e.g: Small, Medium, 10, 12.5 etc..."
                  />
                </div>
                <div className="form-group-stretch">
                  <label htmlFor="productCategory" className="label-2x">
                    Product Category
                  </label>

                  <StatefulMultiSelect
                    className="input-field w-input"
                    options={options}
                    selected={this.state.selected}
                    onSelectedChanged={(selected) =>
                      this.selectCategory({ selected })
                    }
                    overrideStrings={{
                      selectSomeItems: "Select Categories (MAX 2)",
                      allItemsAreSelected: "All Items are Selected",
                      selectAll: "",
                      search: "Search",
                    }}
                  />
                </div>
                <div className="copy-holder">
                  <h4 className="h3">Product Gallery/Images</h4>
                  <div className="copy-description">Edit images</div>
                </div>
                <div className="form-group">
                  <label htmlFor="imgFeatured" className="label-2x">
                    Main Image <span className="required">*</span>
                    <span className="small-text" />
                  </label>
                  <input
                    onChange={this.handleFeaturedImageChange}
                    type="file"
                    className="input-field w-input"
                    maxLength={256}
                    name="featuredImage"
                    data-name="imgFeatured"
                    placeholder="Upload your Main Product Image"
                    id="imgFeatured"
                  />
                </div>
                <div className="form-group">
                  <img
                    alt="placeholder"
                    style={{ width: 50 }}
                    src={
                      this.state.featuredImage
                        ? this.state.featuredImage
                        : "https://via.placeholder.com/200"
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.removeFeaturedImage(this.state.featuredImage, 6)
                    }
                    className="icon-img w-inline-block"
                  >
                    <div className="text-block-3">delete</div>
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="img" className="label-2x">
                    Product Image 2<span className="small-text" />
                  </label>
                  <input
                    onChange={this.handleImageChange}
                    type="file"
                    className="input-field w-input"
                    maxLength={256}
                    name="img2"
                    data-name="img2"
                    placeholder="Product Image 2"
                    id="img"
                  />
                </div>
                <div className="form-group">
                  <img
                    alt="placeholder"
                    style={{ width: 50 }}
                    src={
                      this.state.productImages[0]
                        ? this.state.productImages[0]
                        : "https://via.placeholder.com/200"
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.removeImage(this.state.productImages[0], 0)
                    }
                    className="icon-img w-inline-block"
                  >
                    <div className="text-block-3">delete</div>
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="img-2" className="label-2x">
                    Product Image 3<span className="small-text" />
                  </label>
                  <input
                    onChange={this.handleImageChange}
                    type="file"
                    className="input-field w-input"
                    maxLength={256}
                    name="img3"
                    data-name="img3"
                    placeholder="Product Image 3"
                    id="img-2"
                  />
                </div>
                <div className="form-group">
                  <img
                    alt="placeholder"
                    style={{ width: 50 }}
                    src={
                      this.state.productImages[1]
                        ? this.state.productImages[1]
                        : "https://via.placeholder.com/200"
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.removeImage(this.state.productImages[1], 1)
                    }
                    className="icon-img w-inline-block"
                  >
                    <div className="text-block-3">delete</div>
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="img-3" className="label-2x">
                    Product Image 4<span className="small-text" />
                  </label>
                  <input
                    onChange={this.handleImageChange}
                    type="file"
                    className="input-field w-input"
                    maxLength={256}
                    name="img4"
                    data-name="img4"
                    placeholder="Product Image 4"
                    id="img-3"
                  />
                </div>
                <div className="form-group">
                  <img
                    alt="placeholder"
                    style={{ width: 50 }}
                    src={
                      this.state.productImages[2]
                        ? this.state.productImages[2]
                        : "https://via.placeholder.com/200"
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.removeImage(this.state.productImages[2], 2)
                    }
                    className="icon-img w-inline-block"
                  >
                    <div className="text-block-3">delete</div>
                  </button>
                </div>
                <div className="form-group">
                  <label htmlFor="img-4" className="label-2x">
                    Product Image 5<span className="small-text" />
                  </label>
                  <input
                    onChange={this.handleImageChange}
                    type="file"
                    className="input-field w-input"
                    maxLength={256}
                    name="img5"
                    data-name="img5"
                    placeholder="Product Image 5"
                    id="img-4"
                  />
                </div>
                <div className="form-group">
                  <img
                    alt="placeholder"
                    style={{ width: 50 }}
                    src={
                      this.state.productImages[3]
                        ? this.state.productImages[3]
                        : "https://via.placeholder.com/200"
                    }
                  />
                  <button
                    type="button"
                    onClick={() =>
                      this.removeImage(this.state.productImages[3], 3)
                    }
                    className="icon-img w-inline-block"
                  >
                    <div className="text-block-3">delete</div>
                  </button>
                </div>
                {/* <div className="form"> */}
                <div className="copy-holder">
                  <h4 className="h3">Packaging Info</h4>
                  <div className="copy-description">
                    Product dimension for shipping purposes
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productWeight" className="label-2x">
                    Weight (lbs)
                  </label>
                  <input
                    value={this.state.productWeight}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productWeight"
                    data-name="productWeight"
                    placeholder="e.g: 1 lbs"
                    id="productWeight"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="productHeight" className="label-2x">
                    Height (inches)
                  </label>
                  <input
                    value={this.state.productHeight}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productHeight"
                    data-name="productHeight"
                    placeholder="e.g: 1 lbs"
                    id="productHeight"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="productWidth" className="label-2x">
                    Width (inches)
                  </label>
                  <input
                    value={this.state.productWidth}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productWidth"
                    data-name="productWidth"
                    placeholder="e.g: 1 lbs"
                    id="productWidth"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="productLength" className="label-2x">
                    Length (inches)
                  </label>
                  <input
                    value={this.state.productLength}
                    onChange={this.handleChange}
                    type="text"
                    className="input-field w-input"
                    maxLength={256}
                    name="productLength"
                    data-name="productLength"
                    placeholder="e.g: 1 lbs"
                    id="productLength"
                  />
                </div>
                {/* </div> */}
                <div className="form-group-stretch">
                  <input
                    type="submit"
                    defaultValue="Upload"
                    data-wait="Please wait..."
                    className="btn-sign w-button"
                  />
                </div>
              </form>
            </div>
          </div>
          {/* <div className="form-group-stretch">
              <button
                onClick={this.props.closeModal}
                type="button"
                defaultValue="Upload"
                className="btn-sign w-button"
              >
                cancel
              </button>
            </div> */}
        </div>
      </div>
    );
  }
}
