import React from 'react';
import priceFormater from '../../../helpers/priceFormater';
const Subscriber = (props) => {
    let date = "date"
    console.log("Subscriber prop", props);
    return (
        <div className="order-block">
            <div className="box-date half-box">
                <div className="box-value">{props.subscriber.email}</div>

            </div>
            <div className="box-date half-box">
                <div className="box-value">
                </div>
                <div style={{ paddingTop: "10px" }} className="box-title"></div>

            </div>
            <div className="box-date">
                <div className="box-value">

                </div>
                <div style={{ paddingTop: "10px" }} className="box-title"></div>

            </div>
            <div className="box-date half-box">
                <a
                    className="box-btn-primary w-inline-block"
                >
                    <div style={{ borderRadius: "50px", backgroundColor: "#dad8ff", color: "black" }} className="box-btn-text-primary">Subscribed</div>
                </a>
            </div>
            <div className="box-date half-box">
                <a href={`mailto:${props.subscriber.email}`}
                    style={{ cursor: "pointer" }}
                    className="box-btn-primary w-inline-block"
                >
                    <div className="box-btn-text-tertiary">Email Subscriber </div>
                </a>
            </div>
        </div>
    )
}
export default Subscriber;