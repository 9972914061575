import React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import firebase from "../../../../firebaseConfig";
import {STRIPE_VERIFICATION} from "../../../../secrets"

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ 
        stripe: await this.props.stripePromise
     });
  }

  saveDOB=async(stripeAcceptance)=>{

    try{
      let birthday=this.props.birthday;
      console.log("birthday",birthday)

      let sellerId=this.props.sellerId;

      console.log("sellerId",sellerId)
      const db = firebase.firestore();
      const sellerRef = await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .get();

      let sellerData = sellerRef.data();
      console.log("sellerData=>", sellerData);

      await db.collection("sellersAccounts").doc(sellerId).set(
        {
          dob:birthday,
          stripeAcceptance

        },
        { merge: true }
      );

    }catch(error){

      console.log("error saving dob",error)
    }
  }


  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    this.props.startLoading(true);
    let {
      storeName,
      slug,
      firstName,
      lastName,
      country,
      state,
      city,
      street,
      zipcode,
      virtualCards,
      phoneNumber
    }=this.props.storeInfo;
    let sellerId=this.props.sellerId;
    let birthday=this.props.birthday;

    let cardHolderId="N/A";
    if(virtualCards && virtualCards?.cardHolderId){
      cardHolderId=virtualCards?.cardHolderId;
    }
    let stripeAcceptance=Date.now();
    console.log("stripeAcceptance",stripeAcceptance)

    await this.saveDOB(stripeAcceptance)
    let queryData={
        cardHolderId,
        sellerId,
        storeName,
        slug,
        firstName,
        lastName,
        country,
        state,
        city,
        street,
        zipcode,
        birthday:birthday?birthday:"N/A",
        stripeAcceptance,
        phoneNumber
    }

    console.log("queryData",queryData)

    // Call your backend to create the VerificationSession.
    const response = await fetch( `${STRIPE_VERIFICATION}/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(queryData),
      });

    const session = await response.json();
    console.log("session",session);

    // Show the verification modal.
    const identity = await stripe.verifyIdentity(session.verificationSession.client_secret);

    console.log("identity", identity)
    if (identity?.error) {
      let errorMessage =identity?.error
      this.props.verificationFeedback("error",errorMessage)
      console.log('[error]', identity?.error);
    } else {
      console.log('Verification submitted!');
      this.props.verificationFeedback("success")
    }
  };

  render() {
    const { stripe, submitted } = this.state;

    if (submitted) {
      return (
        <div className="todayscontent">
          <div className="todayscontenttitle">Thanks for submitting your identity document</div>
          <div className="todayscontenttext" style={{textAlign: "center"}}>
            We are processing your verification.
          </div>
        </div>
      );
    }

    return (
      <button 
        className="popupbutton w-inline-block" 
        role="link" 
        style={{margin:"0 auto", width:"100%", padding: "9px 23px", fontSize:"16px"}} 
        disabled={!stripe} 
        onClick={this.handleClick}
    >
        Continue
      </button>
    );
  }
}


export default VerifyButton;