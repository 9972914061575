import React from 'react';
import ReactQuill from 'react-quill';

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video', 'formula'],          // add's image support
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ],
};

// const formats = [
//   'header',
//   'color', 'bold', 'italic', 'underline', 'strike', 'blockquote',
//   'list', 'bullet', 'indent',
//   'link', 'image'
// ];
const MyQuillEditor = (props) => {
    return (
        <ReactQuill value={props.text || ""}
            onChange={props.onChange}
            style={props.style ? props.style : { minWidth: "100%", width: "100%", maxWidth: "500px", height: "50%", minHeight: "50%", maxHeight: "50%" }}
            modules={modules}
        // formats={formats}
        />
    )
}
export default MyQuillEditor;