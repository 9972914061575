import React from 'react';
const DomainInstructions = (props) => {
    return (
        <div style={{ minWidth: "100%", padding: "20px" }} className="product-steps">
            <div className="pop-content">
                <div className="sub-title">
                    If you have not done so already, here are the instructions to complete the process:
                </div>
                <div style={{ paddingTop: "10px", fontSize: "16px" }} className="pop-text">
                    1. Go to your domain provider's website add the following A records: <br />
                    <ul>
                        <li style={{ fontWeight: "bold" }}>199.36.158.100</li>
                    </ul>
            2. After adding this information, <b>it will take about 24 hours</b> before your new domain is ready to be used. <br />
            3. If you need guidance, here are a few resources:
            <ul>
                        <li>For a guide from <b>Google Domains</b>{" "}<a target="_blank" rel="noreferrer noopener" href="https://support.google.com/domains/answer/9211383?hl=en">click here </a></li>
                        <li>For a guide from <b>GoDaddy</b>{" "}<a target="_blank" rel="noreferrer noopener" href="https://www.godaddy.com/help/add-an-a-record-19238">click here </a></li>

                    </ul>
                </div></div> </div>
    )
}
export default DomainInstructions;