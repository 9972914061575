import firebase from "../../firebaseConfig";
import { SHIPO_KEY } from "../../secrets";

const db = firebase.firestore();
const shippo = require("shippo")(SHIPO_KEY);

// var parcel = {
//   length: "5", //max 108 inches
//   width: "5", //max 108 inches
//   height: "5", //max 108 inches
//   distance_unit: "in",
//   weight: "2", //max 70 lbs for USPS and 150 lbs for UPS
//   mass_unit: "lb",
// };

const createShippingLabel = (
  addressFrom,
  addressTo,
  carrierAccount,
  servicelevelToken,
  parcel
) => {
  return new Promise(async (resolve, reject) => {
    try {
      var shipment = {
        address_from: addressFrom,
        address_to: addressTo,
        parcels: [parcel],
      };
      const transaction = await shippo.transaction.create({
        shipment: shipment,
        carrier_account: carrierAccount,
        servicelevel_token: servicelevelToken,
      });
      resolve(transaction);
    } catch (error) {
      console.log("error creating shipping label", error);
      reject(error);
    }
  });
};
export default createShippingLabel;
