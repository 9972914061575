import React from "react";
import getDateFromTimestamp from "../../../helpers/getDateFromTimestamp";
import emailIcon from "../../../../images/send-email.png";

function PendingOrderItem(props) {
  let currency = props.currency?.toLowerCase();
  let date = getDateFromTimestamp(props.date);
  let string=props.status;
  let status=string.charAt(0).toUpperCase() + string.slice(1);

  if(status.toLowerCase()==="completed"){
    status = "Processing";
  }else if(status.toLowerCase()==="fulfilled"){
    status = "Delivered";
  }

  return (
    <a className="orderlistitem white w-inline-block"
      >
      <div className="orderlistnumber">{props.index+1}.</div>
      <div className="orderlisttextwrap">
        <div className="orderlistorderstitle mobile">Orders</div>
        <div className="orderlistorderstext">
          <div>{props.orderId}</div>
        </div>
      </div>
      <div className="orderlisttextwrap">
        <div className="orderlistdatetitle mobile">Date</div>
        <div className="orderlistdatetext">{date}</div>
      </div>
      
      
        <div className="orderlisttextwrap">
          <div className="orderliststatustitle mobile">Status</div>
          <div className="orderliststatus cancelled">
            <div>{status}</div>
          </div>
        </div>
      

      <div className="orderlisttextwrap">
        <div className="orderlistdeliverytitle mobile">Quantity</div>
        <div className="orderlistdeliverytext">{props.orderQuantity}</div>
      </div>
      <div className="orderlisttextwrap lastitem">
        <div className="orderlisttotaltitle mobile">Total</div>
        <div className="orderlisttotaltext">
          {currency == "usd"
            ? "$"
            : currency == "cad"
            ? "CA$"
            : currency == "ngn"
            ? "₦"
            : currency == "gbp"
            ? "£"
            : "$"}
          {props.amount}
        </div>
      </div>

      {props.email? 
      <div className="orderlisttextwrap lastitem">
          <a href = {`mailto: ${props.email}?subject=Checkout with Discount Code - SANTA for 10% OFF!&body=We are offering you 10% with discount code - SANTA at Checkout. Hurry! Offer last for only 48 hours`}>
        <div className="orderlistactionstitle mobile">Actions</div>
        
        <div className="receipt" style={{backgroundImage:`url(${emailIcon})`}}/>
        
        
        <div className="actionbox">
          <div className="orderlistactionsbox" />
          <div className="orderlistactionsbox" />
        </div>
        </a>
      </div>

      :null}
      <div className="orderlistpag" />
    </a>
  );
}

export default PendingOrderItem;
