import React from 'react'

import AvailabilityInput from './AvailabilityInput'


const AddAvailability = (props) => {
    return (
        <div className="holding-onboarding">
            <div className="forms w-form">
                <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    className="update-form"
                    onSubmit={props.handleSubmit}
                >

                    {
                        props.availability.map((day, i) => {
                            return (
                                <AvailabilityInput
                                    day={day.day}
                                    isOpen={day.isOpen}
                                    openTime={day.openTime}
                                    closeTime={day.closeTime}
                                    handleChange={props.handleAvailabilityChange}
                                    setTimeValueOption={props.setTimeValueOption}
                                    index={i}
                                    toggleIsOpen={props.toggleIsOpen}
                                    hours={day.hours}
                                    addHours={props.addHours}
                                    deleteHour={props.deleteHour}
                                />
                            )
                        })
                    }

                    <input
                        type="submit"
                        value={props.isEditing ? "Update Availability" : "Add Availability"}
                        data-wait="Please wait..."
                        className="submit-primary w-button"
                    />
                    {
                        props.isEditing &&
                        <button
                            onClick={props.cancel}
                            className="btn-secondary w-button"
                            style={{ marginTop: 10 }}>
                            <span className="text-btn-secondary">
                                Cancel
                            </span>
                        </button>
                    }
                </form>
                <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
    )
}

export default AddAvailability
