export const times = [
    {
        display: "12:00 AM",
        time: 0
    },
    {
        display: "12:30 AM",
        time: 0.5
    },
    {
        display: "1:00 AM",
        time: 1
    },
    {
        display: "1:30 AM",
        time: 1.5
    },
    {
        display: "2:00 AM",
        time: 2
    },
    {
        display: "2:30 AM",
        time: 2.5
    },
    {
        display: "3:00 AM",
        time: 3
    },
    {
        display: "3:30 AM",
        time: 3.5
    },
    {
        display: "4:00 AM",
        time: 4
    },
    {
        display: "4:30 AM",
        time: 4.5
    },
    {
        display: "5:00 AM",
        time: 5
    }
]

export const timeMap = {
    "12:00 AM": 0.0,
    "12:15 AM": 0.25,
    "12:30 AM": 0.5,
    "12:45 AM": 0.75,
    "1:00 AM": 1.0,
    "1:15 AM": 1.25,
    "1:30 AM": 1.5,
    "1:45 AM": 1.75,
    "2:00 AM": 2,
    "2:15 AM": 2.25,
    "2:30 AM": 2.5,
    "2:45 AM": 2.75,
    "3:00 AM": 3,
    "3:15 AM": 3.25,
    "3:30 AM": 3.5,
    "3:45 AM": 3.75,
    "4:00 AM": 4,
    "4:15 AM": 4.25,
    "4:30 AM": 4.5,
    "4:45 AM": 4.75,
    "5:00 AM": 5,
    "5:15 AM": 5.25,
    "5:30 AM": 5.5,
    "5:45 AM": 5.75,
    "6:00 AM": 6,
    "6:15 AM": 6.25,
    "6:30 AM": 6.5,
    "6:45 AM": 6.75,
    "7:00 AM": 7,
    "7:15 AM": 7.25,
    "7:30 AM": 7.5,
    "7:45 AM": 7.75,
    "8:00 AM": 8,
    "8:15 AM": 8.25,
    "8:30 AM": 8.5,
    "8:45 AM": 8.75,
    "9:00 AM": 9,
    "9:15 AM": 9.25,
    "9:30 AM": 9.5,
    "9:45 AM": 9.75,
    "10:00 AM": 10,
    "10:15 AM": 10.25,
    "10:30 AM": 10.5,
    "10:45 AM": 10.75,
    "11:00 AM": 11,
    "11:15 AM": 11.25,
    "11:30 AM": 11.5,
    "11:45 AM": 11.75,
    "12:00 PM": 12,
    "12:15 PM": 12.25,
    "12:30 PM": 12.5,
    "12:45 PM": 12.75,
    "1:00 PM": 13,
    "1:15 PM": 13.25,
    "1:30 PM": 13.5,
    "1:45 PM": 13.75,
    "2:00 PM": 14,
    "2:15 PM": 14.25,
    "2:30 PM": 14.5,
    "2:45 PM": 14.75,
    "3:00 PM": 15,
    "3:15 PM": 15.25,
    "3:30 PM": 15.5,
    "3:45 PM": 15.75,
    "4:00 PM": 16,
    "4:15 PM": 16.25,
    "4:30 PM": 16.5,
    "4:45 PM": 16.75,
    "5:00 PM": 17,
    "5:15 PM": 17.25,
    "5:30 PM": 17.5,
    "5:45 PM": 17.75,
    "6:00 PM": 18,
    "6:15 PM": 18.25,
    "6:30 PM": 18.5,
    "6:45 PM": 18.75,
    "7:00 PM": 19,
    "7:15 PM": 19.25,
    "7:30 PM": 19.5,
    "7:45 PM": 19.75,
    "8:00 PM": 20,
    "8:15 PM": 20.25,
    "8:30 PM": 20.5,
    "8:45 PM": 20.75,
    "9:00 PM": 21,
    "9:15 PM": 21.25,
    "9:30 PM": 21.5,
    "9:45 PM": 21.75,
    "10:00 PM": 22,
    "10:15 PM": 22.25,
    "10:30 PM": 22.5,
    "10:45 PM": 22.75,
    "11:00 PM": 23,
    "11:15 PM": 23.25,
    "11:30 PM": 23.5,
    "11:45 PM": 23.75,
}


export const timesArray = Object.keys(timeMap)

export let reverseTimeMap = {}
timesArray.forEach((time) => {
    reverseTimeMap[timeMap[time]] = time
})

export const days = ["Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"]

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
