import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import UpdateBankSuccess from "../../../common/UpdateBankSuccess";
// import checkInfo from "../../../../images/check-info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { STRIPE_CLIENT_ID } from "../../../../secrets";
const stripeClientId = STRIPE_CLIENT_ID;

class StripeConnect extends Component {
  state = {
    sellerId: this.props.sellerId,
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    email: this.props.email,
    phoneNumber: this.props.phoneNumber,
    businessName: this.props.businessName,
    country: this.props.country,
    city: this.props.city,
    state: this.props.state,
    zipcode: this.props.zipcode,
    street: this.props.street,
    stripeLink: `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&scope=read_write&state=${this.props.stripeState}&stripe_user[email]=${this.props.email}&stripe_user[first_name]=${this.props.firstName}&stripe_user[last_name]=${this.props.lastName}&stripe_user[phone_number]=${this.props.phoneNumber}&stripe_user[business_name]=${this.props.businessName}&stripe_user[street_address]=${this.props.street}&stripe_user[city]=${this.props.city}&stripe_user[state]=${this.props.state}&stripe_user[zip]=${this.props.zipcode}&stripe_user[country]=${this.props.country}&stripe_user[url]=${this.props.businessURL}`,
  };

  componentDidMount() {
    // this.setState({
    //     stripeLink:`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${stripeClientId}&state=${sellerId}`
    // })
  }

  render() {
    console.log(" SC props", this.props);
    return (
      <>
        <div className="section-onboarding">
          <div className="onboarding-steps">
            <div className="block-title-1">
              <h4 className="h4">Payment Information</h4>
            </div>
            <div className="microcopy-signup">
              When a customer buys your product, this is where your money will
              be deposited. <br />
              <br />
              Please connect your Stripe account. If you don’t have an account,
              then you can create one on the next screen.
            </div>
            <div className="holder-guide">
              <div className="microcopy-signup">
                To create a Stripe account you will need the following
                information:
                <br />‍<br />
                1. Routing Number
                <br />
                2. Bank Account Number
              </div>
            </div>
            <div className="holding-onboarding">
              <div className="forms w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <a
                    className="submit-primary w-button"
                    href={this.state.stripeLink}
                    style={{ textAlign: "center" }}
                  >
                    Connect Stripe
                  </a>
                </form>
                {/* <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

export default StripeConnect;
