import React from "react";
import confetti from "../../images/confetti.svg";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import Facebook from "../UI/buttons/Facebook";
import Email from "../UI/buttons/Email";
import Twitter from "../UI/buttons/Twitter";
import Pinterest from "../UI/buttons/Pinterest";
import Messenger from "../UI/buttons/Messenger";
import LinkedIn from "../UI/buttons/LinkedIn";
import { FB_APP_ID } from "../../secrets";
import Whatsapp from "../UI/buttons/Whatsapp";
//go live container
const GoLive = (props) => {
  return (
    <div style={{ display: "flex" }} className="tint-background">
      <div
        style={{ backgroundColor: "white", maxWidth: "900px" }}
        className="pop-container"
      >
        <div
          style={{ marginTop: "30px", marginBottom: "30px" }}
          className="onboarding-steps end"
        >
          <div className="block-title-1 ready">
            <div className="confetti-box">
              <img src={confetti} alt className="confetti" />
            </div>
            <h4 className="h4">Yay! {props.storeName} is Live.</h4>
          </div>
          <div className="summary">
            {/* <div className="congrats">
              Congratulations!! Your store is now Live!
            </div> */}
            <div className="list-points">
              <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="list-text">
                    Customers can now checkout from your store.
                  </div>
                </div>
              </div>
              <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="point-text-1">
                    Share your url{" "}
                    <span className="url"> cashaam.com/{props.slug} </span> with
                    potential customers online.
                  </div>
                </div>
              </div>
              <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="point-text-1">
                    <span style={{ paddingRight: "10px" }}>
                      Share on social media
                    </span>
                    <FacebookShareButton
                      style={{ paddingRight: "10px" }}
                      url={`https://cashaam.com/${props.slug}`}
                      children={<Facebook />}
                    />
                    <Whatsapp
                      style={{ paddingRight: "10px" }}
                      phoneNumber={props.phoneNumber}
                      url={`https://cashaam.com/${props.slug}`}
                    />
                    {/* <FacebookMessengerShareButton
                        style={{ paddingRight: "10px" }}
                        url={`https://cashaam.com/${props.slug}`}
                        children={<Messenger />}
                        appId={FB_APP_ID}
                      /> */}
                    {/* <PinterestShareButton style={{ paddingRight: "10px" }}
                url={`https://cashaam.com/${props.slug}`}
                children={<Pinterest />}
                media={this.state.featuredImage}
              /> */}
                    <TwitterShareButton
                      style={{ paddingRight: "10px" }}
                      url={`https://cashaam.com/${props.slug}`}
                      children={<Twitter />}
                    />
                    <LinkedinShareButton
                      style={{ paddingRight: "10px" }}
                      url={`https://cashaam.com/${props.slug}`}
                      children={<LinkedIn />}
                    />
                    <EmailShareButton
                      style={{ paddingRight: "10px" }}
                      url={`https://cashaam.com/${props.slug}`}
                      children={<Email />}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="list-text">
                    Your account has been successfully set up to sell online and
                    collect payments.
                  </div>
                </div>
              </div>
              <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="list-text">Launch Your Store &#x1F680;</div>
                </div>
              </div>
              <div className="points">
                <div className="point-icon">
                  <div className="text-block-5">
                    <em className="fontawesome"></em>
                  </div>
                </div>
                <div className="point-text">
                  <div className="list-text">Keep Working On It</div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="holding-onboarding">
            <div className="forms w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form-2"
              >
                <input
                  onClick={() => props.onCloseGoLive()}
                  type="button"
                  value="Done"
                  defaultValue="Done"
                  data-wait="Please wait..."
                  className="submit-primary w-button"
                />
              </form>
            </div>
            {/* <div className="btn-tertiary">
              <div className="text-btn-tertiary">Done</div>
            </div> */}
            {/* <a
              href="launch-url.html"
              aria-current="page"
              className="btn-primary-linker launch w-inline-block w--current"
            >
              <div className="text-btn-primary">Activate your Store</div>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GoLive;
