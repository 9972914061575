import React from "react";

function OrderPagination(props) {
  return (
    <div className="orderpagination _10topmargin">
      <div>543 items</div>
      <a
        href="#"
        className="orderpaginationbox orderpagfirstpage w-inline-block"
      />
      <a href="#" className="orderpaginationbox orderpagleft w-inline-block" />
      <a href="#" className="orderpaginationbox orderpagcurrent w-inline-block">
        <div>1</div>
      </a>
      <div>of 21</div>
      <a href="#" className="orderpaginationbox orderpagright w-inline-block" />
      <a
        href="#"
        className="orderpaginationbox orderpaglastpage w-inline-block"
      />
    </div>
  );
}

export default OrderPagination;
