import React, { Component } from 'react'
import CheckMark from '../../../UI/icons/CheckMark';
import Spinner from '../../../UI/Spinner';
import styles from './AddDomain.module.css'
import icon from '../../../../custom-images/checkMark.png';
import WarningIcon from '../../../UI/icons/WarningIcon';
import DomainInstructions from './DomainInstructions';
class AddDomainForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Domain</h4>

                </div>

                <div className="main">
                    <div style={{ minHeight: "50px", flexDirection: "column" }} className="form-holder">
                        {this.props.ready === false ? <Spinner /> :
                            <div style={{ minWidth: "100%" }} className="product-steps">
                                <div className="block-title-2">
                                    <h4 className="page-title">Manage Your Domain</h4>< br />
                                    {this.props.hasDomain === true ? this.props.activeDomain === true ? <h4 style={{ marginLeft: "auto" }} className="page-title">Connected <CheckMark /> </h4> : <h4 style={{ marginLeft: "auto" }} className="page-title">In Progress<WarningIcon /></h4> : null}
                                </div>
                                {this.props.errorMessage ? <a
                                    className="box-btn-primary w-inline-block"
                                >
                                    <div style={{ borderColor: "red" }} className="btn-text-primary-outline processed">
                                        {this.props.errorMessage}
                                    </div>
                                </a> :
                                    this.props.domainSetSuccess === true ? <a
                                        className="box-btn-primary w-inline-block"
                                    >
                                        <div className="btn-text-primary-outline processed">
                                            Domain request successful
            </div>
                                    </a> :
                                        null}

                                <div className="product-builder" />
                                <div className="holding-onboarding">
                                    <div className="forms w-form">
                                        {this.props.hasDomain === true && this.props.showDomainForm === false ? <div>
                                            {
                                                <div className={styles.domains} >
                                                    <a href={`https://${this.props.domainName}`} target="_blank">

                                                        <p style={{ margin: 0, fontSize: "16px" }}>{this.props.domainName}</p></a>
                                                    <button
                                                        className={styles.editBtn}
                                                        onClick={() => this.props.handleEdit(this.props.domainName)}>
                                                        Edit
                                                        </button>
                                                    <button
                                                        className={styles.deleteBtn}
                                                        onClick={() => this.props.handleDelete(this.props.domainName)}>
                                                        Delete
                                                        </button>
                                                </div>

                                            }
                                        </div> : <><form
                                            id="email-form"
                                            name="email-form"
                                            data-name="Email Form"
                                            className="update-form"
                                            onSubmit={this.props.handleSubmit}
                                        >
                                            <input
                                                value={this.props.domainName}
                                                onChange={this.props.handleChange}
                                                type="text"
                                                className="input-text w-input"
                                                maxLength={256}
                                                name="domainName"
                                                placeholder="ex: mystore.com"
                                                required
                                            />

                                            <button
                                                // onClick={this.props.handleSubmit}
                                                type="submit"
                                                value="Next"
                                                defaultValue="AD"
                                                data-wait="Please wait..."
                                                className="submit-primary w-button"
                                            >
                                                Add
                                            </button>
                                        </form>
                                                <div style={{ fontWeight: "bold", fontSize: "16px", paddingTop: "20px" }}>Don't already have a domain? <a target="_blank" href="https://domains.google/" rel="noreferrer noopener">Buy One</a></div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>}
                        {this.props.ready === true && this.props.activeDomain === false ? <DomainInstructions /> : null}
                    </div>

                </div>
            </div>
        );
    }
}

export default AddDomainForm;