import React,{ Component} from "react";
import truckIcon from "../../images/2102059_truck_construction_lorry_icon.svg";
import clockIcon from "../../images/3507760_clock_history_iconoteka_time_watch_icon.svg";
import walletIcon from "../../images/290143_cash_money_payment_wallet_icon.svg";

import priceFormaterIntl from "../helpers/priceFormaterIntl";
import getDateFromTimestamp from "../helpers/getDateFromTimestamp";

class TransactionControls extends Component {

  state = {
    updateDropdown: false,
    statusTab: true,
    shippingTab: false,
    payoutTab: false
  }

  displayStatus =()=>{

    this.setState({
      updateDropdown: false,
      statusTab: true,
      shippingTab: false,
      payoutTab: false
    })
  }

  displayShippingTab =()=>{
    this.setState({
      updateDropdown: false,
      statusTab: false,
      shippingTab: true,
      payoutTab: false
    })
  }

  
  displayPayout =()=>{
    this.setState({
      updateDropdown: false,
      statusTab: false,
      shippingTab: false,
      payoutTab: true
    })
  }

  displayDropdown = ()=>{

    let prev=this.state.updateDropdown;
      this.setState({
        updateDropdown: !prev
      })
  }

  calculateWeight = (orderList)=>{
    
    let totalWeight=0;
    console.log("totalWeight",totalWeight);

    orderList.forEach((order)=>{

      let weight=parseFloat(order?.productWeight);
      totalWeight+=weight;

    })
      
    console.log("totalWeight",totalWeight);
  }

  openLink=()=>{
    let {shippingLabelLink, labelObject}=this.state;

    if(labelObject?.content){

      console.log("labelObject",labelObject);
      var byteCharacters = atob(labelObject?.content);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return;
    }
    window.open(shippingLabelLink,"Cashaam");
    shippingLabelLink
  }


  dateTest =()=>{

    let val="2022-09-09";
    
    let timeZoneDate = new Date(`${val} GMT+0100`);
    let pureDate=new Date(`${val}`);

    let todayDate=new Date();

    this.setState({
      timeZoneDate: `${timeZoneDate}`,
      pureDate: `${pureDate}`,
      todayDate: `${todayDate}`
    })



  }


  componentDidMount() {

    let orderList=this.props.orderList;
    let order=orderList[0];
    let orderStatus=order?.orderStatus;
    let customerId=order?.customerId;
    let trackingNumber=order?.trackingNumber;

    let shippingLabelLink=order?.shippingLabelLink;
    let labelObject=order?.labelObject;

    let shippingPrice=this.props.shippingPrice;
    let date=this.props.currentTransaction?.date;
    let currency=this.props.currentTransaction?.currency;
    let carrierAccount=this.props.currentTransaction?.shippingCarrierAccount;

    let transactionStatus=order?.orderStatus;

    let instructions;
    if(transactionStatus?.toLowerCase()==="completed"){
      instructions="Awaiting to be shipped customer";
    }else if(transactionStatus?.toLowerCase()==="dispatched"){
      instructions="Awaiting to be delivered to customer";
    }


    let scheduleShipping=false;
    // let formattedDate = getDateFromTimestamp(date);
    // if(new Date('08/14/2022').valueOf() < new Date(formattedDate).valueOf()
    // && this.props.sellerCountry.toLowerCase()=="nigeria"
    // ){
    //   scheduleShipping=true;
    // }

    if(this.props.sellerCountry.toLowerCase()=="nigeria"
    ){
      scheduleShipping=true;
    }

    this.setState({
      labelObject,
      scheduleShipping,
      instructions,
      orderStatus,
      customerId,
      order,
      trackingNumber,
      shippingPrice,
      date,
      currency,
      carrierAccount,
      shippingLabelLink
    })
  }

  render() {
    return (
    <div className="orderdetailsbox">
      <div
        data-current="Tab 1"
        data-easing="ease"
        data-duration-in={300}
        data-duration-out={100}
        className="w-tabs"
      >
        <div className="pdtabmenu tabs-menu w-tab-menu" id="trans-control">
          <a
            data-w-tab="Tab 1"
            onClick={() =>this.displayStatus()}
            className={`pdtabitem w-inline-block w-tab-link ${this.state.statusTab===true?"w--current":""}`}
          >
            <img width={27} loading="lazy" src={clockIcon} alt />
            <div className="pdtabitemtext">Status</div>
          </a>
          <a data-w-tab="Tab 2" 
            onClick={() =>this.displayShippingTab()}
            className={`pdtabitem w-inline-block w-tab-link ${this.state.shippingTab===true?"w--current":""}`}
          >
            <img width={30} loading="lazy" src={truckIcon} alt />
            <div className="pdtabitemtext">Shipping</div>
          </a>
          <a data-w-tab="Tab 3" 
             onClick={() =>this.displayPayout()}
             className={`pdtabitem w-inline-block w-tab-link ${this.state.payoutTab ===true?"w--current":""}`}
          >
            <img width={20} loading="lazy" src={walletIcon} alt />
            <div className="pdtabitemtext">Payouts</div>
          </a>
        </div>
        <div className="w-tab-content">
          <div data-w-tab="Tab 1" className={`w-tab-pane ${this.state.statusTab ===true?"w--tab-active":""}`}>
            <div className="odtabcontent">
              {this.state.instructions?
              <div className="pdstatustitle" style={{border: "none"}}>
              <div className="pdstatus">
                <div>{this.state.instructions}</div>
              </div>
            </div>: null
            }
              

              <div className="odupdatestatuswrap">
                <div className="form-block-3 w-form tracking-btns" style={{display: "flex"}}>

                  {this.state.orderStatus==="completed"?
                    <div
                      onClick={() => this.props.showDeliveryInfo()}
                      style={{ cursor: "pointer", backgroundColor: "#6640aa" }}
                      className="updatestatusbutton lightseagreen w-button"
                    >
                      <div className="text-btn-primary">Deliver Order Yourself</div>
                    </div>
                  :null}

                  {/* {!this.state.scheduleShipping?
                    <div
                    className="updatestatusbutton lightseagreen w-button"
                      type="button"
                      onClick={() =>
                        this.props.displayShippingLabel(
                          this.props.sellerId,
                          this.props.transactionId,
                          this.props.orderList,
                          this.props.orderIds
                        )
                      }
                    >
                      <div className="text-btn-primary">Shipping Label</div>
                      
                    </div>

                    :
                    (!this.state.shippingLabelLink && !this.state.labelObject?.content?
                      <div
                        onClick={() => this.props.showScheduleDelivery()}
                        style={{ cursor: "pointer" }}
                        className="updatestatusbutton lightseagreen w-button"
                      >
                        <div className="text-btn-primary">Schedule Delivery</div>
                      </div>
                      :
                      <div
                        onClick={() => this.props.showShippingInstructions()}
                        style={{ cursor: "pointer" }}
                        className="updatestatusbutton lightseagreen w-button"
                      >
                        <div className="text-btn-primary">View Shipping Label</div>
                      </div>
                    )
              
                  } */}

                  {this.state.shippingLabelLink || this.state.labelObject?.content?
                    <div
                      onClick={() => this.props.showShippingInstructions()}
                      style={{ cursor: "pointer",backgroundColor: "#6640aa" }}
                      className="updatestatusbutton lightseagreen w-button"
                    >
                      <div className="text-btn-primary">View Shipping Label</div>
                    </div>
                  :null}


                  <div
                    data-hover="false"
                    data-delay={0}
                    className="updatestatusbutton lightseagreen withmargin w-dropdown"
                    onClick={() =>this.displayDropdown()}
                  >
                    <div className="dropdown-toggle w-dropdown-toggle">
                      <div className="w-icon-dropdown-toggle" />
                      <div>Update Status</div>
                    </div>
                    <nav className={`dropdown-list-2 w-dropdown-list ${this.state.updateDropdown===true?"w--open":""}`}>
                      {this.state.orderStatus==="completed"?
                        <a  onClick={()=>this.props.markAsShipped(this.props.currentTransaction, this.state.customerId)}
                          className="updatestatusitem w-dropdown-link">
                        Mark as shipped
                      </a>:
                      null
                      }
                      {this.state.orderStatus==="dispatched" ?
                        <a onClick={()=>this.props.markAsDelivered(this.props.currentTransaction, this.state.customerId)} className="updatestatusitem w-dropdown-link">
                        Mark as delivered
                      </a>:null
                      }
                    </nav>
                  </div>

                  {this.props.customerEmail?
                    <a 
                      href = {`mailto: ${this.props.customerEmail}?subject=Delivery Status For Your Cashaam Order`}
                      // className="updatestatusitem w-dropdown-link"
                      style={{ cursor: "pointer", backgroundColor: "#7dda86", fontSize: "16px" }}
                            className="updatestatusbutton lightseagreen w-button"
                    >
                      Contact customer
                    </a>
                  :null}


                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
              </div>




      
              {/* {this.state.todayDate?

                  <div style={{display: "flex",
                  flexDirection: "column"}}>

                    <div style={{fontSize: "14px",
                          marginBottom: "7px"}}
                      >Today's Date = {this.state.todayDate}</div>
                    <div style={{fontSize: "14px",
                          marginBottom: "7px"}}
                          >Date "2022-09-07" = {this.state.pureDate}</div>
                    <div style={{fontSize: "14px",
                          marginBottom: "7px"}}
                          >Date "2022-09-07 GMT+0100" = {this.state.timeZoneDate}</div>

                  </div>
                  :null

              } */}


              {/* <div className="orderdetailswhitesmoke justifybetween">
                <div className="ordernotetitle">Order Note</div>
                <a href="#" className="addordernote w-inline-block">
                  <div>+</div>
                </a>
              </div>
              <div className="odordernote">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse varius enim in eros elementum tristique. Duis
                  cursus, mi quis viverra ornare, eros dolor interdum nulla, ut
                  commodo diam libero vitae erat. Aenean faucibus nibh et justo
                  cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus
                  tristique posuere.
                </p>
              </div> */}
            </div>
          </div>
          <div data-w-tab="Tab 2" className={`w-tab-pane ${this.state.shippingTab ===true?"w--tab-active":""}`}>
            <div className="tabcarriers">
              <div className="productlistwrap">
                <div className="carrierslisttitle">
                  <div className="productlisttitletext">
                    <div>Date</div>
                  </div>
                  <div className="productlisttitletext">
                    <div>Carrier</div>
                  </div>
                  <div className="productlisttitletext">
                    <div>Weight</div>
                  </div>
                  <div className="productlisttitletext">
                    <div>Shipping Cost</div>
                  </div>
                  <div className="productlisttitletext">
                    <div>Tracking Number</div>
                  </div>
                </div>
                <div className="carrierslistitem">
                  <div className="wrapbox">
                    <div className="productlisttitletext mobilepltext">
                      <div>Date</div>
                    </div>
                    <div>{getDateFromTimestamp(this.state.date)}</div>
                  </div>
                  <div className="wrapbox">
                    <div className="productlisttitletext mobilepltext">
                      <div>Carrier</div>
                    </div>
                    <div>{this.props.getCarrierName(this.state.carrierAccount)}</div>
                  </div>
                  <div className="wrapbox">
                    <div className="productlisttitletext mobilepltext">
                      <div>Weight</div>
                    </div>
                    <div>{this.props.productWeight} lb</div>
                  </div>
                  <div className="wrapbox">
                    <div className="productlisttitletext mobilepltext">
                      <div>Shipping Cost</div>
                    </div>
                    <div>{priceFormaterIntl(this.props.shippingPrice, this.state.currency)}</div>
                  </div>
                  <div className="wrapbox">
                    <div className="productlisttitletext mobilepltext">
                      <div>Tracking Number</div>
                    </div>
                    <div>{this.state.trackingNumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div data-w-tab="Tab 3" className={`w-tab-pane ${this.state.payoutTab ===true?"w--tab-active":""}`}>
            <div className="tabpayouts">
              
              <div className="payoutsitem">
                <div className="payoutsitemtop">
                  <div>Balance</div>
                  
                </div>
                <div className="payoutsitemcontent">
                  {this.props.payoutCurrency=="NGN"?
                    <div className="payoutsprice">{priceFormaterIntl(this.props.sellerPayout,this.props.payoutCurrency)}</div>
                  :
                    <div className="payoutsprice">{priceFormaterIntl(this.props.sellerPayout,this.props.transactionCurrency)}</div>
                  }
                  
                  <div className="payoutstext">Available</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
}

export default TransactionControls;
