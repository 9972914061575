import React from "react";

function DefaultPost(props) {
  return (
    <div style={{ position: "relative" }} className={`jss224`}>
      <div className={`jss225`} />
      <div className="jss233">
        <div
          role="textbox"
          aria-multiline="true"
          className="jss236 jss226 jss238"
          data-testid="MarkedEditable"
          data-slate-editor="true"
          data-slate-node="value"
          zindex={-1}
          style={{
            position: "relative",
            outline: "none",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        >
          <div
            data-slate-node="element"
            style={{
              position: "relative",
              display: "flex",
              flex: 1,
              flexDirection: "row",
            }}
          >
            <div
              style={{ alignItems: "flex-start" }}
              className="holder-avatar ai-avatars"
            >
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "white",
                }}
                className="user-avatar"
              >
                <img src="https://res.cloudinary.com/cashaam/image/upload/v1685499319/icons/sparkle_resting_v2_1ff6f6a71f2d298b1a31_khotyb.gif" />
              </div>
            </div>

            <div>
              <div
                data-slate-node="element"
                className="ai-post-title jss239"
                style={{ position: "relative" }}
              >
                <span data-slate-node="text">
                  <span data-slate-leaf="true">
                    <span data-slate-string="true">
                    This is Cashaam AI, your creative and helpful collaborator. 
                    Tell me your product or brand name to generate your Ads Strategy
                    </span>
                  </span>
                </span>
              </div>

              <div
                data-slate-node="element"
                className="jss239"
                style={{ position: "relative" }}
              >
                <span data-slate-node="text">
                  <span data-slate-leaf="true">
                    <span data-slate-string="true">
                      Not sure where to start? Here are examples:
                    </span>
                  </span>
                </span>
              </div>

              <div
                data-slate-node="element"
                className="jss239 ai-suggestion"
                style={{ position: "relative" }}
                onClick={()=>props.pickSuggestion("Jamaican Wigs")}
              >
                <span data-slate-node="text">
                  <span data-slate-leaf="true">
                    <span data-slate-string="true">
                    Jamaican Wigs
                    </span>
                  </span>
                </span>
              </div>

              <div
                data-slate-node="element"
                className="jss239 ai-suggestion"
                style={{ position: "relative" }}
                onClick={()=>props.pickSuggestion("Aso Ebi")}
              >
                <span data-slate-node="text">
                  <span data-slate-leaf="true">
                    <span data-slate-string="true">
                    Aso Ebi
                    </span>
                  </span>
                </span>
              </div>
              <div
                data-slate-node="element"
                className="jss239 ai-suggestion"
                style={{ position: "relative" }}
                onClick={()=>props.pickSuggestion("Weavon")}
              >
                <span data-slate-node="text">
                  <span data-slate-leaf="true">
                    <span data-slate-string="true">
                    Weavon
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultPost;
