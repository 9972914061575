const getUserLocation = () => {
  return new Promise(async (resolve, reject) => {
    try {
      //check if the user had visited before and we already stored their location data
      let locationData = JSON.parse(
        localStorage.getItem("cashaam_user_location")
      );

      if (!locationData || typeof locationData === "undefined") {
        var resp = await fetch(
          "https://api.ipdata.co?api-key=2901187ca9e5bac50b9025861e447a4597f204024e6075cb006c0419"
        );
        let result = await resp.json();

        //store user location info in browser storage to be used on other pages
        localStorage.setItem("cashaam_user_location", JSON.stringify(result));
        var country_code = result.country_code;
        resolve(result);
        //   if (country_code === "NG") {
        //     location.replace("https://www.cashaam.ng");
        //   }
      } else {
        var country_code = locationData.country_code;
        resolve(locationData);
        //   if (country_code === "NG") {
        //     location.replace("https://www.cashaam.ng");
        //   }
      }
    } catch (error) {
      console.log("error getting user location", error);
      reject(error);
    }
  });
};
export default getUserLocation;
