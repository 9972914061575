import firebase from "../../firebaseConfig";

const formatDate = (date) => {
  //formats date with format 2020-9-20 to the correct format 2020-09-20 
  //or 2020-9-2 to the correct format 2020-09-02
  //or 20-09-02 to the correct format 2020-09-02

  let dateArray = date.split("-");
  let year = dateArray[0];
  let month = dateArray[1];
  let day = dateArray[2];

  if (year.length < 4) {
    year = "20" + year;
  }
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  let result = year + "-" + month + "-" + day;
  return result;
}

const getPeriodicOrderInfo = (id, days, country) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      var targetDay = new Date(
        Date.now() - parseInt(days) * 24 * 60 * 60 * 1000
      ); // change to  var targetDay = new Date(Date.now()) for Sales targetDay, # 30 days change 7 to 30days
      var targetDate =
        targetDay.getFullYear() +
        "-" +
        (targetDay.getMonth() + 1) +
        "-" +
        targetDay.getDate();
      var targetTime =
        targetDay.getHours() +
        ":" +
        targetDay.getMinutes() +
        ":" +
        targetDay.getSeconds();
      var targetDateTime = targetDate + " " + targetTime;

      var today = new Date(Date.now());
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      var dateTime = date + "" + time;

      let transactions = await db
        .collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("transactions")
        // .where("date", "<", dateTime)
        // .where("date", "<", targetDateTime)
        .orderBy("date", "desc")
        .get();

        
        let allOrder= await db.collection("sellersAccounts")
        .doc(id)
        .collection("stores")
        .doc(id)
        .collection("orders")
        .get();

        // console.log("allOrder",allOrder)
        const allOrderList = allOrder.docs.map((order) => order.data());
        console.log("allOrderList",allOrderList)

      if (transactions.empty) {
        console.log(`No document found for revenues for ${days} days period`);
        resolve({ count: 0, totalAmount: 0 });
      }
      let orders = [];
      let count = 0;
      let totalAmount = 0;
      let transactionPercentFee = country === "nigeria" ? 0.015 : 0.03;

      let orderAmount=0;
      let orderCurrency=null;
      await transactions.forEach((doc) => {
        let order = doc.data();
        let orderDateAndTime = order.date.split(" ");
        let orderDate = new Date(formatDate(orderDateAndTime[0]));

        if (orderDate > new Date(formatDate(targetDate))) {


          let orderIdList=order?.orderIds;
          console.log("relevant OrderIds:",orderIdList)

          let relevantOrderList= allOrderList.filter(order => orderIdList.includes(order.orderId));
          console.log("relevant relevantOrderList:",relevantOrderList)

          relevantOrderList.map((relevantOrder)=>{
            orderCurrency=orderCurrency || relevantOrder?.currency;
            let productPrice=parseFloat(relevantOrder.productPrice);

            let orderQuantity=relevantOrder?.orderQuantity? relevantOrder.orderQuantity: 1;
            orderAmount+=(productPrice*orderQuantity);
            console.log("current orderAmount",orderAmount)
          })

           
          orders.push(order);
          // let itemCount = order.orderIds ? order.orderIds.length : 1;
          count++;
          // totalAmount = totalAmount + (order.productPrice - 1.5% for nigeria, - 3% for united states)
          let totalTransactionPrice = parseFloat(order.amount);
          let transactionFee = totalTransactionPrice * transactionPercentFee;

          //using parseInt because the payment processors used do not take decimals
          //deviding by 100 because amounts are stored in pennies and cobo
          totalAmount += parseInt((totalTransactionPrice - transactionFee) / 100);
        }
      });

      console.log("total totalAmount",totalAmount)
      console.log("total orderAmount",orderAmount)
      resolve({ totalAmount:orderAmount , count, orderCurrency });
    } catch (error) {
      console.log("error getting dispatched orders", error);
      reject(error);
    }
  });
};
export default getPeriodicOrderInfo;
