import React,{useRef} from "react";
import ConnectorItem from "./ConnectorItem";

function AIContector(props) {
    const clickToGenerate = useRef();

    const scrollToView = (category) => {
        clickToGenerate.current.scrollIntoView({ behavior: 'smooth', block:"center" });
        props.selectCategory(category)
    };

  return (
    <div className="sxPm347 oKk0p7r---direction-10-horizontal oKk0p7r---alignItems-6-center oKk0p7r---justifyContent-6-middle"
    style={{width:"100%"}}
    >
      <div
        className="sxPm347 oKk0p7r---direction-8-vertical"
        style={{ marginTop: 48, width: "100%" }}
      >
        <div className="sxPm347 oKk0p7r---direction-10-horizontal oKk0p7r---alignItems-6-center oKk0p7r---justifyContent-6-middle">
          <h2
            data-hook="title"
            className="svbG8N4 o3k8QhU---appearance-2-H2"
            data-appearance="H2"
            data-light="false"
            data-mask="false"
            style={{fontSize:"27px"}}
          >
            What’s your goal?
          </h2>
        </div>
        <div
          className="sxPm347 oKk0p7r---direction-10-horizontal oKk0p7r---alignItems-6-center oKk0p7r---justifyContent-6-middle"
          style={{ marginTop: 6 }}
        >
          <span
            data-mask="false"
            className="s1-qb7n o1G-RQh---size-6-medium o1G-RQh---skin-8-standard o1G-RQh---weight-4-thin o1G-RQh---list-style-9-checkmark"
            style={{fontSize: "17px",textAlign: "center"}}
            data-size="medium"
            data-secondary="false"
            data-skin="standard"
            data-light="false"
            data-weight="thin"
            data-list-style="checkmark"
          >
            Before creating your Ad, select your Objective below then “Click to Generate”
          </span>
        </div>
        <div
          className="sxPm347 oKk0p7r---direction-8-vertical"
          style={{ marginTop: 30 }}
        >
          {/* <div
            data-gap="24px"
            className="s12_QBK"
            style={{ gap: 24, gridAutoRows: "auto" }}
          > */}
          <div className="ad-goals">
            {props.adsRecommendation?.map((ads, index) => {
                return (
                    <ConnectorItem 
                    title={ads.title}
                    category={ads.category}
                    description={ads.description}
                    icon={ads.icon}
                    selectCategory={()=>scrollToView(ads.category)}
                    selectedCategory={props.selectedCategory}
                    />
                );
              })}

            
          </div>
          <div
            className="sxPm347 oKk0p7r---direction-10-horizontal oKk0p7r---alignItems-6-center oKk0p7r---justifyContent-6-middle"
            style={{ marginTop: 40, marginBottom: 48 }}
          >
            <a
            href={`/seller/dashboard/content?category=${props.selectedCategory}`}
              data-size="large"
              className="s2TzqvB sPKtd3I s6go5Ij o1J2S-7---skin-8-standard o1J2S-7---priority-7-primary o1J2S-7---size-5-large"
              data-hook="connect-page-cta"
              type="button"
              tabIndex={0}
              id="click-to-generate"
              ref={clickToGenerate}
            >
              <span className="s10cpED">Click to Generate</span>
            </a>
          </div>
          <div />
          <div />
        </div>
      </div>
    </div>
  );
}

export default AIContector;
