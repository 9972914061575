import React from "react";
import priceFormaterIntl from "../helpers/priceFormaterIntl";
import ProductItem from "./ProductItem";
import logo from "../../images/usps-logos.png";

class ProductDetails extends React.Component {
  state = {
    orderList: [],
  };

  componentDidMount() {
    let orderList = [];
    orderList = this.props.orderList;

    this.setState({
      orderList,
    });
  }

  render() {
    return (
      <div className="orderdetailsbox">
        <div className="orderdetailstop">
          <div>Products</div>
          <div className="productnumber"></div>
        </div>
        <div className="orderdetailscontent">
          <div className="productlistwrap">
            <div className="productlisttitle">
              <div className="productlisttitletext">
                <div>Product</div>
              </div>
              <div className="productlisttitletext">
                <div className="empty" />
              </div>
              <div className="productlisttitletext">
                <div>Quantity</div>
              </div>
              <div className="productlisttitletext">
                <div>Base Price</div>
                {/* <div className="productlistsubtitle">Tax included</div> */}
              </div>

              <div className="productlisttitletext">
                <div>Variant</div>
              </div>
              <div className="productlisttitletext">
                <div>Total</div>
                {/* <div className="productlistsubtitle">Tax included</div> */}
              </div>

            </div>
            

            {this.props.orderList?.map((order,index) => {
              return (
                <ProductItem
                  key={index}
                  productDescription={order?.productDescription}
                  productName={order?.productName}
                  orderQuantity={order?.orderQuantity}
                  image={order?.mainImage?.main}
                  productPrice={order?.productPrice}
                  productStock={order?.productStock}
                  currency={order?.currency}
                  selectedSize={
                    order.selectedSizes && typeof order.selectedSizes === 'string' 
                    ? order.selectedSizes
                    :(order.selectedSizes && order.selectedSizes[0] !== "N/A"
                        ? order.selectedSizes[0]
                        : null)
                  }
                  selectedColor={
                    order.selectedColors && typeof order.selectedColors === 'string' 
                    ?order.selectedColors
                    :(order.selectedColors && order.selectedColors[0] !== "N/A"
                      ? order.selectedColors[0]
                      : null)
                  }
                  selectedExtraVariants={
                    order.selectedExtraVariants ? order.selectedExtraVariants : []
                  }
                />
              );
            })}
          </div>
          <div className="itemsperpage">
            <div className="itemsperpagewrap">
              <div>Items per page:</div>
              <div className="form-block-4 w-form">
                <form
                  id="email-form-3"
                  name="email-form-3"
                  data-name="Email Form 3"
                  onSubmit={e => e.preventDefault()}
                >
                  <input
                    type="text"
                    className="text-field w-input"
                    maxLength={256}
                    name="name-2"
                    data-name="Name 2"
                    value={5}
                    id="name-2"
                  />
                </form>            
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
            <div className="row lr-arrow mobile">
              <a href="#" className="odpag leftodpag w-inline-block" />
              <a href="#" className="odpag rightodpag w-inline-block" />
            </div>
          </div>
          <div className="orderdetailswhitesmoke">
            <div className="odproductpricegrid">
              <div className="odproductpricewrap rightborder">
                <div className="leftbox">
                  <div className="odproductpricetitle">Products</div>
                  <div className="orderprice invertcolors">
                    <div>{this.props.orderList.length}</div>
                  </div>
                </div>
                <div className="centerbox">
                  <div className="dhltext">Delivery by</div>
                  {this.props.getCorrectShippingType(this.props.shippingCarrierAccount)}
                    
                </div>
              </div>
              <div className="odproductpricewrap transaction-prices">
                <div className="centerbox full-center">
                  <div className="shippingfeetext">Shipping Fee</div>
                  <div>{priceFormaterIntl(this.props.shippingPrice, this.props.transactionCurrency)}</div>
                </div>
                <div className="centerbox full-center">
                  <div className="shippingfeetext">Taxes &amp; Fees</div>
                  <div>{priceFormaterIntl(this.props.taxAndFee, this.props.transactionCurrency)}</div>
                </div>
                <div className="rightbox full-center">
                  <div className="odproductpricetitle">Total</div>
                  <div className="orderprice">
                    <div>{priceFormaterIntl(this.props.transactionAmount, this.props.transactionCurrency)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.props.discountUsed && this.props.discountUsed?.discountType !="N/A"?
            <div className="orderdetailswhitesmoke">
              <div className="discount-container">
                <div style={{fontWeight: "bold"}}>Discount used</div>
                <div style={{textTransform: "uppercase"}}>Code: {this.props.discountUsed?.discountType}</div>
                {this.props.discountUsed?.discountType=="dollar" || this.props.discountUsed?.discountType=="dollars"?
                  <div>Value : ${this.props.discountUsed?.discountValue}</div>
                  :null
                }

                {this.props.discountUsed?.discountType=="percent"?
                  <div>Value : {this.props.discountUsed?.discountValue}%</div>
                  :null
                }

                {this.props.discountUsed?.discountType=="naira"?
                  <div>Value : ₦{this.props.discountUsed?.discountValue}</div>
                  :null
                }
                

              </div>
            </div>
          :null}
        </div>
      </div>
    );
  }
}

export default ProductDetails;
