import firebase from "../../firebaseConfig";

const fulfillOrder = (sellerId, customerId, orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      const sellerRef = db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("orders")
        .doc(orderId);
      const buyersRef = db
        .collection("buyersAccounts")
        .doc(customerId)
        .collection("orders")
        .doc(orderId);

      await sellerRef.update({ orderStatus: "fulfilled" });
      await buyersRef.update({ orderStatus: "fulfilled" });
      resolve(true);
    } catch (error) {
      console.log("error fullfilling order", error);
      reject(error);
    }
  });
};
export default fulfillOrder;
