import React, { Component }  from "react";

class FeaturePromo extends Component {


  render() {
    return (
      <div style={{ display: "flex" }} className="tint-background">
        <div className="pop-container calendar-pop">
          <div
            style={{ display: "flex", overflow: "scroll", maxHeight: "90vh" }}
            className="pop-us-ship-label"
          >
            <div className="pop-header">
              <div className="text-main">{this.props.featureHeading}</div>
              <img onClick={()=> this.props.hideNewFeature()} style={{height:"35px"}} src="https://res.cloudinary.com/cashaam/image/upload/v1661174410/logos/red-x-removebg-preview_tmupc9.png" />
              
            </div>
            <div className="pop-content">
              <div className="sub-title">
              {this.props.featureTitle}
              </div>
              <div className="pop-text bold">
              {this.props.featureDescription}
              </div>

              <div className="">
                <iframe
                style={{ width: "-webkit-fill-available"}}
                  className="shipping-tutorial"
                  src={this.props.featureVideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  // allowfullscreen
                  allowFullScreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen" 
                  msallowfullscreen="msallowfullscreen" 
                  oallowfullscreen="oallowfullscreen" 
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>

              </div>

              
            </div>
            

            <div className="pop-footer">
              {this.props.featureLink?
                  <a
                  href={this.props.featureLink}
                  style={{ cursor: "pointer" }}
                  className="btn-secondary"
                >
                    <div className="text-btn-tertiary">Take Me There</div>
                  </a>
              :
                <div
                  onClick={()=> this.props.hideNewFeature()}
                  style={{ cursor: "pointer" }}
                  className="btn-secondary"
                >
                  {this.props.activated?
                  <div className="text-btn-tertiary">Done</div>
                  :
                  <div className="text-btn-tertiary">Get Started</div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturePromo;
