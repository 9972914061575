import React, { Component, useState, useRef, useEffect } from "react";
import firebase from "../../../../../firebaseConfig";

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import send_icon from "../../../../../images/send-icon.png";
import back_btn from "../../../../../images/back-btn.png";
import anon_buyer from "../../../../../images/anon_buyer.png";
import profilePic from "../../../../../images/prof-pic.jpg"
import sendMessage from "../../../../helpers/sendMessage"

function ChatContent(props){

  const messagesEndRef = useRef();

  const [currentChat, setCurrentChat] = useState("undefined");
  const [formValue, setFormValue] = useState('');

  const auth = firebase.auth();
  const firestore = firebase.firestore();

  
  var messagesRef = firestore.collection("messages").doc(currentChat).collection("threads")

  const query = messagesRef.orderBy('createdAt');

  const [messages, loading, error] = useCollectionData(query, { idField: 'id' });



  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const sendText = async (e) => {
    e.preventDefault();


    let sellerId=props.sellerId;
    let senderId=sellerId;
    let sellerAvatar=props.sellerAvatar;
    let sellerName=props.sellerName;
    

    let buyerId=props.buyerId;
    let receiverId=buyerId;
    let buyerAvatar =props.buyerAvatar;
    let buyerName=props.buyerName;


    console.log("sellerAvatar",sellerAvatar);

    let msg=formValue;

    

    console.log("sendMessage",{ sellerId, buyerId, senderId, receiverId, msg, sellerAvatar, buyerAvatar, sellerName, buyerName})

    let result=await sendMessage(sellerId, buyerId, senderId, receiverId, msg, sellerAvatar, buyerAvatar, sellerName, buyerName);
    console.log("success:",result);

    if(result===true){
      setFormValue('');
    }
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });

    

  }


  useEffect(() => {

    console.log("useEffect");
    
    setCurrentChat(props.currentChat);
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });

  },[props.currentChat,messages]);
  
    return (
      <div className={`main__chatcontent ${props.showChat}`}>
        <div className="content__header">
          
            <div className="back-btn" onClick={props.goToMenu}>
              <img src="https://res.cloudinary.com/cashaam/image/upload/v1670862773/icons/back-btn-removebg-preview_cbupzd.webp" />
            </div>
       

          <div className="blocks">
            <div className="current-chatting-user">
              <Avatar
                isOnline="active"
                image={profilePic}
                buyerName={props.buyerName?props.buyerName:"Anonymous"}
              />
              <p>{props.buyerName?props.buyerName:"Anonymous"}</p>
            </div>
          </div>

          <div className="blocks">
            {/* <div className="settings">
              <button className="btn-nobg">
                <i className="fa fa-cog"></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="content__body">
          <div className="chat__items">
            {messages && messages?.map((text, index) => {
              return (
                <ChatItem
                  animationDelay={2}
                  key={text.key}
                  user={text.sellerId===text.senderId? "me" : "other"}
                  msg={text.msg}
                  image={text.image}
                  createdAt={text.createdAt}
                />
              );
            })}
            <div ref={messagesEndRef} />
          </div>

          {loading?
            <div className="loading-message">
                <Loader
                  type="ThreeDots"
                  color="#7455c1"
                  height={10}
                  width={30}
                />
              </div>
            :null }
        </div>
        <div className="content__footer">
          <div className="sendNewMessage">
            {/* <button className="addFiles">
              <i className="fa fa-plus"></i>
            </button> */}

            <form className="message-form" onSubmit={sendText}>
            <input
              type="text"
              placeholder="Type a message here"
              value={formValue} onChange={(e) => setFormValue(e.target.value)}
            />
            <button className="btnSendMsg" id="sendMsgBtn" type="submit" disabled={!formValue}>
              <img src="https://res.cloudinary.com/cashaam/image/upload/v1670858367/icons/image_vnq3ff.webp" />
            </button>
            </form>
          </div>
        </div>
      </div>
    );
  
}

export default ChatContent;
