import firebase from "../../firebaseConfig";
import addToCloudinaryDeleteList from "./addToCloudinaryDeleteList";

const deleteSmallBanner = (sellerId, url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const db = firebase.firestore();
      await db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .update({
          customSmallBanner: firebase.firestore.FieldValue.delete(),
        });

      await addToCloudinaryDeleteList(url);
      resolve(true);
    } catch (error) {
      console.log("error uploading big banner", error);
      reject(error);
    }
  });
};
export default deleteSmallBanner;
