import firebase from "../../firebaseConfig";
const db = firebase.firestore();

export const saveDomain = (domain, slug) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await db.collection("customDomains").where("slug", "==", slug).get();


            if (result.docs[0]) {

                if (domain === result.docs[0].data().domain) {
                    console.log("same domain used");
                    return resolve(domain)
                }


                result.docs[0].ref.set({
                    domain: domain,
                    active: false
                }, { merge: true });
                return resolve(domain);

            }

            await db
                .collection("customDomains").add({
                    domain: domain,
                    slug: slug,
                    active: false
                });


            resolve(domain);
        } catch (error) {
            console.log("error adding custom domain", error);
            reject(error);
        }
    });
};

export const getDomain = (slug) => {
    return new Promise(async (resolve, reject) => {
        try {

            let result = await db.collection("customDomains").where("slug", "==", slug).get();
            if (result.docs.length === 0) {
                console.log("domain not found for slug:", slug)
                return resolve(false)
            }
            const domainData = result.docs[0].data();

            resolve(domainData);
        } catch (error) {
            console.log("error getting domain", error);
            reject(error);
        }
    });
};

export const deleteDomain = (domain) => {
    return new Promise(async (resolve, reject) => {
        try {
            let result = await db.collection("customDomains").where("domain", "==", domain).get();
            if (result.docs.length === 0) {
                console.log("domain not found:", domain);
                return reject("domain not found");
            }
            result.docs[0].ref.delete();
            console.log("domain deleted:", domain);
            resolve(true);
        } catch (error) {
            console.log("error deleting domain", error);
            reject(error);
        }
    });
};
