import React from "react";
import {NAIRA_EXCHANGE} from "../../../../secrets";

class TopupAds extends React.Component {

    render() {
        return (
            <div style={{display: "flex"}} className="popupmodal">

              <div style={{display: "flex"}} className="popup secondstep">
                <div
                  data-w-id="a3788408-9a71-6de8-31ec-24277824e6ff"
                  onClick={()=>this.props.hideTopup()}
                  className="closepopup"
                />
                <div className="popuptitle">
                  How much you want to add to your Virtual wallet?
                </div>
                <div className="addbalancewrap">
                  <div className="addbalanceicon" />
                  <div className="addbalanceitemwrap">
                    <div className="w-form">
                      <form
                        id="email-form-3"
                        name="email-form-3"
                        data-name="Email Form 3"
                        method="get"
                        className="form"
                        onSubmit={e => e.preventDefault()}
                      >
                        <div 
                            onClick={(e)=>this.props.quickPayment(e, 5)}
                            className="addbalancebuttonitem"
                            >
                          <label className="addbalancebuttonfield w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom addbalance w-radio-input" />
                            <input
                              type="radio"
                              name="Radio"
                              id="radio-6"
                              defaultValue="Radio"
                              data-name="Radio 6"
                              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                            />
                            <span
                              className="addbalancedollarcost w-form-label"
                              htmlFor="radio-6"
                            >
                              $5
                            </span>
                          </label>
                          <div className="addbalancecost">₦ 3,000</div>
                        </div>
                        <div onClick={(e)=>this.props.quickPayment(e,10)}
                            className="addbalancebuttonitem">
                          <label className="addbalancebuttonfield w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom addbalance w-radio-input" />
                            <input
                              type="radio"
                              name="Radio"
                              id="radio-6"
                              defaultValue="Radio"
                              data-name="Radio 6"
                              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                            />
                            <span
                              className="addbalancedollarcost w-form-label"
                              htmlFor="radio-6"
                            >
                              $10
                            </span>
                          </label>
                          <div className="addbalancecost">₦ 6,000</div>
                        </div>
                        <div onClick={(e)=>this.props.quickPayment(e,20)}
                            className="addbalancebuttonitem">
                          <label className="addbalancebuttonfield w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom addbalance w-radio-input" />
                            <input
                              type="radio"
                              name="Radio"
                              id="radio-6"
                              defaultValue="Radio"
                              data-name="Radio 6"
                              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                            />
                            <span
                              className="addbalancedollarcost w-form-label"
                              htmlFor="radio-6"
                            >
                              $20
                            </span>
                          </label>
                          <div className="addbalancecost">₦ 12,000</div>
                        </div>
                        <div onClick={(e)=>this.props.quickPayment(e,50)}
                            className="addbalancebuttonitem">
                          <label className="addbalancebuttonfield w-radio">
                            <div className="w-form-formradioinput w-form-formradioinput--inputType-custom addbalance w-radio-input" />
                            <input
                              type="radio"
                              name="Radio"
                              id="radio-6"
                              defaultValue="Radio"
                              data-name="Radio 6"
                              style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                            />
                            <span
                              className="addbalancedollarcost w-form-label"
                              htmlFor="radio-6"
                            >
                              $50
                            </span>
                          </label>
                          <div className="addbalancecost">₦ 30,000</div>
                        </div>
                        <div className="addbalanceitem">
                          <input
                            type="number"
                            className="customaddbalance w-input"
                            maxLength={256}
                            name="name-2"
                            data-name="Name 2"
                            placeholder="$                           -"
                            id="name-2"
                            value={this.props.topupAmount}
                            onChange={this.props.changeAmount}
                          />
                          {this.props.topupAmount?
                            <div className="customaddbalancecost">{parseFloat(this.props.topupAmount)*NAIRA_EXCHANGE}</div>
                            :
                            <div className="customaddbalancecost">-</div>
                            }
                        </div>
                      </form>
                      <div className="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="popupbuttonwrap justify-between">
                    {this.props.topupAmount?
                        <div className="addbalancefee">
                            <div>FEE</div>
                            <div className="addbalancefeecost">${this.props.topupAmount*0.03}</div>
                        </div>
                  :null}

                  {this.props.topupAmount?
                    <a
                    data-w-id="a3788408-9a71-6de8-31ec-24277824e733"
                    onClick={()=>this.props.makePayment()}
                    className="popupbutton w-inline-block"
                    >
                        <div>Top Up</div>
                    </a>
                    :
                    <a
                        data-w-id="a3788408-9a71-6de8-31ec-24277824e733"
                        style={{backgroundColor: "gray"}}
                        className="popupbutton w-inline-block"
                    >
                    <div>Top Up</div>
                  </a>
                  }
                  
                </div>
              </div>
              <div className="popup thirdstep">
                <div
                  data-w-id="a3788408-9a71-6de8-31ec-24277824e737"
                  className="closepopup"
                />
                <div className="payment" />
              </div>
            </div>
          );
    }
}


export default TopupAds;
