import React from "react";
import whatsAppIcon from '../../custom-images/whatsapp-icon.png';

const UpdateBankFailed = (props) => {
  return (
    <div className="section-onboarding">
      <div className="onboarding-steps">
        <div className="block-title-1">
          <div className="icon-failed w-embed">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-alert-circle"
              width={84}
              height={84}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <circle cx={12} cy={12} r={9} />
              <line x1={12} y1={8} x2={12} y2={12} />
              <line x1={12} y1={16} x2="12.01" y2={16} />
            </svg>
          </div>
          <h4 className="h4">Your BVN validation was not successful</h4>
        </div>
        <div className="microcopy-signup">
          Validating the BVN and the information you provided was not successful.

          Please verify your Account Number is Linked to the BVN. If this issue persists <a href="https://support.cashaam.com" target="_blank" >Contact Us via WhatsApp<img style={{ maxWidth: "25px", marginLeft: "5px" }} src={whatsAppIcon} /></a>
        </div>
        <div className="holder-btn">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => props.retry()}
            className="btn-primary"
          >
            <div className="text-btn-primary">Retry</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateBankFailed;
