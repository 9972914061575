import React from 'react';

function SubscriptionNavBar(props) {
    return (
        <div className="static_pricing-redesign__pricing-tabs-sticky-wrapper">
        <form
          name="pricing-redesign-pricing-tabs"
          __bizdiag={635717905}
          __biza="W___"
          onSubmit={(e)=>{e.preventDefault()}}
        >
          <legend className="visuallyhidden">select a plan</legend>
          <fieldset className="pricing-redesign__pricing-tabs-container">

          {props.personal?
                <div className="pricing-redesign__input-wrapper">
                  <input
                    type="radio"
                    name="static-pricing-redesign-pricing-tabs"
                    id="pricing-tab-professional"
                    defaultValue="professional"
                    className={`pricing-redesign__pricing-tab-input`}
                    onClick={()=>props.changeTab("Personal")}
                  />
                  <label
                    className={`pricing-redesign__pricing-tab pricing-redesign__pricing-tab--professional ${props.activeTab=="Personal"?"clicked-tab":""}`}
                    htmlFor="pricing-tab-professional"
                  >
                    {props.downgrade?
                      "Downgrade"
                      :
                      "Personal"
                    }
                  </label>
                </div>
              :null}

            {props.monthly?
            <div className="pricing-redesign__input-wrapper">
                <input
                    type="radio"
                    name="static-pricing-redesign-pricing-tabs"
                    id="pricing-tab-basic"
                    defaultValue="basic"
                    defaultChecked="checked"
                    className={`pricing-redesign__pricing-tab-input `}

                    onClick={()=>props.changeTab("Monthly")}
                />
                <label
                    className={`pricing-redesign__pricing-tab pricing-redesign__pricing-tab--basic ${props.activeTab=="Monthly"?"clicked-tab":""}`}
                    htmlFor="pricing-tab-basic"
                >
                  {props.accountType=="Personal" && props.subscriptionType!="lite"?"Business":"Basic"}
                    
                </label>
            </div>
            :null}

            {props.quarterly?
            <div className="pricing-redesign__input-wrapper">
              <input
                type="radio"
                name="static-pricing-redesign-pricing-tabs"
                id="pricing-tab-professional"
                defaultValue="professional"
                className={`pricing-redesign__pricing-tab-input`}
                onClick={()=>props.changeTab("Quarterly")}
              />
              <label
                className={`pricing-redesign__pricing-tab pricing-redesign__pricing-tab--professional ${props.activeTab=="Quarterly"?"clicked-tab":""}`}
                htmlFor="pricing-tab-professional"
              >
                Quarterly
              </label>
            </div>
            :null}



            {props.annually?
            <div className="pricing-redesign__input-wrapper">
              <input
                type="radio"
                name="static-pricing-redesign-pricing-tabs"
                id="pricing-tab-unlimited"
                defaultValue="unlimited"
                className={`pricing-redesign__pricing-tab-input `}

                onClick={()=>props.changeTab("Yearly")}
              />
              <label
                className={`pricing-redesign__pricing-tab pricing-redesign__pricing-tab--unlimited ${props.activeTab=="Yearly"?"clicked-tab":""}`}
                htmlFor="pricing-tab-unlimited"
              >
                Annual
              </label>
            </div>
            :null}
          </fieldset>
        </form>
      </div>
    );
}

export default SubscriptionNavBar;