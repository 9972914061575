import React from "react";
import firebase from "../../firebaseConfig";

const getSellerOrders = (sellerId, slug) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("id", sellerId);
      const db = firebase.firestore();
      let ordersRef = db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("orders")
        .where("orderStatus", "==", "completed");

      let pendingOrdersRef = db
        .collection("sellersAccounts")
        .doc(sellerId)
        .collection("stores")
        .doc(sellerId)
        .collection("orders")
        .where("orderStatus", "==", "pending");
      // .where("slug", "==", slug);
      let orders = [];
      let pendingOrders = [];

      const snapshot = await ordersRef.get();
      const pendingSnapshot = await pendingOrdersRef.get();

      snapshot.forEach((doc) => {
        orders.push(doc.data());
      });

      pendingSnapshot.forEach((doc) => {
        pendingOrders.push(doc.data());
      });
      //   console.log("orders", orders);
      //   console.log("pending orders", pendingOrders);

      if (snapshot.length < 1) {
        resolve(null);
      }

      resolve({ orders, pendingOrders });
    } catch (error) {
      console.log("error getting seller info -- getSellerOrders", error);
      reject(error);
    }
  });
};
export default getSellerOrders;
