import firebase from "../../firebaseConfig";

/**
 * Gets the service firebase reference for a store
 * @param {String} storeId 
 */
const getDocRef = (storeId) => {
    const db = firebase.firestore();
    var docRef = db
        .collection("sellersAccounts")
        .doc(storeId)
        .collection("stores")
        .doc(storeId)
        .collection("services")
    return docRef
}

/**
 * Gets all services for a store
 * @param {String} storeId 
 */
export const getServices = (storeId) => {
    return new Promise(async (resolve, reject) => {
        try {
            var docRef = getDocRef(storeId)
            const data = await docRef.get()
            const services = data.docs.map(service => service.data())
            resolve(services)
        } catch (error) {
            console.log("error getting services", error);
            reject(error);
        }
    })
}

/**
 * Saves a service to firebase
 * @param {String} storeId 
 * @param {Object} service 
 */
export const saveService = (storeId, service) => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = firebase.firestore();
            var docRef = getDocRef(storeId).doc()
            await docRef.set({id: docRef.id, ...service})
            const allServicesRef = db.collection("allServices").doc(docRef.id)
            await allServicesRef.set({id: docRef.id, ...service})
            resolve("Success")
        } catch (error) {
            console.log("error saving service", error);
            reject(error);
        }
    });
}

/**
 * Updates a service
 * @param {String} storeId 
 * @param {Object} service 
 */
export const updateService = (storeId, service) => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = firebase.firestore();
            var docRef = getDocRef(storeId).doc(service.id)
            const allServicesRef = db.collection("allServices").doc(service.id)
            await docRef.set(service)
            await allServicesRef.set(service)
            resolve("Success")
        } catch (error) {
            console.log("error updating service", error);
            reject(error);
        }
    });
}

/**
 * Deletes a service from firebase
 * @param {String} storeId 
 * @param {String} serviceId 
 */
export const deleteService = (storeId, serviceId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = firebase.firestore();
            var docRef = getDocRef(storeId).doc(serviceId)
            const allServicesRef = db.collection("allServices").doc(serviceId)
            await docRef.delete()
            await allServicesRef.delete()
            resolve("Success")
        } catch (error) {
            console.log("error deleting service", error);
            reject(error);
        }
    });
}