import React, { Component } from "react";
import firebase from "../../../../../firebaseConfig";
import LeftNav from "../../../../common/LeftNav";
import Metatags from "react-meta-tags";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import getRegistrationProgress from "../../../../helpers/getRegistrationProgress";
import getUserLocation from "../../../../helpers/getUserLocation";
import TopHeader from "../../../../common/TopHeader";
import RightNav from "../../../../common/RightNav";

import BottomNav from "../../../../common/BottomNav";

import NProgress from 'nprogress';

import formatDate from "../../../../helpers/formatDate";
import UpcomingBookingsDisplay from "./UpcomingBookingsDisplay";

import { updateBooking, getCancelledBookings, getPastBookings, getUpcomingBookings, getCompletedBookings } from "../../../../helpers/bookings";
import PastBookingsDisplay from "./PastBookingsDisplay";
import Loading from "../../../../common/Loading";
import CompletedBookings from "./CompletedBookingsDisplay";
import CancelledBookingsDisplay from "./CancelledBooking";

var today = new Date();
var date =
  today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
var time =
  today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
var dateTime = date + " " + time;

const compare = (a, b) => {
  let aDate = new Date(formatDate(a.orderTimestamp));
  let bDate = new Date(formatDate(b.orderTimestamp));

  if (aDate > bDate) {
    return -1;
  }
  if (aDate < bDate) {
    return 1;
  }
  return 0;
};
export default class Bookings extends Component {
  state = {
    sellerId: "",
    sellerName: "",
    storeName: "",
    firstName: "",
    lastName: "",
    sellerPhone: "",
    sellerEmail: "",

    viewUpcomingBookings: true,
    viewPastBookings: false,
    viewCancelledBookings: false,
    viewCompletedBookings: false,
    viewConfirmSchedulePickup: false,
    transactionId: "",
    order: {},
    viewShippingLabelPop: false,
    viewOrderDetails: false,
    tracking_number: null,
    loading: false,
    showLeftNav: false,

    upcomingBookings: [],
    pastBookings: [],
    completedBookings: [],
    cancelledBookings: []
  };

  /**
   * Retrieves all upcoming bookings and
   * modifies the state to show only upcoming bookings
   */
  displayUpcomingBookings = async () => {
    try {
      Loading(true, "Loading")
      let upcomingBookings = await getUpcomingBookings(this.state.userId)
      this.setState({
        viewUpcomingBookings: true,
        viewPastBookings: false,
        viewCancelledBookings: false,
        viewCompletedBookings: false,
        upcomingBookings
      })
      Loading(false)
    } catch (err) {
      this.setState({
        viewUpcomingBookings: true,
        viewPastBookings: false,
        viewCancelledBookings: false,
        viewCompletedBookings: false,
        upcomingBookings: []
      })
      Loading(false)
    }
  };

  /**
   * Retrieves all past bookings and
   * modifies the state to show only past bookings
   */
  displayPastBookings = async () => {
    try {
      Loading(true, "Loading")
      let pastBookings = await getPastBookings(this.state.userId)
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: true,
        viewCancelledBookings: false,
        viewCompletedBookings: false,
        pastBookings
      })

      Loading(false)
    }
    catch (err) {
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: true,
        viewCancelledBookings: false,
        viewCompletedBookings: false,
        pastBookings: []
      })
      Loading(false)
    }
  };

  /**
   * Retrieves all cancelled bookings and
   * modifies the state to show only cancelled bookings
   */
  displayCancelledBookings = async () => {
    try {
      Loading(true, "Loading")
      let cancelledBookings = await getCancelledBookings(this.state.userId)
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: false,
        viewCancelledBookings: true,
        viewCompletedBookings: false,
        cancelledBookings
      })
      Loading(false)
    } catch (err) {
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: false,
        viewCancelledBookings: true,
        viewCompletedBookings: false,
        cancelledBookings: []
      })
      Loading(false)
    }
  };

  /**
   * Retrieves all completed bookings and
   * modifies the state to show only cancelled bookings
   */
  displayCompletedBookings = async () => {
    try {
      Loading(true, "Loading")
      let completedBookings = await getCompletedBookings(this.state.userId)
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: false,
        viewCancelledBookings: false,
        viewCompletedBookings: true,
        completedBookings
      })
      Loading(false)
    } catch (err) {
      this.setState({
        viewUpcomingBookings: false,
        viewPastBookings: false,
        viewCancelledBookings: false,
        viewCompletedBookings: true,
        completedBookings: []
      })
      Loading(false)
    }
  };

  /**
   * Modifies the status of a booking to "Cancelled" and saves to the database
   * @param {{}} booking 
   * @param {String} action - used to determine with type of bookings to retrieve eg: "displayUpcomingBookings"
   */
  cancelBooking = async (booking, action) => {
    try {
      let bookingInfo = { ...booking }
      bookingInfo["status"] = "Cancelled"
      await updateBooking(this.state.sellerId, bookingInfo.customerId, bookingInfo)

      if (action === "displayUpcomingBookings") this.displayUpcomingBookings()
      else if (action === "displayPastBookings") this.displayPastBookings()

    } catch (err) {
      console.error("ERROR CANCELLING BOOKING", err)
    }
  }

  markBookingAsComplete = async (booking) => {
    try {
      let bookingInfo = { ...booking }
      bookingInfo["status"] = "Completed"
      await updateBooking(this.state.sellerId, bookingInfo.customerId, bookingInfo)
      this.displayPastBookings()
    } catch (err) {
      console.error("ERROR MARKING BOOKING AS COMPLETE", err)
    }
  }


  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    } else {
      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");

      }
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };



  logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
      })
      .catch(function (error) {
        // An error happened.
        console.log(error, "error with signout");
      });
  };

  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  componentDidMount() {
    NProgress.start();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        Loading(true, "Loading")
        let location = await this.getLocation();
        this.checkRegistrationCompletion(user.uid, location);
        console.log("LOCATION::", location)
        // this.renderComponent("completed");
        this.setState({ firstName: user.displayName, sellerId: user.uid });
        NProgress.set(0.3);
        const bookings = await getUpcomingBookings(user.uid)
        NProgress.set(0.5);
        this.setState({
          upcomingBookings: bookings,
          userId: user.uid
        })
        NProgress.done()
        Loading(false)
      } else {
        this.props.history.push("/");
      }
    });
  }

  render() {
    return (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Seller - Orders</title>
          <meta
            content="Cashaam - Manage your Product with Ease"
            name="description"
          />
          <meta content="Seller - Orders" property="og:title" />
          <meta
            content="Cashaam - Manage your Product with Ease"
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <meta name="msapplication-TileColor" content="#870000" />
          <meta name="theme-color" content="#870000" />
        </Metatags>
        <ToastContainer />

        <TopHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerId}
              userCurrentCountry={this.state.userCurrentCountry}
            />
            <div className="panel-center">
              <div className="page-header">
                {this.state.viewUpcomingBookings === true ? (
                  <h4 className="header-title">Upcoming Bookings</h4>
                ) : this.state.viewPastBookings === true ? (
                  <h4 className="header-title">Past Bookings</h4>
                ) : this.state.viewCancelledBookings === true ? (
                  <h4 className="header-title">Cancelled Bookings</h4>
                ) : this.state.viewCompletedBookings === true ? (
                  <h4 className="header-title">Completed Bookings</h4>
                ) : null}
              </div>
              <div className="main">
                <div className="page-tab">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayUpcomingBookings()}
                    className={
                      this.state.viewUpcomingBookings === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    UPCOMING <span className="order-cut">BOOKINGS</span>
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayPastBookings()}
                    aria-current="page"
                    className={
                      this.state.viewPastBookings === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    PAST <span className="order-cut">BOOKINGS</span>
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayCancelledBookings()}
                    aria-current="page"
                    className={
                      this.state.viewCancelledBookings === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    Cancelled <span className="order-cut">Bookings</span>
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.displayCompletedBookings()}
                    className={
                      this.state.viewCompletedBookings === true
                        ? "link-tab w--current"
                        : "link-tab"
                    }
                  >
                    Completed <span className="order-cut">Bookings</span>
                  </a>
                </div>
                {this.state.viewUpcomingBookings === true ? (
                  <UpcomingBookingsDisplay
                    upcomingBookings={this.state.upcomingBookings}
                    storeId={this.state.sellerId}
                    displayUpcomingBookings={this.displayUpcomingBookings}
                    cancelBooking={this.cancelBooking}
                  />
                ) : this.state.viewPastBookings === true ? (
                  <PastBookingsDisplay
                    pastBookings={this.state.pastBookings}
                    cancelBooking={this.cancelBooking}
                    markBookingAsComplete={this.markBookingAsComplete}
                  />
                ) : this.state.viewCancelledBookings === true ? (
                  <CancelledBookingsDisplay
                    cancelledBookings={this.state.cancelledBookings}
                  />
                ) : this.state.viewCompletedBookings === true ? (
                  <CompletedBookings completedBookings={this.state.completedBookings} />
                ) : null}
              </div>
            </div>
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="orders"
              logoutHandler={this.logoutHandler}
            />
          </div>
          <RightNav logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
