import React, { Component } from "react";
import firebase from "../../../../firebaseConfig";
import Modal from "../../../UI/Modal";
import EditProduct from "./EditProduct";
import { Link } from "react-router-dom";
import logoutHandler from "../../../helpers/logout";
import TopHeader from "../../../common/TopHeader";
import LeftNav from "../../../common/LeftNav";
import Metatags from "react-meta-tags";
import priceFormater from "../../../helpers/priceFormater";
import Loading from "../../../common/Loading";
import getRegistrationProgress from "../../../helpers/getRegistrationProgress";
import getUserLocation from "../../../helpers/getUserLocation";
import getUnreadChat from "../../../helpers/getUnreadChat";
import getBasicInfo from "../../../helpers/getBasicInfo";
import ProductPage from "./ProductPage";
import BottomNav from "../../../common/BottomNav";
import RightNav from "../../../common/RightNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
const db = firebase.firestore();

const colorOptions = [
  { label: "Black", value: 0 },
  { label: "White", value: 1 },
  { label: "Red", value: 2 },
  { label: "Blue", value: 3 },
  { label: "Green", value: 4 },
  { label: "Yellow", value: 5 },
  { label: "Gold", value: 6 },
  { label: "Grey", value: 7 },
  { label: "Brown", value: 8 },
  { label: "Pink", value: 9 },
  { label: "Orange", value: 10 },
  { label: "Purple", value: 11 },
  { label: "Silver", value: 12 },
  { label: "Other", value: 13 },
];

const defaultColors = [
  "Black",
  "White",
  "Red",
  "Blue",
  "Green",
  "Yellow",
  "Gold",
  "Grey",
  "Brown",
  "Pink",
  "Orange",
  "Purple",
  "Silver",
  "Other",
];

const colorsOptionsToValues = {
  Black: 0,
  White: 1,
  Red: 2,
  Blue: 3,
  Green: 4,
  Yellow: 5,
  Gold: 6,
  Grey: 7,
  Other: 8,
};
export default class Products extends Component {
  state = {
    sellerFullName: "",
    products: [],
    editing: false,
    selectedProductId: "",
    sellerUid: "",
    selectedProductInfo: {
      productImages: [],
      productName: "",
      productDescription: "",
      productPrice: "",
      productStock: "",
      productWeight: "",
      productCategory: [],
      sellerFullName: "",
      productVariant: {},
    },
    selectedProductColorOptions: [],
    selectedProductColorOptionsToValues: {},
    showLeftNav: false,
  };


  getProducts = async (id) => {
    try {
      Loading(true, "Loading...");
      const db = firebase.firestore();
      const sellerUid = id;

      db.collection("sellersAccounts")
        .doc(sellerUid)
        .collection("stores")
        .doc(sellerUid)
        .collection("products")
        .get()
        .then((productsData) => {
          Loading(false);
          const productList = productsData.docs.map((product) =>
            product.data()
          );
          this.setState({ products: productList });
        });
    } catch (error) {
      Loading(false);
      console.log("error getting products--", error);
    }
  };

  editProduct = (productId, product) => {
    // alert("edit");
    console.log("selected prod", product);
    let selectedProductColorOptions = [];
    let selectedProductColorOptionsToValues = {};

    let allColors = [
      ...new Set(product.productVariant.colors.concat(defaultColors)),
    ];

    allColors.map((color, i) => {
      selectedProductColorOptions.push({ label: color, value: i });

      //set "other" to code 100
      if (color === "Other") {
        selectedProductColorOptionsToValues[color] = 100;
      } else {
        selectedProductColorOptionsToValues[color] = i;
      }
    });

    this.setState({
      selectedProductId: productId,
      selectedProductInfo: product,
      selectedProductColorOptions,
      selectedProductColorOptionsToValues,
    });

    this.showModal();
  };

  deleteProduct = async (productId, imagesURLS, index, featuredImageURL) => {
    console.log({ productId, imagesURLS, index, featuredImageURL });
    const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerUid = this.state.sellerUid;
    let batch = db.batch();
    this.state.products.splice(index, 1);
    const newProdState = this.state.products;

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("products")
      .doc(productId);
    batch.delete(sellersAccountsRef);

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.delete(allProductsRef);

    //execute the batch writes
    batch
      .commit()
      .then((message) => {
        this.setState({
          products: newProdState,
        });
        console.log("Image delete successful");
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
        console.log("error deleting image--", error);
      });
  };

  showModal = () => {
    this.setState({ editing: true });
  };

  closeModal = () => {
    this.setState({ editing: false });

    this.componentDidMount();
  };

  checkRegistrationCompletion = async (id, location) => {
    //check the user registration progress
    let step = await getRegistrationProgress(id);
    step = parseFloat(step);
    if (step === 100) {
      // this.props.history.push("/seller/dashboard");
      console.log("completed registration step:", step);
    } else {

      if (step < 4) {
        this.props.history.push("/seller/dashboard/setup/onboarding");

      }
    }
  };

  getLocation = async () => {
    const locationData = await getUserLocation();
    console.log({ locationData });
    this.setState({
      userCurrentCountry:
        locationData.country_name === "India"
          ? "Nigeria"
          : locationData.country_name,
      localCurrency:
        locationData.currency.code === "INR"
          ? "NGN"
          : locationData.currency.code,
    });
    return locationData.country_name === "India"
      ? "Nigeria"
      : locationData.country_name;
  };

  showAwaiting = (value, message) => {
    if (value !== false) {
      let timerInterval;
      Swal.fire({
        title: message,
        timer: 120000,
        onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => { }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
        allowOutsideClick: false,
      });
    } else if (value === false) {
      Swal.close();
    }
  };

  removeImage = async (imageURL, index) => {
    if (!imageURL) {
      return toast.error("No image to be deleted");
    }

    this.showAwaiting(true, "Deleting image...");

    // const onEditImageCount = this.props.productInfo.productImages.length;

    // const db = firebase.firestore();
    const storage = firebase.storage();
    const sellerUid = this.state.sellerUid;
    let batch = db.batch();
    const productId = this.state.selectedProductId;

    // this.state.productImages.splice(index, 1);

    let newProductImagesArray = this.state.selectedProductInfo.productImages;
    console.log("BEFORE PROD ARRAY", newProductImagesArray);
    newProductImagesArray.splice(index, 1);
    console.log("AFTER PROD ARRAY", newProductImagesArray);

    console.log("url--", imageURL.includes("blob:http"));
    console.log("img index--", index);
    let selectedProductInfo = this.state.selectedProductInfo;
    selectedProductInfo["productImages"] = [];

    if (imageURL.includes("blob:http")) {
      //delete a picture after selecting and BEFORE SAVING CHANGES to database

      this.setState({
        selectedProductInfo,
        // newFiles: newNewFiles
      });
      this.showAwaiting(false);
      return;
    }

    //delete from Sellers Accounts Ref
    const sellersAccountsRef = await db
      .collection("sellersAccounts")
      .doc(sellerUid)
      .collection("stores")
      .doc(sellerUid)
      .collection("products")
      .doc(productId);

    batch.update(sellersAccountsRef, { productImages: newProductImagesArray });

    //delete from  All Products Ref
    const allProductsRef = db.collection("allProducts").doc(productId);
    batch.update(allProductsRef, { productImages: newProductImagesArray });
    //clear out the cloudinary images
    if (this.state.selectedProductInfo.cloudinaryImages) {
      batch.update(sellersAccountsRef, { cloudinaryImages: [] });
      batch.update(allProductsRef, { cloudinaryImages: [] });
    }
    await batch
      .commit()
      .then(() => {
        var imageRef = storage.refFromURL(imageURL);

        imageRef
          .delete()
          .then(() => {
            this.setState({
              selectedProductInfo,
              newFiles: [],
            });
            // this.componentDidMount();
            this.showAwaiting(false);
            toast.success("Image deleted");
            console.log("image removed");
          })
          .catch((error) => {
            this.showAwaiting(false);
            toast.error("error delting image");
            console.log("error deleting image--", error);
          });
      })
      .catch((error) => {
        this.showAwaiting(false);
        toast.error("error delting image");

        console.log("error deleting image reference");
      });
  };

  loadHubspot=()=>{
    let script = document.createElement("script");
    script.src="//js.hs-scripts.com/21585626.js";
    script.id="hs-script-loader";
    script.addEventListener("load", () => {
        console.log("hubspotLoaded");
        this.setState({ hubspotLoaded: true });
    });
    document.body.appendChild(script);
  }

  loadPendo =() =>{

    let script = document.createElement("script");

    let pendoKey="6ff8873a-3edf-4dc1-6d19-5a3490191d2a";

    script.src='https://cdn.pendo.io/agent/static/'+pendoKey+'/pendo.js';
    script.addEventListener("load", () => {
      console.log("pendo loaded");
      this.setState({ pendoLoaded: true });
    });
    document.body.appendChild(script);

  }

  componentDidMount() {
    this.setState({
      selectedProductInfo: {},
      selectedProductId: "",
    });
    this.loadHubspot();
    this.loadPendo();
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {

        if(this.state.pendoLoaded){
          console.log("initialize pendo")
          pendo.initialize({
            visitor: {
                id: user.uid,   // Required if user is logged in
                email: user?.email,       // Recommended if using Pendo Feedback, or NPS Email
                full_name: user?.displayName,   // Recommended if using Pendo Feedback
                role:  "seller" 
            },
    
            account: {
                id: user.uid, 
                planLevel:  "seller",
                userType: "seller"
            }
          });

        }

        const storeInfo = await getBasicInfo(user.uid);
        this.setState({ storeInfo})

        getUnreadChat(user.uid).then(
          result => {
            this.setState({
              notification: result
            })
          }
        );
        let location = this.getLocation();
        this.checkRegistrationCompletion(user.uid, location);

        this.setState({
          sellerUid: user.uid,
          sellerFullName: user.displayName,
        });
        this.getProducts(user.uid);
      } else {
        this.props.history.push("/");
      }
    });
  }

  productLink = (id) => {
    return `/seller/dashboard/products/${id}`;
  };

  logoutHandler = logoutHandler;
  openLeftNav = () => {
    this.setState({
      showLeftNav: !this.state.showLeftNav,
    });
  };

  render() {
    return (
      <>
        <Metatags>
          <meta charSet="utf-8" />
          <title>Seller - Products</title>
          <meta
            content="Cashaam - Manage your Product with Ease"
            name="description"
          />
          <meta content="Seller - Products" property="og:title" />
          <meta
            content="Cashaam - Manage your Product with Ease"
            property="og:description"
          />
          <meta content="summary" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <meta name="msapplication-TileColor" content="#870000" />
          <meta name="theme-color" content="#870000" />
        </Metatags>
        <ToastContainer />
        <TopHeader
          firstName={this.state.sellerFullName}
          lastName={this.state.lastName}
          logoutHandler={this.logoutHandler}
          notification={this.state.notification}
        />
        <div className="section-inside">
          <div className="panel-main">
            <LeftNav
              showLeftNav={this.state.showLeftNav}
              logoutHandler={this.logoutHandler}
              sellerId={this.state.sellerUid}
              userCurrentCountry={this.state.userCurrentCountry}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
            {this.state.editing === true ? (
              <EditProduct
                closeModal={this.closeModal}
                productId={this.state.selectedProductId}
                sellerUid={this.state.sellerUid}
                productInfo={this.state.selectedProductInfo}
                removeImage={this.removeImage}
                selectedProductColorOptions={
                  this.state.selectedProductColorOptions
                }
                selectedProductColorOptionsToValues={
                  this.state.selectedProductColorOptionsToValues
                }
                userCurrentCountry={this.state.userCurrentCountry}

              />
            ) : (
                <ProductPage
                  deleteProduct={this.deleteProduct}
                  products={this.state.products}
                  editProduct={this.editProduct}
                />
              )}
            <BottomNav
              openLeftNav={this.openLeftNav}
              page="products"
              logoutHandler={this.logoutHandler}
              subscriptionActive={this.state.storeInfo?.subscriptionActive}
              subscriptionType={this.state.storeInfo?.subscriptionType}
              accountType={this.state.storeInfo?.accountType}
            />
          </div>
          <RightNav 
          notification={this.state.notification}
          logoutHandler={this.logoutHandler} />
        </div>
      </>
    );
  }
}
