import React, { useState } from 'react'
import Loading from '../../../common/Loading';
import { ToastContainer, toast } from "react-toastify";
import storeProductImagesToStorage from '../../../helpers/storeProductImagesToStorage'

import ServiceForm from './ServiceForm'
import { updateService } from "../../../helpers/service";


const options = [
    { label: "Clothing, Shoes, Jewelry & Watches", value: 0 },
    { label: "Movies, Music & Games", value: 1 },
    { label: "Electronics, Computers & Office", value: 2 },
    { label: "Home, Garden & Tools", value: 3 },
    { label: "Food & Grocery", value: 4 },
    { label: "Beauty & Health", value: 5 },
    { label: "Toys, Kids & Baby", value: 6 },
    { label: "Handmade", value: 7 },
    { label: "Sports & Outdoors", value: 8 },
    { label: "Automotive & Industrial", value: 9 },
];

const optionsToValues = {
    "Clothing, Shoes, Jewelry & Watches": 0,
    "Movies, Music & Games": 1,
    "Electronics, Computers & Office": 2,
    "Home, Garden & Tools": 3,
    "Food & Grocery": 4,
    "Beauty & Health": 5,
    "Toys, Kids & Baby": 6,
    "Handmade": 7,
    "Sports & Outdoors": 8,
    "Automotive & Industrial": 9,
};

/**
 * Component to edit a service
 * @param {*} props 
 */
const EditService = (props) => {
    const [selectedService, setSelectedService] = useState({
        serviceName: props.service.name,
        serviceDescription: props.service.description,
        servicePrice: props.service.price,
        serviceDuration: props.service.duration,
        previewImageUrls: props.service.images,
        location: props.service.location,
        addGuestEnabled: props.service.addGuestEnabled,
        email: props.service.email,
        phoneNumber: props.service.phoneNumber
    })
    const [selectedCategories, setSelectedCategories] = useState(props.service.categories.map(category =>
        optionsToValues[category]
    ))
    const [imageFiles, setImageFiles] = useState([])
    const [newPreviewUrl, setNewPreviewUrl] = useState([])

    const onSaveService = async (e) => {
        e.preventDefault()
        const {
            serviceName,
            servicePrice,
            serviceDuration,
            serviceDescription,
            previewImageUrls,
            location,
            addGuestEnabled,
            email,
            phoneNumber
        } = selectedService
        try {
            Loading(true, "Service is being updated")

            const productImagesURL = [];
            for (let i = 0; i < imageFiles.length; i++) {
                const url = await storeProductImagesToStorage(
                    imageFiles[i],
                    serviceName,
                    selectedCategories[0]
                );
                productImagesURL.push(url);
            }
            const service = {
                id: props.service.id,
                name: serviceName,
                description: serviceDescription,
                price: servicePrice,
                duration: serviceDuration,
                images: previewImageUrls.concat(productImagesURL),
                categories: selectedCategories.map(
                    (value) => options[value].label
                ),
                location,
                addGuestEnabled,
                email,
                phoneNumber,
                slug: props.slug
            }

            await updateService(props.userId, service)
            Loading(false)
            props.onCloseEditing()
        } catch (err) {
            Loading(false)
        }

    }

    const handleChange = (e) => {
        setSelectedService({
            ...selectedService,
            [e.target.name]: e.target.value
        })
    }

    const handleCheckboxChange = (e) => {
        setSelectedService({
            ...selectedService,
            addGuestEnabled: e.target.checked
        })
    }
    const selectCategory = ({ selected }) => {
        if (selected.length <= 2) {
            setSelectedCategories(selected)
        } else {
            toast.error("You can only select 2 categories");
        }
    };

    const onImageUpload = (e) => {
        e.preventDefault()
        let file;
        const previewImageUrls = [...newPreviewUrl]
        const tempImageFiles = [...imageFiles]

        for (var i = 0; i < e.target.files.length; i++) {
            file = e.target.files[i]
            if (
                file.type !== "image/png" &&
                file.type !== "image/jpg" &&
                file.type !== "image/jpeg"
            ) {
                const message = `Image of type ${file.type} are not supported. Please upload a JPEG, JPG or PNG`;

                toast.error(message);
                return;
            }
            previewImageUrls.push(URL.createObjectURL(file))
            tempImageFiles.push(file)
        }
        setNewPreviewUrl(previewImageUrls)
        setImageFiles(tempImageFiles)
    }

    const onDeleteImage = (index) => {
        const previewImageUrls = [...selectedService.previewImageUrls]
        const imageFiles = [...imageFiles]
        previewImageUrls.splice(index, 1)
        imageFiles.splice(index, 1)
        setSelectedService({
            ...selectedService,
            previewImageUrls
        })
        setImageFiles(imageFiles)
    }

    return (
        <>
            <div className="panel-center">
                <div className="page-header">
                    <h4 className="header-title">Update Service</h4>
                </div>
                <div className="main">
                    <ServiceForm
                        isEditing={props.isEditing}
                        cancelEditing={props.onCancelEditing}
                        submitForm={onSaveService}
                        onImageUpload={onImageUpload}
                        onDeleteImage={onDeleteImage}
                        previewImageUrls={selectedService.previewImageUrls.concat(newPreviewUrl)}
                        serviceName={selectedService.serviceName}
                        serviceDescription={selectedService.serviceDescription}
                        servicePrice={selectedService.servicePrice}
                        serviceDuration={selectedService.serviceDuration}
                        currency={props.currentCountry}
                        options={options}
                        selected={selectedCategories}
                        selectCategory={selectCategory}
                        handleChange={handleChange}
                        location={selectedService.location}
                        addGuestEnabled={selectedService.addGuestEnabled}
                        email={selectedService.email}
                        phoneNumber={selectedService.phoneNumber}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default EditService
