import React from 'react';
import getDateFromTimestamp from "../helpers/getDateFromTimestamp";
import shippingDot from "../../images/3844442_dot_menu_more_vertical_icon.svg";
import userLogo from "../../images/1564535_customer_user_userphoto_account_person_icon-1.svg";

function CustomerDetails(props) {
    return (
      <>
        <div className="orderdetailsbox">
        <div className="orderdetailstop">
          <div>Customer</div>
        </div>
        <div className="orderdetailscontent">
          <div className="orderdetailswhitesmoke">
            <img
              src={userLogo}
              loading="lazy"
              width={30}
              alt
            />
            <div className="orderdetailscustomername">
              {props.customerName}
            </div>
          </div>
          <div className="odcustomerinfowrap">
            <div className="textwrap">
              <div className="label">Email:</div>
              <div>{props.customerEmail}</div>
            </div>
            <div className="textwrap">
              <div className="label">Phone Number:</div>
              <div>{props.customerPhone}</div>
            </div>
            <div className="textwrap">
              <div className="label">Purchase Date:</div>
              <div>{getDateFromTimestamp(props.purchasedDate)}</div>
            </div>
          </div>
          <div className="orderdetailswhitesmoke extramargin">
            <div className="orderdetailsaddress">
              <div className="odaddresswrap">
                <div className="odaddresstop">
                  <div className="label">Shipping Address</div>
                  <a
                    href="#"
                    className="odaddressdetail w-inline-block"
                  >
                    <img
                      src={shippingDot}
                      loading="lazy"
                      width={20}
                      alt
                    />
                  </a>
                </div>
                <p>
                  {props.customerName}
                  <br />
                  {props.shippingStreet}
                  <br />
                  {props.shippingCity}, {props.shippingState} {props.shippingZip}
                  <br />
                  {props.shippingCountry}
                  <br />
                </p>
              </div>
              {/* <div className="odaddresswrap">
                <div className="odaddresstop">
                  <div className="label">Billing Address</div>
                  <a
                    href="#"
                    className="odaddressdetail w-inline-block"
                  >
                    <img
                      src="images/3844442_dot_menu_more_vertical_icon.svg"
                      loading="lazy"
                      width={20}
                      alt
                    />
                  </a>
                </div>
                <p>
                  John DOE
                  <br />
                  My Company
                  <br />
                  16, Main street 2nd floor
                  <br />
                  Miami, Florida 33133
                  <br />
                  United States
                  <br />
                  0102030405
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="orderdetailsbox">
        <div className="orderdetailstop">
          <div>Shipping Label Tutorial</div>
        </div>
        <div style={{borderTop: "1px solid #d3d3d3"}} className="orderdetailscontent">
          <iframe
            className="shipping-tutorial"
            src="https://www.youtube.com/embed/Rc9_bJ5j_mE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowfullscreen
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen" 
            msallowfullscreen="msallowfullscreen" 
            oallowfullscreen="oallowfullscreen" 
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>

        </div>

      </div>
      </>
    );
}

export default CustomerDetails;